/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Stack,
  Tag,
  TagCloseButton,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { CiCircleCheck, CiCirclePlus } from 'react-icons/ci';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import type { Content } from '@/client/services/api/graphql/gql/graphql';
import NoSearchResultsListItem from '@/client/components/admin/create/content/lists/NoSearchResultsListItem';
import type { SlackChannel } from '@/client/types/admin/slack/SlackChannel';
import SlackService from '@/client/services/api/admin/slack/SlackService';
import { useCombobox } from 'downshift';
import { useFormContext } from 'react-hook-form';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

// TODO: Refactor this combobox for use across all authoring searches

export default function AuthoringFeedbackSlackChannelsSearch() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<Content>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setToast } = useToastStore();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [slackChannelId, setSlackChannelId] = useState<string>('');

  const selectedSlackChannels = watch('ratingConfiguration.messaging.slackChannels');

  const manuallyAddChannel = useMutation({
    mutationFn: () => SlackService.manuallyAddChannel(slackChannelId),
    onSuccess: (mutationData) => {
      if (mutationData && mutationData.name) {
        const existingChannel = selectedSlackChannels.find(
          (channel) => channel.id === mutationData.id,
        );

        if (existingChannel) {
          setToast({
            show: true,
            status: 'warning',
            title: t('slackChannels.previously_added'),
          });
        } else {
          setValue('ratingConfiguration.messaging.slackChannels', [
            ...selectedSlackChannels,
            mutationData,
          ]);

          setToast({
            show: true,
            status: 'success',
            title: t('slackChannels.channel_added'),
          });
        }
      }
    },
    onError: (error) => {
      console.error('error: ', error);

      setToast({
        show: true,
        status: 'error',
        title: t('slackChannels.channel_unavailable'),
      });
    },
    onSettled: () => {
      setSlackChannelId('');
      onClose();
    },
  });

  const {
    isLoading,
    isError,
    data: slackChannelData,
  } = useQuery({
    queryKey: ['slack_channels'],
    queryFn: () => SlackService.getSlackChannelList(),
    select: (selectData) => [
      {
        title: 'Slack Channels',
        options: selectData.map((channel: SlackChannel) => ({
          id: channel.id,
          name: channel.name,
        })),
      },
    ],
  });

  const data = useMemo(() => {
    if (!slackChannelData) {
      return [];
    }

    if (!searchTerm) {
      return slackChannelData;
    }

    const filteredData = slackChannelData.map((section) => {
      const filteredOptions = section.options.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase()),
      );

      return {
        title: section.title,
        options: filteredOptions,
      };
    });

    return filteredData;
  }, [slackChannelData, searchTerm]);

  const flattenGroupOptions = (options: { title: string; options: any[] }[]) =>
    options.reduce((prev: any, curr: any) => [...prev, ...curr.options], []);

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    onInputValueChange({ inputValue }) {
      if (!inputValue) {
        setSearchTerm('');
        return;
      }

      setSearchTerm(inputValue);
    },
    items: flattenGroupOptions(data),
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        return;
      }

      const alreadySelected = selectedSlackChannels.some(
        (selectedSlackChannel) => selectedSlackChannel.id === selectedItem.id,
      );

      if (alreadySelected) {
        setValue(
          'ratingConfiguration.messaging.slackChannels',
          selectedSlackChannels.filter(
            (selectedSlackChannel) => selectedSlackChannel.id !== selectedItem.id,
          ),
        );

        return;
      }

      setValue('ratingConfiguration.messaging.slackChannels', [
        ...selectedSlackChannels,
        selectedItem,
      ]);
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: '', // don't add the item string as input value at selection.
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '', // don't add the item string as input value at selection.
          };
        default:
          return changes;
      }
    },
  });

  if (isLoading)
    <Stack>
      <Skeleton height={20} width="20%" />

      <Skeleton height={20} width="100%" />

      <Skeleton marginLeft={6} height={10} width="20%" />
    </Stack>;

  if (isError)
    return <Box>{t('authoring.settings.enableRatings.feedback.slackChannels.error')}</Box>;

  return (
    <Stack>
      <HStack justifyContent="space-between">
        <Text variant="createLabel">
          {t('authoring.settings.enableRatings.feedback.slackChannels')}
        </Text>

        <Button
          variant="unstyled"
          size="xs"
          fontWeight={400}
          textDecoration="underline"
          onClick={onOpen}
        >
          {t('slackChannels.not_finding')}
        </Button>
      </HStack>

      <Input
        variant="create"
        placeholder={t('authoring.slackContacts.searchChannels')}
        {...getInputProps()}
      />

      <List
        flexDirection="column"
        border={!!searchTerm && data ? '1px solid' : 'none'}
        borderColor="brand.gold.100"
        borderRadius="md"
        background="white"
        overflow="scroll"
        paddingX={6}
        zIndex={9999}
        maxH={400}
        width="inherit"
        {...getMenuProps()}
      >
        {data && data[0]?.options?.length === 0 && <NoSearchResultsListItem />}

        {!!searchTerm &&
          data &&
          data[0].options.length > 0 &&
          data.reduce(
            (results: any, section: any, sectionIndex: any) => {
              results.sections.push(
                <Box
                  key={`results_section_${sectionIndex + 1}`}
                  paddingY={4}
                  borderBottom="1px solid"
                  borderColor="brand.gold.100"
                >
                  {section.options.length > 0 && (
                    <Text variant="createLabel" marginY={2}>
                      {section.title}
                    </Text>
                  )}

                  {section.options.length > 0 &&
                    section.options.map((option: any, optionIndex: any) => {
                      // eslint-disable-next-line no-plusplus, no-param-reassign
                      const resultIndex = results.itemIndex++;

                      const isSelected = selectedSlackChannels.some(
                        (slackChannel) => slackChannel.id === option.id,
                      );

                      return (
                        <ListItem
                          key={`option_index_${optionIndex + 1}`}
                          paddingX={6}
                          paddingY={2}
                          _hover={{
                            cursor: 'pointer',
                          }}
                          {...getItemProps({
                            item: option,
                            index: resultIndex,
                            'aria-selected': isSelected,
                          })}
                          backgroundColor={
                            highlightedIndex === resultIndex ? 'warmNeutral.0' : 'white'
                          }
                          border={highlightedIndex === resultIndex ? '1px solid' : 'none'}
                          borderColor="warmNeutral.400"
                          borderRadius="6px"
                        >
                          <Flex alignItems="center" width="full" justifyContent="space-between">
                            <Box flexGrow={1} textAlign="left" paddingLeft={4}>
                              <Text
                                fontSize="sm"
                                fontWeight={500}
                                color={
                                  isSelected ? 'brand.legibleBlack.50' : 'brand.legibleBlack.100'
                                }
                              >
                                {option.name}
                              </Text>
                            </Box>

                            <Icon
                              boxSize={8}
                              as={isSelected ? CiCircleCheck : CiCirclePlus}
                              color={isSelected ? 'brand.gold.100' : 'brand.grey.40'}
                            />
                          </Flex>
                        </ListItem>
                      );
                    })}
                </Box>,
              );

              return results;
            },
            { sections: [], itemIndex: 0 },
          ).sections}
      </List>

      {selectedSlackChannels.length > 0 && (
        <HStack flexWrap="wrap">
          {selectedSlackChannels.map((slackChannel) => (
            <Tag key={slackChannel.id} variant="create">
              {slackChannel.name}

              <TagCloseButton
                onClick={() =>
                  setValue(
                    'ratingConfiguration.messaging.slackChannels',
                    selectedSlackChannels.filter(
                      (selectedSlackChannel) => selectedSlackChannel.id !== slackChannel.id,
                    ),
                  )
                }
              />
            </Tag>
          ))}
        </HStack>
      )}

      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody paddingY={6}>
            <VStack textAlign="center" fontSize="sm" spacing={4} marginBottom={6}>
              <Text fontSize="lg" fontWeight="500">
                {t('slackChannels.add_direct')}
              </Text>

              <Text>{t('slackChannelList.modal.lineOne')}</Text>

              <Text>{t('slackChannelList.modal.lineTwo')}</Text>

              <Text>{t('slackChannelList.modal.lineThree')}</Text>
            </VStack>

            <Box>
              <Text variant="createLabel">{t('slackChannels.channel_id')}</Text>

              <Input
                variant="create"
                value={slackChannelId}
                onChange={(e) => setSlackChannelId(e.target.value)}
              />
            </Box>

            <Flex justifyContent="center">
              <Button
                size="sm"
                variant="createIconSolid"
                marginTop={6}
                isDisabled={slackChannelId === ''}
                onClick={() => manuallyAddChannel.mutate()}
              >
                {t('global.actions.add')}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}
