import {
  Button,
  Collapse,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import type {
  CSVFinalDataFormatted,
  Category,
} from '@/client/types/admin/user-manager/CsvFinalData';
import {
  HiCheck,
  HiOutlineBuildingLibrary,
  HiOutlineGlobeAlt,
  HiOutlineUserGroup,
} from 'react-icons/hi2';

import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import BulkImportValidationGroup from '@/client/routes/admin/users/BulkImportValidationGroup';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import GhostDownloadButton from '@/client/components/admin/buttons/GhostDownloadButton';
import { useTranslation } from 'react-i18next';

function SplitList({ items }: { items: Category[] }) {
  const { isOpen, onToggle } = useDisclosure();

  // eslint-disable-next-line react/destructuring-assignment
  const firstFive = items.slice(0, 5);

  // eslint-disable-next-line react/destructuring-assignment
  const rest = items.slice(5);

  return (
    <>
      <UnorderedList>
        {firstFive.map((item) => (
          <ListItem key={item.name}>{item.name}</ListItem>
        ))}
      </UnorderedList>

      {rest.length > 0 && (
        <>
          <Button variant="ghost" size="xs" justifyContent="flex-start" onClick={onToggle}>
            {isOpen ? 'Show Less' : `Show ${rest.length} More`}
          </Button>

          <Collapse in={isOpen}>
            <UnorderedList>
              {rest.map((item) => (
                <ListItem key={item.name}>{item.name}</ListItem>
              ))}
            </UnorderedList>
          </Collapse>
        </>
      )}
    </>
  );
}

interface BulkImportReviewProps {
  csvFinalData: CSVFinalDataFormatted;
  downloadCsv: (data: any[], type: string) => void;
}

export default function BulkImportReview({ csvFinalData, downloadCsv }: BulkImportReviewProps) {
  const { t } = useTranslation();

  const {
    createdUsers,
    invitations,
    preprovisions,
    updatedUsers,
    resettings,
    reinvitations,
    deletedUsers,
    migrationsI2P,
    migrationsP2I,
    locations,
    departments,
  } = csvFinalData;

  return (
    <AdminElevatedBox>
      <FormLabelWithTooltip label={t('admin.users.bulkImport.review')} />

      <Stack spacing={6}>
        {invitations.length > 0 ||
          (preprovisions.length > 0 && (
            <BulkImportValidationGroup type="success" icon={HiCheck}>
              <Stack>
                {invitations.length > 0 && (
                  <Text>{`${invitations.length} ${t('edit.useradd.users_invited')}`}</Text>
                )}

                {preprovisions.length > 0 && (
                  <Text>{`${preprovisions.length} ${t('edit.useradd.users_preprovisioned')}`}</Text>
                )}

                {createdUsers.length > 0 && (
                  <HStack>
                    <Text>{`${createdUsers.length} ${t('edit.useradd.users_created')}`}</Text>

                    <GhostDownloadButton
                      ariaLabel="Download Created Users"
                      onClick={() => downloadCsv(createdUsers, 'created-users')}
                    />
                  </HStack>
                )}
              </Stack>
            </BulkImportValidationGroup>
          ))}

        {updatedUsers.length > 0 && (
          <BulkImportValidationGroup type="warning" icon={HiCheck}>
            <Stack spacing={6}>
              <HStack>
                <Text>{`${updatedUsers.length} ${t('edit.useradd.users_updated')}`}</Text>

                <GhostDownloadButton
                  ariaLabel="Download Updated Users"
                  onClick={() => downloadCsv(updatedUsers, 'updated-users')}
                />
              </HStack>

              {migrationsI2P.length > 0 && (
                <Text>{`${migrationsI2P.length} ${t('edit.useradd.message_users_email')}`}</Text>
              )}

              {migrationsP2I.length > 0 && (
                <Text>{`${migrationsP2I.length} ${t('edit.useradd.message_users_sso')}`}</Text>
              )}
            </Stack>
          </BulkImportValidationGroup>
        )}

        {deletedUsers.length !== 0 ||
          reinvitations ||
          (resettings && (
            <BulkImportValidationGroup type="warning" icon={HiOutlineUserGroup}>
              <Stack spacing={6}>
                {reinvitations && <Text>{t('edit.useradd.all_invited')}</Text>}

                {resettings && <Text>{t('edit.useradd.all_reset')}</Text>}

                {deletedUsers.length > 0 && (
                  <Text>{`${deletedUsers.length} ${t('edit.useradd.all_deleted')}`}</Text>
                )}

                {migrationsI2P.length > 0 && (
                  <Text>{`${migrationsI2P.length} ${t('edit.useradd.all_migrated_sso')}`}</Text>
                )}

                {migrationsP2I.length > 0 && (
                  <Text>{`${migrationsP2I.length} ${t('edit.useradd.all_migrated_email')}`}</Text>
                )}
              </Stack>
            </BulkImportValidationGroup>
          ))}

        {locations && locations.length > 0 && (
          <BulkImportValidationGroup type="success" icon={HiOutlineGlobeAlt}>
            <Stack>
              <Text>{`${locations.length} ${t('edit.useradd.locations_added_text')}`}</Text>

              <SplitList items={locations} />
            </Stack>
          </BulkImportValidationGroup>
        )}

        {departments && departments.length > 0 && (
          <BulkImportValidationGroup type="success" icon={HiOutlineBuildingLibrary}>
            <Stack>
              <Text>{`${departments.length} ${t('edit.useradd.departments_added_text')}`}</Text>

              <SplitList items={departments} />
            </Stack>
          </BulkImportValidationGroup>
        )}
      </Stack>
    </AdminElevatedBox>
  );
}
