import { LineChart } from '../LineChart';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetReportData } from '@/client/services/hooks/admin/useGetReportData';

export default function AssignedCompletions() {
  const { dateRange, setDateRange, getDataBasedOnDateRange, getAssignedCompletionsData } =
    useGetReportData();
  const { data, refetch } = getAssignedCompletionsData;
  const { t } = useTranslation();

  useEffect(() => {
    getDataBasedOnDateRange(refetch);
  }, [dateRange]);

  return (
    <LineChart
      data={data || []}
      colSpan={1}
      rowSpan={1}
      dateRange={dateRange}
      setDateRange={setDateRange}
      dateLocale={{ format: 'en_US' }}
      title={t('partners.manager.dashboard.contentCompletions')}
      tooltip={t('partners.manager.dashboard.contentCompletions.help')}
    />
  );
}
