import { NavigateFunction, useNavigate } from "react-router-dom";
import { useAuthStore } from "@/client/services/state/authStore";
import { useEffect } from "react";
import RouteRecognizer from "route-recognizer";

type RouteClosure = (...values: any) => string;

function routeTemplate(strings: TemplateStringsArray, ...keys: any[]): RouteClosure {
  return (...values: any[]) => {
    const dict = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach((key, i) => {
      const value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join("");
  };
}

const routeRedirect = (router: RouteRecognizer, route: string, redirectToTemplate?: Function) => {
  if (!redirectToTemplate) {
    router.add([{ path: route, handler: (...values: any) => { return route; } }]);
  } else {
    router.add([{ path: route, handler: redirectToTemplate }]);
  }
}

const router = new RouteRecognizer();

routeRedirect(router, "/accept");
routeRedirect(router, "/dashboard");
routeRedirect(router, "/dashboard/:location", routeTemplate`/dashboard`);
routeRedirect(router, "/explore");
routeRedirect(router, "/explore/:category", routeTemplate`/explore/${"category"}`);
routeRedirect(router, "/reset");
routeRedirect(router, "/resetpassword", routeTemplate`reset`);
routeRedirect(router, "/signin", routeTemplate`/login`);
routeRedirect(router, "/view/articles/:id", routeTemplate`/article/${"id"}`);
routeRedirect(router, "/view/videos/:id", routeTemplate`/video/${"id"}`);
routeRedirect(router, "/view/files/:id", routeTemplate`/file/${"id"}`);
routeRedirect(router, "/view/scorm/:id", routeTemplate`/scorm/${"id"}`);
routeRedirect(router, "/view/imported-content/:id", routeTemplate`/imported-content/${"id"}`);
routeRedirect(router, "/view/journey/:id", routeTemplate`/journey/${"id"}`);
routeRedirect(router, "/view/tracks/:id", routeTemplate`/track/${"id"}`);
routeRedirect(router, "/view/tracks/:trackId/:contentType/:contentId", routeTemplate`/track/${"trackId"}/${"contentType"}/${"contentId"}`);
routeRedirect(router, "/view/workshops/:id", routeTemplate`/workshop/${"id"}`);
routeRedirect(router, "/view/assessments/:id", routeTemplate`/assessment/${"id"}`);
routeRedirect(router, "/view/multi-session-workshops/:id", routeTemplate`multi-session-workshop/${"id"}`);

export default function SiteRoot() {
  const navigate = useNavigate();
  const { authConfig } = useAuthStore();

  useEffect(() => {
    // If hash fragment exists, try to redirect to an existing route.
    if (window.location.hash) {
      const hash = window.location.hash.replace("#", "");
      const match = router.recognize(hash);
      console.log("hash match", match);
      if (match && match.length > 0) {
        let toRoute = (match[0]?.handler as RouteClosure)(match[0]?.params);
        if (match?.queryParams) {
          toRoute += "?" + new URLSearchParams(match?.queryParams).toString();
        }
        navigate(toRoute);
        return;
      }
    }

    if (authConfig.authtoken) {
      navigate("/explore");
      return;
    }
    navigate("/login");
  }, []);

  return <></>;
}
