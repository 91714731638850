import { Box, Grid } from '@chakra-ui/react';
import RevenueChart from '@/client/components/data-display/charts/ecommerce/RevenueChart'
import PurchaseChart from '@/client/components/data-display/charts/ecommerce/PurchasesChart';
import MostRecentPurchasesList from '@/client/components/data-display/charts/ecommerce/MostRecentPurchasesList';
import MostPurchasedProductsList from '@/client/components/data-display/charts/ecommerce/MostPurchasedProductsList';

export default function RevenueDashboard() {

  return (
    <Box
      padding={6}
      backgroundColor="white"
      margin={2}
      marginX={4}
      borderRadius="10px"
      boxShadow="0 2px 2px rgba(0,0,0,0.1)"
    >
      <Grid
        templateRows="400px 400px"
        templateColumns="repeat(1, 1fr)"
        justifyContent="space-between"
        gap={4}
        padding={4}
        >
          <RevenueChart />
          <PurchaseChart />
        </Grid>
        <Grid
        templateRows="400px 400px"
        templateColumns="repeat(2, 1fr)"
        justifyContent="space-between"
        gap={4}
        padding={4}
        >
          <MostRecentPurchasesList />
          <MostPurchasedProductsList />
        </Grid>
    </Box>
  );
}
