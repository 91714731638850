import AssessmentIntro from './AssessmentIntro';
import QuestionPresenter from './QuestionPresenter';
import { handleWordCount } from '@/client/utils/content/assessments/handleWordCount';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useEffect } from 'react';
import { useTextAssessmentStore } from '@/client/services/state/content/textAssessmentStore';

interface TextAssessmentProps {
  refetchUserAssessment: () => void;
  setRetakingAssessment: (retakingAssessment: boolean) => void;
  inTrack?: boolean;
  inJourney?: boolean;
  handleCompleteContent: Function;
}

export default function TextAssessment({
  refetchUserAssessment,
  setRetakingAssessment,
  inTrack,
  inJourney,
  handleCompleteContent,
}: TextAssessmentProps) {
  useDocumentTitle('View Assessment');

  const {
    assessment,
    userAssessment,
    inProgressResponses,
    setInProgressResponses,
    viewingAssessment,
    setViewingAssessment,
    setShowIntro,
    setCanSubmit,
    setAnsweredQuestions,
    setProgress,
    setInTrack,
    setInJourney,
    reset,
  } = useTextAssessmentStore();

  useEffect(() => {
    setInTrack(inTrack);
    setInJourney(inJourney);

    return () => reset();
  }, []);

  useEffect(() => {
    if (userAssessment && userAssessment.in_progress_responses) {
      const inProgress = Object.entries(userAssessment.in_progress_responses).map(
        ([key, value]) => ({
          questionId: key,
          answers: value,
        }),
      );

      setInProgressResponses(inProgress);
    }
  }, [assessment, userAssessment]);

  const updateProgress = () => {
    const { questions } = assessment;
    const responses = inProgressResponses;

    let answers = 0;

    questions.forEach((question) => {
      const response = responses.find((userResponse) => userResponse.questionId === question.id);

      if (response) {
        if (response.answers.length === 0 || response.answers[0] === null) return;

        if (question.format === 'long_input') {
          const minWords =
            (question.question_options.find((option) => option.name === 'word_minimum')
              ?.value as number) || 1;
          const maxWords =
            (question.question_options.find((option) => option.name === 'word_limit')
              ?.value as number) || 5000;

          const answerLength = handleWordCount(response.answers[0]);

          if (answerLength >= minWords && answerLength <= maxWords) answers += 1;
        } else if (question.format === 'multiple_choice') {
          const optionCount = question.question_options.find(
            (option) => option.name === 'number_of_selections',
          )?.value as number;
          if (optionCount) {
            if (optionCount === response.answers.length) {
              answers += 1;
            }
          } else {
            answers += 1;
          }
        } else if (
          response.answers.length > 0 &&
          response.answers[0] !== null &&
          response.answers[0] !== ''
        ) {
          answers += 1;
        }
      }
    });

    if (answers === questions.length) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }

    setAnsweredQuestions(answers);
    setProgress((answers / questions.length) * 100);
  };

  useEffect(() => {
    updateProgress();
  }, [inProgressResponses]);

  useEffect(() => {
    if (assessment.hide_cover) {
      setViewingAssessment(true);
      setShowIntro(false);
    }
  }, []);

  return (
    <>
      {!viewingAssessment && !assessment.hide_cover && <AssessmentIntro />}

      {viewingAssessment && (
        <QuestionPresenter
          refetchUserAssessment={refetchUserAssessment}
          setRetakingAssessment={setRetakingAssessment}
          handleCompleteContent={handleCompleteContent}
        />
      )}
    </>
  );
}

TextAssessment.defaultProps = {
  inTrack: false,
  inJourney: false,
};
