import { Box, Button, ButtonGroup, Flex, Stack, Text } from '@chakra-ui/react';
import { Redo, Save } from '@/client/components/icons/ContinuIcons';

import ColorPicker from '@/client/components/admin/theme/ColorPicker';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import SampleButtons from './sample-components/SampleButtons';
import SampleFormInputs from './sample-components/SampleFormInputs';
import SampleHighlights from './sample-components/SampleHighlights';
import SampleLinks from './sample-components/SampleLinks';
import SampleTable from './sample-components/SampleTable';
import { useTranslation } from 'react-i18next';
import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';

interface ColorOverridesFormProps {
  colors: any;
  defaultColor?: string;
  setColors: (colors: any) => void;
  saveOverrides?: () => void;
  resetOverrides: () => void;
  isLoading: boolean;
  canSubmit: boolean;
  saveInForm?: boolean;
}

export default function ColorOverridesForm({colors, setColors, saveOverrides, resetOverrides, isLoading, canSubmit, saveInForm, defaultColor}: ColorOverridesFormProps) {
  const { t } = useTranslation();

  const gridItems = [
    {
      label: t('admin.theme.buttons.label'),
      tooltipText: t('admin.theme.buttons.helpText'),
      color: colors.buttons,
      setColor: (color: string) => setColors((prev: any) => ({ ...prev, buttons: color })),
      sampleElement: <SampleButtons overrideColor={colors.buttons || defaultColor} />,
    },
    {
      label: t('admin.theme.tableHeaders.label'),
      tooltipText: t('admin.theme.tableHeaders.helpText'),
      color: colors.headers,
      setColor: (color: string) => setColors((prev: any) => ({ ...prev, headers: color })),
      sampleElement: <SampleTable overrideColor={colors.headers || defaultColor} />,
    },
    {
      label: t('admin.theme.listHighlights.label'),
      tooltipText: t('admin.theme.listHighlights.helpText'),
      color: colors.highLights,
      setColor: (color: string) => setColors((prev: any) => ({ ...prev, highLights: color })),
      sampleElement: <SampleHighlights overrideColor={colors.highLights || defaultColor} />,
    },
    {
      label: t('admin.theme.links.label'),
      tooltipText: t('admin.theme.links.helpText'),
      color: colors.links,
      setColor: (color: string) => setColors((prev: any) => ({ ...prev, links: color })),
      sampleElement: <SampleLinks overrideColor={colors.links || defaultColor} />,
    },
    {
      label: t('admin.theme.formInputs.label'),
      tooltipText: t('admin.theme.formInputs.helpText'),
      color: colors.formInputs,
      setColor: (color: string) => setColors((prev: any) => ({ ...prev, formInputs: color })),
      sampleElement: <SampleFormInputs overrideColor={colors.formInputs || defaultColor} />,
    },
  ];

  return (
    <AdminElevatedBox>
      <Flex justifyContent="space-between">
        <Text marginTop={0} as="h4">
          {t('admin.theme.colors')}
        </Text>

        <ButtonGroup size="xs">
          <Button
            variant="adminWarning"
            leftIcon={<Redo />}
            isLoading={isLoading}
            onClick={resetOverrides}
          >
            {t('global.action.reset')}
          </Button>

          {saveInForm && (
            <Button
            variant="adminPrimary"
            leftIcon={<Save />}
            isDisabled={!canSubmit}
            isLoading={isLoading}
            onClick={saveOverrides}
          >
            {t('global.actions.save')}
            </Button>
          )
          }
        </ButtonGroup>
      </Flex>

      <Stack paddingLeft={4}>
        {gridItems.map((gridItem) => (
          <Flex
            key={gridItem.label}
            paddingRight={4}
            alignItems="center"
            borderBottom="1px solid"
            paddingY={4}
          >
            <Box flex={1} marginRight={8}>
              <FormLabelWithTooltip label={gridItem.label} tooltipText={gridItem.tooltipText} />

              <ColorPicker color={gridItem.color} setColor={gridItem.setColor} defaultColor={ defaultColor } />
            </Box>

            <Flex flex={1}>{gridItem.sampleElement}</Flex>
          </Flex>
        ))}
      </Stack>
    </AdminElevatedBox>
  );
}

ColorOverridesForm.defaultProps = {
  saveInForm: false,
  saveOverrides: null,
  defaultColor: null
}
