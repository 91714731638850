export const validateAndSetCheckboxValue = (value: string | string[], setFunction: (value: string[]) => void) => {
  if (!Array.isArray(value)) {
    const arrayValue: string[] = [value];
    setFunction(arrayValue);
    return;
  }
  setFunction(value);
}

export const validateRadioValue = (value: string | string[]) => {
  if (Array.isArray(value)) {
    const stringValue: string = value[0];
    return stringValue
  }
  return value;
}
