import { Box } from '@chakra-ui/react';
import CertificateCard from '../../data-display/cards/CertificateCard';
import DashboardService from '@/client/services/api/DashboardService';
import Loading from '@/client/components/media/Loading';
import PlaceholderImage from '@/client/components/media/images/PlaceholderImage';
import { useQuery } from '@tanstack/react-query';

export default function DashboardCertificatesList({ userId }: { userId: string }) {
  const {
    isLoading,
    isError,
    data: certificates,
  } = useQuery({
    queryKey: ['query-user-certificates', userId],
    queryFn: () => DashboardService.getUserCertificates(userId),
  });

  if (isLoading) return <Loading />;

  if (isError) {
    return <div>Error</div>;
  }

  if (!certificates.length)
    return (
      <Box padding={10} textAlign="center" fontSize="sm" color="gray.400">
        <PlaceholderImage name="bench" />
        You haven’t earned any certificates yet. <br />
        As you receive certificates you can find them here.
      </Box>
    );

  return (
    <>
      {certificates &&
        certificates.map((certificate) => (
          <CertificateCard key={certificate.title} userCertificate={certificate} />
        ))}
      {}
    </>
  );
}
