import { Button, ButtonGroup, Flex, useToken } from '@chakra-ui/react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import AdminPrimaryHeader from '../AdminPrimaryHeader';
import { PlusCircle } from '@/client/components/icons/ContinuIcons';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useAuthStore } from '@/client/services/state/authStore';
import { useTranslation } from 'react-i18next';

export default function AdminUsersLayout() {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { allowGeneralActivity, isAllowed, allowance } = useAccessHelper();
  const [brandButton] = useToken('colors', ['brand.button']);
  const transparentButtonBackground = hexToRGBA(brandButton, 0.2);

  const feedsEnabled = company.feature_flags.enable_feeds;
  const canAccessSettings = allowance.settings;
  const canAddUsers =
    isAllowed(['admin', 'users', 'add'], null, ['admin']) ||
    isAllowed(['admin', 'users', 'bulk_add'], null, ['admin']);

  const routeIsUsers =
    location.pathname === '/admin/users' ||
    location.pathname.includes('/admin/users/add') ||
    location.pathname.includes('/admin/users/edit');
  const routeIsAddSingleUser = location.pathname.includes('/admin/users/add/single');
  const routeIsFeeds = location.pathname.includes('/admin/users/feeds');
  const routeIsSeats = location.pathname === '/admin/users/seats';

  if (!allowGeneralActivity('users', ['admin'])) return <Navigate to="/explore" replace />;

  return (
    <>
      <AdminPrimaryHeader
        showSearch={false}
        tooltipText=""
        title={`${t('overviewUsers.userManagement')}`}
        bottomElement={
          <Flex width="full" justifyContent="space-between" alignItems="center" marginTop={4}>
            <ButtonGroup>
              <Button
                background={routeIsUsers ? transparentButtonBackground : 'none'}
                variant="adminTab"
                size="xs"
                onClick={() => navigate('/admin/users')}
              >
                {t('global.contentTypes.users')}
              </Button>

              {canAccessSettings && (
                <Button
                  background={routeIsSeats ? transparentButtonBackground : 'none'}
                  variant="adminTab"
                  size="xs"
                  onClick={() => navigate('/admin/users/seats')}
                >
                  {t('overview.settings.user_licenses')}
                </Button>
              )}

              {feedsEnabled && (
                <Button
                  background={routeIsFeeds ? transparentButtonBackground : 'none'}
                  variant="adminTab"
                  size="xs"
                  onClick={() => navigate('/admin/users/feeds')}
                >
                  {t('admin.users.sftpFeeds')}
                </Button>
              )}
            </ButtonGroup>

            {routeIsUsers && canAddUsers && !routeIsAddSingleUser && (
              <Button
                variant="adminPrimary"
                size="xs"
                leftIcon={<PlusCircle />}
                onClick={() => navigate('/admin/users/add')}
              >
                {t('manage.users.addUsers')}
              </Button>
            )}
          </Flex>
        }
      />

      <Outlet />
    </>
  );
}
