import { Box, Divider, Stack } from '@chakra-ui/react';
import { useComponentSize } from 'react-use-size';

import ProductAccessForm from './ProductAccessForm';
import ProductGeneralDetailsForm from './ProductGeneralDetailsForm';
import ProductSettingsForm from './ProductSettingsForm';
import { useFormContext } from 'react-hook-form';
import { Product } from '@/client/services/api/graphql/gql/graphql';
import BaseProductTag from './BaseProductTag';

export default function EditProductForm() {
  const { getValues } = useFormContext<Product>();
  const { ref: containerRef } = useComponentSize();

  return (
    <Stack>
      <Box ref={containerRef}>
        <Box overflowY="auto">
          {getValues('default') && (
            <BaseProductTag />
          )}
          <Stack spacing={6}>
            <ProductGeneralDetailsForm />
            <Divider variant="create" />
            {!getValues('default') && (
              <>
                <ProductSettingsForm />
                <Divider variant="create" />
              </>
            )}
            <ProductAccessForm />
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
}
