import type {
  CreateInstructorMutation,
  InstructorCreateInput,
  InstructorUpdateInput,
  SearchInstructorsQuery,
  UpdateInstructorMutation,
} from '@/client/services/api/graphql/gql/graphql';
import {
  createInstructor as createInstructorMutation,
  searchInstructors as searchInstructorsQuery,
  updateInstructor as updateInstructorMutation,
} from '@/client/services/api/graphql/queries/create/content/courses/instructors';

import { graphQLClient } from '@/client/services/api/clients/graphqlClient';

const createInstructor = async (payload: InstructorCreateInput): Promise<string> => {
  const response: CreateInstructorMutation = await graphQLClient.request(createInstructorMutation, {
    payload,
  });

  return response.createInstructor.id;
};

const searchInstructors = async (name: string): Promise<SearchInstructorsQuery> => {
  const data: SearchInstructorsQuery = await graphQLClient.request(searchInstructorsQuery, {
    name,
  });

  return data;
};

const updateInstructor = async (
  id: string,
  payload: InstructorUpdateInput,
): Promise<UpdateInstructorMutation> => {
  const response: UpdateInstructorMutation = await graphQLClient.request(updateInstructorMutation, {
    id,
    payload,
  });

  return response;
};

const AdminInstructorsService = {
  createInstructor,
  searchInstructors,
  updateInstructor,
};

export default AdminInstructorsService;
