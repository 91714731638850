export const deepSort = (name: string, sortType?: string) => {
  const multiplier = sortType === 'dec' ? -1 : 1;
  return (a: any, b: any) => {
    if (a[name] < b[name]) {
      return -1 * multiplier;
    }
    if (a[name] > b[name]) {
      return 1 * multiplier;
    }
    return 0;
  };
};
