/* eslint react/no-unstable-nested-components: 0 */

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { IoBanOutline, IoTimeOutline } from 'react-icons/io5';
import { useMemo, useState } from 'react';

import AdminUsersService from '@/client/services/api/admin/users/AdminUsersService';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { PendingUser } from '@/client/types/admin/user-manager/AdminUsers';
import UserCell from '../../tables/cells/UserCell';
import UserSearch from '@/client/components/input/search/UserSearch';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useAdminUsersSearch } from '@/client/services/hooks/admin/users/useAdminUsersSearch';
import { useFormatDate } from '@/client/services/hooks/date-and-time/useFormatDate';
import { useQuery } from '@tanstack/react-query';
import { useReinviteUsers } from '@/client/services/hooks/admin/users/useReinviteUsers';
import { useSuspendUsers } from '@/client/services/hooks/admin/users/useSuspendUsers';
import { useTranslation } from 'react-i18next';

export default function PendingUsers() {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();
  const { isAllowed } = useAccessHelper();
  const { suspendUsersMutation } = useSuspendUsers();
  const { reinviteUsersMutation } = useReinviteUsers();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>({});
  const suspendAllowed = isAllowed(['admin', 'users', 'suspend'], null, ['admin']);

  const {
    handleCreateItem,
    selectedItems,
    setSelectedItems,
    setSearchTerm,
    searchTerm,
    handleSelectedItemsChange,
    comboboxOptions,
    searchFromQueryMutation,
    searchPagination,
    setSearchPagination,
  } = useAdminUsersSearch('pending');

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, refetch } = useQuery({
    queryKey: ['pendingUsers', fetchDataOptions],
    queryFn: () => AdminUsersService.getPendingUsers(fetchDataOptions),
  });

  const suspendUsers = () =>
    suspendUsersMutation
      .mutateAsync(rowSelection)
      .then(() => refetch())
      .finally(() => setRowSelection({}));

  const reinviteUsers = () =>
    reinviteUsersMutation
      .mutateAsync(rowSelection)
      .then(() => refetch())
      .finally(() => setRowSelection({}));

  const reinviteAllUsers = () =>
    reinviteUsersMutation
      .mutateAsync({})
      .then(() => refetch())
      .finally(() => {
        setRowSelection({});
        onClose();
      });

  const columnHelper = createColumnHelper<PendingUser>();
  const columns = useMemo<ColumnDefBase<PendingUser, any>[]>(
    () => [
      columnHelper.display({
        id: 'checkbox',
        cell: (info) => (
          <Flex alignItems="center">
            <Checkbox
              marginLeft={9}
              variant="admin"
              onChange={info.row.getToggleSelectedHandler()}
              isChecked={info.row.getIsSelected()}
            />
          </Flex>
        ),
        header: (info) => (
          <Checkbox
            marginLeft={9}
            variant="admin"
            onChange={info.table.getToggleAllRowsSelectedHandler()}
            isChecked={info.table.getIsAllRowsSelected()}
          />
        ),
      }),
      columnHelper.accessor('full_name', {
        cell: (info) => (
          <UserCell
            name={info.row.original.full_name}
            image={info.row.original.image}
            id={info.row.original._id}
          />
        ),
        header: () => <div>{t('global.forms.labels_name')}</div>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('global.forms.labels_email')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => (
          <Box>
            {info.getValue() ? formatDate(new Date(info.getValue()), 'baseWithTime') : '----'}
          </Box>
        ),
        header: () => <span>{t('inviteManager.invitation_sent')}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    [],
  );

  return (
    <Box>
      <ButtonGroup size="xs" marginBottom={4}>
        <Button variant="adminPrimary" leftIcon={<IoTimeOutline />} onClick={onOpen}>
          {t('inviteManager.remind_all')}
        </Button>

        {isAllowed(['admin', 'users', 'password_reset'], null, ['admin']) && (
          <Button
            variant="adminPrimary"
            leftIcon={<IoTimeOutline />}
            isDisabled={!rowSelection || Object.keys(rowSelection).length === 0}
            onClick={reinviteUsers}
          >
            {t('inviteManager.remind')}
          </Button>
        )}

        {suspendAllowed && (
          <Button
            variant="adminWarning"
            leftIcon={<IoBanOutline />}
            isDisabled={!rowSelection || Object.keys(rowSelection).length === 0}
            onClick={suspendUsers}
          >
            {t('global.action.suspend')}
          </Button>
        )}
      </ButtonGroup>

      <Box marginBottom={4}>
        <UserSearch
          variant="admin"
          placeholder={t('modules.usersearch.placeholder')}
          onCreateItem={handleCreateItem}
          items={comboboxOptions || []}
          selectedItems={selectedItems}
          setSearchValue={setSearchTerm}
          searchValue={searchTerm}
          handleClearAll={() => setSelectedItems([])}
          onSelectedItemsChange={(changes: any) => {
            handleSelectedItemsChange(changes.selectedItems, changes.type);
          }}
        />
      </Box>

      <ManuallyPaginatedTable
        columns={columns}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        queryData={selectedItems.length > 0 ? searchFromQueryMutation.data : data}
        pagination={selectedItems.length > 0 ? searchPagination : pagination}
        setPagination={selectedItems.length > 0 ? setSearchPagination : setPagination}
        showCheckbox
      />

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody padding={12}>
            <VStack textAlign="center" spacing={4}>
              <Text fontWeight="bold" fontSize="xl">
                {t('inviteManager.remind_users')}
              </Text>

              <Text>{t('inviteManager.this_will_send_reminders')}</Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup size="sm">
              <Button variant="adminPrimary" onClick={reinviteAllUsers}>
                {t('inviteManager.send_confirm')}
              </Button>

              <Button variant="adminCancel" onClick={onClose}>
                {t('inviteManager.send_deny')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
