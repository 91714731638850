import { NavigateFunction, NavigateOptions, To, useNavigate } from 'react-router-dom';

import { preAuthApiClient } from '../services/api/clients/preAuthApiClient';
import { successfulLoginActions } from '../services/hooks/preauth/useLogin';
import { useAuthStore } from '@/client/services/state/authStore';
import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useToastStore } from '../services/state/toastStore';
import { useTranslation } from 'react-i18next';

const checkAuth = async (authToken: string) => {
  try {
    const response = await preAuthApiClient.get('/v3/authtoken', {
      headers: {
        Authorization: `AuthToken token=${authToken}`,
      },
    });
    if (response?.data?.company?.suspended) {
      return { result: false, data: null, navigate: '/suspended' };
    }
    return { result: true, data: response.data };
  } catch (e: any) {
    console.log('catching error', e);
    return { result: false, data: null };
  }
};

const navigateSamlFactory =
  (navigate: NavigateFunction): NavigateFunction =>
  (to: To | Number, options?: NavigateOptions): void => {
    if (window.name === 'childsso') {
      window.opener.location.href = to;
      window.close();
      return;
    }
    navigate(to as To, options);
  };

export default function SamlTransition() {
  const { authConfig, setAuthConfig, logout } = useAuthStore();
  const [redirectLocation] = useLocalStorage('redirectLocation', null);
  const [angularRedirectLocation] = useLocalStorage('preRedirect', null);
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { t } = useTranslation();

  useEffect(() => {
    const url: URL = new URL(window.location.href);
    const params: URLSearchParams = url.searchParams;
    const navigateSamlHelper = navigateSamlFactory(navigate);

    if (
      params.get('authtoken') &&
      params.get('user') &&
      params.get('ttl') &&
      params.get('createdOn')
    ) {
      const tmp = async () => {
        const resultObj = await checkAuth(params.get('authtoken')!);
        if (!resultObj.result) {
          setToast({
            show: true,
            status: 'error',
            title: t('saml.errors.unkown-origin'),
          });
          navigateSamlHelper('/login');
          return;
        }

        successfulLoginActions(
          resultObj.data,
          authConfig,
          setAuthConfig,
          angularRedirectLocation,
          redirectLocation,
          navigateSamlHelper,
        );
      };
      tmp();
    } else {
      logout();
      navigateSamlHelper('/login');
    }
  }, []);
  return <div />;
}
