import { Text, Checkbox, NumberInput, NumberInputField, HStack, Flex } from '@chakra-ui/react';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import { useTranslation } from 'react-i18next';

interface ValidationRuleEditorProps {
  dataType: string;
  validationRules: Record<string, any> | null;
  validationRuleUpdater: (rule: string, value: string | boolean) => void;
}

export default function ValidationRuleEditor({
  dataType,
  validationRules,
  validationRuleUpdater,
}: ValidationRuleEditorProps) {
  const { t } = useTranslation();

  return (
    <Flex direction="column">
      {dataType !== 'boolean' && (
        <FormLabelWithTooltip label={t('customFields.label.customFieldPermissions')} isRequired />
      )}
      {dataType === 'number' && (
        <HStack>
          <Text fontSize="sm" fontWeight="500">
            {t('customFields.label.minValue')}
          </Text>
          <NumberInput
            width="10%"
            size="sm"
            marginRight={4}
            value={validationRules?.min || ''}
            onChange={(value) => {
              validationRuleUpdater('min', value);
            }}
          >
            <NumberInputField textOverflow="ellipsis" borderRadius="7px" />
          </NumberInput>
          <Text fontSize="sm" fontWeight="500">
            {t('customFields.label.maxValue')}
          </Text>
          <NumberInput
            width="10%"
            size="sm"
            value={validationRules?.max || ''}
            onChange={(value) => {
              validationRuleUpdater('max', value);
            }}
          >
            <NumberInputField borderRadius="7px" />
          </NumberInput>
        </HStack>
      )}
      {dataType === 'string' && (
        <HStack>
          <Text fontSize="sm" fontWeight="500">
            {t('customFields.label.minLength')}
          </Text>
          <NumberInput
            width="10%"
            size="sm"
            marginRight={1}
            value={validationRules?.minLength || ''}
            onChange={(value) => {
              validationRuleUpdater('minLength', value);
            }}
          >
            <NumberInputField borderRadius="7px" />
          </NumberInput>
          <Text margin={1} fontSize="sm" fontWeight="500">
            {t('customFields.label.maxLength')}
          </Text>
          <NumberInput
            width="10%"
            size="sm"
            marginRight={1}
            value={validationRules?.maxLength || ''}
            onChange={(value) => {
              validationRuleUpdater('maxLength', value);
            }}
          >
            <NumberInputField borderRadius="7px" />
          </NumberInput>
          <Text fontSize="sm" fontWeight="500" margin={2}>
            {t('customFields.label.noNumbers')}
          </Text>
          <Checkbox
            size="md"
            variant="admin"
            isChecked={validationRules?.noNumbers || false}
            onChange={() => {
              validationRuleUpdater('noNumbers', !validationRules?.noNumbers);
            }}
          />
          <Text fontSize="sm" fontWeight="500" margin={2}>
            {t('customFields.label.noSymbols')}
          </Text>
          <Checkbox
            size="md"
            variant="admin"
            isChecked={validationRules?.noSymbols || false}
            onChange={() => {
              validationRuleUpdater('noSymbols', !validationRules?.noSymbols);
            }}
          />
        </HStack>
      )}
    </Flex>
  );
}
