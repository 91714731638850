import { Divider, Flex, Text } from '@chakra-ui/react';
import OutlineButton from '@/client/components/shared/buttons/OutlineButton'
import { useNavigate } from 'react-router-dom';
import { cardBrandIcons } from '@/client/components/icons/getCardBrandIcon';
import { useTranslation } from 'react-i18next';

type ChangeSubscriptionPaymentProps = {
  cardBrand: string;
  lastFour: string; 
  originalSubscriptionId: string;
  newProductId: string;
}

export default function ChangeSubscriptionPayment({cardBrand, lastFour, originalSubscriptionId, newProductId}: ChangeSubscriptionPaymentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Flex direction="row" gap="5%" alignItems="center">
        <Text fontSize="sm" fontWeight="500" textAlign="left" width="150px" marginY={5}>
          {t('ecommerce.checkout.paymentMethod')}:
        </Text>
        <Flex direction={{ base: "column", xl: "row"}} width="70%" justifyContent="space-between">
          <Flex marginTop={1}>
            {cardBrandIcons[cardBrand] || cardBrand}
            <Text ml={4}>*{lastFour}</Text>
          </Flex>
          <OutlineButton marginTop={2} variant="createOutline" onClick={() => navigate(`/subscription/${originalSubscriptionId}/add-new-payment/${newProductId}`)}>
            {t('ecommerce.checkout.updatePaymentMethod')}
          </OutlineButton>
        </Flex>
      </Flex>

      <Divider marginY={4} borderTop=".25px solid" opacity="20%" />
    </>
  )
}
