import { Box, Center, Icon, Input, Text, VStack } from '@chakra-ui/react';
import { useCallback, useRef } from 'react';

import type { DropTargetMonitor } from 'react-dnd';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useDrop } from 'react-dnd';
import { useFormContext } from 'react-hook-form';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface ContentImageDropzoneProps {
  setImage: (image: string) => void;
  type: 'banner' | 'thumbnail-card' | 'thumbnail-list';
  height?: number | string;
}

export default function AuthoringImageDropzone({
  setImage,
  type,
  height = '175px',
}: ContentImageDropzoneProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { watch } = useFormContext();
  const { previewFullscreen } = useCreateStore();
  const imageInputRef = useRef<HTMLInputElement>(null);

  const bannerImage = watch('bannerImage');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;

    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({ show: true, status: 'error', title: t('authoring.imageUploadError') });
      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    setImage(URL.createObjectURL(fileObject));
  };

  const handleUploadClick = () => (imageInputRef.current ? imageInputRef.current.click() : null);

  const handleFileDrop = useCallback(
    (item: { files: any[] }) => {
      if (item) {
        const { files } = item;

        setImage(URL.createObjectURL(files[0]));
      }
    },
    [setImage],
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        handleFileDrop(item);
      },
      canDrop() {
        return true;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [handleFileDrop],
  );

  const isActive = canDrop && isOver;

  const setDropzoneStyles = () => {
    switch (type) {
      case 'banner':
        return {
          paddingX: bannerImage || previewFullscreen ? 0 : 6,
          paddingY: 2,
          backgroundColor: isActive ? 'neutral.50' : 'white',
          border: '2px dashed',
          borderColor: 'warmNeutral.400',
          borderRadius: '6px',
          height: `${height}px`,
          maxHeight: '400px',
          textAlign: 'center',
        };
      case 'thumbnail-card':
        return {
          paddingX: 6,
          paddingY: 2,
          borderRadius: 'md',
          backgroundColor: isActive ? 'neutral.50' : 'transparent',
          border: '2px dashed',
          borderColor: 'white',
          textAlign: 'center',
          color: 'white',
        };
      case 'thumbnail-list':
        return {
          marginX: 3,
          borderRadius: '6px',
          height: '125px',
          backgroundColor: isActive ? 'neutral.50' : 'transparent',
          border: '2px dashed',
          borderColor: 'warmNeutral.400',
          textAlign: 'center',
          color: 'brand.legibleBlack.100',
        };
      default:
        return {
          paddingX: 6,
          paddingY: 2,
          borderRadius: 'md',
          backgroundColor: isActive ? 'neutral.50' : 'brand.gold.20',
          border: '2px dashed',
          borderColor: 'warmNeutral.400',
        };
    }
  };

  return (
    <Box
      ref={drop}
      sx={{ ...setDropzoneStyles() }}
      _hover={{
        cursor: 'pointer',
        backgroundColor: type === 'thumbnail-card' ? 'neutral.1200' : 'neutral.0',
      }}
    >
      <Center height="full">
        <VStack spacing={1} fontSize="xs">
          <Icon
            as={IoCloudUploadOutline}
            color={type === 'thumbnail-card' ? 'white ' : 'warmNeutral.400'}
            boxSize={10}
            onClick={handleUploadClick}
          />

          <Input
            display="none"
            ref={imageInputRef}
            type="file"
            accept="image/png, image/jpeg"
            onChange={(e) => handleFileChange(e)}
          />

          <Text>
            {t('authoring.images.dragAndDrop')}{' '}
            <Text
              as="span"
              _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
              color="warmNeutral.1000"
              onClick={handleUploadClick}
            >
              {t('authoring.images.browse')}
            </Text>{' '}
            {t('authoring.images.toChooseImage')}
          </Text>
        </VStack>
      </Center>
    </Box>
  );
}
