import { ArrowRight } from '@/client/components/icons/ContinuIcons';
import { Box, Button, Center, Flex, Text, VStack } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { type Product as ProductType } from '@/client/services/api/graphql/gql/graphql';
import AnalyzeService from '@/client/services/api/AnalyzeService';
import ContentService from '@/client/services/api/ContentService';
import CollapsableHtmlRenderer from '../../../components/html/CollapsableHtmlRenderer';
import ContentHeader from '../../../components/layout/ContentHeader';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import Loading from '@/client/components/media/Loading';
import { useAuthStore } from '@/client/services/state/authStore';
import useDocumentTitle from '../../../utils/useDocumentTitle';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { useGetProduct } from '@/client/services/hooks/content/products/useGetProduct';
import { getCurrencySign, AccessList } from '@/client/services/api/graphql/gql-utils';
import { ProductLicensingType } from '@/client/services/api/graphql/gql/graphql';
import ProductAccessList from '@/client/components/lists/ecommerce/ProductAccessList';
import { capCase } from '@/client/utils/capCase';
import { useComponentSize } from 'react-use-size';
import SolidButton from '@/client/components/shared/buttons/SolidButton';

interface ProductProps {
  isPreview?: boolean;
  previewData?: ProductType;
}

export default function Product({ isPreview = false, previewData = undefined }: ProductProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const routeParams = useParams();
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { ref: imageRef, height: imageHeight } = useComponentSize();
  const heroRef = useRef<HTMLDivElement>(null);

  const id = routeParams.id as string;

  if (!company.feature_flags.enable_ecommerce) {
    setToast({
      show: true,
      status: 'error',
      title: 'E-Commerce is unavailable for the company.',
    });
    navigate('/explore');
  }

  const { data: fetchedProduct, isLoading, isError } = useGetProduct(id);
  const data = isPreview ? previewData : fetchedProduct;
  useDocumentTitle(data ? data.title : 'Product', true);

  const accessList: AccessList = {};
  if (data?.contentListDetails && data.contentListDetails.length > 0) {
    accessList.content = data.contentListDetails;
  }

  useEffect(() => {
    if (data !== null) {
      AnalyzeService.postViews(id, 'product');
      ContentService.postViews(id, 'product');
    }
  }, [data]);

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title={t('products.unableToGet')} />;

  return (
    data && (
      <>
        <Flex
          height={{
            base: '300px',
            md: '400px',
            lg: '500px',
          }}
          width="full"
          position="relative"
          ref={heroRef}
        >
          <Box
            w="100%"
            h="100%"
            background="black"
            position="absolute"
            top="0"
            left="0"
            zIndex="3"
            opacity="0.4"
          />
          <Box
            width="full"
            height="full"
            bgColor="brand.primary"
            backgroundImage={`url(${data.images?.length ? data.images[0] : ''})`}
            backgroundSize="cover"
            backgroundPosition="center center"
            ref={imageRef}
          >
            {!isPreview && (
              <ContentHeader
                contentType="product"
                contentTitle={data.title}
                contentId={id}
                heroRef={heroRef}
                navigateAction={
                  location.state?.originRoute
                    ? () => navigate(location.state.originRoute)
                    : () => navigate('/explore')
                }
              />
            )}
            {isPreview && (
              <Box
                position="fixed"
                top="50px"
                left="50px"
                width="100%"
                backgroundColor="white"
                zIndex="10"
                height={50}
              >
                <Flex padding={4} flex="1" background="white" height={50}>
                  <Box display={{ base: 'none', md: 'flex' }} width="50%" alignItems="center">
                    <Text
                      fontWeight="bold"
                      maxWidth="full"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      color="black"
                      marginTop="auto"
                    >
                      {data.title}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            )}
            <Center width="full" marginTop={{ base: 6, md: 24 }} position="relative" zIndex="5">
              <VStack spacing={4} marginTop={imageHeight / 8 + 50}>
                <Text variant="heroheader" color="white" marginBottom="0">
                  {data.title}
                </Text>
                <Box>
                  <SolidButton
                    rightIcon={<ArrowRight />}
                    marginY={4}
                    paddingY={4}
                    paddingX={8}
                    backgroundColor="brand.button"
                    _hover={{
                      backgroundColor: 'brand.button',
                    }}
                    onClick={isPreview ? () => {} : () => navigate(`/product/${id}/payment-method`)}
                  >
                    {`${t('products.purchase')} - ${getCurrencySign(data.currency || '')}${
                      data.price
                    }`}
                  </SolidButton>
                </Box>
              </VStack>
            </Center>
          </Box>
        </Flex>

        <Center width={{ base: '100%', md: '70%' }} margin="0 auto" marginTop={8}>
          <Flex direction={{ base: 'column-reverse', lg: 'row' }}>
            <Box width={{ base: '100%', lg: '50%' }}>
              <ProductAccessList access={accessList} isPreview={isPreview} />
              <Box paddingY={6}>
                <CollapsableHtmlRenderer html={data.description} />
              </Box>
            </Box>

            <Flex
              width={{ base: '100%', lg: '50%' }}
              justifyContent={{ base: 'center', lg: 'flex-end' }}
              alignSelf="flex-start"
            >
              <Box
                backgroundColor="blackAlpha.50"
                width="70%"
                marginBottom={10}
                paddingY={12}
                paddingX={4}
                display="flex"
                textAlign="left"
              >
                <VStack spacing={4} alignItems="left">
                  <Box paddingX={2}>
                    <Text as="span" fontWeight="bold">{`${t('products.licensingType')}:  `}</Text>
                    <Text as="span" fontWeight="medium">
                      {capCase(data.licensingType)}
                    </Text>
                  </Box>
                  {data.licensingType === ProductLicensingType.Subscription &&
                    data.subscriptionDuration && (
                      <Box paddingX={2}>
                        <Text as="span" fontWeight="bold">{`${t(
                          'products.subscriptionDuration',
                        )}:  `}</Text>
                        <Text as="span" fontWeight="medium">
                          {capCase(data.subscriptionDuration)}
                        </Text>{' '}
                      </Box>
                    )}
                  <Box paddingX={2}>
                    <Text as="span" fontWeight="bold">{`${t('overviewProducts.price')}:  `}</Text>
                    <Text as="span" fontWeight="medium">{`${getCurrencySign(data.currency || '')}${
                      data.price
                    }`}</Text>
                  </Box>
                </VStack>
              </Box>
            </Flex>
          </Flex>
        </Center>
      </>
    )
  );
}
