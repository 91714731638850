import {
  ArrowRight,
  Delete,
  Upload,
} from '@/client/components/icons/ContinuIcons';
import {
  Assessment,
  UserAssessment,
} from '@/client/types/content/assessments/Assessment';
import {
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Input,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';

import AssessmentService from '@/client/services/api/AssessmentService';
import HtmlRenderer from '@/client/components/html/HtmlRenderer';
import axios from 'axios';
import { useAuthStore } from '@/client/services/state/authStore';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import v3ApiService from '@/client/services/api/clients/v3ApiClient';

interface VideoAssessmentProps {
  assessment: Assessment;
  userAssessment: UserAssessment;
  setRetakingAssessment: (value: boolean) => void;
  handleCompleteContent: Function;
}

interface QuestionSettings {
  choose_attempt: boolean;
  manual_grade: boolean;
  manual_points: number;
  question_value_is_custom: boolean;
  recording_limit: number;
  recording_type: string;
  upload_type: string;
}

export default function FileUploadAssessment({
  assessment,
  userAssessment,
  setRetakingAssessment,
  handleCompleteContent,
}: VideoAssessmentProps) {
  const { authConfig } = useAuthStore();
  const user = authConfig.user;
  const queryClient = useQueryClient();
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<
    { link: string; title: string }[] | null
  >(null);
  const allowedFileTypes = assessment.questions[0].question_options.find(
    (item) => item.name === 'upload_type'
  )?.value;

  const onSubmitAssessment = useMutation({
    mutationFn: () =>
      AssessmentService.sendInProgressResponses(
        userAssessment._id,
        assessment.questions[0].id,
        selectedFile
      ),
    onSuccess: () => {
      handleCompleteContent().then(() => {
        queryClient.invalidateQueries([
          'user-content-context', { contextId: userAssessment._id },
        ]);
        setRetakingAssessment(false);
      });
    },
    onError: (error) => console.log('error', error),
  });

  const handleFileUploadClick = () =>
    fileInputRef.current ? fileInputRef.current.click() : null;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.size > 500000000) {
        setToast({
          show: true,
          status: 'error',
          title: 'Error. This file exceeds the 500mb limit.',
        });
        return;
      }

      v3ApiService
        .getS3FilePolicy(file.type, file.name)
        .then((policy) => {
          let formData = new FormData();

          formData.append('key', policy.Key);
          formData.append('Content-Type', policy.ContentType);
          formData.append('AWSAccessKeyId', policy.AWSAccessKeyId);
          formData.append('success_action_status', '201');
          formData.append('policy', policy.S3Policy);
          formData.append('signature', policy.S3Signature);
          formData.append('file', file);

          setFileUploading(true);

          axios
            .post('https://' + policy.Bucket + '.s3.amazonaws.com/', formData)
            .then(() => {
              if (!policy.CloudFrontLink) return;

              setFileUploading(false);
              setSelectedFile([
                { link: policy.CloudFrontLink, title: file.name },
              ]);
            })
            .catch((err) => {
              console.log(err);
              setToast({
                show: true,
                status: 'error',
                title: 'Error uploading file',
              });
            });
        })
        .catch((err) => {
          console.log(err);
          setToast({
            show: true,
            status: 'error',
            title: 'Error uploading file',
          });
        });
    }
  };

  const setAllowedFileTypes = () => {
    const options = ['pdf', 'doc', 'xls', 'jpeg', 'png'];
    const acceptMap = {
      pdf: 'application/pdf',
      doc: '.txt,.doc,.docx',
      xls: 'application/excel,application/vnd.ms-excel,application/vnd.msexcel,.xls,.xlsx',
      jpeg: 'image/jpeg',
      png: 'image/png',
    };

    const allowedFileTypesArray = allowedFileTypes?.toString().split(',');

    console.log('Allowed file types arr: ', allowedFileTypesArray);

    let accept = '';

    options.forEach(function (opt) {
      //@ts-ignore
      if (allowedFileTypesArray.indexOf(opt) !== -1 && acceptMap[opt]) {
        if (accept) {
          accept += ',';
        }
        //@ts-ignore
        accept += acceptMap[opt];
      }
    });

    return accept;
  };

  useDocumentTitle('View Assessment');

  return (
    <Box height="100vh">
      <Center>
        <Box
          marginTop={12}
          backgroundColor="white"
          width={{ base: 'full', lg: 'container.md' }}
          padding={12}
          boxShadow="sm"
        >
          <Text as="h4">{assessment.questions[0].question}</Text>

          {!selectedFile && !fileUploading && (
            <Box backgroundColor="brand.primary" padding={12} borderRadius={4}>
              <VStack spacing={6}>
                <Upload color="white" />

                <Text color="white">{t('edit.files.max_500')}</Text>

                <Input
                  display="none"
                  type="file"
                  ref={fileInputRef}
                  accept={setAllowedFileTypes()}
                  onChange={(e) => handleFileChange(e)}
                />

                <Button variant="outline" onClick={handleFileUploadClick}>
                  {t('edit.files.click_file')}
                </Button>

                <Text color="white">
                  {t('edit.files.supported_type_specific')}
                  {allowedFileTypes
                    ?.toString()
                    .split(',')
                    .join(', ')
                    .toUpperCase()}
                </Text>
              </VStack>
            </Box>
          )}

          {fileUploading && (
            <Center marginY={8}>
              <Spinner />
            </Center>
          )}

          {selectedFile && (
            <VStack spacing={6}>
              <HStack>
                <Text>
                  Selected File:{' '}
                  <Text as="span" fontWeight="bold" color="brand.primary">
                    {selectedFile[0].title}
                  </Text>
                </Text>

                <Tooltip hasArrow label="Remove">
                  <span>
                    <Delete
                      color="red.400"
                      _hover={{ cursor: 'pointer', color: 'red.500' }}
                      onClick={() => setSelectedFile(null)}
                    />
                  </span>
                </Tooltip>
              </HStack>

              <Button
                isLoading={isLoading}
                rightIcon={<ArrowRight />}
                onClick={() => onSubmitAssessment.mutate()}
              >
                {t('global.actions.submit')}
              </Button>
            </VStack>
          )}
          <Box marginTop={8}>
            <HtmlRenderer html={assessment.description} />
          </Box>
        </Box>
      </Center>
    </Box>
  );
}
