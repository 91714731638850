import './BgImagesSlider.scss';

import Segmentation from '@/client/components/admin/forms/users/Segmentation';
import HorizontalAnchorMenu from '@/client/components/admin/navigation/HorizontalAnchorMenu';
import {
  AngleRight,
  AngleLeft,
  ArrowLeft,
  QuestionCircle,
  Upload,
} from '@/client/components/icons/ContinuIcons';
import PartnersHeader from '@/client/components/layout/PartnersHeader';
import PartnersService from '@/client/services/api/admin/PartnersService';
import v3ApiService from '@/client/services/api/clients/v3ApiClient';
import { useAuthStore } from '@/client/services/state/authStore';
import { useToastStore } from '@/client/services/state/toastStore';
import { CreateUpdatePartner } from '@/client/types/Partner';
import { ColorOverrides } from '@/client/types/Theme';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  NumberInput,
  NumberInputField,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Element } from 'react-scroll';
import PartnerColorOverrides from '../admin/theme/PartnerColorOverrides';
import Slider from 'react-slick';
import { useLazyLoadStylesheet } from '@/client/services/hooks/useLazyLoad';
import ColorPicker from '@/client/components/admin/theme/ColorPicker';
import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';

const freshColors = {
  anchors: '',
  buttons: '',
  headers: '',
  highLights: '',
  links: '',
  formInputs: '',
};

export default function PartnersEditor() {
  const routeParams = useParams();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const logoInputRef = useRef<HTMLInputElement>(null);
  const bgImgInputRef = useRef<HTMLInputElement>(null);
  const [logo, setLogo] = useState<string>('');
  const [backgroundImages, setBackgroundImages] = useState<string[]>([]);
  const [colorReset, setColorReset] = useState<ColorOverrides>(freshColors);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToast } = useToastStore();

  const carouselSettings = {
    speed: 500,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <AngleRight />,
    prevArrow: <AngleLeft />,
    dots: true,
  };
  useLazyLoadStylesheet(
    'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css',
  );
  useLazyLoadStylesheet(
    'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css',
  );

  if (!company.feature_flags.enable_partners) {
    navigate('/explore');
  }

  const methods = useForm<CreateUpdatePartner>({
    mode: 'onChange',
    defaultValues: routeParams.id
      ? async () => {
          const partner = await PartnersService.getPartner(routeParams.id!);
          setColorReset(partner.branding.overrides || freshColors);
          return partner;
        }
      : { active: false },
  });

  const validateAndSetSeatCount = (seatCount: string | number) => {
    const seatCountString = String(seatCount).replaceAll(/[^0-9]/g, '');
    return Number(seatCountString);
  };

  const handleBackgroundImageInputClick = () => {
    bgImgInputRef.current!.click();
  };

  const addToBackgroundImages = (newImage: string) => {
    const bgImagesCopy = backgroundImages.slice();
    bgImagesCopy.unshift(newImage);
    setBackgroundImages(bgImagesCopy);
    methods.setValue('branding.background_image_urls', bgImagesCopy);
  };

  const handleLogoInputClick = () => {
    logoInputRef.current!.click();
  };

  const saveImageFile = (file: File, setImage: (img: string) => void) => {
    // const currentImage = imgRef.current.getInstance();

    // const dataURItoBlob = (dataURI: string) => {
    //   const binary = window.atob(dataURI.split(',')[1]);
    //   const array = [];

    //   for (let i = 0; i < binary.length; i++) {
    //     array.push(binary.charCodeAt(i));
    //   }

    //   return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    // };

    // const dataUrl = currentImage.toDataURL();

    // const file = new File([dataURItoBlob(dataUrl)], 'uploaded-img.jpeg');

    v3ApiService
      .getS3PartnerImagePolicy(file.type, file.name, routeParams.id!)
      .then((policy) => {
        const formData = new FormData();

        formData.append('key', policy.Key);
        formData.append('Content-Type', policy.ContentType);
        formData.append('AWSAccessKeyId', policy.AWSAccessKeyId);
        formData.append('success_action_status', '201');
        formData.append('policy', policy.S3Policy);
        formData.append('signature', policy.S3Signature);
        formData.append('file', file);

        axios
          .post('https://' + policy.Bucket + '.s3.amazonaws.com/', formData)
          .then(() => {
            if (!policy.CloudFrontLink) return;

            setImage(policy.CloudFrontLink);
          })
          .catch((err) => {
            console.log(err);
            setToast({
              show: true,
              status: 'error',
              title: t('modules.notifications.canNotUpdateProfile'),
            });
          });
      })
      .catch((err) => {
        console.log(err);
        setToast({
          show: true,
          status: 'error',
          title: t('modules.notifications.canNotUpdateProfile'),
        });
      });
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: 'logo' | 'backgroundImage',
  ) => {
    let fileObject;
    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({ show: true, status: 'error', title: 'Error uploading image' });
      return;
    }

    event.target.value = '';

    if (imageType === 'logo') {
      saveImageFile(fileObject, setLogo);
    }

    if (imageType === 'backgroundImage') {
      saveImageFile(fileObject, addToBackgroundImages);
    }
  };

  useEffect(() => {
    if (typeof logo === 'string' && logo.length) {
      methods.setValue('branding.logo_url', logo);
    }
  }, [logo]);

  const currentLogo = useWatch({ control: methods.control, name: 'branding.logo_url' });
  const currentBGImages = useWatch({
    control: methods.control,
    name: 'branding.background_image_urls',
  });

  type CreateProps = {
    partnerBody: CreateUpdatePartner;
  };

  const createPartner = useMutation({
    mutationFn: ({ partnerBody }: CreateProps) => PartnersService.createPartner(partnerBody),
    onSuccess: () => {
      setToast({ show: true, status: 'success', title: t('partners.admin.save_success') });
      !routeParams.id && navigate(`/admin/extend`);
    },
    onError: () => {
      setToast({ show: true, status: 'error', title: t('partners.admin.save_error') });
    },
  });

  type UpdateProps = { partnerId: string; partnerBody: CreateUpdatePartner };

  const updatePartner = useMutation({
    mutationFn: ({ partnerId, partnerBody }: UpdateProps) =>
      PartnersService.updatePartner(partnerId, partnerBody),
    onSuccess: () => {
      setToast({ show: true, status: 'success', title: t('partners.admin.save_success') });
      setColorReset(methods.getValues('branding.overrides'));
    },
    onError: () => {
      setToast({ show: true, status: 'error', title: t('partners.admin.save_error') });
    },
  });

  const onSubmit: SubmitHandler<CreateUpdatePartner> = async (data: CreateUpdatePartner) => {
    const partnerBody = {
      ...data,
    };
    routeParams.id
      ? updatePartner.mutate({ partnerId: routeParams.id, partnerBody })
      : createPartner.mutate({ partnerBody });
  };

  const copyContent = async (toCopy: string) => {
    try {
      await navigator.clipboard.writeText(toCopy);
      setToast({ show: true, status: 'success', title: t('Copied!') });
    } catch (err) {
      setToast({ show: true, status: 'error', title: t('Failed to copy.') });
    }
  };

  const tabsData = [
    {
      name: 'Details',
      linkName: 'details',
    },
    {
      name: 'Branding',
      linkName: 'branding',
    },
    {
      name: 'Theme Colors',
      linkName: 'theme',
    },
    {
      name: 'Segmentation',
      linkName: 'segmentation',
    },
  ];

  return (
    <Box minHeight="100vh" paddingTop={2} backgroundColor="brand.mist">
      {/* Header */}
      <PartnersHeader />
      <FormProvider {...methods}>
        {/* Back/Save shelf */}
        <AdminElevatedBox>
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="adminPrimary"
              padding={3}
              height={6}
              width="fit-content"
              borderRadius="12px"
              fontSize="sm"
              onClick={() => {
                navigate(`/admin/extend`);
              }}
            >
              <ArrowLeft marginRight={2} />
              {t('global.actions.back')}
            </Button>

            <Button
              variant="adminPrimary"
              padding={3}
              height={6}
              width="fit-content"
              borderRadius="12px"
              fontSize="sm"
              backgroundColor="green"
              _hover={{ background: '' }}
              isDisabled={!methods.formState.isValid}
              onClick={methods.handleSubmit(onSubmit)}
            >
              <Upload marginRight={2} />
              {t('global.actions.save')}
            </Button>
          </Flex>
        </AdminElevatedBox>
        <HorizontalAnchorMenu alignment="center" tabs={tabsData} />

        {/* Details */}
        <Element name="details">
          <AdminElevatedBox>
            <Text marginTop={0} marginBottom={2} as="h4">
              {t('edit.general.sidebar_details')}
              <Tooltip hasArrow label={t('edit.general.sidebar_details')}>
                <QuestionCircle marginLeft={1} color="blackAlpha.500" />
              </Tooltip>
            </Text>
            <Box padding={4}>
              <Flex direction="row" justifyContent="space-between">
                <Flex direction="column" width="50%" marginRight={6}>
                  <Text mb="8px">{t('partners.editor.name')}</Text>
                  <Controller
                    control={methods.control}
                    name="name"
                    render={({ field: { onChange, value } }) => (
                      <Input
                        value={value}
                        onChange={(e: any) => onChange(e.target.value)}
                        placeholder="Add the Account's Name"
                        variant="workshops"
                        maxLength={100}
                      />
                    )}
                  />
                </Flex>
                <Flex direction="column" width="30%">
                  <Text mb="8px">{t('partners.editor.seatCount')}</Text>
                  <Controller
                    control={methods.control}
                    name="seats"
                    render={({ field: { onChange, value } }) => (
                      <NumberInput value={value}>
                        <NumberInputField
                          onChange={(e: any) => onChange(validateAndSetSeatCount(e.target.value))}
                          maxLength={8}
                        />
                      </NumberInput>
                    )}
                  />
                </Flex>
              </Flex>
            </Box>
            {routeParams.id && (
              <Box padding={4}>
                <Flex direction="row" justifyContent="space-between">
                  <Flex direction="column" width="50%" marginRight={6}>
                    <Text mb="8px">{t('partners.editor.loginUrl')}</Text>
                    <Text
                      onClick={() =>
                        copyContent(
                          `https://${window.location.host}/login/extend/${routeParams.id!}`,
                        )
                      }
                      cursor="pointer"
                    >
                      https://{window.location.host}/login/extend/{routeParams.id!}
                      <Tooltip hasArrow label={t('partners.editor.clickLink')}>
                        <QuestionCircle marginLeft={2} color="blackAlpha.500" />
                      </Tooltip>
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            )}
          </AdminElevatedBox>
        </Element>

        {/* Branding */}
        <Element name="branding">
          <AdminElevatedBox>
            <Text marginTop={0} marginBottom={2} as="h4">
              {t('partners.admin.branding')}
              <Tooltip hasArrow label={t('partners.admin.branding')}>
                <QuestionCircle marginLeft={1} color="blackAlpha.500" />
              </Tooltip>
            </Text>
            <Box padding={4}>
              <Grid templateColumns="20% 30% 50%" gap={4}>
                <GridItem>
                  <Flex direction="column" alignItems="center">
                    <Text mb="8px">{t('partners.editor.primaryColor')}</Text>
                    <Controller
                      control={methods.control}
                      name="branding.color_primary"
                      render={({ field: { onChange, value } }) => (
                        <ColorPicker
                          color={value}
                          setColor={(color: string) => onChange(color)}
                          defaultColor={value}
                        />
                      )}
                    />
                  </Flex>
                </GridItem>
                <GridItem>
                  <Flex direction="column" alignItems="center">
                    <Flex direction="row">
                      <Text mb="8px" mr={4}>
                        {t('partners.editor.logoImage')}
                      </Text>
                      {!currentLogo?.length && (
                        <Button
                          variant="adminPrimary"
                          mb="8px"
                          size="xs"
                          onClick={handleLogoInputClick}
                        >
                          {t('partners.editor.uploadALogo')}
                        </Button>
                      )}
                      {!!currentLogo?.length && (
                        <Button
                          variant="adminPrimary"
                          mb="8px"
                          size="xs"
                          onClick={handleLogoInputClick}
                        >
                          {t('partners.editor.replaceLogo')}
                        </Button>
                      )}
                      <Tooltip hasArrow label={t('edit.imageuploader.size_type')}>
                        <QuestionCircle marginLeft={2} color="blackAlpha.500" />
                      </Tooltip>
                    </Flex>
                    <Input
                      type="file"
                      display="none"
                      ref={logoInputRef}
                      onChange={(e) => handleFileChange(e, 'logo')}
                    />
                    {typeof currentLogo === 'string' && !!currentLogo.length && (
                      <Image boxSize="150px" width="fit-content" src={currentLogo} />
                    )}
                  </Flex>
                </GridItem>
                <GridItem>
                  <Flex direction="column" alignItems="center">
                    <Flex direction="row">
                      <Text mb="8px" mr="10px">
                        {t('partners.editor.backgroundImage')}
                      </Text>
                      <Button
                        variant="adminPrimary"
                        mb="8px"
                        size="xs"
                        onClick={handleBackgroundImageInputClick}
                      >
                        {t('partners.editor.uploadBackgroundImage')}
                      </Button>
                      <Tooltip hasArrow label={t('edit.imageuploader.size_type')}>
                        <QuestionCircle marginLeft={2} color="blackAlpha.500" />
                      </Tooltip>
                    </Flex>
                    <Input
                      type="file"
                      display="none"
                      ref={bgImgInputRef}
                      onChange={(e) => handleFileChange(e, 'backgroundImage')}
                    />
                    <Box width="70%">
                      <Slider {...carouselSettings}>
                        {!!currentBGImages?.length &&
                          currentBGImages.map((backgroundImage) => (
                            <Box>
                              <Image
                                key={`background_image_${backgroundImage}`}
                                // justifySelf="center"
                                boxSize="200px"
                                width="fit-content"
                                src={backgroundImage}
                              />
                            </Box>
                          ))}
                      </Slider>
                    </Box>
                  </Flex>
                </GridItem>
              </Grid>
            </Box>
          </AdminElevatedBox>
        </Element>
        <Element name="theme">
          <Controller
            name="branding.overrides"
            control={methods.control}
            defaultValue={freshColors}
            render={({ field: { onChange, value } }) => (
              <PartnerColorOverrides
                originalColors={
                  (!methods.formState.isLoading && methods.getValues('branding.overrides')) ||
                  freshColors
                }
                selectedColors={value || freshColors}
                partnerPrimary={methods.watch('branding.color_primary')}
                setSelectedColors={(colors) => onChange(colors(value || freshColors))}
                resetColors={() => onChange(colorReset)}
                isLoading={methods.formState.isLoading}
              />
            )}
          />
        </Element>
        <Element name="segmentation">{!methods.formState.isLoading && <Segmentation />}</Element>
      </FormProvider>
    </Box>
  );
}
