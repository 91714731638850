import { Box, Flex, Text, VStack } from '@chakra-ui/react';

import ContentLength from '@/client/components/data-display/content/ContentLength';
import type { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ScormBannerPreviewProps {
  imageUrl: string;
  height?: string;
  imageEditActions?: ReactElement;
}

export default function ScormBannerPreview({
  imageUrl,
  height,
  imageEditActions,
}: ScormBannerPreviewProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const [renderImageEditActions, setRenderImageEditActions] = useState<boolean>(false);

  const title = watch('title');
  const duration = watch('duration');

  return (
    <Flex
      height={height}
      width="full"
      backgroundImage={`url(${imageUrl})`}
      backgroundSize="cover"
      backgroundPosition="center center"
      marginTop={12}
      position="relative"
    >
      <Flex
        width="full"
        height="full"
        backgroundColor="blackAlpha.600"
        alignItems="center"
        justifyContent="center"
        onMouseEnter={() => setRenderImageEditActions(true)}
        onMouseLeave={() => setRenderImageEditActions(false)}
      >
        <VStack>
          <Text textAlign="center" fontSize="xl" fontWeight={600} color="white">
            {title || t('authoring.scorm.titlePlaceholder')}
          </Text>

          <ContentLength duration={duration} color="white" />

          <Box position="absolute" bottom={10}>
            {renderImageEditActions && imageEditActions && imageEditActions}
          </Box>
        </VStack>
      </Flex>
    </Flex>
  );
}

ScormBannerPreview.defaultProps = {
  height: '250px',
  imageEditActions: null,
};
