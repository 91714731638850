import { PersistOptions, persist } from 'zustand/middleware';
import { create, StateCreator } from 'zustand';

interface FileState {
  pagePositions: any[] | null;
  setPagePositions: (id: string, page: number, pagePositions: any) => void;
}

type FilePersist = (config: StateCreator<FileState>, options: PersistOptions<FileState>) => StateCreator<FileState>;

export const useFileStore = create<FileState>(
  (persist as FilePersist)(
    (set) => ({
      pagePositions: [],
      setPagePositions: (id: string | undefined, page: number, pagePositions: any) => {
        if (
          pagePositions?.filter((item: any, ind: any) => {
            return item.id === id;
          }).length
        ) {
          let loc = 0;
          let record = pagePositions?.filter((item: any, ind: any) => {
            if (item.id === id) {
              loc = ind;
            }
            return item.id === id;
          })[0];
          let clone = pagePositions;
          record.page = page;
          clone[loc] = record;
          set(() => ({ pagePositions: clone }));
        } else {
          let clone = pagePositions;
          clone.push({ id: id, page: page });
          set(() => ({ pagePositions: clone }));
        }
      },
    }),
    { name: 'fileStore' }
  )
);
