/* eslint react/no-unstable-nested-components: 0 */

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  SkeletonText,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { CSSProperties, useCallback, useMemo, useRef } from 'react';
import {
  ColumnDefBase,
  OnChangeFn,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ellipsisHeaderStyles, ellipsisCellStyles } from '@/client/utils/charts/tableStyleUtils';

import { Person } from '@/client/types/Person';
import { useTranslation } from 'react-i18next';

interface ManuallyPaginatedTableProps {
  columns: ColumnDefBase<any, any>[];
  rowSelection?: { [key: string]: boolean };
  setRowSelection?: OnChangeFn<{ [key: string]: boolean }>;
  queryData: { rows: any[]; pageCount: number } | any | undefined;
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
  showCheckbox?: boolean;
  hidePagination?: boolean;
  rowClick?: any;
  useTableLayoutFixed?: boolean;
  maxHeight?: string; // Prevent table scroll by passing 'auto' when needed
}

export default function ManuallyPaginatedTable({
  columns,
  rowSelection,
  setRowSelection,
  queryData,
  pagination,
  setPagination,
  showCheckbox,
  hidePagination,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rowClick,
  useTableLayoutFixed,
  maxHeight,
}: ManuallyPaginatedTableProps) {
  const { t } = useTranslation();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  // const [selectedRowId, setSelectedRowId] = useState<any>();
  // const [primary] = useToken('colors', ['brand.primary']);
  // const primaryTransparent = hexToRGBA(primary, 0.2);
  const defaultData = useMemo(() => [], []);

  const getRowId = useCallback((row: Person) => row._id, []);

  const table = useReactTable({
    data: queryData?.rows ?? defaultData,
    // @ts-ignore
    columns,
    getRowId,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    pageCount: queryData?.pageCount ?? -1,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination,
      rowSelection,
      columnVisibility: { checkbox: showCheckbox || false },
    },
    initialState: {
      columnVisibility: { checkbox: showCheckbox || false },
    },
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  if (!queryData || queryData.rows.length === 0)
    return (
      <Box marginX={4}>
        <SkeletonText
          startColor="brand.backgroundGrey"
          endColor="brand.backgroundGrey"
          speed={0}
          mt="4"
          noOfLines={10}
          spacing="4"
          skeletonHeight="10"
        />
      </Box>
    );

  const tableStyles = (): CSSProperties => {
    if (useTableLayoutFixed) {
      return {
        tableLayout: 'fixed',
      };
    }
    return {};
  };

  return (
    <>
      <TableContainer
        ref={tableContainerRef}
        borderRadius="xl"
        maxHeight={maxHeight}
        overflowY="auto"
      >
        <Table variant="admin" overflowY="auto" style={{ ...tableStyles() }}>
          <Thead position="sticky" top={0} zIndex={10}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      ...ellipsisHeaderStyles(header, !!useTableLayoutFixed),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getCoreRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    style={{
                      ...ellipsisCellStyles(cell, !!useTableLayoutFixed),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {!hidePagination && (
        <Flex alignItems="center" marginTop={6}>
          <Box width="30%">
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((currentPageSize) => (
                <option key={currentPageSize} value={currentPageSize}>
                  {t('global.actions.show')} {currentPageSize}
                </option>
              ))}
            </select>
          </Box>

          <ButtonGroup width="40%" variant="ghost" justifyContent="center" alignItems="center">
            <Button
              onClick={() => table.setPageIndex(0)}
              isDisabled={!table.getCanPreviousPage()}
              color="brand.grey.100"
            >
              {'<<'}
            </Button>

            <Button
              onClick={() => table.previousPage()}
              isDisabled={!table.getCanPreviousPage()}
              color="brand.grey.100"
            >
              {'<'}
            </Button>

            <Text>{`${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}</Text>

            <Button
              onClick={() => table.nextPage()}
              isDisabled={!table.getCanNextPage()}
              color="brand.grey.100"
            >
              {'>'}
            </Button>

            <Button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              isDisabled={!table.getCanNextPage()}
              color="brand.grey.100"
            >
              {'>>'}
            </Button>
          </ButtonGroup>

          <Box width="30%" />
        </Flex>
      )}
    </>
  );
}

ManuallyPaginatedTable.defaultProps = {
  showCheckbox: false,
  hidePagination: false,
  rowClick: undefined,
  rowSelection: null,
  setRowSelection: null,
  useTableLayoutFixed: false,
  maxHeight: '800px',
};
