import { Box } from '@chakra-ui/react';
import ExploreTabs from '../components/disclosure/ExploreTabs';
import useDocumentTitle from '../utils/useDocumentTitle';

export default function Explore() {
  useDocumentTitle('Explore');

  return (
    <Box>
      <ExploreTabs />
    </Box>
  );
}
