/* eslint react/jsx-props-no-spreading: 0 */

import { SubmitHandler, useFormContext, useForm, FormProvider } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { useToastStore } from '@/client/services/state/toastStore';
import RegisterWithProductSelection from '../RegisterWithProductSelection';
import { useListProducts } from '@/client/services/hooks/content/products/useListProducts';

export default function LandingPagePreview() {
  const { setToast } = useToastStore();
  const { watch } = useFormContext();
  const { t } = useTranslation();

  // Get form data from current form context to populate preview
  const formData: any = watch();

  const onSubmit: SubmitHandler<any> = async () => {
    setToast({
      show: true,
      status: 'info',
      title: t('registrationForms.preview.submitNotice'),
    });
  };

  // Create new mock form to wrap preview
  const previewMethods = useForm({ mode: 'onChange' });

  // Populate products from current selected list of form ids in editor
  const { data } = useListProducts({
    id: { in: formData?.products },
    page: 0,
    pageSize: 10,
    includeTotalCount: true,
  });

  return (
    <FormProvider {...previewMethods}>
      <form onSubmit={previewMethods.handleSubmit(onSubmit!)}>
        <RegisterWithProductSelection
          // Spread Form data to add populated products and correct variable names
          formData={{
            ...formData,
            products: data?.products.map((product) => ({
              ...product,
              _id: product.id,
              subscription_duration: product.subscriptionDuration,
            })),
          }}
        />
      </form>
    </FormProvider>
  );
}
