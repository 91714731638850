import {
  Box,
  ComponentWithAs,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconProps,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CheckCircle, Circle } from '@/client/components/icons/ContinuIcons';

import { EngagementReport } from '@/client/types/admin/reports/ReportTypes';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export interface ReportFormValues {
  reportName: string;
  report: EngagementReport;
}

interface ReportGroup {
  icon: ComponentWithAs<'svg', IconProps>;
  report: EngagementReport;
}

interface GridRadioGroupProps {
  engagementReports: ReportGroup[];
  summaryReports: ReportGroup[];
  statusReports: ReportGroup[];
  isDisabled: boolean;
}

interface RadioGridProps {
  reports: ReportGroup[];
  isDisabled: boolean;
  label: string;
}

function RadioGrid({ reports, isDisabled, label }: RadioGridProps) {
  const { setValue, watch } = useFormContext<ReportFormValues>();

  const selectedReportType = watch('report.formValue');

  return (
    <Box>
      <FormLabelWithTooltip label={label} />

      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        {reports.map((option) => (
          <GridItem
            key={option.report.name}
            borderRadius="xl"
            padding={4}
            boxShadow={
              selectedReportType === option.report.formValue
                ? `0px 4px 4px 0px rgba(0, 0, 0, 0.25)`
                : '2px 2px 2px 0px rgba(0, 0, 0, 0.1)'
            }
            _hover={{ cursor: isDisabled ? 'unset' : 'pointer' }}
            onClick={() => (isDisabled ? null : setValue('report', option.report))}
          >
            <Flex>
              <Flex>
                {selectedReportType === option.report.formValue ? (
                  <CheckCircle color={isDisabled ? 'brand.gray.200' : 'brand.green'} />
                ) : (
                  <Circle color="brand.gray.50" />
                )}
              </Flex>

              <Stack marginLeft={4} color={isDisabled ? 'brand.gray.200' : 'black'}>
                <Icon
                  as={option.icon}
                  boxSize={8}
                  color={isDisabled ? 'brand.gray.200' : 'brand.primary'}
                />

                <Text fontWeight="medium">{option.report.name}</Text>

                <Text fontSize="xs">{option.report.help}</Text>
              </Stack>
            </Flex>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
}

export default function GridRadioGroup({
  engagementReports,
  summaryReports,
  statusReports,
  isDisabled,
}: GridRadioGroupProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <RadioGrid
        reports={engagementReports}
        isDisabled={isDisabled}
        label={t('reports.types.engagementReports')}
      />

      <RadioGrid
        reports={summaryReports}
        isDisabled={isDisabled}
        label={t('reports.types.summaryReports')}
      />

      <RadioGrid
        reports={statusReports}
        isDisabled={isDisabled}
        label={t('reports.types.statusReports')}
      />
    </Stack>
  );
}
