/* eslint-disable react/no-unstable-nested-components */
import { Box, Link, Text } from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { analyzeApiClient } from '@/client/services/api/clients/analyzeApiClient';
import { learnApiClient } from '@/client/services/api/clients/learnApiClient';
import { useFormatDate } from '@/client/services/hooks/date-and-time/useFormatDate';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useRenderContentIcon } from '@/client/services/hooks/useRenderContentIcon';
import { useTranslation } from 'react-i18next';

interface ContentWithTitle {
  _id: string;
  company: string;
  user: string;
  content: string;
  content_type: string;
  created_on: string;
  title: string;
  contentType: string;
}

// TODO: We're front loading content views because returning total document count requires hefty analayze work.
// TODO: Need to return total count at some point in the future

export default function UserViewedContent() {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const getUserManagerViewedContent = async (
    options: { pageIndex: number; pageSize: number },
    userId: string | undefined,
  ): Promise<{ rows: ContentWithTitle[]; pageCount: number }> => {
    const contentWithoutTitle = await analyzeApiClient.get<any[]>(
      `views/users/${userId}?page=${options.pageIndex + 1}&per_page=${
        options.pageSize
      }&sort=created_on,-1`,
    );

    const titles = await learnApiClient.post<any[]>('viewables/titles', {
      viewables: contentWithoutTitle.data.map((contentItem) => ({
        type: contentItem.content_type,
        id: contentItem.content,
      })),
    });

    const contentWithTitle = contentWithoutTitle.data.map((content) => ({
      ...content,
      title: titles.data.find((title) => title.id === content.content)?.title || 'Title not found',
      contentType: titles.data.find((title) => title.id === content.content)?.contentType,
    }));

    return {
      rows: contentWithTitle,
      pageCount: 1,
    };
  };

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 500,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data } = useQuery({
    queryKey: ['user-manager-viewed-content', fetchDataOptions, id],
    queryFn: () => getUserManagerViewedContent(fetchDataOptions, id),
  });

  const generateContentLink = (view: ContentWithTitle) => {
    if (view.content_type === 'quiz') {
      return `/pvt/#/edit/quizzes/${view.content}`;
    }

    let link = '/pvt/#host/' + view.content_type;

    if (view.content_type !== 'track' && view.content_type !== 'scorm') {
      link += 's';
    }
    if (view.content_type === 'imported_content') {
      link = '/host/imported-content';
    }
    if (view.content_type === 'presentation') {
      link = '/view/presentations';
    }
    if (view.contentType === 'multi_session_workshop') {
      link = '/host/multi-session-workshops';
    }
    link += '/' + view.content;

    return link;
  };

  const columnHelper = createColumnHelper<ContentWithTitle>();
  const columns = useMemo<ColumnDefBase<ContentWithTitle, any>[]>(
    () => [
      columnHelper.accessor('content_type', {
        cell: (info) => useRenderContentIcon(info.getValue()),
        header: () => <span>{t('manage.users.viewed_type')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('title', {
        cell: (info) => (
          <Link href={generateContentLink(info.row.original)}>{info.getValue()}</Link>
        ),
        header: () => <span>{t('manage.users.viewed_title')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('created_on', {
        cell: (info) => <Text>{formatDate(new Date(info.getValue()), 'baseWithTime')}</Text>,
        header: () => <span>{t('manage.users.viewed_dateViewed')}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    [],
  );

  return (
    <Box
      marginX={4}
      marginY={1}
      paddingX={6}
      paddingY={2}
      borderRadius="md"
      backgroundColor="white"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1);"
    >
      <ManuallyPaginatedTable
        columns={columns}
        queryData={data}
        pagination={pagination}
        setPagination={setPagination}
        hidePagination
        maxHeight="auto"
      />
    </Box>
  );
}
