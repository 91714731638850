import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import ThemeColorOverrides from './ThemeColorOverrides';
import ExploreCardForm from './ExploreCardForm';
import { Navigate } from 'react-router-dom';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useTranslation } from 'react-i18next';

export default function Theme() {
  const { t } = useTranslation();
  const { allowance } = useAccessHelper();

  if (!allowance.theme) return <Navigate to="/explore" replace />;

  return (
    <>
      <AdminPrimaryHeader
        title={`${t('global.theme')}`}
        setSearchTerm={() => {}}
        sortOrder="newest"
        setSortOrder={() => {}}
        tooltipText=""
        isSearching={false}
        showSearch={false}
      />

      <ExploreCardForm />

      <ThemeColorOverrides />
    </>
  );
}
