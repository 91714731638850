export const Badge = {
  baseStyle: {
    color: 'white',
    textTransform: 'none',
    borderRadius: 'sm',
  },
  variants: {
    error: {
      backgroundColor: 'brand.red',
    },
    warning: {
      backgroundColor: 'orange.300',
    },
    success: {
      backgroundColor: 'brand.green',
    },
    active: {
      backgroundColor: '#41B305'
    },
    inactive: {
      backgroundColor: '#D80808'
    },
    pending: {
      backgroundColor: '#F8BC4C'
    }
  },
};
