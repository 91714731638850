import { Box, Circle, Flex, HStack, Link, Stack, Text, Tooltip } from '@chakra-ui/react';
import { CalendarEvent, CheckCircle, Clock, Popular, Workshop } from '../../icons/ContinuIcons';
import { useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { getMSWIdFromSessionId } from '@/client/services/api/content/workshops/MultiSessionWorkshopService';
import { getWorkshopCardBackgroundUrl, getWorkshopCardImageAlignment } from '@/client/utils/content/handleCardBackgroundImage';
import { useTranslation } from 'react-i18next';

type WorkshopsCardProps = {
  workshopId: string;
  workshopTitle: string;
  workshopType: string;
  online: boolean;
  image: string;
  registeredStatus: string;
  startDate: string;
  imageAlignment: string;
  seats?: number;
  registrations?: number;
};

export default function WorkshopsCard({
  workshopId,
  workshopTitle,
  workshopType,
  online,
  image,
  registeredStatus,
  startDate,
  imageAlignment,
  seats,
  registrations,
}: WorkshopsCardProps) {

  const { t } = useTranslation();
  const [contentUrl, setContentUrl] = useState<string>('');
  const [hovered, setHovered] = useState(false);
  const bgRef = useRef<any>();

  const getContentTypeTitle = (string: string, typeOnline: boolean) => {
    let contentTitle;
    let contentLocation;
    if (string === 'multi_session_workshop_session') {
      contentTitle = t('workshopTypeSelection.multiSession');
    } else {
      contentTitle = t('workshopTypeSelection.singleSession');
    }
    if (typeOnline) {
      contentLocation = t('workshops.online');
    } else {
      contentLocation = t('workshops.inPerson');
    }
    return `${contentTitle} | ${contentLocation}`;
  };

  const getWorkshopUrl = async (id: string, workshopUrlType: string = '') => {
    if (workshopUrlType === 'multi_session_workshop_session') {
      const mswId = await getMSWIdFromSessionId(id);
      setContentUrl(`/multi-session-workshop/${mswId}`);
    } else {
      setContentUrl(`/workshop/${id}`);
    }
  };

  const getStatusIcon = (status: string, iconStartDate: string) => {
    const dateUpcoming = new Date(iconStartDate) > new Date();

    if (dateUpcoming) {
      if (status === 'registered') {
        return <CheckCircle color="green" />;
      }
      if (status === 'available' || status === 'removed') {
        return <CalendarEvent color="black" />;
      }
      if (status === 'waiting') {
        return <Clock color="black" />;
      }
    } else {
      if (status === 'attended') {
        return <CheckCircle color="gray" />;
      }
      if (status === 'absent' || status === 'registered' || status === 'available') {
        return <CalendarEvent color="gray" />;
      }
      if (status === 'waiting') {
        return <Clock color="gray" />;
      }
    }
  };

  const getStatus = (status: string, statusStartDate: string) => {
    const dateUpcoming = new Date(statusStartDate) > new Date();

    if (dateUpcoming) {
      if (status === 'registered') {
        return (
          <Text fontWeight="500" fontSize="14px" color="green">
            {t('workshops.attending')}
          </Text>
        );
      }
      if (status === 'available' || status === 'removed') {
        return (
          <Text fontWeight="500" fontSize="14px" color="black">
            {t('workshopviewer.register')}
          </Text>
        );
      }
      if (status === 'waiting') {
        return (
          <Text fontWeight="500" fontSize="14px" color="black">
            {t('workshops.waitlisted')}
          </Text>
        );
      }
    } else {
      if (status === 'attended') {
        return (
          <Text fontWeight="500" fontSize="14px" color="gray">
            {t('workshops.attended')}
          </Text>
        );
      }
      if (status === 'absent' || status === 'registered') {
        return (
          <Text fontWeight="500" fontSize="14px" color="gray">
            {t('workshops.registered')}
          </Text>
        );
      }
      if (status === 'available' || status === 'removed') {
        return (
          <Text fontWeight="500" fontSize="14px" color="gray">
            {t('workshops.past')}
          </Text>
        );
      }
      if (status === 'waiting') {
        return (
          <Text fontWeight="500" fontSize="14px" color="gray">
            {t('workshops.waitlisted')}
          </Text>
        );
      }
    }
  };

  const formatDate = (date: string) => {
    const dateToFormat = new Date(date);
    const formattedDate = format(dateToFormat, "EEEE, MMMM do, yyyy 'at' p");
    return formattedDate;
  };

  const checkMostPopular = () => {
    if (registrations && seats) {
      const popularity = (registrations / seats) * 100;
      if (popularity > 75) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    getWorkshopUrl(workshopId, workshopType);
  }, [workshopType, workshopId]);

  return (
    <Link as={RouterLink} to={contentUrl} state={{ originRoute: '/workshops' }} maxWidth="566px">
      <Box
        width={{base: "100%", lg: "auto"}}
        height="auto"
        position="relative"
        background="white"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.1)"
        rounded="lg"
        overflow="hidden"
        _hover={{
          cursor: 'pointer',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.20)',
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Box height="324px" overflow="hidden" mb={5}>
          <Box
            ref={bgRef}
            width="full"
            height="324px"
            backgroundImage="url(/public/images/cardPlaceholder.png)"
            background={`url(${getWorkshopCardBackgroundUrl(image)})`}
            backgroundPosition={`${getWorkshopCardImageAlignment(imageAlignment)} center`}
            margin={0}
            backgroundSize="cover"
            transition="all ease .5s"
            transform={`scale(${hovered ? 1.1 : 1})`}
            position="static"
          />
        </Box>

        <Stack paddingX={6}>
          <Flex alignItems="center" flexDirection="row" justifyContent="space-between">
            <HStack alignItems="center">
              <Circle size="24px" border="1px" borderColor="#5D5B57" color="#5D5B57" padding={2}>
                <Workshop transform="translate(0.3px, 0.25px)" boxSize={3.5} />
              </Circle>

              <Text color="#5D5B57" fontWeight="500" fontSize="12px" marginRight={2}>
                {getContentTypeTitle(workshopType, online)}
              </Text>
            </HStack>
            <HStack>
              {getStatusIcon(registeredStatus, startDate)}
              {getStatus(registeredStatus, startDate)}
              {registrations && seats && checkMostPopular() && (
                <Tooltip label={t('workshops.mostPopular')} bg="white" color="black" hasArrow>
                  <Circle
                    size="26px"
                    border="1px"
                    borderColor="#FFC701"
                    background="#FFC701"
                    color="white"
                    padding={2}
                  >
                    <Popular />
                  </Circle>
                </Tooltip>
              )}
            </HStack>
          </Flex>

          <Box flex={1} height="21px" overflow="hidden" textOverflow="ellipsis">
            <Text fontWeight={600} fontSize="lg" color="blackAlpha.900" marginBottom={2}>
              {workshopTitle}
            </Text>
            <Text fontWeight="400" fontSize="sm" color="blackAlpha.900" marginBottom={4}>
              {formatDate(startDate)}
            </Text>
          </Box>
        </Stack>
      </Box>
    </Link>
  );
}

WorkshopsCard.defaultProps = {
  seats: 0,
  registrations: 0,
};
