export const locales: { name: string; value: string }[] = [
  { name: 'Afrikaans', value: 'af' },
  { name: 'Akan', value: 'ak' },
  { name: 'Albanian', value: 'sq' },
  { name: 'Amharic', value: 'am' },
  { name: 'Arabic', value: 'ar' },
  { name: 'Armenian', value: 'hy' },
  { name: 'Aromanian', value: 'rup_MK' },
  { name: 'Assamese', value: 'as' },
  { name: 'Azerbaijani', value: 'az' },
  { name: 'Azerbaijani (Turkey)', value: 'az_TR' },
  { name: 'Bashkir', value: 'ba' },
  { name: 'Basque', value: 'eu' },
  { name: 'Belarusian', value: 'bel' },
  { name: 'Bengali', value: 'bn_BD' },
  { name: 'Bosnian', value: 'bs_BA' },
  { name: 'Bulgarian', value: 'bg_BG' },
  { name: 'Burmese', value: 'my_MM' },
  { name: 'Catalan', value: 'ca' },
  { name: 'Catalan (Balear)', value: 'bal' },
  { name: 'Chinese (China)', value: 'zh_CN' },
  { name: 'Chinese (Hong Kong)', value: 'zh-hk' },
  { name: 'Chinese (Taiwan)', value: 'zh_TW' },
  { name: 'Corsican', value: 'co' },
  { name: 'Croatian', value: 'hr' },
  { name: 'Czech', value: 'cs_CZ' },
  { name: 'Danish', value: 'da_DK' },
  { name: 'Dhivehi', value: 'dv' },
  { name: 'Dutch', value: 'nl_NL' },
  { name: 'Dutch (Belgium)', value: 'nl_BE' },
  { name: 'English', value: 'en_US' },
  { name: 'English (Australia)', value: 'en_AU' },
  { name: 'English (Canada)', value: 'en_CA' },
  { name: 'English (UK)', value: 'en_GB' },
  { name: 'Esperanto', value: 'eo' },
  { name: 'Estonian', value: 'et' },
  { name: 'Faroese', value: 'fo' },
  { name: 'Finnish', value: 'fi' },
  { name: 'French (Belgium)', value: 'fr_BE' },
  { name: 'French (France)', value: 'fr_FR' },
  { name: 'French (Canada)', value: 'fr_CA' },
  { name: 'Frisian', value: 'fy' },
  { name: 'Fulah', value: 'fuc' },
  { name: 'Galician', value: 'gl_ES' },
  { name: 'Georgian', value: 'ka_GE' },
  { name: 'German', value: 'de_DE' },
  { name: 'German (Switzerland)', value: 'de_CH' },
  { name: 'Greek', value: 'el' },
  { name: 'Guaraní', value: 'gn' },
  { name: 'Gujarati', value: 'gu_IN' },
  { name: 'Hawaiian', value: 'haw_US' },
  { name: 'Hazaragi', value: 'haz' },
  { name: 'Hebrew', value: 'he_IL' },
  { name: 'Hindi', value: 'hi_IN' },
  { name: 'Hungarian', value: 'hu_HU' },
  { name: 'Icelandic', value: 'is_IS' },
  { name: 'Ido', value: 'ido' },
  { name: 'Indonesian', value: 'id_ID' },
  { name: 'Irish', value: 'ga' },
  { name: 'Italian', value: 'it_IT' },
  { name: 'Japanese', value: 'ja' },
  { name: 'Javanese', value: 'jv_ID' },
  { name: 'Kannada', value: 'kn' },
  { name: 'Kazakh', value: 'kk' },
  { name: 'Khmer', value: 'km' },
  { name: 'Kinyarwanda', value: 'kin' },
  { name: 'Kirghiz', value: 'ky_KY' },
  { name: 'Korean', value: 'ko_KR' },
  { name: 'Kurdish (Sorani)', value: 'ckb' },
  { name: 'Lao', value: 'lo' },
  { name: 'Latvian', value: 'lv' },
  { name: 'Limburgish', value: 'li' },
  { name: 'Lingala', value: 'lin' },
  { name: 'Lithuanian', value: 'lt_LT' },
  { name: 'Luxembourgish', value: 'lb_LU' },
  { name: 'Macedonian', value: 'mk_MK' },
  { name: 'Malagasy', value: 'mg_MG' },
  { name: 'Malay', value: 'ms_MY' },
  { name: 'Malayalam', value: 'ml_IN' },
  { name: 'Marathi', value: 'mr' },
  { name: 'Mingrelian', value: 'xmf' },
  { name: 'Mongolian', value: 'mn' },
  { name: 'Montenegrin', value: 'me_ME' },
  { name: 'Nepali', value: 'ne_NP' },
  { name: 'Norwegian (Bokmål)', value: 'nb_NO' },
  { name: 'Norwegian (Nynorsk)', value: 'nn_NO' },
  { name: 'Oriya', value: 'ory' },
  { name: 'Ossetic', value: 'os' },
  { name: 'Pashto', value: 'ps' },
  { name: 'Persian', value: 'fa_IR' },
  { name: 'Persian (Afghanistan)', value: 'fa_AF' },
  { name: 'Polish', value: 'pl_PL' },
  { name: 'Portuguese (Brazil)', value: 'pt_BR' },
  { name: 'Portuguese (Portugal)', value: 'pt_PT' },
  { name: 'Punjabi', value: 'pa_IN' },
  { name: 'Rohingya', value: 'rhg' },
  { name: 'Romanian', value: 'ro_RO' },
  { name: 'Russian', value: 'ru_RU' },
  { name: 'Russian (Ukraine)', value: 'ru_UA' },
  { name: 'Rusyn', value: 'rue' },
  { name: 'Sakha', value: 'sah' },
  { name: 'Sanskrit', value: 'sa_IN' },
  { name: 'Sardinian', value: 'srd' },
  { name: 'Scottish', value: 'gd' },
  { name: 'Serbian', value: 'sr_RS' },
  { name: 'Sindhi', value: 'sd_PK' },
  { name: 'Sinhala', value: 'si_LK' },
  { name: 'Slovak', value: 'sk_SK' },
  { name: 'Slovenian', value: 'sl_SI' },
  { name: 'Somali', value: 'so_SO' },
  { name: 'South', value: 'azb' },
  { name: 'Spanish (Argentina)', value: 'es_AR' },
  { name: 'Spanish (Chile)', value: 'es_CL' },
  { name: 'Spanish (Colombia)', value: 'es_CO' },
  { name: 'Spanish (Mexico)', value: 'es_MX' },
  { name: 'Spanish (Peru)', value: 'es_PE' },
  { name: 'Spanish (Puerto', value: 'es-pr' },
  { name: 'Spanish (Spain)', value: 'es_ES' },
  { name: 'Spanish (Venezuela)', value: 'es_VE' },
  { name: 'Sundanese', value: 'su_ID' },
  { name: 'Swahili', value: 'sw' },
  { name: 'Swedish', value: 'sv_SE' },
  { name: 'Swiss', value: 'gsw' },
  { name: 'Tagalog', value: 'tl' },
  { name: 'Tajik', value: 'tg' },
  { name: 'Tamazight (Central Atlas)', value: 'tzm' },
  { name: 'Tamil', value: 'ta_IN' },
  { name: 'Tamil (Sri Lanka)', value: 'ta-lk' },
  { name: 'Tatar', value: 'tt_RU' },
  { name: 'Telugu', value: 'te' },
  { name: 'Thai', value: 'th' },
  { name: 'Tibetan', value: 'bo' },
  { name: 'Tigrinya', value: 'tir' },
  { name: 'Turkish', value: 'tr_TR' },
  { name: 'Turkmen', value: 'tuk' },
  { name: 'Uighur', value: 'ug_CN' },
  { name: 'Ukrainian', value: 'uk' },
  { name: 'Urdu', value: 'ur' },
  { name: 'Uzbek', value: 'uz_UZ' },
  { name: 'Vietnamese', value: 'vi' },
  { name: 'Walloon', value: 'wa' },
  { name: 'Welsh', value: 'cy' },
];
