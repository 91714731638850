/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Stack,
  Switch,
} from '@chakra-ui/react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import ContentSegmentationSearch from '@/client/components/admin/input/search/ContentSegmentationSearch';
import { EngagementReport } from '@/client/types/admin/reports/ReportTypes';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import GridRadioGroup from './form/GridRadioGroup';
import InputWithToolTip from '@/client/components/admin/forms/InputWithTooltip';
import Loading from '@/client/components/media/Loading';
import ReportsService from '@/client/services/api/admin/reports/ReportsService';
import { Save } from '@/client/components/icons/ContinuIcons';
import UserGroupSearch from '@/client/components/admin/input/search/UserGroupSearch';
import { useConfigStore } from '@/client/services/state/configStore';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useEffect } from 'react';
import { useReportTypes } from '@/client/services/hooks/admin/reports/useReportTypes';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';

const filterTypeToSearchType = {
  Article: 'articles',
  Assignment: 'assignments',
  Media: 'media',
  ImportedContent: 'imported_content',
  ScormContent: 'scorm',
  Track: 'tracks',
  Workshop: 'workshops',
  User: 'user',
  location: 'location',
  department: 'department',
  team: 'team',
  org_level: 'org_level',
  grade: 'grade',
  Group: 'group',
  Assessment: 'assessments',
};

export interface ReportFormValues {
  allSelected: boolean;
  reportName: string;
  report: EngagementReport;
  fields: any;
  groupUserSearchTerm: string;
  includeSuspendedUsers: boolean; // * content-engagement and workshop-engagement reports do not use this prop
  contentFilterSearchTerm: string;
  userFilters: any;
  contentFilters: any;
}

const arrToObj = (arr: any[]) => arr.reduce((obj, item) => ({ ...obj, [item.key]: true }), {});

export default function ReportsForm() {
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const { reportTypes, engagementReports, summaryReports, statusReports } = useReportTypes();
  const { reportId } = useParams();
  const { config } = useConfigStore();
  useDocumentTitle(
    reportId ? t('overviewReports.documentTitle.edit') : t('overviewReports.documentTitle.create'),
  );

  const {
    isFetching,
    isError,
    data: reportData,
  } = useQuery({
    enabled: !!reportId,
    queryKey: ['report', reportId],
    queryFn: () => ReportsService.getReport(reportId),
  });

  const defaultFormData = {
    allSelected: !reportId,
    reportName: '',
    report: reportTypes['user-engagement'] as EngagementReport,
    fields: arrToObj(reportTypes['user-engagement'].defaultFields),
    groupUserSearchTerm: '',
    includeSuspendedUsers: false,
    userFilters: [],
    contentFilters: [],
  };

  const methods = useForm<ReportFormValues>({
    defaultValues: defaultFormData,
    mode: 'onChange',
  });

  // * Set field values based on selected fields when editing a report
  const editFields = (
    fields: string[],
    defaultFields: { key: string; name: string; copy: string; previewValues: number[] }[],
  ) => {
    let obj = {};

    defaultFields.forEach((field) => {
      obj = { ...obj, [field.key]: fields.includes(field.key) };
    });

    return obj;
  };

  const currentSummaryReports = config.features.content_completion_flag
    ? summaryReports
    : summaryReports.filter(
        (report: { report: { name: string } }) => report.report.name !== 'Content Completion',
      );

  // * Reset form data when report data is fetched
  useEffect(() => {
    if (reportData) {
      let userFilters: any[] = [];
      let contentFilters: any[] = [];

      reportData.report_filters.forEach((reportFilter) => {
        const searchType = reportFilter.value_sub_type || reportFilter.value_type;

        reportFilter.value.forEach((reportFilterValue) => {
          const filter = {
            _id: reportFilterValue._id,
            name: reportFilterValue.label,
            filterType: searchType,
          };
          if (
            searchType === filterTypeToSearchType.User ||
            searchType === filterTypeToSearchType.location ||
            searchType === filterTypeToSearchType.department ||
            searchType === filterTypeToSearchType.team ||
            searchType === filterTypeToSearchType.org_level ||
            searchType === filterTypeToSearchType.grade ||
            searchType === filterTypeToSearchType.Group
          ) {
            userFilters = [...userFilters, filter];
          } else {
            contentFilters = [...contentFilters, filter];
          }
        });
      });

      const formData = {
        reportName: reportData.name,
        // @ts-ignore
        report: reportTypes[reportData.report_type] as EngagementReport,
        fields: editFields(
          reportData.report_fields,
          // @ts-ignore
          reportTypes[reportData.report_type].defaultFields,
        ),
        groupUserSearchTerm: '',
        includeSuspendedUsers: reportData.report_options?.includeSuspendedUsers || false,
        userFilters,
        contentFilters,
      };

      const allFieldsSelected = Object.values(formData.fields).every((field) => field === true);

      methods.reset({ ...formData, allSelected: allFieldsSelected });
    }
  }, [reportData]);

  const { watch } = methods;

  const selectedReport = watch('report');
  const fields = watch('fields');
  const includeSuspendedUsers = watch('includeSuspendedUsers');
  const userFilters = watch('userFilters');
  const contentFilters = watch('contentFilters');
  const allSelected = watch('allSelected');

  const handleAllSelectedChange = () => {
    methods.setValue('allSelected', !allSelected);

    if (!allSelected) {
      Object.entries(fields).forEach(([key]) => {
        methods.setValue(`fields.${key}`, true);
      });
    } else {
      Object.entries(fields).forEach(([key]) => {
        methods.setValue(`fields.${key}`, false);
      });
    }
  };

  // * Set all fields to true if creating a new report and report type changes
  useEffect(() => {
    if (!reportId) {
      Object.entries(fields).forEach(([key]) => {
        methods.setValue(`fields.${key}`, true);
      });
    }
  }, [selectedReport]);

  const formSections = [
    {
      name: 'report-type',
      label: t('editReports.reportType'),
      tooltipText: t('editReports.reportType.helpText'),

      children: (
        <GridRadioGroup
          engagementReports={engagementReports}
          summaryReports={currentSummaryReports}
          statusReports={statusReports}
          isDisabled={reportId !== undefined}
        />
      ),
    },
    {
      name: 'report-filters',
      label: t('editReports.filters'),
      tooltipText: t('editReports.filters.helpText'),

      children: (
        <Stack spacing={6}>
          <UserGroupSearch
            label={t('editReports.userSegmentation')}
            userFilter={selectedReport.userFilter}
            includeSuspendedUsers={includeSuspendedUsers}
            selectedItems={userFilters}
            setSelectedItems={(items: any[]) => methods.setValue('userFilters', items)}
          />

          {selectedReport.defaultFilters.user_filter?.suspended && (
            <Controller
              name="includeSuspendedUsers"
              control={methods.control}
              render={({ field }) => (
                <FormControl {...field} display="flex" alignItems="center" width="25%">
                  <FormLabel
                    id="includeSuspendedUsers"
                    htmlFor="includeSuspendedUsers"
                    mb="0"
                    fontSize="sm"
                    minWidth="50%"
                  >
                    {t('editReports.includeSuspendedUsers')}
                  </FormLabel>

                  <Switch isChecked={field.value} />
                </FormControl>
              )}
            />
          )}

          {selectedReport.contentFilter && (
            <ContentSegmentationSearch
              label={t('editReports.contentSegmentation')}
              contentOnly
              selectedItems={contentFilters}
              setSelectedItems={(items: any[]) => methods.setValue('contentFilters', items)}
              forType={selectedReport.contentFilter}
              isRequired={selectedReport.formValue === 'track-status'}
            />
          )}
        </Stack>
      ),
    },
    {
      name: 'report-fields',
      label: t('editReports.fields'),
      tooltipText: t('editReports.fields.helpText'),

      children: (
        <>
          <Controller
            name="allSelected"
            control={methods.control}
            render={({ field }) => (
              <FormControl {...field} display="flex">
                <FormLabel htmlFor="allSelected" mb={4} fontSize="sm">
                  {t('global.actions.select_all')}
                </FormLabel>

                <Switch isChecked={allSelected} onChange={handleAllSelectedChange} />
              </FormControl>
            )}
          />

          <Grid templateColumns="repeat(3, 1fr)" gap={2}>
            {selectedReport.defaultFields.map((option) => (
              <GridItem key={option.key}>
                <Controller
                  name={`fields.${option.key}`}
                  control={methods.control}
                  render={({ field }) => (
                    <FormControl
                      {...field}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      paddingX={4}
                    >
                      <FormLabel
                        htmlFor={`fields.${option.key}`}
                        mb="0"
                        fontSize="sm"
                        minWidth="50%"
                      >
                        {option.name}
                      </FormLabel>

                      <Switch isChecked={fields[option.key]} />
                    </FormControl>
                  )}
                />
              </GridItem>
            ))}
          </Grid>
        </>
      ),
    },
  ];

  const checkIfFilterTypeIsCategory = (filterType: string) => {
    if (
      filterType === 'location' ||
      filterType === 'department' ||
      filterType === 'team' ||
      filterType === 'org_level' ||
      filterType === 'grade' ||
      filterType === 'Category'
    ) {
      return true;
    }

    return false;
  };

  const formatReportFilters = (filters: any[]) => {
    let formattedFilters: any[] = [];

    // User Segmentation
    const users = filters.filter(
      (filter) => filter.filterType === 'User' || filter.filterType === 'user',
    );
    const categories = filters.filter((filter) => checkIfFilterTypeIsCategory(filter.filterType));
    const groups = filters.filter(
      (filter) => filter.filterType === 'Group' || filter.filterType === 'group',
    );

    if (users.length > 0) {
      formattedFilters = [{ value: users.map((user) => user._id), value_type: 'User' }];
    }

    if (categories.length > 0) {
      formattedFilters = [
        ...formattedFilters,
        { value: categories.map((category) => category._id), value_type: 'Category' },
      ];
    }

    if (groups.length > 0) {
      formattedFilters = [
        ...formattedFilters,
        { value: groups.map((group) => group._id), value_type: 'Group' },
      ];
    }

    // Content Segmentation
    const articles = filters.filter((filter) => filter.filterType === 'Article');
    const media = filters.filter((filter) => filter.filterType === 'Media');
    const scorm = filters.filter((filter) => filter.filterType === 'ScormContent');
    const importedContent = filters.filter((filter) => filter.filterType === 'ImportedContent');
    const tracks = filters.filter((filter) => filter.filterType === 'Track');
    const workshops = filters.filter((filter) => filter.filterType === 'Workshop');
    const assessments = filters.filter((filter) => filter.filterType === 'Assessment');

    if (articles.length > 0) {
      formattedFilters = [
        ...formattedFilters,
        { value: articles.map((article) => article._id), value_type: 'Article' },
      ];
    }

    if (media.length > 0) {
      formattedFilters = [
        ...formattedFilters,
        { value: media.map((medium) => medium._id), value_type: 'Media' },
      ];
    }

    if (scorm.length > 0) {
      formattedFilters = [
        ...formattedFilters,
        { value: scorm.map((scormContent) => scormContent._id), value_type: 'ScormContent' },
      ];
    }

    if (importedContent.length > 0) {
      formattedFilters = [
        ...formattedFilters,
        {
          value: importedContent.map((importedContentItem) => importedContentItem._id),
          value_type: 'ImportedContent',
        },
      ];
    }

    if (tracks.length > 0) {
      formattedFilters = [
        ...formattedFilters,
        { value: tracks.map((track) => track._id), value_type: 'Track' },
      ];
    }

    if (workshops.length > 0) {
      formattedFilters = [
        ...formattedFilters,
        { value: workshops.map((workshop) => workshop._id), value_type: 'Workshop' },
      ];
    }

    if (assessments.length > 0) {
      formattedFilters = [
        ...formattedFilters,
        { value: assessments.map((assessment) => assessment._id), value_type: 'Assessment' },
      ];
    }

    return formattedFilters;
  };

  interface Payload {
    name: string;
    report_fields: string[];
    report_filters: any;
    report_options: any;
    report_type: string;
  }

  const saveReport = useMutation({
    mutationFn: (payload: Payload) =>
      reportId
        ? ReportsService.updateReport(reportId, payload)
        : ReportsService.createReport(payload),
    onSuccess: () => {
      setToast({
        status: 'success',
        title: `Your report has been successfully ${reportId ? 'updated' : 'created'}.`,
        show: true,
      });

      navigate('/admin/reports');
    },
    onError: () =>
      setToast({
        status: 'error',
        title: `There was an error ${reportId ? 'updating' : 'creating'} your report.`,
        show: true,
      }),
  });

  const onSubmit: SubmitHandler<ReportFormValues> = (data) => {
    const payload = {
      name: data.reportName,
      report_type: data.report.formValue,
      report_fields: Object.keys(data.fields).filter((key) => data.fields[key]),
      report_options: {
        includeSuspendedUsers,
      },
      report_filters: formatReportFilters([...userFilters, ...contentFilters]),
    };

    saveReport.mutate(payload);
  };

  if (isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorAlert title="Error getting report" />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <AdminPrimaryHeader
          title={`${t('overviewReports.reports')}`}
          showSearch={false}
          tooltipText=""
          setSearchTerm={() => {}}
          setSortOrder={() => {}}
          goBackAction={() => navigate('/admin/reports')}
          rightElement={
            <Button
              type="submit"
              isDisabled={
                !methods.formState.isValid ||
                (selectedReport.formValue === 'track-status' && !contentFilters.length)
              }
              size="xs"
              variant="adminPrimary"
              rightIcon={<Save />}
            >
              {reportId ? 'Update' : t('global.actions.save')}
            </Button>
          }
        />

        <AdminElevatedBox>
          <FormLabelWithTooltip
            label={t('editReports.details')}
            tooltipText={`${t('editReports.details.helpText')}`}
          />

          <InputWithToolTip
            isRequired
            name="reportName"
            label={t('editReports.name')}
            tooltipText={t('editReports.reportTitleHelp')}
          />
        </AdminElevatedBox>

        {formSections.map((section) => (
          <Box
            key={section.name}
            backgroundColor="white"
            marginTop={4}
            marginX={4}
            padding={6}
            borderRadius="xl"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          >
            <FormLabelWithTooltip label={section.label} tooltipText={`${section.tooltipText}`} />

            {section.children}
          </Box>
        ))}
      </form>
    </FormProvider>
  );
}
