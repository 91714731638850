import './multiSessionWorkshop.scss';

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { Cohort, Instance } from '@/client/types/content/MultiSessionWorkshop';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import { AngleDown } from '@/client/components/icons/ContinuIcons';
import CommentList from '@/client/components/lists/CommentList';
import ContentHeader from '@/client/components/layout/ContentHeader';
import ContentService from '@/client/services/api/ContentService';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import HtmlRenderer from '@/client/components/html/HtmlRenderer';
import Loading from '@/client/components/media/Loading';
import MultiSessionWorkshopService from '@/client/services/api/content/workshops/MultiSessionWorkshopService';
import NotAuthorized from '@/client/components/data-display/content/NotAuthorized';
import PopupSurveyLayout from '@/client/components/overlay/PopupSurvey';
import RelatedWorkshopsList from '@/client/components/content/workshop/RelatedWorkshopsList';
import { format } from 'date-fns';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCheckContentAuth } from '@/client/services/hooks/content/useCheckContentAuth';
import { useCheckSurveyCompletion } from '@/client/services/hooks/useCheckSurveyCompletion';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { usePartnerStore } from '@/client/services/state/partnerStore';

interface MultiSessionWorkshopProps {
  inJourney?: boolean;
  mswId?: string;
}

export default function MultiSessionWorkshop({ inJourney, mswId }: MultiSessionWorkshopProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;
  const { id } = useParams();
  const navigate = useNavigate();
  const { allowView } = useCheckContentAuth();
  const { partner } = usePartnerStore();
  const [selectedCohort, setSelectedCohort] = useState<Cohort | undefined>(undefined);
  const [selectedVariedInstances, setSelectedVariedInstances] = useState<{
    [key: string]: Instance;
  }>({});
  const [selectedInstanceIds, setSelectedInstanceIds] = useState<string[]>([]);
  const [registerButtonDisabled, setRegisterButtonDisabled] = useState(true);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [openSurvey, setOpenSurvey] = useState(false);
  const [selectedSurveyId, setSelectedSurveyId] = useState('');
  const [selectedSurveySessionId, setSelectedSurveySessionId] = useState('');
  const { availableSurveys, handleSessionSurveys } = useCheckSurveyCompletion();
  const [completedSurveySessionIds, setCompletedSurveySessionIds] = useState<string[]>([]);
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();

  const contentId = mswId || id;

  if (!company.feature_flags.enable_multisession_workshops) {
    navigate('/explore');
  }

  useEffect(() => {
    if (isAllowed) {
      AnalyzeService.postViews(contentId, 'workshop');
      ContentService.postViews(contentId, 'workshop');
    }
  }, [isAllowed]);

  const { isError, isLoading, data, refetch } = useQuery({
    queryKey: ['workshop', contentId, user._id],
    queryFn: () => MultiSessionWorkshopService.getMultiSessionWorkshop(contentId, user._id),
    onSuccess: (successData) => {
      document.title = successData.workshop.name;
    },
    onError: (error) => console.log('Error fetching MSW: ', error),
  });

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data.workshop, true);

        setIsAllowed(allowed);
      }
    })();
  }, [data]);

  useDocumentTitle(data ? data.workshop.name : 'Workshop', true);

  const throwMswError = (message: string) => {
    setToast({
      show: true,
      status: 'error',
      title: message,
    });
  };

  const onSurveyComplete = (sessionId: string) => {
    const completedSurveySessionIdsCopy = completedSurveySessionIds.slice();
    completedSurveySessionIdsCopy.push(sessionId);
    setCompletedSurveySessionIds(completedSurveySessionIdsCopy);
  };

  const registerForCohortWorkshop = useMutation({
    mutationFn: () =>
      MultiSessionWorkshopService.registerForCohortWorkshop(
        contentId,
        selectedCohort?.id,
        user._id,
      ),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('multiSessionWorkshopViewer.successfullyRegisteredMessage', {
          name: data?.workshop.name,
        }),
      });
      refetch();
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data?.message || 'There was an error registering for this workshop';
      throwMswError(errorMessage);
    },
  });

  const registerForVariedWorkshop = useMutation({
    mutationFn: () =>
      MultiSessionWorkshopService.registerForVariedWorkshop(
        contentId,
        selectedInstanceIds,
        user._id,
      ),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('multiSessionWorkshopViewer.successfullyRegisteredMessage', {
          name: data?.workshop.name,
        }),
      });
      refetch();
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data?.message || 'There was an error registering for this workshop';
      throwMswError(errorMessage);
    },
  });

  const unregisterFromWorkshop = useMutation({
    mutationFn: () => MultiSessionWorkshopService.unregister(contentId, user._id),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('multiSessionWorkshopViewer.successfullyUnegisteredMessage', {
          name: data?.workshop.name,
        }),
      });
      refetch();
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data?.code === 409
          ? t('multiSessionWorkshopViewer.errorUnegisteringMessage')
          : err.response?.data?.message
          ? err.response?.data?.message
          : 'There was an error unregistering from this workshop.';
      throwMswError(errorMessage);
    },
  });

  const handleRegistrationClick = (workshopType: string, isRegistered: boolean) => {
    if (isRegistered) {
      unregisterFromWorkshop.mutate();
      return;
    }

    if (workshopType === 'freeform') {
      registerForVariedWorkshop.mutate();
    } else if (workshopType === 'cohort') {
      registerForCohortWorkshop.mutate();
    }
  };

  const handleSelectFreeformInstance = (sessionId: string, instance: Instance) => {
    setSelectedVariedInstances({
      ...selectedVariedInstances,
      [sessionId]: instance,
    });
  };

  // Handle user registered/unregistered states when data changes
  useEffect(() => {
    if (!data?.userRegistrationInfo.isRegistered) {
      setRegisterButtonDisabled(true);
      setSelectedCohort(undefined);
      setSelectedVariedInstances({});
      setSelectedInstanceIds([]);
      setStartDate(data?.workshop.start);
      setEndDate(data?.workshop.end);
    }

    if (data?.userRegistrationInfo.isRegistered) {
      setRegisterButtonDisabled(false);
      handleSessionSurveys(
        data?.workshop.sessions,
        data?.userRegistrationInfo.attendedSessions,
        inJourney,
      );

      if (data.workshop.type === 'cohort') {
        setSelectedCohort(data.userRegistrationInfo.registeredCohort);
      }

      if (data.workshop.type === 'freeform') {
        setSelectedVariedInstances(data.userRegistrationInfo.registeredInstancesBySessionId);
      }
    }
  }, [data]);

  // Check for registered cohort
  useEffect(() => {
    if (selectedCohort) {
      setStartDate(selectedCohort.instanceOptions[0].start);
      setEndDate(selectedCohort.instanceOptions[selectedCohort.instanceOptions.length - 1].end);
      setRegisterButtonDisabled(false);
    }
  }, [selectedCohort]);

  // Check if selected instances are chronological order
  const checkChronological = (instanceTimes: string[]) =>
    instanceTimes.every((time: string, index: number) => {
      const currentTime = time;
      const nextTime = instanceTimes[index + 1];

      if (!nextTime) return true;

      if (!currentTime || currentTime >= nextTime) {
        return false;
      }

      return true;
    });

  // Check for selected varied instances
  useEffect(() => {
    // Check if selected varied instances length is equal to the number of sessions
    if (Object.keys(selectedVariedInstances).length === data?.workshop.sessions.length) {
      const workshopSessionIds = data?.workshop.sessions.map((session) => session.id);

      // Sort the selected instances by the order of the workshop sessions
      const sortedSelectedInstanceTimes = workshopSessionIds.map(
        (sessionId) => selectedVariedInstances[sessionId].start,
      );

      const isChronlogical = checkChronological(sortedSelectedInstanceTimes);

      if (isChronlogical) {
        setSelectedInstanceIds(
          Object.values(selectedVariedInstances).map((instance) => instance.id),
        );
        setRegisterButtonDisabled(false);
        setStartDate(sortedSelectedInstanceTimes[0]);
        setEndDate(sortedSelectedInstanceTimes[sortedSelectedInstanceTimes.length - 1]);
      } else {
        setRegisterButtonDisabled(true);
        setToast({
          show: true,
          status: 'error',
          title: 'Sessions must be attended in order. Please select different dates',
        });
      }
    }
  }, [selectedVariedInstances]);

  // Check for first available survey
  useEffect(() => {
    if (Object.keys(availableSurveys).length) {
      const firstSessionWithAvailableSurvey = data?.workshop.sessions.find(
        (session) =>
          availableSurveys[session.id] === true && !completedSurveySessionIds.includes(session.id),
      );
      if (firstSessionWithAvailableSurvey) {
        setSelectedSurveySessionId(firstSessionWithAvailableSurvey.id);
        setSelectedSurveyId(firstSessionWithAvailableSurvey.details.surveys[0].survey_id);
        setOpenSurvey(true);
      }
    }
  }, [availableSurveys]);

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title="Unable to get multi session workshop" />;

  if (isAllowed === false) return <NotAuthorized />;

  return (
    <Box marginTop={50}>
      {isAllowed && (
        <>
          {!inJourney && (
            <ContentHeader
              contentType="multi-session-workshop"
              contentTitle={data.workshop.name}
              contentId={data.workshop.id}
              allowComments={data.workshop.enableComments}
              goToComments={() => setActiveTabIndex(1)}
            />
          )}

          <Box minHeight="450px" position="relative" backgroundColor="#191919">
            <Box position="absolute" top="0" left="0" width="full" height="full" overflow="hidden">
              <Box
                position="absolute"
                top="0"
                left="0"
                height="full"
                width="full"
                backgroundImage={data.workshop.bannerImage}
                backgroundSize="cover"
                filter="blur(7px)"
                transform="scale(1.07)"
              />
            </Box>

            <Container
              maxW="container.lg"
              height="full"
              minHeight="450px"
              position="relative"
              backgroundColor="white"
              paddingLeft={0}
              paddingRight={0}
            >
              <Flex direction={{ base: 'column', md: 'row' }} height="full" minHeight="450px">
                <Flex
                  flexBasis="67%"
                  backgroundImage={data.workshop.bannerImage}
                  backgroundSize="cover"
                  backgroundPosition="top center"
                />

                <Flex
                  direction="column"
                  width={{ base: 'full', md: '50%' }}
                  backgroundColor="blackAlpha.50"
                  padding={6}
                >
                  {startDate && endDate && (
                    <>
                      <Text
                        textTransform="uppercase"
                        color="brand.primary"
                        fontSize="xl"
                        fontWeight="medium"
                      >
                        {format(new Date(startDate), 'MMM')}
                      </Text>

                      <Text color="brand.primary" fontSize="3xl" fontWeight="medium">
                        {format(new Date(startDate), 'dd')}
                      </Text>

                      <Text fontSize="sm" color="blackAlpha.500">
                        {format(new Date(startDate), 'MMMM dd, yyyy')} -{' '}
                        {format(new Date(endDate), 'MMMM dd, yyyy')}
                      </Text>
                    </>
                  )}

                  <Heading as="h1" fontSize="3xl" marginY={4} fontWeight="400">
                    {data.workshop.name}
                  </Heading>

                  <Stack spacing={2}>
                    <Text
                      as="i"
                      fontSize="sm"
                      color="blackAlpha.600"
                    >{`${data.workshop.firstName} ${data.workshop.lastName}`}</Text>

                    <Text fontSize="xs">
                      {data.workshop.availableSeats > 0
                        ? t('workshopviewer.spots_left', {
                            count: data.workshop.availableSeats,
                          })
                        : t('multiSessionWorkshopViewer.noAvailableSpots')}
                    </Text>

                    <Button
                      isDisabled={registerButtonDisabled}
                      isLoading={
                        registerForCohortWorkshop.status === 'loading' ||
                        registerForVariedWorkshop.status === 'loading' ||
                        unregisterFromWorkshop.status === 'loading'
                      }
                      onClick={() =>
                        handleRegistrationClick(
                          data.workshop.type,
                          data.userRegistrationInfo.isRegistered,
                        )
                      }
                    >
                      {data.userRegistrationInfo.isRegistered
                        ? t('multiSessionWorkshopViewer.cancelRegistration')
                        : t('multiSessionWorkshopViewer.register')}
                    </Button>

                    {data.userRegistrationInfo.isRegistered && (
                      <Text>You are registered for this workshop</Text>
                    )}

                    {!data.userRegistrationInfo.isRegistered &&
                      data.workshop.type === 'cohort' &&
                      (data.workshop.availableCohorts > 0 ? (
                        <Menu>
                          <MenuButton color="brand.primary">
                            <Flex alignItems="center">
                              {selectedCohort
                                ? selectedCohort.name
                                : t('multiSessionWorkshopViewer.selectCohort')}

                              <AngleDown marginLeft={4} />
                            </Flex>
                          </MenuButton>

                          <MenuList>
                            {data.workshop.cohorts.map((cohort) => {
                              if (!cohort.isAvailable) return;

                              return (
                                <MenuItem key={cohort.id} onClick={() => setSelectedCohort(cohort)}>
                                  {cohort.name}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </Menu>
                      ) : (
                        <Text>{t('multiSessionWorkshopViewer.workshopFull')}</Text>
                      ))}
                  </Stack>
                </Flex>
              </Flex>
            </Container>
          </Box>

          <Container maxW="container.lg" paddingTop={10} marginBottom={24}>
            <Tabs
              index={activeTabIndex}
              width="full"
              borderBottomColor="transparent"
              onChange={(index) => setActiveTabIndex(index)}
            >
              {data.workshop.enableComments && !partner._id && (
                <TabList>
                  <Tab>{t('contentViews.events.info_tabName')}</Tab>
                  <Tab>{t('contentViews.events.discussion_tabName')}</Tab>
                </TabList>
              )}
              <TabPanels>
                <TabPanel>
                  <HtmlRenderer html={data.workshop.description} />

                  {data.workshop.sessions.map((session, index) => (
                    <Flex key={session.id} borderBottom="1px" borderColor="gray.300" marginTop={10}>
                      <Box
                        flex=".50"
                        height="8rem"
                        backgroundColor="brand.primary"
                        background={`url(${session.details.bannerImage})`}
                        backgroundSize="cover"
                        backgroundRepeat="no-repeat"
                        backgroundPosition="center"
                        borderRadius="lg"
                        marginRight={6}
                      />

                      <Stack spacing={4} flex="2">
                        <Text fontWeight="bold">{session.details.name}</Text>

                        {data.workshop.type === 'cohort' &&
                          (selectedCohort ? (
                            <>
                              <Text fontSize="xs" color="blackAlpha.500">
                                {selectedCohort.instanceOptions[index].formattedDate}
                              </Text>

                              <Text fontSize="xs" fontWeight="bold" color="blackAlpha.500">
                                Facilitated by:{' '}
                                {selectedCohort.instanceOptions[index].facilitatorName}
                              </Text>
                            </>
                          ) : (
                            <Text fontSize="xs" color="blackAlpha.500">
                              {t('multiSessionWorkshopViewer.dropdownNeedsCohort')}
                            </Text>
                          ))}

                        {data.workshop.type === 'freeform' &&
                          data.userRegistrationInfo.isRegistered &&
                          selectedVariedInstances[session.id] && (
                            <Text fontSize="xs" color="blackAlpha.500">
                              {selectedVariedInstances[session.id].formattedDate}
                            </Text>
                          )}

                        {data.workshop.type === 'freeform' &&
                          !data.userRegistrationInfo.isRegistered &&
                          (session.availableInstances.length > 0 ? (
                            <Menu>
                              <MenuButton fontSize="sm">
                                <Flex alignItems="center">
                                  {selectedVariedInstances[session.id]
                                    ? selectedVariedInstances[session.id].formattedDate
                                    : t('multiSessionWorkshopViewer.dropdownSeeAvailableTimes')}
                                  <AngleDown marginLeft={4} />
                                </Flex>
                              </MenuButton>

                              <MenuList maxHeight="300px" overflowY="auto">
                                {session.availableInstances.map((instanceOption) => (
                                  <MenuItem
                                    key={instanceOption.id}
                                    onClick={() =>
                                      handleSelectFreeformInstance(session.id, instanceOption)
                                    }
                                  >
                                    {instanceOption.formattedDate}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </Menu>
                          ) : (
                            <Text fontSize="sm">
                              {t('multiSessionWorkshopViewer.dropDownNoDates')}
                            </Text>
                          ))}
                        {availableSurveys[session.id] &&
                          !completedSurveySessionIds.includes(session.id) && (
                            <Button
                              onClick={() => {
                                setSelectedSurveyId(session.details.surveys[0].survey_id);
                                setSelectedSurveySessionId(session.id);
                                setOpenSurvey(true);
                              }}
                              backgroundColor={
                                selectedSurveySessionId === session.id
                                  ? 'brand.green'
                                  : 'brand.primary'
                              }
                              _hover={{
                                backgroundColor:
                                  selectedSurveySessionId === session.id
                                    ? 'brand.green'
                                    : 'brand.primary',
                              }}
                              fontSize="xs"
                              width={40}
                              height={6}
                            >
                              {selectedSurveySessionId === session.id
                                ? 'Complete Survey Below'
                                : t('surveys.viewing.prompt')}
                            </Button>
                          )}

                        {data.workshop.type === 'freeform' &&
                          selectedVariedInstances[session.id] && (
                            <Text fontSize="xs" fontWeight="bold" color="blackAlpha.500">
                              Facilitated by: {selectedVariedInstances[session.id].facilitatorName}
                            </Text>
                          )}

                        <Box className="sessionDetails">
                          <HtmlRenderer html={session.details.description} />
                        </Box>
                      </Stack>
                    </Flex>
                  ))}
                </TabPanel>

                {data.workshop.enableComments && !partner._id && (
                  <TabPanel>
                    <CommentList
                      contentId={data.workshop.id}
                      contentType="workshop"
                      contentTitle={data.workshop.name}
                    />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>

            {data.relatedWorkshops.length > 0 && (
              <RelatedWorkshopsList relatedWorkshops={data.relatedWorkshops} />
            )}
          </Container>
          {openSurvey && (
            <PopupSurveyLayout
              surveyId={selectedSurveyId}
              contentId={selectedSurveySessionId}
              contentType="workshop"
              onCompleteAction={() => onSurveyComplete(selectedSurveySessionId)}
            />
          )}
        </>
      )}
    </Box>
  );
}

MultiSessionWorkshop.defaultProps = {
  inJourney: false,
  mswId: '',
};
