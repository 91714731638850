import type { ContentArchiveData } from '@/client/types/admin/content-authoring/shared/ContentArchiveData';
import { learnApiClient } from '../../clients/learnApiClient';

export interface ArchiveContentParams {
  id: string | undefined;
  type:
    | 'article'
    | 'file'
    | 'video'
    | 'scorm'
    | 'product'
    | 'track'
    | 'linkedin'
    | 'udemy'
    | 'masterclass'
    | null;
}

const archiveContent = async (payload: ArchiveContentParams): Promise<ContentArchiveData> => {
  const response = await learnApiClient.post(`viewables/archive`, {
    content: [payload],
  });

  return response.data;
};

const AuthoringSharedService = {
  archiveContent,
};

export default AuthoringSharedService;
