/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Circle,
  ComponentWithAs,
  Flex,
  Icon,
  IconProps,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  Article,
  Files,
  Video,
  Scorm,
  ImportedContent,
  Track,
  Workshop,
  Assessment,
  Journey,
} from '@/client/components/icons/ContinuIcons';
import { CiCircleCheck, CiCirclePlus } from 'react-icons/ci';
import CreateFormGroup from '@/client/components/admin/create/shared/layout/CreateFormGroup';
import NoSearchResultsListItem from '@/client/components/admin/create/content/lists/NoSearchResultsListItem';
import { useAuthoringContentSearch } from '@/client/services/hooks/admin/authoring/useAuthoringContentSearch';
import { useCombobox } from 'downshift';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Product } from '@/client/services/api/graphql/gql/graphql';
import { AdminContent } from '@/client/types/content/AdminContent';
import AuthoringContentList from '../../lists/AuthoringContentList';

interface AuthoringSegmentationSearchProps {
  selectedItems: any[]
  setSelectedItems: (items: any[]) => void;
  formField: string;
  includeJourneys: boolean;
}

export default function AuthoringContentSearch({selectedItems,
  setSelectedItems, formField, includeJourneys}: AuthoringSegmentationSearchProps) {
  const { t } = useTranslation();
  const { searchTerm, setSearchTerm, isFetching, data } = useAuthoringContentSearch(includeJourneys);
  const { setValue } = useFormContext<Product>();
  
  const typeIconMap: {[key: string]: ComponentWithAs<'svg', IconProps>} = {
    article: Article,
    file: Files,
    video: Video,
    scorm: Scorm,
    imported_content: ImportedContent,
    track: Track,
    workshop: Workshop,
    assessment: Assessment,
    journey: Journey,
  };

  const checkResultsExist = (d: any) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const section of d) {
      if (section.options.length > 0) {
        return true;
      }
    }
    return false;
  }

  const flattenGroupOptions = (options: any) =>
    options.reduce((prev: any, curr: any) => [...prev, ...curr.options], []);

  const { getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
    onInputValueChange({ inputValue }) {
      setSearchTerm(inputValue || '');
    },
    items: flattenGroupOptions(data || []) || [],
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        return;
      }

      const existingMatch = selectedItems.find(
        // @ts-ignore
        (item) => item.id === selectedItem._id,
      );

      if (existingMatch) {
        setValue(
          // @ts-ignore
          formField,
          // @ts-ignore
          selectedItems.filter((item: any) => item.id !== selectedItem._id),
        );
      } else {
        const index = selectedItems.indexOf(selectedItem);

        if (index > 0) {
          setSelectedItems([...selectedItems.slice(0, index), ...selectedItems.slice(index + 1)]);
        } else if (index === 0) {
          setSelectedItems([...selectedItems.slice(1)]);
        } else {
          setSelectedItems([...selectedItems, selectedItem]);
        }
      }
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep menu open after selection.
            highlightedIndex: state.highlightedIndex,
            inputValue: '', // don't add the item string as input value at selection.
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '', // don't add the item string as input value at selection.
          };
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
          return {
            ...changes,
            inputValue: state.inputValue,
          };
        default:
          return changes;
      }
    },
  });

  return (
    <CreateFormGroup renderBorder={false}>
      <Stack spacing={4}>
        <Text variant="createHeading">{t('authoring.tabs.addContent')}</Text>
        <Text variant="createHelpText">{t('authoring.product.addContentSubHeading')}</Text>

        <InputGroup>
          <Input
            variant="create"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder={`${t('global.forms.labels_search')}`}
            {...getInputProps()}
          />

          <InputRightElement>{isFetching && <Spinner size="sm" />}</InputRightElement>
        </InputGroup>

        <List
          flexDirection="column"
          // border={data ? '1px solid' : 'none'}
          borderColor="warmNeutral.500"
          borderRadius="md"
          background="white"
          overflowY="scroll"
          paddingX={6}
          zIndex={777}
          maxH={400}
          width="inherit"
          {...getMenuProps()}
        >
          {data && !checkResultsExist(data) && <NoSearchResultsListItem />}

          {data &&
            data.length > 0 &&
            data.reduce(
              (results: any, section: any, sectionIndex: any) => {
                if (section.options?.length > 0) {
                  results.sections.push(
                    <Box
                      key={`results_section_${sectionIndex + 1}`}
                      paddingY={4}
                      borderBottom="1px solid"
                      borderColor="warmNeutral.100"
                    >
                      {section.options.length > 0 && (
                        <Text variant="createLabel" marginY={2}>
                          {section.title}
                        </Text>
                      )}

                      {section.options.length > 0 &&
                        section.options.map((option: AdminContent, optionIndex: any) => {
                          // eslint-disable-next-line no-plusplus, no-param-reassign
                          const resultIndex = results.itemIndex++;
                          const isSelected = selectedItems.find(
                            (item: any) => item.id === option._id,
                          );

                          return (
                            <ListItem
                              key={`option_index_${optionIndex + 1}`}
                              paddingX={4}
                              paddingY={2}
                              _hover={{
                                cursor: 'pointer',
                              }}
                              {...getItemProps({
                                // @ts-ignore
                                item: option,
                                index: resultIndex,
                                'aria-selected': isSelected,
                              })}
                              backgroundColor={
                                highlightedIndex === resultIndex ? 'warmNeutral.0' : 'white'
                              }
                              border={highlightedIndex === resultIndex ? '1px solid' : 'none'}
                              borderColor="warmNeutral.400"
                              borderRadius="6px"
                            >
                              <Flex alignItems="center" width="full" justifyContent="space-between">
                                <Circle
                                  backgroundColor={isSelected ? 'neutral.50' : 'neutral.100'}
                                  size="30px"
                                >
                                  <Icon
                                    color={isSelected ? 'neutral.1000' : 'neutral.1200'}
                                    as={typeIconMap[option.type]}
                                  />
                                </Circle>

                                <Box flexGrow={1} textAlign="left" paddingLeft={4}>
                                  <Text fontSize="sm" fontWeight={500} color="neutral.1200">
                                    {option.title || option.name}
                                  </Text>
                                </Box>

                                <Icon
                                  boxSize={8}
                                  as={isSelected ? CiCircleCheck : CiCirclePlus}
                                  color={isSelected ? 'warmNeutral.300' : 'neutral.800'}
                                />
                              </Flex>
                            </ListItem>
                          );
                        })}
                    </Box>,
                  );
                }

                return results;
              },
              { sections: [], itemIndex: 0 },
            ).sections}
        </List>

        <Stack spacing={4} marginY={2}>
          <AuthoringContentList />
        </Stack>
      </Stack>
    </CreateFormGroup>
  );
}
