/* eslint react/no-unstable-nested-components: 0 */

import { Box, Flex, Link, Tooltip } from '@chakra-ui/react';
import { View, CircleFilled, Assign, Users } from '@/client/components/icons/ContinuIcons';

import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import TableActionsMenu from '@/client/components/admin/menus/TableActionsMenu';

import Loading from '@/client/components/media/Loading';
import PartnersManagerHeader from '@/client/components/layout/PartnersManagerHeader';
import PartnersService from '@/client/services/api/admin/PartnersService';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useRenderContentIcon } from '@/client/services/hooks/useRenderContentIcon';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { DateFormatGroupKey, dateFormatTenant } from '@/client/utils/dateFormat';
import { useAuthStore } from '@/client/services/state/authStore';
import { usePartnerStore } from '@/client/services/state/partnerStore';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { AdminContent } from '@/client/types/content/AdminContent';
import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import { WIDTH_ACTION_CELL, WIDTH_DATE_CELL, WIDTH_DATE_TIME_CELL } from '@/client/components/admin/tables/cells/CellSizes';

// TODO: Add published?
// TODO: Decide on Table actions
// TODO: Add a fetch for the content count for pagination
// TODO: Ask about content filtering by type

export default function PartnerManagerContentList() {
  const { partner } = usePartnerStore();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle(t('partners.manager.contentListTitle'));

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );
  

  const { data, isLoading } = useQuery({
    queryKey: ['partner-content', fetchDataOptions, partner._id],
    queryFn: () => PartnersService.getAllPartnerContent(fetchDataOptions, partner._id),
    enabled: !!partner._id,
  });

  const columnHelper = createColumnHelper<AdminContent>();
  const contentColumns = useMemo<ColumnDefBase<AdminContent, any>[]>(
    () => [
      columnHelper.display({
        id: 'activity',
        cell: (info) => (
          // TODO: Look at context in link
          <Flex justifyContent="center" marginLeft={2}>
            <Link as={RouterLink} to={`./${info.row.original.type}/${info.row.original._id}/activity`}>
              <Users boxSize={5} color="brand.primary" />
            </Link>
          </Flex>
        ),
        header: () => (
          <Box textAlign="center" marginLeft={2}>
            {t('dashboard.activity.tabName')}
          </Box>
        ),
        footer: (info) => info.column.id,
        size: 80,
      }),
      columnHelper.display({
        id: 'view',
        cell: (info) => (
          // TODO: Look at context in link
          <Flex justifyContent="center">
            <Link as={RouterLink} to={`/${info.row.original.type}/${info.row.original._id}`}>
              <View boxSize={5} color="brand.primary" />
            </Link>
          </Flex>
        ),
        header: () => (
          <Box textAlign="center">
            {t('dashboard.assignments.continue')}
          </Box>
        ),
        footer: (info) => info.column.id,
        size: 60,
      }),
      columnHelper.accessor((row) => row.title ?? row.name, {
        id: 'title',
        cell: (info) => info.getValue(),
        header: () => <span>{t('global.forms.labels_title')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('explore_hide', {
        cell: (info) => (
          <Box>
            <Flex alignItems="center" marginLeft={9}>
              <CircleFilled color={info.getValue() ? 'red' : 'green'} />
            </Flex>
          </Box>
        ),
        header: () => <span>{t('contentManager.on_explore')}</span>,
        footer: (info) => info.column.id,
        size: 120,
      }),
      columnHelper.accessor('type', {
        cell: (info) => (
          <Box>
            <Tooltip label={info.getValue()}>{useRenderContentIcon(info.getValue())}</Tooltip>
          </Box>
        ),

        header: () => <span>{t('global.forms.labels_type')}</span>,
        footer: (info) => info.column.id,
        size: 60,
      }),
      columnHelper.accessor('updatedAt', {
        cell: (info) => (
          <span>
            {info.getValue()
              ? dateFormatTenant(
                  new Date(info.getValue()),
                  company.date_display as DateFormatGroupKey,
                  'baseWithTime',
                )
              : '-----'}
          </span>
        ),
        header: () => <span>{t('manage.lastUpdated')}</span>,
        footer: (info) => info.column.id,
        size: WIDTH_DATE_TIME_CELL,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <TableActionsMenu
            key={`actions_${info.row.original._id}`}
            additionalActions={[
              {
                label: 'Assign',
                icon: <Assign color="brand.button" />,
                onClick: () =>
                  navigate(`./${info.row.original.type}/${info.row.original._id}/assign`),
              },
            ]}
          />
        ),
        size: WIDTH_ACTION_CELL,
      }),
    ],
    [],
  );

  if (isLoading) return <Loading />;

  return (
    <Box minHeight="100vh" paddingTop={2} backgroundColor="brand.mist">
      <PartnersManagerHeader currentTab="content" />
      <AdminElevatedBox>
        <ManuallyPaginatedTable
          columns={contentColumns}
          queryData={data}
          pagination={pagination}
          setPagination={setPagination}
          useTableLayoutFixed
        />
      </AdminElevatedBox>
    </Box>
  );
}
