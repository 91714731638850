import { LineChart } from '../LineChart';
import { useEffect } from 'react';
import { useGetReportData } from '@/client/services/hooks/admin/useGetReportData';
import { useTranslation } from 'react-i18next';

export function AssignmentsFromWorkflows() {
  const { dateRange, setDateRange, getDataBasedOnDateRange, getAssignmentsFromWorkflowData } =
    useGetReportData();
  const { data, refetch } = getAssignmentsFromWorkflowData;
  const { t } = useTranslation();

  useEffect(() => {
    getDataBasedOnDateRange(refetch);
  }, [dateRange]);

  return (
    <LineChart
      data={data || []}
      colSpan={2}
      rowSpan={1}
      dateRange={dateRange}
      setDateRange={setDateRange}
      dateLocale={{ format: 'en_US' }}
      title={t('partners.manager.dashboard.assignmentBreakdown')}
      tooltip={t('partners.manager.dashboard.assignmentBreakdown.help')}
    />
  );
}
