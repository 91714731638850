import { Box, IconButton, Tooltip } from '@chakra-ui/react';

interface IconButtonWithTooltipProps {
  tooltipCopy: string;
  icon: JSX.Element;
  ariaLabel: string;
  onClick: () => void;
}

export default function IconButtonWithTooltip({
  tooltipCopy,
  icon,
  ariaLabel,
  onClick,
}: IconButtonWithTooltipProps) {
  return (
    <Box>
      <Tooltip label={tooltipCopy}>
        <IconButton variant="unstyled" aria-label={ariaLabel} icon={icon} onClick={onClick} />
      </Tooltip>
    </Box>
  );
}
