import {
  Box,
  Button,
  HStack,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Content, TextGenerationStatus } from '@/client/services/api/graphql/gql/graphql';
import { useRef, useState } from 'react';

import { CloseIcon } from '@chakra-ui/icons';
import { DefaultTFuncReturn } from 'i18next';
import { FileFormData } from '@/client/routes/admin/create/content/EditFile';
import GenerativeAiModal from '../content/overlay/GenerativeAiModal';
import GenerativeService from '@/client/services/api/graphql/GenerativeService';
import { IoSparklesSharp } from 'react-icons/io5';
import TinyMceEditor from './TinyMceEditor';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface GenerativeDescriptionProps {
  placeholder?: string | DefaultTFuncReturn;
  enableAiPopover?: boolean;
  label: string | DefaultTFuncReturn;
}

export default function GenerativeDescription({
  placeholder,
  enableAiPopover,
  label,
}: GenerativeDescriptionProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { watch } = useFormContext<Content & FileFormData>();
  const { isOpen: isPopoverOpen, onOpen: onPopoverOpen, onClose: onPopoverClose } = useDisclosure();
  const {
    isOpen: isGenerativeAiModalOpen,
    onOpen: onGenerativeAiModalOpen,
    onClose: onGenerativeAiModalClose,
  } = useDisclosure();
  const editorRef = useRef<any>(null);
  const [aiDescription, setAiDescription] = useState<string>('');

  const textGenerationStatus = watch('textGenerationStatus');
  const id = watch('id');

  const isLoading = !!(
    textGenerationStatus === undefined || textGenerationStatus === TextGenerationStatus.InProgress
  );

  const mediaDescription = useMutation({
    mutationFn: (contentId: string) => GenerativeService.getGenerativeDescriptionById(contentId),
    onSuccess: (data: string) => {
      setAiDescription(data);
      setToast({
        show: true,
        status: 'success',
        title: t('authoring.media.description.generateWithAi.success'),
      });
    },
    onError: (error) => {
      console.log('Failed to retrieve AI generated description: ', error);
      setToast({
        show: true,
        status: 'error',
        title: t('authoring.media.description.generateWithAi.failure'),
      });
    },
  });

  const closeModal = () => {
    onGenerativeAiModalClose();
    onPopoverClose();
  };

  const popoverFocus = () => {
    if (enableAiPopover) {
      onPopoverOpen();
    }
  };

  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        onClose={onPopoverClose}
        placement="top-end"
        closeOnBlur={false}
        initialFocusRef={editorRef}
      >
        <PopoverTrigger>
          <Box>
            <TinyMceEditor
              isRequired
              formName="description"
              placeholder={placeholder}
              onFocus={popoverFocus}
              popoverRef={editorRef}
              label={label}
            />
          </Box>
        </PopoverTrigger>

        <PopoverContent
          marginBottom={-10}
          backgroundColor="#E2B670"
          color="white"
          border="2px solid"
          borderColor="warmNeutral.300"
          width="fit-content"
          height="fit-content"
          borderRadius="full"
        >
          <PopoverArrow
            backgroundColor="#E2B670"
            borderBottom="2px solid"
            borderRight="2px solid"
            borderBottomColor="warmNeutral.300"
            borderRightColor="warmNeutral.300"
            top="2px !important"
            left="15px !important"
            boxShadow="none"
          />

          <PopoverBody
            height="fit-content"
            width="fit-content"
            paddingY={0}
            paddingX={2}
            borderRadius="full"
          >
            <HStack>
              <Button
                borderRadius="full"
                size="sm"
                fontSize="12px"
                backgroundColor="#E2B670"
                leftIcon={<IoSparklesSharp />}
                isLoading={isLoading}
                loadingText={t('authoring.generateWithAi.processing')}
                _hover={{ backgroundColor: '#E2B670' }}
                _loading={{ backgroundColor: '#E2B670' }}
                onClick={() => {
                  onGenerativeAiModalOpen();
                  mediaDescription.mutate(id);
                }}
              >
                <Text
                  opacity={isLoading ? 0.7 : 1}
                  textDecoration={isLoading ? 'none' : 'underline'}
                >
                  {t('authoring.media.description.generateWithAi')}
                </Text>
              </Button>

              {!isLoading && (
                <IconButton
                  padding={1}
                  borderRadius="full"
                  backgroundColor="white"
                  aria-label="Close"
                  size="2xs"
                  variant="ghost"
                  icon={<CloseIcon height={2} width={2} />}
                  color="#E2B670"
                  _hover={{ color: '#E2B670' }}
                  onClick={onPopoverClose}
                />
              )}
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <GenerativeAiModal
        data={aiDescription}
        isLoading={mediaDescription.isLoading}
        isOpen={isGenerativeAiModalOpen}
        onClose={closeModal}
      />
    </>
  );
}

GenerativeDescription.defaultProps = {
  placeholder: null,
  enableAiPopover: false,
};
