import { Assessment, UserAssessment } from '@/client/types/content/assessments/Assessment';

import { AssessmentScores } from '@/client/types/content/assessments/AssessmentScores';
import { learnApiClient } from './clients/learnApiClient';

const getAssessment = async (id: string | undefined): Promise<Assessment> => {
  const response = await learnApiClient.get(
    `assessments/take/${id}?fields=company,user,questions,title,description,archived,private,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,linked_category,image,banner_image,cover_justification,featured_justification,explore_hide,tags,published,allow_retake,number_of_retakes,show_user_correct,show_user_correct_after_fail,show_user_grade,video_assessment,file_assessment,creator_name,certificate,certificate_data,hide_cover,metadata&user-populate=first_name,last_name,email`
  );

  return response.data;
};

const getUserAssessment = async (contextId: string | undefined): Promise<UserAssessment> => {
  const assessmentResponse = await learnApiClient.get(
      `user-assessments/${contextId}?fields=user,assessment,in_progress_responses,scores,passed,date_passed,awaiting_manual_grade`
  );
  return assessmentResponse.data;
};

const sendInProgressResponses = async (
  userAssessmentId: string,
  questionid: string,
  answers: string[] | { link: string; title: string }[] | null
) => {
  const response = await learnApiClient.post(`user-assessments/${userAssessmentId}?fields=in_progress_responses`, {
    in_progress_responses: {
      [questionid]: answers,
    },
  });

  return response.data;
};

const sendInProgressTextAssessmentResponses = async (
  userAssessmentId: string,
  payload: { [key: string]: string[] }
): Promise<{ _id: string; in_progress_responses: { [key: string]: string[] } }> => {
  const response = await learnApiClient.post(`user-assessments/${userAssessmentId}?fields=in_progress_responses`, {
    in_progress_responses: payload,
  });

  return response.data;
};

const getAssessmentScores = async (userAssessmentId: string): Promise<AssessmentScores> => {
  const response = await learnApiClient.get(
    `assessment-scores/${userAssessmentId}?fields=max_percentage,assessment_when_scored,graded_responses`
  );

  return response.data;
};

const AssessmentService = {
  getAssessment,
  getUserAssessment,
  sendInProgressResponses,
  getAssessmentScores,
  sendInProgressTextAssessmentResponses,
};

export default AssessmentService;
