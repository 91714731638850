import { learnApiClient } from "../clients/learnApiClient";

const getAllProfiles = async () => {
  const allProfiles = await learnApiClient.get<any[]>('access-profiles');
  return allProfiles.data;
}

const ProfilesService = {
  getAllProfiles
}

export default ProfilesService;