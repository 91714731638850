import { AuthConfig } from '@/client/types/AuthConfig';
import { Config } from '@/client/types/Config';
import { useState } from 'react';

export const useIntercom = () => {
  const [intercomBooted, setIntercomBooted] = useState(false);
  const [toggleIntercomStatus, setToggleIntercomStatus] = useState<'hidden' | 'showing'>('hidden');

  const timeConvert = (date: number) => {
    let dateValue = new Date(date).valueOf();

    return parseInt(dateValue.toString().slice(0, -3));
  };

  const bootIntercomUser = (config: Config, authConfig: AuthConfig) => {
    if (intercomBooted) {
      return;
    }

    var app_id = config.intercomAppId;
    var company = authConfig.company;
    var user = authConfig.user;

    if (!user.email) {
      return;
    }

    // Set Google Analytics
    if (company && company.google_analytics) {
      let ga =
        window.ga ||
        function () {
          (ga.q = ga.q || []).push(arguments);
        };
      ga.l = +new Date();

      ga.l = +new Date();

      ga('create', company.google_analytics, 'auto');
    }
    // End Google Analytics

    if (!user || !user.first_name || !company || !company.name) {
      return;
    }

    let created = user.first_login || user.createdAt || user.created_at;

    window.Intercom &&
      window.Intercom('boot', {
        app_id: app_id,
        user_id: user._id,
        name: user.first_name + ' ' + user.last_name,
        email: user.email,
        created_at: timeConvert(+created),
        role: user.role,
        department: user.department,
        is_manager: user.is_manager ? true : false,
        manager_email: user.manager_email ? user.manager_email : '',
        has_avatar: user.image ? true : false,
        banner_image: user.banner_image ? true : false,
        about: user.about ? true : false,
        skills: user.skills && user.skills.length ? true : false,
        company: {
          id: company._id,
          name: company.name,
          created_at: timeConvert(+company.createdAt),
        },
        widget: {
          activator: '#IntercomDefaultWidget',
        },
        hide_default_launcher: true,
      });

    setIntercomBooted(true);
  };

  const intercomUpdate = () => {
    setTimeout(() => {
      window.Intercom && window.Intercom('update');
    }, 3);
  };

  const intercomShutdown = () => {
    if (!intercomBooted) {
      return;
    }

    window.Intercom && window.Intercom('shutdown');
    setIntercomBooted(false);
  };

  return {
    intercomBooted,
    toggleIntercomStatus,
    setIntercomBooted,
    setToggleIntercomStatus,
    bootIntercomUser,
    intercomUpdate,
    intercomShutdown,
  };
};
