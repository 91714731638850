export const formatTrack = (sections: any[], userCompleted: any, isTrackSequential: boolean) => {
  const completedContentIds: string[] = [
    ...userCompleted.completed_media,
    ...userCompleted.completed_articles,
    ...userCompleted.completed_quizzes,
    ...userCompleted.completed_assessments,
    ...userCompleted.completed_scorm,
    ];

  const sortedSections = sections.sort((a, b) => {
    return a.position - b.position;
  });

  const finalSort: any[] = sortedSections.map((section) => {
    const sortedCourses = section.courses.sort((a: any, b: any) => {
      return a.position - b.position;
    });

    return {
      ...section,
      courses: sortedCourses,
    };
  });

  finalSort.forEach((section) => {
    section.courses.forEach((course: any) => {
      setContentParams(course, completedContentIds);
    });

    setSectionComplete(section);
  });

  const formattedTrack = {
    sections: setAvailability(finalSort, completedContentIds, isTrackSequential),
    totalDuration: totalDuration(finalSort),
    nextAvailableContent: setNextContent(
      completedContentIds,
      userCompleted[0] ? userCompleted[0].last_viewed : '',
      finalSort
    ),
    trackProgress: setTrackProgress(finalSort),
  };

  return formattedTrack;
};

const setSectionComplete = (section: any) => {
  let total = 0;

  section.courses.forEach((course: any) => {
    course.contentCompleted ? total++ : null;
  });

  total === section.courses.length ? (section.complete = true) : (section.complete = false);
};

const setContentParams = (course: any, completedIds: string[]) => {
  const contentTypes = ['video', 'article', 'file', 'assessment', 'scorm'];

  Object.keys(course).forEach((key) => {
    if (contentTypes.includes(key) && course[key] !== null) {
      course.contentType = course[key].type;
      course.contentTitle = course[key].title;
      course.contentId = course[key]._id;
      course.contentDuration = course[key].duration ?? 0;
      course.contentLink = `/${course[key].type}/${course[key]._id}`;
      course.contentCompleted = completedIds.includes(course[key]._id);
    }
  });

  return course;
};

const totalDuration = (track: any) => {
  let duration = 0;

  track.forEach((section: any) => {
    section.courses.forEach((course: any) => (course.contentDuration ? (duration += course.contentDuration) : null));
  });

  return duration;
};

const setNextContent = (completedIds: string[], lastViewed: string, sections: any[]) => {
  let nextAvailableContent;

  if (completedIds.length === 0 && !lastViewed) {
    nextAvailableContent = sections[0].courses[0];
  } else {
    sections.forEach((section) => {
      section.courses.filter((course: any) => {
        if (course.contentId === lastViewed && !completedIds.includes(lastViewed)) {
          nextAvailableContent = course;
          return;
        } else if (course.available && !course.completed) {
          nextAvailableContent = course;
          return;
        }
      });
    });
  }

  return nextAvailableContent;
};

const setTrackProgress = (sections: any[]) => {
  let complete = 0;
  let incomplete = 0;

  sections.forEach((section) => {
    section.courses.forEach((course: any) => {
      course.contentCompleted ? complete++ : incomplete++;
    });
  });

  return Math.floor((complete / (complete + incomplete)) * 100);
};

const setAvailability = (sections: any[], userCompletedIds: string[], isTrackSequential: boolean) => {
  let previousComplete = false;
  let earliestNext = {
    section: 0,
    course: 0,
  };
  let firstUnfinished: unknown = null;

  sections.forEach((section: any, index: number) => {
    if (!section.courses || !section.courses.length) {
      return;
    }

    for (var i = 0; i < section.courses.length; i++) {
      // totalCourses++;
      section.courses[i].completed = false;
      section.courses[i].available = false;

      if (userCompletedIds.includes(section.courses[i].contentId)) {
        section.courses[i].completed = true;
        section.courses[i].available = true;

        previousComplete = true;
        // completedCourses++;
        continue;
      }

      if (!isTrackSequential) {
        section.courses[i].available = true;

        if (!earliestNext) {
          earliestNext = {
            section: index,
            course: i,
          };
        }

        previousComplete = false;
        continue;
      }

      if (index === 0 && i === 0) {
        section.courses[i].available = true;
        previousComplete = false;
        firstUnfinished = true;
        continue;
      }

      if (previousComplete && !firstUnfinished) {
        previousComplete = false;

        section.courses[i].available = true;

        if (!earliestNext) {
          earliestNext = {
            section: index,
            course: i,
          };
        }

        firstUnfinished = true;
        continue;
      }
      previousComplete = false;
    }
  });

  // if (this.progressFunction) {
  //   this.progressFunction({
  //     percentComplete: Math.floor((this.completedCourses / this.totalCourses) * 100),
  //     nextSectionIndex: earliestNext.section,
  //     nextCourseIndex: earliestNext.course,
  //   });
  // }

  return sections;
};
