import 'tui-image-editor/dist/tui-image-editor.css';

import { Box, Button, HStack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import User from '@/client/services/api/User';
import axios from 'axios';
import { useRef } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import v3ApiService from '@/client/services/api/clients/v3ApiClient';
import ImageEditor from './TuiImageEditorComponent';

type BannerEditorProps = {
  userId: string;
  banner: any;
  setBanner: (banner: any) => void;
  setBannerUploading: (uploading: boolean) => void;
};

export default function BannerEditor({
  userId,
  banner,
  setBanner,
  setBannerUploading,
}: BannerEditorProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const queryClient = useQueryClient();
  const bannerEditorRef = useRef<any>(null);

  const updateUserBannerImage = useMutation({
    mutationFn: async (url: string) => User.updateBannerImage(userId, url),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] });
      setBannerUploading(false);
      setBanner({ bannerEditorModalOpen: false, img: null });
      setToast({
        show: true,
        status: 'success',
        title: t('modules.notifications.profile_updated'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('modules.notifications.canNotUpdateProfile'),
      }),
  });

  const tuiTheme = {
    'header.display': 'none',
  };

  const handleBannerSave = () => {
    const bannerEditor = bannerEditorRef.current.getInstance();

    const dataURItoBlob = (dataURI: string) => {
      const binary = window.atob(dataURI.split(',')[1]);
      let array = [];

      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }

      return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    };

    const dataUrl = bannerEditor.toDataURL();

    const file = new File([dataURItoBlob(dataUrl)], 'uploaded-img.jpeg');

    v3ApiService
      .getS3ImagePolicy(file.type, file.name)
      .then((policy) => {
        let formData = new FormData();

        formData.append('key', policy.Key);
        formData.append('Content-Type', policy.ContentType);
        formData.append('AWSAccessKeyId', policy.AWSAccessKeyId);
        formData.append('success_action_status', '201');
        formData.append('policy', policy.S3Policy);
        formData.append('signature', policy.S3Signature);
        formData.append('file', file);

        setBannerUploading(true);

        axios
          .post('https://' + policy.Bucket + '.s3.amazonaws.com/', formData)
          .then(() => {
            if (!policy.CloudFrontLink) return;

            updateUserBannerImage.mutate(policy.CloudFrontLink);
          })
          .catch((err) => {
            console.log(err);
            setToast({
              show: true,
              status: 'error',
              title: t('modules.notifications.canNotUpdateProfile'),
            });
          });
      })
      .catch((err) => {
        console.log(err);
        setToast({
          show: true,
          status: 'error',
          title: t('modules.notifications.canNotUpdateProfile'),
        });
      });
  };

  return (
    <Box>
      <ImageEditor
        ref={bannerEditorRef}
        includeUI={{
          loadImage: {
            path: banner.img ? URL.createObjectURL(banner.img) : null,
            name: 'SampleImage',
          },
          theme: tuiTheme,
          menu: ['filter', 'crop', 'flip', 'rotate'],
          uiSize: {
            width: '1000px',
            height: '700px',
          },
          menuBarPosition: 'bottom',
        }}
        cssMaxHeight={500}
        cssMaxWidth={700}
        selectionStyle={{
          cornerSize: 20,
          rotatingPointOffset: 70,
        }}
      />

      <HStack marginTop={4} spacing={4}>
        <Button onClick={() => handleBannerSave()}>{t('global.actions.save')}</Button>

        <Button
          backgroundColor="gray.300"
          color="gray.700"
          _hover={{ backgroundColor: 'gray.400' }}
          onClick={() =>
            setBanner({
              bannerEditorModalOpen: false,
              img: null,
            })
          }
        >
          {t('global.actions.cancel')}
        </Button>
      </HStack>
    </Box>
  );
}
