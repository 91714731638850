import { Flex, Text, VStack } from '@chakra-ui/react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useAuthStore } from '@/client/services/state/authStore';
import { InputWithTagList } from '@/client/components/forms/registration-forms/InputWithTagList';
import CompanyService from '@/client/services/api/CompanyService';
import { CustomField } from '@/client/types/AuthConfig';
import { FormField } from '@/client/types/RegistrationForms';
import { useEffect, useState } from 'react';
import { Book } from '@/client/components/icons/ContinuIcons';
import { useToastStore } from '@/client/services/state/toastStore';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import CreateInput from "@/client/components/admin/create/shared/input/CreateInput";

export default function RegistrationFormDetails() {
  const {  watch, getValues, setValue } = useFormContext();
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { setToast } = useToastStore();
  const [requiredSearchTerm, setRequiredSearchTerm] = useState<string | undefined>('');
  const [optionalSearchTerm, setOptionalSearchTerm] = useState<string | undefined>('');
  const [requiredSearchItems, setRequiredSearchItems] = useState<CustomField[]>([]);
  const [optionalSearchItems, setOptionalSearchItems] = useState<CustomField[]>([]);

  const defaultFields = [
    {
      name: t('registrationForms.fieldTitle.job_title'),
      dataType: 'string',
      validationRules: {},
      visibility: {
        certificates: 'none',
        profile: 'none',
        reports: 'none',
      },
    },
    {
      name: t('registrationForms.fieldTitle.language'),
      dataType: 'drop_down',
      validationRules: {},
      visibility: {
        certificates: 'none',
        profile: 'none',
        reports: 'none',
      },
    },
    {
      name: t('registrationForms.fieldTitle.userid'),
      dataType: 'string',
      validationRules: {},
      visibility: {
        certificates: 'none',
        profile: 'none',
        reports: 'none',
      },
    },
  ];

  const dataTypeToInputType: Record<string, string> = {
    string: 'text',
    number: 'number',
    boolean: 'boolean',
  };

  const { data: fields } = useQuery({
    queryKey: ['customFields', authConfig.company._id],
    queryFn: async () => CompanyService.getCustomFields(authConfig.company._id),
    select: (data: CustomField[]) => [...defaultFields, ...data],
  });

  const mapFieldToFormField = (field: CustomField, required?: boolean) => {
    const validationObject = field.validationRules || {};
    const validationRules = Object.keys(validationObject).reduce(
      (validationList: any[], validationKey: string) => {
        if (validationKey === 'email') {
          validationList.push({
            validation: validationKey,
          });
        } else {
          validationList.push({
            validation: validationKey.toLowerCase(),
            parameters: validationObject[validationKey],
          });
        }
        return validationList;
      },
      [],
    );

    if (required) {
      validationRules.push({
        validation: 'required',
      });
    }

    const newFieldName = field.name.toLowerCase().split(' ').join('_');

    return {
      name: newFieldName,
      title: field.name,
      input_type: dataTypeToInputType[field.dataType] || field.dataType,
      description: field.description || field.name,
      validations: validationRules,
      maps_to_write_type: 'user_core',
      maps_to_field_name: ['first_name', 'last_name', 'email', 'language', 'job_title'].includes(
        newFieldName,
      )
        ? newFieldName
        : 'other',
    };
  };

  const onDeleteField = (field: FormField) => {
    const currentFields = getValues('fields');
    const filteredFields = currentFields.filter(
      (currentField: FormField) => currentField.title !== field.title,
    );

    setValue('fields', filteredFields);
  };

  const onAddField = (field: CustomField, required?: boolean) => {
    const currentFields = getValues('fields') || [];

    // Check for field already in field list
    const selectedFieldWithSameName = currentFields.find(
      (currentField: FormField) => currentField.title === field.name,
    );

    if (selectedFieldWithSameName) {
      setToast({
        show: true,
        status: 'error',
        title: 'This field is already on your registration form',
      });
      return;
    }

    const newField = mapFieldToFormField(field, required);
    currentFields.push(newField);
    setValue('fields', currentFields);
  };

  const searchWithPreloadedData = (searchTerm: string) => {
    if (!searchTerm.length) {
      return [];
    }
    const filteredFields = fields?.filter((field: CustomField) =>
      field.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    return filteredFields;
  };

  const checkIfSelected = (currentField: CustomField, selectedFields: FormField[]) => {
    const selectedField = selectedFields?.find(
      (field: FormField) => field.title === currentField.name,
    );
    return !!selectedField;
  };

  const getRequiredValidation = (selectedField: FormField) =>
    selectedField.validations?.filter((validation) => {
      if (validation.validation === 'required') {
        return true;
      }
      return false;
    });

  const filterForDefault = (selectedField: FormField) => {
    if (
      selectedField.name === 'first_name' ||
      selectedField.name === 'email' ||
      selectedField.name === 'last_name'
    ) {
      return true;
    }
    return false;
  };

  const filterForRequired = (selectedField: FormField) => {
    if (filterForDefault(selectedField)) {
      return false;
    }
    if (!selectedField.validations || !selectedField.validations.length) {
      return false;
    }
    const requiredFields = getRequiredValidation(selectedField);
    if (requiredFields?.length) {
      return true;
    }
    return false;
  };

  const filterForOptional = (selectedField: FormField) => {
    if (filterForDefault(selectedField)) {
      return false;
    }
    if (!selectedField.validations || !selectedField.validations.length) {
      return true;
    }
    const requiredFields = getRequiredValidation(selectedField);
    if (requiredFields?.length) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const searchedData = searchWithPreloadedData(requiredSearchTerm || '');
    setRequiredSearchItems(searchedData || []);
  }, [requiredSearchTerm]);

  useEffect(() => {
    const searchedData = searchWithPreloadedData(optionalSearchTerm || '');
    setOptionalSearchItems(searchedData || []);
  }, [optionalSearchTerm]);

  const selectedFields = watch('fields') || [];

  return (
    <VStack width="100%" padding={6}>
      <Flex direction="row" width="100%" justifyContent="space-between">
        <Flex direction="column" width="50%">
          <FormLabelWithTooltip label={t('registrationForms.edit.uniqueIdentifier')} isRequired />
          <CreateInput
            name="code"
            placeholder={`${t('registrationForms.edit.uniqueIdentifierPlaceholder')}`}
            variant="landingPage"
            isRequired
          />
        </Flex>
        <Flex direction="column">
          <Text fontSize="sm" fontWeight="600">
            {t('registrationForms.edit.pageUrl')}
          </Text>
          <Text color="neutral.1500" fontSize="sm" fontWeight="300">
            {/* TODO: Update this to be a dynamic link to the registration page when refactored to support reg forms */}
            {`${company.domain}/product/registration/`}
            <strong>{`${watch('code') ? watch('code') : ''}`}</strong>
          </Text>
        </Flex>
      </Flex>
      <Flex direction="column" width="100%">
        <FormLabelWithTooltip label={t('registrationForms.edit.requiredFormFields')} />
        <InputWithTagList
          fieldName="fields"
          defaultItems={selectedFields.filter(filterForDefault)}
          selectedItems={selectedFields.filter(filterForRequired)}
          selectItem={(selectedItem: CustomField) => onAddField(selectedItem, true)}
          deleteItem={onDeleteField}
          checkIfSelected={checkIfSelected}
          searchTerm={requiredSearchTerm}
          setSearchTerm={(term: string | undefined) => setRequiredSearchTerm(term)}
          searchedItems={requiredSearchItems}
          inputPlaceholder={`${t('registrationForms.edit.requiredFormFieldsPlaceholder')}`}
          ItemsIcon={Book}
        />
      </Flex>
      <Flex direction="column" width="100%">
        <FormLabelWithTooltip label={t('registrationForms.edit.optionalFormFields')} />
        <InputWithTagList
          fieldName="fields"
          selectedItems={selectedFields.filter(filterForOptional)}
          selectItem={onAddField}
          deleteItem={onDeleteField}
          searchTerm={optionalSearchTerm}
          checkIfSelected={checkIfSelected}
          setSearchTerm={(term: string | undefined) => setOptionalSearchTerm(term)}
          searchedItems={optionalSearchItems}
          inputPlaceholder={`${t('registrationForms.edit.optionalFormFieldsPlaceholder')}`}
          ItemsIcon={Book}
        />
      </Flex>
    </VStack>
  );
}
