import { Icon, IconButton, ScaleFade, Tooltip } from '@chakra-ui/react';

import { IoSparklesSharp } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';

interface HeaderCommentsIconProps {
  lightIcons?: boolean;
  goToRecommendations?: () => void;
}

export default function HeaderRecommendationsIcon({
  lightIcons,
  goToRecommendations,
}: HeaderCommentsIconProps) {
  const { t } = useTranslation();
  const { allowance } = useAccessHelper();
  const enabled = !!allowance.recommendations;

  const handleScroll = () => {
    if (goToRecommendations) {
      goToRecommendations();
      return;
    }

    const recommendationsContainer = document.getElementById('recommendations-container');

    if (recommendationsContainer) {
      recommendationsContainer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {enabled && (
        <ScaleFade initialScale={0.9} in>
          <Tooltip label={t('global.continuAI')}>
            <IconButton
              aria-label="Go to recommendations"
              variant="ghost"
              icon={
                <Icon
                  as={IoSparklesSharp}
                  color={lightIcons ? 'white' : 'brand.iconGrey'}
                  boxSize={6}
                  _hover={{ color: 'brand.button' }}
                />
              }
              onClick={handleScroll}
            />
          </Tooltip>
        </ScaleFade>
      )}
    </>
  );
}

HeaderRecommendationsIcon.defaultProps = {
  lightIcons: false,
  goToRecommendations: null,
};
