import { useNavigate, useParams } from 'react-router-dom';

import ContentAudienceForm from '@/client/components/admin/create/content/forms/audience/ContentAudienceForm';
import ContentPublishForm from '@/client/components/admin/create/content/forms/ContentPublishForm';
import ContentSettingsForm from '@/client/components/admin/create/content/forms/settings/ContentSettingsForm';
import CreateTabs from '@/client/components/admin/create/shared/tabs/CreateTabs';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import type { FileFormData } from '@/client/routes/admin/create/content/EditFile';
import FileGeneralDetailsForm from '@/client/components/admin/create/content/forms/files/FileGeneralDetailsForm';
import Loading from '@/client/components/media/Loading';
import { useAuthStore } from '@/client/services/state/authStore';
import { useAuthorFile } from '@/client/services/hooks/admin/authoring/UseAuthorFile';
import { useCheckContentEditable } from '@/client/services/hooks/admin/authoring/useCheckContentEditable';
import { useCreateNavigationBlocker } from '@/client/services/hooks/admin/authoring/useCreateNavigationBlocker';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTrackStateStore } from '@/client/services/state/admin/create/trackStateStore';
import { useTranslation } from 'react-i18next';
import ContentCreateTabControls from "@/client/components/admin/create/content/tabs/ContentCreateTabControls";

export default function EditFileForm() {
  useCreateNavigationBlocker();
  const { id } = useParams();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { submitFileMutation, getFileForEditor } = useAuthorFile(id);
  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { watch, formState } = useFormContext<FileFormData>();
  const { trackState, setTrackState } = useTrackStateStore();
  const { isValid } = formState;

  const { checkContentEditable } = useCheckContentEditable();
  const {
    setCanAdvance,
    tabIndex,
    setTabIndex,
    setNoImageModalIsOpen,
    setContentType,
    setContentTitle,
  } = useCreateStore();

  useEffect(() => {
    setContentType('file');
    setTabIndex(0);
  }, []);

  const title = watch('title');
  const author = watch('author');
  const description = watch('description');
  const fileDetails = watch('fileDetails');
  const cardImage = watch('image');
  const bannerImage = watch('bannerImage');
  const isDraft = watch('draft');
  const link = watch('link');
  const uploadType = watch('fileUploadType');

  useEffect(() => {
    setContentTitle(title as string);
  }, [title]);

  const newFileCanAdvance = !id && uploadType === 'upload' ? fileDetails !== null : link !== '';
  const requiredFieldsFilledOut = description !== '' && title !== '' && author !== '';

  const canAdvance = id ? requiredFieldsFilledOut : newFileCanAdvance && requiredFieldsFilledOut;

  useEffect(() => {
    if (canAdvance) {
      setCanAdvance(true);

      return;
    }

    setCanAdvance(false);
  }, [canAdvance]);

  const tabOptions = [
    {
      label: t('authoring.file.label'),
      content: <FileGeneralDetailsForm canAdvance={canAdvance} />,
      isDisabled: false,
    },
    {
      label: t('authoring.tabs.audience'),
      content: <ContentAudienceForm />,
      isDisabled: !isValid || !requiredFieldsFilledOut,
    },
    {
      label: t('authoring.tabs.settings'),
      content: <ContentSettingsForm isFile />,
      isDisabled: !isValid || !requiredFieldsFilledOut,
    },
    {
      label: id ? t('authoring.update') : t('authoring.tabs.publish'),
      content: <ContentPublishForm />,
      isDisabled: !isValid || !requiredFieldsFilledOut,
    },
  ];

  const { data, isFetching, isError, error } = getFileForEditor;

  useEffect(() => checkContentEditable(data), [data]);

  const onProceed = () => {
    submitFileMutation.mutateAsync({
      approval_required: user.is_collaborator,
      approved: !user.is_collaborator,
      draft: isDraft,
    });

    if (tabIndex === 0 && !cardImage && !bannerImage && !trackState) {
      setNoImageModalIsOpen(true);
      return;
    }

    setTabIndex(tabIndex + 1);
  };

  const handlePublish = () =>
    submitFileMutation
      .mutateAsync({
        approval_required: user.is_collaborator,
        approved: !user.is_collaborator,
        draft: false,
      })
      .then((submissionData) => {
        setToast({ show: true, status: 'success', title: 'File Created Successfully' });

        if (trackState) {
          setTrackState({
            ...trackState,
            contentId: submissionData._id,
            contentTitle: submissionData.title,
            returningFromContent: true,
          });

          navigate(`/admin/edit/course/track/${trackState.track}`);

          return;
        }

        navigate(`/file/${submissionData._id}`);
      });

  if (id && isFetching) return <Loading />;

  if (isError) {
    console.error('Error Fetching File', error);

    return (
      <ErrorAlert
        title="There was an error fetching the file"
        backAction={{
          to: '/pvt/#/overview/content',
          label: 'Back to content overview',
          isAngular: true,
        }}
      />
    );
  }

  return (
    <CreateTabs
      tabOptions={tabOptions}
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      controls={
        <ContentCreateTabControls
          tabOptionsLength={tabOptions.length}
          onProceed={onProceed}
          canPublish={formState.isValid && link !== ''}
          handlePublish={handlePublish}
        />
      }
    />
  );
}
