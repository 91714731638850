/* eslint-disable react/no-unstable-nested-components */

import { ArrowLeft, Plus } from '@/client/components/icons/ContinuIcons';
import { Button, Flex, HStack, Stack, Tag, Text } from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import {
  FeedActivity as FeedActivityType,
  Stat,
} from '@/client/types/admin/user-manager/FeedActivity';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import AdminFeedsService from '@/client/services/api/admin/users/feeds/AdminFeedsService';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import Loading from '@/client/components/media/Loading';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const userUpdateMap = [
  'new_users',
  'user_updates',
  'user_resets',
  'user_reinvites',
  'user_suspensions',
  'user_unsuspensions',
  'user_i2p_migrations',
  'user_p2i_migrations',
  'user_duplicated',
  'user_deletions',
];

const userErrorMap = [
  'user_errored',
  'user_unidentifiable',
  'user_errored_on_duplicated-userid',
  'user_errored_on_unidentifiable-record',
  'user_errored_on_duplicated-email',
];

const userUnchangedMap = ['user_unchanged'];

interface ChangeGroupProps {
  entityStats: Stat[];
  segmentationStats: Stat[];
}

function ChangeGroup({ entityStats, segmentationStats }: ChangeGroupProps) {
  const { t } = useTranslation();

  const getCount = (stats: Stat[], map: string[]) =>
    stats.filter((stat) => map.includes(stat.name)).reduce((acc, stat) => acc + stat.stat, 0);

  const userUpdateCount = getCount(entityStats, userUpdateMap);
  const userErrorCount = getCount(entityStats, userErrorMap);
  const userUnchangedCount = getCount(entityStats, userUnchangedMap);
  const locations = segmentationStats.find((stat) => stat.name === 'new_locations')?.stat || 0;
  const departments = segmentationStats.find((stat) => stat.name === 'new_departments')?.stat || 0;
  const teams = segmentationStats.find((stat) => stat.name === 'new_teams')?.stat || 0;
  const orgLevals = segmentationStats.find((stat) => stat.name === 'new_org_levels')?.stat || 0;
  const grades = segmentationStats.find((stat) => stat.name === 'new_grades')?.stat || 0;

  return (
    <Stack paddingY={2}>
      {entityStats.length === 0 && segmentationStats.length === 0 && (
        <Tag variant="adminWarning">{t('admin.users.sftpFeeds.activity.changes.noChanges')}</Tag>
      )}

      {entityStats.length > 0 && (
        <>
          {userUpdateCount > 0 && (
            <Tag variant="adminSuccess">
              {userUpdateCount} {t('admin.users.sftpFeeds.activity.changes.usersUpdated')}
            </Tag>
          )}

          {userErrorCount > 0 && (
            <Tag variant="adminError">
              {userErrorCount} {t('admin.users.sftpFeeds.activity.changes.usersErrored')}
            </Tag>
          )}

          {userUnchangedCount > 0 && (
            <Tag variant="adminWarning">
              {userUnchangedCount} {t('admin.users.sftpFeeds.activity.changes.usersUnchanged')}
            </Tag>
          )}
        </>
      )}

      {locations > 0 && (
        <Tag variant="adminSuccess">
          {locations} {t('admin.users.sftpFeeds.activity.changes.newLocations')}
        </Tag>
      )}

      {departments > 0 && (
        <Tag variant="adminSuccess">
          {departments} {t('admin.users.sftpFeeds.activity.changes.newDepartments')}
        </Tag>
      )}

      {teams > 0 && (
        <Tag variant="adminSuccess">
          {teams} {t('admin.users.sftpFeeds.activity.changes.newTeams')}
        </Tag>
      )}

      {orgLevals > 0 && (
        <Tag variant="adminSuccess">
          {orgLevals} {t('admin.users.sftpFeeds.activity.changes.newOrgLevels')}
        </Tag>
      )}

      {grades > 0 && (
        <Tag variant="adminSuccess">
          {grades} {t('admin.users.sftpFeeds.activity.changes.newGrades')}
        </Tag>
      )}
    </Stack>
  );
}

export default function FeedActivity() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, isLoading, isError } = useQuery({
    queryKey: ['feed', id],
    queryFn: () => AdminFeedsService.getFeedById(id),
  });

  const {
    data: activityData,
    isLoading: activityDataIsLoading,
    isError: activityDataIsError,
  } = useQuery({
    queryKey: ['feed-activity', id, fetchDataOptions],
    queryFn: () => AdminFeedsService.getFeedActivity(id, fetchDataOptions),
  });

  const columnHelper = createColumnHelper<FeedActivityType>();
  const columns = useMemo<ColumnDefBase<FeedActivityType, any>[]>(
    () => [
      columnHelper.accessor('_id', {
        cell: (info) => <span>{info.row.original._id}</span>,
        header: () => <span>{t('admin.users.sftpFeeds.activity.jobId')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => (
          <span>{format(new Date(info.row.original.createdAt), 'hh:mm a z MM/dd/yyyy')}</span>
        ),
        header: () => <span>{t('admin.users.sftpFeeds.activity.dateReceived')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('updatedAt', {
        cell: (info) => (
          <span>{format(new Date(info.row.original.updatedAt), 'hh:mm a z MM/dd/yyyy')}</span>
        ),
        header: () => <span>{t('admin.users.sftpFeeds.activity.processed')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'changes',
        cell: (info) => (
          <ChangeGroup
            entityStats={info.row.original.report.entity_stats}
            segmentationStats={info.row.original.report.segmentation_stats}
          />
        ),
        header: () => <span>{t('admin.users.sftpFeeds.activity.changes')}</span>,
      }),
    ],
    [],
  );

  if (isLoading || activityDataIsLoading) return <Loading />;

  if (isError || activityDataIsError)
    return (
      <ErrorAlert
        title={t('admin.users.sftpFeeds.unableToGetFeed')}
        backAction={{ to: '/admin/users/feeds', label: t('admin.users.sftpFeeds.backToAll') }}
      />
    );

  return (
    <AdminElevatedBox>
      <Button
        variant="adminPrimary"
        size="xs"
        leftIcon={<ArrowLeft />}
        onClick={() => navigate('/admin/users/feeds')}
      >
        {t('admin.users.sftpFeeds.backToAll')}
      </Button>

      <Flex marginTop={4} justifyContent="space-between" marginY={8}>
        <HStack alignItems="baseline" spacing={12}>
          <FormLabelWithTooltip label={data.name} />

          {data.schedule?.nextRunAt && (
            <Text fontSize="xs">{`${t('admin.users.sftpFeeds.activity.nextScheduled')} ${format(
              new Date(data.schedule.nextRunAt),
              'MM/dd/yyyy hh:mm a z ',
            )}`}</Text>
          )}
        </HStack>

        <Button
          variant="adminPrimary"
          size="xs"
          leftIcon={<Plus />}
          onClick={() => navigate('/admin/users/add/bulk')}
        >
          {t('admin.users.sftpFeeds.manuallyImportUsers')}
        </Button>
      </Flex>

      <ManuallyPaginatedTable
        columns={columns}
        queryData={activityData}
        pagination={pagination}
        setPagination={setPagination}
        maxHeight="auto"
      />
    </AdminElevatedBox>
  );
}

FeedActivity.defaultProps = {
  nextRunAt: null,
};
