import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { QueryProductsArgs } from '@/client/services/api/graphql/gql/graphql';
import ProductsService from '@/client/services/api/graphql/ProductsService';

export const useListProducts = (options: QueryProductsArgs) =>
  useQuery({
    queryKey: ['products', options],
    queryFn: () => ProductsService.listProducts(options),
  });

export const useAdminListProducts = (options: QueryProductsArgs) =>
  useQuery({
    queryKey: ['admin-products', options],
    queryFn: () => ProductsService.adminListProducts(options)
  });

export const useListProductsInfinite = (options: QueryProductsArgs) =>
  useInfiniteQuery({
    queryKey: ['infinite-products', options],
    refetchOnWindowFocus: false,
    queryFn: ({pageParam = 0}) => ProductsService.listProducts({...options, page: pageParam}),
    getNextPageParam: (lastPage, pages) =>
      lastPage.pageInfo.hasNextPage ? pages.length : undefined,
  });
