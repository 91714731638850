import { BaseSyntheticEvent, useEffect, useState } from 'react';
/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Assignment } from '@/client/types/admin/assignments/Assignment';
import AssignmentNotificationsService from '@/client/services/api/admin/notifications/AssignmentNotificationsService';
import AssignmentsService from '@/client/services/api/admin/assignments/AssignmentsService';
import { DynamicMessage } from '@/client/types/admin/assignments/DynamicMessage';
import NotificationEmailForm from './forms/NotificationEmailForm';
import NotificationInAppForm from './forms/NotificationInAppForm';
import NotificationScheduleForm from './forms/NotificationScheduleForm';
import NotificationSlackForm from './forms/NotificationSlackForm';
import { useNotificationEditor } from '@/client/services/hooks/admin/notifications/useNotificationEditor';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface EditNotificationModalProps {
  notification: DynamicMessage | null;
  assignment: Assignment;
  refetch: () => void;
  onClose: () => void;
}

export default function EditNotificationModal({
  notification,
  assignment,
  refetch,
  onClose,
}: EditNotificationModalProps) {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { templateOptions } = useNotificationEditor();
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);

  const methods = useForm<DynamicMessage>({
    mode: 'onChange',
  });

  const { reset, handleSubmit, watch, setValue } = methods;

  const selectedNotificationType = watch('type');
  const selectedNotificationRecipient = watch('recipient');

  useEffect(() => {
    if (!selectedNotificationType || !selectedNotificationRecipient) return;

    const emailBody =
      templateOptions.email[selectedNotificationRecipient][selectedNotificationType];
    const slackBody =
      templateOptions.slack[selectedNotificationRecipient][selectedNotificationType];
    const inAppBody =
      templateOptions.inApp[selectedNotificationRecipient][selectedNotificationType];

    setValue('email_body', emailBody);
    setValue('slack_body', slackBody);
    setValue('in_app_body', inAppBody);
  }, [selectedNotificationType, selectedNotificationRecipient]);

  useEffect(() => {
    if (notification) {
      const { type } = notification;
      const notificationType = type.split('_').slice(0, -1).join('_');
      const recipient = type.split('_').reverse()[0];

      const emailFromName = notification.messaging.email.filter((e) => e.name === 'from_name')[0];
      const emailSubject = notification.messaging.email.filter((e) => e.name === 'subject')[0];

      reset({
        ...notification,
        type: notificationType,
        recipient,
        time_before_due: notification.time_before_due || 1,
        cadence: notification.cadence || null,
        total_messages: notification.total_messages || 1,
        email_from_name: emailFromName ? emailFromName.value : '',
        email_subject: emailSubject ? emailSubject.value : '',
        email_body: notification.messaging.email.filter((e) => e.name === 'body')[0].value,
        slack_body: notification.messaging.slack.filter((e) => e.name === 'body')[0].value,
        slack_include_template: notification.messaging.slack.filter(
          (e) => e.name === 'include_template',
        )[0].value,
        in_app_body: notification.messaging.in_app.filter((e) => e.name === 'body')[0].value,
      });
    }
  }, [notification]);

  const {
    data: integrationData,
    isLoading: integrationLoading,
    isError: integrationError,
  } = useQuery({
    queryKey: ['company-integrations'],
    queryFn: async () => AssignmentNotificationsService.getCompanyIntegrations(),
    select: (data) => data[0],
  });

  const updateNotificationMutation = useMutation({
    mutationFn: (payload: any) =>
      AssignmentNotificationsService.updateNotification(notification?._id, payload),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: 'Notification updated successfully.',
      });

      onClose();
      setSaveModalOpen(false);
      refetch();
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: 'Error updating notification.',
      });
    },
  });

  const updateAssignmentMutation = useMutation({
    mutationFn: (payload: any) => AssignmentsService.updateAssignment(assignment._id, payload),
  });

  const update = async (notificationPayload: any, assignmentPayload: any) => {
    await updateNotificationMutation
      .mutateAsync(notificationPayload)
      .then(() => updateAssignmentMutation.mutate(assignmentPayload));
  };

  // TODO: Refactor this for creating new notifications
  const onSubmit: SubmitHandler<DynamicMessage> = async (data) => {
    const notificationPayload = {
      messaging: {
        email: [
          {
            name: 'body',
            value: data.email_body,
            _id: data.messaging.email.filter((e) => e.name === 'body')[0]._id,
          },
          {
            name: 'from_name',
            value: data.email_from_name,
          },
          {
            name: 'subject',
            value: data.email_subject,
          },
        ],
        in_app: [
          {
            name: 'body',
            value: data.in_app_body,
            _id: data.messaging.in_app.filter((e) => e.name === 'body')[0]._id,
          },
        ],
        slack: [
          {
            name: 'include_template',
            value: data.slack_include_template,
            _id: data.messaging.slack.filter((e) => e.name === 'include_template')[0]._id,
          },
          {
            name: 'body',
            value: data.slack_body,
            _id: data.messaging.slack.filter((e) => e.name === 'body')[0]._id,
          },
        ],
      },
      _id: data._id,
      company: data.company,
      user: data.user,
      type: `${data.type}_${data.recipient}`,
      content_type: data.content_type,
      name: data.name,
      channels: data.channels,
      options: data.options,
      recipient: data.recipient,
    };

    if (data.type === 'assignment_overdue') {
      // @ts-ignore
      notificationPayload.overdueCadence = data.cadence;
      // @ts-ignore
      notificationPayload.overdueOccurrences = data.total_messages;
    }

    if (data.type === 'assignment_reminder') {
      // @ts-ignore
      notificationPayload.time_before_due = data.time_before_due;
    }

    const assignmentPayload = {
      escalation_contacts: assignment.escalation_contacts.map((contact) => contact._id),
      non_reminder_messages: assignment.non_reminder_messages,
      reminder_messages: assignment.reminder_messages.map((message) => {
        if (message.message === data._id) {
          return {
            message: message.message,
            time_before_due: data.time_before_due,
          };
        }

        return message;
      }),
      remove_sent_messages: assignment.reminder_messages.map((message) => message.message),
    };

    update(notificationPayload, assignmentPayload);
  };

  if (integrationLoading) return <p>Loading...</p>;

  if (integrationError) return <p>Error...</p>;

  const slackAvailable =
    (integrationData &&
      integrationData.slack &&
      integrationData.slack.bot &&
      integrationData.slack.bot.bot_access_token) ||
    (integrationData && integrationData.slack && integrationData.slack.access_token);

  return (
    <Modal size="full" isOpen={!!notification} onClose={onClose}>
      <ModalOverlay />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent paddingTop={12} borderRadius="lg" margin={50}>
            <ModalCloseButton />

            <ModalBody>
              <Tabs variant="adminSmall">
                <TabList>
                  <Tab>{t('assignments.notifications.edit.modal.schedule')}</Tab>

                  <Tab>{t('assignments.notifications.edit.modal.email')}</Tab>

                  {slackAvailable && <Tab>{t('assignments.notifications.edit.modal.slack')}</Tab>}

                  <Tab>{t('assignments.notifications.edit.modal.inApp')}</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <NotificationScheduleForm />
                  </TabPanel>

                  <TabPanel>
                    <NotificationEmailForm />
                  </TabPanel>

                  {slackAvailable && (
                    <TabPanel>
                      <NotificationSlackForm />
                    </TabPanel>
                  )}

                  <TabPanel>
                    <NotificationInAppForm />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </ModalBody>

            <ModalFooter>
              <ButtonGroup spacing={2}>
                <Button variant="adminPrimary" onClick={() => setSaveModalOpen(true)}>
                  {t('assignments.notifications.edit.modal.save')}
                </Button>

                <Button variant="adminCancel" onClick={onClose}>
                  {t('assignments.notifications.edit.modal.cancel')}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>

          <Modal size="2xl" isOpen={saveModalOpen} onClose={() => setSaveModalOpen(false)}>
            <ModalOverlay />

            <ModalContent>
              <ModalCloseButton />

              <ModalBody padding={10}>
                <VStack spacing={6} textAlign="center">
                  <Text fontWeight="bold" fontSize="lg">
                    {t('assignments.notifications.edit.modal.confirmEdit')}
                  </Text>

                  <Text>{t('assignments.notifications.edit.modal.verify.pt1')}</Text>

                  <Text fontSize="xs">{t('assignments.notifications.edit.modal.verify.pt2')}</Text>
                </VStack>
              </ModalBody>

              <ModalFooter>
                <ButtonGroup spacing={2}>
                  <Button
                    type="submit"
                    variant="adminPrimary"
                    onClick={handleSubmit(onSubmit)}
                    isLoading={
                      updateNotificationMutation.isLoading || updateAssignmentMutation.isLoading
                    }
                  >
                    {t('global.forms.labels_submit')}
                  </Button>

                  <Button variant="adminCancel" onClick={() => setSaveModalOpen(false)}>
                    {t('global.action.cancel')}
                  </Button>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </form>
      </FormProvider>
    </Modal>
  );
}
