import { PointProps } from "@/client/types/Charts";

export function Circle({ size, color, borderWidth, borderColor }: PointProps) {
  return (
    <g>
      <circle
        r={size / 2}
        fill={color}
        stroke={borderColor}
        strokeWidth={borderWidth}
        style={{ pointerEvents: 'none' }}
      />
    </g>
  );
};
