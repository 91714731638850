export const Accordion = {
  variants: {
    adminForm: {
      root: {
        marginX: 4,
      },
      container: {
        border: 'none',
        backgroundColor: 'white',
        marginBottom: 4,
        borderRadius: 'xl',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        padding: 4,
      },
      button: {
        _hover: {
          backgroundColor: 'white',
        },
      },
    },
  },
};
