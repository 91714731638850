import { useEffect, useState } from 'react';

import { useDebounce } from 'usehooks-ts';
import TableCombobox from '../TableComboBox';
import SelectedTagList from '../../lists/SelectedTagList';

interface TableSearchProps {
  setSearchTerm?: (value: string) => void;
  searchTerm: string | undefined;
  selectedItems: any[] | undefined;
  data: any[] | undefined;
  setSelected?: (value: any) => void;
  isSearching?: boolean;
}

export default function TableSearchSelect({
  setSearchTerm,
  searchTerm,
  selectedItems,
  data,
  setSelected,
  isSearching,
}: TableSearchProps) {
  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const debouncedSearchInputValue = useDebounce(searchInputValue, 500);

  const handleSearch = (value: string) => setSearchInputValue(value);

  useEffect(() => {
    !!setSearchTerm && setSearchTerm(debouncedSearchInputValue);
  }, [debouncedSearchInputValue]);

  return (
    <>
      <TableCombobox
        items={data || []}
        selectedItems={selectedItems || []}
        setSelectedItems={setSelected}
        isLoading={isSearching}
        searchTerm={searchTerm || ''}
        setSearchTerm={(input: string) => handleSearch(input)}
      />

      <SelectedTagList
        selectedItems={selectedItems || []}
        removeItem={(itemForRemoval, action) => 
          selectedItems &&
          setSelected &&
          setSelected(selectedItems.filter((item) => item.id !== itemForRemoval.id))
        }
      />
    </>
  );
}

TableSearchSelect.defaultProps = {
  isSearching: false,
  setSearchTerm: undefined,
  setSelected: undefined,
};
