import CategoriesService from './CategoriesService';
import { Category } from '@/client/types/Category';
import { Person } from '@/client/types/Person';
import { learnApiClient } from './clients/learnApiClient';

// * Get Users
const getOrganizationMembers = async (pageParam: number, perPage: number): Promise<Person[]> => {
  const response = await learnApiClient.get(
    `users?fields=first_name,last_name,full_name,email,image,role,linked_departments,linked_teams,linked_org_levels,linked_grades,linked_locations,skills,job_title,last_login&last_login=$exists,true&page=${pageParam}&per_page=${perPage}&skills-populate=name&&linked_locations-populate=name,_id&linked_departments-populate=name,_id&linked_teams-populate=name,_id&linked_org_level-populate=name,_id&linked_grade-populate=name,_idsort=last_name,1`,
  );

  return response.data;
};

// * Search for search combobox options
const searchByUserName = async (searchTerm: string) => {
  const response = await learnApiClient.get(
    `users?fields=first_name,last_name,email,full_name,role,image,last_login,manager_email,skills,groups,managing_groups&full_name=$regex,2,${searchTerm},i&last_login=$exists,true&page=1&per_page=6&sort=full_name,-1`,
  );

  return response.data;
};

// * Search for search combobox options
const searchSkills = async (query: string) => {
  const response = await learnApiClient.get(`skills?blocked=$ne,true&name=$regex,2,${query},i`);

  return response.data;
};

const getSearchOptions = async (searchTerm: string) => {
  const withType = (withTypeData: Category[], dataType: string) =>
    withTypeData.map((item) => ({ ...item, type: dataType }));

  const userOptions = await searchByUserName(searchTerm);

  const skillOptions = await searchSkills(searchTerm);

  const categoryOptions = await CategoriesService.searchCategories(searchTerm);

  return [
    { title: 'Users', options: withType(userOptions, 'user') },
    { title: 'Skills', options: withType(skillOptions, 'skill') },
    ...categoryOptions,
  ];
};

// * User search
const searchWithParamsInfinite = async (pageParam: number, query: string) => {
  const response = await learnApiClient.get(
    `users?${query}&fields=_id,first_name,last_name,email,full_name,role,image,linked_locations,linked_departments,linked_teams,linked_org_level,linked_grade,job_title,last_login,createdAt,hired,manager_email,groups,managing_groups&linked_departments-populate=name&linked_grade-populate=name&linked_locations-populate=name&linked_org_level-populate=name&linked_teams-populate=name&page=${pageParam}&per_page=20`,
  );

  return response.data;
};

// * User Search
const searchWithParamsPaginated = async (
  options: {
    pageIndex: number;
    pageSize: number;
  },
  queryString: string,
) => {
  const response = await learnApiClient.get(
    `users?${queryString}&fields=_id,first_name,last_name,email,full_name,role,image,linked_locations,linked_departments,linked_teams,linked_org_level,linked_grade,job_title,last_login,createdAt,hired,manager_email,groups,managing_groups&linked_departments-populate=name&linked_grade-populate=name&linked_locations-populate=name&linked_org_level-populate=name&linked_teams-populate=name&page=${
      options.pageIndex + 1
    }&per_page=${options.pageSize}`,
  );

  const count = await learnApiClient.get<{ count: number }>(`users/count?${queryString}`);

  return {
    rows: response.data,
    pageCount: Math.floor(count.data.count / options.pageSize) || -1,
  };
};

const PeopleService = {
  getOrganizationMembers,
  searchByUserName,
  searchSkills,
  searchWithParamsInfinite,
  searchWithParamsPaginated,
  getSearchOptions,
};

export default PeopleService;
