/* eslint react/no-unstable-nested-components: 0 */

import { Box, Button, ButtonGroup, Checkbox, Flex } from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import AdminUsersService from '@/client/services/api/admin/users/AdminUsersService';
import { CiUndo } from 'react-icons/ci';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { SuspendedUser } from '@/client/types/admin/user-manager/AdminUsers';
import UserCell from '../../tables/cells/UserCell';
import UserSearch from '@/client/components/input/search/UserSearch';
import { format } from 'date-fns';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useAdminUsersSearch } from '@/client/services/hooks/admin/users/useAdminUsersSearch';
import { useFormatDate } from '@/client/services/hooks/date-and-time/useFormatDate';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useUnsuspendUsers } from '@/client/services/hooks/admin/users/useUnsuspendUsers';

export default function SuspendedUsers() {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();
  const { unsuspendMutation } = useUnsuspendUsers();
  const { isAllowed } = useAccessHelper();
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>({});
  const reinstateAllowed = isAllowed(['admin', 'users', 'add'], null, ['admin']);

  const {
    handleCreateItem,
    selectedItems,
    setSelectedItems,
    setSearchTerm,
    searchTerm,
    handleSelectedItemsChange,
    suspendedComboboxOptions,
    searchSuspendedMutation,
    searchPagination,
    setSearchPagination,
  } = useAdminUsersSearch('suspended');

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, refetch } = useQuery({
    queryKey: ['suspendedUsers', fetchDataOptions],
    queryFn: () => AdminUsersService.getSuspendedUsers(fetchDataOptions),
  });

  const unsuspendUsers = () =>
    unsuspendMutation
      .mutateAsync(rowSelection)
      .then(() => refetch())
      .finally(() => setRowSelection({}));

  const columnHelper = createColumnHelper<SuspendedUser>();
  const columns = useMemo<ColumnDefBase<SuspendedUser, any>[]>(
    () => [
      columnHelper.display({
        id: 'checkbox',
        cell: (info) => (
          <Flex alignItems="center">
            <Checkbox
              marginLeft={9}
              variant="admin"
              onChange={info.row.getToggleSelectedHandler()}
              isChecked={info.row.getIsSelected()}
            />
          </Flex>
        ),
        header: (info) => (
          <Checkbox
            marginLeft={9}
            variant="admin"
            onChange={info.table.getToggleAllRowsSelectedHandler()}
            isChecked={info.table.getIsAllRowsSelected()}
          />
        ),
      }),
      columnHelper.accessor('full_name', {
        cell: (info) => (
          <UserCell
            name={info.row.original.full_name}
            image={info.row.original.image}
            id={info.row.original._id}
            isSuspended
          />
        ),
        header: () => <div>{t('global.forms.labels_name')}</div>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('global.forms.labels_email')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('suspended_on', {
        cell: (info) => (
          <Box>
            {info.getValue() ? formatDate(new Date(info.getValue()), 'baseWithTime') : '----'}
          </Box>
        ),
        header: () => <span>{t('suspendManager.suspended_On')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('last_login', {
        cell: (info) => (
          <Box>
            {info.getValue() ? formatDate(new Date(info.getValue()), 'baseWithTime') : '----'}
          </Box>
        ),
        header: () => <span>{t('manage.users.lastLogin')}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    [],
  );

  return (
    <Box>
      <ButtonGroup
        isDisabled={!rowSelection || Object.keys(rowSelection).length === 0}
        size="xs"
        marginBottom={4}
      >
        {reinstateAllowed && (
          <Button variant="adminPrimary" leftIcon={<CiUndo />} onClick={unsuspendUsers}>
            {t('global.action.reinstate')}
          </Button>
        )}
      </ButtonGroup>

      <Box marginBottom={4}>
        <UserSearch
          showPredefinedFilters={false}
          variant="admin"
          placeholder={t('modules.usersearch.placeholder')}
          onCreateItem={handleCreateItem}
          items={suspendedComboboxOptions || []}
          selectedItems={selectedItems}
          setSearchValue={setSearchTerm}
          searchValue={searchTerm}
          handleClearAll={() => setSelectedItems([])}
          onSelectedItemsChange={(changes: any) => {
            handleSelectedItemsChange(changes.selectedItems, changes.type);
          }}
        />
      </Box>

      <ManuallyPaginatedTable
        columns={columns}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        queryData={selectedItems.length > 0 ? searchSuspendedMutation.data : data}
        pagination={selectedItems.length > 0 ? searchPagination : pagination}
        setPagination={selectedItems.length > 0 ? setSearchPagination : setPagination}
        showCheckbox
      />
    </Box>
  );
}
