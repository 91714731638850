import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

import { CalendarData } from '@/client/types/Calendar';
import DashboardCard from '../cards/DashboardCard';
import WorkshopsCard from '../cards/WorkshopsCard';

interface CalendarModalProps {
  assignments: CalendarData[];
  workshops: CalendarData[];
  isCalendarModalOpen: boolean;
  setIsCalendarModalOpen: (isCalendarModalOpen: boolean) => void;
}

export default function CalendarModal({
  assignments,
  workshops,
  isCalendarModalOpen,
  setIsCalendarModalOpen,
}: CalendarModalProps) {
  // TODO: Pull this out into a util for use in Workshop/Explore cards
  const setContentImageAlignment = (content: any, isFirst: boolean) => {
    let topPos = '';
    if (!isFirst && content.cover_justification === 'left') {
      topPos = 'north';
    }
    if (!isFirst && content.cover_justification === 'right') {
      topPos = 'south';
    }
    if (isFirst && content.featured_justification === 'left') {
      topPos = 'north';
    }
    if (isFirst && content.featured_justification === 'right') {
      topPos = 'south';
    }
    return `${topPos}`;
  };

  return (
    <Modal size="4xl" isOpen={isCalendarModalOpen} onClose={() => setIsCalendarModalOpen(false)}>
      <ModalOverlay />
      <ModalContent padding={{base: 0, lg: 10}} style={{position: "fixed", maxHeight: "85%"}}>
        <ModalBody overflowY="scroll">
          {assignments.length > 0 && (
            <>
              <Text as="h3">Assignments</Text>

              {assignments.map((assignment) => (
                <Stack spacing={4} key={`container-${assignment.key}`}>
                  <DashboardCard
                    key={assignment.key}
                    isAssignment
                    id={assignment.id}
                    title={assignment.title}
                    type={assignment.contentType}
                    imageUrl={assignment.imageUrl}
                    isCompleted={assignment.isCompleted}
                    dueIn={assignment.dueIn}
                  />
                </Stack>
              ))}
            </>
          )}

          {workshops.length > 0 && (
            <>
              <Text as="h3">Workshops</Text>
              <Box overflowY="scroll">
              <Stack spacing={4} paddingLeft={{base: 0, lg: 25}} paddingRight={{base: 0, lg: 50}}>
                {workshops.map((workshop) =>
                  workshop.online !== undefined && workshop.status ? (                    
                      <Box width={{ base: 'auto', lg: 'auto' }} key={workshop.key} alignItems="center">
                      <WorkshopsCard
                        workshopId={workshop.id}
                        workshopTitle={workshop.title}
                        workshopType={workshop.contentType}
                        online={workshop.online}
                        image={workshop.imageUrl}
                        startDate={workshop.startDate}
                        registeredStatus={workshop.status}
                        imageAlignment={setContentImageAlignment(workshop, false)}
                      />
                    </Box>
                  ) : (
                    <DashboardCard
                      key={workshop.key}
                      id={workshop.id}
                      title={workshop.title}
                      type="workshop"
                      imageUrl={workshop.imageUrl}
                      workshopStartDate={workshop.startDate}
                      workshopEndDate={workshop.endDate}
                    />
                  ),
                )}
              </Stack>
              </Box>
            </>
          )}
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
}
