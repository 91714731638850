import { Skill } from '@/client/types/Skill';
import { learnApiClient } from './clients/learnApiClient';

const setSortOrder = (sortOrder: string) => {
  let sort;

  switch (sortOrder) {
    case 'newest':
      sort = `createdAt,-1`;
      break;
    case 'oldest':
      sort = `createdAt,1`;
      break;
    case 'asc':
      sort = `name,1`;
      break;
    case 'desc':
      sort = `name,-1`;
      break;
    default:
      sort = `createdAt,-1`;
  }

  return sort;
};

const getSkills = async (
  pageParam: number,
  blocked: boolean | undefined,
  sortOrder: 'asc' | 'desc' | 'oldest' | 'newest',
) => {
  const response = await learnApiClient.get(
    `skills?blocked=${
      blocked ? 'true' : '$ne,true'
    }&fields=name,user,blocked,createdAt&page=${pageParam}&per_page=50&user-populate=first_name,last_name,full_name,email,image&sort=${setSortOrder(
      sortOrder,
    )}`,
  );

  return response.data;
};

const searchSkills = async (
  term: string,
  sortOrder: 'asc' | 'desc' | 'oldest' | 'newest',
  pageParam?: number,
  blocked?: boolean | undefined,
) => {
  const response = await learnApiClient.get<Skill[]>(
    `skills?blocked=${
      blocked ? 'true' : '$ne,true'
    }&fields=name,createdAt&name=$regex,2,${term},i&page=${pageParam}&per_page=50&sort=${setSortOrder(
      sortOrder,
    )}`,
  );

  return response;
};

const createSkills = async (skills: string[], userId: string) => {
  const response = await learnApiClient.post(`skills/create-multiple`, {
    skills,
    user: userId,
  });

  return response;
};

const blockSkills = async (existingSkillIds: string[], newSkillIds: string[], userId: string) => {
  const response = await learnApiClient.post(`skills/block-multiple`, {
    existingSkills: existingSkillIds,
    newSkills: newSkillIds || [],
    user: userId,
  });

  return response.data;
};

const unblockSkills = async (skillIds: string[]) => {
  const response = await learnApiClient.post(`skills/unblock-multiple`, {
    skills: skillIds,
  });

  return response.data;
};

const deleteSkills = async (skillIds: string[]) => {
  const response = await learnApiClient.post(`skills/remove-multiple`, {
    skills: skillIds,
  });

  return response.data;
};

const editSkill = async (skillId: string, name: string) => {
  const response = await learnApiClient.post(`skills/${skillId}`, {
    name,
  });

  return response.data;
};

const SkillService = {
  getSkills,
  searchSkills,
  createSkills,
  blockSkills,
  unblockSkills,
  deleteSkills,
  editSkill,
};

export default SkillService;
