import { useQuery } from '@tanstack/react-query';
import ProductsService from '@/client/services/api/graphql/ProductsService';

export const useGetPurchaseFromProduct = (userId: string, productId: string) => useQuery({
  enabled: !!userId && !!productId,
  queryKey: ['dashboard-puchases', userId, productId],
  queryFn: () =>
    ProductsService.listPurchases({
      user: { eq: userId },
      product: { eq: productId },
      subscriptionActive: true,
      page: 0,
      pageSize: 1,
      includeTotalCount: false,
    }),
});