/* eslint react/jsx-props-no-spreading: 0 */

import { Button, FormControl, FormErrorMessage, Input, Select, Stack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { FormField } from '../../types/RegistrationForms';
import { getSupportedLanguages } from '@/client/utils/getSupportedLanguages';

interface FieldProps {
  fields: FormField[];
}

export default function DynamicForm({ fields }: FieldProps) {
  const { t } = useTranslation();
  const supportedLanguages = getSupportedLanguages();

  const { register, formState } = useFormContext();

  return (
    <Stack spacing={8}>
      {fields.map((field: FormField) => {
        const fieldIsRequired = !!field.validations?.find(
          (validation) => validation.validation === 'required',
        );

        return (
          <FormControl isInvalid={!!formState.errors[field.name]}>
            {field.name === 'language' ? (
              <Select
                variant="flushed"
                placeholder={`${t(`registrationForms.fieldTitle.language`)}`}
                {...register('language', { required: fieldIsRequired })}
              >
                {supportedLanguages.map((language) => (
                  <option value={language.value}>{t(language.name)}</option>
                ))}
              </Select>
            ) : (
              <Input
                variant="flushed"
                type={field.input_type}
                placeholder={`${t(`registrationForms.fieldTitle.${field.name}`)}`}
                {...register(field.name, {
                  required: fieldIsRequired,
                })}
              />
            )}
            {formState.errors[field.name] && (
              <FormErrorMessage>{`${t(
                `registrationForms.fieldTitle.${field.name}`,
              )} is required`}</FormErrorMessage>
            )}
          </FormControl>
        );
      })}

      <Button type="submit" isDisabled={!formState.isValid}>
        {t('preauth.invite.confirmRegistration')}
      </Button>
    </Stack>
  );
}
