/* eslint-disable react/no-unstable-nested-components */

import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import AssignUsersModal from '@/client/components/admin/overlay/assignUsersModal/AssignUsersModal';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { PlusCircle } from '@/client/components/icons/ContinuIcons';
import { Profile } from '@/client/types/admin/profiles/Profile';
import ProfileUsersModal from '@/client/components/admin/overlay/profiles/ProfileUsersModal';
import TableActionsMenu from '@/client/components/admin/menus/TableActionsMenu';
import { useNavigate } from 'react-router-dom';
import { useProfilesService } from '@/client/services/hooks/admin/profiles/useProfilesService';
import { useTranslation } from 'react-i18next';
import { useUserSearch } from '@/client/services/hooks/shared/search/useUserSearch';
import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';

// TODO: Handle no profiles state - are there default profiles when client is created?

export default function Profiles() {
  const userSearchHook = useUserSearch('admin');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showProfileUsers, setShowProfileUsers] = useState<boolean>(false);
  const {
    searchTerm,
    setSearchTerm,
    deleteItemId,
    setDeleteItemId,
    selectedProfile,
    setSelectedProfile,
    getProfiles,
    deleteProfile,
    assignProfileUsers,
  } = useProfilesService();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  useEffect(() => {
    if (searchTerm !== '') {
      setPagination({ pageIndex: 0, pageSize: 10 });
    }
  }, [searchTerm]);

  const { data, refetch } = getProfiles(fetchDataOptions);

  const columnHelper = createColumnHelper<Profile>();
  const columns = useMemo<ColumnDefBase<Profile, any>[]>(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('global.forms.labels_name')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('description', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('global.forms.labels_description')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('userCount', {
        cell: (info) => (
          <Text
            color="brand.link"
            cursor="pointer"
            onClick={() => {
              setSelectedProfile(info.row.original);
              setShowProfileUsers(true);
            }}
          >
            {info.getValue()}
          </Text>
        ),

        header: () => <span>{t('profiles.manager.associatedUsers')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('created_by', {
        cell: (info) => (info.getValue().full_name ? info.getValue().full_name : '-----'),
        header: () => <span>{t('profiles.manager.createdBy')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <TableActionsMenu
            onAssign={() => {
              setSelectedProfile(info.row.original);
              setModalOpen(true);
            }}
            onEdit={() => navigate(`/admin/profiles/${info.row.original._id}`)}
            onDelete={() => setDeleteItemId(info.row.original._id)}
          />
        ),
      }),
    ],
    [],
  );

  return (
    <>
      <AdminPrimaryHeader
        title={`${t('overview.settings.access_profiles')}`}
        tooltipText={`${t('global.searchByName')}`}
        setSearchTerm={(term) => setSearchTerm(term)}
        sortOrder="asc"
        setSortOrder={() => {}}
        isSearching={false}
        rightElement={
          <Button
            size="xs"
            variant="adminPrimary"
            leftIcon={<PlusCircle />}
            onClick={() => navigate('/admin/profiles/create')}
          >
            {t('admin.profiles.createProfile')}
          </Button>
        }
      />

      <AdminElevatedBox>
        <ManuallyPaginatedTable
          columns={columns}
          queryData={data}
          rowSelection={{}}
          setRowSelection={() => {}}
          pagination={pagination}
          setPagination={setPagination}
        />
      </AdminElevatedBox>

      <Modal size="3xl" isOpen={deleteItemId !== null} onClose={() => setDeleteItemId(null)}>
        <ModalOverlay onClick={() => () => setDeleteItemId(null)} />

        <ModalContent>
          <ModalBody>
            <VStack>
              <Text as="h3">{t('profiles.removalModalHeader')}</Text>

              <Text textAlign="center">{t('profiles.removalModalBody')}</Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button variant="adminCancel" onClick={() => setDeleteItemId(null)}>
                {t('global.actions.cancel')}
              </Button>

              <Button
                variant="adminError"
                onClick={() =>
                  deleteProfile.mutateAsync().then(() => {
                    if (data?.rows.length === 1 && pageIndex > 0) {
                      setPagination({ pageIndex: 0, pageSize });
                    }

                    refetch();
                  })
                }
              >
                {t('global.actions.delete')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ProfileUsersModal
        selectedProfile={selectedProfile}
        setSelectedProfile={setSelectedProfile}
        showProfileUsers={showProfileUsers}
        setShowProfileUsers={setShowProfileUsers}
      />

      <AssignUsersModal
        userSearchHook={userSearchHook}
        isOpen={modalOpen}
        onClose={() => {
          refetch();
          setModalOpen(false);
        }}
        assignUsers={assignProfileUsers}
      />
    </>
  );
}
