import { Box, Heading, Stack } from '@chakra-ui/react';

import MeetsActivationButton from '../MeetsActivationButton';
import TeamsActivationButton from '../TeamsActivationButton';
import { useAuthStore } from '@/client/services/state/authStore';
import { useTranslation } from 'react-i18next';

export default function IntegrationsSettingsForm() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const company = authConfig.company;

  return (
    <Box marginTop={12}>
      <Heading as="h3" fontSize="sm" textTransform="uppercase">
        {t('dashboard.settings.integrations_headline')}
      </Heading>
      <Stack spacing={4}>
        {company.feature_flags.enable_microsoft_teams_meetings && <TeamsActivationButton />}
        {company.feature_flags.enable_google_meets && <MeetsActivationButton />}
      </Stack>
    </Box>
  );
}
