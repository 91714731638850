import { create } from 'zustand';

interface RecommendationsState {
  recommendationsLoaded: boolean;
  setRecommendationsLoaded: (showHeaderIcon: boolean) => void;
}

export const useRecommendationsStore = create<RecommendationsState>((set) => ({
  recommendationsLoaded: false,
  setRecommendationsLoaded: (recommendationsLoaded) => set({ recommendationsLoaded }),
}));
