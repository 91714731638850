import { Box, Circle, HStack, Stack, Text } from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { ExploreCardProductDetails } from '@/client/types/ExploreContent';
import ProductCardTag from './ProductCardTag';
import { truncateText } from '@/client/utils/text/truncateText';
import { useNavigate } from 'react-router-dom';
import { useRenderContentIcon } from '@/client/services/hooks/useRenderContentIcon';
import { useTranslation } from 'react-i18next';

interface CardProps {
  isFirst: boolean | undefined;
  contentLink: string;
  title: string;
  contentTypeTitle: string;
  contentType: string;
  imageUrl: string | undefined;
  vendor: string | undefined;
  imageAlignment: string;
  productDetails?: ExploreCardProductDetails;
}

export default function Card({
  isFirst,
  contentLink,
  title,
  contentType,
  imageUrl,
  contentTypeTitle,
  vendor,
  imageAlignment,
  productDetails,
}: CardProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const bgRef = useRef<any>();

  // TODO: Pull this out into a util for use in Workshop/Explore cards
  const setBackgroundImage = (url: string | undefined) => {
    if (!url) return 'url(/public/images/cardPlaceholder.png)';

    const split = url.split('.');

    const ext = split[split.length - 1];

    if (ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png' && ext !== 'svg') return `url(${url})`;

    let resultUrl = `https://res.cloudinary.com/continu/image/fetch/c_fill`;

    if (bgRef.current) {
      resultUrl += `,w_${bgRef.current.clientWidth * 2},h_${bgRef.current.clientHeight * 2}`;
    }

    if (imageAlignment.length) {
      resultUrl += `,g_${imageAlignment}`;
    } else {
      resultUrl += `,g_center`;
    }

    resultUrl += `,f_auto,q_auto/${url}`;

    return resultUrl?.endsWith('undefined') || imageUrl?.endsWith('q_auto/')
      ? 'url(/public/images/cardPlaceholder.png)'
      : `url('${resultUrl}')`;
  };

  return (
    <Box
      width="full"
      height="250px"
      position="relative"
      bg="white"
      boxShadow="1px 1px 10px rgba(0, 0, 0, 0.1)"
      rounded="lg"
      p={6}
      overflow="hidden"
      _hover={{
        cursor: 'pointer',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.20)',
      }}
      onClick={() => navigate(contentLink)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box height="150px" maxHeight="150px" overflow="hidden" mt={-6} mx={-6}>
        <Box
          height="full"
          width="full"
          maxHeight="150px"
          backgroundImage="url(/public/images/cardPlaceholder.png)"
          background={
            imageUrl ? setBackgroundImage(imageUrl) : 'url(/public/images/cardPlaceholder.png)'
          }
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          overflow="hidden"
          transition="0.5s ease all"
          willChange="transform"
          transform={`translate3d(0,0,0) scale(${hovered ? '1.1' : '1'})`}
          ref={bgRef}
        />
      </Box>

      {isFirst && (
        <Box
          position="absolute"
          top={4}
          left={0}
          backgroundColor="brand.primary"
          padding="2px 20px 2px 16px"
          width="20%"
          clipPath="polygon(0% 0%, 100% 1%, 80% 53%, 100% 100%, 0% 100%)"
          fontSize="sm"
          borderRadius="0 4px 4px 0"
          zIndex="20"
        >
          <Text color="white" fontSize="14px" fontWeight={700}>
            {t('explore.general.featuredFlag')}
          </Text>
        </Box>
      )}

      <Stack marginTop={3}>
        <HStack alignItems="center">
          <Circle size="22px" border="1px" borderColor="#5D5B57" color="#5D5B57" padding={1}>
            {useRenderContentIcon(contentType, vendor, 3)}
          </Circle>

          <Text color="#5D5B57" fontWeight="500" fontSize="12px">
            {contentTypeTitle}
          </Text>

          {productDetails && (
            <ProductCardTag
              price={productDetails.price}
              currency={productDetails.currency}
              purchased={productDetails.purchased}
              licensingType={productDetails.licensingType}
              duration={productDetails.duration}
            />
          )}
        </HStack>

        <Text flex={1} fontWeight={600} fontSize="14px" textOverflow="clip">
          {truncateText(title, 42, 39)}
        </Text>
      </Stack>
    </Box>
  );
}

Card.defaultProps = {
  productDetails: null,
};
