/* eslint-disable jsx-a11y/anchor-is-valid */

import './JourneyStyles.scss';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import {
  ArrowDown,
  ArrowLeft,
  Article as ArticleIcon,
  Assessment as AssessmentIcon,
  CalendarEvent,
  CalendarWeek,
  CheckCircle,
  FileVideo,
  Files,
  ImportedContent as ImportedContentIcon,
  Journey as JourneyIcon,
  Locked,
  Minus,
  Plus,
  Scorm as ScormIcon,
  Steps,
  Track,
} from '@/client/components/icons/ContinuIcons';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Article from '@/client/routes/content/Article';
import Assessment from '../assessments/Assessment';
import ContentFile from '../ContentFile';
import ContentHeader from '@/client/components/layout/ContentHeader';
import ContentRatingInput from '@/client/components/data-display/ratings/ContentRatingInput';
import ContentWrapper from '@/client/components/content/ContentWrapper';
import HtmlRenderer from '@/client/components/html/HtmlRenderer';
import ImportedContent from '@/client/routes/content/ImportedContent';
import JourneyService from '@/client/services/api/content/JourneyService';
import LearningTrack from '../learning-tracks/LearningTrack';
import Loading from '@/client/components/media/Loading';
import MultiSessionWorkshop from '../MultiSessionWorkshop';
import PopupSurveyLayout from '@/client/components/overlay/PopupSurvey';
import Recommendations from '@/client/components/layout/Recommendations';
import Scorm from '../Scorm';
import Video from '@/client/routes/content/Video';
import Workshop from '../Workshop';
import { format } from 'date-fns';
import { formatJourney } from '@/client/utils/content/journeys/formatJourney';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCheckSurveyCompletion } from '@/client/services/hooks/useCheckSurveyCompletion';
import { useQuery } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export default function Journey() {
  const { authConfig } = useAuthStore();
  const { user, company } = authConfig;

  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { journeyId } = useParams<{ journeyId: string }>();
  const { t } = useTranslation();
  const [ searchParams ] = useSearchParams();

  const userJourneyId = searchParams.get('contextId');
  const heroRef = useRef<HTMLDivElement>(null);
  const [selectedContent, setSelectedContent] = useState<any>(null);
  const [ratingModalOpen, setRatingModalOpen] = useState<boolean>(false);
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [selectedContent]);

  const renderContentIcon = (
    contentType: string,
    boxSize: number,
    color?: string,
    hover?: any,
    onClick?: () => void,
  ) => (
    <>
      {contentType === 'article' && (
        <ArticleIcon boxSize={boxSize} color={color} _hover={hover} onClick={onClick} />
      )}
      {contentType === 'video' && (
        <FileVideo boxSize={boxSize} color={color} _hover={hover} onClick={onClick} />
      )}
      {contentType === 'file' && (
        <Files boxSize={boxSize} color={color} _hover={hover} onClick={onClick} />
      )}
      {contentType === 'workshop' && (
        <CalendarEvent boxSize={boxSize} color={color} _hover={hover} onClick={onClick} />
      )}
      {contentType === 'multi_session_workshop' && (
        <CalendarWeek boxSize={boxSize} color={color} _hover={hover} onClick={onClick} />
      )}
      {contentType === 'track' && (
        <Track boxSize={boxSize} color={color} _hover={hover} onClick={onClick} />
      )}
      {contentType === 'scorm' && (
        <ScormIcon boxSize={boxSize} color={color} _hover={hover} onClick={onClick} />
      )}
      {contentType === 'imported_content' && (
        <ImportedContentIcon boxSize={boxSize} color={color} _hover={hover} onClick={onClick} />
      )}
      {contentType === 'assessment' && (
        <AssessmentIcon boxSize={boxSize} color={color} _hover={hover} onClick={onClick} />
      )}
    </>
  );

  if (!company.feature_flags.journeys) {
    setToast({
      show: true,
      status: 'error',
      title: 'Journeys are unavailable for the company.',
    });
    navigate('/explore');
  }

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['journey', journeyId, user._id, userJourneyId],
    queryFn: () => JourneyService.getJourney(journeyId, user._id, userJourneyId!),
    select: (journeyData) => {
      const formattedJourney = formatJourney(journeyData.journey, journeyData.userJourney);

      return {
        journey: journeyData.journey,
        userAssignments: journeyData.userAssignments,
        userJourney: journeyData.userJourney,
        progress: formattedJourney.progress,
        userProgress: formattedJourney.userProgress,
        progressMap: formattedJourney.progressMap,
        availableIndex: formattedJourney.availableIndexe,
      };
    },
    onSuccess: (successData) => {
      checkShowPopupSurvey(successData.journey, successData.userProgress.width === 100);
    },
  });

  const backToJourney = () => refetch().then(() => setSelectedContent(null));

  if (isLoading) return <Loading />;

  if (isError) return <Box>Error...</Box>;

  // Only assigned journeys should be accessible by users
  if (!data.userJourney._id) {
    navigate('/explore');
  }
  let contentToRender;
  switch (selectedContent && selectedContent.type) {
    case 'article':
      contentToRender = Article;
      break;
    case 'video':
      contentToRender = Video;
      break;
    case 'file':
      contentToRender = ContentFile;
      break;
    case 'assessment':
      contentToRender = Assessment;
      break;
    case 'scorm':
      contentToRender = Scorm;
      break;
    case 'track':
      contentToRender = LearningTrack;
      break;
    default:
      contentToRender = null;
  }

  return (
    <>
      <Flex
        height="169px"
        alignItems="center"
        backgroundColor="black"
        backgroundImage={`url(${data.journey.banner_image})`}
        backgroundSize="cover"
        backgroundPosition="center top"
        position="relative"
        ref={heroRef}
      >
        <Box
          w="100%"
          h="100%"
          background="black"
          position="absolute"
          top="0"
          left="0"
          zIndex="3"
          opacity="0.6"
        />

        <ContentHeader
          contentType="journey"
          contentId={journeyId}
          heroRef={heroRef}
          navigateAction={
            selectedContent
              ? () => {
                  setSelectedContent(null);
                  navigate(`/journey/${journeyId}`);
                }
              : undefined
          }
        />

        <Box zIndex={4} paddingX={10} width="full" color="white">
          <Stack direction="row" alignItems="center" spacing={6}>
            <Text fontWeight="400" fontSize={{ base: 'xl', md: '4xl' }}>
              {data.journey.title}
            </Text>

            <JourneyIcon boxSize={{ base: 8, lg: 10 }} display={{ base: 'none', md: 'flex' }} />
          </Stack>

          <Flex
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
            marginTop={{ base: 2, md: 8 }}
          >
            <Box width={{ base: 'full', md: '33%' }}>
              <>
                {selectedContent && (
                  <Button
                    variant="ghost"
                    size="sm"
                    color="white"
                    leftIcon={<ArrowLeft />}
                    onClick={() => backToJourney()}
                  >
                    Back To Journey
                  </Button>
                )}

                {!selectedContent &&
                  data.userAssignments &&
                  data.userAssignments.length > 0 &&
                  data.userAssignments[0].due_date && (
                    <Text fontWeight="500">
                      Due:{' '}
                      {format(new Date(data.userAssignments[0].due_date), 'MM/dd/yyyy hh:mm a')}
                    </Text>
                  )}
              </>
            </Box>

            <Box width={{ base: 'full', md: '50%', lg: '33%' }}>
              <Tooltip
                placement="bottom"
                hasArrow
                label="Please note, if you have completed content outside of this Journey, it will automatically mark that step as completed."
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <HStack justifyContent={{ base: 'start', md: 'end' }}>
                  <HStack>
                    <Steps />

                    <Text>{data.journey.satisfiable_entities.length} Steps</Text>
                  </HStack>

                  <Progress variant="hollow" value={data.userProgress.width} />

                  <Text>{data.progress} Complete</Text>
                </HStack>
              </Tooltip>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Box padding={8} minHeight="100vh">
        {selectedContent && (
          <Box>
            {selectedContent.type === 'imported_content' && (
              <ImportedContent
                inJourney
                journeyId={journeyId}
                importedContentId={selectedContent.entity}
              />
            )}
            {selectedContent.type === 'workshop' && (
              <Workshop inJourney workshopId={selectedContent.entity} />
            )}
            {selectedContent.type === 'multi_session_workshop' && (
              <MultiSessionWorkshop inJourney mswId={selectedContent.entity} />
            )}
            {contentToRender && (
              <ContentWrapper
                key={selectedContent.entity}
                contentType={selectedContent.type}
                content={contentToRender}
                contentId={selectedContent.entity}
                journeyId={journeyId}
                selectorDisabled
                journeyNavigateAction={backToJourney}
                parentContextId={userJourneyId!}
              />
            )}
          </Box>
        )}

        {!selectedContent && (
          <>
            <Box backgroundColor="white" borderRadius="lg" padding={4}>
              <Accordion allowToggle defaultIndex={0}>
                <AccordionItem border="none">
                  {({ isExpanded }) => (
                    <>
                      <AccordionButton paddingX={0} _hover={{ backgroundColor: 'white' }}>
                        <Box flex="1" textAlign="left" paddingLeft={0}>
                          <Text fontSize="lg" fontWeight="semibold">
                            Content Summary
                          </Text>
                        </Box>

                        {isExpanded ? <Minus /> : <Plus />}
                      </AccordionButton>

                      <AccordionPanel>
                        <Box className="journey-description">
                          <HtmlRenderer html={data.journey.description} />
                        </Box>
                      </AccordionPanel>
                    </>
                  )}
                </AccordionItem>
              </Accordion>
            </Box>

            {data.userProgress.width === 100 && (
              <Recommendations contentId={journeyId} width={{ base: 'full', md: '82%' }} />
            )}

            <Box backgroundColor="white" borderRadius="lg" padding={4} marginTop={4}>
              <Text fontSize="lg" fontWeight="semibold">
                Steps
              </Text>

              <Stack spacing={8} marginTop={4}>
                {data.journey.satisfiable_entities.map((entity) => {
                  const isLocked = data.progressMap[entity.entity].state === 'locked';
                  let imageUrl = '';

                  if (entity.entity_details && entity.entity_details.image) {
                    imageUrl = entity.entity_details.image;
                  } else if (entity.entity_details.images && entity.entity_details.images.explore) {
                    imageUrl = entity.entity_details.images.explore;
                  }

                  return (
                    <Flex width="full" key={entity._id}>
                      <Box width="full" borderBottom="1px solid" borderBottomColor="#DCDDDE">
                        <Flex
                          flexDirection={{ base: 'column', md: 'row' }}
                          maxHeight="10rem"
                          backgroundColor="brand.mist"
                          marginBottom={8}
                        >
                          <Flex width={{ base: 'full', md: '80%', lg: '90%' }}>
                            <Box
                              width="83px"
                              minWidth="83px"
                              borderTopLeftRadius="lg"
                              borderBottomLeftRadius="lg"
                              backgroundImage={`url(${imageUrl})`}
                              backgroundSize="cover"
                              backgroundPosition="center center"
                              backgroundRepeat="no-repeat"
                              backgroundColor="brand.primary"
                            >
                              <Center height="full">
                                <Tooltip hasArrow label={t('modules.contentPicker.' + entity.type)}>
                                  <span>{renderContentIcon(entity.type, 8, 'white')}</span>
                                </Tooltip>
                              </Center>
                            </Box>

                            <Stack padding={{ base: 2, md: 8 }} width="full">
                              {isLocked ? (
                                <Text>{entity.entity_details.displayName}</Text>
                              ) : (
                                <Link
                                  color="brand.link"
                                  onClick={() => {
                                    setSelectedContent(entity);
                                  }}
                                >
                                  {entity.entity_details.displayName}
                                </Link>
                              )}

                              <Text
                                overflow="hidden"
                                textOverflow="ellipsis"
                                fontSize={{ base: 'xs', md: 'sm' }}
                                paddingBottom={6}
                              >
                                {entity.description.substring(0, 100)}...
                              </Text>
                            </Stack>
                          </Flex>

                          <Box
                            borderLeft={{ base: 'none', md: '1px' }}
                            width={{ base: 'full', md: '20%', lg: '10%' }}
                            marginY={{ base: 2, md: 8 }}
                            borderColor="gray.400"
                            backgroundColor={{
                              base: 'blackAlpha.300',
                              md: 'transparent',
                            }}
                          >
                            <Center height="full" width="full">
                              {data.progressMap[entity.entity].state === 'available' && (
                                <Text align="center">Available</Text>
                              )}

                              {data.progressMap[entity.entity].state === 'completed' && (
                                <Text align="center">Complete</Text>
                              )}

                              {data.progressMap[entity.entity].state === 'locked' && (
                                <Stack
                                  spacing={4}
                                  alignItems="center"
                                  justifyContent="center"
                                  width="full"
                                  direction={{ base: 'row', md: 'column' }}
                                >
                                  <Locked boxSize={8} color="gray" />

                                  {data.progressMap[entity.entity].dateAvailable ? (
                                    <>
                                      <Text>Unlocks</Text>
                                      <Text>{data.progressMap[entity.entity].dateAvailable}</Text>
                                    </>
                                  ) : (
                                    <Text>Locked</Text>
                                  )}
                                </Stack>
                              )}
                            </Center>
                          </Box>
                        </Flex>
                      </Box>

                      <Box
                        className="progressTrack"
                        display={{ base: 'none', md: 'block' }}
                        textAlign="center"
                        width="20%"
                        minHeight="100%"
                      >
                        <Box style={data.progressMap[entity.entity].topPath} minHeight="33%" />

                        <Flex alignItems="center" justifyContent="center">
                          {data.progressMap[entity.entity].state === 'completed' && (
                            <CheckCircle boxSize={8} color="brand.primary" />
                          )}

                          {data.progressMap[entity.entity].state === 'available' && (
                            <Tooltip hasArrow label={t('modules.contentPicker.' + entity.type)}>
                              {renderContentIcon(
                                entity.type,
                                8,
                                'brand.primary',
                                { cursor: 'pointer' },
                                () => {
                                  setSelectedContent(entity);
                                },
                              )}
                            </Tooltip>
                          )}

                          {data.progressMap[entity.entity].state === 'locked' && (
                            <Tooltip hasArrow label={t('modules.contentPicker.' + entity.type)}>
                              <span>{renderContentIcon(entity.type, 8)}</span>
                            </Tooltip>
                          )}
                        </Flex>

                        <Box style={data.progressMap[entity.entity].bottomPath} minHeight="33%" />

                        {data.progressMap[entity.entity].state === 'available' &&
                          !data.progressMap[entity.entity].lastStep && (
                            <ArrowDown boxSize={8} color="brand.primary" marginTop={4} />
                          )}
                      </Box>
                    </Flex>
                  );
                })}
              </Stack>
            </Box>
          </>
        )}
      </Box>

      <Modal
        isOpen={ratingModalOpen}
        isCentered
        size="3xl"
        onClose={() => setRatingModalOpen(false)}
      >
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody>
            <ContentRatingInput
              contentId={data.journey._id}
              contentType="journey"
              ratingConfiguration={data.journey.rating_configuration}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {showPopup && (
        <PopupSurveyLayout
          surveyId={data.journey.surveys[0].survey_id}
          contentId={journeyId}
          contentType="journey"
        />
      )}
    </>
  );
}
