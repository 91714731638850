import { Flex } from '@chakra-ui/react';
import { ReactElement } from 'react';

interface AdminSecondaryHeaderProps {
  children: ReactElement;
  useBoxShadow?: boolean;
}

export default function AdminSecondaryHeader({
  children,
  useBoxShadow,
}: AdminSecondaryHeaderProps) {
  return (
    <Flex
      height="40px"
      alignContent="center"
      alignItems="center"
      justifyContent="space-between"
      marginX={4}
      marginY={2}
      paddingX={4}
      paddingY={2}
      borderRadius="md"
      backgroundColor="white"
      boxShadow={useBoxShadow ? '0px 2px 2px rgba(0, 0, 0, 0.1);' : 'none'}
    >
      {children}
    </Flex>
  );
}

AdminSecondaryHeader.defaultProps = {
  useBoxShadow: false,
};
