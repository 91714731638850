import { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import { TrackProps } from 'react-player/file';
import { isFirefox } from 'react-device-detect';

type VideoPlayerProps = {
  url: string;
  recordPosition?: (progressed: number) => void;
  position?: number | undefined;
  vttFiles?: VttProps[];
  videoSource?: string;
  enableRecommendations?: boolean;
  setRenderRecommendations: (value: boolean) => void;
};

type VttProps = {
  kind: string;
  locale: string;
  url: string;
  _id: string;
};

export default function VideoPlayer({
  url,
  recordPosition,
  position,
  videoSource,
  vttFiles,
  enableRecommendations,
  setRenderRecommendations,
}: VideoPlayerProps) {
  const [progressed, setProgressed] = useState<number>(0);
  const [isReady, setIsReady] = useState<boolean>(false);
  const playerRef = useRef<ReactPlayer>(null);
  const [captions, setCaptions] = useState<TrackProps[]>([]);
  const [videoUrl, setVideoUrl] = useState(url);

  const onReady = useCallback(() => {
    if (!isReady) {
      if (position) {
        const fractionPosition = position / 100;
        playerRef?.current?.seekTo(fractionPosition);
      }
      if (videoSource === 'custom') {
        const link = isFirefox
          ? url.substring(0, url.lastIndexOf('.')) + '.webm'
          : url.substring(0, url.lastIndexOf('.')) + '.mp4';
        setVideoUrl(link);
      }
      setIsReady(true);
    }
  }, [isReady]);

  const handleProgress = (data: any) => setProgressed(data.played);

  const handleRecordPosition = () => {
    const percentProgressed = progressed * 100;

    if (recordPosition) {
      recordPosition(percentProgressed);
    }
  };

  const buildSubtitleTracks = () => {
    if (vttFiles && vttFiles.length) {
      vttFiles.map((item: VttProps) => {
        const isDefault = item.locale === 'en_US';
        const buff = Buffer.from(item.url);
        const base64data = buff.toString('base64');
        const toAppend = {
          label: item.locale,
          kind: 'subtitles',
          src: '/vtt-proxy?u=' + base64data,
          srcLang: item.locale,
          default: isDefault,
        };
        setCaptions([...captions, toAppend]);
        return item;
      });
    }
  };

  useEffect(() => {
    buildSubtitleTracks();
  }, [vttFiles]);

  return (
    <>
      <style>
        {`
    ::cue {
      color: white;
      font-size: 18px;
      font-family: 'Inter'
    }
    `}
      </style>

      <Box backgroundColor="black">
        {(vttFiles && vttFiles.length ? captions.length : true) && (
          <ReactPlayer
            ref={playerRef}
            width="100%"
            url={videoUrl}
            controls
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload',
                  preload: 'metadata',
                },
                tracks: captions,
              },
            }}
            onProgress={handleProgress}
            onPause={handleRecordPosition}
            onReady={onReady}
            onEnded={() => (enableRecommendations ? setRenderRecommendations(true) : null)}
          />
        )}
      </Box>
    </>
  );
}

VideoPlayer.defaultProps = {
  recordPosition: () => {},
  position: 0,
  vttFiles: [],
  videoSource: '',
  enableRecommendations: false,
};
