/* eslint-disable react/no-unstable-nested-components */

import { BaseSyntheticEvent, useMemo, useState } from 'react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { Delete, Edit } from '@/client/components/icons/ContinuIcons';

import { Assignment } from '@/client/types/admin/assignments/Assignment';
import { AssignmentModalFormType } from '@/client/components/admin/assignments/AssignmentModal';
import AssignmentsService from '@/client/services/api/admin/assignments/AssignmentsService';
import { DynamicMessage } from '@/client/types/admin/assignments/DynamicMessage';
import EditNotificationModal from './EditNotificationModal';
import { Link } from '@chakra-ui/react';
import ManuallyPaginatedTable from '../../tables/ManuallyPaginatedTable';
import { Link as RouterLink } from 'react-router-dom';
import TableActionsMenuNew from '../../menus/TableActionsMenuNew';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface AssignmentModalNotificationsProps {
  assignment: Assignment;
  onSubmit: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
}

export default function AssignmentModalNotifications({
  assignment,
  onSubmit,
}: AssignmentModalNotificationsProps) {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<AssignmentModalFormType>();
  const [selectedNotification, setSelectedNotification] = useState<DynamicMessage | null>(null);

  const nonReminderMessageIds = watch('non_reminder_messages');
  const reminderMessages = watch('reminder_messages');
  const overdueMessages = watch('overdue_messages');

  const totalMessageIds = [
    ...nonReminderMessageIds,
    ...reminderMessages.map((m) => m.message),
    ...overdueMessages.map((m) => m.message),
  ];

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 500,
  });

  // const fetchDataOptions = {
  //   pageIndex,
  //   pageSize,
  // };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['dynamic-messages', totalMessageIds],
    queryFn: () => AssignmentsService.getDynamicMessages(totalMessageIds),
    select: (dynamicMessageData) => ({
      rows: dynamicMessageData.rows.map((message: DynamicMessage) => {
        let existingReminderMatch;
        let existingOverdueMatch;

        if (message.type.includes('reminder')) {
          // eslint-disable-next-line prefer-destructuring
          existingReminderMatch = reminderMessages.filter((m) => m.message === message._id)[0];
        }

        if (message.type.includes('overdue')) {
          // eslint-disable-next-line prefer-destructuring
          existingOverdueMatch = overdueMessages.filter((m) => m.message === message._id)[0];
        }

        if (existingReminderMatch) {
          return {
            ...message,
            time_before_due: existingReminderMatch.time_before_due || 0,
          };
        }

        if (existingOverdueMatch) {
          return {
            ...message,
            cadence: existingOverdueMatch.cadence,
            total_messages: existingOverdueMatch.total_messages,
            total_sent: existingOverdueMatch.total_sent,
            satisfied: existingOverdueMatch.satisfied,
          };
        }

        return message;
      }),
      pageCount: dynamicMessageData.pageCount,
    }),
  });

  const formatSchedule = (notif: DynamicMessage) => {
    if (!data) return;

    if (!notif.type || typeof notif.type !== 'string') {
      return '--';
    }

    if (notif.type.indexOf('created') !== -1) {
      return t('assignmentMessaging.upon_assignment');
    }
    if (notif.type.indexOf('passed') !== -1) {
      return t('assignmentMessaging.upon_completion');
    }
    if (notif.type.indexOf('failed') !== -1) {
      return t('assignmentMessaging.past_due_date');
    }
    if (notif.type.indexOf('overdue') !== -1) {
      return t('assignmentMessaging.overdue');
    }
    if (notif.type.indexOf('reminder') !== -1) {
      const original = reminderMessages.filter((message) => message.message === notif._id)[0];

      return original.time_before_due
        ? original.time_before_due +
            (original.time_before_due > 1
              ? t('assignments.notifications.edit.days')
              : t('assignments.notifications.edit.day')) +
            t('assignments.notifications.edit.beforeDue')
        : '--';
    }
  };

  const itemMap: { [key: string]: string } = {
    in_app: t('assignments.notifications.edit.inApp'),
    email: t('assignments.notifications.edit.email'),
    slack: t('assignments.notifications.edit.slack'),
  };

  const getTypes = (item: DynamicMessage) => {
    let list = '';
    const listArr: string[] = [];

    item.channels.forEach((channel) => {
      if (!itemMap[channel]) {
        return;
      }

      listArr.push(itemMap[channel]);
    });

    listArr.sort();

    for (let i = 0; i < listArr.length; i += 1) {
      if (list !== '') {
        list = list + ' | ' + listArr[i];
      } else {
        list = listArr[i];
      }
    }

    return list;
  };

  const getAudience = (item: DynamicMessage) => {
    if (!item.type) {
      return '--';
    }
    if (item.type.indexOf('assignee') !== -1) {
      return t('assignmnets.notifications.edit.assignee');
    }
    if (item.type.indexOf('escalator') !== -1) {
      return t('assignments.notifications.edit.escalationContacts');
    }
    return '--';
  };

  //! Does not delete, user must still submit the update
  const handleDelete = (notification: DynamicMessage) => {
    const messageType =
      notification.type.indexOf('reminder') !== -1
        ? 'rm'
        : notification.type.indexOf('overdue') !== -1
        ? 'om'
        : 'nrm';

    switch (messageType) {
      case 'rm': {
        const newReminderMessages = reminderMessages.filter(
          (reminderMessage: any) => reminderMessage.message !== notification._id,
        );
        setValue('reminder_messages', newReminderMessages);
        break;
      }
      case 'om': {
        const newOverdueMessages = overdueMessages.filter(
          (overdueMessage: any) => overdueMessage.message !== notification._id,
        );
        setValue('overdue_messages', newOverdueMessages);
        break;
      }
      case 'nrm': {
        const newNonReminderMessages = nonReminderMessageIds.filter(
          (nonReminderMessageId: string) => nonReminderMessageId !== notification._id,
        );
        setValue('non_reminder_messages', newNonReminderMessages);
        break;
      }
      default:
        break;
    }
  };

  const columnHelper = createColumnHelper<DynamicMessage>();
  const columns = useMemo<ColumnDefBase<DynamicMessage, any>[]>(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('assignments.notifications.edit.name')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'schedule',
        cell: (info) => formatSchedule(info.row.original),
        header: () => <span>{t('assignments.notifications.edit.schedule')}</span>,
      }),
      columnHelper.display({
        id: 'type',
        cell: (info) => getTypes(info.row.original),
        header: () => <span>{t('assignments.notifications.edit.type')}</span>,
      }),
      columnHelper.display({
        id: 'audience',
        cell: (info) => getAudience(info.row.original),
        header: () => <span>{t('assignments.notifications.edit.audience')}</span>,
      }),
      columnHelper.accessor('user', {
        cell: (info) => {
          const user = info.getValue();

          return user ? (
            <Link as={RouterLink} to={`/admin/users/edit/single/${user._id}`}>
              {user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : '--'}
            </Link>
          ) : (
            '--'
          );
        },
        header: () => <span>{t('assignments.notifications.edit.creator')}</span>,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <TableActionsMenuNew
            actions={[
              {
                icon: <Edit color="brand.button" />,
                label: t('assignments.notifications.edit.edit'),
                enabled: true,
                onClick: () => setSelectedNotification(info.row.original),
              },
              {
                icon: <Delete color="red" />,
                label: t('assignments.notifications.edit.delete'),
                enabled: true,
                onClick: () => handleDelete(info.row.original),
              },
            ]}
          />
        ),
      }),
    ],
    [data, nonReminderMessageIds, overdueMessages, reminderMessages],
  );

  if (isLoading) return <div>Loading...</div>;

  if (isError) return <div>Error...</div>;

  return (
    <>
      <ManuallyPaginatedTable
        columns={columns}
        queryData={data}
        pagination={pagination}
        setPagination={setPagination}
        hidePagination
      />

      <EditNotificationModal
        notification={selectedNotification}
        assignment={assignment}
        refetch={refetch}
        onClose={() => setSelectedNotification(null)}
      />
    </>
  );
}
