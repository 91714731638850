import {Box, Button, Checkbox, Flex } from '@chakra-ui/react';
import { Close } from '@/client/components/icons/ContinuIcons';
import { useTranslation } from 'react-i18next';
import { ValidateFieldBody, FormField } from '@/client/types/RegistrationForms';


type RequiredFieldProps = {
  updateField: (updateBody: ValidateFieldBody, identifyingFieldName: string) => void;
  fieldData: FormField;
  removeField: (identifyingFieldName: string) => void;
}

export default function ValidateRequiredForm({updateField, fieldData, removeField}: RequiredFieldProps ) {
  const { t } = useTranslation();

  const isAlreadyChecked = !!fieldData.validations?.find(validation => validation.validation === 'required');

  const handleRequiredValidation = (checked: boolean) => {
    const updateBody = {
      addValidation: checked,
      validation: "required"
    }
    updateField(updateBody, fieldData.name);
  }

  return (
    <Box padding={4}>
      <Flex direction="row" justifyContent="space-between">
        <Checkbox variant="adminBrand" size="lg" isChecked={isAlreadyChecked} onChange={(e) => handleRequiredValidation(e.target.checked)}>
          {t('registrationForms.requiredField')}
        </Checkbox>
        <Button
          variant="adminPrimary"
          padding={2}
          height={6}
          width="fit-content"
          borderRadius="12px"
          fontSize="sm"
          backgroundColor="brand.red"
          onClick={() => removeField(fieldData.name)}
        >
          <Close marginRight={2}/>
          {t('global.action.remove')}
        </Button>
      </Flex>
    </Box>
  )
}