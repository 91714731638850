import { Center, Image, Text, VStack } from '@chakra-ui/react';
// eslint-disable-next-line import/extensions
import explorer from '@/client/assets/images/notFoundExplorer.svg';
import { useTranslation } from 'react-i18next';

export default function InactiveRegistrationFormPage() {
  const { t } = useTranslation();

  return (
    <Center minHeight="100vh" backgroundColor="brand.neutral.100">
      <VStack spacing={2}>
        <Image src={explorer} width={260} height={260} margin="0 auto" marginBottom={4} />
        <Text fontSize="xl" fontWeight="600" color="brand.legibleBlack.90">
          {t('registrationForms.inactivePage.oops')}
        </Text>

        <Text color="brand.legibleBlack.90" fontSize="sm">
          {t('registrationForms.inactivePage.weCouldNotFind')}
        </Text>
      </VStack>
    </Center>
  );
}
