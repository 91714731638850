import { Article } from '@/client/types/content/Article';
import { File } from '@/client/types/content/File';
import { Video } from '@/client/types/content/Video';
import { learnApiClient } from './clients/learnApiClient';
import { setContentType } from '@/client/utils/content/setContentType';

const getArticle = async (id: string | undefined) => {
  const response = await learnApiClient.get<Article>(
    `articles/${id}?fields=title,description,author,content,summary,tags,image,banner_image,user,linked_category,explore_hide,duration,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,rating_configuration,average_rating,allow_comments,groups,surveys&user-populate=first_name,last_name,email`
  );

  return response.data;
};

const getVideo = async (id: string | undefined) => {
  const response = await learnApiClient.get<Video>(
    `media/${id}?fields=user,title,author,duration,category,type,link,link_name,description,images,explore_hide,source,source_id,tags,linked_category,youtube_id,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,createdAt,completion_percent,vtt_files,rating_configuration,average_rating,allow_comments,groups,surveys&user-populate=first_name,last_name,email`
  );
  return response.data;
};

const getFile = async (id: string | undefined) => {
  const response = await learnApiClient.get<File>(
    `media/file/${id}?fields=user,title,author,duration,category,type,link,link_name,description,images,explore_hide,source,source_id,tags,linked_category,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,downloadable,completion_percent,embed,embed_settings,rating_configuration,average_rating,allow_comments,groups,surveys&user-populate=first_name,last_name,email`
  );

  return response.data;
};

const getLearningTrackContent = async (contentType: string, id: string) => {
  switch (contentType) {
    case 'article':
      return getArticle(id);
    case 'file':
      return getFile(id);
    case 'video':
      return getVideo(id);
    // case 'assessment':
    //   return getAssessment(id);
  }
};

const getCommentCount = async (contentType: string, _id: string | undefined, inTrack?: boolean, trackId?: string) => {
  let query;

  const type = setContentType(contentType);

  if (inTrack && trackId) {
    query = `maincomments/count?removed=$ne,true&track=${trackId}&${type}=${_id}`;
  } else if (contentType === 'track') {
    query = `maincomments/count?removed=$ne,true&${type}=${_id}`;
  } else {
    query = `maincomments/count?removed=$ne,true&&track=$exists,false&${type}=${_id}`;
  }

  const response = await learnApiClient.get(query);

  return response.data;
};

const completeContent = async (
  contentId: string | undefined,
  contentType: string | undefined,
  contexts?: { key: string; value: any }[],
  contextId?: string | null,
  parentContextId?: string | null,
) => {
  const response = await learnApiClient.post(`content/complete`, {
    contexts: contexts || [],
    type: contentType,
    id: contentId,
    contextId,
    parentContextId,
  });

  return response.data;
};

const postViews = async (content: string | undefined, contentType: string) => {
  await learnApiClient.post(`content/views`, {
    content,
    contentType,
  });
};

const ContentService = {
  getArticle,
  getVideo,
  getFile,
  getLearningTrackContent,
  getCommentCount,
  completeContent,
  postViews,
};

export default ContentService;
