import { Box, Stack } from '@chakra-ui/react';

import DashboardCard from '../../data-display/cards/DashboardCard';
import { Fragment } from 'react';
import { InView } from 'react-intersection-observer';
import Loading from '@/client/components/media/Loading';
import PlaceholderImage from '@/client/components/media/images/PlaceholderImage';
import { Trans } from 'react-i18next';
import { useGetUserBadgesInfinite } from '@/client/services/hooks/badges/useGetUserBadgesInfinite';

export default function DashboardLearningTracksList({ userId }: { userId: string }) {
  const perPage = 50;

  const { data, isLoading, isError, fetchNextPage, isFetchingNextPage } = useGetUserBadgesInfinite(
    userId,
    perPage,
  );

  if (isLoading) return <Loading />;

  if (isError) {
    return <div>Error</div>;
  }

  if (data.pages[0].length === 0) {
    return (
      <Box padding={10} textAlign="center" fontSize="sm" color="blackAlpha.600">
        <PlaceholderImage name="park" />

        <Trans i18nKey="dashboard.badges.emptyMessage" />
      </Box>
    );
  }

  return (
    <Stack spacing={8} overflowY="auto">
      {data.pages.map((page, i) => (
        <Fragment key={i}>
          {page.map((item: any) => {
            if (!item.badge) return;

            return (
              <DashboardCard
                key={item.badge._id}
                id={item.badge._id}
                title={item.badge.title}
                type="badge"
                imageUrl={item.badge.uploaded_image}
                badgeDetails={item}
              />
            );
          })}
        </Fragment>
      ))}

      <>
        <InView
          rootMargin="-200px"
          as="div"
          threshold={0}
          onChange={(inView) => {
            if (inView) {
              fetchNextPage();
            }
          }}
        />

        {isFetchingNextPage && <Loading />}
      </>
    </Stack>
  );
}
