import { Person } from '@/client/types/Person';
import { learnApiClient } from '../../clients/learnApiClient';

const getSegmentationList = async (
  options: { pageIndex: number; pageSize: number },
  userIds: string[],
): Promise<{ rows: Person[]; pageCount: number }> => {
  const response = await learnApiClient.post(
    `users/segmentation-list?fields=_id,first_name,last_name,email,full_name,image,job_title,linked_locations,linked_departments,linked_teams,linked_org_level,linked_grade,role,hired,createdAt,last_login&linked_departments-populate=name&linked_grade-populate=name&linked_locations-populate=name&linked_org_level-populate=name&linked_teams-populate=name&page=${
      options.pageIndex + 1
    }&per_page=${options.pageSize}`,
    {
      in: userIds,
      nin: [],
      queries: [],
    },
  );

  return {
    rows: response.data,
    pageCount: Math.ceil(userIds.length / options.pageSize) || -1,
  };
};

const getSegmentedUserCount = async (
  locationIds: string[],
  departmentIds: string[],
  teamIds: string[],
  orgLevelIds: string[],
  gradeIds: string[],
  groupIds: string[],
): Promise<number> => {
  let params: { [key: string]: string } = {
    'linked_departments-populate': 'name',
    'linked_grade-populate': 'name',
    'linked_locations-populate': 'name',
    'linked_org_level-populate': 'name',
    'linked_teams-populate': 'name',
    'groups-populate': 'name',
    role: '$ne,external',
    suspended: '$ne,true',
  };

  if (departmentIds.length > 0) {
    params = {
      ...params,
      linked_departments: `$in,${departmentIds.length},${departmentIds.join(',')}`,
    };
  }

  if (gradeIds.length > 0) {
    params = {
      ...params,
      linked_grade: `$in,${gradeIds.length},${gradeIds.join(',')}`,
    };
  }

  if (locationIds.length > 0) {
    params = {
      ...params,
      linked_locations: `$in,${locationIds.length},${locationIds.join(',')}`,
    };
  }

  if (orgLevelIds.length > 0) {
    params = {
      ...params,
      linked_org_level: `$in,${orgLevelIds.length},${orgLevelIds.join(',')}`,
    };
  }

  if (teamIds.length > 0) {
    params = {
      ...params,
      linked_teams: `$in,${teamIds.length},${teamIds.join(',')}`,
    };
  }

  if (groupIds.length > 0) {
    params = {
      ...params,
      groups: `$in,${groupIds.length},${groupIds.join(',')}`,
    };
  }

  const response = await learnApiClient.get('users/count', {
    params,
  });

  return response.data.count || 0;
};

const SegmentationService = {
  getSegmentationList,
  getSegmentedUserCount,
};

export default SegmentationService;
