/* eslint-disable import/extensions */
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';

import { BsPlusCircle } from 'react-icons/bs';
import IntegrationService from '@/client/services/api/admin/connect/IntegrationService';
import Loading from '@/client/components/media/Loading';
import linkedin from '@/client/assets/images/linkedin.svg';
import masterclass from '@/client/assets/images/masterclass.svg';
import udemy from '@/client/assets/images/udemy.svg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface ImportedContentButtonProps {
  image: any;
  onClick: () => void;
}

function ImportedContentButton({ image, onClick }: ImportedContentButtonProps) {
  return (
    <Box
      as={Button}
      width="full"
      paddingY={4}
      paddingX={8}
      borderRadius="8px"
      border="1px solid"
      borderColor="neutral.200"
      onClick={onClick}
      backgroundColor="neutral.0"
      _hover={{ backgroundColor: 'neutral.100' }}
    >
      <Image src={image} />
    </Box>
  );
}

export default function TrackStart() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ['existing-content-integrations'],
    queryFn: () => IntegrationService.getExistingContentIntegrations(),
  });

  useEffect(() => {
    if (data && data.integrations.length === 0) {
      navigate('/admin/edit/course/track');
    }
  }, [data]);

  if (isLoading) return <Loading />;

  return (
    <Box height="100vh">
      <Center>
        <Grid paddingTop={40} templateColumns="repeat(2, 1fr)">
          <GridItem
            colSpan={1}
            borderRight="1px solid"
            borderRightColor="neutral.200"
            paddingX={20}
          >
            <Stack spacing={6}>
              <Text fontSize="24px" fontWeight={700}>
                {t('authoring.courses.start.buildTrack')}
              </Text>

              <Text>{t('authoring.courses.start.buildTrack.helpText')}</Text>

              <Box
                as={Button}
                leftIcon={<Icon as={BsPlusCircle} boxSize={5} />}
                backgroundColor="warmNeutral.0"
                color="warmNeutral.800"
                border="2px dashed"
                borderColor="warmNeutral.200"
                borderRadius="6px"
                paddingY={24}
                paddingX={32}
                _hover={{
                  backgroundColor: 'warmNeutral.100',
                }}
                onClick={() => navigate('/admin/edit/course/track')}
              >
                {t('authoring.courses.start.buildTrack')}
              </Box>
            </Stack>
          </GridItem>

          <GridItem colSpan={1} paddingX={20}>
            {data && data.integrations.length > 0 && (
              <Stack spacing={6}>
                <Text fontSize="24px" fontWeight={700}>
                  {t('authoring.courses.start.importCourse')}
                </Text>

                <Text>{t('authoring.courses.start.importCourse.helpText')}</Text>

                {data.integrations.includes('linkedin_learning') && (
                  <ImportedContentButton
                    image={linkedin}
                    onClick={() => navigate('/admin/edit/course/linkedin')}
                  />
                )}

                {data.integrations.includes('masterclass') && (
                  <ImportedContentButton
                    image={masterclass}
                    onClick={() => navigate('/admin/edit/course/masterclass')}
                  />
                )}

                {data.integrations.includes('udemy') && (
                  <ImportedContentButton
                    image={udemy}
                    onClick={() => navigate('/admin/edit/course/udemy')}
                  />
                )}
              </Stack>
            )}
          </GridItem>
        </Grid>
      </Center>
    </Box>
  );
}
