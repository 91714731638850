export const Checkbox = {
  baseStyle: {
    control: {
      _checked: {
        color: 'white',
        backgroundColor: 'brand.formInput',
        borderColor: 'brand.formInput',
        _hover: {
          backgroundColor: 'brand.formInput',
          borderColor: 'brand.formInput',
        },
      },
    },
  },
  variants: {
    admin: {
      control: {
        backgroundColor: 'white',
        _checked: {
          color: 'white',
          backgroundColor: 'brand.formInput',
          borderColor: 'brand.formInput',
          _hover: {
            backgroundColor: 'brand.formInput',
            borderColor: 'brand.formInput',
          },
        },
      },
    },
    form: {
      control: {
        backgroundColor: 'white',
        width: 5,
        height: 5,
        borderColor: 'brand.gray.100',
        _checked: {
          backgroundColor: 'brand.formInput',
          borderColor: 'brand.gray.100',
        },
        _hover: {
          borderColor: 'brand.gray.200',
        },
      },
    },
    adminBrand: {
      control: {
        backgroundColor: 'white',
        borderRadius: '5px',
        _checked: {
          color: 'white',
          background: 'brand.formInput',
          borderColor: 'brand.formInput',
          opacity: '0.5',
          _hover: {
            backgroundColor: 'brand.formInput',
            borderColor: 'brand.formInput',
            opacity: '0.5',
          },
        },
      },
    },
    create: {
      container: {
        backgroundColor: 'white',
        padding: 3,
        border: '1px solid',
        borderColor: 'brand.gold.40',
        borderRadius: 'md',
        _hover: {
          borderColor: 'brand.gold.100',
        },
        _checked: {
          borderColor: 'brand.gold.100',
        },
      },
      control: {
        borderColor: 'brand.gold.40',
        _checked: {
          backgroundColor: 'brand.gold.100',
          borderColor: 'brand.gold.100',
          _hover: {
            backgroundColor: 'brand.gold.100',
            borderColor: 'brand.gold.100',
          },
        },
        _hover: {
          borderColor: 'brand.gold.100',
        },
        _focus: {
          borderColor: 'brand.gold.100',
        },
      },
      label: {
        fontSize: 'sm',
        fontWeight: 500,
        color: 'brand.legibleBlack.20',
        _checked: {
          color: 'brand.legibleBlack.100',
        },
      },
    },
    createSimple: {
      control: {
        _checked: {
          backgroundColor: 'brand.gold.100',
          borderColor: 'brand.gold.100',
        },
      },
    },
  },
};
