import { Box, Divider, Stack, Text } from '@chakra-ui/react';

import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';
import { DndProvider } from 'react-dnd';
import GenerativeDescription from '../../../shared/GenerativeDescription';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TinyMceEditor from '@/client/components/admin/create/shared/TinyMceEditor';
import VideoUpload from '@/client/components/admin/create/content/forms/videos/VideoUpload';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface VideoGeneralDetailsFormProps {
  canAdvance: boolean;
}

export default function VideoGeneralDetailsForm({ canAdvance }: VideoGeneralDetailsFormProps) {
  const { t } = useTranslation();
  const { setCanAdvance, previewFullscreen } = useCreateStore();
  const { watch, setValue } = useFormContext();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;

  const id = watch('id');
  const link = watch('link');
  const description = watch('description');
  const source = watch('source');
  const enableAiPopover = source === 'custom';

  useEffect(() => {
    if (canAdvance) {
      setCanAdvance(true);
      return;
    }

    setCanAdvance(false);
  }, [canAdvance]);

  return (
    <Box>
      <Text color="baseBlack" fontSize="28px" fontWeight={600} marginBottom={4}>
        {t('authoring.video.videoDetails')}
      </Text>

      <Stack spacing={6}>
        <DndProvider backend={HTML5Backend}>
          <VideoUpload />
        </DndProvider>
      </Stack>

      {link && (
        <Box>
          <Stack spacing={6}>
            <Divider variant="create" />

            <Stack spacing={0}>
              <Text variant="createLabel">
                {t('authoring.video.generalDetails.videoInformation')}
              </Text>

              <Text variant="createHelpText">
                {t('authoring.video.generalDetails.videoInformation.helpText')}
              </Text>
            </Stack>

            <Stack spacing={2}>
              <Text variant="createLabel">{t('authoring.video.videoTitle')}</Text>

              <CreateInput
                name="title"
                placeholder={t('authoring.video.generalDetails.titlePlaceholder')}
                variant="create"
                isRequired
              />
            </Stack>

            <Stack spacing={2}>
              <Text variant="createLabel">{t('authoring.authorName')}</Text>

              <CreateInput
                name="author"
                placeholder={t('authoring.video.generalDetails.authorPlaceholder')}
                variant="create"
                isRequired
              />
            </Stack>

            {company.feature_flags.enable_generative_ai && id && !previewFullscreen && (
              <GenerativeDescription
                placeholder={t('authoring.video.descriptionPlaceholder')}
                enableAiPopover={enableAiPopover}
                label="Video Content"
              />
            )}

            {!company.feature_flags.enable_generative_ai && !previewFullscreen && (
              <TinyMceEditor
                isRequired
                formName="description"
                label="Video Content"
                placeholder={t('authoring.video.descriptionPlaceholder')}
              />
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
}
