import { Center, Link, Text, VStack } from '@chakra-ui/react';

import { useConfigStore } from '@/client/services/state/configStore';

export default function WaitForInvite() {
  const { config } = useConfigStore();

  return (
    <Center
      minHeight="100vh"
      backgroundColor="blue.400"
    >
      <VStack spacing={6}>
        <Text as="h1" fontSize="5xl" color="white">
          Thank you for registering!
        </Text>

        <Text color="white" fontSize="2xl">
          You'll receive an email soon to finish setting up your account
        </Text>

        <Text color="white">
          For Continu Support, please review our{' '}
          <Link
            isExternal
            href={config.supportCenterBrandLocation}
            color="white"
            _hover={{ textDecoration: 'underline' }}
          >
            Support Center
          </Link>
        </Text>
      </VStack>
    </Center>
  );
}