import {
  Button,
  Collapse,
  Flex,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import {
  HiCheck,
  HiOutlineBuildingLibrary,
  HiOutlineClock,
  HiOutlineGlobeAlt,
  HiOutlineUserGroup,
} from 'react-icons/hi2';

import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import BulkImportValidationGroup from '@/client/routes/admin/users/BulkImportValidationGroup';
import { CSVFinalDataFormatted } from '@/client/types/admin/user-manager/CsvFinalData';
import { CSVValidationData } from '@/client/types/admin/user-manager/CsvValidationData';
import { CsvFinalMutationPayload } from '@/client/services/hooks/admin/users/useAdminUsers';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import GhostDownloadButton from '@/client/components/admin/buttons/GhostDownloadButton';
import { UseMutationResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

function SplitList({ items }: { items: string[] }) {
  const { isOpen, onToggle } = useDisclosure();

  // eslint-disable-next-line react/destructuring-assignment
  const firstFive = items.slice(0, 5);

  // eslint-disable-next-line react/destructuring-assignment
  const rest = items.slice(5);

  return (
    <>
      <UnorderedList>
        {firstFive.map((item) => (
          <ListItem key={item}>{item}</ListItem>
        ))}
      </UnorderedList>

      {rest.length > 0 && (
        <>
          <Button variant="ghost" size="xs" justifyContent="flex-start" onClick={onToggle}>
            {isOpen ? 'Show Less' : `Show ${rest.length} More`}
          </Button>

          <Collapse in={isOpen}>
            <UnorderedList>
              {rest.map((item) => (
                <ListItem key={item}>{item}</ListItem>
              ))}
            </UnorderedList>
          </Collapse>
        </>
      )}
    </>
  );
}

interface BulkImportPreviewProps {
  csvValidationData: CSVValidationData;
  onImportUsers: UseMutationResult<CSVFinalDataFormatted, any, CsvFinalMutationPayload, unknown>;
  downloadCsv: (data: any[], type: string) => void;
}

export default function BulkImportPreview({
  csvValidationData,
  onImportUsers,
  downloadCsv,
}: BulkImportPreviewProps) {
  const { t } = useTranslation();

  const { results } = csvValidationData;

  const {
    create,
    locations,
    departments,
    errored,
    unchanged,
    update,
    reinvite,
    reset,
    delete: toDelete,
    migrations_i2p: i2pMigrations,
    migrations_p2i: p2iMigrations,
  } = results;

  const checkCanImport = () => {
    if (create && create.length > 0) {
      return true;
    }

    if (locations && locations.length > 0) {
      return true;
    }

    if (departments && departments.length > 0) {
      return true;
    }

    if (update && update.length > 0) {
      return true;
    }

    if (reinvite && reinvite.length > 0) {
      return true;
    }

    if (reset && reset.length > 0) {
      return true;
    }

    if (toDelete && toDelete.length > 0) {
      return true;
    }

    if (i2pMigrations && i2pMigrations.length > 0) {
      return true;
    }

    if (p2iMigrations && p2iMigrations.length > 0) {
      return true;
    }

    return false;
  };

  return (
    <AdminElevatedBox>
      <Flex width="full" justifyContent="space-between" marginBottom={4}>
        <FormLabelWithTooltip label={t('global.label.preview')} />

        <Button
          isDisabled={!checkCanImport()}
          variant="adminPrimary"
          size="xs"
          onClick={() => onImportUsers.mutate({ payload: csvValidationData })}
        >
          Import Users
        </Button>
      </Flex>

      <Stack spacing={6}>
        {create && create.length > 0 && (
          <BulkImportValidationGroup type="success" icon={HiCheck}>
            <HStack>
              <Text>{`${create.length} ${t('edit.useradd.users_to_add')}`}</Text>

              <GhostDownloadButton
                ariaLabel="Download Users To Add"
                onClick={() => downloadCsv(create, 'to-invite')}
              />
            </HStack>
          </BulkImportValidationGroup>
        )}

        {locations && locations.length > 0 && (
          <BulkImportValidationGroup type="success" icon={HiOutlineGlobeAlt}>
            <Stack>
              <Text>{`${locations.length} ${t('edit.useradd.locations_created')}`}</Text>

              <SplitList items={locations} />
            </Stack>
          </BulkImportValidationGroup>
        )}

        {departments && departments.length > 0 && (
          <BulkImportValidationGroup type="success" icon={HiOutlineBuildingLibrary}>
            <Stack>
              <Text>{`${departments.length} ${t('edit.useradd.departments_created')}`}</Text>

              <SplitList items={departments} />
            </Stack>
          </BulkImportValidationGroup>
        )}

        {errored && errored.length > 0 && (
          <BulkImportValidationGroup type="error" icon={HiOutlineClock}>
            <HStack>
              <Text>{`${errored.length} ${t('edit.useradd.invalid_users')}`}</Text>

              <GhostDownloadButton
                ariaLabel="Download Invalid Users"
                onClick={() => downloadCsv(errored, 'errored')}
              />
            </HStack>
          </BulkImportValidationGroup>
        )}

        {unchanged && unchanged.length > 0 && (
          <BulkImportValidationGroup type="unchanged" icon={HiOutlineClock}>
            <HStack>
              <Text>{`${unchanged.length} ${t('edit.useradd.nochanges_users')}`}</Text>

              <GhostDownloadButton
                ariaLabel="Download Unchanged Users"
                onClick={() => downloadCsv(unchanged, 'unchanged')}
              />
            </HStack>
          </BulkImportValidationGroup>
        )}

        {update && update.length > 0 && (
          <BulkImportValidationGroup type="warning" icon={HiOutlineUserGroup}>
            <Stack>
              <HStack>
                <Text>{`${update.length} ${t('edit.useradd.will_update')}`}</Text>

                <GhostDownloadButton
                  ariaLabel="Download Users To Update"
                  onClick={() => downloadCsv(update, 'to-update')}
                />
              </HStack>

              {reinvite && reinvite.length > 0 && (
                <Text>{`${reinvite.length} ${t('edit.useradd.will_reinvite')}`}</Text>
              )}

              {reset && reset.length > 0 && (
                <Text>{`${reset.length} ${t('edit.useradd.will_pw')}`}</Text>
              )}

              {toDelete && toDelete.length > 0 && (
                <Text>{`${toDelete.length} ${t('edit.useradd.will_delete')}`}</Text>
              )}

              {i2pMigrations && i2pMigrations.length > 0 && (
                <Text>{`${i2pMigrations.length} ${t('edit.useradd.will_migrate_email')}`}</Text>
              )}

              {p2iMigrations && p2iMigrations.length > 0 && (
                <Text>{`${p2iMigrations.length} ${t('edit.useradd.will_migrate_sso')}`}</Text>
              )}
            </Stack>
          </BulkImportValidationGroup>
        )}
      </Stack>
    </AdminElevatedBox>
  );
}
