import { Box, FormControl, Input, Stack, Text, useOutsideClick } from '@chakra-ui/react';

import { Category } from '@/client/types/Category';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import { useRef } from 'react';
import { useSegmentationSearch } from '@/client/services/hooks/admin/useSegmentationSearch';
import { useTranslation } from 'react-i18next';
import SelectedTagList from '@/client/components/admin/lists/SelectedTagList';

interface CategorySegmentationSearchProps {
  label: string;
  inputPlaceholder: string;
  tooltipText: string;
  categoriesOnly?: boolean;
  segmentationSelections: any;
  updateSelections: (option: Category, action: 'add' | 'remove') => void;
}

export default function SegmentationSearch({
  label,
  inputPlaceholder,
  tooltipText,
  categoriesOnly,
  segmentationSelections,
  updateSelections,
}: CategorySegmentationSearchProps) {
  const { t } = useTranslation();
  const { categorySearchTerm, setCategorySearchTerm, handleSearchTermChange, categorySearchData } =
    useSegmentationSearch(categoriesOnly);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref,
    handler: () => setCategorySearchTerm(null),
  });

  return (
    <Stack spacing={4}>
      <FormControl id="fieldSelections" maxWidth="60%">
        <FormLabelWithTooltip label={label} tooltipText={tooltipText} />

        <Input
          variant="adminInput"
          placeholder={inputPlaceholder}
          value={categorySearchTerm || ''}
          onChange={(e) => handleSearchTermChange(e.target.value)}
        />

        {categorySearchTerm && categorySearchData && (
          <Stack
            ref={ref}
            height="200px"
            maxHeight="200px"
            width="full"
            overflow="scroll"
            position="absolute"
            backgroundColor="white"
            border="1px solid"
            borderColor="rgba(178, 178, 178, 0.5)"
            borderRadius="md"
            padding={4}
            zIndex={10}
          >
            {categorySearchData.map((section) => (
              <Box key={section.title}>
                <Text fontWeight="bold" fontSize="14px">
                  {section.title}
                </Text>

                {section.options.length ? (
                  section.options.map((option) => (
                    <Box
                      key={option._id}
                      paddingLeft={4}
                      _hover={{
                        cursor: 'pointer',
                        backgroundColor: 'brand.grey.10',
                      }}
                      onClick={() => {
                        updateSelections(option, 'add');
                        setCategorySearchTerm(null);
                      }}
                    >
                      <Text key={option._id} fontSize="12px">
                        {option.legacyName}
                      </Text>
                    </Box>
                  ))
                ) : (
                  <Text paddingLeft={4} fontSize="12px">
                    {t('admin.connect.groups.fieldSelectionsNoResults')}
                  </Text>
                )}
              </Box>
            ))}
          </Stack>
        )}
      </FormControl>

      {!categoriesOnly && (
        <>
          <SelectedTagList
            label={t('admin.connect.groups.selectedLabel', {
              type: t('admin.connect.departments.plural'),
            })}
            selectedItems={segmentationSelections.departments}
            removeItem={updateSelections}
          />

          <SelectedTagList
            label={t('admin.connect.groups.selectedLabel', {
              type: t('admin.connect.locations.plural'),
            })}
            selectedItems={segmentationSelections.locations}
            removeItem={updateSelections}
          />

          <SelectedTagList
            label={t('admin.connect.groups.selectedLabel', {
              type: t('admin.connect.teams.plural'),
            })}
            selectedItems={segmentationSelections.teams}
            removeItem={updateSelections}
          />

          <SelectedTagList
            label={t('admin.connect.groups.selectedLabel', {
              type: t('admin.connect.levels.plural'),
            })}
            selectedItems={segmentationSelections.org_levels}
            removeItem={updateSelections}
          />

          <SelectedTagList
            label={t('admin.connect.groups.selectedLabel', {
              type: t('admin.connect.grades.plural'),
            })}
            selectedItems={segmentationSelections.grades}
            removeItem={updateSelections}
          />
        </>
      )}

      {categoriesOnly && (
        <SelectedTagList
          label={t('segmentation.selectedCategories')}
          selectedItems={segmentationSelections}
          removeItem={updateSelections}
        />
      )}
    </Stack>
  );
}

SegmentationSearch.defaultProps = {
  categoriesOnly: false,
};
