import { Divider, Flex, Text } from '@chakra-ui/react';

type SubscriptionDescriptionProps = {
  headline: string;
  title: string;
  price: number;
  duration: string;
  action: string;
}

export default function ChangeSubscriptionDescription({ headline, title, price, duration, action }: SubscriptionDescriptionProps) {

  return (
    <>
      <Flex direction="row" gap="5%">
        <Text fontSize="sm" fontWeight="500" textAlign="left" marginY={5} width="150px">
          {headline}:
        </Text>
        <Flex direction="column" gap={2} width="70%">
        <Text fontSize="sm" textAlign="left" marginTop={5} fontWeight={600}>
          {title}
        </Text>
        <Text fontSize="sm" textAlign="left">
          {`$${price} / ${duration}`}
        </Text>
        <Text fontSize="sm" textAlign="left">
          {action}
        </Text>
        </Flex>
      </Flex>
      <Divider marginY={4} borderTop=".25px solid" opacity="20%" />
    </>
  )
}