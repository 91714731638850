export const IntegerAxis = {
  format: (e: number) => Math.floor(e) === e && e,
};

export const SkipTicksAxis = (
  hardcodedLabelLength: number,
  width: number,
  dataToMatch: any,
  xVal: string = 'x',
) => ({
  format: (val: number) => {
    if (!dataToMatch) return '';
    const ftIdx = dataToMatch.findIndex((vts: {[key: string]: number}) => vts[xVal] === val);
    const tickWidth = width / dataToMatch.length;
    let numTicksPerLabel = Math.floor(hardcodedLabelLength / tickWidth);
    numTicksPerLabel = Math.max(numTicksPerLabel, 1);
    if (ftIdx % numTicksPerLabel === 0) return val;
    return '';
  },
});
