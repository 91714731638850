import { Box, Text, Textarea, VStack } from '@chakra-ui/react';

import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import ProductTagSearch from '../products/ProductTagSearch';
import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';

type DetailsProps = {
  onNextButtonClick: () => void;
};

export default function ProductLandingPageDetails({ onNextButtonClick }: DetailsProps) {
  const { control, watch, formState: {isValid} } = useFormContext();
  const { t } = useTranslation();
  const description = watch('description');
  const products = watch('products');
  const productsSelected = !!(products && products.length > 0);
  
  return (
    <Box marginBottom={6}>
      <Text fontWeight="500" mb={4}>
        {t('ecommerce.landingPageCreate.header.productLandingPage')}
      </Text>
      <VStack alignItems="start">
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.name')} isRequired />
        <CreateInput
          name="title"
          placeholder={`${t('ecommerce.landingPageCreate.placeholder.name')}`}
          variant="landingPage"
          isRequired
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.headline')} isRequired />
        <CreateInput
          name="headline"
          placeholder={`${t('ecommerce.landingPageCreate.placeholder.headline')}`}
          variant="landingPage"
          isRequired
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.description')} isRequired />
        <Controller
          control={control}
          name="description"
          render={({ field: { onChange, value } }) => (
            <Textarea
              onChange={onChange}
              value={value}
              placeholder={`${t('ecommerce.landingPageCreate.placeholder.description')}`}
              variant="landingPage"
              height="140px"
            />
          )}
        />
        <FormLabelWithTooltip label={t('ecommerce.landingPageCreate.heading.productsListed')} isRequired />
        <ProductTagSearch fieldName='products' placeholder={t('ecommerce.landingPageCreate.placeholder.productsListed')}/>
        <SolidButton marginTop={6} alignSelf="flex-end" onClick={() => onNextButtonClick()} isDisabled={!isValid || !description || !productsSelected}>
          {t('button.action.saveAndContinue')}
        </SolidButton>
      </VStack>
    </Box>
  );
}
