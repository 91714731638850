import {
    Flex,
    Text,
} from '@chakra-ui/react';

import { hexToRGBA } from '@/client/utils/hexToRGBA';


interface DayProps {
    dayData: {
        displayDate: string,
        backgroundColor: string,
        color: string,
        data: any
        opacity?: number
        borderColor?: string
    },
    modalOpener: (data: any)=> void
}
export default function CalendarDay({dayData, modalOpener}: DayProps) {
    return (
        <Flex
          height="30px"
          width="30px"
          margin={{base: 1, lg: 3}}
          justifyContent="center"
          alignItems="center"
          borderRadius="50%"
          borderColor={dayData.borderColor && dayData.opacity ? hexToRGBA(dayData.borderColor, dayData.opacity) : 'white'}
          borderWidth={dayData.borderColor ? '2px' : '0px'}
          backgroundColor={dayData.opacity ? hexToRGBA(dayData.backgroundColor, dayData.opacity):dayData.backgroundColor}
          color={dayData.color}
          _hover={{ cursor: dayData.data.length > 0 ? 'pointer' : 'default' }}
          onClick={
            dayData.data.length > 0
              ? () => modalOpener(dayData.data)
              : () => {}
          }
        >
          <Text fontSize="xs" fontWeight="medium">
            {dayData.displayDate}
          </Text>
        </Flex>
      );
}