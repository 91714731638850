import { Text, VStack } from '@chakra-ui/react';

import ContentLength from './ContentLength';
import ContentRatingGroup from '../ratings/ContentRatingGroup';
import { RatingConfiguration } from '@/client/types/content/RatingConfiguration';

// TODO: More accurately handle duration hours/minutes

type ContentStatsProps = {
  author: string;
  duration: number;
  averageRating: number | undefined;
  ratingConfiguration: RatingConfiguration;
};

export default function ContentStats({ author, duration, averageRating, ratingConfiguration }: ContentStatsProps) {
  return (
    <VStack alignItems="start" spacing={0.5} marginBottom={8}>
      <Text fontWeight="bold" color="blackAlpha.700" fontSize={14}>
        {author}
      </Text>

      <ContentLength duration={duration} />

      {averageRating && <ContentRatingGroup averageRating={averageRating} ratingConfiguration={ratingConfiguration} />}
    </VStack>
  );
}
