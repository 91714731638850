import { AdminContent } from '@/client/types/content/AdminContent';
import { learnApiClient } from '../clients/learnApiClient';
import { learnV4ApiClient } from '../clients/learnV4ApiClient';
import { graphQLClient } from '@/client/services/api/clients/graphqlClient';
import { listPartners as listPartnersQuery } from '@/client/services/api/graphql/queries/partners';
import { QueryPartnersArgs, Partner as GQLPartner } from '@/client/services/api/graphql/gql/graphql';
import { Partner, Activity } from '@/client/types/Partner';
import { User } from '@/client/types/AuthConfig';
import { TablePaginationOptions } from '@/client/types/General';

const getPartnerCount = async () => {
  const response = await learnApiClient.get<number>(`partners/count`);
  return response.data;
};

const getAllPartners = async (
  options: TablePaginationOptions,
  searchQuery?: any,
) => {
  const count = await getPartnerCount();

  const response = await learnApiClient.get<Partner[]>('partners', {
    params: { page: options.pageIndex, perPage: options.pageSize || count, query: searchQuery },
  });

  return { rows: response.data, pageCount: Math.ceil(count / options.pageSize!) || 1 };
};

async function searchPartners(
  options: QueryPartnersArgs,
) {
  const data: any = await graphQLClient.request(listPartnersQuery, options);
  if (!data.partners) {
    return {
      partners: [],
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
  }
  const partners: GQLPartner[] = data.partners.edges.map((productNode: any) => productNode.node);
  const { pageInfo } = data.partners;
  return { rows: partners, pageCount: Math.ceil(pageInfo.totalCount / (options.pageSize || 1)) };
}


const getPartnerStats = async (id: string) => {
  const response = await learnApiClient.get(`partners/${id}/stats`);
  return response.data;
};

const getPartnerUserStats = async (id: string) => {
  const response = await learnApiClient.get(`partners/${id}/user-stats`);
  return response.data;
};

const getPartner = async (id: string) => {
  const response = await learnApiClient.get<Partner>(`partners/${id}`);

  return response.data;
};

const createPartner = async (partnerBody: any) => {
  const response = await learnApiClient.post(`partners`, partnerBody);

  return response.data;
};

const updatePartner = async (id: string, updateBody: any) => {
  const updatedFormResponse = await learnApiClient.patch<{_id: string}>(`partners/${id}`, updateBody);

  return updatedFormResponse.data;
};

const getAllPartnerUsers = async (
  options: TablePaginationOptions,
  partnerId: string,
  searchQuery?: any
) => {
  const response = await learnApiClient.get <{rows: User[], count: number}>(`partners/${partnerId}/users`, {
    params: { page: options.pageIndex, perPage: options.pageSize, query: searchQuery }
  });

  return { rows: response.data.rows, pageCount: Math.ceil(response.data.count / options.pageSize!) || 1 };
};

const getAllPartnerAssignments = async (
  options: TablePaginationOptions,
  partnerId: string,
) => {
  const response = await learnApiClient.get<{assignments: any[], count: number}>(`partners/${partnerId}/assignments`, {
    params: { page: options.pageIndex, perPage: options.pageSize },
  });

  return {
    rows: response.data.assignments,
    pageCount: Math.ceil(response.data.count / options.pageSize!) || 1,
  };
};

const getAllPartnerContent = async (
  options: TablePaginationOptions,
  partnerId: string,
) => {
  const requestedTypes: string =
    'articles,videos,files,scorm,tracks,imported_content,workshops,journeys';
  const response = await learnV4ApiClient.get<{rows: AdminContent[], count: number}>(`admin-content/partner/${partnerId}`, {
    params: {
      page: options.pageIndex + 1,
      per_page: options.pageSize,
      types: requestedTypes,
      sort: 'updatedAt,-1'
    },
  });

  return { rows: response.data.rows, pageCount: Math.ceil(response.data.count / options.pageSize!) || 1 };
};

const getUserActivityForContent = async (
  options: TablePaginationOptions,
  partnerId: string,
  contentId: string,
  contentType: string,
) => {
  const response = await learnApiClient.get<{ rows: Activity[]; count: number }>(
    `partners/${partnerId}/content/${contentId}/activity`,
    {
      params: { page: options.pageIndex, perPage: options.pageSize, contentType },
    },
  );
  return { rows: response.data.rows, pageCount: response.data.count };
};
  

const PartnersService = {
  getAllPartners,
  searchPartners,
  getPartner,
  getPartnerCount,
  getPartnerStats,
  getPartnerUserStats,
  createPartner,
  updatePartner,
  getAllPartnerUsers,
  getAllPartnerAssignments,
  getAllPartnerContent,
  getUserActivityForContent
};

export default PartnersService;
