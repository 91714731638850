import { Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function BaseProductTag() {
  const { t } = useTranslation();

  return (
    <Stack
      backgroundColor="warmNeutral.100"
      borderColor="warmNeutral.500"
      borderWidth="1px"
      borderRadius={4}
      padding={4}
    >
      <Text fontWeight="600" fontSize="lg">
        {t('ecommerce.baseProduct.about')}
      </Text>
      <Text fontSize="sm">{t('ecommerce.baseProduct.aboutHelpText')}</Text>
      <Text fontSize="sm">{t('ecommerce.baseProduct.aboutHelpTextTwo')}</Text>
    </Stack>
  );
}
