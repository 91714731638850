import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Progress,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import { AuthoringScorm } from '@/client/components/icons/ContinuIcons';
import AuthoringScormService from '@/client/services/api/admin/content-authoring/AuthoringScormService';
import { CloseIcon } from '@chakra-ui/icons';
import type { DropTargetMonitor } from 'react-dnd';
import IconButtonWithTooltip from '@/client/components/buttons/IconButtonWithTooltip';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { NativeTypes } from 'react-dnd-html5-backend';
import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import { bytesToSize } from '@/client/utils/bytesToSize';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useDrop } from 'react-dnd';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

// TODO: Look into refactoring this for use with Files/Videos/SCORM

export default function ScormPackageDropzone() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { setToast } = useToastStore();
  const { setValue, watch } = useFormContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setUploadStatus } = useCreateStore();

  const [uploadProgress, setUploadProgress] = useState(0);

  const scormId = watch('id');
  const scormFileDetails = watch('scorm_file_details');

  const scormInputRef = useRef<HTMLInputElement>(null);

  const uploadScormMutation = useMutation({
    mutationFn: (file: File) =>
      AuthoringScormService.uploadScormPackage(
        {
          file,
          title: 'Title Pending',
          user: user._id,
          self_hosted: true,
        },
        setUploadProgress,
        scormId ? `scorm/content/${scormId}/replace` : `scorm/content`,
      ),
    onSuccess: (data) => {
      /* Creating */
      if (!scormId) {
        setValue('title', data.title);
        setValue('id', data._id);
        setValue('rating_configuration', data.rating_configuration);
        setValue('creator', data.user);

        AnalyzeService.recordContentCreation({
          document: data._id,
          type: 'scorm-content',
        });

        trackEvent('scorm_content_created', {
          content: data._id,
          type: 'scorm-content',
          title: data.title,
        });

        setToast({
          show: true,
          status: 'success',
          title: t('authoring.scorm.package.uploadSuccess'),
        });
        return;
      }
      /* End Creating */

      /* Replacing */

      AnalyzeService.recordContentModifications({
        document: scormId,
        type: 'scorm-content',
      });

      trackEvent('scorm_content_updated', {
        content: data._id,
        type: 'scorm-content',
        title: data.title,
      });

      setToast({
        show: true,
        status: 'success',
        title: t('authoring.scorm.package.replaceSuccess'),
      });
      /* End Replacing */
    },
    onError: () => {
      setToast({ show: true, status: 'error', title: t('edit.scorm.upload_error') });

      setUploadProgress(0);
      setValue('scorm_file_details', null);
    },
  });

  const { status } = uploadScormMutation;

  useEffect(() => {
    setUploadStatus(status);
  }, [status]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;

    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({ show: true, status: 'error', title: t('edit.scorm.upload_error') });
      return;
    }

    if (fileObject.size > 10000000000) {
      setToast({
        show: true,
        status: 'error',
        title: t('authoring.scorm.package.uploadSizeError'),
      });

      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    setValue('scorm_file_details', {
      name: fileObject.name,
      size: fileObject.size,
    });

    uploadScormMutation.mutateAsync(fileObject);
  };

  const handleUploadClick = () => (scormInputRef.current ? scormInputRef.current.click() : null);

  const handleFileDrop = useCallback((item: { files: any[] }) => {
    if (item) {
      const { files } = item;

      if (files[0].size > 10000000000) {
        setToast({
          show: true,
          status: 'error',
          title: t('authoring.scorm.package.uploadSizeError'),
        });

        return;
      }

      setValue('scorm_file_details', {
        name: files[0].name,
        size: files[0].size,
      });
      uploadScormMutation.mutateAsync(files[0]);
    }
  }, []);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        handleFileDrop(item);
      },
      canDrop() {
        return true;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [handleFileDrop],
  );

  const isActive = canDrop && isOver;

  return (
    <>
      {!scormFileDetails && (
        <Flex
          ref={drop}
          justifyContent="center"
          border="2px dashed"
          borderColor={isActive ? 'brand.gold.100' : 'brand.gold.60'}
          backgroundColor={isActive ? 'brand.gold.40' : 'transparent'}
          borderRadius="md"
          padding={12}
          textAlign="center"
        >
          <Input
            display="none"
            ref={scormInputRef}
            type="file"
            accept=".zip"
            onChange={(e) => handleFileChange(e)}
          />

          <HStack>
            <Icon as={IoCloudUploadOutline} boxSize={6} />

            <Text fontWeight={600} color="brand.legibleBlack.100">
              {t('authoring.scorm.dragDrop')}{' '}
              <Text
                as="span"
                color="brand.gold.100"
                _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={handleUploadClick}
              >
                {t('authoring.scorm.browse')}
              </Text>{' '}
              {id ? t('authoring.scorm.toReplace') : t('authoring.scorm.toChoose')}
            </Text>
          </HStack>
        </Flex>
      )}

      {(status === 'loading' || status === 'success' || status === 'idle') && scormFileDetails && (
        <Box backgroundColor="brand.gold.10" borderRadius="md" padding={6}>
          <Flex justifyContent="space-between" alignItems="center">
            <Stack flex={1}>
              <AuthoringScorm boxSize={8} />

              <Text variant="createLabel">{scormFileDetails.name}</Text>

              <Text variant="createHelpText">{bytesToSize(scormFileDetails.size)}</Text>

              {status === 'loading' && (
                <>
                  <Text variant="createHelpText">{t('authoring.scorm.uploading')}</Text>

                  <Progress variant="create" value={uploadProgress} width="full" />
                </>
              )}
            </Stack>

            <IconButtonWithTooltip
              tooltipCopy={t('authoring.scorm.package.replace')}
              icon={<Icon as={CloseIcon} color="brand.gold.100" />}
              ariaLabel={t('authoring.scorm.package.replace')}
              onClick={onOpen}
            />
          </Flex>
        </Box>
      )}

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />

        <ModalContent>
          <ModalBody paddingTop={12}>
            <VStack textAlign="center">
              <Text fontSize="20px" fontWeight={600} color="baseBlack">
                {t('authoring.scorm.replaceModal1')}
              </Text>

              <Text fontSize="14px" fontWeight={500} color="neutral.1000">
                {t('authoring.scorm.replaceModal3')}
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
            <ButtonGroup width="full" alignItems="center" justifyContent="center">
              <OutlineButton onClick={onClose}>
                {t('authoring.scorm.replaceModal.cancel')}
              </OutlineButton>

              <SolidButton
                onClick={() => {
                  setValue('scorm_file_details', null);
                  onClose();
                }}
              >
                {t('authoring.scorm.replaceModal.confirm')}
              </SolidButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
