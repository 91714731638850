import { useEffect, useState } from 'react';

import AdminUsersService from '@/client/services/api/admin/users/AdminUsersService';
import { CSVValidationData } from '@/client/types/admin/user-manager/CsvValidationData';
import { useAuthStore } from '@/client/services/state/authStore';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export interface CsvFinalMutationPayload {
  payload: CSVValidationData;
}

export const useAdminUsers = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const useNewProliferation = company.feature_flags.enable_proliferation_new_route;
  const [csv, setCsv] = useState<File | null>(null);

  const csvInitialMutation = useMutation({
    mutationFn: () => AdminUsersService.csvInitial(csv, useNewProliferation),
    onError: (error: any) => {
      console.error('error: ', error);
      const { response } = error;
      const { status, data } = response;

      if (status === 409) {
        setToast({
          show: true,
          status: 'error',
          title: t('edit.useradd.duplicate_rows'),
        });
        return;
      }

      setToast({
        show: true,
        status: 'error',
        title:
          data && data.message
            ? data.message.substr(data.message.lastIndexOf(':') + 1)
            : 'Unable to process file.',
      });
    },
  });

  const csvValidationMutation = useMutation({
    mutationFn: () => AdminUsersService.csvValidation(csv, useNewProliferation),
    onError: (error: any) => {
      console.error('error: ', error);
      const { response } = error;
      const { status, data } = response;
      const { message } = data;

      if (status === 409) {
        const key: string = (message && message.includes('duplicate')) ? 'edit.useradd.user_file_has_duplicates': 'edit.useradd.user_seats_limit_met';
        setToast({
          show: true,
          status: 'error',
          title: t(key),
        });
        return;
      }

      setToast({
        show: true,
        status: 'error',
        title:
          data && data.message
            ? data.message.substr(data.message.lastIndexOf(':') + 1)
            : 'Unable to process file.',
      });
    },
  });

  useEffect(() => {
    if (csv) {
      csvValidationMutation.mutate();
    }
  }, [csv]);

  const csvFinalMutation = useMutation({
    mutationFn: ({ payload }: CsvFinalMutationPayload) =>
      AdminUsersService.csvFinal(payload, useNewProliferation),
    onError: (error: any) => {
      console.error('error importing users: ', error);

      setToast({
        show: true,
        status: 'error',
        title: t('admin.users.bulkImport.error'),
      });
    },
  });

  return {
    csv,
    setCsv,
    csvInitialMutation,
    csvValidationMutation,
    csvFinalMutation,
  };
};
