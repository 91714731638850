import { ExploreContent } from '@/client/types/ExploreContent';
import { learnApiClient } from '../clients/learnApiClient';

interface RecommendationsResponse {
  recommendationId: string;
  content: ExploreContent[];
}

const getGeneralRecommendations = async (): Promise<RecommendationsResponse> => {
  const response = await learnApiClient.get('/recommendations/general');

  return response.data.data;
};

const getRecommendations = async (contentId: string) => {
  const response = await learnApiClient.get(`/recommendations/per_item/${contentId}`);

  return response.data.data;
};

const RecommendationsService = {
  getGeneralRecommendations,
  getRecommendations,
};

export default RecommendationsService;
