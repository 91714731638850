import { Button, FormControl, Input, Stack } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useLogin } from '@/client/services/hooks/preauth/useLogin';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PartnerLoginConfig } from '@/client/types/preauth/Preauth';

type UIDLoginFormProps = {
  idPlaceholder: string;
  registerButtonCopy: string;
  idPattern: any;
  idLimit: number;
  partnerLoginConfig?: PartnerLoginConfig;
};

interface UIDInputs {
  employeeId: string;
  password: string;
}

export default function UIDLoginForm({ idPlaceholder, registerButtonCopy, idPattern, idLimit, partnerLoginConfig }: UIDLoginFormProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { login, loginLoading } = useLogin();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<UIDInputs>({ mode: 'onChange' });

  const onSubmit: SubmitHandler<UIDInputs> = (data) =>
    login({ userid: data.employeeId, password: data.password, loginType: 'employeeId', partnerLoginConfig });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <FormControl isInvalid={!!errors.employeeId}>
          <Input
            variant="flushed"
            placeholder={idPlaceholder || `${t('global.forms.labels_userid')}`}
            {...register('employeeId', {
              required: true,
              pattern: idPattern,
              maxLength: idLimit,
            })}
          />
        </FormControl>

        <FormControl>
          <Input
            variant="flushed"
            type="password"
            placeholder={`${t('global.forms.labels_password')}`}
            {...register('password', { required: true })}
          />
        </FormControl>

        <Stack spacing={4}>
          <Button type="submit" isDisabled={!isValid} isLoading={loginLoading}>
            {t('preauth.signin.button')}
          </Button>

          <Button onClick={() => navigate('/register-with-userid')}>
            {registerButtonCopy || t('preauth.signin.register_with_userid')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

UIDLoginForm.defaultProps = {
  partnerLoginConfig: {
    checkForPartnerMatch: false,
    partner: ''
  }
}