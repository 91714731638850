import { PartnerPermissions } from '@/client/types/Partner';
import { create } from 'zustand';

interface DeleteModal {
  selectedItem: any | null;
  isOpen: boolean;
}

interface PartnerPermissionModal {
  selectedItem: any | null;
  isOpen: boolean;
  isControlled: boolean;
  value: PartnerPermissions;
  handleSubmit: ((item: any) => void) | null;
}

interface CategoriesState {
  deleteModal: DeleteModal;
  partnerPermissionModal: PartnerPermissionModal;
  setDeleteModal: (deleteModal: DeleteModal) => void;
  setPartnerPermissionModal: (partnerPermissionModal: PartnerPermissionModal) => void;
}

export const useAdminStore = create<CategoriesState>((set) => ({
  deleteModal: { isOpen: false, selectedItem: null },
  partnerPermissionModal: { isOpen: false, selectedItem: null, value: 'tenant', isControlled: false, handleSubmit: null },
  setDeleteModal: (deleteModal) => set({ deleteModal }),
  setPartnerPermissionModal: (partnerPermissionModal) => set({ partnerPermissionModal }),
}));
