import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import DeleteItemModal from '@/client/components/admin/overlay/DeleteItemModal';
import ThreeColumnLayout from '@/client/components/layout/admin/ThreeColumnLayout';
import { useConnect } from '@/client/services/hooks/admin/connect/useConnect';
import { useConnectStore } from '@/client/services/state/admin/connectStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function Locations() {
  const { t } = useTranslation();
  const { deleteItem } = useConnect();
  const {
    setType,
    setShowExternalOption,
    setColumnCopy,
    clearStore,
    setSortOrder,
    setSearchTerm,
    isSearching,
    sortOrder,
  } = useConnectStore();

  const columnOneTitle = t('admin.connect.locations.columnOneTitle');
  const columnTwoTitle = t('admin.connect.locations.columnTwoTitle');
  const columnThreeTitle = t('admin.connect.locations.columnThreeTitle');
  const plural = t('admin.connect.locations.plural');
  const singular = t('admin.connect.locations.singular');
  const subLocationsPlural = t('admin.connect.locations.columnTwoTitlePlural');
  const subSubLocationsPlural = t('admin.connect.locations.columnThreeTitlePlural');

  const columnCopy = {
    plural,
    singular,
    columnOneTitle,
    columnTwoTitle,
    columnThreeTitle,
    noSubItemsText: t('admin.connect.noSubItems', {
      subType: columnTwoTitle,
      mainType: columnOneTitle,
    }),
    noSubSubItemsText: t('admin.connect.noSubSubItems', {
      subSubType: columnThreeTitle,
      subType: columnTwoTitle,
    }),
    noResultsMain: t('admin.connect.noSearchResultsMain', { type: plural }),
    noResultsSub: t('admin.connect.noSearchResultsSub', {
      subType: subLocationsPlural,
      mainType: columnOneTitle,
    }),
    noResultsSubSub: t('admin.connect.noSearchResultsSubSub', {
      subSubType: subSubLocationsPlural,
      subType: columnTwoTitle,
    }),
  };

  useEffect(() => {
    clearStore();
    setType('location');
    setShowExternalOption(false);
    setColumnCopy(columnCopy);
  }, []);

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={t('admin.connect.searchPlaceholder', { type: singular })}
        showSortSelector
        title={plural}
        setSearchTerm={setSearchTerm}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isSearching={isSearching}
      />

      <ThreeColumnLayout />

      <DeleteItemModal
        subType={subLocationsPlural}
        subSubType={subSubLocationsPlural}
        deleteItem={deleteItem}
      />
    </>
  );
}
