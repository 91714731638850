import { Button, Stack } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

export default function SampleButtons({ overrideColor }: { overrideColor: string }) {
  const { t } = useTranslation();

  return (
    <Stack>
      <Button
        size="xs"
        backgroundColor={overrideColor || 'brand.primary'}
        _hover={{ backgroundColor: overrideColor || 'brand.primary' }}
      >
        {t('admin.theme.samples.sampleSolidButton')}
      </Button>

      <Button
        size="xs"
        variant="outline"
        color={overrideColor || 'brand.primary'}
        borderColor={overrideColor || 'brand.primary'}
        _hover={{ color: 'white', backgroundColor: overrideColor || 'brand.primary' }}
      >
        {t('admin.theme.samples.sampleOutlineButton')}
      </Button>
    </Stack>
  );
}
