import { Box, GridItem, Stack, Text } from '@chakra-ui/react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

import { format } from 'date-fns';
import { useRef } from 'react';
import { useRenderContentIcon } from '@/client/services/hooks/useRenderContentIcon';
import { useTranslation } from 'react-i18next';
import { capCase } from '@/client/utils/capCase';

export interface CardProps {
  id: string;
  type: string;
  cardType: string;
  title: string | undefined;
  createdAt: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const ItemTypes = {
  CARD: 'card',
};

export default function DraggableCard({ id, type, cardType, title, createdAt, index, moveCard }: CardProps) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <GridItem
      ref={ref}
      style={{ opacity }}
      cursor="move"
      backgroundColor="#eaeaea"
      padding={6}
      data-handler-id={handlerId}
      _hover={{ boxShadow: 'inset 0 0 0 5px #00bcf1' }}
      colSpan={{ base: 1, md: cardType === 'card' && index === 0 ? 2 : 1 }}
      minHeight={cardType === 'card' ? '400px' : '100px'}
      borderRadius={4}
      color={'blackAlpha.800'}
    >
      {cardType === 'card' && (
        <Stack padding={6} pt={20} flex="1" zIndex={2} spacing={0}>
          <Box
            width="40px"
            height="40px"
            borderRadius="60px"
            border="2px solid black"
            alignItems="center"
            display="inline-flex"
            justifyContent="center"
            marginBottom={2}
            color="black"
          >
            {useRenderContentIcon(type)}
          </Box>

          <Text fontWeight={600} fontSize="24px" mb={4} flex={1}>
            {title}
          </Text>
          <Text fontSize="xs" opacity="0.8">
            {t('explore.order.published_on')} {format(new Date(createdAt), 'MM-dd-yyyy')}
          </Text>
        </Stack>
      )}

      {cardType === 'list' && (
        <>
          <Text fontSize="2xs" opacity="0.5" marginBottom={0.5}>
            {capCase(type)}
          </Text>

          <Text fontSize="md" fontWeight="bolder" noOfLines={1}>
            {title}
          </Text>

          <Text fontSize="xs" opacity="0.8">
            {t('explore.order.published_on')} {format(new Date(createdAt), 'MM-dd-yyyy')}
          </Text>
        </>
      )}
    </GridItem>
  );
}
