import { Box, Button, HStack, useToken } from '@chakra-ui/react';
import { Dispatch, SetStateAction, useState } from 'react';

import WebhooksIntegrationsArchive from './WebhooksIntegrationsArchive';
import WebhooksIntegrationsEdit from './WebhooksIntegrationsEdit';
import WebhooksIntegrationsList from './WebhooksIntegrationsList';
import WebhooksIntegrationsSingleView from './WebhooksIntegrationsSingleView';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useTranslation } from 'react-i18next';

interface WebhooksManagerTypes {
  editing: string;
  viewId: string;
  setEditing: Dispatch<SetStateAction<string>>;
  setViewId: Dispatch<SetStateAction<string>>;
  getWebhooks: any;
  removeWebhook: any;
  setEditingId: Dispatch<SetStateAction<string>>;
  editingId: string;
}
export default function WebhooksManager({
  editing,
  viewId,
  setEditing,
  setViewId,
  getWebhooks,
  removeWebhook,
  setEditingId,
  editingId,
}: WebhooksManagerTypes) {
  const [visibleTable, setVisibleTable] = useState<'active' | 'archived'>('active');
  const [brandButton] = useToken('colors', ['brand.button']);
  const { t } = useTranslation();

  return (
    <Box
      backgroundColor="white"
      marginTop={2}
      marginX={4}
      padding={5}
      borderRadius="xl"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
    >
      {editing === '' && viewId === '' && (
        <Box marginBottom={4}>
          <HStack>
            <Button
              onClick={() => {
                setVisibleTable('active');
              }}
              variant="adminTab"
              size="xs"
              background={visibleTable === 'active' ? hexToRGBA(brandButton, 0.2) : 'transparent'}
            >
              {t('webhooks.active')}
            </Button>
            <Button
              onClick={() => {
                setVisibleTable('archived');
              }}
              variant="adminTab"
              size="xs"
              background={visibleTable === 'archived' ? hexToRGBA(brandButton, 0.2) : 'transparent'}
            >
              {t('webhooks.archived')}
            </Button>
          </HStack>
        </Box>
      )}
      {visibleTable === 'active' && editing === '' && viewId === '' && (
        <WebhooksIntegrationsList
          setEditing={setEditing}
          setViewId={setViewId}
          getWebhooks={getWebhooks}
          removeWebhook={removeWebhook}
          setEditingId={setEditingId}
        />
      )}
      {visibleTable === 'archived' && editing === '' && viewId === '' && (
        <WebhooksIntegrationsArchive getWebhooks={getWebhooks} />
      )}
      {editing === '' && viewId !== '' && (
        <WebhooksIntegrationsSingleView id={viewId} setViewId={setViewId} />
      )}
      {editing === 'webhooks' && (
        <WebhooksIntegrationsEdit
          setEditing={setEditing}
          existingId={editingId}
          setEditingId={setEditingId}
        />
      )}
    </Box>
  );
}
