import { AxiosError } from 'axios';
import ScormService from '@/client/services/api/ScormService';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useQuery } from '@tanstack/react-query';

export const useGetScorm = (
  scormId: string | undefined,
  contextId: string | undefined,
  scormLimit: number,
  scormEnforcementType: string,
  onArchivedError?: () => void,
  isPreview?: boolean,
) =>
  useQuery({
    enabled: isPreview ? !!scormId : !!scormId && !!contextId,
    queryKey: ['scorm', scormId],
    cacheTime: 0,
    queryFn: () => ScormService.getScorm(scormId, contextId, scormLimit, scormEnforcementType),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      document.title = data.scorm.title;

      trackEvent('scorm_viewed', {
        content: data.scorm._id,
        content_type: 'scorm',
        title: data.scorm.title,
      });
    },
    onError: (e) => {
      const error = e as AxiosError<{ status?: number }>;
      if (error.response?.status === 403) {
        onArchivedError && onArchivedError();
      }
    },
  });
