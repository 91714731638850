import { Box } from '@chakra-ui/react';
import ConnectInput from '@/client/components/admin/connect/ConnectInput';
import SingleColumnList from '../../admin/lists/SingleColumnList';
import { useConnectStore } from '@/client/services/state/admin/connectStore';
import { useEffect } from 'react';

interface SingleColumnLayoutProps {
  showInput?: boolean;
  noSearchResultsText: string;
}

export default function SingleColumnLayout({
  showInput = true,
  noSearchResultsText,
}: SingleColumnLayoutProps) {
  const { items, isSearching, setIsSearching, searchItems, searchTerm } = useConnectStore();

  useEffect(() => {
    if (searchTerm) {
      setIsSearching(true);
      return;
    }

    setIsSearching(false);
  }, [searchTerm]);

  return (
    <Box backgroundColor="white" paddingX={6} paddingY={2} marginX={4} marginY={2}>
      {showInput && !isSearching && <ConnectInput level={1} parentId={null} />}

      <SingleColumnList
        items={isSearching ? searchItems : items}
        noSearchResultsText={noSearchResultsText}
      />
    </Box>
  );
}
