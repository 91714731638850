import type { SlackChannel } from '@/client/types/admin/slack/SlackChannel';
import { v3ApiClient } from '@/client/services/api/clients/v3ApiClient';

const getSlackChannelList = async (): Promise<SlackChannel[]> => {
  const response = await v3ApiClient.get('slackmessage/channels?include_list=');

  return response.data.channels;
};

const checkSlackEnabled = async (): Promise<boolean> => {
  const response = await v3ApiClient.get('companyintegrations');

  const companyIntegration = response.data.length > 0 ? response.data[0] : null;

  let slackEnabled = false;

  if (
    companyIntegration &&
    companyIntegration.slack &&
    companyIntegration.slack.token_type &&
    companyIntegration.slack.token_type === 'bot'
  ) {
    slackEnabled = true;
  }

  return slackEnabled;
};

const manuallyAddChannel = async (channelId: string): Promise<SlackChannel> => {
  const response = await v3ApiClient.get(`slackmessage/channel-access/${channelId}`);

  return response.data;
};

const SlackService = {
  getSlackChannelList,
  checkSlackEnabled,
  manuallyAddChannel,
};

export default SlackService;
