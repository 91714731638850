import ContentService from '@/client/services/api/ContentService';
import { useQuery } from '@tanstack/react-query';

export const useGetCommentCount = (
  contentType: string | undefined,
  contentId: string | undefined,
  inTrack?: boolean,
  trackId?: string
) =>
  useQuery({
    queryKey: [`query-${contentType}-comment-count`, { contentId }],
    queryFn: async () => await ContentService.getCommentCount(contentType!, contentId!, inTrack, trackId),
  });
