import SolidButton from '@/client/components/shared/buttons/SolidButton';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface CreatePublishButtonProps {
  isDisabled?: boolean;
  onClick: () => void;
}

export default function CreatePublishButton({
  isDisabled = false,
  onClick,
}: CreatePublishButtonProps) {
  const { id } = useParams();
  const { t } = useTranslation();
  const { trigger } = useFormContext();

  const isUpdating = !!id;

  return (
    <SolidButton
      isDisabled={isDisabled}
      onClick={onClick}
      onMouseOver={() => {
        if (isDisabled) {
          trigger();
        }
      }}
    >
      {isUpdating ? t('authoring.update') : t('authoring.publish')}
    </SolidButton>
  );
}
