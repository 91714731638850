import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import CommentList from '@/client/components/lists/CommentList';
import HtmlRenderer from '@/client/components/html/HtmlRenderer';
import { Workshop } from '@/client/types/content/Workshop';
import { useTranslation } from 'react-i18next';
import { usePartnerStore } from '@/client/services/state/partnerStore';

interface WorkshopTabsProps {
  workshop: Workshop;
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
}

export default function WorkshopTabs({
  workshop,
  activeTabIndex,
  setActiveTabIndex,
}: WorkshopTabsProps) {
  const { t } = useTranslation();
  const { partner } = usePartnerStore();

  return (
    <Tabs
      index={activeTabIndex}
      width="full"
      borderBottomColor="transparent"
      onChange={(index) => setActiveTabIndex(index)}
    >
      {workshop.allow_comments && !partner._id && (
        <TabList>
          <Tab fontSize="sm">{t('contentViews.events.info_tabName')}</Tab>
          <Tab id="discussion" fontSize="sm">
            {t('contentViews.events.discussion_tabName')}
          </Tab>
        </TabList>
      )}

      <TabPanels width="full">
        <TabPanel>{workshop.description && <HtmlRenderer html={workshop.description} />}</TabPanel>

        {workshop.allow_comments && !partner._id && (
          <TabPanel>
            <CommentList
              contentId={workshop._id}
              contentType="workshop"
              contentTitle={workshop.title}
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}
