import ExploreService from '../api/ExploreService';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useSearchExploreContent = (
  isSearching: boolean,
  queryString: string,
  searchTerm: string
) =>
  useInfiniteQuery({
    enabled: isSearching,
    queryKey: [`search-content`, { queryString }],
    queryFn: ({ pageParam = 1 }) =>
      ExploreService.searchContent(queryString, pageParam, searchTerm),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });
