import { Box, Container, Flex, HStack, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { Dribbble, Facebook, GitHub, Instagram, LinkedIn, Twitter } from '@/client/components/icons/ContinuIcons';
import { format, formatDistance, subDays } from 'date-fns';
import { useEffect, useState } from 'react';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import ErrorAlert from '../components/data-display/ErrorAlert';
import Feed from '../components/lists/Feed';
import HtmlRenderer from '../components/html/HtmlRenderer';
import Loading from '@/client/components/media/Loading';
import SkillTagList from '../components/lists/SkillTagList';
import User from '@/client/services/api/User';
import UserStatsSection from '../components/data-display/dashboard/UserStatsSection';
import ViewActions from '@/client/components/overlay/ViewActions';
import { useAuthStore } from '@/client/services/state/authStore';
import useDocumentTitle from '../utils/useDocumentTitle';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export default function Profile() {
  useDocumentTitle('User');
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { authConfig } = useAuthStore();
  const company = authConfig.company;
  const currentUser = authConfig.user;
  const [socialFeed, setSocialFeed] = useState<any[] | null>(null);
  const defaultBannerImg =
    'https://d2277n3gvptnup.cloudfront.net/images/51d5f21b2f410a020b000002/cb4e0e69-b263-43a6-aa1d-f73d8325688c.jpg';

  const renderSocialIcon = (type: string) => {
    switch (type) {
      case 'linkedin':
        return <LinkedIn />;
      case 'github':
        return <GitHub />;
      case 'dribbble':
        return <Dribbble />;
      case 'twitter':
        return <Twitter />;
      case 'facebook':
        return <Facebook />;
      case 'instagram':
        return <Instagram />;
    }
  };

  const currentDate = format(subDays(new Date(), 30), 'MM-dd-yy');

  const currentUsersProfile = currentUser._id === id;

  const {
    isLoading: isUserLoading,
    isError: isUserError,
    data: user,
    error: userError,
  } = useQuery(['query-user', { id }], async () => await User.getUser(id));

  const {
    isLoading: isUserSuggestionsLoading,
    isError: isUserSuggestionsError,
    data: userSuggestions,
    error: userSuggestionsError,
  } = useQuery(['query-user-suggestions', { id }], async () => await User.getSuggestions(id));

  const {
    isLoading: isUserCommentsLoading,
    isError: isUserCommentsError,
    data: userComments,
    error: userCommentsError,
  } = useQuery(['query-user-comments', { id }], async () => await User.getComments(id));

  const {
    isLoading: isUserCommentCommentLoading,
    isError: isUserCommentCountError,
    data: userCommentCount,
    error: userCommentCountError,
  } = useQuery(['query-user-comment-count', { id }], async () => await User.getCommentCount(id));

  const {
    isLoading: isUserContentViewsLoading,
    isError: isUserContentViewsError,
    data: userContentViews,
    error: userContentViewsError,
  } = useQuery(
    ['query-user-content-views', { id, currentDate }],
    async () => await AnalyzeService.getUserContentViews(id, currentDate)
  );

  useEffect(() => {
    if (userSuggestions && userComments) {
      setSocialFeedItems(userSuggestions, userComments);
    }
  }, [userSuggestions, userComments, user]);

  const setSocialFeedItems = (suggestions: [], comments: []) => {
    const types = {
      article: '',
      comment: '',
      like: '',
      media: '',
      track: '',
      quiz: '',
      presentation: '',
      event: '',
      assesment: '',
    };

    let socialFeedItems: any[] = [];

    // TODO: Clean object key types up
    const formatFeedItems = (arr: any[], actionType: string) => {
      arr.forEach((item) => {
        Object.keys(item).forEach((key) => {
          Object.keys(types).forEach((type) => {
            if (key === type) {
              (item as any)[key]
                ? socialFeedItems.push({
                    _id: (item as any)[key]._id,
                    type: type === 'media' ? (item as any)[key].type : key,
                    title: type === 'track' ? (item as any)[key].name : (item as any)[key].title,
                    actionType: actionType,
                    createdAt: item.createdAt,
                    timeSince: formatDistance(new Date(item.createdAt), new Date()),
                  })
                : null;
            }
          });
        });
      });
    };

    formatFeedItems(comments, 'comment');
    formatFeedItems(suggestions, 'like');

    const sorted = socialFeedItems.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));

    setSocialFeed(sorted);
  };

  if (isUserLoading) return <Loading />;

  if (isUserError) return <ErrorAlert title="Unable to load profile" />;

  return (
    <Container minWidth="100%" minHeight="100vh" position="relative">
      <ViewActions
        buttons={authConfig.user._id === id ? ['gototop', 'editprofile'] : ['gototop']}
        userid={authConfig.user._id}
      />
      <Box
        position="absolute"
        top="0"
        left="0"
        width="full"
        height={{ base: '100vh', md: '600px' }}
        zIndex="0"
        overflow="hidden"
        background={user.banner_image ? 'black' : 'brand.mist'}
      >
        <Box
          position="absolute"
          top="0"
          left="0"
          width="full"
          height="100%"
          backgroundColor="black"
          backgroundImage={user.banner_image ? 'url(' + user.banner_image + ')' : 'url(' + defaultBannerImg + ')'}
          backgroundPosition="center"
          backgroundSize="cover"
          filter="blur(7px)"
          transform="scale(1.1)"
        ></Box>
        <Box
          position="absolute"
          top="0"
          left="0"
          width="full"
          height="100%"
          zIndex="1"
          background={'blackAlpha.600'}
        ></Box>
        <Box
          position="absolute"
          bottom="0"
          left="0"
          width="full"
          height="50%"
          bgGradient="linear(transparent 0%, white 98%)"
          zIndex="2"
        ></Box>
      </Box>

      <UserStatsSection
        name={user.full_name}
        email={company.feature_flags.enable_email_visibility_in_user_profile ? user.email : null}
        userTitle={user.job_title}
        stats={[
          {
            statCount: userCommentCount ? userCommentCount.count : 0,
            counterLabel: t('userProfileDisplay.comments'),
            counterSubtext: t('userProfileDisplay.contributed_to_discussions') || '',
          },
          {
            statCount: userContentViews ? userContentViews.count : 0,
            counterLabel: t('userProfileDisplay.content_view'),
            counterSubtext: t('userProfileDisplay.in_last_30') || '',
          },
        ]}
        avatarSrc={user.image}
        currentUser={currentUsersProfile}
      />

      <Flex direction={{ base: 'column', lg: 'row' }} zIndex="1" position="relative">
        <Flex direction="column" background="white" padding={8} width={{ base: '100%', lg: '70%' }}>
          <Stack direction={{ base: 'column' }} spacing={10}>
            <Flex flexDirection={{ base: 'column', md: 'row' }}>
              {!!user.linked_locations.length && (
                <Box flex="1" marginBottom={{ base: 8, md: 0 }}>
                  <Heading variant="h3" fontSize="2xs" textTransform="uppercase" marginTop={0} marginBottom={4}>
                    {t('userProfileDisplay.location')}
                  </Heading>
                  <Text color="brand.primary" fontSize="lg">
                    {user.linked_locations[0].name}
                  </Text>
                </Box>
              )}

              {!!user.linked_departments.length && (
                <Box flex="1" marginBottom={{ base: 8, md: 0 }}>
                  <Heading variant="h3" fontSize="2xs" textTransform="uppercase" marginTop={0} marginBottom={2}>
                    {t('userProfileDisplay.department')}
                  </Heading>
                  <Text color="brand.primary" fontSize="lg">
                    {user.linked_departments[0].name}
                  </Text>
                </Box>
              )}

              {!!user.hired && (
                <Box flex="1">
                  <Heading variant="h3" fontSize="2xs" textTransform="uppercase" marginTop={0} marginBottom={4}>
                    {t('userProfileDisplay.with_company_for')}
                  </Heading>
                  <Text color="brand.primary" fontSize="lg">
                    {format(new Date(user.hired), 'MMMM do, yyyy ')}
                  </Text>
                </Box>
              )}
            </Flex>

            {!!user.about && (
              <Box>
                <Heading variant="h3" fontSize="sm" textTransform="uppercase" marginTop={0} marginBottom={4}>
                  {t('userProfileDisplay.about')}
                </Heading>

                <HtmlRenderer html={user.about} />
              </Box>
            )}

            <Box>
              <Heading variant="h3" fontSize="sm" textTransform="uppercase" marginTop={0} marginBottom={4}>
                {t('userProfileDisplay.skills')}
              </Heading>

              {user.skills.length ? (
                <SkillTagList skills={user.skills} removeSkill={() => {}} />
              ) : (
                <Text>{t('userProfileDisplay.no_skills', { name: user.first_name })}</Text>
              )}
            </Box>

            {company.feature_flags.enable_social_profiles && (
              <Box>
                <Heading variant="h3" fontSize="sm" textTransform="uppercase" marginTop={0} marginBottom={4}>
                  {t('userProfileDisplay.social')}
                </Heading>

                {user.social_links.length ? (
                  <HStack>
                    {user.social_links.map((link: any) => {
                      return (
                        <Flex key={link._id} alignItems="center" marginX={4}>
                          <Box marginRight={2} color="brand.gray.500">
                            {renderSocialIcon(link.name)}
                          </Box>

                          <Link href={`${link.value}`}>{link.name}</Link>
                        </Flex>
                      );
                    })}
                  </HStack>
                ) : (
                  <Text>{t('userProfileDisplay.no_social', { name: user.first_name })}</Text>
                )}
              </Box>
            )}
          </Stack>
        </Flex>

        <Flex
          direction="column"
          background="white"
          padding={8}
          marginLeft={{ base: 0, lg: 4 }}
          marginTop={{ base: 4, lg: 0 }}
          width={{ base: '100%', lg: '30%' }}
        >
          <Heading variant="h3" fontSize="sm" textTransform="uppercase" marginTop={0}>
            {t('userProfileDisplay.social_feed')}
          </Heading>

          {socialFeed && socialFeed.length > 0 ? (
            <Feed feed={socialFeed} firstName={user.first_name}></Feed>
          ) : (
            <Text>{t('dashboard.activity.manager_timeline_noTimeline')}</Text>
          )}
        </Flex>
      </Flex>
    </Container>
  );
}
