/* eslint-disable no-restricted-syntax */

import './fonts/fonts.css';
import './i18n';

import { Box, ChakraProvider, extendTheme, useToast } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import ConfigService from '@/client/services/api/ConfigService';
import Loading from '@/client/components/media/Loading';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import customTheme from './theme/theme';
import { useAuthStore } from '@/client/services/state/authStore';
import { useConfigStore } from '@/client/services/state/configStore';
import { useIntercom } from './services/hooks/useIntercom';
import { useNPS } from './services/hooks/useNPS';
import { useQuery } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { usePartnerStore } from './services/state/partnerStore';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const chakraToast = useToast();
  const { config, setConfig } = useConfigStore();
  const { toast, setToast } = useToastStore();
  const [theme, setTheme] = useState<any>();
  const [hostname, setHostname] = useState<string>('');
  const { authConfig } = useAuthStore();
  const { partner } = usePartnerStore();
  const { i18n } = useTranslation();
  const { intercomUpdate, intercomShutdown } = useIntercom();
  const { removeNPS } = useNPS();

  const updateThemeFromEffect = () => {
    if (!config.theme || Object.keys(config.theme).length === 0) {
      return;
    }
    let partnerPrimary: string | null = partner?.branding?.color_primary;
    if (partnerPrimary === '') {
      partnerPrimary = null;
    }
    const newTheme = extendTheme(customTheme, {
      colors: {
        brand: {
          primary: partnerPrimary ?? config.theme.colors.brand.primary,
          secondary: config.theme.colors.brand.secondary,
          tertiary: config.theme.colors.brand.tertiary,
          button:
            partnerPrimary ??
            (config.theme.colors.overrides?.buttons !== ''
              ? config.theme.colors.overrides?.buttons
              : config.theme.colors.brand.primary),
          link:
            partnerPrimary ??
            (config.theme.colors.overrides?.links !== ''
              ? config.theme.colors.overrides?.links
              : config.theme.colors.brand.primary),
          tableHeader:
            partnerPrimary ??
            (config.theme.colors.overrides?.headers !== ''
              ? config.theme.colors.overrides?.headers
              : config.theme.colors.brand.primary),
          formInput:
            partnerPrimary ??
            (config.theme.colors.overrides?.formInputs !== ''
              ? config.theme.colors.overrides?.formInputs
              : config.theme.colors.brand.primary),
          highlight:
            partnerPrimary ??
            (config.theme.colors.overrides?.highLights !== ''
              ? config.theme.colors.overrides?.highLights
              : config.theme.colors.brand.primary),
        },
      },
    });
    setTheme(newTheme);
  };

  useEffect(() => {
    window.location.hostname === 'local.continu.co'
      ? setHostname('staging.continu.co')
      : setHostname(window.location.hostname);
  }, []);

  useEffect(() => {
    const { ga } = window;

    if (ga) {
      ga('set', 'page', location.pathname);
      ga('send', 'pageview');
    }

    if (authConfig.authtoken) {
      intercomUpdate();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!authConfig.authtoken) {
      intercomShutdown();
      removeNPS();

      return;
    }

    if (authConfig?.user?.language) {
      i18n.changeLanguage(authConfig.user.language);
    }
  }, [authConfig]);

  useEffect(() => {
    updateThemeFromEffect();
  }, [partner, config.theme]);

  useEffect(() => {
    if (toast.show) {
      chakraToast({
        description: toast.title,
        status: toast.status,
        duration: 5000,
        isClosable: true,
        position: 'top',
        onCloseComplete: () => setToast({ show: false, status: 'success', title: '' }),
      });
    }
  }, [toast]);

  const { isLoading: isConfigLoading, isError: isConfigError } = useQuery({
    queryKey: ['config', hostname],
    queryFn: () => ConfigService.getConfig(hostname),
    refetchOnWindowFocus: false,
    enabled: hostname !== '',
    onSuccess: (res: any) => {
      setConfig(res);

      if (res.customCopy) {
        const { customCopy } = res;

        for (const langKey of Object.keys(customCopy)) {
          const customCopyObj: { [key: string]: string } = {};

          for (const transPair of customCopy[langKey]) {
            customCopyObj[transPair.key] = transPair.value;
          }

          i18n.addResourceBundle(langKey, 'translation', customCopyObj, true, true);
        }
      }
    },
    onError: (err: any) => {
      console.log('Error', err.response?.data || err);
      navigate('/');
      setTheme(customTheme);
    },
  });

  if (isConfigLoading) {
    return <Loading />;
  }

  if (isConfigError) {
    throw new Error('Unable to fetch application configuration, please try again later.');
  }

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <ChakraProvider theme={theme}>
        <Box>
          <Outlet />
        </Box>
      </ChakraProvider>
    </QueryParamProvider>
  );
}

export default App;
