import { HStack, Tag, TagLabel, TagRightIcon } from '@chakra-ui/react';

import { Close } from '@/client/components/icons/ContinuIcons';
import Combobox from '../Combobox';
import ContentSegmentationSearchService from '@/client/services/api/admin/segmentation/ContentSegmentationSearchService';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';

/*
 * In AngularJS: filtered-content-search.html
 * Used in the following:
 * archived-manager.html
 * content-host-assignments.html
 * content-host-views.html
 * content-manager.html
 * content-rating-feedback.html
 * imported-content-host-registrations.html
 * journey-host.html
 * polls-manager.html
 * polls-results.html
 * scorm-host-registrations.html
 * track-host.html
 * workshops-manager.html
 * assessments-manager.html
 * assignments-manager.html
 * manager-user-assignments.html
 * edit-report.html
 */

interface ContentSegmentationSearchProps {
  label: string;
  contentOnly?: boolean;
  includeJourneys?: boolean;
  selectedItems: any[];
  setSelectedItems: any;
  forType: string;
  isRequired?: boolean;
  noList?: boolean;
  inputVariant?: string;
}

export default function ContentSegmentationSearch({
  label,
  contentOnly = false,
  includeJourneys = false,
  selectedItems,
  setSelectedItems,
  forType,
  isRequired = false,
  noList = false,
  inputVariant = '',
}: ContentSegmentationSearchProps) {
  const { watch, setValue } = useFormContext();
  const searchTerm = watch('contentFilterSearchTerm');

  let types = '';

  switch (forType) {
    case 'content':
      types += 'articles,videos,files,';
      break;
    case 'reportContent':
      types += 'articles,videos,files,scorm,imported_content,workshops,tracks,';
      break;
    case 'track':
      types += 'tracks,';
      break;
    case 'journey':
      types += 'journeys,';
      break;
    case 'workshop':
      types += 'workshops,';
      break;
    case 'assessment':
      types += 'assessments,';
      break;
    case 'scorm':
      types += 'scorm,';
      break;
    case 'imported_content':
      types += 'imported_content,';
      break;
    case 'contentAndJourneys':
      types += 'articles,videos,files,scorm,imported_content,workshops,tracks,journeys,';
      break;
    default:
      break;
  }

  types = types.slice(0, -1);

  const { data, isFetching } = useQuery({
    enabled: !!searchTerm,
    queryKey: ['user-group-search', searchTerm, types, contentOnly, includeJourneys],
    queryFn: () => ContentSegmentationSearchService.search(searchTerm, types, contentOnly, includeJourneys),
  });

  return (
    <>
      <Combobox
        label={label}
        items={data || []}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        isLoading={isFetching}
        searchTerm={searchTerm}
        setSearchTerm={(input: string) => setValue('contentFilterSearchTerm', input)}
        isRequired={isRequired}
        inputVariant={inputVariant}
      />

      {selectedItems?.length > 0 && !noList && (
        <HStack flexWrap="wrap">
          {selectedItems.map((item) => (
            <Tag variant="admin" key={item._id}>
              <TagLabel>
                {item.legacyName || item.name || item.full_name || item.title || item.label}
              </TagLabel>

              <TagRightIcon
                boxSize={3}
                as={Close}
                _hover={{ cursor: 'pointer' }}
                onClick={() => setSelectedItems(selectedItems.filter((i) => i._id !== item._id))}
              />
            </Tag>
          ))}
        </HStack>
      )}
    </>
  );
}
