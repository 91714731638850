import { graphql } from '@/client/services/api/graphql/gql/gql';
// IMPORTANT: Build gql after ANY changes to queries (client OR api)

export const getUserOverviewStats: any = graphql(`
  query UserStats {
    companyUserStats {
      activeUsers
      firstLoginLastThirty
      lastLoginThisMonth
      availableSeats
      pendingUsers
      externalUsers
      adminUsers
      creatorUsers
      suspendedUsers
    }
  }
`);
