import { Button, FormControl, FormErrorMessage, FormHelperText, Input, Link, Stack, Text } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Link as RouterLink } from 'react-router-dom';
import { useLogin } from '@/client/services/hooks/preauth/useLogin';
import { useTranslation } from 'react-i18next';
import { PartnerLoginConfig } from '@/client/types/preauth/Preauth';

interface EmailLoginFormProps {
  registerAllow: boolean;
  partnerLoginConfig?: PartnerLoginConfig;
  purchaseProductId?: string;
}

interface LoginFormInputs {
  email: string;
  password: string;
}

export default function EmailLoginForm({ registerAllow, partnerLoginConfig, purchaseProductId }: EmailLoginFormProps) {
  const { t } = useTranslation();
  const { login, loginLoading } = useLogin();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<LoginFormInputs>({ mode: 'onChange' });

  interface LoginVariables {
    email: string;
    password: string;
  }

  const onSubmit: SubmitHandler<LoginFormInputs> = async (data) =>
    login({ userid: data.email, password: data.password, loginType: 'email', partnerLoginConfig, purchaseProductId });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <FormControl isInvalid={!!errors.email}>
          <Input
            id="email"
            type="email"
            placeholder={`${t('global.forms.labels_emailAddress')}`}
            variant="flushed"
            {...register('email', {
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: t('login.email.error'),
              },
            })}
          />

          <FormErrorMessage>{errors.email && t('login.email.error')}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.password}>
          <Input
            id="password"
            type="password"
            placeholder={`${t('global.forms.labels_password')}`}
            variant="flushed"
            {...register('password', { required: true })}
          />

          <FormHelperText textAlign="right">
            <Link
              as={RouterLink}
              to="/forgot"
              color="gray.400"
              fontSize="xs"
              _hover={{
                color: 'brand.primary',
                cursor: 'pointer',
              }}
            >
              {t('preauth.signin.forgotPassword')}
            </Link>
          </FormHelperText>

          <FormErrorMessage>{errors.password && 'Password is required'}</FormErrorMessage>
        </FormControl>

        <Button type="submit" isDisabled={!isValid} isLoading={loginLoading}>
          {t('preauth.signin.button')}
        </Button>

        {registerAllow && (
          <Link as={RouterLink} to="/register" color="brand.primary" fontSize="xs">
            <Text align="center">Click Here to Register</Text>
          </Link>
        )}
      </Stack>
    </form>
  );
}

EmailLoginForm.defaultProps = {
  partnerLoginConfig: {
    checkForPartnerMatch: false,
    partner: ''
  }
}