import { Avatar, Box, Grid, GridItem, Input, Stack } from '@chakra-ui/react';

import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import AdminUsersService from '@/client/services/api/admin/users/AdminUsersService';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import Loading from '@/client/components/media/Loading';
import { format } from 'date-fns';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

function InfoBlock({ label, value }: { label: string; value: string }) {
  return (
    <Stack spacing={0}>
      <FormLabelWithTooltip label={label} />

      <Input variant="adminInput" value={value} isDisabled />
    </Stack>
  );
}

export default function SuspendedUserProfile({ id }: { id: string | undefined }) {
  const { t } = useTranslation();

  const { data, isLoading, isError } = useQuery({
    enabled: !!id,
    queryKey: ['admin-users-suspended', id],
    queryFn: () => AdminUsersService.getSuspendedUserById(id),
  });

  const formatDate = (date: string) => format(new Date(date), 'MMMM dd, yyyy');

  if (isLoading) return <Loading />;

  if (isError)
    return (
      <ErrorAlert
        title={t('admin.users.suspended.unableToGetUser')}
        backAction={{
          to: '/admin/users',
          label: t('admin.users.suspended.backToUsersOverview'),
        }}
      />
    );

  return (
    <AdminElevatedBox>
      <Box padding={12}>
        <Avatar name={data.full_name} src={data.image} size="2xl" />

        <Grid templateColumns="repeat(2, 1fr)" gap={4} marginTop={8}>
          <GridItem>
            <Stack spacing={4}>
              <InfoBlock label={t('edit.useradd.first_name')} value={data.first_name} />

              <InfoBlock label={t('edit.useradd.last_name')} value={data.last_name} />

              <InfoBlock label={t('edit.useradd.email')} value={data.email} />
            </Stack>
          </GridItem>

          <GridItem>
            <Stack spacing={4}>
              <InfoBlock label={t('userEditor.created_on')} value={formatDate(data.createdAt)} />

              {data.last_login && (
                <InfoBlock
                  label={t('edit.useradd.lastLogin')}
                  value={formatDate(data.last_login)}
                />
              )}
            </Stack>
          </GridItem>
        </Grid>
      </Box>
    </AdminElevatedBox>
  );
}
