import { Flex, Text, Tooltip } from '@chakra-ui/react';

import { DefaultTFuncReturn } from 'i18next';
import { QuestionCircle } from '@/client/components/icons/ContinuIcons';

interface FormLabelWithTooltipProps {
  label: string;
  tooltipText?: string | DefaultTFuncReturn;
  isRequired?: boolean;
  marginBottom?: number;
}

export default function FormLabelWithTooltip({
  label,
  tooltipText,
  isRequired,
  marginBottom,
}: FormLabelWithTooltipProps) {
  return (
    <Flex alignItems="center" marginBottom={marginBottom}>
      <Text variant="adminFormLabel" marginRight={4}>
        {label}

        {isRequired && (
          <Text as="span" color="red" marginLeft={1}>
            *
          </Text>
        )}
      </Text>

      {tooltipText && (
        <Tooltip hasArrow label={tooltipText} aria-label="A tooltip">
          <QuestionCircle color="brand.grey.50" />
        </Tooltip>
      )}
    </Flex>
  );
}

FormLabelWithTooltip.defaultProps = {
  isRequired: false,
  marginBottom: 2,
  tooltipText: null,
};
