import {Box, Flex, Select, Text, Tooltip } from '@chakra-ui/react';
import { QuestionCircle } from '@/client/components/icons/ContinuIcons';
import PartnersService from '@/client/services/api/admin/PartnersService';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useAuthStore } from '../../../services/state/authStore';


type PartnerSelectionProps = {
  selectedPartner: string | undefined;
  setSelectedPartner: (partner: string) => void;
}

export default function PartnerSelection({selectedPartner, setSelectedPartner}: PartnerSelectionProps ) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const [{ pageIndex, pageSize }, setPagination] = useState<any>({
    pageIndex: 0,
    pageSize: undefined,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const { data, isLoading } = useQuery({
    queryKey: ['partners', fetchDataOptions, company._id],
    queryFn: () => PartnersService.getAllPartners(fetchDataOptions),
  });

  if (isLoading) {
    return null;
  }
  
  return (
    <Box pb={6}>
      <Text pb={4} fontSize="md" fontWeight="500">
      {t('registrationForms.admin.addPartner')}
        <Tooltip hasArrow label={t('registrationForms.tooltip.addPartner')}>
          <QuestionCircle color="#1A1A1A80" marginLeft={1} />
        </Tooltip>
      </Text>      
      <Select placeholder='Select Extend Account' width="40%" value={selectedPartner} onChange={(e) => setSelectedPartner(e.target.value)}>
        {!!data && !!data.rows && !!data.rows.length && data.rows.map(partner => <option key={`partner_${partner._id}`} value={partner._id}>{partner.name}</option>)}
      </Select>
    </Box>
  )
}