/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import {
  Country,
  RegionCodeRegisterPayload,
  Retailer,
  Store,
} from '@/client/types/preauth/Preauth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import PreAuthService from '@/client/services/api/PreAuthService';
import { deepSort } from '@/client/utils/deepSort';
import { useConfigStore } from '@/client/services/state/configStore';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface RegistrationDetailsFormProps {
  countries: Country[];
}

interface RegistrationDetailsInputs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  country: string;
  retailer: string;
  address: string;
}

// TODO: Add translation keys

export default function RegistrationDetailsForm({ countries }: RegistrationDetailsFormProps) {
  const { config } = useConfigStore();
  const domain = config.host;
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(undefined);
  const [selectedRetailer, setSelectedRetailer] = useState<Retailer | undefined>(undefined);
  const [selectedStore, setSelectedStore] = useState<Store | undefined>(undefined);

  const uppercase = (text: String) =>
    text
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    watch,
  } = useForm<RegistrationDetailsInputs>({ mode: 'onChange' });

  const countrySelected = watch('country');
  const retailerSelected = watch('retailer');
  const storeSelected = watch('address');

  useEffect(() => {
    if (countrySelected) {
      const country = countries.find((countryOption) => countryOption.country === countrySelected);
      setSelectedCountry(country);
      if (country?.country !== selectedCountry?.country) {
        setSelectedRetailer(undefined);
        setSelectedStore(undefined);
        return;
      }
    }

    if (retailerSelected && selectedCountry) {
      const retailer = selectedCountry?.retailers.find(
        (retailerOption) => retailerOption.retailer === retailerSelected,
      );
      setSelectedRetailer(retailer);
      if (retailer?.retailer !== selectedRetailer?.retailer) {
        setSelectedStore(undefined);
        return;
      }
    }

    if (storeSelected && selectedRetailer && selectedCountry) {
      const store = selectedRetailer?.stores.find(
        (storeOption) => storeOption.store_address === storeSelected,
      );
      setSelectedStore(store);
    }
  }, [countrySelected, retailerSelected, storeSelected]);

  interface RegisterWithRegionCodeVariables {
    payload: RegionCodeRegisterPayload;
  }

  const registerWithRegionCode = useMutation({
    mutationFn: async ({ payload }: RegisterWithRegionCodeVariables) =>
      PreAuthService.registerWithRegionCodeSecond(payload),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('user.registration.success'),
      });
      navigate('/login');
    },
    onError: (error) => {
      console.log('Error: ', error);
      setToast({
        show: true,
        status: 'error',
        title: t('user.registration.error'),
      });
    },
  });

  const onSubmit: SubmitHandler<RegistrationDetailsInputs> = async (data) => {
    const { country, retailer, firstName, lastName, email, password, address } = data;

    const payload = {
      domain,
      user: {
        country,
        retailer,
        region: selectedStore?.region || '',
        distributor: selectedStore?.distributor || '',
        store_address: address,
        store_name: selectedStore?.store_name || '',
        team: selectedStore?.team || '',
        language_code: selectedStore?.language_code || '',
        language: selectedStore?.language || '',
        first_name: firstName,
        last_name: lastName,
        email,
        password,
      },
    };

    registerWithRegionCode.mutate({ payload });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={8}>
        <Grid gap={4} templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}>
          <GridItem>
            <FormControl>
              <Input
                variant="flushed"
                placeholder={`${t('global.forms.labels_firstName')}`}
                {...register('firstName', { required: true })}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <Input
                variant="flushed"
                placeholder={`${t('global.forms.labels_lastName')}`}
                {...register('lastName', { required: true })}
              />
            </FormControl>
          </GridItem>
        </Grid>

        <FormControl isInvalid={!!errors.email}>
          <Input
            variant="flushed"
            type="email"
            placeholder={`${t('global.forms.labels_emailAddress')}`}
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />

          {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
        </FormControl>

        <FormControl>
          <Input
            variant="flushed"
            type="password"
            placeholder={`${t('global.forms.labels_passwordNew')}`}
            {...register('password', { required: true })}
          />
        </FormControl>

        <FormControl isInvalid={!!errors.confirmPassword}>
          <Input
            variant="flushed"
            type="password"
            placeholder={`${t('global.forms.labels_passwordConfirm')}`}
            {...register('confirmPassword', {
              required: true,
              validate: (value) => {
                if (value !== watch('password')) {
                  const str: string = t('preauth.acceptinvite.passwords_dont_match');
                  return str;
                }
              },
            })}
          />

          {errors.confirmPassword && (
            <FormErrorMessage>{errors.confirmPassword.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl>
          <Select
            placeholder={`${t('global.forms.labels_country')}`}
            {...register('country', { required: true })}
          >
            {countries.sort(deepSort('country')).map((country) => (
              <option value={country.country}>{uppercase(country.country)}</option>
            ))}
          </Select>
        </FormControl>

        {selectedCountry && countrySelected && (
          <FormControl>
            <Select
              placeholder={`${t('global.forms.labels_retailer')}`}
              {...register('retailer', { required: true })}
            >
              {selectedCountry.retailers.sort(deepSort('retailer')).map((retailer: Retailer) => (
                <option value={retailer.retailer}>{uppercase(retailer.retailer)}</option>
              ))}
            </Select>
          </FormControl>
        )}

        {selectedRetailer && selectedCountry && (
          <FormControl>
            <Select
              placeholder={`${t('global.forms.labels_address')}`}
              {...register('address', { required: true })}
            >
              {selectedRetailer.stores.sort(deepSort('store_name')).map((store: Store) => (
                <option value={store.store_address}>{uppercase(store.store_address)}</option>
              ))}
            </Select>
          </FormControl>
        )}

        <Button
          type="submit"
          isLoading={registerWithRegionCode.status === 'loading'}
          isDisabled={!isValid}
        >
          {t('global.forms.labels_submit')}
        </Button>
      </Stack>
    </form>
  );
}
