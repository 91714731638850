/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Divider, Stack, Text } from '@chakra-ui/react';
import type { TrackCourse, TrackSectionData } from '@/client/services/api/graphql/gql/graphql';
import { useEffect, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { BsPlusCircle } from 'react-icons/bs';
import ContentAuthoringBox from '@/client/components/admin/layout/ContentAuthoringBox';
import ContentSearchDrawer from '../overlay/ContentSearchDrawer';
import type { CourseFormData } from '@/client/routes/admin/create/content/tracks/EditCourse';
import CourseSection from './CourseSection';
import { autoScrollWindowForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { isCourseSectionData } from './course-section-data';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useTranslation } from 'react-i18next';
import HtmlRenderer from '@/client/components/html/HtmlRenderer';

export default function CourseAddContentForm() {
  const { t } = useTranslation();
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext<CourseFormData>();
  const { setCanAdvance, tabIndex, tabs, setTabs } = useCreateStore();
  const { fields, append, move } = useFieldArray({
    control,
    name: 'sections',
  });
  const ref = useRef<HTMLDivElement | null>(null);

  const title = watch('title');
  const description = watch('description');
  const sections = watch('sections');

  // TODO: Pull this tab enable/disable logic into hook
  useEffect(() => {
    if (!errors.sections) {
      setCanAdvance(true);

      const updatedTabs = tabs.map((tab, index) => {
        if (index === tabIndex) return tab;

        return {
          ...tab,
          isDisabled: false,
        };
      });

      setTabs(updatedTabs);

      return;
    }

    setCanAdvance(false);

    const updatedTabs = tabs.map((tab, index) => {
      if (index <= tabIndex) return tab;

      return {
        ...tab,
        isDisabled: true,
      };
    });

    setTabs(updatedTabs);
  }, [errors]);

  const validateSections = () => {
    if (sections && sections.length > 0) {
      if (sections.some((section) => section.courses && section.courses.length === 0)) {
        return false;
      }

      if (sections.some((section) => !section.name)) {
        return false;
      }

      return true;
    }

    return false;
  };

  useEffect(() => {
    if (validateSections()) {
      setCanAdvance(true);

      const updatedTabs = tabs.map((tab, index) => {
        if (index === tabIndex) return tab;

        return {
          ...tab,
          isDisabled: false,
        };
      });

      setTabs(updatedTabs);

      return;
    }

    setCanAdvance(false);

    const updatedTabs = tabs.map((tab, index) => {
      if (index === tabIndex) return tab;

      return {
        ...tab,
        isDisabled: true,
      };
    });

    setTabs(updatedTabs);
  }, [sections]);

  const newSection: TrackSectionData = {
    id: Math.random().toString(),
    name: '',
    description: '',
    position: fields.length + 1,
    courses: [],
  };

  // useEffect(() => {
  //   trigger('sections');
  // }, []);

  useEffect(() => {
    return monitorForElements({
      canMonitor({ source }) {
        return isCourseSectionData(source.data);
      },
      onDrop({ location, source }) {
        if (!sections) return;

        const target = location.current.dropTargets[0];
        if (!target) {
          return;
        }

        const sourceData = source.data;
        const targetData = target.data;

        if (!isCourseSectionData(sourceData) || !isCourseSectionData(targetData)) {
          return;
        }

        const indexOfSource = sections.findIndex((field: any) => field.name === sourceData.name);
        const indexOfTarget = sections.findIndex((field: any) => field.name === targetData.name);

        if (indexOfTarget < 0 || indexOfSource < 0) {
          return;
        }

        move(indexOfSource, indexOfTarget);
      },
    });
  }, [sections]);

  useEffect(() => {
    return autoScrollWindowForElements();
  });

  return (
    <ContentAuthoringBox>
      <Stack spacing={2}>
        <Text fontSize="24px" fontWeight={600}>
          {title}
        </Text>

        <Box className="description">
          <HtmlRenderer html={description || ''} />
        </Box>
      </Stack>

      <Divider marginY={2} borderColor="neutral.100" />

      {sections && (
        <Box ref={ref} overflowY="scroll">
          {sections.map((section, index: number) => {
            return (
              <Box key={section.id}>
                <CourseSection
                  section={{
                    index,
                    name: section.name,
                    _id: section.id,
                    courses: section.courses as TrackCourse[],
                  }}
                />

                <Divider marginY={8} borderColor="neutral.100" />
              </Box>
            );
          })}
        </Box>
      )}

      <Button
        marginTop={8}
        width="full"
        paddingY={6}
        paddingX={8}
        border="2px dashed"
        borderColor="neutral.300"
        borderRadius="6px"
        leftIcon={<BsPlusCircle />}
        color="neutral.300"
        fontSize="16px"
        fontWeight={600}
        backgroundColor="transparent"
        _hover={{ backgroundColor: 'neutral.0', color: 'neutral.500' }}
        onClick={() => append(newSection)}
      >
        {t('authoring.courses.newSection')}
      </Button>

      <ContentSearchDrawer />
    </ContentAuthoringBox>
  );
}
