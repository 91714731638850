/* eslint-disable react/jsx-props-no-spreading */
import { Controller, useFormContext } from 'react-hook-form';

import { Switch } from '@chakra-ui/react';

interface CreateSwitchProps {
  name: string;
}

export default function CreateSwitch({ name }: CreateSwitchProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <Switch {...field} variant="create" isChecked={field.value} />}
    />
  );
}
