/* eslint-disable react/jsx-props-no-spreading */

import * as z from 'zod';

import {
  Content,
  ContentTypes,
  ImportedContentExtras,
  PartnerPermissionTypes,
  TrackExtras,
} from '@/client/services/api/graphql/gql/graphql';
import { FormProvider, useForm } from 'react-hook-form';

import { Box } from '@chakra-ui/react';
import type { ContentFormValues } from '@/client/types/admin/content-authoring/ContentFormValues';
import EditCourseForm from './EditCourseForm';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';

export type CourseFormData = Content & ImportedContentExtras & TrackExtras & ContentFormValues;

const learningTrackFormSchema = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  instructors: z
    .array(z.object({ name: z.string().min(1) }))
    .min(1, { message: 'At least 1 instructor is required' }),
  sections: z
    .array(
      z.object({
        name: z.string().min(1),
        courses: z.array(z.any()).min(1),
      }),
    )
    .min(1),
});

const importedContentFormSchema = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  instructors: z
    .array(z.object({ name: z.string().min(1) }))
    .min(1, { message: 'At least 1 instructor is required' }),
});

const courseFormDefaultValues: CourseFormData = {
  id: '',
  draft: true,
  archived: false,
  company: '',
  contentType: ContentTypes.Track,
  exploreHide: true,
  creator: '',
  lockScorm: false,
  resource: false,
  sequential: false,
  tags: [],
  instructors: [],
  learnItems: [],
  allowComments: false,
  duration: 0,
  privacyCollaborators: [],
  sections: [
    {
      id: Math.random().toString(),
      name: '',
      description: '',
      position: 1,
      courses: [],
    },
  ],
  vendor: '',
  vendorLink: '',
  vendorFullLink: '',
  vendorContentId: '',
  title: '',
  description: '',
  image: '',
  bannerImage: '',
  lilStructure: [],
  linkedCategory: [],
  ratingConfiguration: {
    allowRating: false,
    allowFeedback: false,
    notifyFeedbackOnly: false,
    allowEdits: false,
    showUsersRating: false,
    totalValue: 5,
    messaging: {
      individuals: [],
      slackChannels: [],
    },
  },
  published: false,
  privacyLocations: [],
  privacyDepartments: [],
  privacyTeams: [],
  privacyOrgLevels: [],
  privacyGrades: [],
  privacyGroups: [],
  enable_segmentation: false,
  private: false,
  partnerPermissions: PartnerPermissionTypes.Tenant,
  enable_collaborators: false,
  enable_surveys: false,
  surveys: [],
};

export default function EditCourse() {
  const { t } = useTranslation();
  const { contentType } = useCreateStore();

  const courseFormSchema =
    contentType === 'track' ? learningTrackFormSchema : importedContentFormSchema;

  useDocumentTitle(t('authoring.courses.editCourse'));

  const methods = useForm({
    resolver: zodResolver(courseFormSchema),
    defaultValues: courseFormDefaultValues,
    mode: 'onChange',
  });

  return (
    <Box minHeight="100vh" maxWidth="915px" margin="0 auto">
      <FormProvider {...methods}>
        <EditCourseForm />
      </FormProvider>
    </Box>
  );
}
