import { WorkshopInstance } from '@/client/types/content/Workshop';
import WorkshopService from '@/client/services/api/content/workshops/WorkshopService';
import { useQuery } from '@tanstack/react-query';

export const useGetAvailableSeats = (workshopId: string | undefined, workshopInstance: WorkshopInstance | undefined) =>
  useQuery({
    enabled: !!workshopId && !!workshopInstance,
    queryKey: ['available-seats', workshopId, workshopInstance],
    queryFn: async () => await WorkshopService.getAvailableInstanceSeats(workshopId, workshopInstance),
  });
