import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import {
  ArrowLeft,
  ArrowRight,
  Close,
  Expand,
  ZoomIn,
  ZoomOut,
} from '@/client/components/icons/ContinuIcons';
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  HStack,
  IconButton,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useEffect, useState } from 'react';

import type { ReactElement } from 'react';
import { useComponentSize } from 'react-use-size';

interface PdfControlButtonProps {
  onClick: () => void;
  icon: ReactElement;
  ariaLabel: string;
  isDisabled?: boolean;
}

function PdfControlButton({ onClick, icon, ariaLabel, isDisabled }: PdfControlButtonProps) {
  return (
    <IconButton
      isDisabled={isDisabled}
      size="sm"
      aria-label={ariaLabel}
      icon={icon}
      onClick={onClick}
      backgroundColor="white"
      color="brand.button"
      borderRadius="full"
      border="2px solid"
      borderColor="neutral.100"
      _hover={{
        borderColor: 'brand.button',
      }}
    />
  );
}

PdfControlButton.defaultProps = {
  isDisabled: false,
};

type PdfViewProps = {
  source: string;
  recordPage: (page: number) => void;
  recoveredPage: number | undefined | null;
  enableRecommendations?: boolean;
  setRenderRecommendations?: (render: boolean) => void;
  renderFullscreenButton?: boolean;
};

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function PdfView({
  source,
  recordPage,
  recoveredPage,
  enableRecommendations,
  setRenderRecommendations,
  renderFullscreenButton,
}: PdfViewProps) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { ref, width } = useComponentSize();

  useEffect(() => {
    recordPage(pageNumber);

    if (enableRecommendations && setRenderRecommendations && pageNumber === numPages) {
      setRenderRecommendations(true);
    }
  }, [pageNumber]);

  function onDocumentLoadSuccess({ numPages: numberOfPages }: { numPages: number }) {
    setNumPages(numberOfPages);

    setPageNumber(recoveredPage || 1);
  }

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  const previousPage = () => changePage(-1);

  const nextPage = () => changePage(1);

  const zoomIn = () => setScale((prevScale) => prevScale + 0.1);

  const zoomOut = () => setScale((prevScale) => prevScale - 0.1);

  const onItemClick = ({ pageNumber: itemPageNumber }: any) => setPageNumber(itemPageNumber);

  const handleFullScreen = () => setIsFullScreen(!isFullScreen);

  return (
    <Box
      ref={ref}
      borderRadius="md"
      margin="0 auto"
      height="full"
      style={
        isFullScreen
          ? {
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: 9999,
              overflow: 'auto',
            }
          : {
              position: 'relative',
              maxWidth: 850,
            }
      }
    >
      <Flex backgroundColor="white" marginBottom={2} justifyContent="space-between">
        <HStack>
          {renderFullscreenButton && (
            <Box marginRight={3}>
              <PdfControlButton
                onClick={handleFullScreen}
                icon={isFullScreen ? <Close /> : <Expand />}
                ariaLabel="Fullscreen"
              />
            </Box>
          )}

          <PdfControlButton ariaLabel="Zoom In" icon={<ZoomIn />} onClick={zoomIn} />

          <PdfControlButton ariaLabel="Zoom Out" icon={<ZoomOut />} onClick={zoomOut} />
        </HStack>

        <HStack>
          <PdfControlButton
            ariaLabel="Previous Page"
            icon={<ArrowLeft />}
            isDisabled={pageNumber === 1}
            onClick={() => previousPage()}
          />

          <Text fontSize="sm">
            {pageNumber} of {numPages}
          </Text>

          <PdfControlButton
            ariaLabel="Next Page"
            icon={<ArrowRight />}
            isDisabled={pageNumber === numPages}
            onClick={() => nextPage()}
          />
        </HStack>
      </Flex>

      <Box boxShadow="0px 4px 12px 0px rgba(0, 0, 0, 0.09)">
        <Document
          file={source}
          onLoadSuccess={(data) => onDocumentLoadSuccess(data)}
          onItemClick={onItemClick}
          loading={
            <Center marginY={8}>
              <Spinner />
            </Center>
          }
        >
          <Page
            pageNumber={pageNumber || 1}
            width={width}
            scale={scale}
            loading={
              <Center marginY={8}>
                <Spinner />
              </Center>
            }
          />
        </Document>
      </Box>
    </Box>
  );
}

PdfView.defaultProps = {
  enableRecommendations: false,
  setRenderRecommendations: null,
  renderFullscreenButton: true,
};
