import {
  type Product,
  type ArticleExtras,
  type Content,
  type FileExtras,
  type ScormExtras,
  type VideoExtras,
  ProductSubscriptionDuration,
} from '@/client/services/api/graphql/gql/graphql';
import {
  AuthoringGrayFile,
  AuthoringGrayScorm,
  ViewOutline,
  ViewSlash,
} from '@/client/components/icons/ContinuIcons';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
  Spinner,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';

import AuthoringBannerUpload from '@/client/components/admin/create/content/images/AuthoringBannerUpload';
import AuthoringThumbnailUpload from '@/client/components/admin/create/content/images/AuthoringThumbnailUpload';
import ContentLength from '@/client/components/data-display/content/ContentLength';
import type { DefaultTFuncReturn } from 'i18next';
import HtmlRenderer from '@/client/components/html/HtmlRenderer';
import PdfView from '../../../files/PdfView';
import VideoPlayer from '@/client/components/media/video/VideoPlayer';
import { useComponentSize } from 'react-use-size';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFileStore } from '@/client/services/state/routeState/fileStore';
import { useFormContext } from 'react-hook-form';
import { useTrackStateStore } from '@/client/services/state/admin/create/trackStateStore';
import { useTranslation } from 'react-i18next';
import Scorm from '@/client/routes/content/Scorm';

interface AuthoringLivePreviewProps {
  titlePlaceholder?: string | DefaultTFuncReturn;
  contentType: 'article' | 'video' | 'file' | 'scorm' | 'product';
}

export default function AuthoringLivePreview({
  titlePlaceholder = 'Title of Content',
  contentType,
}: AuthoringLivePreviewProps) {
  const { t } = useTranslation();
  const { previewFullscreen } = useCreateStore();
  const [showThumbnailCard, setShowThumbnailCard] = useState<boolean>(true);
  const [showThumbnailButton, setShowThumbnailButton] = useState<boolean>(false);
  const [renderScormPreview, setRenderScormPreview] = useState<boolean>(false);
  const [scormPreviewLoading, setScormPreviewLoading] = useState<boolean>(false);
  const { watch } = useFormContext<
    Content & ArticleExtras & VideoExtras & FileExtras & ScormExtras & Product
  >();

  const { trackState } = useTrackStateStore();

  const { ref: previewRef, width } = useComponentSize();

  const heroHeight = /* contentType === 'scorm' ? 250 : */ useMemo(
    () => (width ? width / 3.5 : 0),
    [width],
  );

  const renderThumbnailUpload =
    showThumbnailCard &&
    (contentType === 'article' ||
      (contentType === 'scorm' && !trackState) ||
      (contentType === 'video' && !trackState) ||
      (contentType === 'file' && !trackState));

  const id = watch('id');
  const title = watch('title');
  const author = watch('author');
  const duration = watch('duration') || 0;
  const description = watch('description') || '';
  const mediaLink = watch('link');
  const contentId = watch('id');
  const sourceV2 = watch('sourceV2') || '';
  const isEmbed = watch('embed');
  const isTranscoding = watch('transcoding');
  const videoSource = watch('source') || '';
  const bannerImage = watch('bannerImage');

  // ! Hacky workaround to wait for scorm package to be available in S3
  useEffect(() => {
    if (contentType === 'scorm' && id) {
      setScormPreviewLoading(true);

      setTimeout(() => {
        setScormPreviewLoading(false);
        setRenderScormPreview(true);
      }, 5000);
    }
  }, [contentType, id]);

  // products
  const price = watch('price');
  const subscriptionDuration = watch('subscriptionDuration');

  const pagePositions = useFileStore((state: any) => state.pagePositions);
  const setPagePositions = useFileStore((state: any) => state.setPagePositions);

  const contentIsPdf = contentType === 'file' && mediaLink?.indexOf('.pdf') !== -1;

  const contentIsBoxLink =
    (contentType === 'file' && sourceV2 !== '' && sourceV2?.includes('box.com')) ||
    (mediaLink !== '' && mediaLink?.includes('box.com'));

  const renderVideoPlaceholder = (mediaLink && isTranscoding) || !mediaLink;

  return (
    <Box
      ref={previewRef}
      height="full"
      backgroundColor="white"
      borderRadius="xl"
      position="relative"
      overflowY="scroll"
      boxShadow="0px 11.15px 18.583px 0px rgba(20, 27, 45, 0.10)"
      paddingTop={12}
    >
      {contentType === 'scorm' && (
        <VStack marginTop={12} marginBottom={4}>
          <Text
            textAlign="center"
            fontSize="18px"
            fontWeight={600}
            color={title ? 'baseBlack' : 'neutral.400'}
          >
            {title || titlePlaceholder}
          </Text>

          <ContentLength
            duration={duration}
            size="10px"
            color="neutral.500"
            iconBoxSize={3}
            fontWeight={500}
          />
        </VStack>
      )}

      {contentType !== 'scorm' && contentType !== 'product' && (
        <Container maxWidth="container.md">
          <Stack spacing={0}>
            <Text
              fontSize="9px"
              fontWeight={600}
              color={author ? 'baseBlack' : 'neutral.600'}
              marginBottom={2.5}
            >
              {author || t('authoring.livePreview.authorPlaceholder')}
            </Text>

            <Box marginBottom={3.5}>
              <ContentLength
                duration={duration}
                size="9px"
                color="neutral.600"
                iconBoxSize={3}
                fontWeight={500}
              />
            </Box>

            <Text
              fontSize="19px"
              fontWeight={500}
              color={title ? 'baseBlack' : 'neutral.600'}
              marginBottom={8}
            >
              {title || titlePlaceholder}
            </Text>
          </Stack>
        </Container>
      )}

      {contentType !== 'file' && contentType !== 'video' && contentType !== 'scorm' && (
        <Box paddingX={bannerImage || previewFullscreen ? 0 : 4}>
          <AuthoringBannerUpload
            contentType={contentType}
            // heroHeight={contentType === 'scorm' && bannerImage ? 250 : heroHeight}
            heroHeight={heroHeight}
          />
        </Box>
      )}

      {contentType === 'scorm' && !renderScormPreview && (
        <Center
          height={500}
          border="2px dashed"
          borderColor="neutral.300"
          marginX={5}
          borderRadius="md"
        >
          <VStack>
            <AuthoringGrayScorm boxSize={12} />

            <Text fontSize="14px" color="neutral.600" fontWeight={400}>
              {t('authoring.livePreview.scormPreview')}
            </Text>

            {scormPreviewLoading && <Spinner color="neutral.600" />}
          </VStack>
        </Center>
      )}

      {renderScormPreview && (
        <Box paddingX="16px" paddingY="48px">
          <Scorm contentId={id} isPreview />
        </Box>
      )}

      {contentType === 'file' && !mediaLink && (
        <Center
          height={500}
          border="2px dashed"
          borderColor="neutral.300"
          marginX={5}
          borderRadius="md"
        >
          <VStack>
            <AuthoringGrayFile boxSize={12} />

            <Text fontSize="14px" color="neutral.600" fontWeight={400}>
              {t('authoring.livePreview.filePreview')}
            </Text>
          </VStack>
        </Center>
      )}

      {contentType === 'product' && (
        <Container maxWidth="container.md" marginTop={8}>
          <Stack paddingLeft={8} spacing={0}>
            <Text
              fontSize="xl"
              fontWeight={800}
              color={title ? 'baseBlack' : 'neutral.600'}
              marginBottom={8}
            >
              {title || titlePlaceholder}
            </Text>

            <Box marginRight="auto">
              <HStack
                border="1px"
                borderColor="neutral.200"
                borderRadius="8px"
                backgroundColor="neutral.50"
                padding={2}
              >
                <Text fontWeight={500}>{`$${price}`}</Text>
                <Text>{` / ${t('products.subscriptionDurationPreview.billed')} ${
                  subscriptionDuration === ProductSubscriptionDuration.Monthly
                    ? t('products.subscriptionDurationOptions.monthly')
                    : t('products.subscriptionDurationOptions.yearly')
                }`}</Text>
              </HStack>
            </Box>
          </Stack>
        </Container>
      )}

      {contentType === 'file' && mediaLink && (
        <Container maxWidth="containter.lg">
          <Box paddingX={12}>
            {contentIsPdf && !isEmbed && (
              <PdfView
                renderFullscreenButton={false}
                source={mediaLink}
                recordPage={(page: number) => setPagePositions(contentId, page, pagePositions)}
                recoveredPage={0}
              />
            )}

            {contentIsBoxLink && !isEmbed && (
              <Box background="white" width="100%" zIndex="3">
                <iframe
                  title="box-viewer"
                  style={{
                    width: '100%',
                    maxWidth: '900px',
                    margin: '0 auto',
                    zIndex: '2',
                    minHeight: '500px',
                  }}
                  // eslint-disable-next-line
                  view-file-only="true"
                  src={sourceV2}
                  seamless
                  allowFullScreen
                />
              </Box>
            )}

            {isEmbed && (
              <Box background="white" height="100%">
                <iframe
                  title="embded-viewer"
                  style={{
                    height: '100vh',
                    width: '100%',
                    zIndex: '2',
                    position: 'relative',
                    minHeight: '500px',
                  }}
                  // eslint-disable-next-line react/no-unknown-property
                  view-file-only="true"
                  src={mediaLink}
                  seamless
                  allowFullScreen
                />
              </Box>
            )}
          </Box>
        </Container>
      )}

      {contentType === 'video' && (
        <>
          {renderVideoPlaceholder && (
            <Container maxWidth="container.md">
              <Flex justifyContent="center" paddingX={12}>
                <Image src="/public/images/video-placeholder-2.png" />
              </Flex>
            </Container>
          )}

          {mediaLink && !isTranscoding && (
            <Container maxWidth="container.md">
              <Box width="100%" marginBottom={8}>
                <Box maxWidth="container.sm" margin="0 auto">
                  <VideoPlayer
                    url={mediaLink}
                    videoSource={videoSource}
                    setRenderRecommendations={() => {}}
                  />
                </Box>
              </Box>
            </Container>
          )}
        </>
      )}

      <Box margin="0 auto" marginTop="30.5px" maxWidth={previewFullscreen ? '700px' : '80%'}>
        <HtmlRenderer html={description} />
      </Box>

      {renderThumbnailUpload && (
        <AuthoringThumbnailUpload
          containerWidth={width}
          placeholder={titlePlaceholder}
          contentType={contentType}
          setRenderThumbnailButton={setShowThumbnailButton}
        />
      )}

      {(showThumbnailButton || !renderThumbnailUpload) && contentType !== 'product' && (
        <Button
          variant="createCancel"
          leftIcon={showThumbnailCard ? <ViewSlash /> : <ViewOutline />}
          size="sm"
          fontSize="12px"
          borderRadius="full"
          paddingX={3}
          paddingY={2}
          position="fixed"
          bottom="20px"
          left={previewFullscreen ? width - 75 : renderThumbnailUpload ? width - 40 : width - 45}
          zIndex={100}
          onClick={() => {
            setShowThumbnailCard(!showThumbnailCard);
          }}
          onMouseEnter={() => setShowThumbnailButton(true)}
          onMouseLeave={() => setShowThumbnailButton(false)}
        >
          {showThumbnailCard
            ? t('authoring.livePreview.hideCard')
            : t('authoring.livePreview.showCard')}
        </Button>
      )}
    </Box>
  );
}
