import { Fade, Stack, Text } from '@chakra-ui/react';

import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';
import TinyMceEditor from '@/client/components/admin/create/shared/TinyMceEditor';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function ContentGeneralDetailsForm() {
  const { t } = useTranslation();
  const { contentType, previewFullscreen } = useCreateStore();
  const { setValue, watch } = useFormContext();

  const contentTypeMap: { [key: string]: string } = {
    article: 'Article',
    video: 'Video',
    file: 'File',
    scorm: 'Scorm',
  };

  const description = watch('description');

  return (
    <Fade in delay={0.1}>
      <Stack spacing={6}>
        <Stack spacing={2}>
          <Text variant="createLabel">{contentTypeMap[contentType]} Title</Text>

          <CreateInput
            name="title"
            placeholder={t('authoring.generalDetails.titlePlaceholder')}
            variant="createTitle"
            isRequired
          />
        </Stack>

        <Stack spacing={2}>
          <Text variant="createLabel">{t('authoring.authorName')}</Text>

          <CreateInput
            name="author"
            placeholder={t('authoring.generalDetails.authorPlaceholder')}
            variant="create"
            isRequired
          />
        </Stack>

        {!previewFullscreen && (
          <TinyMceEditor
            isRequired
            formName="description"
            placeholder={`${t('authoring.tinymce.addContentPlaceholder1')} ${contentTypeMap[
              contentType
            ].toLowerCase()} ${t('authoring.tinymce.addContentPlaceholder2')}`}
            label={`${contentTypeMap[contentType]} Content`}
          />
        )}
      </Stack>
    </Fade>
  );
}
