import 'froala-editor/css/froala_style.min.css';

import {ArrowRight, ArrowUp} from "@/client/components/icons/ContinuIcons";
import {Box, Button, Collapse, useDisclosure} from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import HtmlRenderer from './HtmlRenderer';
import { useTranslation } from 'react-i18next';

export default function CollapsableHtmlRenderer({
    html,
    width,
    comment,
}: {
    html: string;
    width?: string;
    comment?: boolean;
}) {
    const { isOpen, onToggle } = useDisclosure();
    const { t } = useTranslation();
    const [showCollapseButton, setShowCollapseButton] = useState<boolean>(false);
    const elementRef = useCallback((node: HTMLElement | null)  => {
        if (!node) return;
        const resizeObserver = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                const { contentRect } = entry;
                const currentHeight = contentRect.height;
                if (currentHeight > 110) {
                    setShowCollapseButton(true);
                }
            })
        });
        resizeObserver.observe(node);
    }, []);

    return (
        <>
        <Collapse in={isOpen} startingHeight={110} animateOpacity>
            <Box className="description" ref={elementRef}>
                <HtmlRenderer html={html} width={width} comment={comment}/>
            </Box>
        </Collapse>
        {showCollapseButton && (
            <Button
                size="xs"
                leftIcon={isOpen ? <ArrowUp /> : <ArrowRight />}
                variant="ghost"
                marginTop={6}
                onClick={onToggle}
            >
                {isOpen
                    ? t('global.actions.hideDetails')
                    : t('global.actions.showMoreDetails')}
            </Button>
        )}
        </>
);
}

CollapsableHtmlRenderer.defaultProps = {
    width: null,
    comment: false,
};