import { HStack, Stack, Text } from '@chakra-ui/react';

import CreateFormGroup from '@/client/components/admin/create/shared/layout/CreateFormGroup';
import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';
import CreateSwitchContainer from '@/client/components/admin/create/shared/layout/CreateSwitchContainer';
import type { ScormFormData } from '@/client/routes/admin/create/content/EditScorm';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function ScormPlayerConfigurationForm() {
  const { t } = useTranslation();
  const { watch } = useFormContext<ScormFormData>();

  const openScoInNewWindow = watch('sslaConfig.openScoInNewWindow');
  const tryFullscreen = watch('sslaConfig.scoWindowTryFullscreen');

  return (
    <CreateFormGroup>
      <Stack spacing={6}>
        <CreateSwitchContainer
          name="sslaConfig.openScoInNewWindow"
          label={t('authoring.scorm.player.newWindow')}
          helpText={t('authoring.scorm.player.newWindow.helpText')}
        />

        {openScoInNewWindow && (
          <>
            <CreateSwitchContainer
              name="sslaConfig.scoWindowTryFullscreen"
              label={t('authoring.scorm.player.wholeScreen')}
            />

            {!tryFullscreen && (
              <HStack>
                <Stack>
                  <Text variant="createHelpText">
                    {t('authoring.scorm.player.dimensions.width')}
                  </Text>

                  <CreateInput
                    type="number"
                    name="sslaConfig.scoWindowWidth"
                    placeholder={t('authoring.scorm.player.dimensions.width.placeholder')}
                    variant="create"
                  />
                </Stack>

                <Stack>
                  <Text variant="createHelpText">
                    {t('authoring.scorm.player.dimensions.height')}
                  </Text>

                  <CreateInput
                    type="number"
                    name="sslaConfig.scoWindowHeight"
                    placeholder={t('authoring.scorm.player.dimensions.height.placeholder')}
                    variant="create"
                  />
                </Stack>
              </HStack>
            )}
          </>
        )}
      </Stack>
    </CreateFormGroup>
  );
}
