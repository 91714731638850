import { ApiIntegration, ApiIntegrationsListTypes } from '@/client/types/Integrations';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { ReactElement, useEffect, useMemo, useState } from 'react';

import ApiIntegrationsCreate from './ApiIntegrationsCreate';
import DeleteItemModal from '@/client/components/admin/overlay/DeleteItemModal';
import EmptyTable from '@/client/components/data-display/emptyTable/EmptyTable';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import TableActionsMenu from '@/client/components/admin/menus/TableActionsMenu';
import { useAdminStore } from '@/client/services/state/admin/adminStore';
import { useTranslation } from 'react-i18next';

export default function ApiIntegrationsList({
  editing,
  setEditing,
  getApiIntegrations,
  createApiIntegration,
  searchTerm,
  removeMutation,
}: ApiIntegrationsListTypes) {
  const [selectedItem, setSelectedItem] = useState<any>();
  const { t } = useTranslation();
  const [rowSelection, setRowSelection] = useState<any>();
  const { setDeleteModal } = useAdminStore();

  const columnHelper = createColumnHelper<ApiIntegration>();
  const columns = useMemo<ColumnDefBase<ApiIntegration, any>[]>(
    () => [
      columnHelper.accessor('application_name', {
        cell: (info) =>
          (
            <Text
              color="brand.link"
              cursor="pointer"
              onClick={() => {
                setSelectedItem(info.row.original);
              }}
            >
              {info.getValue()}
            </Text>
          ) as ReactElement,
        header: () => t('apiIntegrations.appName'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('application_info', {
        cell: (info) => info.getValue()[0].value,
        header: () => t('apiIntegrations.appDescription'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('client_id', {
        cell: (info) => info.getValue(),
        header: () => t('apiIntegrations.clientId'),
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) =>
          (
            <TableActionsMenu
              onDelete={() => {
                setDeleteModal({ isOpen: true, selectedItem: info.row.original._id });
              }}
            />
          ) as any,
      }),
    ],
    [],
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const dataQuery = getApiIntegrations(fetchDataOptions);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const rowClickHandler = (item: {
    _id: string;
    application_name: string;
    client_id: string;
    application_info: [
      {
        name: string;
        value: string;
        _id: string;
      },
    ];
  }) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    if (dataQuery.data?.rows.length > 0) {
      setSelectedItem(dataQuery.data?.rows[0]);
      setEditing('');
    } else {
      setEditing('api');
    }
  }, [dataQuery.data?.rows]);

  return (
    <>
      <Flex marginX={4} marginY={2} flex="1">
        <Box
          background="white"
          backgroundColor="white"
          padding={5}
          borderRadius="xl"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          marginRight={2}
          flex={1}
        >
          {!dataQuery.data?.rows.length && (
            <EmptyTable
              label={
                searchTerm && searchTerm.length > 0
                  ? t('apiIntegrations.noResults')
                  : t('apiIntegrations.noApiIntegrations')
              }
            />
          )}

          {!!dataQuery.data?.rows.length && (
            <Box borderRadius={8} overflow="hidden">
              {dataQuery && (
                <ManuallyPaginatedTable
                  columns={columns}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  queryData={dataQuery.data}
                  pagination={pagination}
                  setPagination={setPagination}
                  showCheckbox={false}
                  rowClick={rowClickHandler}
                />
              )}
            </Box>
          )}
        </Box>

        <Box
          width="30%"
          maxWidth="500px"
          background="white"
          height="full"
          padding={4}
          borderRadius="xl"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        >
          <ApiIntegrationsCreate
            createApiIntegration={createApiIntegration}
            setEditing={setEditing}
            editing={editing}
            viewData={selectedItem}
          />
        </Box>
      </Flex>

      <DeleteItemModal deleteItem={removeMutation} />
    </>
  );
}
