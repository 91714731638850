import { ArrowLeft, Save } from '../../../icons/ContinuIcons';
import { Button, ButtonGroup, Flex, Grid, GridItem, Tooltip } from '@chakra-ui/react';

import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import ControlledSelect from '@/client/components/admin/forms/ControlledSelect';
import HeadingWithTooltip from '../HeadingWithTooltip';
import InputWithToolTip from '../InputWithTooltip';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type UserInfoProps = {
  goBackAction: () => void;
  saveAction: () => void;
  isLoading: boolean;
};

type GridInputData = {
  name: string;
  label: string;
  placeholder: string;
  helpText: string;
  isRequired: boolean;
};

export default function BasicUserInfo({ goBackAction, saveAction, isLoading }: UserInfoProps) {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const { id } = useParams<{ id: string }>();

  const inputs: GridInputData[] = [
    {
      name: 'first_name',
      label: t('edit.useradd.first_name'),
      placeholder: t('edit.useradd.first_name'),
      helpText: 'This is where you put your first name',
      isRequired: true,
    },
    {
      name: 'last_name',
      label: t('edit.useradd.last_name'),
      placeholder: t('edit.useradd.last_name'),
      helpText: 'This is where you put your last name',
      isRequired: true,
    },
    {
      name: 'job_title',
      label: t('edit.useradd.job_title'),
      placeholder: t('edit.useradd.job_title'),
      helpText: 'This is where you put your job title',
      isRequired: false,
    },
    {
      name: 'email',
      label: t('edit.useradd.email'),
      placeholder: t('edit.useradd.email'),
      helpText: 'This is where you put your email',
      isRequired: true,
    },
    {
      name: 'userid',
      label: t('edit.useradd.employeeid'),
      placeholder: t('edit.useradd.employeeid'),
      helpText: "This is where you put your employee's id",
      isRequired: false,
    },
  ];

  return (
    <AdminElevatedBox>
      <Flex direction="row" justifyContent="flex-end">
        <ButtonGroup>
          <Button variant="adminPrimary" size="xs" onClick={goBackAction} leftIcon={<ArrowLeft />}>
            {t('global.actions.back')}
          </Button>

          <Tooltip label="Some required fields are missing" isDisabled={formState.isValid}>
            <Button
              type="submit"
              variant="adminPrimary"
              size="xs"
              backgroundColor="green"
              isDisabled={!formState.isValid}
              onClick={saveAction}
              isLoading={isLoading}
              _hover={{ backgroundColor: '' }}
              leftIcon={<Save />}
            >
              {t('global.actions.save')}
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Flex>

      <HeadingWithTooltip
        title={t('userEditor.basic_information')}
        helpText={t('registrationForms.tooltip.segmentation')}
        paddingTop="4"
      />

      <Grid
        h="170px"
        templateRows="repeat(2, 32%)"
        templateColumns="repeat(3, 1fr)"
        gap={4}
        rowGap={8}
        marginTop={4}
        marginLeft={2}
      >
        {inputs.map((input: GridInputData) => (
          <GridItem key={input.name} colSpan={1} rowSpan={1}>
            <InputWithToolTip
              name={input.name}
              label={input.label}
              placeholder={input.placeholder}
              tooltipText={input.helpText}
              isRequired={input.isRequired}
            />
          </GridItem>
        ))}

        <GridItem colSpan={1} rowSpan={1}>
          {id && (
            <GridItem colSpan={1} rowSpan={1}>
              <InputWithToolTip
                isDisabled
                name="provisioning_status"
                label={t('userEditor.provisioning_status')}
              />
            </GridItem>
          )}

          {!id && (
            <ControlledSelect
              name="provisioning_status"
              label={t('userEditor.provisioning_status')}
              options={[
                { value: 'inviting', label: t('userEditor.inviting') },
                { value: 'inviting_muted', label: t('userEditor.invitingMuted') },
                { value: 'provisioning', label: t('userEditor.provisioning') },
                { value: 'provisioning_muted', label: t('userEditor.provisioningMuted') },
              ]}
            />
          )}
        </GridItem>
      </Grid>
    </AdminElevatedBox>
  );
}
