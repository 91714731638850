import { Box, Circle, Flex, Grid, GridItem, HStack, Text } from '@chakra-ui/react';

import { CloseIcon } from '@chakra-ui/icons';
import TextareaWithTooltip from '@/client/components/admin/forms/TextareaWithTooltip';
import { useFormContext } from 'react-hook-form';
import { useNotificationEditor } from '@/client/services/hooks/admin/notifications/useNotificationEditor';
import { useTranslation } from 'react-i18next';

export default function NotificationInAppForm() {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { insertOptions, parseTemplate } = useNotificationEditor();

  const body = watch('in_app_body');
  const preview = body;

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={4} paddingTop={4}>
      <GridItem>
        <TextareaWithTooltip
          name="in_app_body"
          placeholder={t('assignments.notifications.edit.inApp.placeholder')}
          insertOptions={insertOptions}
        />
      </GridItem>

      <GridItem>
        <Box backgroundColor="brand.mist" padding={2}>
          <Flex justifyContent="flex-end">
            <CloseIcon boxSize={3} />
          </Flex>

          <HStack spacing={4}>
            <Circle size="40px" backgroundColor="brand.gray.600" />

            <Text whiteSpace="pre-wrap">{parseTemplate(preview, 'in-app')}</Text>
          </HStack>
        </Box>
      </GridItem>
    </Grid>
  );
}
