import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

export default function ChildReactLink({ child }: { child: any }) {
  return (
    <Link
      as={RouterLink}
      to={child.route}
      key={child.name}
      fontSize="xs"
      paddingLeft={3}
      color="rgba(26, 26, 26, 0.8)"
      fontWeight="medium"
      _hover={{
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
    >
      {child.name}
    </Link>
  );
}
