import { Button, Flex, Tabs, TabList, Tab } from '@chakra-ui/react';
import { PlusCircle } from '../icons/ContinuIcons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AdminSecondaryHeader from '../admin/layout/AdminSecondaryHeader';
import AdminPrimaryHeader from '../admin/layout/AdminPrimaryHeader';

type HeaderProps = {
  currentTab: string;
  addUser?: () => void;
  addAssignment?: () => void;
  setSearchTerm?: (value: string) => void;
};

type TabData = {
  name: string;
  displayName: string;
  link: string;
};

export default function PartnersManagerHeader({
  currentTab,
  addUser,
  addAssignment,
  setSearchTerm,
}: HeaderProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabData = [
    {
      name: 'overview',
      displayName: 'Overview',
      link: '/extend/manager',
    },
    {
      name: 'content',
      displayName: 'Content',
      link: '/extend/manager/content',
    },
    {
      name: 'users',
      displayName: 'Users',
      link: '/extend/manager/users',
    },
    {
      name: 'assignments',
      displayName: 'Assignments',
      link: '/extend/manager/assignments',
    },
  ];
  return (
    <>
      <AdminPrimaryHeader
        title={`${t('overview.label.partners')}`}
        showSearch={!!setSearchTerm}
        setSearchTerm={setSearchTerm}
        tooltipText="tooltip"
      />
      <AdminSecondaryHeader>
        <Flex direction="row" justifyContent="space-between" width="100%">
          <Tabs
            variant="soft-rounded"
            size="sm"
            color="brand.primary"
            index={tabData.indexOf(
              tabData.find((tab: TabData) => tab.name === currentTab) || tabData[0],
            )}
          >
            <TabList>
              {tabData.map((tab: TabData) => (
                <Tab fontWeight="500" key={tab.name} onClick={() => navigate(tab.link)}>
                  {tab.displayName}
                </Tab>
              ))}
            </TabList>
          </Tabs>
          <Flex direction="row" justifyContent="end" alignItems="center" gap={2}>
            {addUser && (
              <Button variant="adminPrimary" size="xs" leftIcon={<PlusCircle />} onClick={addUser}>
                {t('partners.manager.addUser')}
              </Button>
            )}
            {addAssignment && (
              <Button
                variant="adminPrimary"
                size="xs"
                leftIcon={<PlusCircle />}
                onClick={addAssignment}
              >
                {t('partners.manager.createNewAssignment')}
              </Button>
            )}
          </Flex>
        </Flex>
      </AdminSecondaryHeader>
    </>
  );
}

PartnersManagerHeader.defaultProps = {
  addUser: null,
  addAssignment: null,
  setSearchTerm: null,
};
