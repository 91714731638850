import { Cell, Header } from "@tanstack/react-table";
import { CSSProperties } from "react";

export const ellipsisHeaderStyles = <TData, TValue>(header: Header<TData, TValue>, useTableLayoutFixed: boolean): CSSProperties => {
  const cssProps: CSSProperties = {};
  if (useTableLayoutFixed) {
    cssProps.width = header.getSize() === 150 ? 'auto' : header.getSize();
    cssProps.maxWidth = cssProps.width;
    // @ts-ignore
    if (header.column.columnDef.meta?.ellipsis) {
      cssProps.overflow = 'hidden';
      cssProps.textOverflow = 'ellipsis';
      cssProps.whiteSpace = 'nowrap';
    }
  }
  return cssProps;
};

export const ellipsisCellStyles = <TData, TValue>(cell: Cell<TData, TValue>, useTableLayoutFixed: boolean): CSSProperties => {
  const cssProps: CSSProperties = {};
  if (useTableLayoutFixed) {
    cssProps.width = cell.column.getSize() === 150 ? 'auto' : cell.column.getSize();
    cssProps.maxWidth = cssProps.width;
    // @ts-ignore
    if (cell.column.columnDef.meta?.ellipsis) {
      cssProps.overflow = 'hidden';
      cssProps.textOverflow = 'ellipsis';
      cssProps.whiteSpace = 'nowrap';
    }
  }
  return cssProps;
};