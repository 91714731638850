import { Controller, useFormContext } from 'react-hook-form';
import { Stack, Switch } from '@chakra-ui/react';

import { DefaultTFuncReturn } from 'i18next';
import FormLabelWithTooltip from './FormLabelWithTooltip';

interface SwitchWithTooltipProps {
  name: string;
  label: string;
  tooltipText?: string | DefaultTFuncReturn;
  isDisabled?: boolean;
}

export default function SwitchWithTooltip({
  name,
  label,
  tooltipText,
  isDisabled,
}: SwitchWithTooltipProps) {
  const { control } = useFormContext();

  return (
    <Stack direction="row" alignItems="center" alignContent="center" spacing={4}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <Switch isDisabled={isDisabled} isChecked={field.value} variant="admin" {...field} />

            <FormLabelWithTooltip label={label} tooltipText={tooltipText} marginBottom={0} />
          </>
        )}
      />
    </Stack>
  );
}

SwitchWithTooltip.defaultProps = {
  isDisabled: false,
  tooltipText: null,
};
