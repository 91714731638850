import { Box, Image, Text } from '@chakra-ui/react';
import { ChoiceOption, Question } from '@/client/types/content/assessments/Assessment';
import { useEffect, useState } from 'react';

import DropdownQuestion from '@/client/components/content/assessments/questionTypes/DropdownQuestion';
import LongInputQuestion from '@/client/components/content/assessments/questionTypes/LongInputQuestion';
import MultipleChoiceMultiSelectQuestion from '@/client/components/content/assessments/questionTypes/MultipleChoiceMultiSelectQuestion';
import MultipleChoiceQuestion from '@/client/components/content/assessments/questionTypes/MultipleChoiceQuestion';
import ShortInputQuestion from '@/client/components/content/assessments/questionTypes/ShortInputQuestion';
import VideoPlayer from '@/client/components/media/video/VideoPlayer';
import { shuffleArray } from '@/client/utils/content/assessments/shuffleArray';

interface AssessmentQuestionProps {
  question: Question;
  inProgressResponse?: string[];
  handleAnswer: (questionId: string, answer: string[]) => void;
}

export default function TextAssessmentQuestion({
  question,
  inProgressResponse,
  handleAnswer,
}: AssessmentQuestionProps) {
  const [selected, setSelected] = useState<string[]>([]);
  const [answers, setAnswers] = useState<ChoiceOption[] | undefined>([]);
  const [multiSelect, setMultiSelect] = useState<boolean>(false);
  const [multiSelectCount, setMultiSelectCount] = useState<number>(0);

  const wordMinimum =
    (question.question_options.find((option) => option.name === 'word_minimum')?.value as number) ||
    0;
  const wordLimit =
    (question.question_options.find((option) => option.name === 'word_limit')?.value as number) ||
    0;

  useEffect(() => {
    const multipleAnswers =
      question.question_options.find((option) => option.name === 'multiple_selections')?.value ||
      false;

    if (multipleAnswers) {
      setMultiSelect(true);
      const allowedAnswers = question.question_options.find(
        (option) => option.name === 'number_of_selections',
      )?.value;
      if (typeof allowedAnswers === 'number') {
        setMultiSelectCount(allowedAnswers);
      } else {
        setMultiSelectCount(question.choice_options?.length || 0);
      }
    }

    const randomize =
      question.question_options.find((option) => option.name === 'randomize_choice_order')?.value ||
      false;

    if (randomize) {
      const shuffled = shuffleArray(question.choice_options);
      setAnswers(shuffled);
    } else {
      setAnswers(question.choice_options);
    }
  }, []);

  useEffect(() => {
    if (inProgressResponse && inProgressResponse.length > 0) {
      setSelected(inProgressResponse);
    }
  }, [inProgressResponse]);

  const handleMultipleSelect = (value: string[]) => {
    setSelected(value);
    handleAnswer(question.id, value);
  };

  const handleSelect = (value: string) => {
    // * If answer is already selected, change the selected answer
    if (!selected.length) {
      setSelected([value]);
      handleAnswer(question.id, [value]);
      return;
    }

    // * If the currently selected answer is the same as the new answer, deselect it
    if (selected.length && value === selected[0]) {
      setSelected([]);
      handleAnswer(question.id, []);

      // !
      // * If the currently selected answer is different than the new answer, change the selected answer
    } else if (selected.length && value !== selected[0]) {
      setSelected([value]);
      handleAnswer(question.id, [value]);
    }
  };

  return (
    <Box>
      <Text as="h4">{question.question}</Text>

      {question.question_asset_type === 'image' && (
        <Box marginBottom={8}>
          <Image src={question.question_asset} />
        </Box>
      )}

      {question.question_asset_type === 'video' && question.question_asset && (
        <VideoPlayer url={question.question_asset} setRenderRecommendations={() => {}} />
      )}

      {question.format === 'multiple_choice' && answers && !multiSelect && (
        <MultipleChoiceQuestion selected={selected} answers={answers} handleSelect={handleSelect} />
      )}

      {question.format === 'multiple_choice' && answers && multiSelect && (
        <MultipleChoiceMultiSelectQuestion
          selected={selected}
          answers={answers}
          handleSelect={handleMultipleSelect}
          multiSelectCount={multiSelectCount}
        />
      )}

      {question.format === 'dropdown' && answers && (
        <DropdownQuestion selected={selected} answers={answers} handleSelect={handleSelect} />
      )}

      {question.format === 'short_input' && (
        <ShortInputQuestion
          selected={selected}
          numberOfSelections={Number(
            question.question_options.find((option) => option.name === 'number_of_selections')
              ?.value,
          )}
          handleSelect={handleSelect}
          handleMultipleSelect={handleMultipleSelect}
        />
      )}

      {question.format === 'long_input' && (
        <LongInputQuestion
          selected={selected[0]}
          wordLimit={wordLimit}
          wordMinimum={wordMinimum}
          handleSelect={handleSelect}
        />
      )}
    </Box>
  );
}

TextAssessmentQuestion.defaultProps = {
  inProgressResponse: null,
};
