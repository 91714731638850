import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import DeleteItemModal from '@/client/components/admin/overlay/DeleteItemModal';
import SingleColumnLayout from '@/client/components/layout/admin/SingleColumnLayout';
import { useConnect } from '@/client/services/hooks/admin/connect/useConnect';
import { useConnectStore } from '@/client/services/state/admin/connectStore';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function Teams() {
  const { t } = useTranslation();
  const { deleteItem } = useConnect();
  const {
    setType,
    setShowExternalOption,
    setColumnCopy,
    clearStore,
    setSortOrder,
    setSearchTerm,
    isSearching,
    sortOrder,
  } = useConnectStore();

  const plural = t('admin.connect.teams.plural');
  const singular = t('admin.connect.teams.singular');

  const columnCopy = {
    plural,
    singular,
  };

  useEffect(() => {
    clearStore();
    setType('team');
    setShowExternalOption(false);
    setColumnCopy(columnCopy);
  }, []);

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={t('admin.connect.searchPlaceholder', { type: singular })}
        showSortSelector
        title={plural}
        setSearchTerm={setSearchTerm}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isSearching={isSearching}
      />

      <SingleColumnLayout
        noSearchResultsText={t('admin.connect.noSearchResultsMain', { type: plural })}
      />

      <DeleteItemModal deleteItem={deleteItem} />
    </>
  );
}
