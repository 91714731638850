import { Box, Divider, Stack, Text } from '@chakra-ui/react';

import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';
import { DndProvider } from 'react-dnd';
import FileUpload from './FileUpload';
import GenerativeDescription from '../../../shared/GenerativeDescription';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TinyMceEditor from '@/client/components/admin/create/shared/TinyMceEditor';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface FileGeneralDetailsFormProps {
  canAdvance: boolean;
}

export default function FileGeneralDetailsForm({ canAdvance }: FileGeneralDetailsFormProps) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { setCanAdvance, previewFullscreen } = useCreateStore();
  const { watch } = useFormContext();

  const id = watch('id');
  const link = watch('link');
  const enableAiPopover = link.endsWith('.pdf');

  useEffect(() => {
    if (canAdvance) {
      setCanAdvance(true);
      return;
    }

    setCanAdvance(false);
  }, [canAdvance]);

  return (
    <Box>
      <Stack spacing={6}>
        <DndProvider backend={HTML5Backend}>
          <FileUpload />
        </DndProvider>
      </Stack>

      {link && (
        <Box>
          <Stack spacing={6}>
            <Divider variant="create" />
            <Stack spacing={0}>
              <Text variant="createLabel">{t('authoring.file.generalDetails.information')}</Text>

              <Text variant="createHelpText">{t('authoring.file.generalDetails.helpText')}</Text>
            </Stack>
            <Stack spacing={2}>
              <CreateInput
                name="title"
                placeholder={t('authoring.file.title.placeholder')}
                variant="create"
                isRequired
              />

              <CreateInput
                name="author"
                placeholder={t('authoring.file.author.placeholder')}
                variant="create"
                isRequired
              />
            </Stack>

            <Stack spacing={2}>
              {company.feature_flags.enable_generative_ai && id && !previewFullscreen && (
                <GenerativeDescription
                  placeholder={t('authoring.tinymce.addFileContent')}
                  enableAiPopover={enableAiPopover}
                  label="File Content"
                />
              )}

              {(!company.feature_flags.enable_generative_ai || !id) && !previewFullscreen && (
                <TinyMceEditor
                  isRequired
                  formName="description"
                  label="File Content"
                  placeholder={t('authoring.tinymce.addFileContent')}
                />
              )}
            </Stack>
          </Stack>
        </Box>
      )}
    </Box>
  );
}
