// !!!! Deprecated - use client/services/hooks/date-and-time/useFormatDate.ts instead !!!!

// TODO: Replace all uses of this function with the useFormatDate hook

import { format } from 'date-fns';

export type DateFormatGroupKey = 'usa' | 'australia';

const formatOptions = {
  usa: {
    base: 'M-dd-yyyy',
    baseWithTime: 'M-dd-yyyy p',
    dateSlash: 'M/dd/yyyy',
    time: 'h:mmaa',
    long: 'EEE, MMM do @ h:mmaa',
    formal: 'MMMM do, yyyy',
  },
  australia: {
    base: 'dd-M-yyyy',
    baseWithTime: 'dd-M-yyyy p',
    dateSlash: 'dd/M/yyyy',
    time: 'h:mmaa',
    long: 'EEE, MMM do @ h:mmaa',
    formal: 'MMMM do, yyyy',
  },
};

export function dateFormatTenant(
  date: Date,
  formatGroupKey?: DateFormatGroupKey,
  formatOptionKey?: 'base' | 'dateSlash' | 'time' | 'long' | 'formal' | 'baseWithTime',
) {
  const formatGroup = formatGroupKey ? formatOptions[formatGroupKey] : formatOptions.usa;
  const formatOption = formatOptionKey ? formatGroup[formatOptionKey] : formatGroup.base;
  return format(date, formatOption);
}
