import { Flex, IconButton, Text } from '@chakra-ui/react';

import { Comment } from '@/client/components/icons/ContinuIcons';
import { useGetCommentCount } from '@/client/services/hooks/comments/useGetCommentCount';

interface HeaderCommentsIconProps {
  contentType: string;
  contentId: string | undefined;
  lightIcons?: boolean;
  goToComments?: () => void;
}

export default function HeaderCommentsIcon({
  contentType,
  contentId,
  lightIcons,
  goToComments,
}: HeaderCommentsIconProps) {
  const { isLoading, isError, data } = useGetCommentCount(contentType, contentId, false);

  const handleGoToComments = () => {
    if (
      contentType === 'workshop' ||
      contentType === 'imported_content' ||
      contentType === 'track'
    ) {
      if (!goToComments) return;

      goToComments();
    } else {
      const commentSection = document.getElementById('comments');

      if (commentSection) {
        commentSection.scrollIntoView();
      }
    }
  };

  if (isLoading || isError) return <></>;

  return (
    <Flex alignItems="center">
      <IconButton
        aria-label="Go to comments"
        variant="ghost"
        icon={
          <Comment
            color={lightIcons ? 'white' : 'brand.iconGrey'}
            boxSize={6}
            _hover={{ color: 'brand.button' }}
          />
        }
        onClick={handleGoToComments}
      />

      {data.count > 0 && <Text textColor={lightIcons ? 'white' : 'black'}>{data.count}</Text>}
    </Flex>
  );
}
