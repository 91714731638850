import { TrackCourse } from '@/client/services/api/graphql/gql/graphql';

export type TCourseSection = { index: number; name: string; _id: string; courses: TrackCourse[] };

const courseSectionDataKey = Symbol('course-section');

export type TCourseSectionData = { [courseSectionDataKey]: true; name: TCourseSection['name'] };

export function getCourseSectionData(section: TCourseSection): TCourseSectionData {
  return { [courseSectionDataKey]: true, name: section.name };
}

export function isCourseSectionData(
  data: Record<string | symbol, unknown>,
): data is TCourseSectionData {
  return data[courseSectionDataKey] === true;
}
