import { Grid, GridItem } from '@chakra-ui/react';

import ThreeColumnListNew from './ThreeColumnListNew';
import { useConnectStore } from '@/client/services/state/admin/connectStore';
import { useTranslation } from 'react-i18next';

export default function ThreeColumnLayout() {
  const { t } = useTranslation();
  const {
    activeMainCategory,
    activeSubCategory,
    isSearching,
    items,
    subItems,
    subSubItems,
    columnCopy,
  } = useConnectStore();

  const checkSecondLevelInput = () => {
    if (!activeMainCategory || activeMainCategory.level === 10 || isSearching) {
      return false;
    }
    return true;
  };

  const checkThirdLevelInput = () => {
    if (!activeSubCategory || activeMainCategory?.level === 10 || isSearching) {
      return false;
    }

    return true;
  };

  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={6} padding={4}>
      <GridItem>
        <ThreeColumnListNew
          title={columnCopy.columnOneTitle}
          categories={items}
          parentId={null}
          level={1}
          showInput={!isSearching}
          noItemsText={t('global.forms.labels_noResults')}
          noSearchResultsCopy={columnCopy.noResultsMain}
        />
      </GridItem>

      <GridItem>
        <ThreeColumnListNew
          title={columnCopy.columnTwoTitle}
          categories={subItems}
          parentId={activeMainCategory?._id}
          level={2}
          showInput={checkSecondLevelInput()}
          noItemsText={
            activeMainCategory?.level === 10
              ? t('admin.connect.categories.noFeaturedSecondary')
              : columnCopy.noSubItemsText
          }
          noSearchResultsCopy={columnCopy.noResultsSub}
        />
      </GridItem>

      <GridItem>
        <ThreeColumnListNew
          title={columnCopy.columnThreeTitle}
          categories={subSubItems}
          parentId={activeSubCategory?._id}
          level={3}
          showInput={checkThirdLevelInput()}
          noItemsText={
            activeMainCategory?.level === 10
              ? t('admin.connect.categories.noFeaturedTertiary')
              : columnCopy.noSubSubItemsText
          }
          noSearchResultsCopy={columnCopy.noResultsSubSub}
        />
      </GridItem>
    </Grid>
  );
}
