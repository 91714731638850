import { PersistOptions, persist } from 'zustand/middleware';
import { StateCreator, create } from 'zustand';

interface TrackState {
  contentId: string | null;
  contentPosition: number | null;
  contentTitle: string | null;
  contentType: 'article' | 'file' | 'video' | 'scorm' | 'assessment';
  section: number;
  track: string;
  trackTitle: string;
  ttl: number;
  returningFromContent: boolean;
  returningFromEditContent?: boolean;
}

interface PersistedTrackState {
  trackState: TrackState | null;
  setTrackState: (trackState: TrackState | null) => void;
}

type TrackPersist = (
  config: StateCreator<PersistedTrackState>,
  options: PersistOptions<PersistedTrackState>,
) => StateCreator<PersistedTrackState>;

const useTrackStateStore = create<PersistedTrackState>(
  (persist as TrackPersist)(
    (set) => ({
      trackState: null,
      setTrackState: (trackState: TrackState | null) => set({ trackState }),
    }),
    {
      name: 'reactTrackState',
    },
  ),
);

export { useTrackStateStore };
