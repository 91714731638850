import { Navigate, Outlet } from 'react-router-dom';

import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';

export default function AdminSettingsLayout() {
  const { allowance } = useAccessHelper();

  if (!allowance.reports) return <Navigate to="/explore" replace />;

  return <Outlet />;
}
