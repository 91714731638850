import { Button, HStack, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { format, subDays } from 'date-fns';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

const formatDateQuery = (difference: number) =>
  format(subDays(new Date(), difference), 'MM-dd-yyyy');

interface UserSearchFiltersProps {
  onFilterChange: (filter: any) => void;
}

export default function UserSearchFilters({ onFilterChange }: UserSearchFiltersProps) {
  const { t } = useTranslation();

  const roleFilterValues = [
    {
      type: 'role',
      _id: 'admin',
      value: t('global.roles.admins'),
      label: t('global.roles.admins'),
    },
    {
      type: 'role',
      _id: 'creator',
      value: t('global.roles.creators'),
      label: t('global.roles.creators'),
    },
    {
      type: 'role',
      _id: 'user',
      value: t('global.roles.users'),
      label: t('global.roles.users'),
    },
    {
      type: 'role',
      _id: 'external',
      value: t('global.roles.externalUsers'),
      label: t('global.roles.externalUsers'),
    },
  ];

  const hiredOnFilterValues = [
    {
      type: 'hiredOn',
      _id: `$gte,date-${formatDateQuery(7)}`,
      value: t('modules.usersearch.hiredWithin', { daycount: '7' }),
      label: t('modules.usersearch.filter_hiredonWithin', { daycount: '7' }),
    },
    {
      type: 'hiredOn',
      _id: `$gte,date-${formatDateQuery(30)}`,
      value: t('modules.usersearch.hiredWithin', { daycount: '30' }),
      label: t('modules.usersearch.filter_hiredonWithin', { daycount: '30' }),
    },
    {
      type: 'hiredOn',
      _id: `$gte,date-${formatDateQuery(60)}`,
      value: t('modules.usersearch.hiredWithin', { daycount: '60' }),
      label: t('modules.usersearch.filter_hiredonWithin', { daycount: '60' }),
    },
    {
      type: 'hiredOn',
      _id: `$gte,date-${formatDateQuery(90)}`,
      value: t('modules.usersearch.hiredWithin', { daycount: '90' }),
      label: t('modules.usersearch.filter_hiredonWithin', { daycount: '90' }),
    },
  ];

  const createdOnFilterValues = [
    {
      type: 'createdOn',
      _id: `$gte,date-${formatDateQuery(7)}`,
      value: t('modules.usersearch.createdLessThan', { daycount: '7' }),
      label: t('modules.usersearch.filter_createdLessthan', { daycount: '7' }),
    },
    {
      type: 'createdOn',
      _id: `$gte,date-${formatDateQuery(30)}`,
      value: t('modules.usersearch.createdLessThan', { daycount: '30' }),
      label: t('modules.usersearch.filter_createdLessthan', { daycount: '30' }),
    },
    {
      type: 'createdOn',
      _id: `$gte,date-${formatDateQuery(60)}`,
      value: t('modules.usersearch.createdLessThan', { daycount: '60' }),
      label: t('modules.usersearch.filter_createdLessthan', { daycount: '60' }),
    },
    {
      type: 'createdOn',
      _id: `$gte,date-${formatDateQuery(90)}`,
      value: t('modules.usersearch.createdLessThan', { daycount: '90' }),
      label: t('modules.usersearch.filter_createdLessthan', { daycount: '90' }),
    },
  ];

  const lastActiveFilterValues = [
    {
      type: 'lastActive',
      _id: `$gte,date-${formatDateQuery(7)}`,
      value: t('modules.usersearch.activeStatusLessThan', { daycount: '7' }),
      label: t('modules.usersearch.filter_createdLessthan', { daycount: '7' }),
    },
    {
      type: 'lastActive',
      _id: `$gte,date-${formatDateQuery(30)}`,
      value: t('modules.usersearch.activeStatusLessThan', { daycount: '30' }),
      label: t('modules.usersearch.filter_createdLessthan', { daycount: '30' }),
    },
    {
      type: 'lastActive',
      _id: `$gte,date-${formatDateQuery(60)}`,
      value: t('modules.usersearch.activeStatusLessThan', { daycount: '60' }),
      label: t('modules.usersearch.filter_createdLessthan', { daycount: '60' }),
    },
    {
      type: 'lastActive',
      _id: `$gte,date-${formatDateQuery(90)}`,
      value: t('modules.usersearch.activeStatusLessThan', { daycount: '90' }),
      label: t('modules.usersearch.filter_createdLessthan', { daycount: '90' }),
    },
  ];

  return (
    <HStack spacing={6}>
      <Menu variant="admin">
        <MenuButton variant="adminMenu" as={Button} rightIcon={<ChevronDownIcon />} flex={1}>
          {t('modules.usersearch.menuLabel')}
        </MenuButton>

        <MenuList>
          {roleFilterValues.map((roleFilterValue) => (
            <MenuItem onClick={() => onFilterChange(roleFilterValue)} key={roleFilterValue._id}>
              {roleFilterValue.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <Menu variant="admin">
        <MenuButton variant="adminMenu" as={Button} rightIcon={<ChevronDownIcon />} flex={1}>
          {t('modules.usersearch.filter_hiredon')}
        </MenuButton>

        <MenuList>
          {hiredOnFilterValues.map((hiredOnFilterValue) => (
            <MenuItem
              onClick={() => onFilterChange(hiredOnFilterValue)}
              key={hiredOnFilterValue._id}
            >
              {hiredOnFilterValue.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <Menu variant="admin">
        <MenuButton variant="adminMenu" as={Button} rightIcon={<ChevronDownIcon />} flex={1}>
          {t('modules.usersearch.filter_created')}
        </MenuButton>

        <MenuList>
          {createdOnFilterValues.map((createdOnFilterValue) => (
            <MenuItem
              onClick={() => onFilterChange(createdOnFilterValue)}
              key={createdOnFilterValue._id}
            >
              {createdOnFilterValue.value}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      <Menu variant="admin">
        <MenuButton variant="adminMenu" as={Button} rightIcon={<ChevronDownIcon />} flex={1}>
          {t('modules.usersearch.filter_lastactive')}
        </MenuButton>

        <MenuList>
          {lastActiveFilterValues.map((lastActiveFilterValue) => (
            <MenuItem
              onClick={() => onFilterChange(lastActiveFilterValue)}
              key={lastActiveFilterValue._id}
            >
              {lastActiveFilterValue.value}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  );
}
