import { Box, useToken } from '@chakra-ui/react';

import { FormField } from '@/client/types/RegistrationForms';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useCallback } from 'react';
import { useDrag } from 'react-dnd';

type DraggableFieldProps = {
  fieldName: string;
  fieldData: FormField;
  addField: (field: FormField) => void;
  isInForm: boolean;
};

export default function DraggableField({
  fieldName,
  fieldData,
  addField,
  isInForm,
}: DraggableFieldProps) {
  const [brandHighlight] = useToken('colors', ['brand.highlight']);

  const fieldIsNotInForm = useCallback(() => !isInForm, [isInForm]);

  const [{ isDragging, handlerId }, drag] = useDrag(() => ({
    type: 'FIELD',
    item: fieldData,
    options: { dropEffect: 'move' },
    canDrag: fieldIsNotInForm,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<FormField>();
      if (item && dropResult) {
        addField(item);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <Box
      ref={drag}
      cursor={isInForm ? 'not-allowed' : 'move'}
      backgroundColor={isDragging || isInForm ? hexToRGBA(brandHighlight, 0.3) : brandHighlight}
      padding={1}
      width="100%"
      borderRadius="5px"
      paddingLeft={2}
      data-handler-id={handlerId}
    >
      {fieldName}
    </Box>
  );
}
