import { PointProps } from "@/client/types/Charts";

export function Rect({ size, color, borderWidth, borderColor }: PointProps) {
  return (
    <g>
      <rect
        x={-size / 2}
        y={-size / 2}
        width={size}
        height={size}
        fill={color}
        stroke={borderColor}
        strokeWidth={borderWidth}
        style={{ pointerEvents: 'none' }}
      />
    </g>
  );
};
