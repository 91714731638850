import { PersistOptions, persist } from 'zustand/middleware';
import { create, StateCreator } from 'zustand';

import { ExploreCategory } from '@/client/types/ExploreContent';

interface ExploreState {
  selectedCategory: string | null;
  setSelectedCategory: (category: string | null) => void;
  selectedTopic: ExploreCategory | undefined;
  setSelectedTopic: (topic: ExploreCategory | undefined) => void;
  selectedSubTopic: ExploreCategory | undefined;
  setSelectedSubTopic: (subTopic: ExploreCategory | undefined) => void;
  selectedIndex: number | undefined;
  setSelectedIndex: (index: number | undefined) => void;
  removeSelectedCategory: () => void;
  persistedSearchTerm: string;
  setPersistedSearchTerm: (search: string) => void;
  persistedSearchQuery: string;
  setPersistedSearchQuery: (search: string) => void;
  persistedTypeRestriction: string;
  setPersistedTypeRestriction: (type: string) => void;
  persistedSelectedItems: any[];
  setPersistedSelectedItems: (items: any[]) => void;
  clearExploreStore: () => void;
}

type ExplorePersist = (
  config: StateCreator<ExploreState>,
  options: PersistOptions<ExploreState>
) => StateCreator<ExploreState>;

const useExploreStore = create<ExploreState>(
  (persist as ExplorePersist)(
    (set) => ({
      selectedCategory: null,
      setSelectedCategory: (categoryId: string | null) =>
        set(() => ({ selectedCategory: categoryId })),
      selectedTopic: undefined,
      setSelectedTopic: (topic: ExploreCategory | undefined) =>
        set(() => ({ selectedTopic: topic })),
      selectedSubTopic: undefined,
      setSelectedSubTopic: (subTopic: ExploreCategory | undefined) =>
        set(() => ({ selectedSubTopic: subTopic })),
      selectedIndex: undefined,
      setSelectedIndex: (index: number | undefined) =>
        set(() => ({ selectedIndex: index })),
      removeSelectedCategory: () => set({ selectedCategory: null }),
      persistedSearchTerm: '',
      setPersistedSearchTerm: (search: string) =>
        set(() => ({ persistedSearchTerm: search })),
      persistedSearchQuery: '',
      setPersistedSearchQuery: (search: string) =>
        set(() => ({ persistedSearchQuery: search })),
      persistedTypeRestriction: '',
      setPersistedTypeRestriction: (type: string) =>
        set(() => ({ persistedTypeRestriction: type })),
      persistedSelectedItems: [],
      setPersistedSelectedItems: (items: any[]) =>
        set(() => ({ persistedSelectedItems: items })),
      clearExploreStore: () => {
        set(() => ({
          selectedCategory: null,
          selectedTopic: undefined,
          selectedSubTopic: undefined,
          selectedIndex: undefined,
          persistedSearchTerm: '',
          persistedSearchQuery: '',
          persistedTypeRestriction: '',
          persistedSelectedItems: [],
        }));
      },
    }),
    { name: 'exploreStore' }
  )
);

export { useExploreStore };
