import { Flex, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react';

import Certificate from '@/client/components/data-display/certificates/Certificate';
import { UserCertificate } from '@/client/types/content/UserCertificate';
import { format } from 'date-fns';
import { useAuthStore } from '@/client/services/state/authStore';
import { useState } from 'react';

type CertificateCardProps = {
  userCertificate: UserCertificate;
};

export default function CertificateCard({ userCertificate }: CertificateCardProps) {
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const [viewingCertificate, setViewingCertificate] = useState(false);
  return (
    <>
      <Flex
        marginY={4}
        marginRight={4}
        minWidth="30%"
        display="flex"
        padding={4}
        background={useColorModeValue('white', 'gray.900')}
        _hover={{ textDecoration: 'none', cursor: 'pointer', color: 'brand.link' }}
        onClick={() => {
          setViewingCertificate(true);
        }}
      >
        <Flex w="150px" h="150px" padding={4}>
          <Image
            objectFit="cover"
            boxSize="100%"
            src="https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/cert-badge.svg"
          />
        </Flex>

        <Stack p={4} flex={1} flexDirection="column">
          <Text fontWeight={600} size="sm" mb={4}>
            {userCertificate.title}
          </Text>

          <Text size="xs" color="gray.400" mb={4}>
            {format(new Date(userCertificate.completedDate), 'MMMM do, yyyy ')}
          </Text>
        </Stack>
      </Flex>

      {viewingCertificate && (
        <Certificate
          contentTitle={userCertificate.title}
          completedDate={userCertificate.completedDate}
          userFullName={user.full_name}
          userMetadata={user.metadata}
          contentMetadata={userCertificate.metadata}
          certificateData={userCertificate.certificateData}
          onClose={() => setViewingCertificate(false)}
        />
      )}
    </>
  );
}
