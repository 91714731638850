import {
  Button,
  ButtonGroup,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
// eslint-disable-next-line import/extensions
import duplicate from '@/client/assets/images/duplicate.svg';
import { learnApiClient } from '@/client/services/api/clients/learnApiClient';
import { useConfigStore } from '@/client/services/state/configStore';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface DuplicateModalProps {
  contentTitle: string;
}

export default function DuplicateModal({ contentTitle }: DuplicateModalProps) {
  const { id } = useParams();
  const { t } = useTranslation();
  const { config } = useConfigStore();
  const { setToast } = useToastStore();
  const { duplicateModalIsOpen, setDuplicateModalIsOpen, contentType } = useCreateStore();

  const isOpen = duplicateModalIsOpen;

  const onClose = () => setDuplicateModalIsOpen(false);

  const contentTypeToLabel: { [key: string]: string } = {
    article: t('authoring.type.article'),
    file: t('authoring.type.file'),
    video: t('authoring.type.video'),
  };

  const contentTypeToApi: { [key: string]: string } = {
    article: 'articles',
    file: 'media',
    video: 'media',
  };

  const duplicateContent = async () => {
    const response = await learnApiClient.post(`${contentTypeToApi[contentType]}/${id}/duplicate`);

    return response.data;
  };

  const duplicateMutation = useMutation({
    mutationFn: duplicateContent,
    onSuccess: (data) => {
      setToast({
        show: true,
        status: 'success',
        title: t('authoring.duplicateModal.success'),
      });

      window.open(
        `//${
          config.host === 'staging.continu.co' ? 'local.continu.co' : config.host
        }/admin/edit/${contentType}/${data._id}`,
        '_blank',
      );
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: t('authoring.duplicateModal.error'),
      });
    },
    onSettled: () => onClose(),
  });

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />

      <ModalContent>
        <ModalBody paddingTop={12}>
          <VStack textAlign="center">
            <Image src={duplicate} width={120} height="auto" />

            <Text fontSize="20px" fontWeight={600} color="baseBlack">
              {t('authoring.duplicateModal.pt1')}
            </Text>

            <Text fontSize="14px" fontWeight={500} color="neutral.1000">
              {t('authoring.duplicateModal.pt2')}
            </Text>

            <Text fontSize="14px" fontWeight={700} color="neutral.1200">
              "{contentTitle}"
            </Text>

            <Text fontSize="14px" fontWeight={500} color="neutral.1000">
              {`${t('authoring.duplicateModal.pt3')} ${contentTypeToLabel[contentType]}.`}
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
          <ButtonGroup width="full" alignItems="center" justifyContent="center">
            <OutlineButton onClick={onClose}>{t('authoring.returnToEdit')}</OutlineButton>

            <SolidButton type="submit" onClick={() => duplicateMutation.mutateAsync()}>
              {t('authoring.duplicateModal.duplicate')}
            </SolidButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
