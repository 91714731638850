import { colors } from '../colors';
import { hexToRGBA } from '@/client/utils/hexToRGBA';

export const Tabs = {
  variants: {
    line: {
      tab: {
        _selected: {
          borderColor: 'brand.primary',
          color: 'brand.primary',
        },
        _hover: {
          color: 'brand.primary',
        },
      },
      tablist: {
        borderBottom: 'none',
        borderColor: 'rgba()',
        overflowY: 'hidden',
      },
    },
    admin: {
      tab: {
        fontSize: 'xs',
        fontWeight: 'bold',
        paddingY: 1,
        paddingX: 2,
        borderRadius: 'md',
        _selected: {
          backgroundColor: hexToRGBA(colors.brand.primary, 0.2),
        },
      },
    },
    adminSmall: {
      tab: {
        fontSize: 'xs',
        fontWeight: 'bold',
        borderRadius: 'md',
        padding: 1.5,
        _hover: {
          backgroundColor: hexToRGBA(colors.brand.primary, 0.2),
        },
        _selected: {
          backgroundColor: hexToRGBA(colors.brand.primary, 0.2),
        },
      },
    },
  },
};
