import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';
import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import TextareaWithTooltip from '../../forms/TextareaWithTooltip';

export default function ProductGeneralDetailsForm() {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <FormLabelWithTooltip label="" />
      <CreateInput
        name="title"
        placeholder={t('authoring.product.titlePlaceholder')}
        variant="createTitle"
        isRequired
      />

      <TextareaWithTooltip
        name="description"
        placeholder={t('authoring.product.descriptionPlaceholder')}
        label=""
        isRequired
        variant="create"
      />
    </Stack>
  );
}
