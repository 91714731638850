import { Filter, Search } from '@/client/components/icons/ContinuIcons';
import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
  VStack,
  useToken,
} from '@chakra-ui/react';

import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useDebounceValue } from 'usehooks-ts';
import { useEffect } from 'react';

interface TableSearchProps {
  showSortSelector?: boolean;
  tooltipText: string;
  showSearch?: boolean;
  setSearchTerm?: (value: string) => void;
  sortOrder?: 'asc' | 'desc' | 'oldest' | 'newest';
  setSortOrder?: (value: 'asc' | 'desc' | 'oldest' | 'newest') => void;
  isSearching?: boolean;
}

export default function TableSearch({
  showSortSelector = false,
  tooltipText,
  setSearchTerm = undefined,
  showSearch = false,
  sortOrder = 'asc',
  setSortOrder = undefined,
  isSearching = false,
}: TableSearchProps) {
  const [brandHighlight] = useToken('colors', ['brand.highlight']);
  const menuHoverColor = hexToRGBA(brandHighlight, 0.1);
  const [debouncedValue, setDebouncedValue] = useDebounceValue('', 500);

  const handleSearch = (value: string) => setDebouncedValue(value);

  useEffect(() => {
    !!setSearchTerm && setSearchTerm(debouncedValue);
  }, [debouncedValue]);

  return (
    <Flex justifyContent="center" width="40%">
      {showSearch && (
        <InputGroup size="sm" backgroundColor="brand.backgroundGrey" borderRadius="5px">
          <InputLeftElement>
            <Search color="brand.grey.60" />
          </InputLeftElement>

          <Input
            placeholder="Search"
            borderRadius="5px"
            color="brand.grey.100"
            border="none"
            _placeholder={{ color: 'brand.grey.60' }}
            onChange={(e) => handleSearch(e.target.value)}
          />

          <InputRightElement>
            <Tooltip hasArrow label={tooltipText}>
              <span>
                <QuestionOutlineIcon color="brand.grey.50" />
              </span>
            </Tooltip>
          </InputRightElement>
        </InputGroup>
      )}

      {showSortSelector && !!setSortOrder && !isSearching && (
        <Menu variant="admin">
          <MenuButton
            backgroundColor="transparent"
            size="sm"
            color="brand.grey.100"
            as={IconButton}
            icon={<Filter />}
            _hover={{ backgroundColor: 'brand.grey.10' }}
            _active={{ backgroundColor: 'brand.grey.10' }}
          />

          <MenuList>
            <MenuGroup title="Sort" fontWeight="bold" fontSize="14px">
              <VStack paddingX={6}>
                <MenuItem
                  borderRadius="lg"
                  fontSize="12px"
                  fontWeight="bold"
                  backgroundColor={sortOrder === 'asc' ? menuHoverColor : 'transparent'}
                  _hover={{ backgroundColor: menuHoverColor }}
                  onClick={() => setSortOrder('asc')}
                >
                  A-Z
                </MenuItem>
                <MenuItem
                  borderRadius="lg"
                  fontSize="12px"
                  fontWeight="bold"
                  backgroundColor={sortOrder === 'desc' ? menuHoverColor : 'transparent'}
                  _hover={{ backgroundColor: menuHoverColor }}
                  onClick={() => setSortOrder('desc')}
                >
                  Z-A
                </MenuItem>
                <MenuItem
                  borderRadius="lg"
                  fontSize="12px"
                  fontWeight="bold"
                  backgroundColor={sortOrder === 'oldest' ? menuHoverColor : 'transparent'}
                  _hover={{ backgroundColor: menuHoverColor }}
                  onClick={() => setSortOrder('oldest')}
                >
                  Oldest
                </MenuItem>
                <MenuItem
                  borderRadius="lg"
                  fontSize="12px"
                  fontWeight="bold"
                  backgroundColor={sortOrder === 'newest' ? menuHoverColor : 'transparent'}
                  _hover={{ backgroundColor: menuHoverColor }}
                  onClick={() => setSortOrder('newest')}
                >
                  Newest
                </MenuItem>
              </VStack>
            </MenuGroup>
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
}
