import { Box, Button, FormControl, FormHelperText, Link, Stack, Text } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { mockUser } from '../../../utils/mockData/mockUser';
import { mockUserProps } from '../../../types/mockUserProps';
import { useTranslation } from 'react-i18next';

type EmailLoginFormProps = {
  buttonCopy: string;
  onLogin: Dispatch<SetStateAction<null | mockUserProps>>;
};

export default function EmailLoginForm({ buttonCopy, onLogin }: EmailLoginFormProps) {
  const { t } = useTranslation();

  return (
    <Stack spacing={4}>
      <Text textAlign="center">{t('preauth.signin.select_below_to_login')}</Text>

      <Box>
        <Button
          width="full"
          bgColor="brand.primary"
          _hover={{
            bg: 'blue.500',
          }}
          onClick={() => {
            onLogin ? onLogin(mockUser) : '';
          }}
        >
          {buttonCopy || t('preauth.signin.signin_with_single_sign_on')}
        </Button>

        <FormControl>
          <FormHelperText textAlign="right">
            <Link
              as={RouterLink}
              to="/forgot"
              color="gray.400"
              fontSize="xs"
              _hover={{
                color: 'brand.primary',
                cursor: 'pointer',
              }}
            >
              {t('preauth.signin.forgotPassword')}
            </Link>
          </FormHelperText>
        </FormControl>
      </Box>
    </Stack>
  );
}
