export const Select = {
  variants: {
    outline: {
      field: {
        _focusWithin: {
          border: '1px',
          borderColor: 'brand.primary',
          boxShadow: 'none',
        },
      },
    },
    create: {
      field: {
        border: '1px',
        borderColor: 'warmNeutral.200',
        _focusWithin: {
          borderColor: 'warmNeutral.600',
          boxShadow: 'none',
        },
      },
    },
  },
};
