import axios, { AxiosInstance } from 'axios';
import { CLIENT_CREATION_KEY_ANALYZE, registerClientCreation } from './clientRegister';

export let analyzeApiClient: AxiosInstance;

const create = (baseURL: string) => {
  analyzeApiClient = axios.create({
    baseURL: `${baseURL}/api/v1`
  });

  analyzeApiClient.interceptors.request.use(
    async (config) => {
      const value = localStorage.getItem('authStore');
  
      let keys;
  
      if (value) {
        keys = JSON.parse(value);
  
        config.headers = {
          authorization: `AuthToken token=${keys.state.authConfig.authtoken}`,
        };
      }
  
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
}

registerClientCreation(CLIENT_CREATION_KEY_ANALYZE, create);
