import { Box, Divider, Stack, Text } from '@chakra-ui/react';

import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import type { ScormFormData } from '@/client/routes/admin/create/content/EditScorm';
import ScormPackageDropzone from './ScormPackageDropzone';
import TinyMceEditor from '@/client/components/admin/create/shared/TinyMceEditor';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ScormGeneralDetailsFormProps {
  canAdvance: boolean;
}

export default function ScormGeneralDetailsForm({ canAdvance }: ScormGeneralDetailsFormProps) {
  const { t } = useTranslation();
  const { setCanAdvance, previewFullscreen } = useCreateStore();
  const { watch, setValue } = useFormContext<ScormFormData>();

  const description = watch('description');

  useEffect(() => {
    if (canAdvance) {
      setCanAdvance(true);
      return;
    }

    setCanAdvance(false);
  }, [canAdvance]);

  return (
    <Box>
      <Stack spacing={6}>
        <DndProvider backend={HTML5Backend}>
          <ScormPackageDropzone />
        </DndProvider>

        <Divider variant="create" />

        <Stack spacing={0}>
          <Text variant="createLabel">{t('authoring.scorm.generalDetails.information')}</Text>

          <Text variant="createHelpText">
            {t('authoring.scorm.generalDetails.information.helpText')}
          </Text>
        </Stack>

        <Stack spacing={2}>
          <Text variant="createLabel">{t('authoring.scorm.scormTitle')}</Text>

          <CreateInput
            name="title"
            placeholder={t('authoring.scorm.title.placeholder')}
            variant="create"
            isRequired
          />
        </Stack>

        <Stack spacing={2}>
          <Text variant="createLabel">{t('authoring.scorm.scormAuthor')}</Text>

          <CreateInput
            name="author"
            placeholder={t('authoring.scorm.author.placeholder')}
            variant="create"
          />
        </Stack>

        {!previewFullscreen && (
          <TinyMceEditor
            isRequired
            formName="description"
            placeholder={t('authoring.tinymce.addScormContent')}
            label="Scorm Content"
          />
        )}
      </Stack>
    </Box>
  );
}
