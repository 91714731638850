import { Button, Stack, Text } from '@chakra-ui/react';

import EmailInput from '@/client/components/input/EmailInput';
import PreAuthService from '@/client/services/api/PreAuthService';
import { useConfigStore } from '@/client/services/state/configStore';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '../utils/useDocumentTitle';

export default function ForgotPassword() {
  useDocumentTitle('Password Recover');
  const { t } = useTranslation();
  const { config } = useConfigStore();
  const { setToast } = useToastStore();
  const [email, setEmail] = useState<string>('');
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const domain = config.host;

  const resetPassword = useMutation({
    mutationFn: () => PreAuthService.requestPasswordReset(email, domain),
    onSettled: () => {
      setShowSuccess(true);

      setToast({
        show: true,
        status: 'success',
        title: t('preauth.forgot.emailHasBeenSent'),
      });
    },
  });

  return (
    <>
      {!showSuccess && (
        <Stack spacing={4} width="100%">
          <Text as="h4">{t('preauth.forgot.headline')}</Text>

          <EmailInput setEmail={setEmail} />

          <Button isDisabled={email === ''} onClick={() => resetPassword.mutate()}>
            {t('preauth.forgot.button')}
          </Button>
        </Stack>
      )}

      {showSuccess && (
        <Stack spacing={4} width="100%">
          <Text as="h4" marginY={0}>
            {t('preauth.forgot.successHeadline')}
          </Text>

          <Text>{t('preauth.forgot.successHint')}</Text>
        </Stack>
      )}
    </>
  );
}
