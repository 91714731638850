import BadgeService from '@/client/services/api/BadgeService';
import { useInfiniteQuery } from '@tanstack/react-query';

export const useGetUserBadgesInfinite = (userId: string, perPage: number) =>
  useInfiniteQuery({
    queryKey: ['query-user-badges-infinite', userId, perPage],
    refetchOnWindowFocus: false,
    queryFn: ({ pageParam = 0 }) => BadgeService.getUserBadgesInfinite(userId, pageParam, perPage),
    getNextPageParam: (lastPage, pages) =>
      lastPage.length === perPage ? pages.length + 1 : undefined,
  });
