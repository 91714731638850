import {
  Button,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/react';

import { CourseFormData } from '@/client/routes/admin/create/content/tracks/EditCourse';
import CreateFormGroup from '../../shared/layout/CreateFormGroup';
import GenerativeService from '@/client/services/api/graphql/GenerativeService';
import { IoSparklesSharp } from 'react-icons/io5';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';

export default function AuthoringLearnItemInput() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { setToast } = useToastStore();
  const { watch, register, setValue } = useFormContext<CourseFormData>();
  const { contentType } = useCreateStore();
  const learnItems = watch('learnItems') || [];
  const id = watch('id');
  const sections = watch('sections');

  const contentCount = (sections || []).reduce((count, section) => {
    const courseCount = (section.courses || []).reduce(
      (acc, course) =>
        course.type === 'article' ||
        course.type === 'video' ||
        course.type === 'file' ||
        course.type === 'scorm'
          ? acc + 1
          : acc,
      0,
    );
    return count + courseCount;
  }, 0);

  useEffect(() => {
    if (learnItems.length < 3) {
      const filledItems = [...learnItems, ...Array(3 - learnItems.length).fill('')];
      setValue('learnItems', filledItems);
    }
  }, [learnItems]);

  const onTopicGenerationSuccess = (data: string[]) => {
    setValue('learnItems', data);
    setToast({
      show: true,
      status: 'success',
      title: t('authoring.settings.learnItems.generateWithAi.success'),
    });
  };

  const onTopicGenerationFailure = (error: any) => {
    console.log('Failed to generate learn items with AI: ', error);
    setToast({
      show: true,
      status: 'error',
      title: t('authoring.settings.learnItems.generateWithAi.error'),
    });
  };

  const trackItems = useMutation({
    mutationFn: (trackid: string) => GenerativeService.getGenerativeTrackTopics(trackid),
    onSuccess: (data) => onTopicGenerationSuccess(data),
    onError: (e) => onTopicGenerationFailure(e),
  });

  return (
    <CreateFormGroup renderBorder={false}>
      <Stack>
        <Text variant="createLabel">{t('authoring.settings.learnItems.label')}</Text>
        <HStack justify="space-between" width="100%">
          <Text variant="createHelpText">{t('authoring.settings.learnItems.helpText')}</Text>
          {company.feature_flags.enable_generative_ai &&
            contentType === 'track' &&
            contentCount > 1 && (
              <Button
                leftIcon={<IoSparklesSharp />}
                variant="generateAiTags"
                onClick={() => {
                  trackItems.mutate(id);
                }}
              >
                {t('authoring.settings.learnItems.generateWithAi')}
              </Button>
            )}
        </HStack>
      </Stack>
      <GridItem colSpan={4} mt={4}>
        <Stack spacing={6}>
          {learnItems.map((item, index) => (
            <InputGroup key={index}>
              <InputLeftElement pointerEvents="none" color="warmNeutral.500" fontSize="1.2em">
                {`${index + 1}.`}
              </InputLeftElement>
              <Input
                variant="create"
                disabled={
                  trackItems.status === 'loading' ||
                  (index === 1 && !learnItems[0]) ||
                  (index === 2 && (!learnItems[0] || !learnItems[1]))
                }
                placeholder={
                  trackItems.status === 'loading'
                    ? `${t('authoring.settings.learnItems.generateWithAi.placeholder')}`
                    : ''
                }
                {...register(`learnItems.${index}`)}
              />
            </InputGroup>
          ))}
        </Stack>
      </GridItem>
    </CreateFormGroup>
  );
}
