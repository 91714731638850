import SwitchList from './SwitchList';
import { useConfigStore } from '@/client/services/state/configStore';
import { useTranslation } from 'react-i18next';

export default function ProfileAdminAccess() {
  const { t } = useTranslation();
  const { config } = useConfigStore();

  const adminAccessOptions = [
    {
      name: 'permissions.admin.overview',
      label: t('profiles.edit.adminOverview'),
      enabled: true,
    },
    {
      name: 'permissions.admin.settings',
      label: t('profiles.edit.adminSettings'),
      enabled: true,
    },
    {
      name: 'permissions.admin.workflows',
      label: t('profiles.edit.adminWorkflows'),
      enabled: true,
    },
    {
      name: 'permissions.admin.reports',
      label: t('profiles.edit.adminReports'),
      enabled: true,
    },
    {
      name: 'permissions.admin.badges',
      label: t('profiles.edit.badges'),
      enabled: config.features.enable_badges,
    },
    {
      name: 'permissions.admin.surveys',
      label: t('profiles.edit.surveys'),
      enabled: config.features.enable_surveys,
    },
  ];

  return <SwitchList enableSelectAll={false} options={adminAccessOptions} />;
}
