import { Box, Grid, GridItem, Text } from '@chakra-ui/react';

import { FullRelatedWorkshop } from '@/client/types/content/Workshop';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface RelatedWorkshopsListProps {
  relatedWorkshops: FullRelatedWorkshop[];
}

export default function RelatedWorkshopsList({ relatedWorkshops }: RelatedWorkshopsListProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Text as="h4" fontWeight="bold">
        {t('relatedworkshops.title')}
      </Text>
      <Grid templateColumns={{ base: '1', md: 'repeat(3, 1fr)' }} gap={6} marginTop={8}>
        {relatedWorkshops.map((relatedWorkshop) => {
          const { workshop } = relatedWorkshop;
          const { instance } = relatedWorkshop;
          return (
            <GridItem
              key={workshop._id}
              boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1)"
              border="1px"
              borderColor="gray.200"
              _hover={{ cursor: 'pointer', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
              onClick={() =>
                navigate(
                  `/${
                    workshop.type === 'multi_session_workshop'
                      ? 'multi-session-workshop'
                      : workshop.type
                  }/${workshop._id}`,
                )
              }
            >
              <Box width="full">
                <Box
                  height="200px"
                  width="full"
                  backgroundColor="brand.primary"
                  backgroundImage={`url(${workshop.banner_image})`}
                  backgroundPosition="top center"
                  backgroundSize="cover"
                  position="relative"
                />

                <Box padding={2}>
                  {!instance && <Text fontSize="xs">{t('workshopviewer.no_dates')}</Text>}

                  {instance && instance === 'multiple' && (
                    <Text fontSize="xs">{t('workshopviewer.multiple_dates')}</Text>
                  )}

                  {instance && instance !== 'multiple' && (
                    <Text fontSize="xs">
                      {format(new Date(instance.start_date), 'E, MMM d h:mm a')}
                    </Text>
                  )}

                  <Text fontSize="lg" fontWeight="500">
                    {workshop.title || ''}
                  </Text>

                  {instance && instance !== 'multiple' && instance.online && (
                    <Text fontSize="sm" color="blackAlpha.600">
                      {t('workshopviewer.online')}
                    </Text>
                  )}

                  {instance && instance !== 'multiple' && !instance.online && instance.location && (
                    <Text fontSize="xs" color="blackAlpha.600">
                      {instance.location.venue ||
                        instance.location.city_state ||
                        instance.location.country ||
                        ''}
                    </Text>
                  )}
                </Box>
              </Box>
            </GridItem>
          );
        })}
      </Grid>
    </>
  );
}
