/* eslint-disable react/jsx-props-no-spreading */

import { ArrowLeft, Save } from '@/client/components/icons/ContinuIcons';
import { Box, Button, Flex, FormControl, HStack, Stack, Textarea } from '@chakra-ui/react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Category } from '@/client/types/Category';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import InputWithToolTip from '@/client/components/admin/forms/InputWithTooltip';
import Loading from '@/client/components/media/Loading';
import NavigationBlocker from '@/client/components/navigation/NavigationBlocker';
import SegmentationSearch from '@/client/components/admin/input/search/SegmentationSearch';
import { stripHtmlTags } from '@/client/utils/stripHtmlTags';
import { useAuthStore } from '@/client/services/state/authStore';
import { useEffect } from 'react';
import { useGroupsService } from '@/client/services/hooks/admin/connect/useGroupsService';
import { useTranslation } from 'react-i18next';

interface GroupFormType {
  name: string;
  description: string;
  external_id: string;
  departments: Category[];
  locations: Category[];
  teams: Category[];
  org_levels: Category[];
  grades: Category[];
}

const defaultGroupFormValues = {
  name: '',
  description: '',
  external_id: '',
  departments: [],
  locations: [],
  teams: [],
  org_levels: [],
  grades: [],
};

export default function GroupForm() {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const { authConfig } = useAuthStore();
  const user = authConfig?.user;
  const navigate = useNavigate();
  const { saveGroup, getGroup } = useGroupsService();
  const { isLoading: groupLoading, isError: isGroupError, data: groupData } = getGroup(groupId);

  const methods = useForm<GroupFormType>({
    defaultValues: defaultGroupFormValues,
    mode: 'onChange',
  });

  const segmentationSelections = {
    departments: methods.watch('departments'),
    locations: methods.watch('locations'),
    teams: methods.watch('teams'),
    org_levels: methods.watch('org_levels'),
    grades: methods.watch('grades'),
  };

  useEffect(() => {
    if (groupData) {
      const departments = groupData.categories.filter((c) => c.department);
      const locations = groupData.categories.filter((c) => c.location);
      const teams = groupData.categories.filter((c) => c.team);
      const levels = groupData.categories.filter((c) => c.org_level);
      const grades = groupData.categories.filter((c) => c.grade);

      const formData = {
        name: groupData.group.name,
        description: stripHtmlTags(groupData.group.description),
        external_id: groupData.group.external_id,
        departments,
        locations,
        teams,
        org_levels: levels,
        grades,
      };

      methods.reset(formData);
    }
  }, [groupData]);

  const onSubmit: SubmitHandler<GroupFormType> = (data) => {
    const groupSaveData = {
      ...data,
      departments: data.departments.map((department) => department._id),
      locations: data.locations.map((location) => location._id),
      teams: data.teams.map((team) => team._id),
      org_levels: data.org_levels.map((level) => level._id),
      grades: data.grades.map((grade) => grade._id),
      user: groupData?.group.user || user._id,
    };

    saveGroup.mutate({ groupId, groupData: groupSaveData });
  };

  const handleContentSegmentationSelection = (option: Category, action: 'add' | 'remove') => {
    if (option.department) {
      action === 'add'
        ? methods.setValue('departments', [...segmentationSelections.departments, option])
        : methods.setValue(
            'departments',
            segmentationSelections.departments.filter((d) => d._id !== option._id),
          );
    }

    if (option.location) {
      action === 'add'
        ? methods.setValue('locations', [...segmentationSelections.locations, option])
        : methods.setValue(
            'locations',
            segmentationSelections.locations.filter((d) => d._id !== option._id),
          );
    }

    if (option.team) {
      action === 'add'
        ? methods.setValue('teams', [...segmentationSelections.teams, option])
        : methods.setValue(
            'teams',
            segmentationSelections.teams.filter((d) => d._id !== option._id),
          );
    }

    if (option.org_level) {
      action === 'add'
        ? methods.setValue('org_levels', [...segmentationSelections.org_levels, option])
        : methods.setValue(
            'org_levels',
            segmentationSelections.org_levels.filter((d) => d._id !== option._id),
          );
    }

    if (option.grade) {
      action === 'add'
        ? methods.setValue('grades', [...segmentationSelections.grades, option])
        : methods.setValue(
            'grades',
            segmentationSelections.grades.filter((d) => d._id !== option._id),
          );
    }
  };

  if (groupLoading && groupId) return <Loading />;

  if (isGroupError && groupId) return <ErrorAlert title="Error loading group" />;

  return (
    <>
      <NavigationBlocker enabled={methods.formState.isDirty} />

      <Box
        backgroundColor="white"
        marginX={4}
        padding={6}
        borderRadius="xl"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Flex alignItems="center" justifyContent="space-between" marginBottom={8}>
              <Button
                size="sm"
                variant="adminPrimary"
                leftIcon={<ArrowLeft />}
                onClick={() => navigate(-1)}
              >
                {t('global.actions.back')}
              </Button>

              <Button
                isDisabled={!methods.formState.isValid}
                isLoading={saveGroup.isLoading}
                size="sm"
                variant="adminPrimary"
                leftIcon={<Save />}
                type="submit"
              >
                {t('global.action.save')}
              </Button>
            </Flex>

            <Stack paddingX={6} spacing={4}>
              <HStack spacing={4} maxWidth="60%">
                <FormControl id="name">
                  <InputWithToolTip
                    name="name"
                    isRequired
                    label={t('global.forms.labels_name')}
                    tooltipText={t('edit.groups.help_groupNameText')}
                  />
                </FormControl>

                <FormControl id="externalId">
                  <InputWithToolTip
                    name="external_id"
                    label={t('reports.fields.external_id')}
                    tooltipText={t('edit.groups.help_externalGroupText')}
                  />
                </FormControl>
              </HStack>

              <FormControl id="description" maxWidth="60%">
                <FormLabelWithTooltip
                  isRequired
                  label={t('global.forms.labels_description')}
                  tooltipText={`${t('edit.groups.help_describeGroupText')}`}
                />

                <Controller
                  name="description"
                  control={methods.control}
                  rules={{
                    required: { value: true, message: 'This field is required' },
                  }}
                  render={({ field }) => (
                    <Textarea
                      variant="adminInput"
                      placeholder={`${t('global.forms.labels_description')}`}
                      {...field}
                    />
                  )}
                />
              </FormControl>

              <SegmentationSearch
                label={t('edit.groups.fieldSelections')}
                inputPlaceholder={t('global.forms.labels_search')}
                tooltipText={t('admin.connect.groups.fieldSelectionsTooltip')}
                segmentationSelections={segmentationSelections}
                updateSelections={handleContentSegmentationSelection}
              />
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </>
  );
}

GroupForm.defaultProps = {
  isEditing: false,
};
