export const defaultProfileFormValuesTrue = {
  name: '',
  description: '',
  permissions: {
    explore: {
      order_content: true,
      update_theme: true,
    },
    admin: {
      connect: {
        categories: true,
        locations: true,
        departments: true,
        teams: true,
        levels: true,
        grades: true,
        groups: true,
        skills: true,
      },
      content: {
        all_create: true,
        all_edit: true,

        workshops_create: false,
        articles_create: false,
        files_create: false,
        videos_create: false,
        scorm_create: false,
        assessments_create: false,
        tracks_create: false,
        imported_content_create: false,
        presentations_create: false,
        surveys_create: false,

        my_content_edit: false,

        workshops_edit: false,
        articles_edit: false,
        files_edit: false,
        videos_edit: false,
        scorm_edit: false,
        assessments_edit: false,
        tracks_edit: false,
        imported_content_edit: false,
        presentations_edit: false,
        surveys_edit: false,

        all_analytics: true,
        workshops_analytics: false,
        articles_analytics: false,
        files_analytics: false,
        videos_analytics: false,
        scorm_analytics: false,
        assessments_analytics: false,
        presentations_analytics: false,
        tracks_analytics: false,
        imported_content_analytics: false,
        my_content_analytics: false,

        restrict_content_categories: false,
        allowed_categories: [],
        assessment_reset: true,
        segmentation_group: {
          departments: [],
          locations: [],
          teams: [],
          org_levels: [],
          grades: [],
        },
        require_segmentation: false,
        restrict_segmentation_options: false,
        restrict_category_options: false,
        approve_content: true,
        archive_content: true,
        unarchive_content: true,
        category_group: {
          categories: [],
        },
      },
      users: {
        restricted_access: false,
        view: true,
        add: true,
        edit: true,
        password_reset: true,
        suspend: true,
        admin_set: true,
        creator_set: true,
        manager_set: true,
        buddy_set: true,
        profile_set: true,
        external_users: true,
        user_group: {
          departments: [],
          locations: [],
          teams: [],
          org_levels: [],
          grades: [],
        },
        bulk_add: false,
        view_user_activity: true,
      },
      assignments: {
        create: true,
        edit: true,
        view: true,
        archive: true,
        reset: true,
        complete: true,
      },
      journeys: {
        create: false,
        edit: false,
        view: true,
        archive: false,
      },
      overview: true,
      settings: true,
      workflows: true,
      reports: true,
      badges: true,
      surveys: true,
    },
  },
};

export const defaultProfileFormValuesFalse = {
  name: '',
  description: '',
  permissions: {
    explore: {
      order_content: false,
      update_theme: false,
    },
    admin: {
      content: {
        all_create: false,
        all_edit: false,

        workshops_create: false,
        articles_create: false,
        files_create: false,
        videos_create: false,
        scorm_create: false,
        assessments_create: false,
        tracks_create: false,
        imported_content_create: false,
        presentations_create: false,
        surveys_create: false,

        my_content_edit: false,

        workshops_edit: false,
        articles_edit: false,
        files_edit: false,
        videos_edit: false,
        scorm_edit: false,
        assessments_edit: false,
        tracks_edit: false,
        imported_content_edit: false,
        presentations_edit: false,
        surveys_edit: false,

        all_analytics: false,
        workshops_analytics: false,
        articles_analytics: false,
        files_analytics: false,
        videos_analytics: false,
        scorm_analytics: false,
        assessments_analytics: false,
        presentations_analytics: false,
        tracks_analytics: false,
        imported_content_analytics: false,
        my_content_analytics: false,

        restrict_content_categories: false,
        allowed_categories: [],
        assessment_reset: false,
        segmentation_group: {
          departments: [],
          locations: [],
          teams: [],
          org_levels: [],
          grades: [],
        },
        require_segmentation: false,
        restrict_segmentation_options: false,
        restrict_category_options: false,
        approve_content: false,
        archive_content: false,
        unarchive_content: false,
        category_group: {
          categories: [],
        },
      },
      users: {
        restricted_access: false,
        view: false,
        add: false,
        edit: false,
        password_reset: false,
        suspend: false,
        admin_set: false,
        creator_set: false,
        manager_set: false,
        buddy_set: false,
        profile_set: false,
        external_users: false,
        user_group: {
          departments: [],
          locations: [],
          teams: [],
          org_levels: [],
          grades: [],
        },
        bulk_add: false,
        view_user_activity: false,
      },
      assignments: {
        create: false,
        edit: false,
        view: false,
        archive: false,
        reset: false,
        complete: false,
      },
      journeys: {
        create: false,
        edit: false,
        view: false,
        archive: false,
      },
      overview: false,
      settings: false,
      workflows: false,
      reports: false,
      badges: false,
      surveys: false,
    },
  },
};
