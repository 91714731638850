import { Button, Grid, GridItem, Heading, Input, Text } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import User from "@/client/services/api/User";
import { difference } from "lodash";
import { useToastStore } from "@/client/services/state/toastStore";
import { useTranslation } from "react-i18next";
import { capCase } from "@/client/utils/capCase";

type SocialProfile = {
  name: string;
  value: string;
  _id?: string;
};

type SocialProfilesFormProps = {
  userId: string;
  socialProfiles: SocialProfile[];
};

export default function SocialProfilesForm({
  userId,
  socialProfiles,
}: SocialProfilesFormProps) {
  const { setToast } = useToastStore();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const providers = [
    "linkedin",
    "twitter",
    "facebook",
    "instagram",
    "dribbble",
    "github",
  ];
  const [initialSocialProfiles, setInitialSocialProfiles] = useState<
    SocialProfile[]
  >([]);
  const [updatedSocialProfiles, setUpdatedSocialProfiles] = useState<
    SocialProfile[]
  >([]);
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    const initial = providers.map((provider) => {
      const socialProfile = socialProfiles.find(
        (socialProfile) => socialProfile.name === provider
      );

      return {
        name: provider,
        value: socialProfile ? socialProfile.value : "",
      };
    });

    setInitialSocialProfiles(initial);
    setUpdatedSocialProfiles(initial);
  }, []);

  const handleUpdateAvailable = (diff: number) =>
    diff > 0 ? setUpdateAvailable(true) : setUpdateAvailable(false);

  useEffect(() => {
    const diff = difference(initialSocialProfiles, updatedSocialProfiles);

    handleUpdateAvailable(diff.length);
  }, [updatedSocialProfiles]);

  const updateSocialProfiles = useMutation({
    mutationFn: (socialProfiles: SocialProfile[]) =>
      User.updateSocialProfiles(userId, socialProfiles),
    onSuccess: () => {
      setToast({
        show: true,
        status: "success",
        title: t("modules.notifications.profile_updated"),
      });
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () =>
      setToast({
        show: true,
        status: "error",
        title: t("modules.notifications.canNotUpdateProfile"),
      }),
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    const updated = updatedSocialProfiles.map((socialProfile) => {
      if (socialProfile.name === event.target.name) {
        return { ...socialProfile, value };
      }

      return socialProfile;
    });

    setUpdatedSocialProfiles(updated);
  };

  const handleSubmit = () => {
    const trimmed = updatedSocialProfiles
      .filter((profile) => profile.value !== "")
      .map((profile) => ({ name: profile.name, value: profile.value }));

    updateSocialProfiles.mutate(trimmed);
  };

  return (
    <>
      <Heading as="h3" fontSize="sm" textTransform="uppercase">
        {t("userProfileEdit.yourSocialProfile")}
      </Heading>

      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={10}
        marginBottom={6}
      >
        {updatedSocialProfiles.length &&
          updatedSocialProfiles.map((profile) => (
            <GridItem key={profile.name}>
              <Text variant="formlabel">
                {capCase(profile.name)}
              </Text>

              <Input
                name={profile.name}
                variant="flushed"
                value={profile.value}
                onChange={(event) => handleChange(event)}
              />
            </GridItem>
          ))}
      </Grid>

      {updateAvailable && (
        <Button onClick={() => handleSubmit()}>
          {t("userProfileEdit.updateProfile")}
        </Button>
      )}
    </>
  );
}
