import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';
import { Flex } from '@chakra-ui/react';
import ContentFormTextGroup from '../create/content/text/ContentFormTextGroup';

interface ContentInputGroupProps {
  label: string;
  placeholder: string;
  helpText?: string;
  formName: string;
  isRequired?: boolean;
}

export default function ContentInputGroup({
  label,
  placeholder,
  helpText = undefined,
  formName,
  isRequired = false,
}: ContentInputGroupProps) {
  return (
    <Flex paddingY={8}>
      <ContentFormTextGroup label={label} helpText={helpText} />

      <CreateInput
        name={formName}
        variant="create"
        placeholder={placeholder}
        isRequired={isRequired}
      />
    </Flex>
  );
}
