import { AbsoluteCenter, Spinner } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import { useEffect } from 'react';
import { useExploreStore } from '../services/state/routeState/exploreStore';
import { useGetExploreCategories } from '../services/hooks/useGetExploreCategories';

export default function LegacyExploreRoute() {
  const { categoryId } = useParams<{ categoryId: string }>();
  const navigate = useNavigate();
  const { setSelectedCategory, setSelectedTopic, setSelectedSubTopic } = useExploreStore();

  const { getCategories } = useGetExploreCategories();

  const { data: categories, isLoading, isError } = getCategories;

  useEffect(() => {
    setSelectedCategory(null);
    setSelectedTopic(undefined);
    setSelectedSubTopic(undefined);

    if (categories) {
      const categoryMatch = categories?.allCategories.find((c: any) => c._id === categoryId);

      if (!categoryMatch) navigate('/explore');

      if (categoryMatch?.level === 1 || categoryMatch?.level === 10) {
        const category = categoryMatch._id;

        navigate(`/explore?category=${category}`);
      } else if (categoryMatch?.level === 3) {
        const subTopic = categoryMatch._id;

        const topic = categories?.allCategories.find((c: any) => c._id === categoryMatch?.parent);

        const category = categories?.allCategories.find((c: any) => c._id === topic?.parent);

        if (!topic || !category) navigate('/explore');

        navigate(`/explore?category=${category?._id}&topic=${topic?._id}&subTopic=${subTopic}`);
      } else if (categoryMatch?.level === 2) {
        const topic = categoryMatch._id;

        const category = categories?.allCategories.find(
          (c: any) => c._id === categoryMatch?.parent,
        );

        if (!category) navigate('/explore');

        navigate(`/explore?category=${category?._id}&topic=${topic}`);
      }
    }
  }, [categories]);

  if (isLoading)
    return (
      <AbsoluteCenter>
        <Spinner />
      </AbsoluteCenter>
    );

  if (isError) navigate('/explore');

  return <></>;
}
