/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
  useNumberInput,
} from '@chakra-ui/react';
import { FaMinus, FaPlus } from 'react-icons/fa6';

import CreateFormGroup from '@/client/components/admin/create/shared/layout/CreateFormGroup';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function AuthoringDurationForm() {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  const contentDuration = watch('duration');

  const {
    getInputProps: getHoursInputProps,
    getIncrementButtonProps: getHoursIncrementButtonProps,
    getDecrementButtonProps: getHoursDecrementButtonProps,
  } = useNumberInput({
    defaultValue: Math.floor(contentDuration / 3600),
    min: 0,
    max: 10,
  });

  const {
    getInputProps: getMinutesInputProps,
    getIncrementButtonProps: getMinutesIncrementButtonProps,
    getDecrementButtonProps: getMinutesDecrementButtonProps,
  } = useNumberInput({
    defaultValue: Math.floor((contentDuration % 3600) / 60),
    min: 0,
    max: 59,
  });

  const incHours = getHoursIncrementButtonProps();
  const decHours = getHoursDecrementButtonProps();
  const inputHours = getHoursInputProps();
  const incMinutes = getMinutesIncrementButtonProps();
  const decMinutes = getMinutesDecrementButtonProps();
  const inputMinutes = getMinutesInputProps();

  useEffect(() => {
    const currentHours = Math.floor(contentDuration / 3600);
    const currentMinutes = Math.floor((contentDuration % 3600) / 60);
    const hoursInput = inputHours['aria-valuenow'];
    const minutesInput = inputMinutes['aria-valuenow'];

    if (typeof hoursInput !== 'number' || typeof minutesInput !== 'number') return;

    if (currentHours !== hoursInput) {
      setValue('duration', hoursInput * 3600 + minutesInput * 60);
    }

    if (currentMinutes !== minutesInput) {
      setValue('duration', hoursInput * 3600 + minutesInput * 60);
    }
  }, [inputHours, inputMinutes]);

  return (
    <CreateFormGroup>
      <Flex width="full" justifyContent="space-between">
        <Stack flex={1}>
          <Text variant="createLabel">{t('authoring.settings.duration.estimatedTime')}</Text>

          <Text variant="createHelpText">{t('authoring.settings.duration.helpText')}</Text>
        </Stack>

        <HStack justifyContent="flex-end" flex={1}>
          <Stack>
            <Text
              fontSize="xs"
              textAlign="center"
              color="neutral.700"
              fontWeight={600}
              textTransform="uppercase"
            >
              {t('authoring.settings.duration.hours')}
            </Text>

            <HStack>
              {/* @ts-ignore */}
              <IconButton
                size="sm"
                variant="createNumberStepper"
                {...decHours}
                isRound
                icon={<FaMinus />}
              />

              <Box width={14}>
                <Input
                  variant="createNumber"
                  fontSize="14px"
                  fontWeight={600}
                  color={inputHours['aria-valuenow'] === 0 ? 'neutral.300' : 'baseBlack'}
                  {...inputHours}
                />
              </Box>

              {/* @ts-ignore */}
              <IconButton
                size="sm"
                variant="createNumberStepper"
                isRound
                icon={<FaPlus />}
                {...incHours}
              />
            </HStack>
          </Stack>

          <Stack>
            <Text
              fontSize="xs"
              textAlign="center"
              color="neutral.700"
              fontWeight={600}
              textTransform="uppercase"
            >
              {t('authoring.settings.duration.minutes')}
            </Text>

            <HStack>
              {/* @ts-ignore */}
              <IconButton
                size="sm"
                variant="createNumberStepper"
                isRound
                icon={<FaMinus />}
                {...decMinutes}
              />

              <Box width={14}>
                <Input
                  variant="createNumber"
                  fontSize="14px"
                  fontWeight={600}
                  color={inputMinutes['aria-valuenow'] === 0 ? 'neutral.300' : 'baseBlack'}
                  {...inputMinutes}
                />
              </Box>

              {/* @ts-ignore */}
              <IconButton
                size="sm"
                variant="createNumberStepper"
                isRound
                icon={<FaPlus />}
                {...incMinutes}
              />
            </HStack>
          </Stack>
        </HStack>
      </Flex>
    </CreateFormGroup>
  );
}
