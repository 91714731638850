import { Box, Container, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import DedicatedWorkshopsDates from '../components/data-display/calendar/DedicatedWorkshopsDates';
import WorkshopAssignmentCalendar from '@/client/components/data-display/calendar/WorkshopAssignmentCalendar';
import WorkshopService from '../services/api/content/workshops/WorkshopService';
import WorkshopsFilter from '@/client/components/disclosure/WorkshopsFilter';
import WorkshopsList from '@/client/components/lists/WorkshopsList';
import CircleStatSection from '../components/data-display/stats/CircleStatSection';
import { useAuthStore } from '@/client/services/state/authStore';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useToastStore } from '@/client/services/state/toastStore';

export default function DedicatedWorkshops() {
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const [query, setQuery] = useState<any>({});
  const [sort, setSort] = useState<any>('default');
  const [filter, setFilter] = useState<any>({});
  const [isSearching, setIsSearching] = useState(false);

  if (!company.feature_flags.enable_dedicated_workshops) {
    setToast({
      show: true,
      status: 'error',
      title: 'Workshops are unavailable for the company.',
    });
    navigate('/explore');
  }

  const { data: statData } = useQuery({
    queryKey: ['workshops/stats'],
    queryFn: () => WorkshopService.getUserWorkshopStats(),
  });

  const {
    data: searchData,
    isFetchingNextPage: isFetchingNextPageSearch,
    fetchNextPage: fetchNextPageSearch,
    status: statusSearch,
    refetch,
  } = useInfiniteQuery({
    enabled: true,
    refetchOnWindowFocus: false,
    queryKey: ['workshop-search', query],
    queryFn: ({ pageParam = 1 }) => WorkshopService.searchWorkshops(query, pageParam),
    getNextPageParam: (lastPage: any) => lastPage.nextPage,
  });

  useEffect(() => {
    setQuery({ ...filter, sort });
  }, [filter, sort]);

  useEffect(() => {
    setIsSearching(true);
    refetch();
    setIsSearching(false);
  }, [query]);

  return (
    <Container minHeight="100%" minWidth="100%" backgroundColor="brand.mist" position="relative">
      <Box
        backgroundColor="rgb(247,247,247)"
        backgroundRepeat="no-repeat"
        backgroundSize="100vw"
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="fit-content"
        zIndex="0"
      >
        <Box
          maxWidth="1200px"
          height="fit-content"
          margin="auto"
        >
          <WorkshopsFilter updateSearch={setFilter} workshopsLoadingStatus={statusSearch} />
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            gap={2}
            minHeight="630px"
            height={{ base: 'auto', lg: '80vh' }}
            marginBottom={6}
          >
            <WorkshopsList
              searchData={searchData}
              querySort={setSort}
              fetchNextPage={fetchNextPageSearch}
              isPast={filter.upcoming === false}
              isSearching={isSearching}
              isFetchingNextPage={isFetchingNextPageSearch}
            />
            <Box order={{ base: 1, lg: 2 }}>
              <Flex direction="column" flex={1}>
                <Box
                  padding={6}
                  paddingLeft={8}
                  backgroundColor="white"
                  marginRight={6}
                  marginBottom={2}
                  borderRadius="5px"
                  width="auto"
                  height="460px"
                >
                  <WorkshopAssignmentCalendar DayRenderer={DedicatedWorkshopsDates} />
                </Box>
                {statData && (
                  <CircleStatSection
                    stats={[
                      { label: 'workshops.registered', value: statData.registered },
                      { label: 'workshops.waitlisted', value: statData.waitlisted },
                      { label: 'workshops.attended', value: statData.attended },
                    ]}
                  />
                )}
              </Flex>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Container>
  );
}
