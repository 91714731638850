import type {
  Element,
  LinkedinSearchData,
} from '@/client/types/admin/content-authoring/tracks/imported-content/LinkedInSearchData';
import { ImportedContent, Registration } from '@/client/types/content/ImportedContent';

import type { MasterclassSearchData } from '@/client/types/admin/content-authoring/tracks/imported-content/MasterclassSearchData';
import type { UdemyCourseData } from '@/client/types/admin/content-authoring/tracks/imported-content/UdemyCourseData';
import type { UdemySearchData } from '@/client/types/admin/content-authoring/tracks/imported-content/UdemySearchData';
import { learnApiClient } from './clients/learnApiClient';
import { v3ApiClient } from './clients/v3ApiClient';

const register = async (contentId: string | undefined, userId: string) => {
  const response = await learnApiClient.post(`imported-content/registrations/register`, {
    content: contentId,
    user: userId,
  });

  return response.data;
};

const getImportedContent = async (
  contentId: string | undefined,
  userId: string,
): Promise<{ content: ImportedContent; registration: Registration; progressPercent: number }> => {
  const content = await learnApiClient.get<ImportedContent>(
    `imported-content/${contentId}?fields=title,description,instructors,headline,learn_items,vendor,vendor_link,vendor_full_link,tags,image,banner_image,user,linked_category,explore_hide,duration,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,lil_structure,difficulty,allow_comments,groups,surveys,completed,metadata&instructor_ids-populate=name,image,bio,notes,email,socials`,
  );

  const registerUser = await register(contentId, userId);

  let progressPercent = 0;

  if (registerUser.results) {
    const latestProgress = registerUser.results.find(
      (result: any) => result.name === 'latest_progress',
    );

    progressPercent = registerUser.completed ? 100 : 0;

    if (latestProgress) {
      latestProgress.object = JSON.parse(latestProgress.value);
    }
  }

  return {
    content: content.data,
    registration: registerUser,
    progressPercent,
  };
};

const adminSearchLinkedinContent = async (
  sortBy: string | null | undefined,
  type: string | null | undefined,
  difficulty: string | null | undefined,
  language: string | null | undefined,
  searchTerm: string,
  count: number,
  start: number,
): Promise<LinkedinSearchData> => {
  const langBlock: { [key: string]: { language: string; country: string } } = {
    en: {
      language: 'en',
      country: 'US',
    },
    de: {
      language: 'de',
      country: 'DE',
    },
    ja: {
      language: 'ja',
      country: 'JP',
    },
    fr: {
      language: 'fr',
      country: 'FR',
    },
    es: {
      language: 'es',
      country: 'ES',
    },
  };

  const payload = {
    q: 'criteria',
    'assetFilteringCriteria.assetTypes[0]': type || 'COURSE',
    'assetFilteringCriteria.count': count,
    start,
    'assetFilteringCriteria.fields': 'urn,title,details,type,contents:($*:(asset:(urn,type)))',
    'assetRetrievalCriteria.expandDepth': 1,
    'assetPresentationCriteria.sortBy': sortBy,
    'assetFilteringCriteria.keyword': searchTerm,
    'assetFilteringCriteria.difficultyLevels[0]': difficulty,
    'assetFilteringCriteria.locale.language[0]': language ? langBlock[language].language : 'en',
    'assetPresentationCriteria.targetLocale.language': language
      ? langBlock[language].language
      : 'en',
    'assetFilteringCriteria.locale.country[0]': language ? langBlock[language].country : 'US',
    'assetPresentationCriteria.targetLocale.country': language ? langBlock[language].country : 'US',
  };

  const response = await v3ApiClient.post(
    `content-integrations/search-content/linkedin_learning`,
    payload,
  );

  return response.data;
};

const getLinkedinCourseByUrn = async (urn: string): Promise<Element> => {
  const response = await v3ApiClient.get(`content-integrations/lil-course/${urn}`);

  return response.data;
};

const adminSearchMasterclassContent = async (
  limit: number,
  offset: number,
  query: string,
): Promise<{ data: MasterclassSearchData; offset: number }> => {
  const response = await v3ApiClient.post(`content-integrations/search-content/masterclass`, {
    'page[limit]': limit,
    'page[offset]': offset,
    q: query,
  });

  return {
    data: response.data,
    offset,
  };
};

const adminSearchUdemyContent = async (
  pageNumber: number,
  pageSize: number,
  searchTerm: string,
  ordering: string | null | undefined,
  ratings: string | null | undefined,
  duration: string | null | undefined,
): Promise<UdemySearchData> => {
  const response = await v3ApiClient.post(`content-integrations/search-content/udemy`, {
    is_affiliate_agreed: true,
    page: pageNumber,
    page_size: pageSize,
    search: searchTerm,
    ordering,
    ratings,
    duration,
  });

  return response.data;
};

interface IntegrationParams {
  portal_name: string;
  portal_id: string;
}

export interface FullUdemyCourseData {
  course: UdemyCourseData;
  integrationParams: IntegrationParams;
}

const getUdemyIntegrationParams = async (): Promise<IntegrationParams> => {
  const response = await v3ApiClient.get('content-integrations/udemy');

  return response.data;
};

const getUdemyCourseById = async (id: number): Promise<FullUdemyCourseData> => {
  const integrationParams = await getUdemyIntegrationParams();
  const response = await v3ApiClient.get(`content-integrations/udemy-course/${id.toString()}`);

  return {
    course: response.data,
    integrationParams,
  };
};

const ImportedContentService = {
  getImportedContent,
  register,
  adminSearchLinkedinContent,
  getLinkedinCourseByUrn,
  adminSearchMasterclassContent,
  adminSearchUdemyContent,
  getUdemyIntegrationParams,
  getUdemyCourseById,
};

export default ImportedContentService;
