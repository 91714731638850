import { useState, useEffect } from 'react';
import ContextSelector from '@/client/components/overlay/ContextSelector';
import ContextService from '@/client/services/api/ContextService';
import ContentService from '@/client/services/api/ContentService';
import { useParams, useSearchParams } from 'react-router-dom';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

interface ContentWrapperProps {
  content: any;
  contentType: string;
  contentId?: string;
  selectorDisabled?: boolean;
  trackId?: string;
  journeyId?: string;
  onCompleteContent?: Function;
  journeyNavigateAction?: Function;
  parentContextId?: string;
}

/**
 * Wrapped content types: 
 * Article
 * ContentFile
 * Video
 * Scorm
 * LearningTrack
 * Assessment
 * 
 * Unwrapped content types:
 * Workshop
 * MultiSessionWorkshop
 * ImportedContent
 * Journey
 */

export default function ContentWrapper({
  content,
  contentType,
  contentId,
  selectorDisabled,
  trackId,
  journeyId,
  onCompleteContent,
  journeyNavigateAction,
  parentContextId,
}: ContentWrapperProps) {
  const { t } = useTranslation();
  let { id } = useParams();
  if (contentType === 'track') {
    id = useParams().learningTrackId;
  }
  id = contentId || id;
  const [searchParams, setSearchParams] = useSearchParams();
  let contextIdSearchParam = !journeyId && !trackId ? searchParams.get('contextId') : null;

  const { setToast } = useToastStore();
  const [completionContextId, setCompletionContextId] = useState<string | null>(null);
  const [needsContextSelection, setNeedsContextSelection] = useState(true);

  const handleContextSelect = async (selectedContextId: string) => {
    try {
      await ContextService.validate(selectedContextId, contentType);
      setCompletionContextId(selectedContextId);
    } catch (e) {
      console.log('Error attempting to validate user content', e)
      setToast({
        show: true,
        status: 'error',
        title: t('recompletions.errors.accessDenied'),
      });
      setSearchParams({});
      removeContextId();
    }
  };

  const contexts: { key: string; value: string }[] = [];
  if (journeyId) {
    contexts.push({ key: 'journey', value: journeyId });
  }
  if (trackId) {
    contexts.push({ key: 'track', value: trackId });
  }

  useEffect(() => {
    if (contextIdSearchParam) {
      handleContextSelect(contextIdSearchParam);
    }
  }, [contextIdSearchParam]);
  
  useEffect(() => {
    if (completionContextId) {
      setNeedsContextSelection(false);
    }
  }, [completionContextId]);

  const completionMutation = useMutation({
    mutationFn: async () => await ContentService.completeContent(id, contentType, contexts, completionContextId, parentContextId),
    onSuccess: async () => {
      if (onCompleteContent) {
        await onCompleteContent();
      }
    },
    onError: (error) => {
      console.log(`Error completing content`, error);
    },
  });

  const removeContextId = () => {
    contextIdSearchParam = null;
    setCompletionContextId(null);
  }

  const Content = content;

  return (
    <>
      <ContextSelector
        contentType={contentType}
        contentId={id}
        handleContextSelect={handleContextSelect}
        enabled={!selectorDisabled}
        initialContextId={contextIdSearchParam}
        parentContextId={parentContextId}
        contexts={contexts}
      />
      <Content
        key={completionContextId}
        contentId={id}
        needsContextSelection={needsContextSelection}
        sendCompleteContent={completionMutation.mutateAsync}
        runCompletionSideEffects={onCompleteContent}
        contextId={completionContextId}
        trackId={trackId}
        journeyId={journeyId}
        journeyNavigateAction={journeyNavigateAction}
      />
    </>
  );
}

ContentWrapper.defaultProps = {
  contentId: null,
  selectorDisabled: false,
  trackId: null,
  journeyId: null,
  onCompleteContent: null,
  journeyNavigateAction: null,
  parentContextId: null,
}
