/* eslint react/jsx-props-no-spreading: 0 */

import { Button, Divider, Flex, Grid, GridItem, Text } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { Product, Purchase } from '@/client/services/api/graphql/gql/graphql';
import ChangeSubscriptionDescription from './ChangeSubscriptionDescription';
import ChangeSubscriptionPayment from './ChangeSubscriptionPayment';

type SummaryProps = {
  originalSubscription: Purchase;
  selectedProduct: Product;
  handleSubscriptionChange: () => void;
  newPaymentData?: {
    id: string;
    brand: string;
    lastFour: string;
  } | null;
}

export default function ChangeSubscriptionSummary({ originalSubscription, selectedProduct, handleSubscriptionChange, newPaymentData = null}: SummaryProps) {
  const { t } = useTranslation();

  const durationToDisplay: { [key: string]: string } = {
    MONTHLY: 'monthly',
    YEARLY: 'annually',
  };

  // TODO: See if Stripe has this for us, this might be an estimate
  const calculateProration = (purchaseDate: string, price: number, duration: string) => {
    // Calculate the end of the cycle from purchaseDate
    const start: any = new Date(purchaseDate);
    const cycleEnd: any = new Date(purchaseDate);
    // https://stackoverflow.com/questions/33070428/add-a-year-to-todays-date
    if (duration === 'MONTHLY') {
      cycleEnd.setMonth(cycleEnd.getMonth() + 1);
    }
    if (duration === 'YEARLY') {
      cycleEnd.setFullYear(cycleEnd.getFullYear() + 1);
    }

    // subtract today from that date
    // https://stackoverflow.com/questions/4944750/how-to-subtract-date-time-in-javascript
    const now: any = new Date();
    const difference = Math.abs(cycleEnd - now);

    // divide that number by the length of the cycle?
    const cycleLength = Math.abs(cycleEnd - start);
    const timeLeft = difference / cycleLength;

    // multiply price by that number
    const prorate = price * timeLeft;
    return prorate.toFixed(2);
  };

  return (

      <Flex
        direction="column"
        h="100%"
        borderColor="lightgray"
        border="1px solid"
        borderRadius={10}
        marginY={10}
        marginX="20%"
        paddingY={10}
        paddingX={16}
      >
        <Text fontWeight="600" textAlign="left" marginBottom={4}>
          {t('ecommerce.checkout.summary')}
        </Text>
        <Grid
          templateColumns="30% 70%"
          templateRows="25% 25% 25% 30%"
        >
          {/* Current Subscription Block */}
          <GridItem colStart={1} colSpan={2} rowStart={1} rowSpan={1}>
            <ChangeSubscriptionDescription 
              headline={t('ecommerce.subscriptionManagement.currentSubscription')}
              title={originalSubscription.productDetails.title}
              price={originalSubscription.productDetails.price}
              duration={durationToDisplay[originalSubscription.productDetails.subscriptionDuration!]}
              action={`${t('ecommerce.subscriptionManagement.actionCancel')} `}
            />
          </GridItem>
          {/* New Subscription Block */}
          <GridItem colStart={1} colSpan={2} rowStart={2} rowSpan={1}>
            <ChangeSubscriptionDescription 
              headline={t('ecommerce.subscriptionManagement.updatedSubscription')}
              title={selectedProduct?.title}
              price={selectedProduct?.price}
              duration={durationToDisplay[selectedProduct?.subscriptionDuration!]}
              action={t('ecommerce.subscriptionManagement.actionActivate')}
            />
          </GridItem>
          {/* Payment Method/Update Payment Block */}
          <GridItem colStart={1} colSpan={2} rowStart={3} rowSpan={1}>
            <ChangeSubscriptionPayment 
              cardBrand={newPaymentData?.brand || originalSubscription.cardDetails?.brand!}
              lastFour={newPaymentData?.lastFour || originalSubscription.cardDetails?.lastFour!}
              originalSubscriptionId={originalSubscription.id}
              newProductId={selectedProduct?.id}
            />
          </GridItem>
          {/* How It Works Block */}
          <GridItem colStart={1} colSpan={2} rowStart={4} rowSpan={1}>
            <Flex direction="row" gap="5%">
              <Text fontSize="sm" fontWeight="500" textAlign="left" width="150px">
                {t('ecommerce.checkout.howItWorks')}:
              </Text>
              <Flex direction="column" gap={2} width="70%">
                <Text fontSize="sm" textAlign="left">
                  {t('ecommerce.subscriptionManagement.changeSubscriptionHowItWorks1')}
                </Text>
                <Text fontSize="sm" textAlign="left" >
                  {t('ecommerce.subscriptionManagement.changeSubscriptionHowItWorks2', {
                    refund: `$${calculateProration(
                      originalSubscription.purchaseConfirmedDate,
                      originalSubscription.productDetails.price!,
                      originalSubscription.productDetails.subscriptionDuration!,
                    )}`,
                    cancelledSubscription:
                      originalSubscription.productDetails.title,
                  })}
                </Text>
                <Text fontSize="sm" textAlign="left">
                  {t('ecommerce.subscriptionManagement.changeSubscriptionHowItWorks3', {
                    newCharge: selectedProduct?.price,
                    duration: durationToDisplay[selectedProduct?.subscriptionDuration!],
                    newSubscription: selectedProduct?.title,
                  })}
                </Text>
              </Flex>
            </Flex>
            <Divider marginY={6} borderTop=".25px solid" opacity="20%"/>
          </GridItem>
        </Grid>
        <Button width="100%" marginTop={8} colorScheme="blue" type="submit" onClick={() => handleSubscriptionChange()}>
          {t('ecommerce.checkout.button.completeCheckout')}
        </Button>
      </Flex>
  );
}
