/* eslint-disable react/no-unstable-nested-components */
import { Button, Flex, Link } from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { Navigate, Link as RouterLink, useNavigate } from 'react-router-dom';
import { useMemo, useState } from 'react';

import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import AdminFeedsService from '@/client/services/api/admin/users/feeds/AdminFeedsService';
import { Feed } from '@/client/types/admin/user-manager/Feed';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { Plus } from '@/client/components/icons/ContinuIcons';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useAuthStore } from '@/client/services/state/authStore';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export default function SftpFeeds() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { isAllowed } = useAccessHelper();

  const allowance = {
    bulk_add: isAllowed(['admin', 'users', 'bulk_add'], null, ['admin', 'manager']),
  };

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data } = useQuery({
    queryKey: ['sftp-feeds ', fetchDataOptions],
    queryFn: () => AdminFeedsService.getFeeds(fetchDataOptions),
  });

  const columnHelper = createColumnHelper<Feed>();
  const columns = useMemo<ColumnDefBase<Feed, any>[]>(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Link as={RouterLink} to={info.row.original._id}>
            {info.getValue()}
          </Link>
        ),
        header: () => <span>{t('admin.users.sftpFeeds.feedName')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('origin', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('admin.users.sftpFeeds.origin')}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    [],
  );

  if (!company.feature_flags.enable_feeds) return <Navigate to="/explore" replace />;

  if (data?.rows.length === 1) return <Navigate to={`/admin/users/feeds/${data?.rows[0]._id}`} />;

  return (
    <AdminElevatedBox>
      {allowance.bulk_add && (
        <Flex justifyContent="space-between" marginBottom={8}>
          <Button
            variant="adminPrimary"
            size="xs"
            leftIcon={<Plus />}
            onClick={() => navigate('/admin/users/add/bulk')}
          >
            {t('admin.users.sftpFeeds.manuallyImportUsers')}
          </Button>
        </Flex>
      )}

      <ManuallyPaginatedTable
        columns={columns}
        queryData={data}
        pagination={pagination}
        setPagination={setPagination}
      />
    </AdminElevatedBox>
  );
}
