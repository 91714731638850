import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Link,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { Close, Hamburger } from '@/client/components/icons/ContinuIcons';
import { useEffect, useRef } from 'react';

import Logo from '@/client/components/media/images/Logo';
import { Link as RouterLink } from 'react-router-dom';
import SideNav from './SideNav';
import UserMenu from './UserMenu';

export default function MainNav() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const iconBtnRef = useRef<HTMLButtonElement>(null);

  const breakpoint = useBreakpointValue({ base: 'base', lg: 'lg' });

  useEffect(() => {
    if (breakpoint === 'lg') {
      onClose();
    }
  }, [breakpoint]);

  return (
    <Flex
      data-testid="main-nav"
      height="50px"
      alignItems="center"
      justifyContent="space-between"
      paddingX={4}
      borderBottom="1px"
      borderBottomColor="gray.100"
      backgroundColor="white"
      width="full"
      position="fixed"
      zIndex={100}
      id="mainNav"
    >
      <IconButton
        size="md"
        icon={isOpen ? <Close /> : <Hamburger />}
        aria-label="Open Menu"
        display={{ lg: 'none' }}
        onClick={isOpen ? onClose : onOpen}
        backgroundColor="transparent"
        color="blackAlpha.800"
        _hover={{
          backgroundColor: 'transparent',
          color: 'brand.primary',
        }}
        _active={{
          backgroundColor: 'transparent',
          color: 'brand.primary',
        }}
        ref={iconBtnRef}
      />

      <HStack spacing={10} alignItems="center" height="100%">
        <Link as={RouterLink} to="/explore">
          <Logo variant="explore" />
        </Link>
      </HStack>

      <UserMenu />

      <Drawer
        isOpen={isOpen}
        placement="left"
        variant="sidenav"
        onClose={onClose}
        finalFocusRef={iconBtnRef}
      >
        <DrawerOverlay />

        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <Box paddingBottom={4} display={{ lg: 'none' }}>
              <SideNav initialOpen />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
