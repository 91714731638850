/* eslint-disable react/jsx-props-no-spreading */
import { IconButton, IconButtonProps, useToken } from '@chakra-ui/react';

import { hexToRGBA } from '@/client/utils/hexToRGBA';

export default function OutlineIconButton(props: IconButtonProps) {
  const [brandButton] = useToken('colors', ['brand.button']);

  return (
    <IconButton
      {...props}
      border="2px solid"
      borderColor="brand.button"
      backgroundColor="transparent"
      color="brand.button"
      borderRadius="md"
      paddingY={4}
      paddingX={8}
      _hover={{ backgroundColor: hexToRGBA(brandButton, 0.1) }}
    />
  );
}
