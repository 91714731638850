/* eslint react/no-unstable-nested-components: 0 */

import {
  Badge,
  Box,
  Flex,
  IconButton,
  MenuList,
  Menu,
  MenuItem,
  MenuButton,
} from '@chakra-ui/react';
import { VerticalEllipsis, EditSimple, Close, Check } from '@/client/components/icons/ContinuIcons';

import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import TableActionsMenu from '@/client/components/admin/menus/TableActionsMenu';

import Loading from '@/client/components/media/Loading';
import PartnersManagerHeader from '@/client/components/layout/PartnersManagerHeader';
import PartnersService from '@/client/services/api/admin/PartnersService';
import { useNavigate } from 'react-router-dom';
import { useState, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { DateFormatGroupKey, dateFormatTenant } from '@/client/utils/dateFormat';
import { useAuthStore } from '@/client/services/state/authStore';
import { usePartnerStore } from '@/client/services/state/partnerStore';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { AssigneeGroup, Assignment } from '@/client/types/Assignment';
import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';

export default function PartnerManagerAssignmentsList() {
  useDocumentTitle('Assignments');
  const { partner } = usePartnerStore();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const getGroupsCell = (groups: AssigneeGroup[]) => {
    if (groups.length > 1) {
      const groupNames = groups.map((group: AssigneeGroup) => group.name);
      return groupNames.join(' | ');
    }
    if (!groups.length) {
      return '---';
    }
    return groups[0].name;
  };

  const getAssignedContentCell = (content: { content_id: { _id: string; title?: string, name?: string } }[]) => {
    const contentTitles = content.map(
      (assignment: { content_id: { _id: string; title?: string; name?: string } }) => assignment.content_id.title ?? assignment.content_id.name,
    );
    return contentTitles.join(' | ');
  };

  const getStatusCell = (completed: boolean, dueDate: string | undefined) => {
    const now = new Date();

    if (completed) {
      return (
        <Badge borderRadius="5px" variant="active" width="100%" padding={1} textAlign="center">
          Completed
        </Badge>
      );
    }
    if (!completed && dueDate && now > new Date(dueDate)) {
      return (
        <Badge borderRadius="5px" variant="inactive" width="100%" padding={1} textAlign="center">
          Past Due
        </Badge>
      );
    }
    return (
      <Badge borderRadius="5px" variant="pending" width="100%" padding={1} textAlign="center">
        Pending Completion
      </Badge>
    );
  };

  const { data, isLoading } = useQuery({
    queryKey: ['partner-assignments', fetchDataOptions, partner._id],
    queryFn: () => PartnersService.getAllPartnerAssignments(fetchDataOptions, partner._id),
    enabled: !!partner._id,
  });

  // FIX TYPES
  const columnHelper = createColumnHelper<Assignment>();
  const usersColumns = useMemo<ColumnDefBase<Assignment, any>[]>(
    () => [
      columnHelper.accessor('assignee.full_name', {
        cell: (info) => (
          <Flex alignItems="center" marginLeft={9}>
            {info.getValue()}
          </Flex>
        ),
        header: () => (
          <Box as="span" marginLeft={9}>
            {t('global.forms.labels_name')}
          </Box>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('assignee.groups', {
        cell: (info) => (
          <Box overflow="hidden" textOverflow="ellipsis">
            {getGroupsCell(info.getValue())}
          </Box>
        ),
        header: () => <span>Groups</span>,
        footer: (info) => info.column.id,
        size: 2,
      }),
      columnHelper.accessor('assigned_content', {
        cell: (info) => <span>{getAssignedContentCell(info.getValue())}</span>,
        header: () => <span>Content</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('completed', {
        cell: (info) => <span>{getStatusCell(info.getValue(), info.row.original.due_date)}</span>,
        header: () => <span>Status</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => (
          <span>
            {info.getValue()
              ? dateFormatTenant(
                  new Date(info.getValue()),
                  company.date_display as DateFormatGroupKey,
                  'base',
                )
              : '-----'}
          </span>
        ),

        header: () => <span>Assigned Date</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('due_date', {
        cell: (info) => (
          <span>
            {info.getValue()
              ? dateFormatTenant(
                  new Date(info.getValue()),
                  company.date_display as DateFormatGroupKey,
                  'base',
                )
              : '-----'}
          </span>
        ),
        header: () => <span>Due Date</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('completed_date', {
        cell: (info) => (
          <span>
            {info.getValue()
              ? dateFormatTenant(
                  new Date(info.getValue()),
                  company.date_display as DateFormatGroupKey,
                  'base',
                )
              : '-----'}
          </span>
        ),
        header: () => <span>Completion Date</span>,
        footer: (info) => info.column.id,
      }),
    ],
    [],
  );

  if (isLoading) return <Loading />;

  return (
    <Box minHeight="100vh" paddingTop={2} backgroundColor="brand.mist">
      <PartnersManagerHeader currentTab="assignments" />
      <AdminElevatedBox>
        <ManuallyPaginatedTable
          columns={usersColumns}
          queryData={data}
          pagination={pagination}
          setPagination={setPagination}
        />
      </AdminElevatedBox>
    </Box>
  );
}
