/* eslint-disable react/no-unstable-nested-components */

import { Box, Text } from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { format, utcToZonedTime } from 'date-fns-tz';
import { useMemo, useState } from 'react';

import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { UserManagerWorkshop } from '@/client/types/admin/user-manager/UserManagerWorkshop';
import { learnApiClient } from '@/client/services/api/clients/learnApiClient';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

const getUserManagerWorkshops = async (
  options: { pageIndex: number; pageSize: number },
  userId: string | undefined,
): Promise<{ rows: UserManagerWorkshop[]; pageCount: number }> => {
  const response = await learnApiClient.get<UserManagerWorkshop[]>(`user-workshops`, {
    params: {
      active_status: '$ne,removed',
      fields: 'user,active_status,workshop_instance,workshop,status_changed',
      page: options.pageIndex + 1,
      per_page: options.pageSize,
      sort: 'status_changed,-1',
      user: userId,
      'workshop-populate': 'title',
      'workshop_instance-populate': 'start_date,viewing_timezone',
    },
  });

  const count = await learnApiClient.get<{ count: number }>(`user-workshops/count?user=${userId}`);

  return {
    rows: response.data,
    pageCount: Math.ceil(count.data.count / options.pageSize) || 1,
  };
};

export default function UserWorkshops() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data } = useQuery({
    queryKey: ['user-manager-workshops', fetchDataOptions, id],
    queryFn: () => getUserManagerWorkshops(fetchDataOptions, id),
    select: (workshopData) => ({
      rows: workshopData.rows.filter((row) => row.workshop_instance !== null),
      pageCount: workshopData.pageCount,
    }),
  });

  const formatDateToTimeZone = (dateString: string, timezone: string) => {
    const date = new Date(dateString);
    const zonedDate = utcToZonedTime(date, timezone);

    const pattern = 'EEEE, MMMM dd, yyyy (h:mm a z)';

    return format(zonedDate, pattern, { timeZone: timezone });
  };

  const columnHelper = createColumnHelper<UserManagerWorkshop>();
  const columns = useMemo<ColumnDefBase<UserManagerWorkshop, any>[]>(
    () => [
      columnHelper.accessor('workshop.title', {
        cell: (info) => <Text>{info.getValue()}</Text>,
        header: () => <span>{t('manage.users.workshops.title')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('workshop_instance.start_date', {
        cell: (info) => (
          <Text>
            {formatDateToTimeZone(
              info.row.original.workshop_instance.start_date,
              info.row.original.workshop_instance.viewing_timezone,
            )}
          </Text>
        ),
        header: () => <span>{t('manage.users.workshops.dateTime')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('active_status', {
        cell: (info) => <Text textTransform="capitalize">{info.getValue()}</Text>,
        header: () => <span>{t('manage.users.workshops.status')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status_changed', {
        cell: (info) => (
          <Text>
            {formatDateToTimeZone(
              info.getValue(),
              info.row.original.workshop_instance.viewing_timezone,
            )}
          </Text>
        ),
        header: () => <span>{t('manage.users.workshops.lastActivity')}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    [],
  );

  return (
    <Box
      marginX={4}
      marginY={1}
      paddingX={6}
      paddingY={2}
      borderRadius="md"
      backgroundColor="white"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1);"
    >
      <ManuallyPaginatedTable
        columns={columns}
        queryData={data}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Box>
  );
}
