import { Button, Stack } from '@chakra-ui/react';

import Certificate from '@/client/components/data-display/certificates/Certificate';
import CreateFormGroup from '@/client/components/admin/create/shared/layout/CreateFormGroup';
import CreateInput from '@/client/components/admin/create/shared/input/CreateInput';
import CreateSwitchContainer from '@/client/components/admin/create/shared/layout/CreateSwitchContainer';
import { useAuthStore } from '@/client/services/state/authStore';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ContentCertificateForm() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { watch } = useFormContext();
  const [viewingCertificate, setViewingCertificate] = useState(false);

  const certificateEnabled = watch('certificate');
  const contentTitle = watch('title');
  const altTitle = watch('certificate_alternate_title');
  const hideDate = watch('certificate_hide_completion_date');
  const userFullName = user.full_name;
  const completedDate = new Date();

  return (
    <CreateFormGroup>
      <Stack spacing={6}>
        <CreateSwitchContainer
          name="certificate"
          label={t('authoring.certificate.enableCertificate')}
          helpText={t('authoring.certificate.enableCertificate.helpText')}
        />

        {certificateEnabled && (
          <Stack spacing={6}>
            <CreateSwitchContainer
              name="certificate_hide_completion_date"
              label={t('authoring.certificate.hideCompletionDate')}
            />

            <Stack spacing={0}>
              <CreateInput
                isRequired={false}
                variant="create"
                name="certificate_alternate_title"
                placeholder={t('authoring.certificate.altTitlePlaceholder')}
              />

              <Button
                variant="ghost"
                textAlign="left"
                justifyContent="start"
                padding={0}
                margin={0}
                color="brand.gold.100"
                _hover={{
                  textDecoration: 'underline',
                  color: 'brand.gold.100',
                }}
                onClick={() => setViewingCertificate(true)}
              >
                {t('authoring.certificate.preview')}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>

      {viewingCertificate && (
        <Certificate
          contentTitle={altTitle || contentTitle}
          userFullName={userFullName}
          completedDate={completedDate}
          userMetadata={user.metadata}
          contentMetadata={{}}
          certificateData={[{ name: 'hide_completion', value: hideDate }]}
          onClose={() => setViewingCertificate(false)}
        />
      )}
    </CreateFormGroup>
  );
}
