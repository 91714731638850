import { Category } from '@/client/types/Category';
import { categoryLegacyName } from '@/client/utils/categoryLegacyName';
import { learnApiClient } from './clients/learnApiClient';

const getCategoriesById = async (categoryIds: string[]) => {
  if (categoryIds.length === 0) {
    return [];
  }

  const response = await learnApiClient.post<Category[]>(`categories/multiple-get`, {
    _ids: categoryIds,
  });

  response.data.forEach((category) => categoryLegacyName(category));

  return response.data;
};

const searchCategories = async (searchTerm: string, categoriesOnly: boolean = false) => {
  const withType = (withTypeData: Category[], dataType: string) =>
    withTypeData.map((item) => ({ ...item, type: dataType }));

  const response = await learnApiClient.post<Category[]>(`categories/search`, {
    ignoreSelections: false,
    term: searchTerm,
    types: categoriesOnly ? 'categories' : 'locations,departments,teams,org_levels,grades',
  });

  const locations: Category[] = [];
  const departments: Category[] = [];
  const teams: Category[] = [];
  const orgLevels: Category[] = [];
  const grades: Category[] = [];
  const categories: Category[] = [];

  response.data.forEach((category) => {
    if (!categoriesOnly) {
      if (category.location) {
        locations.push({ ...category, type: 'location' });
        return;
      }
      if (category.department) {
        departments.push({ ...category, type: 'department' });
        return;
      }
      if (category.team) {
        teams.push({ ...category, type: 'team' });
        return;
      }
      if (category.org_level) {
        orgLevels.push({ ...category, type: 'orgLevel' });
        return;
      }
      if (category.grade) {
        grades.push({ ...category, type: 'grade' });
        return;
      }
    }

    if (categoriesOnly) {
      categories.push({ ...category, type: 'category' });
    }
  });

  if (!categoriesOnly) {
    locations.forEach((location) => categoryLegacyName(location));
    departments.forEach((department) => categoryLegacyName(department));
    teams.forEach((team) => categoryLegacyName(team));
    orgLevels.forEach((orgLevel) => categoryLegacyName(orgLevel));
    grades.forEach((grade) => categoryLegacyName(grade));
  }

  if (categoriesOnly) {
    categories.forEach((category) => categoryLegacyName(category));
  }

  return categoriesOnly
    ? [{ title: 'Categories', options: withType(categories, 'category') }]
    : [
        {
          title: 'Locations',
          options: withType(locations, 'location'),
        },
        { title: 'Departments', options: withType(departments, 'department') },
        { title: 'Teams', options: withType(teams, 'team') },
        { title: 'Org Levels', options: withType(orgLevels, 'orgLevel') },

        { title: 'Grades', options: withType(grades, 'grade') },
      ];
};

const CategoriesService = {
  getCategoriesById,
  searchCategories,
};

export default CategoriesService;
