/* eslint-disable react/jsx-props-no-spreading */
import Combobox from './Combobox';

interface TableComboboxProps {
  items: any;
  selectedItems: any[];
  setSelectedItems: any;
  searchTerm: string;
  setSearchTerm: any;
  isLoading?: boolean;
}

export default function TableCombobox({
  items,
  selectedItems,
  setSelectedItems,
  searchTerm,
  setSearchTerm,
  isLoading,
}: TableComboboxProps) {


  const tableCssOptions = {
    input: {
      borderRadius: '5px',
      color: 'brand.grey.100',
      border: 'none',
      _placeholder: { color: 'brand.grey.60' },
    },
    inputGroup: {
      size: 'sm',
      backgroundColor: 'brand.backgroundGrey',
      borderRadius: '5px',
    },
  };

  return (
    <Combobox
      items={items}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      isLoading={isLoading}
      positionAbsolute
      inputCssOptions={tableCssOptions.input}
      inputGroupCssOptions={tableCssOptions.inputGroup}
      width="40%"
      border=".5 solid"
      borderColor="brand.backgroundGrey"
      showQuestionIcon
    />
  );
}

TableCombobox.defaultProps = {
  isLoading: false,
};
