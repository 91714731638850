import { differenceInCalendarDays, format, getUnixTime, startOfDay, subDays } from "date-fns";

import { Doc } from '@/client/types/admin/reports/ReportData';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useReportTypes } from './useReportTypes';
import { useReportsService } from './useReportsService';
import { useState } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

// TODO: Handle custom dates

export const useGenerateReport = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const { reportTypes } = useReportTypes();
  const { generateReportMutation } = useReportsService();
  const [showChooseAtLeastOneTrack, setShowChooseAtLeastOneTrack] = useState(false);
  const [showChooseAtLeastOneContent, setShowChooseAtLeastOneContent] = useState(false);
  const [generateReportIsLoading, setGenerateReportIsLoading] = useState(false);

  const generateReport = (
    report: Doc,
    pastDays: number,
    reportArguments?: any,
    customStartDate?: Date,
    customEndDate?: Date,
  ) => {
    try {
      setGenerateReportIsLoading(true);

      const reportType = report.report_type;

      let fd = subDays(new Date(), pastDays);

      let ud = new Date();

      // ! All time
      if (pastDays === 100) {
        fd = new Date(report.first_date);
      }

      // ! Last Year
      if (pastDays === 120) {
        fd = new Date(report.start_of_last_year);
        ud = new Date(report.end_of_last_year);
      }

      // Start custom datepicker
      if (pastDays === 110) {
        if (customStartDate && customEndDate) {
          fd = new Date(customStartDate);
          ud = new Date(customEndDate);

          if (fd.getTime() === ud.getTime()) {
            setToast({
              show: true,
              status: 'info',
              title: t('precooked.date_range_same'),
            });

            setGenerateReportIsLoading(false);
            return;
          }
        }
      }
      // end custom datepicker

      // @ts-ignore
      const reportTypeConfig = reportTypes[report.report_type];
      const reportRequest = JSON.parse(JSON.stringify(reportTypeConfig.defaultFilters));
      const customFields: string[] = [];

      report.fields.forEach((report_field) => {
        if (report_field.includes('userMetadata')) {
          customFields.push(report_field.split('_')[1]);
        } else {
          reportRequest[report_field] = true;
        }
      });

      if (customFields.length) {
        switch (report.report_type) {
          case 'assignment-summary':
            reportRequest.assignee_metadata = customFields;
            break;
          default:
            reportRequest.user_metadata = customFields;
            break;
        }
      }

      report.filters.forEach((reportFilter) => {
        const filterConfig =
          reportTypeConfig.filterTypeToReportFilterConfig[
            reportFilter.value_sub_type || reportFilter.value_type
          ];

        if (!(filterConfig.reportsGroup in reportRequest)) {
          reportRequest[filterConfig.reportsGroup] = {};
        }

        if (!(filterConfig.reportsGroupKey in reportRequest[filterConfig.reportsGroup])) {
          reportRequest[filterConfig.reportsGroup][filterConfig.reportsGroupKey] = '';
        }

        if (reportRequest[filterConfig.reportsGroup][filterConfig.reportsGroupKey].length !== 0) {
          reportRequest[filterConfig.reportsGroup][filterConfig.reportsGroupKey] += ',';
        }

        reportRequest[filterConfig.reportsGroup][filterConfig.reportsGroupKey] +=
          reportFilter.value.join(',');
      });

      if (!report.isStandard) {
        if (
          // eslint-disable-next-line no-prototype-builtins
          reportRequest.user_filter?.hasOwnProperty('suspended') &&
          // eslint-disable-next-line no-prototype-builtins
          report.options.hasOwnProperty('includeSuspendedUsers')
        ) {
          reportRequest.user_filter.suspended = report.options.includeSuspendedUsers ? '' : 'false';
        }
      }

      if (report.isStandard) {
        if (reportArguments.query.users && reportArguments.query.users.length) {
          if (reportRequest.user_filter) {
            reportRequest.user_filter.id = reportArguments.query.users.join(',');
          }

          if (reportRequest.manager_filter) {
            reportRequest.manager_filter.id = reportArguments.query.users.join(',');
          }
        }

        if (reportArguments.query.locations && reportArguments.query.locations.length) {
          if (reportRequest.user_filter) {
            reportRequest.user_filter.locations = reportArguments.query.locations.join(',');
          }

          if (reportRequest.manager_filter) {
            reportRequest.manager_filter.locations = reportArguments.query.locations.join(',');
          }

          if (reportRequest.content_filter) {
            reportRequest.content_filter.locations = reportArguments.query.locations.join(',');
          }

          if (reportRequest.track_filter) {
            reportRequest.track_filter.locations = reportArguments.query.locations.join(',');
          }
        }

        if (reportArguments.query.departments && reportArguments.query.departments.length) {
          if (reportRequest.user_filter) {
            reportRequest.user_filter.departments = reportArguments.query.departments.join(',');
          }

          if (reportRequest.manager_filter) {
            reportRequest.manager_filter.departments = reportArguments.query.departments.join(',');
          }

          if (reportRequest.content_filter) {
            reportRequest.content_filter.departments = reportArguments.query.departments.join(',');
          }

          if (reportRequest.track_filter) {
            reportRequest.track_filter.departments = reportArguments.query.departments.join(',');
          }
        }

        if (reportArguments.query.teams && reportArguments.query.teams.length) {
          if (reportRequest.user_filter) {
            reportRequest.user_filter.teams = reportArguments.query.teams.join(',');
          }

          if (reportRequest.manager_filter) {
            reportRequest.manager_filter.teams = reportArguments.query.teams.join(',');
          }

          if (reportRequest.content_filter) {
            reportRequest.content_filter.teams = reportArguments.query.teams.join(',');
          }

          if (reportRequest.track_filter) {
            reportRequest.track_filter.teams = reportArguments.query.teams.join(',');
          }
        }

        if (reportArguments.query.org_levels && reportArguments.query.org_levels.length) {
          if (reportRequest.user_filter) {
            reportRequest.user_filter.org_levels = reportArguments.query.org_levels.join(',');
          }

          if (reportRequest.manager_filter) {
            reportRequest.manager_filter.org_levels = reportArguments.query.org_levels.join(',');
          }

          if (reportRequest.content_filter) {
            reportRequest.content_filter.org_levels = reportArguments.query.org_levels.join(',');
          }

          if (reportRequest.track_filter) {
            reportRequest.track_filter.org_levels = reportArguments.query.org_levels.join(',');
          }
        }

        if (reportArguments.query.grades && reportArguments.query.grades.length) {
          if (reportRequest.user_filter) {
            reportRequest.user_filter.grades = reportArguments.query.grades.join(',');
          }

          if (reportRequest.manager_filter) {
            reportRequest.manager_filter.grades = reportArguments.query.grades.join(',');
          }

          if (reportRequest.content_filter) {
            reportRequest.content_filter.grades = reportArguments.query.grades.join(',');
          }

          if (reportRequest.track_filter) {
            reportRequest.track_filter.grades = reportArguments.query.grades.join(',');
          }
        }

        if (reportArguments.query.content && reportArguments.query.content.length) {
          if (reportRequest.content_filter) {
            reportRequest.content_filter.content_id = reportArguments.query.content.join(',');
          }

          if (reportRequest.assignment_filter) {
            reportRequest.assignment_filter.content_id = reportArguments.query.content.join(',');
          }

          if (reportRequest.assessment_filter) {
            reportRequest.assessment_filter.content_id = reportArguments.query.content.join(',');
          }

          if (reportRequest.workshop_filter) {
            reportRequest.workshop_filter.content_id = reportArguments.query.content.join(',');
          }

          if (reportRequest.scorm_filter) {
            reportRequest.scorm_filter.content_id = reportArguments.query.content.join(',');
          }
        }

        if (reportArguments.query.tracks && reportArguments.query.tracks.length) {
          if (reportRequest.track_filter) {
            reportRequest.track_filter.content_id = reportArguments.query.tracks.join(',');
          }
        }
      }

      const numberOfDaysInRange = differenceInCalendarDays(ud, fd);

      if (
        reportType === 'track-status' &&
        (!reportRequest.track_filter || !reportRequest.track_filter.content_id)
      ) {
        setToast({
          show: true,
          status: 'error',
          title: t('precooked.choose_at_least_one_track'),
        });

        setGenerateReportIsLoading(false);
        return;
      }
      if (
        numberOfDaysInRange > 366 &&
        reportType === 'assignment-summary' &&
        (!reportRequest.assignment_filter || !reportRequest.assignment_filter.content_id)
      ) {
        setToast({
          show: true,
          status: 'error',
          title: t('precooked.choose_at_least_one_content'),
        });

        setGenerateReportIsLoading(false);
        return;
      }

      if (
        reportType === 'track-status' &&
        (!reportRequest.track_filter || !reportRequest.track_filter.content_id)
      ) {
        setShowChooseAtLeastOneTrack(true);

        setGenerateReportIsLoading(false);
        return;
      }

      if (
        numberOfDaysInRange > 366 &&
        reportType === 'assignment-summary' &&
        (!reportRequest.assignment_filter || !reportRequest.assignment_filter.content_id)
      ) {
        setShowChooseAtLeastOneContent(true);

        setGenerateReportIsLoading(false);
        return;
      }

      setGenerateReportIsLoading(true);

      const reportDetails: any = { report: reportType };

      if (reportTypeConfig.applyDateRange === true) {
        // set start of day
        reportDetails.from = getUnixTime(startOfDay(fd));
        reportDetails.until = getUnixTime(ud);
      } else {
        setToast({
          show: true,
          status: 'info',
          title: t('precooked.date_range_info'),
        });
      }

      generateReportMutation
        .mutateAsync({ reportDetails, reportRequest })
        .then((result: any) => {
          if (result.status === 404) {
            setToast({
              show: true,
              status: 'error',
              title: t('precooked.no_data_for_report'),
            });

            setGenerateReportIsLoading(false);
            return;
          }
          if (result.status === 422) {
            setToast({
              show: true,
              status: 'error',
              title: t('precooked.could_not_build'),
            });

            setGenerateReportIsLoading(false);
            return;
          }
          if (result.length === 0) {
            setToast({
              show: true,
              status: 'error',
              title: t('precooked.could_not_build'),
            });

            setGenerateReportIsLoading(false);
            return;
          }

          const csv = report.csv(result);

          try {
            const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' });

            window.URL = window.webkitURL || window.URL;

            const anchor = window.document.createElement('a');
            anchor.href = window.URL.createObjectURL(blob);
            anchor.download =
              report.name +
              '-report-' +
              format(fd, 'yyyyMMdd') +
              '-' +
              format(ud, 'yyyyMMdd') +
              '.csv';

            window.document.body.appendChild(anchor); // For FF
            anchor.target = '_blank';
            anchor.click();
            // It's better to remove the elem
            window.document.body.removeChild(anchor);

            trackEvent('report_downloaded', {
              content: report.name,
              content_type: reportType,
            });
          } catch (e) {
            console.log('Error downloading:', e);
          }
        })
        .catch((error) => {
          console.log('Error calling report resource', error);

          setToast({
            show: true,
            status: 'error',
            title: t('precooked.could_not_download'),
          });

          setGenerateReportIsLoading(false);
        })
        .finally(() => {
          setGenerateReportIsLoading(false);
        });
    } catch (e) {
      console.log(e);

      setGenerateReportIsLoading(false);

      setToast({
        show: true,
        status: 'error',
        title: t('precooked.could_not_download'),
      });
    }
  };

  return {
    generateReport,
    showChooseAtLeastOneTrack,
    showChooseAtLeastOneContent,
    generateReportIsLoading,
  };
};
