import { Flex, Stack, useToken } from '@chakra-ui/react';

import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function SampleHighlights({ overrideColor }: { overrideColor: string }) {
  const { t } = useTranslation();
  const [brandPrimary] = useToken('colors', ['brand.primary']);
  const [selectedItem, setSelectedItem] = useState(1);

  const items = [
    {
      id: 1,
      title: t('admin.theme.samples.highlights.itemOne'),
    },
    {
      id: 2,
      title: t('admin.theme.samples.highlights.itemTwo'),
    },
    {
      id: 3,
      title: t('admin.theme.samples.highlights.itemThree'),
    },
  ];

  return (
    <Stack spacing={0} width="xs">
      {items.map((item) => (
        <Flex
          key={item.id}
          padding={4}
          alignItems="center"
          borderBottom="1px solid"
          fontSize="sm"
          fontWeight="bold"
          backgroundColor={
            selectedItem === item.id ? hexToRGBA(overrideColor || brandPrimary, 0.2) : 'white'
          }
          _hover={{
            backgroundColor: hexToRGBA(overrideColor || brandPrimary, 0.05),
            cursor: 'pointer',
          }}
          onClick={() => setSelectedItem(item.id)}
        >
          {item.title}
        </Flex>
      ))}
    </Stack>
  );
}
