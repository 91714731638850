import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Input,
  Link,
  Progress,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Delete, Download, Upload } from '@/client/components/icons/ContinuIcons';
import { useRef, useState } from 'react';

import AuthoringVideoService from '@/client/services/api/admin/content-authoring/AuthoringVideoService';
import CreateFormGroup from '@/client/components/admin/create/shared/layout/CreateFormGroup';
import type { VttFile } from '@/client/services/api/graphql/gql/graphql';
import { useConfigStore } from '@/client/services/state/configStore';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export default function VttUploadForm() {
  const { watch, setValue } = useFormContext();
  const { locales } = useConfigStore();
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedLocale, setSelectedLocale] = useState<string | null>(null);

  const vttFiles = watch('vttFiles');

  const selectedLocales = vttFiles.map((file: VttFile) => file.locale);

  const filteredLocales = locales.filter((locale) => !selectedLocales.includes(locale.value));

  interface UploadVttMutationVariables {
    file: File;
    progressCallback: (progress: number) => void;
  }

  const uploadVttMutation = useMutation({
    mutationFn: (variables: UploadVttMutationVariables) =>
      AuthoringVideoService.uploadVtt(variables.file, variables.progressCallback),
    onSuccess: () => {
      setToast({ show: true, status: 'success', title: t('authoring.video.vtt.uploadSuccess') });
    },
    onError: () => {
      setToast({ show: true, status: 'error', title: t('authoring.video.vtt.uploadError') });
    },
  });

  const { status, data, reset } = uploadVttMutation;

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileObject;

    if (event.target.files && event.target.files.length > 0) {
      fileObject = event.target.files?.[0];
    }

    if (!fileObject) {
      setToast({ show: true, status: 'error', title: t('authoring.video.vtt.uploadError') });
      return;
    }

    if (fileObject.size > 10000000) {
      setToast({
        show: true,
        status: 'error',
        title: t('edit.videos.vtt_limit'),
      });

      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    uploadVttMutation.mutateAsync({ file: fileObject, progressCallback: setUploadProgress });
  };

  const handleUploadClick = () => (fileInputRef.current ? fileInputRef.current.click() : null);

  return (
    <CreateFormGroup renderBorder={false}>
      <Stack>
        <Text variant="createLabel">{t('authoring.video.vtt.closedCaptioning')}</Text>

        <Text variant="createHelpText">{t('authoring.video.vtt.closedCaptioning.helpText')}</Text>

        {!data && (
          <>
            {status !== 'loading' && (
              <>
                <Button
                  marginTop={4}
                  variant="createIconSolid"
                  maxWidth={36}
                  leftIcon={<Upload />}
                  onClick={handleUploadClick}
                >
                  {t('authoring.video.vtt.uploadFile')}
                </Button>

                <Input
                  display="none"
                  ref={fileInputRef}
                  type="file"
                  accept=".vtt"
                  onChange={(e) => handleFileChange(e)}
                />
              </>
            )}

            {status === 'loading' && (
              <Stack marginTop={4}>
                <Text variant="createLabel">{t('authoring.video.vtt.uploading')}</Text>

                <Progress variant="create" value={uploadProgress} width="full" />
              </Stack>
            )}
          </>
        )}

        {data && (
          <HStack marginTop={4}>
            <Select
              variant="create"
              placeholder={`${t('authoring.video.vtt.localePlaceholder')}`}
              onChange={(e) => setSelectedLocale(e.target.value)}
            >
              {filteredLocales.map((locale) => (
                <option key={locale.value} value={locale.value}>
                  {locale.name}
                </option>
              ))}
            </Select>

            <ButtonGroup>
              <Button
                variant="createIconSolid"
                isDisabled={!selectedLocale}
                onClick={() => {
                  setValue('vttFiles', [
                    ...vttFiles,
                    {
                      kind: 'captions',
                      locale: selectedLocale,
                      url: data.cloudfrontLink,
                    },
                  ]);

                  setSelectedLocale(null);
                  reset();
                }}
              >
                {t('authoring.video.vtt.addToVideo')}
              </Button>

              <Button variant="createIconSolid" onClick={reset}>
                {t('authoring.video.vtt.cancel')}
              </Button>
            </ButtonGroup>
          </HStack>
        )}

        {vttFiles.length > 0 && (
          <Box
            marginTop={4}
            borderRadius="md"
            backgroundColor="brand.gold.10"
            border="1px solid"
            borderColor="brand.gold.100"
            padding={4}
          >
            <Stack>
              {vttFiles.map((file: VttFile) => (
                <HStack key={file.url} alignContent="center" justifyContent="space-between">
                  <Text variant="createLabel">
                    {locales.find((locale) => locale.value === file.locale)?.name}
                  </Text>

                  <ButtonGroup size="xs">
                    <Button variant="createIconOutline" leftIcon={<Download />}>
                      <Link
                        href={file.url as string}
                        target="_blank"
                        download={file.url}
                        color="inherit"
                        _hover={{ color: 'inherit' }}
                      >
                        {t('authoring.video.vtt.download')}
                      </Link>
                    </Button>

                    <Button
                      variant="createIconOutline"
                      leftIcon={<Delete />}
                      onClick={() =>
                        setValue(
                          'vttFiles',
                          vttFiles.filter((existingFile: VttFile) => existingFile.url !== file.url),
                        )
                      }
                    >
                      {t('authoring.video.vtt.remove')}
                    </Button>
                  </ButtonGroup>
                </HStack>
              ))}
            </Stack>
          </Box>
        )}
      </Stack>
    </CreateFormGroup>
  );
}
