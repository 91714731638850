import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { useTheme, useToken } from '@chakra-ui/react';

import { Doughnut } from 'react-chartjs-2';
import Loading from '@/client/components/media/Loading';

ChartJS.register(ArcElement, Tooltip, Legend);

type DoughnutChartProps = {
  labelOne: string;
  labelTwo: string;
  valueOne: number;
  valueTwo: number;
  isLoading: boolean;
  updateMode?: 'resize' | 'reset' | 'none' | 'hide' | 'show' | 'normal' | 'active' | undefined;
};

export default function DoughnutChart({
  labelOne,
  labelTwo,
  valueOne,
  valueTwo,
  isLoading,
  updateMode,
}: DoughnutChartProps) {
  const theme = useTheme();

  const [brandSecondary, gray200] = useToken('colors', ['brand.secondary', 'brand.gray.200']);

  const data = {
    labels: [`  ${labelOne}`, `  ${labelTwo}`],
    datasets: [
      {
        data: [valueOne, valueTwo],
        backgroundColor: [gray200, brandSecondary],
        borderWidth: 3,
        radius: '70%',
        options: {
          animation: {
            animateRotate: true,
          },
        },
      },
    ],
  };

  if (isLoading) return <Loading />;

  return (
    <Doughnut
      data={data}
      updateMode={updateMode}
      style={{
        maxWidth: '100%',
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
    />
  );
}
