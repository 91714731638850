/* eslint-disable react/jsx-props-no-spreading */
import { Controller, useFormContext } from 'react-hook-form';
import {
  NumberInput,
  InputGroup,
  Stack,
  NumberInputField,
} from '@chakra-ui/react';
import FormLabelWithTooltip from './FormLabelWithTooltip';
import { useTranslation } from 'react-i18next';

interface NumberInputWithToolTipProps {
  name: string;
  label: string;
  tooltipText: string;
  isRequired?: boolean;
  precision?: number;
}

export default function NumberInputWithToolTip({
  name,
  label,
  tooltipText,
  isRequired,
  precision,
}: NumberInputWithToolTipProps) {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <FormLabelWithTooltip label={label} isRequired={isRequired} tooltipText={tooltipText} />

      <InputGroup>
        <Controller
          name={name}
          control={control}
          rules={{
            required: { value: isRequired || false, message: t('global.forms.field_required') },
          }}
          render={({ field }) => (
            <NumberInput
              {...field}
              variant="adminInput"
              precision={precision}
            >
              <NumberInputField />
            </NumberInput>
          )}
        />
      </InputGroup>
    </Stack>
  );
}

NumberInputWithToolTip.defaultProps = {
  isRequired: false,
  precision: 0,
};
