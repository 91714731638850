import { Box, Flex, GridItem, Tooltip } from '@chakra-ui/react';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Range } from 'react-calendar/dist/cjs/shared/types';
import 'react-calendar/dist/Calendar.css';
import { CalendarWeek, QuestionCircle } from '../../icons/ContinuIcons';
import { ReactElement } from 'react';

type ChartHeaderProps = {
  showDatePicker: boolean;
  dateRange?: string | Date | Range<any>;
  setDateRange?: (value: any) => void;
  dateLocale?: any;
  tooltip: string;
  title: string;
  colSpan: number;
  rowSpan: number;
  children: ReactElement;
};

export function ChartWrapper({
  showDatePicker,
  dateRange,
  setDateRange,
  dateLocale,
  tooltip,
  title,
  colSpan,
  rowSpan,
  children,
}: ChartHeaderProps) {
  return (
    <GridItem
      padding={10}
      paddingTop={4}
      backgroundColor="white"
      marginRight={6}
      marginLeft={{ base: 6, lg: 0 }}
      borderRadius="10px"
      border="black 1px solid"
      boxShadow="0 2px 2px rgba(0,0,0,0.1)"
      colSpan={colSpan}
      rowSpan={rowSpan}
    >
      <Flex direction="row" justifyContent="space-between" width="100%">
        <Box fontWeight={500}>
          {title}
          <Tooltip label={tooltip}>
            <QuestionCircle marginLeft={2} />
          </Tooltip>
        </Box>
        {showDatePicker && (
          <DateRangePicker
            required
            onChange={setDateRange}
            value={dateRange}
            calendarIcon={<CalendarWeek />}
            locale={dateLocale}
            clearIcon={null}
            showFixedNumberOfWeeks
          />
        )}
      </Flex>
      {children}
    </GridItem>
  );
}

ChartWrapper.defaultProps = {
  dateRange: null,
  setDateRange: null,
  dateLocale: null,
};
