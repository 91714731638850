import { Controller, useFormContext } from 'react-hook-form';
import { OptionBase, Select } from 'chakra-react-select';

import FormLabelWithTooltip from '../admin/forms/FormLabelWithTooltip';
import { Stack } from '@chakra-ui/react';

interface Option extends OptionBase {
  readonly value: string;
  readonly label: string;
}

interface InputProps {
  label: string;
  name: string;
  tooltipText: string;
  placeholder: string;
  isMultiple: boolean;
  isRequired?: boolean;
  options: Option[];
}

export default function SearchableDropdownInput({
  label,
  name,
  tooltipText,
  placeholder,
  isMultiple,
  options,
  isRequired,
}: InputProps) {
  const { control } = useFormContext();
  return (
    <Stack spacing={2}>
      <FormLabelWithTooltip label={label} tooltipText={tooltipText} isRequired={isRequired} />

      <Controller
        name={name}
        control={control}
        rules={{
          required: false,
        }}
        render={({ field: { onChange, value } }) => (
          <Select
            isSearchable
            options={options}
            isMulti={isMultiple}
            value={
              isMultiple
                ? options.filter((option: Option) => value?.includes(option.value))
                : options.find((option: Option) => option.value === value)
            }
            menuPortalTarget={document.body}
            placeholder={placeholder}
            /* @ts-ignore */
            onChange={(newValue: Option[] | Option) =>
              isMultiple
                ? /* @ts-ignore */
                  onChange(newValue?.map((option: Option) => option.value))
                : /* @ts-ignore */
                  onChange(newValue?.value)
            }
          />
        )}
      />
    </Stack>
  );
}

SearchableDropdownInput.defaultProps = {
  isRequired: false,
};
