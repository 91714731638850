import { Box, Grid, GridItem, Image, Link, SlideFade, Text } from '@chakra-ui/react';

import ContentCard from '../../data-display/cards/ContentCard';
import { ExploreContent } from '@/client/types/ExploreContent';
import ExploreService from '@/client/services/api/ExploreService';
import RecommendationsService from '@/client/services/api/recommendations/RecommendationsService';
import { Link as RouterLink } from 'react-router-dom';
// eslint-disable-next-line import/extensions
import recommendationsIcon from '@/client/assets/images/recommendationsIcon.svg';
// eslint-disable-next-line import/extensions
import recommendationsNoDataFound from '@/client/assets/images/recommendationsNoDataFound.svg';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useAuthStore } from '@/client/services/state/authStore';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export default function RecommendationsOverview() {
  const { allowance } = useAccessHelper();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const enabled = !!allowance.recommendations;
  const { t } = useTranslation();

  // TODO: Pull this out into a util for use in Workshop/Explore cards
  const setContentImageAlignment = (content: ExploreContent, isFirst: boolean) => {
    let topPos = '';
    if (!isFirst && content.cover_justification === 'left') {
      topPos = 'north';
    }
    if (!isFirst && content.cover_justification === 'right') {
      topPos = 'south';
    }
    if (isFirst && content.featured_justification === 'left') {
      topPos = 'north';
    }
    if (isFirst && content.featured_justification === 'right') {
      topPos = 'south';
    }
    return `${topPos}`;
  };

  const { isError, data } = useQuery({
    enabled,
    queryKey: ['general-explore-recommendations'],
    queryFn: () => RecommendationsService.getGeneralRecommendations(),
  });

  const { data: featuredCategory } = useQuery({
    enabled,
    queryKey: ['featured-category'],
    queryFn: () => ExploreService.getFeaturedCategory(),
  });

  const browsingLink =
    company.landing === 'dashboard' ? '/dashboard' : `/explore/category=${featuredCategory?._id}`;

  if (isError) return <Box>{t('products.loadError')}</Box>;

  return (
    <Box>
      <Grid
        marginY={40}
        h="200px"
        w={{ base: '100%', sm: '530px' }}
        margin={{ base: '0 auto 20px', sm: '0 auto' }}
        templateRows={{ base: 'repeat(3, 1fr)', sm: 'repeat(2, 1fr)' }}
        templateColumns="repeat(3, 1fr)"
      >
        <GridItem
          rowSpan={{ base: 1, sm: 2 }}
          colStart={{ base: 2, sm: 1 }}
          colEnd={{ base: 3, sm: 2 }}
          alignSelf="center"
        >
          <Image src={recommendationsIcon} width={140} height={140} marginBottom={4} />
        </GridItem>
        <GridItem
          rowSpan={{ base: 2, sm: 1 }}
          colStart={2}
          colEnd={{ base: 3, sm: 2 }}
          alignSelf="end"
          justifySelf={{ base: 'center', sm: 'start' }}
        >
          <Text fontSize="3xl" fontWeight="500" color="blackAlpha.700">
            {t('recommendations.explore.forYou')}
          </Text>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={{ base: 3, sm: 2 }}
          justifySelf={{ base: 'center', sm: 'start' }}
        >
          <Text fontSize="lg">{t('recommendations.explore.aiDescription')}</Text>
        </GridItem>
      </Grid>
      {(!data?.content || !data?.content.length) && (
        <>
          <Image margin="20px auto" src={recommendationsNoDataFound} />
          <Text fontSize="sm" textAlign="center">
            {t('recommendations.explore.noRecommendations')}
          </Text>
          <Text fontSize="sm" textAlign="center">
            {t('recommendations.explore.interactWithContent')}
          </Text>
          <Text textAlign="center" fontSize="sm">
            <Link as={RouterLink} to={browsingLink}>
              {t('recommendations.explore.startBrowsing')}
            </Link>
          </Text>
        </>
      )}
      <Grid templateColumns={{ base: 'repeat(2, 1fr)', sm: 'repeat(3, 1fr)' }} gap={6}>
        {data?.content.map((item) => (
          <SlideFade
            key={item._id}
            in={!!item}
            offsetY={100}
            transition={{ enter: { duration: Math.random() + 1 } }}
          >
            <GridItem>
              <ContentCard
                id={item._id}
                title={item.title || item.name}
                type={item.type}
                imageUrl={item.images ? item.images.explore : item.image}
                cardType="list"
                imageAlignment={setContentImageAlignment(item, false)}
                isStatic
              />
            </GridItem>
          </SlideFade>
        ))}
      </Grid>
    </Box>
  );
}
