import { Button, FormControl, Input, Stack, Text } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Loading from '@/client/components/media/Loading';
import { Navigate, useNavigate } from 'react-router-dom';
import PreAuthService from '@/client/services/api/PreAuthService';
import { useConfigStore } from '@/client/services/state/configStore';
import useDocumentTitle from '../utils/useDocumentTitle';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToastStore } from '@/client/services/state/toastStore';
import RegistrationFormsService from '@/client/services/api/RegistrationFormsService';

interface CodeInputs {
  code: string;
}

interface InitialDataVariables {
  code: string;
}

export default function RegisterAskForCode() {
  useDocumentTitle('Register');
  const { config } = useConfigStore();
  if (!config.features.enable_registration_forms) {
    return <Navigate to="/login" />;
  }

  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const domain = config.host;
  const { t } = useTranslation();

  const { isLoading, isError, data } = useQuery({
    queryKey: ['checkRegistrationActive'],
    queryFn: () => PreAuthService.checkRegistrationActive(domain),
  });

  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm<CodeInputs>({ mode: 'onChange' });

  const setInitialData = useMutation({
    mutationFn: ({ code }: InitialDataVariables) =>
      RegistrationFormsService.getFormByCode(domain, code!),
    onSuccess: (res) => {
      if (res.active) {
        if (res.type === 'product_landing_page') {
          return navigate(`/product/registration/${res.code}`);
        } else {
          return navigate(`/registration/${res.code}`);
        }
      }
      setToast({ show: true, status: 'error', title: t('modules.notifications.invalidCode') });
    },
    onError: () =>
      setToast({ show: true, status: 'error', title: t('modules.notifications.invalidCode') }),
  });

  const onSubmit: SubmitHandler<InitialDataVariables> = async (formData) =>
    setInitialData.mutate({ code: formData.code.toLowerCase() });

  if (isLoading) return <Loading />;

  if (isError || !data || data.status !== 'active') return <Navigate to="/login" />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={0}>
        <Text fontWeight="bold">{t('global.forms.labels_enterCode')}</Text>

        <FormControl>
          <Input
            id="code"
            type="text"
            placeholder={`${t('global.forms.labels_code')}`}
            variant="flushed"
            {...register('code', { required: true })}
          />
        </FormControl>

        <div style={{ marginTop: '30px', marginBottom: '10px' }}>
          <Button
            type="submit"
            width="full"
            isDisabled={!isValid}
            isLoading={setInitialData.status === 'loading'}
          >
            {t('global.forms.labels_submit')}
          </Button>
        </div>
      </Stack>
    </form>
  );
}
