import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { Flex } from '@chakra-ui/react';
import type { ReactElement } from 'react';
import { useState } from 'react';

type ContentHeroProps = {
  imageUrl: string;
  height?: string;
  imageEditActions?: ReactElement;
};

export default function ContentHero({ imageUrl, height, imageEditActions }: ContentHeroProps) {
  const [renderImageEditActions, setRenderImageEditActions] = useState<boolean>(false);
  const { contentType } = useCreateStore();

  return (
    <Flex
      height={height}
      width="full"
      backgroundImage={`url(${imageUrl})`}
      backgroundSize="cover"
      backgroundPosition="center center"
      borderRadius={contentType === 'product' ? '12px' : 'none'}
    >
      <Flex
        width="full"
        height="full"
        backgroundColor={contentType === 'product' ? 'blackAlpha.50' : 'blackAlpha.600'}
        alignItems="center"
        justifyContent="center"
        borderRadius={contentType === 'product' ? '12px' : 'none'}
        onMouseEnter={() => setRenderImageEditActions(true)}
        onMouseLeave={() => setRenderImageEditActions(false)}
      >
        {renderImageEditActions && imageEditActions && imageEditActions}
      </Flex>
    </Flex>
  );
}

ContentHero.defaultProps = {
  height: { base: '200px', md: '300px', lg: '400px' },
  imageEditActions: null,
};
