/* eslint react/no-unstable-nested-components: 0 */

import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import DeleteGroupModal from './overlay/DeleteGroupModal';
import { Group } from '@/client/services/api/admin/connect/GroupsService';
import { PlusCircle } from '@/client/components/icons/ContinuIcons';
import TableActionsMenu from '@/client/components/admin/menus/TableActionsMenu';
import { stripHtmlTags } from '@/client/utils/stripHtmlTags';
import { useGroupsService } from '@/client/services/hooks/admin/connect/useGroupsService';
import { useTranslation } from 'react-i18next';
import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';

// TODO: Refactor this to use ManuallyPaginatedTable.tsx

export default function Groups() {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<Group>();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  const {
    getGroups,
    setSearchTerm,
    deleteGroup,
    sortOrder,
    setSortOrder,
    isSearching,
    downloadCsv,
  } = useGroupsService();

  const trimDescription = (description: string) => {
    if (description.length > 50) {
      return `${description.substring(0, 50)}...`;
    }
    return description;
  };

  const handleGroupDownloadCsv = (groupId: string | undefined) => {
    downloadCsv.mutateAsync(groupId).then(() => {
    });
  };

  const columns = React.useMemo<ColumnDefBase<Group, any>[]>(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => (
          <Box as="span" marginLeft={9}>
            {info.getValue()}
          </Box>
        ),
        header: () => (
          <Box as="span" marginLeft={9}>
            {t('admin.connect.groups.groupName')}
          </Box>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('_id', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('admin.connect.groups.groupId')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('description', {
        cell: (info) => trimDescription(stripHtmlTags(info.getValue())),
        header: () => <span>{t('admin.connect.groups.groupDescription')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('managers', {
        cell: (info) => (
          <Link
            variant="adminPrimary"
            as={RouterLink}
            state={{
              groupId: info.row.original._id,
              groupName: info.row.original.name,
            }}
            to={`${info.row.original._id}/users?type=managers`}
          >
            {info.getValue()} managers
          </Link>
        ),
        header: () => <span>{t('global.roles.managers')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('users', {
        cell: (info) => (
          <Link
            variant="adminPrimary"
            as={RouterLink}
            to={`${info.row.original._id}/users?type=users`}
          >
            {info.getValue()} users
          </Link>
        ),
        header: () => <span>{t('profiles.manager.associatedUsers')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('user.full_name', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('profiles.manager.createdBy')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <TableActionsMenu
            onEdit={() => navigate(`/admin/connect/groups/${info.row.original._id}/edit`)}
            onDownload={() => {
              handleGroupDownloadCsv(info.row.original._id);
            }}
            onDelete={() => {
              setSelectedGroup(info.row.original._id);
              setIsOpen(true);
            }}
            onCopy={() => {
              navigator.clipboard.writeText(info.row.original._id);
            }}
          />
        ),
      }),
    ],
    [],
  );

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const dataQuery = getGroups(fetchDataOptions);

  const defaultData = React.useMemo(() => [], []);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data: dataQuery.data?.rows ?? defaultData,
    // @ts-ignore
    columns,
    pageCount: dataQuery.data?.pageCount ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  const handleDeleteGroup = () => {
    deleteGroup.mutateAsync(selectedGroup).then(() => {
      dataQuery.refetch();
      setIsOpen(false);
    });
  };

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={t('admin.connect.searchPlaceholder', {
          type: t('admin.connect.groups.singular'),
        })}
        title={`${t('admin.connect.groups.plural')}`}
        setSearchTerm={setSearchTerm}
        showSortSelector
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        isSearching={isSearching}
        rightElement={
          <Button
            variant="adminPrimary"
            size="xs"
            leftIcon={<PlusCircle />}
            onClick={() => navigate('/admin/connect/groups/create')}
          >
            {t('admin.connect.groups.addGroup')}
          </Button>
        }
      />

      <AdminElevatedBox>
        <TableContainer backgroundColor="white" borderRadius="xl" overflow="auto">
          <Table variant="admin">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <Box>{flexRender(header.column.columnDef.header, header.getContext())}</Box>
                      )}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id} overflow="hidden" fontWeight="medium" fontSize="14px">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <Flex alignItems="center" marginTop={6}>
          <Box width="30%">
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((currentPageSize) => (
                <option key={currentPageSize} value={currentPageSize}>
                  Show {currentPageSize}
                </option>
              ))}
            </select>
          </Box>

          <ButtonGroup width="40%" variant="ghost" justifyContent="center" alignItems="center">
            <Button
              onClick={() => table.setPageIndex(0)}
              isDisabled={!table.getCanPreviousPage()}
              color="brand.grey.100"
            >
              {'<<'}
            </Button>

            <Button
              onClick={() => table.previousPage()}
              isDisabled={!table.getCanPreviousPage()}
              color="brand.grey.100"
            >
              {'<'}
            </Button>

            <Text>{`${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}</Text>

            <Button
              onClick={() => table.nextPage()}
              isDisabled={!table.getCanNextPage()}
              color="brand.grey.100"
            >
              {'>'}
            </Button>

            <Button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              isDisabled={!table.getCanNextPage()}
              color="brand.grey.100"
            >
              {'>>'}
            </Button>
          </ButtonGroup>

          <Box width="30%" />

          {/* {dataQuery.isFetching ? "Loading..." : null} */}
        </Flex>
      </AdminElevatedBox>

      <DeleteGroupModal
        isOpen={isOpen}
        handleDelete={handleDeleteGroup}
        onClose={() => {
          setSelectedGroup(null);
          setIsOpen(false);
        }}
      />
    </>
  );
}
