/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ArrowRight,
  Check,
  Facebook,
  LinkedIn,
  Twitter,
} from '@/client/components/icons/ContinuIcons';
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Link,
  Progress,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useToken,
} from '@chakra-ui/react';
import { Instructor, LearningTrack } from '@/client/types/content/learning-tracks/LearningTrack';
import { useRef, useState } from 'react';

import { BiCollapse } from 'react-icons/bi';
import CollapsableHtmlRenderer from '@/client/components/html/CollapsableHtmlRenderer';
import CommentList from '@/client/components/lists/CommentList';
import ContentLength from '@/client/components/data-display/content/ContentLength';
import HtmlRenderer from '@/client/components/html/HtmlRenderer';
import TrackContentList from '@/client/components/lists/track/TrackContentList';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useTranslation } from 'react-i18next';

interface LearningTrackPreviewProps {
  data: {
    learningTrack: LearningTrack;
    trackDuration: number;
    trackProgress: number;
  };
}

export default function LearningTrackPreview({ data }: LearningTrackPreviewProps) {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [selectedInstructor, setSelectedInstructor] = useState<number>(0);
  const heroRef = useRef<HTMLDivElement>(null);
  const brandPrimary = useToken('colors', 'brand.primary');
  const { setCoursePreviewModalIsOpen } = useCreateStore();

  const id = `1234`;

  return (
    <>
      <Flex
        height={{
          base: data.learningTrack.resource ? 'auto' : '300px',
          md: data.learningTrack.resource ? 'auto' : '400px',
          lg: data.learningTrack.resource ? 'auto' : '500px',
        }}
        width="full"
        position="relative"
        ref={heroRef}
      >
        <Box
          w="100%"
          h="100%"
          background="black"
          position="absolute"
          top="0"
          left="0"
          zIndex="3"
          opacity="0.4"
        />

        <Box
          width="full"
          height="full"
          bgColor="brand.primary"
          backgroundImage={`url(${data.learningTrack.banner_image})`}
          backgroundSize="cover"
          backgroundPosition="center center"
        >
          <Flex width="full" justifyContent="flex-end" padding={4}>
            <Button
              zIndex={9999}
              variant="createCancel"
              leftIcon={<Icon as={BiCollapse} />}
              onClick={() => setCoursePreviewModalIsOpen(false)}
            >
              {t('authoring.livePreview.returnToEditing')}
            </Button>
          </Flex>

          <Center
            width="full"
            marginTop={{ base: 6, md: 24 }}
            marginBottom={data.learningTrack.resource ? { base: 6, md: 24 } : ''}
            position="relative"
            zIndex="5"
          >
            <VStack spacing={4}>
              <Text variant="heroheader" color="white" marginBottom="0">
                {data.learningTrack.name}
              </Text>

              {data.trackDuration > 0 && !data.learningTrack.resource && (
                <ContentLength duration={data.trackDuration} color="white" size="lg" />
              )}

              <Box>
                <Button
                  isDisabled
                  rightIcon={<ArrowRight />}
                  marginY={4}
                  paddingY={4}
                  paddingX={8}
                  backgroundColor="brand.button"
                  _hover={{
                    backgroundColor: 'brand.button',
                  }}
                >
                  {t('contentViews.tracks.start')}
                </Button>
              </Box>

              {data.trackProgress > 0 && !data.learningTrack.resource && (
                <Flex width={80} alignItems="center" paddingX={14}>
                  <Text fontSize="xs" flex="1" color="white" fontWeight="medium">
                    {data.trackProgress}% {t('contentViews.tracks.complete')}
                  </Text>

                  <Progress
                    flex="1"
                    width="full"
                    size="xs"
                    value={data.trackProgress}
                    variant="hollow"
                  />
                </Flex>
              )}
            </VStack>
          </Center>
        </Box>
      </Flex>

      <Center width={{ base: '100%', md: '70%' }} margin="0 auto" marginTop={8}>
        <Tabs
          index={activeTabIndex}
          width="full"
          isFitted
          onChange={(index) => setActiveTabIndex(index)}
        >
          <TabList marginBottom={8} width="auto" justifyContent="center" paddingBottom={1}>
            <Tab flex="0" marginRight={data.learningTrack.allow_comments ? 2 : 0}>
              {t('contentViews.tracks.info_tabName')}
            </Tab>

            {data.learningTrack.instructor_ids[selectedInstructor].notes &&
              data.learningTrack.instructor_ids[selectedInstructor].notes !== '' && (
                <Tab flex="0">{t('contentViews.tracks.instructor_tabName')}</Tab>
              )}

            {data.learningTrack.allow_comments && <Tab flex="0">{t('trackviewer.discussion')}</Tab>}
          </TabList>

          <TabPanels>
            <TabPanel maxWidth="1060px" margin="0 auto">
              <Flex direction={{ base: 'column-reverse', lg: 'row' }}>
                <Box width={{ base: '100%', lg: '50%' }}>
                  {data.learningTrack.learn_items?.length > 0 && (
                    <Box marginBottom={8}>
                      <Text as="h4" fontSize="md">
                        {t('contentViews.tracks.learn_headline')}
                      </Text>

                      {data.learningTrack.learn_items.map((item: any) => {
                        if (item === '') return null;

                        return (
                          <HStack key={item} spacing={4}>
                            <Check color="brand.primary" />

                            <Text fontSize="sm">{item}</Text>
                          </HStack>
                        );
                      })}
                    </Box>
                  )}

                  <CollapsableHtmlRenderer html={data.learningTrack.description} />

                  <TrackContentList
                    sections={data.learningTrack.sections}
                    lastViewedContent={undefined}
                    trackComplete={false}
                    handleGoToCourse={() => {}}
                  />
                </Box>

                <Flex
                  width={{ base: '100%', lg: '50%' }}
                  justifyContent={{ base: 'center', lg: 'flex-end' }}
                  alignSelf="flex-start"
                >
                  {data.learningTrack.instructor_ids.length > 0 && (
                    <Box width="70%" marginBottom={10} paddingY={12} paddingX={4}>
                      <VStack
                        spacing={0}
                        backgroundColor="blackAlpha.50"
                        paddingY={16}
                        paddingX={6}
                      >
                        {data.learningTrack.instructor_ids.map(
                          (instructor: Instructor, index: number) => {
                            if (index !== selectedInstructor) return;
                            return (
                              <VStack spacing={2}>
                                <Avatar
                                  name={instructor.name}
                                  src={instructor.image}
                                  backgroundColor="blackAlpha.300"
                                  color="blackAlpha.600"
                                />

                                {instructor.email && (
                                  <Link href={`mailto:${instructor.email}`}>
                                    {instructor.email}
                                  </Link>
                                )}

                                <HStack spacing={6}>
                                  {instructor.socials?.twitter && (
                                    <Link href="#">
                                      <Twitter color="blackAlpha.600" />
                                    </Link>
                                  )}

                                  {instructor.socials?.linkedin && (
                                    <Link href="#">
                                      <LinkedIn color="blackAlpha.600" />
                                    </Link>
                                  )}

                                  {instructor.socials?.facebook && (
                                    <Link href="#">
                                      <Facebook color="blackAlpha.600" />
                                    </Link>
                                  )}
                                </HStack>

                                <Text as="h4">{instructor.name}</Text>

                                <Box className="author-bio" textAlign="center" paddingX={6}>
                                  <HtmlRenderer html={instructor.bio || ''} />
                                </Box>
                              </VStack>
                            );
                          },
                        )}
                        <HStack padding={4}>
                          {data.learningTrack.instructor_ids.map(
                            (instructor: Instructor, index: number) => (
                              <Box
                                key={instructor.name}
                                width={2.5}
                                height={2.5}
                                boxShadow={`inset 0 0 0 2px ${brandPrimary}`}
                                background={
                                  selectedInstructor === index ? 'brand.primary' : 'transparent'
                                }
                                display="inline-block"
                                borderRadius={8}
                                transition="0.3s ease all"
                                onClick={() => {
                                  setSelectedInstructor(index);
                                }}
                                _hover={{
                                  cursor: 'pointer',
                                  boxShadow: `inset 0 0 0 4px ${brandPrimary}`,
                                }}
                              />
                            ),
                          )}
                        </HStack>
                      </VStack>
                    </Box>
                  )}
                </Flex>
              </Flex>
            </TabPanel>

            {data.learningTrack.instructor_ids[selectedInstructor].notes &&
              data.learningTrack.instructor_ids[selectedInstructor].notes !== '' && (
                <TabPanel>
                  <HtmlRenderer
                    html={data.learningTrack.instructor_ids[selectedInstructor].notes}
                  />
                </TabPanel>
              )}

            {data.learningTrack.allow_comments && (
              <TabPanel maxWidth="640px" margin="0 auto">
                <CommentList
                  contentId={id}
                  contentType="track"
                  contentTitle={data.learningTrack.name}
                />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Center>
    </>
  );
}
