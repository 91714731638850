import { Text, Textarea } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { handleWordCount } from '@/client/utils/content/assessments/handleWordCount';
import { useDebounce } from 'usehooks-ts';

interface LongInputQuestionProps {
  selected: string;
  wordLimit: number;
  wordMinimum: number;
  handleSelect: (value: string) => void;
}

export default function LongInputQuestion({
  wordMinimum,
  wordLimit,
  selected,
  handleSelect,
}: LongInputQuestionProps) {
  const [wordCount, setWordCount] = useState<number>(0);
  const [value, setValue] = useState<string>(selected);

  useEffect(() => {
    if (selected) {
      setWordCount(handleWordCount(selected));
      setValue(selected);
    }
  }, [selected]);

  const handleTextAreaChange = (textValue: string) => {
    setWordCount(handleWordCount(textValue));
    setValue(textValue);
    handleSelect(textValue);
  };

  return (
    <>
      {wordMinimum > 0 && <Text>{wordMinimum} words minimum</Text>}

      <Textarea value={value} onChange={(e) => handleTextAreaChange(e.target.value)} />

      <Text>
        {wordCount} / {wordLimit} words
      </Text>
    </>
  );
}
