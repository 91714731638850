import {
  Button,
  ButtonGroup,
  Grid,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Loading from '@/client/components/media/Loading'
import { DndProvider } from 'react-dnd';
import DraggableCard from '../data-display/cards/draggable/DraggableCard';
import ExploreService from '@/client/services/api/ExploreService';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useExploreStore } from '../../services/state/routeState/exploreStore';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@/client/services/state/authStore';

type DraggableListProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export interface Item {
  _id: string;
  type: string;
  title?: string;
  name?: string;
  createdAt: string;
  resource?: boolean;
  position: number;
}

export interface ContainerStare {
  items: Item[];
}

// * https://codesandbox.io/s/musing-wind-kuziyx?file=/src/Container.tsx:1587-1657

export default function DraggableList({ isOpen, setIsOpen }: DraggableListProps) {
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const company = authConfig.company;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const categoryId = useExploreStore((state) => state.selectedCategory);
  const [cards, setCards] = useState<Item[]>([]);
  
  const inListView = company.feature_flags.explore_in_list_view;
  const cardColumns = {
    base: 'repeat(1, 1fr)',
    sm: 'repeat(1, 1fr)',
    md: 'repeat(2, 1fr)',
    lg: 'repeat(3, 1fr)',
    xl: 'repeat(4, 1fr)',
    '2xl': 'repeat(5, 1fr)',
  };
  
  const listColumns = {
    base: 'repeat(1, 1fr)',
    md: 'repeat(2, 1fr)',
    lg: 'repeat(3, 1fr)',
    xl: 'repeat(3, 1fr)',
    '2xl': 'repeat(4, 1fr)',
  };

  const columns = inListView ? listColumns : cardColumns;

  const reOrderContent = useMutation({
    mutationFn: (contentIds: string[]) => ExploreService.reorderContent(categoryId, contentIds),
    onSuccess: () => {
      setToast({ show: true, status: 'success', title: t('explore.order.order_saved') });
      queryClient.invalidateQueries({ queryKey: ['explore-content'] });
      setIsOpen(false);
    },
    onError: () => setToast({ show: true, status: 'error', title: t('explore.order.error_saving') }),
  });

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      })
    );
  }, []);

  const renderCard = useCallback((card: Item, index: number) => {
    return (
      <DraggableCard
        key={card._id}
        index={index}
        id={card._id}
        type={card.type}
        cardType={inListView ? 'list' : 'card'}
        title={card.title || card.name}
        createdAt={card.createdAt}
        moveCard={moveCard}
      />
    );
  }, []);

  const { isLoading, isError, data, error } = useQuery(
    ['ordered-content', { categoryId }],
    async () => await ExploreService.getOrderedContent(categoryId),
    {
      enabled: !!categoryId,
      refetchOnWindowFocus: false,
      onSuccess: (res: any) => {
        const ordered = setOrder(res);

        setCards(ordered);
      },
    }
  );

  const setOrder = (data: { data: Item[]; order: string[] }) => {
    let orderedItems: Item[] = [];
    let unorderedItems: Item[] = [];
    let orderedIds: Record<string, boolean> = {};

    for (let i = 0; i < data.data.length; i++) {
      const index = data.order.indexOf(data.data[i]._id);
      if (data.data[i].type === 'track' && data.data[i].resource) {
        data.data[i].type = 'resource';
      }

      if (index !== -1) {
        orderedIds[data.data[i]._id] = true;
        data.data[i].position = index;
        orderedItems.push(data.data[i]);
        continue;
      }
      unorderedItems.push(data.data[i]);
    }

    orderedItems.sort(function (a, b) {
      return a.position - b.position;
    });

    unorderedItems.sort(function (a, b) {
      return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
    });

    orderedItems = orderedItems.concat(unorderedItems);
    return orderedItems;
  };

  const handleSave = () => {
    const orderedIds = cards.map((card) => card._id);

    reOrderContent.mutate(orderedIds);
  };

  return (
    <Modal size="full" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <ButtonGroup width="full" justifyContent="flex-end" spacing={4}>
            <Button onClick={() => handleSave()}>{t('global.actions.save')}</Button>

            <Button variant="cancel" onClick={() => setIsOpen(false)}>
              {t('global.actions.cancel')}
            </Button>
          </ButtonGroup>
        </ModalHeader>

        <ModalBody marginTop={12}>
          {isLoading && <Loading/>}

          {isError && <Text>Error... </Text>}

          {cards && cards.length > 0 && (
            <DndProvider backend={HTML5Backend}>
              <Grid
                templateColumns={columns}
                gap={inListView ? 3 : 5}
              >
                {cards.map((card, i) => renderCard(card, i))}
              </Grid>
            </DndProvider>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
