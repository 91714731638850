import GroupsService, { GroupData } from '@/client/services/api/admin/connect/GroupsService';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export const useGroupsService = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | 'oldest' | 'newest'>('newest');

  useEffect(() => {
    if (!searchTerm) {
      setIsSearching(false);
    }

    if (searchTerm) {
      setIsSearching(true);
    }
  }, [searchTerm]);

  const getGroup = (groupId: string | undefined) =>
    useQuery({
      enabled: !!groupId,
      queryKey: ['group', groupId],
      queryFn: () => GroupsService.getGroup(groupId),
    });

  const getGroups = (fetchDataOptions: { pageIndex: number; pageSize: number }) =>
    useQuery({
      queryKey: ['groups', fetchDataOptions, isSearching, searchTerm, sortOrder],
      queryFn: () => GroupsService.getGroups(fetchDataOptions, sortOrder, isSearching, searchTerm),
      keepPreviousData: true,
    });

  interface SaveGroupVariables {
    groupId: string | undefined;
    groupData: GroupData;
  }

  const saveGroup = useMutation({
    mutationFn: ({ groupId, groupData }: SaveGroupVariables) =>
      GroupsService.saveGroup(groupId, groupData),
    onSuccess: (data, variables) => {
      setToast({
        show: true,
        status: 'success',
        title: variables.groupId
          ? t('admin.saveSuccessful')
          : t('admin.connect.groups.createSuccessful'),
      });

      !variables.groupId && navigate('/admin/connect/groups');
    },
    onError: (data, variables) =>
      setToast({
        show: true,
        status: 'error',
        title: variables.groupId
          ? t('admin.connect.groups.saveError')
          : t('admin.connect.groups.createError'),
      }),
  });

  const getGroupUsers = (
    fetchDataOptions: {
      pageIndex: number;
      pageSize: number;
    },
    groupId: string | undefined,
    isEnabled: boolean,
  ) =>
    useQuery({
      enabled: isEnabled,
      queryKey: ['group-users', fetchDataOptions, groupId],
      queryFn: () => GroupsService.getGroupUsers(fetchDataOptions, groupId),
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
    });

  const getGroupManagers = (
    fetchDataOptions: {
      pageIndex: number;
      pageSize: number;
    },
    groupId: string | undefined,
    isEnabled: boolean,
  ) =>
    useQuery({
      enabled: isEnabled,
      queryKey: ['group-managers', fetchDataOptions, groupId],
      queryFn: () => GroupsService.getGroupManagers(fetchDataOptions, groupId),
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
    });

  const getGroupNameById = (groupId: string | undefined) =>
    useQuery({
      queryKey: ['group-name', groupId],
      queryFn: () => GroupsService.getGroupNameById(groupId),
    });

  interface DeleteGroupMembersVariables {
    groupId: string | undefined;
    userIds: string[];
    removeManagers: boolean;
  }

  const deleteGroupUsers = useMutation({
    mutationFn: (variables: DeleteGroupMembersVariables) =>
      GroupsService.deleteGroupUsers(
        variables.groupId,
        variables.userIds,
        variables.removeManagers,
      ),
    onSuccess: (data, variables) => {
      setToast({
        show: true,
        status: 'success',
        title: variables.removeManagers
          ? t('admin.connect.groups.managersDeleteSuccessful')
          : t('admin.connect.groups.usersDeleteSuccessful'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.groups.usersDeleteError'),
      }),
  });

  const deleteGroup = useMutation({
    mutationFn: (groupId: string | null) => GroupsService.deleteGroup(groupId),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('admin.connect.groups.groupDeleteSuccessful'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.groups.groupDeleteError'),
      }),
  });

  interface AddUsersVariables {
    groupId: string | undefined;
    userIds: string[];
    managers: boolean;
  }

  const addGroupUsers = useMutation({
    mutationFn: (variables: AddUsersVariables) =>
      GroupsService.addUsersToGroup(variables.groupId, variables.userIds, variables.managers),
    onSuccess: (data, variables) => {
      setToast({
        show: true,
        status: 'success',
        title: variables.managers
          ? t('admin.connect.groups.managersAddSuccessful')
          : t('admin.connect.groups.usersAddSuccessful'),
      });

      navigate(
        `/admin/connect/groups/${variables.groupId}/users?type=${
          variables.managers ? 'managers' : 'users'
        }`,
      );
    },
    onError: (error, variables) => {
      console.log(error);

      setToast({
        show: true,
        status: 'error',
        title: variables.managers
          ? t('admin.connect.groups.managersAddError')
          : t('admin.connect.groups.usersAddError'),
      });
    },
  });

  // const downloadCsv = (groupId: string | undefined) => GroupsService.downloadCsv(groupId);
  const downloadCsv = useMutation({
    mutationFn: (groupId: string | undefined) => GroupsService.downloadCsv(groupId),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('admin.connect.groups.groupDownloadedSuccessful'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.groups.groupDownloadedError'),
      }),
  });

  return {
    getGroup,
    getGroups,
    isSearching,
    searchTerm,
    setSearchTerm,
    saveGroup,
    getGroupUsers,
    getGroupManagers,
    getGroupNameById,
    deleteGroupUsers,
    deleteGroup,
    sortOrder,
    setSortOrder,
    addGroupUsers,
    downloadCsv,
  };
};
