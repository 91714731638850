import { Box, Collapse, Fade, HStack, Stack } from '@chakra-ui/react';

import AuthoringFeedbackContactsSearch from './AuthoringFeedbackContactsSearch';
import AuthoringFeedbackSlackChannelsSearch from '@/client/components/admin/create/content/forms/settings/AuthoringFeedbackSlackChannelsSearch';
import type { Content } from '@/client/services/api/graphql/gql/graphql';
import CreateCheckbox from '@/client/components/admin/create/shared/input/CreateCheckbox';
import CreateFormGroup from '@/client/components/admin/create/shared/layout/CreateFormGroup';
import CreateSwitchContainer from '@/client/components/admin/create/shared/layout/CreateSwitchContainer';
import IntegrationService from '@/client/services/api/admin/connect/IntegrationService';
import { useAuthStore } from '@/client/services/state/authStore';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export default function ContentRatingForm() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { watch } = useFormContext<Content>();

  const allowRating = watch('ratingConfiguration.allowRating');
  const allowFeedback = watch('ratingConfiguration.allowFeedback');
  const notifyContacts = watch('ratingConfiguration.notifyFeedbackOnly');
  const showUsersRating = watch('ratingConfiguration.showUsersRating');

  const { data } = useQuery({
    enabled: company.slack,
    queryKey: ['checkSlackEnabled'],
    queryFn: () => IntegrationService.checkSlackEnabled(),
  });

  return (
    <CreateFormGroup>
      <Stack>
        <CreateSwitchContainer
          name="ratingConfiguration.allowRating"
          label={t('authoring.settings.enableRatings')}
          helpText={t('authoring.settings.enableRatings.helpText')}
        />

        <Collapse in={allowRating} animateOpacity>
          <Stack spacing={4}>
            <HStack>
              <CreateCheckbox
                name="ratingConfiguration.allowFeedback"
                label={t('authoring.settings.enableRatings.allowFeedback')}
                isChecked={allowFeedback}
              />

              <Box
                maxHeight={allowFeedback ? '47px' : '0px'}
                maxWidth={allowFeedback ? '240px' : '0px'}
                overflow="hidden"
                transition="max-width 0.4s ease"
              >
                <Fade in={allowFeedback} delay={0.3}>
                  <CreateCheckbox
                    name="ratingConfiguration.notifyFeedbackOnly"
                    label={t('authoring.settings.enableRatings.notifyContact')}
                    isChecked={notifyContacts}
                  />
                </Fade>
              </Box>

              <CreateCheckbox
                name="ratingConfiguration.showUsersRating"
                label={t('authoring.settings.enableRatings.showAverageRating')}
                isChecked={showUsersRating}
              />
            </HStack>

            <Collapse in={notifyContacts} animateOpacity>
              <Stack spacing={4}>
                <AuthoringFeedbackContactsSearch />

                {data && data.slack && <AuthoringFeedbackSlackChannelsSearch />}
              </Stack>
            </Collapse>
          </Stack>
        </Collapse>
      </Stack>
    </CreateFormGroup>
  );
}
