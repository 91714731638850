/* eslint-disable react/no-unstable-nested-components */

import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { useMemo, useState } from 'react';

import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { Person } from '@/client/types/Person';
import { Profile } from '@/client/types/admin/profiles/Profile';
import ProfilesService from '@/client/services/api/admin/profiles/ProfilesService';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface ProfileUsersModalProps {
  selectedProfile: Profile | null;
  showProfileUsers: boolean;
  setSelectedProfile: (value: Profile | null) => void;
  setShowProfileUsers: (value: boolean) => void;
}

export default function ProfileUsersModal({
  selectedProfile,
  showProfileUsers,
  setSelectedProfile,
  setShowProfileUsers,
}: ProfileUsersModalProps) {
  const { t } = useTranslation();

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const columnHelper = createColumnHelper<Person>();
  const columns = useMemo<ColumnDefBase<Person, any>[]>(
    () => [
      columnHelper.accessor('full_name', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('global.forms.labels_name')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linked_departments', {
        cell: (info) => (info.getValue()[0] ? info.getValue()[0].name : '-----'),
        header: () => <span>{t('global.forms.labels_department')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linked_locations', {
        cell: (info) => (info.getValue()[0] ? info.getValue()[0].name : '-----'),
        header: () => <span>{t('global.label.location')}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    [],
  );

  const { data } = useQuery({
    queryKey: ['profileUsers', fetchDataOptions, selectedProfile?._id, selectedProfile?.userCount],
    queryFn: () =>
      ProfilesService.getProfileUsers(
        fetchDataOptions,
        selectedProfile?._id,
        selectedProfile?.userCount!,
      ),
  });

  const onHandleClose = () => {
    setShowProfileUsers(false);
    setSelectedProfile(null);
  };

  return (
    <Modal size="3xl" isOpen={showProfileUsers && selectedProfile !== null} onClose={onHandleClose}>
      <ModalOverlay onClick={onHandleClose} />

      <ModalContent>
        <ModalBody>
          <ManuallyPaginatedTable
            columns={columns}
            queryData={data}
            rowSelection={{}}
            setRowSelection={() => {}}
            pagination={pagination}
            setPagination={setPagination}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
