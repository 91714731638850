export const colors = {
  warmNeutral: {
    0: '#FFFCF7',
    100: '#fef3df',
    200: '#fde9c8',
    300: '#fadfb2',
    400: '#f6d59d',
    500: '#f1ca89',
    600: '#ebbf76',
    700: '#e4b464',
    800: '#dca954',
    900: '#d29e44',
    1000: '#c79236',
    1100: '#af8236',
    1200: '#977334',
    1300: '#816432',
    1400: '#6c552e',
    1500: '#58472a',
    1600: '#453924',
  },
  neutral: {
    0: '#fafafa',
    50: '#f6f6f6',
    100: '#ededed',
    200: '#e0e0e0',
    300: '#d4d4d4',
    400: '#c7c7c7',
    500: '#bababa',
    600: '#adadad',
    700: '#a0a0a0',
    800: '#949494',
    900: '#878787',
    1000: '#7a7a7a',
    1100: '#6d6d6d',
    1200: '#606060',
    1300: '#535353',
    1400: '#474747',
    1500: '#3a3a3a',
  },
  baseBlack: '#2D2D2D',
  brand: {
    grey: {
      10: `rgba(26, 26, 26, 0.1)`,
      20: `rgba(26, 26, 26, 0.2)`,
      30: `rgba(26, 26, 26, 0.3)`,
      40: `rgba(26, 26, 26, 0.4)`,
      50: `rgba(26, 26, 26, 0.5)`,
      60: `rgba(26, 26, 26, 0.6)`,
      70: `rgba(26, 26, 26, 0.7)`,
      80: `rgba(26, 26, 26, 0.8)`,
      90: `rgba(26, 26, 26, 0.9)`,
      100: `rgba(26, 26, 26, 1)`,
    },
    gold: {
      10: `rgba(235, 180, 100, 0.1)`,
      20: `rgba(235, 180, 100, 0.2)`,
      30: `rgba(235, 180, 100, 0.3)`,
      40: `rgba(235, 180, 100, 0.4)`,
      50: `rgba(235, 180, 100, 0.5)`,
      60: `rgba(235, 180, 100, 0.6)`,
      70: `rgba(235, 180, 100, 0.7)`,
      80: `rgba(235, 180, 100, 0.8)`,
      90: `rgba(235, 180, 100, 0.9)`,
      100: `rgba(235, 180, 100, 1)`,
    },
    legibleBlack: {
      10: `rgba(45, 45, 45, 0.1)`,
      20: `rgba(45, 45, 45, 0.2)`,
      30: `rgba(45, 45, 45, 0.3)`,
      40: `rgba(45, 45, 45, 0.4)`,
      50: `rgba(45, 45, 45, 0.5)`,
      60: `rgba(45, 45, 45, 0.6)`,
      70: `rgba(45, 45, 45, 0.7)`,
      80: `rgba(45, 45, 45, 0.8)`,
      90: `rgba(45, 45, 45, 0.9)`,
      100: `rgba(45, 45, 45, 1)`,
    },
    button: 'brand.primary',
    link: 'brand.primary',
    tableHeader: 'brand.primary',
    formInput: 'brand.primary',
    highlight: 'brand.primary',
    warning: 'rgba(255, 184, 0, 1)',
    backgroundGrey: '#F9F9F9',
    primary: '#00BCF1',
    secondary: '#004071',
    tertiary: '#0072aa',
    red: '#ea6153',
    green: '#2ec36b',
    orange: '#ff8100',
    yellow: '',
    olive: '#B5CC18',
    blue: '#00BCF1',
    violet: '#6435C9',
    purple: '#A333C8',
    pink: '#E03997',
    brown: '#A5673F',
    white: '#FFFFFF',
    black: '#191919',
    mist: '#f7f7f7',
    iconGrey: '#575b5d',
    blackAlpha: {
      50: 'rgba(25,25,25,0.04)',
      100: 'rgba(25,25,25,0.06)',
      200: 'rgba(25,25,25,0.08)',
      300: 'rgba(25,25,25,0.16)',
      400: 'rgba(25,25,25,0.24)',
      500: 'rgba(25,25,25,0.36)',
      600: 'rgba(25,25,25,0.48)',
      700: 'rgba(25,25,25,0.64)',
      800: 'rgba(25,25,25,0.80)',
      900: 'rgba(25,25,25,0.92)',
    },
    gray: {
      50: '#F2F2F2',
      100: '#DFDFDF',
      200: '#CBCBCB',
      300: '#B8B8B8',
      400: '#A4A4A4',
      500: '#919191',
      600: '#7D7D7D',
      700: '#717171',
      800: '#656565',
      900: '#4E4E4E',
    },
    table: {
      100: '#f7f7f7',
    },
  },
};
