// IMPORTANT: Build gql after ANY changes to queries (client OR api)
import { graphql } from '@/client/services/api/graphql/gql/gql';

export const createInstructor: any = graphql(`
  mutation CreateInstructor($payload: InstructorCreateInput!) {
    createInstructor(create: $payload) {
      id
    }
  }
`);

export const searchInstructors: any = graphql(`
  query SearchInstructors($name: String) {
    instructors(name: { contains: $name }) {
      edges {
        node {
          id
          name
          email
          bio
          image
          notes
          reusable
          createdAt
          updatedAt
          socials {
            dribbble
            facebook
            github
            instagram
            linkedin
            slack
            twitter
            youtube
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
        totalCount
      }
    }
  }
`);

export const updateInstructor: any = graphql(`
  mutation UpdateInstructor($id: String!, $payload: InstructorUpdateInput!) {
    updateInstructor(id: $id, update: $payload) {
      id
      name
      email
      bio
      notes
      image
      reusable
      archived
      createdAt
      updatedAt
      socials {
        dribbble
        facebook
        github
        instagram
        linkedin
        slack
        twitter
        youtube
      }
    }
  }
`);
