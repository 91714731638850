import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';

import { ArrowLeft } from '../../icons/ContinuIcons';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ReactElement } from 'react';
import TableSearch from '../input/search/TableSearch';
import { useLanguage } from '@/client/services/hooks/language/useLanguage';
import { useTranslation } from 'react-i18next';

interface AdminPrimaryHeaderProps {
  showLanguageSelector?: boolean;
  showSortSelector?: boolean;
  tooltipText: string;
  title: string | ReactElement;
  titleIsElement?: boolean;
  rightElement?: ReactElement;
  bottomElement?: ReactElement;
  showSearch?: boolean;
  setSearchTerm?: (value: string) => void;
  goBackAction?: () => void;
  sortOrder?: 'asc' | 'desc' | 'oldest' | 'newest';
  setSortOrder?: (value: 'asc' | 'desc' | 'oldest' | 'newest') => void;
  isSearching?: boolean;
  useBoxShadow?: boolean;
}

export default function AdminPrimaryHeader({
  showLanguageSelector = false,
  showSortSelector = false,
  tooltipText,
  title,
  titleIsElement = false,
  rightElement = undefined,
  bottomElement = undefined,
  showSearch = true,
  setSearchTerm = undefined,
  goBackAction = undefined,
  sortOrder = 'asc',
  setSortOrder = undefined,
  isSearching = false,
  useBoxShadow = false,
}: AdminPrimaryHeaderProps) {
  const { t } = useTranslation();
  const { getAvailableLanguages, setSelectedLanguage, selectedLanguage, defaultLanguage } =
    useLanguage();

  return (
    <Flex
      height={bottomElement ? 'auto' : '45px'}
      alignContent="center"
      alignItems="center"
      marginX={4}
      marginY={1}
      paddingX={6}
      paddingY={2}
      borderRadius="md"
      backgroundColor="white"
      boxShadow={useBoxShadow ? '0px 2px 2px rgba(0, 0, 0, 0.1);' : 'none '}
    >
      <Flex direction="column" width="full">
        <Flex width="full">
          <Flex width="30%" alignItems="center">
            {goBackAction && (
              <Button
                size="xs"
                marginRight={4}
                variant="adminPrimary"
                leftIcon={<ArrowLeft />}
                onClick={goBackAction}
              >
                {t('global.actions.back')}
              </Button>
            )}

            {titleIsElement && title}

            {!titleIsElement && (
              <Text fontSize="18px" fontWeight="medium" color="black">
                {title}
              </Text>
            )}
          </Flex>

          <TableSearch
            showSortSelector={showSortSelector}
            tooltipText={tooltipText}
            setSearchTerm={setSearchTerm}
            showSearch={showSearch}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            isSearching={isSearching}
          />

          <Flex justifyContent="end" width="30%">
            {showLanguageSelector && (
              <Menu variant="admin">
                <MenuButton
                  backgroundColor="transparent"
                  size="sm"
                  color="black"
                  textAlign="left"
                  fontSize="12px"
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  _hover={{ backgroundColor: 'brand.grey.10' }}
                  _active={{ backgroundColor: 'brand.grey.10' }}
                >
                  {selectedLanguage ? t(`${selectedLanguage.key}`) : t(`${defaultLanguage.key}`)}
                </MenuButton>

                <MenuList>
                  {getAvailableLanguages().map((language) => (
                    <MenuItem key={language.abr} onClick={() => setSelectedLanguage(language)}>
                      {t(`${language.name}`)}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            )}

            {rightElement}
          </Flex>
        </Flex>

        {bottomElement && bottomElement}
      </Flex>
    </Flex>
  );
}
