import { Box, Heading } from '@chakra-ui/react';
import { ComponentType, useState } from 'react';

import { Assignment } from '@/client/types/Assignment';
import { CalendarData } from '@/client/types/Calendar';
import CalendarHeader from './CalendarHeader';
import CalendarModal from './CalendarModal';
import { DashboardWorkshop } from '@/client/types/DashboardWorkshop';
import Weekdays from './Weekdays';
import { differenceInCalendarDays } from 'date-fns';

interface WorkshopAssignmentCalendarProps {
  assignments?: Assignment[];
  workshops?: DashboardWorkshop[];
  DayRenderer: ComponentType<any>;
  showHeading?: boolean;
}

export default function WorkshopAssignmentCalendar({
  assignments,
  workshops,
  DayRenderer,
  showHeading,
}: WorkshopAssignmentCalendarProps) {
  const [activeDate, setActiveDate] = useState(new Date());
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [currentAssignments, setCurrentAssignments] = useState<CalendarData[]>([]);
  const [currentWorkshops, setCurrentWorkshops] = useState<CalendarData[]>([]);
  const setCurrentCalendarData = (data: CalendarData[]) => {
    const currentWorkshopsFromData: CalendarData[] = [];
    const currentAssignmentsFromData: CalendarData[] = [];
    data.map((item) => {
      switch (item.dataType) {
        case 'assignment':
          currentAssignmentsFromData.push(item);
          break;
        case 'workshop':
          currentWorkshopsFromData.push(item);
          break;
        default:
          undefined;
      }
      return item;
    });
    setCurrentAssignments(currentAssignmentsFromData);
    setCurrentWorkshops(currentWorkshopsFromData);
  };

  const data: CalendarData[] = [];
  assignments?.forEach((assignment) => {
    assignment.assigned_content.map((content) => {
      const newContentObj = {
        dataType: 'assignment',
        key: content.content_id._id,
        id: content.content_id._id,
        title: content.content_id.title || content.content_id.name,
        contentType: content.content_id.type,
        imageUrl: content.content_id.banner_image,
        isCompleted: assignment.completed,
        startDate: assignment.due_date,
        dueIn: differenceInCalendarDays(new Date(assignment.due_date), new Date()),
      };
      data.push(newContentObj);
      return content;
    });
  });

  workshops?.forEach((workshop: Record<string, any>) => {
    if (!workshop.workshop || !workshop.workshop._id) return;

    const newWorkshopObj = {
      dataType: 'workshop',
      key: workshop.workshop._id,
      id: workshop.workshop._id,
      title: workshop.workshop.title,
      contentType: 'Workshop',
      imageUrl: workshop.workshop.image,
      startDate: workshop.start_date || workshop.workshop_instance.start_date,
      endDate: workshop.end_date || workshop.workshop_instance.end_date,
    };
    data.push(newWorkshopObj);
  });

  return (
    <Box backgroundColor="white" padding={4} marginTop={2}>
      {showHeading && (
        <Heading as="h2" fontSize="sm" textTransform="uppercase" marginBottom={4}>
          Upcoming Assignments
        </Heading>
      )}

      <CalendarHeader activeDate={activeDate} setActiveDate={setActiveDate} />

      <Weekdays activeDate={activeDate} />

      <DayRenderer
        {...{ data }}
        activeDate={activeDate}
        setCurrentCalendarData={setCurrentCalendarData}
        setIsCalendarModalOpen={setIsCalendarModalOpen}
      />

      <CalendarModal
        assignments={currentAssignments}
        workshops={currentWorkshops}
        isCalendarModalOpen={isCalendarModalOpen}
        setIsCalendarModalOpen={setIsCalendarModalOpen}
      />
    </Box>
  );
}

WorkshopAssignmentCalendar.defaultProps = {
  assignments: [],
  workshops: [],
  showHeading: false,
};
