import { Article, FileAlt, Scorm, Track, Video } from '@/client/components/icons/ContinuIcons';

interface ContentTypeIconProps {
  contentType: string;
  lightScheme?: boolean;
}

export default function ContentTypeIcon({ contentType, lightScheme }: ContentTypeIconProps) {
  let icon = <Article marginX={2} color={lightScheme ? 'white' : 'brand.gray.300'} />;

  switch (contentType) {
    case 'articles':
      icon = <Article marginX={2} color={lightScheme ? 'white' : 'brand.gray.300'} />;
      break;
    case 'tracks':
      icon = <Track marginX={2} color={lightScheme ? 'white' : 'brand.gray.300'} />;
      break;
    case 'scorm':
      icon = <Scorm marginX={2} color={lightScheme ? 'white' : 'brand.gray.300'} />;
      break;
    case 'videos':
      icon = <Video marginX={2} color={lightScheme ? 'white' : 'brand.gray.300'} />;
      break;
    case 'files':
      icon = <FileAlt marginX={2} color={lightScheme ? 'white' : 'brand.gray.300'} />;
      break;
  }

  return icon;
}
