/* eslint-disable react/jsx-props-no-spreading */
import { Close } from '@/client/components/icons/ContinuIcons';
import { IconButton } from '@chakra-ui/react';

interface HeaderCloseIconProps {
  closeColor?: string;
  navigateAction?: () => void;
  iconCssOptions?: any;
}

export default function HeaderCloseIcon({
  closeColor = 'brand.iconGrey',
  navigateAction = () => {},
  iconCssOptions = {},
}: HeaderCloseIconProps) {
  return (
    <IconButton
      aria-label="Close"
      variant="ghost"
      {...iconCssOptions}
      icon={
        <Close color={closeColor} _hover={{ color: 'brand.button' }} />
      }
      onClick={navigateAction}
    />
  );
}
