export default {
  article: 'Article',
  assessment: 'Assessment',
  imported_content: 'ImportedContent',
  media: 'Media',
  file: 'Media',
  video: 'Media',
  scorm: 'ScormContent',
  track: 'Track',
  workshop: 'Workshop',
  presentation: 'Presentation',
  journey: 'Journey',
};
