import { learnApiClient } from './clients/learnApiClient';
import { Company, CustomField } from '@/client/types/AuthConfig';

const getCompany = async (companyId: string): Promise<Company> => {
  const response = await learnApiClient.get(`/companies?companyId=${companyId}`);
  return response.data;
};

const getCustomFields = async (companyId: string): Promise<CustomField[]> => {
  const response = await learnApiClient.get(`/companies?companyId=${companyId}`);
  const { data } = response;
  const mappedFields = data.user_metadata_fields.map((field: Record<string, any>) => ({
    name: field.name,
    description: field.description,
    dataType: field.data_type,
    validationRules: field.validation_rules,
    visibility: field.visibility,
  }));
  const userMetadataFields = mappedFields as CustomField[];
  return userMetadataFields;
}

const updateCompanyCustomFields = async (
  companyId: string,
  fields: CustomField[],
): Promise<Company> => {
  const response = await learnApiClient.post(`companies/${companyId}/custom-fields`, {
    customFields: fields,
  });
  return response.data;
};

const CompanyService = {
  getCompany,
  getCustomFields,
  updateCompanyCustomFields,
};

export default CompanyService;
