import { learnApiClient } from './clients/learnApiClient';
import { preAuthApiClient } from './clients/preAuthApiClient';
import { CreateFormBody, Form, RegisterFromFormBody } from '@/client/types/RegistrationForms';
import { TablePaginationOptions } from '@/client/types/General';

const getAllForms = async (
  options: TablePaginationOptions,
  searchQuery?: any
) => {
  const response = await learnApiClient.get<{rows: Form[], count: number}>(
    'registration_forms', {params: {page: options.pageIndex, perPage: options.pageSize, query: searchQuery}}
  );

  return { rows: response.data.rows, pageCount: Math.ceil(response.data.count / options.pageSize!) || 1 };
};

const getFormCount = async () => {
  const response = await learnApiClient.get(
    `registration_forms/count`
  );
  return response.data;
};

const getForm = async (id: string) => {
  const response = await learnApiClient.get(
    `registration_forms/${id}`
  );

  return response.data;
};


const createForm = async (formBody: CreateFormBody) => {
  const response = await learnApiClient.post(
    `registration_forms`,
    formBody
    );
    
    return response.data;
  };
  
// Will update body type when update endpoint is ready
const updateForm = async (id: string, updateBody: any) => {
  const updatedFormResponse = await learnApiClient.patch(
    `registration_forms/${id}`,
    updateBody
    )
    
    return updatedFormResponse.data;
  }
    
 
  
// Public
const getFormByCode = async (domain: string, code: string) => {
  const response = await preAuthApiClient.get(
    `v3/public/registration_forms_by_code`, {params: {domain, code}}
  );

  return response.data;
};

const registerWithRegistrationFormCode = async (
  formId: string,
  domain: string,
  userData: RegisterFromFormBody
) => {
  const response = await preAuthApiClient.post(
    `v3/public/registration_forms/${formId}/register_user`,
    {
      domain,
      data: userData
    }
  );

  return response.data;
};

const RegistrationFormsService = {
  getAllForms,
  getFormCount,
  getForm,
  getFormByCode,
  createForm,
  updateForm,
  registerWithRegistrationFormCode
}

export default RegistrationFormsService;