import { Image } from '@chakra-ui/react';
import { useConfigStore } from '@/client/services/state/configStore';
import { useAuthStore } from '@/client/services/state/authStore';
import { usePartnerStore } from '@/client/services/state/partnerStore';

type LogoProps = {
  variant: 'explore' | 'login' | 'email';
};

export default function Logo({ variant }: LogoProps) {
  const { config } = useConfigStore();
  const { authConfig } = useAuthStore();
  const { partner }  = usePartnerStore();

  let url = "";
  if (authConfig?.user?.partner?.branding?.logo_url) {
    url = authConfig?.user?.partner?.branding?.logo_url;
  } else if (partner?.branding?.logo_url) {
    url = partner?.branding?.logo_url;
  } else {
    url = `${config.publicImageUrl}${config.layout}/logos/logo.${config.theme.logo_format}`;
  }

  const height =
    config.theme.logo_format === 'svg' && variant === 'explore'
      ? '32px'
      : 'auto';

  const dims = {
    login: {
      maxX: '250px',
      y: '48px',
      maxY: '48px',
    },
    explore: {
      maxX: '144px',
      y: '',
      maxY: '32px',
    },
    email: {
      maxX: '',
      y: '40px',
      maxY: '40px',
    },
  };

  return (
    <Image
      src={url}
      alt="Company Logo"
      display="inline-block"
      maxWidth={dims[variant].maxX}
      height={height}
      maxHeight={dims[variant].maxY}
    />
  );
}
