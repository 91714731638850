import { create } from 'zustand';

interface ValidationState {
  validating: boolean;
  setValidating: (validating: boolean) => void;
  returnedValidation: string;
  setReturnedValidation: (val: string) => void;
}

export const useValidationStore = create<ValidationState>((set) => ({
  validating: false,
  setValidating: (validating) => set({ validating }),
  returnedValidation: '',
  setReturnedValidation: (val) => set({ returnedValidation: val }),
}));
