import {
  Box,
  Center,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DefaultTFuncReturn } from 'i18next';
import { InView } from 'react-intersection-observer';
import { ReactElement } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
// eslint-disable-next-line import/extensions
import noSearchResults from '@/client/assets/images/noSearchResults.svg';
import { useTranslation } from 'react-i18next';

interface ImportedContentSearchProps {
  heading: DefaultTFuncReturn;
  searchPlaceholder: DefaultTFuncReturn;
  setSearchTerm: (searchTerm: string) => void;
  filterGroup?: ReactElement;
  status: string;
  isFetchingNextPage: boolean;
  canFetchNextPage: boolean;
  fetchNextPage: () => void;
  renderNoResults: boolean | undefined;
  renderResults: boolean | undefined;
  renderEndOfResults: boolean;
  searchResultsElement: ReactElement | undefined;
}

export default function ImportedContentSearch({
  heading,
  searchPlaceholder,
  setSearchTerm,
  filterGroup = undefined,
  status,
  isFetchingNextPage,
  canFetchNextPage,
  fetchNextPage,
  renderNoResults,
  renderResults,
  renderEndOfResults,
  searchResultsElement,
}: ImportedContentSearchProps) {
  const { t } = useTranslation();

  return (
    <Stack marginBottom={20}>
      <Text fontWeight={700} fontSize="32px" color="baseBlack" marginBottom={8}>
        {heading}
      </Text>

      <InputGroup marginBottom={4}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="neutral.500" />
        </InputLeftElement>

        <Input
          variant="create"
          placeholder={`${searchPlaceholder}`}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>

      {filterGroup && filterGroup}

      <>
        {status === 'loading' && (
          <Center paddingY={12}>
            <Spinner color="warmNeutral.800" />
          </Center>
        )}

        {renderNoResults && (
          <Center>
            <VStack spacing={2}>
              <Image src={noSearchResults} />

              <Text fontSize="18px" color="baseBlack" fontWeight={700}>
                {t('authoring.courses.importedContent.search.noResults')}
              </Text>

              <Text fontSize="14px" color="baseBlack" fontWeight={400}>
                {t('authoring.courses.importedContent.search.noResults.adjustSearch')}
              </Text>
            </VStack>
          </Center>
        )}

        {renderResults && searchResultsElement && (
          <Box>
            {searchResultsElement}

            {renderEndOfResults && (
              <Center marginTop={12}>
                <Text fontSize="md" fontWeight={600}>
                  {t('authoring.courses.importedContent.search.endOfResults')}
                </Text>
              </Center>
            )}

            {isFetchingNextPage && (
              <Center paddingY={12}>
                <Spinner color="warmNeutral.800" />
              </Center>
            )}

            <InView
              id="in-view"
              as="div"
              threshold={0}
              onChange={(inView) => {
                if (inView && canFetchNextPage) {
                  fetchNextPage();
                }
              }}
            />
          </Box>
        )}
      </>
    </Stack>
  );
}
