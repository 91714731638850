import { Text, Tooltip } from '@chakra-ui/react';

import { DefaultTFuncReturn } from 'i18next';
import { QuestionCircle } from '../../icons/ContinuIcons';

type HeadingProps = {
  title: string;
  helpText?: string | DefaultTFuncReturn;
  paddingTop?: string;
};

export default function HeadingWithTooltip({ title, helpText, paddingTop }: HeadingProps) {
  return (
    <Text paddingTop={paddingTop} fontSize="md" fontWeight="500" marginBottom={4}>
      {title}

      {helpText && (
        <Tooltip hasArrow label={helpText}>
          <QuestionCircle color="#1A1A1A80" marginLeft={1} />
        </Tooltip>
      )}
    </Text>
  );
}

HeadingWithTooltip.defaultProps = {
  paddingTop: '2',
  helpText: null,
};
