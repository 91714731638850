/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Progress, Text, useMultiStyleConfig, useTab } from '@chakra-ui/react';

import { forwardRef } from 'react';

export const CreateTab = forwardRef((props: any, ref) => {
  const { index, label, tabIndex } = props;
  const tabProps = useTab(props);
  const isSelected = !!tabProps['aria-selected'];
  const styles = useMultiStyleConfig('Tabs', tabProps);

  const setLabelColor = () => {
    if (isSelected || tabIndex >= index) {
      return 'baseBlack';
    }

    return 'neutral.800';
  };

  const setProgressValue = () => {
    if (isSelected) return 20;

    if (tabIndex > index) return 100;

    return 0;
  };

  return (
    <Button
      {...tabProps}
      __css={styles.tab}
      textAlign="left"
      flex={1}
      paddingTop={6}
      paddingRight={6}
      paddingBottom={3.5}
      paddingLeft={6}
      borderRadius="md"
      marginBottom={0}
      _selected={{ borderColor: 'none', color: 'none' }}
      _hover={{ background: !props.isDisabled ? 'warmNeutral.100' : 'none' }}
    >
      <Progress
        variant={tabIndex < index ? 'createDisabled' : 'create'}
        marginBottom={2}
        value={setProgressValue()}
      />

      <Text fontSize="12px" fontWeight={600} color={setLabelColor()}>
        {label}
      </Text>
    </Button>
  );
});
