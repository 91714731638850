import { Button, Flex, Input, Stack, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

import { useState } from 'react';

interface ShortInputQuestionProps {
  selected: string[];
  numberOfSelections: number;
  handleSelect: (id: string) => void;
  handleMultipleSelect: (value: string[]) => void;
}

export default function ShortInputQuestion({
  selected,
  numberOfSelections,
  handleSelect,
  handleMultipleSelect,
}: ShortInputQuestionProps) {
  const [value, setValue] = useState('');

  const handleMultipleInputs = () => {
    if (selected.length === numberOfSelections) {
      const newArr = selected.slice(1);
      newArr.push(value);

      handleMultipleSelect(newArr);
    }

    if (selected.length < numberOfSelections) {
      const newArr = [...selected, value];

      handleMultipleSelect(newArr);
    }

    setValue('');
  };

  const removeSelection = (value: string) => {
    const newArr = selected.filter((item) => item !== value);

    handleMultipleSelect(newArr);
  };

  return (
    <>
      {numberOfSelections > 1 ? (
        <Stack>
          <Flex>
            <Input
              variant="flushed"
              value={value}
              marginBottom={4}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleMultipleInputs();
                }
              }}
            />

            <Button isDisabled={value === ''} onClick={() => handleMultipleInputs()}>
              Add
            </Button>
          </Flex>

          {selected.length > 0 && (
            <Flex>
              {selected.map((item) => (
                <Tag key={item}>
                  <TagLabel>{item}</TagLabel>
                  <TagCloseButton onClick={() => removeSelection(item)} />
                </Tag>
              ))}
            </Flex>
          )}
        </Stack>
      ) : (
        <Input
          variant="flushed"
          value={selected.length ? selected[0] : ''}
          onChange={(e) => handleSelect(e.target.value)}
          placeholder="Type your answer here"
        />
      )}
    </>
  );
}
