import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { Category } from '@/client/types/Category';
import PeopleService from '@/client/services/api/PeopleService';
import { buildQueryString } from '@/client/utils/search/userSearch/buildQueryString';
import { useMultipleSelection } from 'downshift';

export interface UserSearchHook {
  selectedUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  selectedItems: Category[];
  setSelectedItems: React.Dispatch<React.SetStateAction<Category[]>>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  searchActive: boolean;
  setSearchActive: React.Dispatch<React.SetStateAction<boolean>>;
  handleCreateItem: (item: Category) => void;
  handleSelectedItemsChange: (newSelectedItems: Category[], changeType: string) => void;
  searchWithParamsInfinite: UseInfiniteQueryResult<any, unknown>;
  searchWithParamsPaginated: (fetchDataOptions: {
    pageIndex: number;
    pageSize: number;
  }) => UseQueryResult<any, unknown>;
  filteredCategories: { title: string; options: Category[] }[];
  activeGroupTab: 'users' | 'managers' | null;
  setActiveGroupTab: React.Dispatch<React.SetStateAction<'users' | 'managers' | null>>;
  handleSelectedUsersChange: (data: any[]) => void;
}

export const useUserSearch = (type: 'learners' | 'admin', groupId?: string) => {
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<Category[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [queryString, setQueryString] = useState('');
  const [activeGroupTab, setActiveGroupTab] = useState<'users' | 'managers' | null>(null);
  const [filteredCategories, setFilteredCategories] = useState<
    { title: string; options: Category[] }[]
  >([]);

  useEffect(() => {
    if (searchValue === '') setFilteredCategories([]);
  }, [searchValue]);

  const perPage = 20;

  const handleSelectedUsersChange = useCallback((data: any[]) => {
    setSelectedUsers(data);
  }, []);

  useEffect(() => {
    if (selectedItems.length > 0) {
      setSearchActive(true);
      setQueryString(buildQueryString(selectedItems, groupId, activeGroupTab));
    } else {
      setQueryString('');
      setSearchActive(false);
    }
  }, [selectedItems]);

  const handleCreateItem = (item: Category) => {
    setSelectedItems((curr) => [...curr, item]);
  };

  const handleSelectedItemsChange = (newSelectedItems: Category[], changeType: string) => {
    if (newSelectedItems.length > 0) {
      if (
        changeType === useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace ||
        changeType === useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem
      ) {
        setSelectedItems(newSelectedItems);
        return;
      }

      const isUser = newSelectedItems[newSelectedItems.length - 1].type === 'user';

      if (isUser) {
        setSelectedItems([newSelectedItems[newSelectedItems.length - 1]]);
        return;
      }

      const newItem = newSelectedItems[newSelectedItems.length - 1];

      const match = selectedItems.find((item) => item.type === newItem.type);

      if (match) {
        const existingIndex = selectedItems.findIndex((item) => item.type === newItem.type);
        const newArr = [...selectedItems];

        newArr[existingIndex] = newItem;

        setSelectedItems(newArr);
      } else {
        setSelectedItems(newSelectedItems);
      }
    } else {
      setSelectedItems(newSelectedItems);
    }
  };

  const searchWithParamsInfinite = useInfiniteQuery({
    enabled: queryString !== '' && type === 'learners',
    queryKey: ['user-search-infinite', type, queryString],
    queryFn: ({ pageParam = 1 }) => PeopleService.searchWithParamsInfinite(pageParam, queryString),
    getNextPageParam: (lastPage, pages) =>
      lastPage.length === perPage ? pages.length + 1 : undefined,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });

  const searchWithParamsPaginated = (fetchDataOptions: { pageIndex: number; pageSize: number }) =>
    useQuery({
      enabled: queryString !== '' && type === 'admin',
      queryKey: ['user-search-paginated', type, queryString, fetchDataOptions],
      queryFn: () => PeopleService.searchWithParamsPaginated(fetchDataOptions, queryString),
    });

  const { data: searchOptions } = useQuery({
    enabled: searchValue !== '',
    queryKey: ['search-options', searchValue, queryString],
    queryFn: () => PeopleService.getSearchOptions(searchValue),
    onSuccess: (data) => {
      // TODO: Remove this onSuccess callback
      setFilteredCategories(data);
    },
  });

  const userSearch = {
    selectedUsers,
    setSelectedUsers,
    selectedItems,
    setSelectedItems,
    searchValue,
    setSearchValue,
    searchActive,
    setSearchActive,
    handleCreateItem,
    handleSelectedItemsChange,
    searchWithParamsInfinite,
    searchWithParamsPaginated,
    filteredCategories,
    activeGroupTab,
    setActiveGroupTab,
    handleSelectedUsersChange,
    setQueryString,
    searchOptions,
  };

  return userSearch;
};
