import {
  HStack,
  Input,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Button,
} from '@chakra-ui/react';
import {
  ContentTypes,
  TextGenerationStatus,
  type Content,
} from '@/client/services/api/graphql/gql/graphql';
import CreateFormGroup from '@/client/components/admin/create/shared/layout/CreateFormGroup';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoSparklesSharp } from 'react-icons/io5';
import { ArticleFormData } from '@/client/routes/admin/create/content/EditArticle';
import GenerativeService from '@/client/services/api/graphql/GenerativeService';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { FileFormData } from "@/client/routes/admin/create/content/EditFile";
import { useAuthStore } from '@/client/services/state/authStore';

interface GenerateTagButtonProps {
  onClick: () => void;
}

function GenerateTagButton({ onClick }: GenerateTagButtonProps) {
  const { t } = useTranslation();
  return (
    <Button leftIcon={<IoSparklesSharp />} variant="generateAiTags" onClick={onClick}>
      {t('authoring.settings.tags.generateWithAi')}
    </Button>
  );
}

export default function AuthoringTagInput() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } =  authConfig;
  const { setToast } = useToastStore();
  const { watch, setValue } = useFormContext<Content & ArticleFormData & FileFormData>();

  const id = watch('id');
  const tags = watch('tags') || [];
  const description = watch('description') || '';
  const type = watch('contentType');
  const textGenerationStatus = watch('textGenerationStatus');

  const handleTagChange = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      const tag = e.currentTarget.value;

      if (tag && !tags.includes(tag)) {
        setValue('tags', [...tags, tag]);
        e.currentTarget.value = '';
      }
    }
  };

  const onTagGenerationSuccess = (data: string[]) => {
    const newTags = data.filter((tag) => !tags.includes(tag));
    setValue('tags', [...tags, ...newTags]);
    setToast({
      show: true,
      status: 'success',
      title: t('authoring.settings.tags.generateWithAi.success'),
    });
  };

  const onTagGenerationFailure = (error: any) => {
    console.log('Failed to retrieve ai generated tags: ', error);
    setToast({
      show: true,
      status: 'error',
      title: t('authoring.settings.tags.generateWithAi.error'),
    });
  };

  const articleTags = useMutation({
    mutationFn: (text: string) => GenerativeService.getGenerativeKeywords(text),
    onSuccess: (data) => onTagGenerationSuccess(data),
    onError: (e) => onTagGenerationFailure(e),
  });

  const mediaTags = useMutation({
    mutationFn: (contentId: string) => GenerativeService.getGenerativeKeywordsById(contentId),
    onSuccess: (data) => onTagGenerationSuccess(data),
    onError: (e) => onTagGenerationFailure(e),
  });

  return (
    <CreateFormGroup renderBorder={false}>
      <Stack>
        <Text variant="createLabel">{t('authoring.settings.tags.label')}</Text>

        <HStack justify="space-between" width="100%">
          <Text variant="createHelpText">{t('authoring.settings.tags.helpText')}</Text>

          {company.feature_flags.enable_generative_ai && description && type === ContentTypes.Article && (
            <GenerateTagButton
              onClick={() => {
                articleTags.mutate(description);
              }}
            />
          )}

          {company.feature_flags.enable_generative_ai && textGenerationStatus === TextGenerationStatus.Completed &&
            (type === ContentTypes.File || type === ContentTypes.Video) && (
              <GenerateTagButton
                onClick={() => {
                  mediaTags.mutate(id);
                }}
              />
            )}
        </HStack>

        <Input
          variant="create"
          placeholder={
            articleTags.status === 'loading' || mediaTags.status === 'loading'
              ? `${t('authoring.settings.tags.generateWithAi.placeholder')}`
              : `${t('authoring.settings.tags.placeholder')}`
          }
          disabled={articleTags.status === 'loading' || mediaTags.status === 'loading'}
          onKeyDown={handleTagChange}
        />

        {tags.length > 0 && (
          <HStack flexWrap="wrap">
            {tags.map((tag: string) => (
              <Tag key={tag} variant="create">
                <TagLabel>{tag}</TagLabel>

                <TagCloseButton
                  onClick={() =>
                    setValue(
                      'tags',
                      tags.filter((filterTag: string) => filterTag !== tag),
                    )
                  }
                />
              </Tag>
            ))}
          </HStack>
        )}
      </Stack>
    </CreateFormGroup>
  );
}
