import { ProductSubscriptionDuration } from '@/client/services/api/graphql/gql/graphql';
import { Box, Button, Flex, Image, Stack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// TODO: Refactor expected types to make passing things in less of a headache
type Product = {
  title: string;
  description: string;
  price: number;
  subscription_duration: ProductSubscriptionDuration;
  _id: string;
  images: string[];
};

type ProductCardProps = {
  product: Product;
  onSelect?: ((productId: string) => void) | undefined;
  onViewPayments?: ((productId: string) => void) | undefined;
  selected?: boolean;
  isPurchased?: boolean;
  managementButton?: React.ReactElement | null;
  nextBillingDate?: string;
};

type PriceTagProps = {
  price: number;
  duration: string;
};

export function PriceTag({ price, duration }: PriceTagProps) {
  const durationToDisplay: { [key: string]: string } = {
    MONTHLY: 'monthly',
    YEARLY: 'annually',
  };

  return (
    <Box
      padding={2}
      backgroundColor="brand.mist"
      width="fit-content"
      borderRadius={10}
      border="1px solid lightgray"
    >
      <Text as="b" fontSize="sm">{`$${price} / Billed ${
        durationToDisplay[duration] || duration
      }`}</Text>
    </Box>
  );
}

export default function ProductCard({
  product,
  onSelect = undefined,
  selected = false,
  onViewPayments = undefined,
  isPurchased = false,
  managementButton = null,
  nextBillingDate = '',
}: ProductCardProps) {
  const { t } = useTranslation();
  // const [expandDescription, setExpandDescription] = useState(false);
  // const collapsedDescriptionHeight = isPurchased ? '125px' : '85px';

  return (
    <Box
      padding={8}
      borderRadius={10}
      border="1px solid"
      backgroundColor={isPurchased ? 'brand.backgroundGrey' : 'white'}
      borderColor={selected ? 'brand.primary' : 'lightgray'}
    >
      <Flex direction="row" gap={8}>
        <Stack>
          <Image
            src={product.images[0] || '/public/images/cardPlaceholder.png'}
            width={200}
            height={100}
            borderRadius={10}
            marginBottom={4}
          />
          {!managementButton && !!onSelect && (
            <Button
              width={200}
              isDisabled={selected}
              variant="adminPrimary"
              borderRadius={10}
              onClick={() => onSelect(product._id)}
            >
              {t('ecommerce.productCard.selectSubscription')}
            </Button>
          )}
          {!!managementButton && (
            <Stack>
              {managementButton}
              {!!onViewPayments && (
                <Button
                  width={200}
                  isDisabled={selected}
                  variant="adminGhost"
                  borderRadius={10}
                  borderColor="brand.primary"
                  border="1px solid"
                  onClick={() => onViewPayments(product._id)}
                >
                  {t('ecommerce.productCard.viewSubscriptionPaymentHistory')}
                </Button>
              )}
            </Stack>
          )}
        </Stack>
        <Stack width={400} height="fit-content">
          <Text fontSize="lg" fontWeight="600">
            {product.title}
          </Text>
          <PriceTag price={product.price} duration={product.subscription_duration} />
          {!!isPurchased && !!nextBillingDate && (
            <Stack>
              <Text>{`${t('ecommerce.productCard.nextBillingDate')}: ${nextBillingDate}`}</Text>
              <Text>{t('ecommerce.productCard.renewedAutomatically')}</Text>
            </Stack>
          )}
          <Box
            fontSize="sm"
            height={
              // expandDescription ? 
              'fit-content' 
              // : collapsedDescriptionHeight
            }
            overflow="hidden"
            textOverflow="ellipsis"
            // onClick={() => setExpandDescription(!expandDescription)}
            // cursor="pointer"
          >
            {product.description}
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
}


