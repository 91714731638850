import { PointProps } from "@/client/types/Charts";

export function EquilateralTriangle({ size, color, borderWidth, borderColor }: PointProps) {
  const svgPoints = [`${-size / 2}, ${size / 2}`, `${size / 2}, ${size / 2}`, `${0}, ${-size / 2}`];
  const points = svgPoints.join(',');
  return (
    <g>
      <polygon
        points={points}
        fill={color}
        stroke={borderColor}
        strokeWidth={borderWidth}
        style={{ pointerEvents: 'none' }}
      />
    </g>
  );
};
