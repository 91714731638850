import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import ControlledCheckboxGroup from '@/client/components/admin/forms/ControlledCheckboxGroup';
import HeadingWithTooltip from '@/client/components/admin/forms/HeadingWithTooltip';
import { Stack } from '@chakra-ui/react';
import SwitchList from '@/client/routes/admin/profiles/forms/SwitchList'; // TODO: Move this component
import SwitchWithTooltip from '@/client/components/admin/forms/SwitchWithTooltip';
import { useAuthStore } from '@/client/services/state/authStore';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function ManagerConfiguration() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { watch } = useFormContext();

  const allowUserCreation = watch('manager_configuration.allow_user_creation');
  const allowUserEdit = watch('manager_configuration.allow_user_edit');

  const topLevelOptions = [
    {
      name: 'manager_configuration.disable_assignments',
      label: t('managerConfig.disableManagerContent'),
      enabled: true,
    },
    {
      name: 'manager_configuration.disable_assignment_edit',
      label: t('managerConfig.disableAssignEdit'),
      enabled: true,
    },
    {
      name: 'manager_configuration.disable_assignment_completion',
      label: t('managerConfig.disableAssignCompletion'),
      enabled: true,
    },
    {
      name: 'manager_configuration.disable_assignment_removal',
      label: t('managerConfig.disableAssignRemove'),
      enabled: true,
    },
    {
      name: 'manager_configuration.allow_user_creation',
      label: t('managerConfig.allowManagerToCreateUsers'),
      enabled: true,
    },
    {
      name: 'manager_configuration.hide_non_managing_groups',
      label: t('managerConfig.hideGroupsNotManaged'),
      enabled: true,
    },
    {
      name: 'manager_configuration.allow_direct_report_suspension',
      label: t('managerConfig.allowDirectReportSuspension'),
      enabled: true,
    },
  ];

  const segmentationOptions = [
    {
      label: t('managerConfig.linkedTeams'),
      value: 'linked_teams',
      enabled: true,
    },
    {
      label: t('managerConfig.linkedDepartments'),
      value: 'linked_departments',
      enabled: true,
    },
    {
      label: t('managerConfig.linkedLocations'),
      value: 'linked_locations',
      enabled: true,
    },
    {
      label: t('managerConfig.linkedGrades'),
      value: 'linked_grade',
      enabled: true,
    },
    {
      label: t('managerConfig.linkedOrgLevels'),
      value: 'linked_org_level',
      enabled: true,
    },
    {
      label: t('managerConfig.linkedGroups'),
      value: 'groups',
      enabled: company.groups,
    },
  ];

  const restrictedFieldOptions = [
    {
      label: t('managerConfig.restrictedFields.name'),
      value: 'name',
      enabled: true,
    },
    {
      label: t('managerConfig.restrictedFields.email'),
      value: 'email',
      enabled: true,
    },
    {
      label: t('managerConfig.restrictedFields.externalId'),
      value: 'userid',
      enabled: true,
    },
    {
      label: t('managerConfig.restrictedFields.jobTitle'),
      value: 'title',
      enabled: true,
    },
    {
      label: t('managerConfig.restrictedFields.languageSupport'),
      value: 'language',
      enabled: true,
    },
  ];

  return (
    <AdminElevatedBox>
      <Stack spacing={12}>
        <Stack>
          <HeadingWithTooltip title={t('managerConfig.managerConfig')} />

          <SwitchList enableSelectAll={false} options={topLevelOptions} />
        </Stack>

        {allowUserCreation && (
          <>
            <Stack>
              <HeadingWithTooltip title={t('managerConfig.requiredSegmentationFieldsForGroups')} />

              <ControlledCheckboxGroup
                formValueName="manager_configuration.segmentation_required"
                options={segmentationOptions}
              />
            </Stack>

            <Stack>
              <HeadingWithTooltip title={t('managerConfig.restrictSegmentationgForGroups')} />

              <ControlledCheckboxGroup
                formValueName="manager_configuration.segmentation_subset"
                options={segmentationOptions}
              />
            </Stack>

            <Stack>
              <HeadingWithTooltip title={t('managerConfig.hideUserValuesForGroups')} />

              <ControlledCheckboxGroup
                formValueName="manager_configuration.hide_options"
                options={segmentationOptions}
              />
            </Stack>
          </>
        )}

        <Stack>
          <HeadingWithTooltip title={t('managerConfig.managerEdit')} />

          <SwitchWithTooltip
            name="manager_configuration.allow_user_edit"
            label={t('managerConfig.allowUserEdits')}
          />
        </Stack>

        {allowUserEdit && (
          <>
            <Stack>
              <SwitchWithTooltip
                name="manager_configuration.restricted_user_edit"
                label={t('managerConfig.restrictFields')}
              />
            </Stack>

            <Stack>
              <SwitchWithTooltip
                name="manager_configuration.allow_password_reset"
                label={t('managerConfig.allowPasswordReset')}
              />
            </Stack>

            <Stack>
              <HeadingWithTooltip title={t('managerConfig.allowedEditableFields')} />

              <ControlledCheckboxGroup
                formValueName="manager_configuration.restricted_editable_fields"
                options={restrictedFieldOptions}
              />
            </Stack>

            <Stack>
              <HeadingWithTooltip title={t('managerConfig.appointManager')} />

              <SwitchWithTooltip
                name="manager_configuration.allow_adding_group_manager"
                label={t('managerConfig.allowGroupAllocation')}
              />
            </Stack>
          </>
        )}
      </Stack>
    </AdminElevatedBox>
  );
}
