import { Circle, Flex, Icon, ListItem, Text } from '@chakra-ui/react';

import { SearchIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';

export default function NoSearchResultsListItem() {
  const { t } = useTranslation();

  return (
    <ListItem paddingX={4} paddingY={4}>
      <Flex alignItems="center" width="full">
        <Circle backgroundColor="gray.100" size="30px">
          <Icon color="neutral.1200" as={SearchIcon} />
        </Circle>

        <Text paddingLeft={4} fontSize="sm" fontWeight={500} color="neutral.1000">
          {t('authoring.segmentation.noResults')}
        </Text>
      </Flex>
    </ListItem>
  );
}
