import { EditReportData } from '@/client/types/admin/reports/EditReportData';
import { ReportData } from '@/client/types/admin/reports/ReportData';
import { learnApiClient } from '../../clients/learnApiClient';
import { reportsApiClient } from '../../clients/reportsApiClient';
import { format } from 'date-fns';

const formatDates = (url: string, dateRange: Date[]) =>
  url +
  '?' +
  new URLSearchParams({
    from: format(dateRange[0], 'yyyy-MM-dd'),
    until: format(dateRange[1], 'yyyy-MM-dd'),
  });

const getReports = async (
  fetchDataOptions: { pageIndex: number; pageSize: number },
  userId?: string,
  searchTerm?: string | undefined,
): Promise<{ rows: ReportData['docs']; pageCount: number }> => {
  let params: {
    page: number;
    limit: number;
    created_by?: string;
    search?: { name: string | undefined };
  } = {
    page: fetchDataOptions.pageIndex + 1,
    limit: fetchDataOptions.pageSize,
  };

  if (userId) {
    params = {
      ...params,
      created_by: userId,
    };
  }

  if (searchTerm !== '') {
    params = {
      ...params,
      search: { name: searchTerm },
    };
  }

  const response = await learnApiClient.get<ReportData>('custom-reports', {
    params,
  });

  return {
    rows: response.data.docs,
    pageCount: Math.ceil(response.data.totalDocs / fetchDataOptions.pageSize) || -1,
  };
};

const getReport = async (reportId: string | undefined): Promise<EditReportData> => {
  const response = await learnApiClient.get(`custom-reports/${reportId}`);

  return response.data;
};

const generateReport = async (
  reportDetails: { report: string; from: number; until: number },
  reportRequest: any,
) => {
  let url = '';
  
  if (reportDetails.report === 'workshop-status' || reportDetails.report === 'assessment-status') {
    url = `${reportDetails.report}`;
  } else if (reportDetails.report === 'content-completion') {
    url = `user-content/${reportDetails.from}/${reportDetails.until}`;
  } else {
    url = `${reportDetails.report}/${reportDetails.from}/${reportDetails.until}`;
  }

  const response = await reportsApiClient.post(`reports/${url}`, {
    ...reportRequest,
  });

  return response.data;
};

const deleteReport = async (reportId: string) => {
  const response = await learnApiClient.delete(`custom-reports/${reportId}`);

  return response.data;
};

const duplicateReport = async (reportId: string) => {
  const response = await learnApiClient.post(`custom-reports/${reportId}/duplicate`);

  return response.data;
};

interface ReportFormData {
  name: string;
  report_fields: string[];
  report_filters: any;
  report_options: any;
  report_type: string;
}

const createReport = async (reportFormData: ReportFormData): Promise<EditReportData> => {
  const response = await learnApiClient.post('custom-reports', reportFormData);

  return response.data;
};

const updateReport = async (
  reportId: string | undefined,
  reportFormData: ReportFormData,
): Promise<EditReportData> => {
  const response = await learnApiClient.post(`custom-reports/${reportId}`, reportFormData);

  return response.data;
};

const getPartnerAssignedCompletions = async (dates: Date[]) => {
  const urlWithDates = formatDates('analytics-partner/assigned-completion-counts', dates);
  const response = await reportsApiClient.get(urlWithDates);
  return response.data;
};

const getPartnerAssignmentsByWorkflow = async (dates: Date[]) => {
  const urlWithDates = formatDates('analytics-partner/assignments-from-workflows-counts', dates);
  const response = await reportsApiClient.get(urlWithDates);
  return response.data;
};

const getPartnerAssignmentsOnTime = async (dates: Date[]) => {
  const urlWithDates = formatDates('analytics-partner/assigned-status-due-counts', dates);
  const response = await reportsApiClient.get(urlWithDates);
  return response.data;
};

const ReportsService = {
  getReports,
  getReport,
  generateReport,
  deleteReport,
  duplicateReport,
  createReport,
  updateReport,
  getPartnerAssignedCompletions,
  getPartnerAssignmentsByWorkflow,
  getPartnerAssignmentsOnTime,
};

export default ReportsService;
