import { Alert, AlertDescription, Link, Text } from '@chakra-ui/react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { useTrackStateStore } from '@/client/services/state/admin/create/trackStateStore';
import { useTranslation } from 'react-i18next';

interface TrackStateAlertProps {
  contentType: 'article' | 'file' | 'video' | 'scorm';
}

export default function TrackStateAlert({ contentType }: TrackStateAlertProps) {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { trackState } = useTrackStateStore();

  const contentTypeToTranslationMap = {
    article: t('authoring.trackState.article'),
    file: t('authoring.trackState.file'),
    video: t('authoring.trackState.video'),
    scorm: t('authoring.trackState.scorm'),
  };

  if (!trackState) return null;

  return (
    <Alert
      position="absolute"
      top={0}
      zIndex={999}
      status="success"
      backgroundColor="brand.primary"
      justifyContent="center"
    >
      <AlertDescription color="white">
        {id ? t('authoring.trackState.editing') : t('authoring.trackState.adding')}{' '}
        {contentTypeToTranslationMap[contentType]}{' '}
        {id ? t('authoring.trackState.inThe') : t('authoring.trackState.toThe')}{' '}
        <Text as="b">{trackState.trackTitle}</Text> {t('authoring.trackState.learningTrack')}{' '}
        <Link
          as={RouterLink}
          to={`/admin/edit/course/track/${trackState.track}`}
          color="white"
          _hover={{ color: 'white', textDecoration: 'underline' }}
        >
          {t('authoring.trackState.cancel')}
        </Link>
      </AlertDescription>
    </Alert>
  );
}
