import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import ExternalUserSelection from './ExternalUserSelection';
import PartnerSelection from './PartnerSelection';
import SegmentationFormField from '../SegmentationFormField';
import SegmentationFormFieldGroup from '../SegmentationFormFieldGroup';
import { QuestionCircle } from '@/client/components/icons/ContinuIcons';
import { useTranslation } from 'react-i18next';
import { Fragment, useRef } from 'react';
import { useAuthStore } from '@/client/services/state/authStore';
import { SegmentationField, UserType } from '@/client/types/RegistrationForms';

type SegmentationFormProps = {
  fieldData: SegmentationField;
  updateField: (data: SegmentationField) => void;
  externalUsersEnabled: boolean;
  userType: UserType;
  updateUserType: (userType: UserType) => void;
  selectedPartner: string | undefined;
  setSelectedPartner: (partner: string) => void;
};

export default function SegmentationForm({
  fieldData,
  updateField,
  externalUsersEnabled,
  userType,
  updateUserType,
  selectedPartner,
  setSelectedPartner,
}: SegmentationFormProps) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const partnersEnabled = company.feature_flags.enable_partners;
  const ecommerceEnabled = company.feature_flags.enable_ecommerce;

  // After initial setup of the SegmentationForm, never allow updates from the outside again.
  // All data selection should always be pushed out.  If updates are required, create a new instance.
  const fieldDataState = useRef<SegmentationField>(fieldData);

  const updateSegmentation = (categoryType: string, categoryId: string) => {
    const newCategory: any = {};
    if (Array.isArray(fieldData[categoryType as keyof SegmentationField])) {
      if (categoryId) {
        newCategory[categoryType] = [categoryId];
      } else {
        newCategory[categoryType] = [];
      }
    } else {
      newCategory[categoryType] = categoryId;
    }

    updateField({ ...fieldDataState.current, ...newCategory });
    fieldDataState.current = { ...fieldDataState.current, ...newCategory };
  };

  const getCategoryIdFromFieldData = (categoryType: string): string => {
    const catData = fieldData[categoryType as keyof SegmentationField];
    if (Array.isArray(catData)) {
      if (catData.length > 0) {
        return catData[0];
      }
      return '';
    }
    return catData;
  };

  return (
    <Box paddingTop={4}>
      {(externalUsersEnabled || partnersEnabled) && (
        <>
          {externalUsersEnabled && (
            <ExternalUserSelection userType={userType} updateUserType={updateUserType} />
          )}
          {partnersEnabled && userType !== 'external' && (
            <PartnerSelection
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
            />
          )}
        </>
      )}

      {!selectedPartner && (
        <>
          <Text pb={4} fontSize="md" fontWeight="500">
            {t('edit.general.headlines_segmentation')}
            <Tooltip hasArrow label={t('registrationForms.tooltip.segmentation')}>
              <QuestionCircle color="#1A1A1A80" marginLeft={1} />
            </Tooltip>
          </Text>
          <SegmentationFormField
            type="location"
            fieldLabel="Location"
            helpLabel={t('registrationForms.help.segment', { type: 'location' })}
            initialId={getCategoryIdFromFieldData('location')}
            updateCategory={updateSegmentation}
          />
          <SegmentationFormField
            type="department"
            fieldLabel="Department"
            helpLabel={t('registrationForms.help.segment', { type: 'department' })}
            initialId={getCategoryIdFromFieldData('department')}
            updateCategory={updateSegmentation}
          />
          <SegmentationFormField
            type="team"
            fieldLabel="Team"
            helpLabel={t('registrationForms.help.segment', { type: 'team' })}
            initialId={getCategoryIdFromFieldData('team')}
            updateCategory={updateSegmentation}
          />
          <SegmentationFormField
            type="org_level"
            fieldLabel="Level"
            helpLabel={t('registrationForms.help.segment', { type: 'level' })}
            initialId={getCategoryIdFromFieldData('org_level')}
            updateCategory={updateSegmentation}
          />
          <SegmentationFormField
            type="grade"
            fieldLabel="Grade"
            helpLabel={t('registrationForms.help.segment', { type: 'grade' })}
            initialId={getCategoryIdFromFieldData('grade')}
            updateCategory={updateSegmentation}
          />

          <Text mb={1} fontSize="sm" fontWeight="500">
            Group
            <Tooltip hasArrow label={t('registrationForms.help.segment', { type: 'group' })}>
              <QuestionCircle color="#1A1A1A80" marginLeft={1} />
            </Tooltip>
          </Text>
          <Flex direction="row" justifyContent="space-between" gap={4} mb={4}>
            <SegmentationFormFieldGroup
              key="group"
              fieldLabel="group"
              type="group"
              initialId={getCategoryIdFromFieldData('group')}
              updateSelectedData={updateSegmentation}
            />
            <div style={{ flex: 0.33 }} />
            <div style={{ flex: 0.33 }} />
          </Flex>
        </>
      )}
    </Box>
  );
}
