/* eslint-disable react/no-unstable-nested-components */
import { Box, Badge, Link } from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import ProductsService from '@/client/services/api/graphql/ProductsService';
import { useQuery } from '@tanstack/react-query';
import { Purchase, Product, PageInfo } from '@/client/services/api/graphql/gql/graphql';

import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';

import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@/client/services/state/authStore';
import { useListProducts } from '@/client/services/hooks/content/products/useListProducts';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import AdminTableWrapper from '@/client/components/admin/layout/AdminTableWrapper';
import { Link as RouterLink } from 'react-router-dom';
import { useFormatDate } from '@/client/services/hooks/date-and-time/useFormatDate';

export default function PurchasesList() {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const { formatDate } = useFormatDate();
  useDocumentTitle(t('overviewProducts.overview'));
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, refetch, isError: purchasesError } = useQuery({
    queryKey: ['list-purchases', pageIndex, pageSize, selectedProducts],
    queryFn: () => {
      const options: any = {
        includeTotalCount: true,
        page: pageIndex,
        pageSize,
      }

      if (selectedProducts.length) {
        options.product = {in: selectedProducts.map((product: Product) => product.id)}
      }
      
      return ProductsService.listPurchases(options)
    },
  });

  const formatProductData = (returnedProductData: { products: Product[]; pageInfo: PageInfo }) => [
    { title: 'Products', options: returnedProductData.products },
  ];
  
  const {
    data: productData,
    isLoading: productsLoading,
  } = useListProducts({
        page: pageIndex,
        pageSize,
        includeTotalCount: true,
        title: { contains: searchTerm },
      },
    );

  const columnHelper = createColumnHelper<Purchase>();
  const columns = useMemo<ColumnDefBase<Purchase, any>[]>(
    () => [
      columnHelper.accessor('productDetails.title', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('overviewProducts.title')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('userName', {
        cell: (info) => (
          <Link as={RouterLink} to={`/admin/users/edit/single/${info.row.original.user}`}>
            {info.getValue()}
          </Link>
        ),
        header: () => <span>{t('overviewProducts.user')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('purchaseConfirmedDate', {
        cell: (info) => (
          <Box>{info.getValue() ? formatDate(new Date(info.getValue()), 'dateSlash') : '----'}</Box>
        ),
        header: () => <span>{t('overviewProducts.purchaseDate')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('subscriptionActive', {
        cell: (info) => (
          <Badge
            borderRadius="5px"
            variant={info.getValue() ? 'active' : 'inactive'}
            width="70%"
            padding={1}
            textAlign="center"
          >
            {info.getValue() ? 'Active' : 'Canceled'}
          </Badge>
        ),
        header: () => <span>{t('overviewProducts.subscriptionStatus')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('productDetails.subscriptionDuration', {
        cell: (info) => <Box>{info.getValue() === 'MONTHLY' ? 'Monthly' : 'Annually'}</Box>,
        header: () => <span>{t('overviewProducts.termLength')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('productDetails.price', {
        cell: (info) => `$${info.getValue()}`,
        header: () => <span>{t('overviewProducts.price')}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    [],
  );
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  useEffect(() => {
    refetch();
  }, [pageIndex]);

  if (purchasesError) return <ErrorAlert title="Error getting purchases" />;

  return (
    <Box
      padding={6}
      backgroundColor="white"
      margin={2}
      marginX={4}
      borderRadius="10px"
      boxShadow="0 2px 2px rgba(0,0,0,0.1)"
    >
      <AdminTableWrapper
        selectSearch
        setSearchTerm={(term) => setSearchTerm(term)}
        setSelected={(selected) => setSelectedProducts(selected)}
        selectedItems={selectedProducts}
        searchData={!!searchTerm?.length && productData && formatProductData(productData) || []}
        sortOrder="asc"
        setSortOrder={() => {}}
        isSearching={productsLoading}
        tooltipText='Get help'
      >
        <ManuallyPaginatedTable
          columns={columns}
          queryData={{
            rows: data ? data.purchases : [],
            pageCount: data?.pageInfo?.totalCount
              ? Math.ceil(!!data && (data.pageInfo.totalCount / pageSize))
              : null,
          }}
          rowSelection={{}}
          setRowSelection={() => {}}
          pagination={pagination}
          setPagination={setPagination}
        />
      </AdminTableWrapper>
    </Box>
  );
}
