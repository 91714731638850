import { Center } from '@chakra-ui/react';
import FormLabelWithTooltip from '@/client/components/admin/forms/FormLabelWithTooltip';
import { ProfileForm } from '@/client/types/admin/profiles/ProfileForm';
import SwitchList from '@/client/routes/admin/profiles/forms/SwitchList';
import { useConfigStore } from '@/client/services/state/configStore';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function ProfileContentEdit() {
  const { t } = useTranslation();
  const { watch } = useFormContext<ProfileForm>();
  const { config } = useConfigStore();

  const allContentEdit = watch('permissions.admin.content.all_edit');

  const contentEditOptions = [
    {
      name: 'permissions.admin.content.all_edit',
      label: t('profiles.edit.editAny'),
      enabled: true,
    },
    {
      name: 'permissions.admin.content.my_content_edit',
      label: t('profiles.edit.editMyContent'),
      enabled: true,
    },
    {
      name: 'permissions.admin.content.workshops_edit',
      label: t('profiles.edit.editWorkshops'),
      enabled: true,
    },
    {
      name: 'permissions.admin.content.articles_edit',
      label: t('profiles.edit.editArticles'),
      enabled: true,
    },
    {
      name: 'permissions.admin.content.files_edit',
      label: t('profiles.edit.editFiles'),
      enabled: true,
    },
    {
      name: 'permissions.admin.content.videos_edit',
      label: t('profiles.edit.editVideos'),
      enabled: true,
    },
    {
      name: 'permissions.admin.content.scorm_edit',
      label: t('profiles.edit.editScorm'),
      enabled: true,
    },
    {
      name: 'permissions.admin.content.assessments_edit',
      label: t('profiles.edit.editAssessments'),
      enabled: true,
    },
    {
      name: 'permissions.admin.content.tracks_edit',
      label: t('profiles.edit.editTracks'),
      enabled: true,
    },
    {
      name: 'permissions.admin.content.imported_content_edit',
      label: t('profiles.edit.editThirdParty'),
      enabled: true,
    },
    // {
    //   name: 'permissions.admin.content.surveys_edit',
    //   label: t('profiles.edit.editSurveys'),
    //   enabled: config.features.enable_surveys,
    // },
  ];

  return (
    <>
      <Center>
        <FormLabelWithTooltip label={t('edit.profiles.editContent')} />
      </Center>

      <SwitchList allChecked={allContentEdit} options={contentEditOptions} />
    </>
  );
}
