import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import AdminUserOverviewReporting from './AdminUserOverViewReporting';
import ConfirmedUsers from '@/client/components/admin/user-management/user-overview/ConfirmedUsers';
import PendingUsers from '@/client/components/admin/user-management/user-overview/PendingUsers';
import SuspendedUsers from '@/client/components/admin/user-management/user-overview/SuspendedUsers';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useTranslation } from 'react-i18next';

export default function AdminUserOverview() {
  useDocumentTitle('Users Overview');
  const { t } = useTranslation();
  const { isAllowed } = useAccessHelper();

  const canAddUsers = isAllowed(['admin', 'users', 'add'], null, ['admin']);

  return (
    <AdminElevatedBox>
      {canAddUsers && <AdminUserOverviewReporting />}

      <Tabs variant="admin" isLazy>
        <TabList>
          <Tab>{t('manage.users.confirmed')}</Tab>

          <Tab>{t('manage.users.pending')}</Tab>

          <Tab>{t('overviewUsers.suspended')}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ConfirmedUsers />
          </TabPanel>

          <TabPanel>
            <PendingUsers />
          </TabPanel>

          <TabPanel>
            <SuspendedUsers />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </AdminElevatedBox>
  );
}
