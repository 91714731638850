/* eslint-disable react/jsx-props-no-spreading */
import {
  HStack,
  Flex,
  Box,
  useToken,
  Input,
  Stack,
  Button,
  Icon,
  IconButton,
  ButtonGroup,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
  useDisclosure,
  Alert,
  AlertDescription,
  AlertIcon,
} from '@chakra-ui/react';
import { BsPlusCircle } from 'react-icons/bs';
import { BiErrorCircle } from 'react-icons/bi';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { RiDragMove2Fill } from 'react-icons/ri';
import { CiTrash } from 'react-icons/ci';

import { useState, useRef, useEffect } from 'react';
import invariant from 'tiny-invariant';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { TCourseSection, getCourseSectionData, isCourseSectionData } from './course-section-data';
import DraggableCourseContentList from './DraggableCourseContentList';
import { CloseIcon } from '@chakra-ui/icons';
import { useTrackStateStore } from '@/client/services/state/admin/create/trackStateStore';
import { useNavigate } from 'react-router-dom';
import { useAuthorTrack } from '@/client/services/hooks/admin/authoring/courses/useAuthorTrack';
import { useAuthStore } from '@/client/services/state/authStore';
import { useTranslation } from 'react-i18next';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
// eslint-disable-next-line import/extensions
import removeModalImage from '@/client/assets/images/removeModalImage.svg';
import {
  TrackArticle,
  TrackAssessment,
  TrackFile,
  TrackScorm,
  TrackVideo,
} from '@/client/components/icons/ContinuIcons';
import { useFormContext } from 'react-hook-form';
import TinyMceEditor from '../../../shared/TinyMceEditor';

type SectionState =
  | {
      type: 'idle';
    }
  | {
      type: 'preview';
      container: HTMLElement;
    }
  | {
      type: 'is-dragging';
    }
  | {
      type: 'is-dragging-over';
      closestEdge: Edge | null;
    };

const idle: SectionState = { type: 'idle' };

export default function CourseSection({ section }: { section: TCourseSection }) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { setInstructorDrawerIsOpen, setSelectedSectionIndex, renderErrors } = useCreateStore();
  const {
    register,
    watch,
    setValue,
    setFocus,
    trigger,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const ref = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<SectionState>(idle);
  const [newContentListOpen, setNewContentListOpen] = useState(false);
  const [borderColor] = useToken('colors', ['warmNeutral.500']);
  const { setTrackState } = useTrackStateStore();
  const navigate = useNavigate();
  const { submitTrackMutation } = useAuthorTrack();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const sections = watch('sections');

  const handleRemoveSection = (index: number) => {
    sections.splice(index, 1);
    setValue('sections', sections);
  };

  useEffect(() => {
    if (section.courses.length === 0) {
      trigger(`sections.${section.index}`);

      return;
    }

    clearErrors(`sections.${section.index}`);
  }, [section.courses]);

  useEffect(() => {
    const element = ref.current;
    invariant(element);
    return combine(
      draggable({
        element,
        getInitialData() {
          return getCourseSectionData(section);
        },
        onDragStart() {
          setState({ type: 'is-dragging' });
        },
        onDrop() {
          setState(idle);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          // * Don't allow items to drop on themselves
          if (source.element === element) {
            return false;
          }
          // * Only allow course section data to be dropped
          return isCourseSectionData(source.data);
        },
        getData({ input }) {
          const data = getCourseSectionData(section);
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ['top', 'bottom'],
          });
        },
        getIsSticky() {
          return true;
        },
        onDragEnter({ self }) {
          const closestEdge = extractClosestEdge(self.data);
          setState({ type: 'is-dragging-over', closestEdge });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          // * Only need to update react state if nothing has changed.
          // * Prevents re-rendering.
          setState((current) => {
            if (current.type === 'is-dragging-over' && current.closestEdge === closestEdge) {
              return current;
            }

            return { type: 'is-dragging-over', closestEdge };
          });
        },
        onDragLeave() {
          setState(idle);
        },
        onDrop() {
          setState(idle);
        },
      }),
    );
  }, [section]);

  const handleGoToContent = async (
    contentType: 'article' | 'file' | 'video' | 'scorm' | 'assessment',
  ) => {
    await submitTrackMutation
      .mutateAsync({
        approval_required: user.is_collaborator,
        approved: !user.is_collaborator,
        draft: watch('draft'),
      })
      .then(() => {
        setTrackState({
          contentId: null,
          contentPosition: null,
          contentTitle: null,
          contentType,
          section: section.index,
          track: watch('id'),
          trackTitle: watch('title'),
          ttl: new Date().valueOf() + 5000,
          returningFromContent: true,
        });

        if (contentType !== 'assessment') {
          navigate(`/admin/edit/${contentType}`);

          return;
        }

        window.location.replace('/pvt/#/edit/assessments');
      });
  };

  useEffect(() => {
    if (section.index === sections.length - 1) {
      const timer = setTimeout(() => {
        setFocus(`sections.${section.index}.name`);
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [sections.length, setFocus]);

  // @ts-ignore
  const fieldError = errors.sections?.[section.index];

  return (
    <Box
      paddingY={4}
      position="relative"
      backgroundColor={state.type === 'is-dragging-over' ? 'warmNeutral.0' : 'transparent'}
      sx={{
        opacity: state.type === 'is-dragging' ? 0.4 : 1,
        borderTop:
          state.type === 'is-dragging-over' && state.closestEdge === 'top'
            ? `1px solid ${borderColor}`
            : 'none',
        borderBottom:
          state.type === 'is-dragging-over' && state.closestEdge === 'bottom'
            ? `1px solid ${borderColor}`
            : 'none',
      }}
    >
      {fieldError && renderErrors && (
        <Alert variant="createError">
          <AlertIcon as={BiErrorCircle} />

          <AlertDescription>
            {fieldError.name && !fieldError.courses && 'This section is missing a name.'}

            {!fieldError.name && fieldError.courses && 'This section is missing courses.'}

            {fieldError.name && fieldError.courses && 'This section is missing a name and courses.'}

            {sections.length !== 1 && ' Complete the section or delete it to proceed.'}
          </AlertDescription>
        </Alert>
      )}

      <Stack ref={ref} spacing={0}>
        <Flex justifyContent="flex-end">
          <HStack>
            {sections.length > 1 && (
              <>
                <Icon
                  boxSize={4}
                  as={RiDragMove2Fill}
                  color="baseBlack"
                  _hover={{ cursor: 'grab' }}
                />
                <Icon
                  boxSize={4}
                  as={CiTrash}
                  color="red"
                  _hover={{ cursor: 'pointer' }}
                  onClick={onOpen}
                />
              </>
            )}
          </HStack>
        </Flex>

        <Stack marginBottom={8}>
          <Input
            {...register(`sections.${section.index}.name` as const)}
            paddingLeft={0}
            placeholder={`${t('authoring.courses.sectionName')}`}
            variant="ghost"
            fontSize="18px"
            fontWeight={600}
            _placeholder={{ color: 'neutral.500' }}
          />

          <Box>
            <TinyMceEditor
              inline
              isRequired
              formName={`sections.${section.index}.description`}
              placeholder={`${t('authoring.courses.sectionDescription')}`}
              label=""
              useMenu={false}
              toolbarOpts={false}
              customStyles="/public/styles/tinymce/tracks-tinymce-styles.css"
            />
          </Box>
        </Stack>

        {section.courses && section.courses.length > 0 && (
          <DraggableCourseContentList
            courseContent={section.courses}
            sectionIndex={section.index}
          />
        )}
      </Stack>

      {!newContentListOpen && (
        <ButtonGroup isDisabled={!section.name || section.name === ''} width="full" marginTop={8}>
          <Button
            variant="warmNeutralDashedBorder"
            width="full"
            leftIcon={<BsPlusCircle />}
            onClick={() => setNewContentListOpen(true)}
          >
            {t('authoring.courses.newContent')}
          </Button>

          <Button
            variant="warmNeutralDashedBorder"
            width="full"
            leftIcon={<BsPlusCircle />}
            onClick={() => {
              setSelectedSectionIndex(section.index);
              setInstructorDrawerIsOpen(true);
            }}
          >
            {t('authoring.courses.existingContent')}
          </Button>
        </ButtonGroup>
      )}

      {newContentListOpen && (
        <ButtonGroup width="full" marginTop={8} alignItems="center">
          <Button
            height="fit-content"
            flex={1}
            variant="warmNeutralDashedBorder"
            onClick={() => handleGoToContent('article')}
          >
            <VStack>
              <TrackArticle boxSize={6} />

              <Text>{t('authoring.courses.add.article')}</Text>
            </VStack>
          </Button>

          <Button
            flex={1}
            height="fit-content"
            variant="warmNeutralDashedBorder"
            onClick={() => handleGoToContent('video')}
          >
            <VStack>
              <TrackVideo boxSize={6} />

              <Text>{t('authoring.courses.add.video')}</Text>
            </VStack>
          </Button>

          <Button
            flex={1}
            height="fit-content"
            variant="warmNeutralDashedBorder"
            onClick={() => handleGoToContent('file')}
          >
            <VStack>
              <TrackFile boxSize={6} />

              <Text>{t('authoring.courses.add.file')}</Text>
            </VStack>
          </Button>

          <Button
            flex={1}
            height="fit-content"
            variant="warmNeutralDashedBorder"
            onClick={() => handleGoToContent('assessment')}
          >
            <VStack>
              <TrackAssessment boxSize={6} />

              <Text>{t('authoring.courses.add.assessment')}</Text>
            </VStack>
          </Button>

          <Button
            flex={1}
            height="fit-content"
            variant="warmNeutralDashedBorder"
            onClick={() => handleGoToContent('scorm')}
          >
            <VStack>
              <TrackScorm boxSize={6} />

              <Text>{t('authoring.courses.add.scorm')}</Text>
            </VStack>
          </Button>

          <IconButton
            border="2px solid"
            borderColor="neutral.100"
            borderRadius="full"
            backgroundColor="white"
            icon={<CloseIcon color="neutral.800" boxSize={3} />}
            _hover={{ backgroundColor: 'neutral.100' }}
            aria-label={`${t('authoring.courses.selectContentMenu.close')}`}
            onClick={() => setNewContentListOpen(false)}
          />
        </ButtonGroup>
      )}

      <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />

        <ModalContent>
          <ModalBody paddingTop={12}>
            <VStack textAlign="center">
              <Image src={removeModalImage} width={120} height="auto" />

              <Text fontSize="20px" fontWeight={600} color="baseBlack">
                {t('authoring.courses.section.removeModal.areYouSure')}
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
            <ButtonGroup width="full" alignItems="center" justifyContent="center">
              <SolidButton onClick={onClose}>{t('global.actions.cancel')}</SolidButton>

              <OutlineButton
                onClick={() => {
                  handleRemoveSection(section.index);
                  onClose();
                }}
              >
                {t('authoring.courses.section.removeModal.remove')}
              </OutlineButton>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
