/* eslint-disable no-param-reassign */

import { Box, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import CommentList from '../../components/lists/CommentList';
import ContentContainer from '../../components/layout/ContentContainer';
import ContentHeader from '../../components/layout/ContentHeader';
import ContentHero from '../../components/media/images/ContentHero';
import ContentRatingInput from '@/client/components/data-display/ratings/ContentRatingInput';
import ContentService from '@/client/services/api/ContentService';
import ContentStats from '../../components/data-display/content/ContentStats';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import HtmlRenderer from '../../components/html/HtmlRenderer';
import ImageModal from '@/client/components/overlay/ImageModal';
import { InView } from 'react-intersection-observer';
import Loading from '@/client/components/media/Loading';
import NotAuthorized from '@/client/components/data-display/content/NotAuthorized';
import PopupSurveyLayout from '@/client/components/overlay/PopupSurvey';
import Recommendations from '@/client/components/layout/Recommendations';
import ViewActions from '@/client/components/overlay/ViewActions';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useCheckContentAuth } from '@/client/services/hooks/content/useCheckContentAuth';
import { useCheckSurveyCompletion } from '@/client/services/hooks/useCheckSurveyCompletion';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { useQuery } from '@tanstack/react-query';
import { useSessionTracking } from '@/client/services/hooks/useSessionTracking';
import { useTranslation } from 'react-i18next';
import { usePartnerStore } from '@/client/services/state/partnerStore';

interface ArticleProps {
  trackId?: string;
  journeyId?: string;
  contentId: string;
  needsContextSelection: boolean;
  sendCompleteContent: Function;
}

export default function Article({
  trackId,
  journeyId,
  contentId,
  needsContextSelection,
  sendCompleteContent,
}: ArticleProps) {
  const { t } = useTranslation();
  const { allowView } = useCheckContentAuth();
  const { partner } = usePartnerStore();
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();
  const [imageModalSource, setImageModalSource] = useState<string>('');
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [allowRecommendations, setAllowRecommendations] = useState<boolean>(false);
  const [renderRecommendations, setRenderRecommendations] = useState<boolean>(false);

  const { sendSessionTime } = useSessionTracking(contentId, 'article');

  const handleImageClick = (e: any) => {
    if (e.target.tagName === 'IMG') {
      setImageModalSource(e.target.src);
      setImageModalOpen(true);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (!trackId && !journeyId) {
      setAllowRecommendations(true);
    }

    if (!needsContextSelection && isAllowed) {
      sendCompleteContent();
      AnalyzeService.postViews(contentId, 'article');
      ContentService.postViews(contentId, 'article');
    }

    return () => {
      sendSessionTime();
    };
  }, [needsContextSelection, isAllowed]);

  const { isLoading, isError, data } = useQuery({
    enabled: !!contentId,
    queryKey: ['article', contentId],
    queryFn: () => ContentService.getArticle(contentId),
    onSuccess: async (responseData) => {
      document.title = responseData.title;

      checkShowPopupSurvey(responseData, true, !!trackId || !!journeyId);

      trackEvent('view', {
        content: responseData._id,
        content_type: 'article',
        title: responseData.title,
      });
    },
  });

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data);

        setIsAllowed(allowed);
      }
    })();
  }, [data]);

  useDocumentTitle(data ? data.title : 'Article');

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title={t('modules.notifications.unableToGetArticle')} />;

  if (isAllowed === false) return <NotAuthorized />;

  return (
    <Box onClick={(e) => handleImageClick(e)}>
      {isAllowed && (
        <>
          {!trackId && !journeyId && (
            <ContentHeader
              contentType="article"
              contentTitle={data.title}
              contentId={contentId}
              withBorder
              allowComments={data.allow_comments}
            />
          )}

          <ContentContainer inJourney={!!journeyId}>
            <ContentStats
              author={data.author}
              duration={data.duration}
              ratingConfiguration={data.rating_configuration}
              averageRating={data.average_rating}
            />

            <Text variant="contentheader">{data.title}</Text>
          </ContentContainer>

          {data.banner_image && <ContentHero imageUrl={data.banner_image} />}

          <ContentContainer inArticle>
            <HtmlRenderer html={data.content} width="700px" />

            {allowRecommendations && (
              <InView
                as="div"
                onChange={(inView) => {
                  if (inView) {
                    setRenderRecommendations(true);
                  }
                }}
              />
            )}

            {renderRecommendations && <Recommendations contentId={contentId} />}

            {data.rating_configuration && data.rating_configuration.allow_rating && (
              <ContentRatingInput
                contentId={data._id}
                contentType="Article"
                ratingConfiguration={data.rating_configuration}
              />
            )}

            {data.allow_comments && !journeyId && !partner._id &&(
              <CommentList contentId={contentId} contentType="article" contentTitle={data.title} />
            )}
          </ContentContainer>

          {!trackId && !journeyId && (
            <ViewActions buttons={['gototop']} bottomOffset={showPopup ? 10 : 0} />
          )}

          {showPopup && (
            <PopupSurveyLayout
              surveyId={data.surveys[0]?.survey_id}
              contentId={contentId}
              contentType="article"
            />
          )}

          <ImageModal
            isOpen={imageModalOpen}
            imageSrc={imageModalSource}
            setIsOpen={() => setImageModalOpen(false)}
          />
        </>
      )}
    </Box>
  );
}

Article.defaultProps = {
  trackId: null,
  journeyId: null,
};
