import { Box, Flex, Grid, GridItem, Link, Text } from '@chakra-ui/react';
import DashboardService, {
  CombinedCourse,
} from '@/client/services/api/DashboardService';

import DashboardCard from '../../data-display/cards/DashboardCard';
import Loading from '@/client/components/media/Loading';
import PlaceholderImage from '@/client/components/media/images/PlaceholderImage';
import { Link as RouterLink } from 'react-router-dom';
import ToggleButton from '@/client/components/buttons/ToggleButton';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ContentList({
  items,
  isChecked,
}: {
  items: CombinedCourse[];
  isChecked: boolean;
}) {
  const { t } = useTranslation();

  if (!items.length)
    return (
      <Box padding={10} textAlign="center" fontSize="sm" color="gray.400">
        <PlaceholderImage name="park" />
        {isChecked ? (
          <Text>{t('dashboard.imported_content.completed_emptyPtOne')}</Text>
        ) : (
          <Text>
            {t('dashboard.imported_content.active_empty')}{' '}
            <Link to="/explore" as={RouterLink}>
              {t('global.vertNavbar.explore')}
            </Link>
            .
          </Text>
        )}
      </Box>
    );

  return (
    <>
      {items.map((item: any) => (
        <GridItem key={item.content_id}>
          <DashboardCard
            id={item.content_id}
            title={item.content_title}
            type={item.type}
            imageUrl={item.imageUrl}
            originRoute="/dashboard"
          />
        </GridItem>
      ))}
    </>
  );
}

export default function DashboardExternalCoursesList({
  userId,
}: {
  userId: string;
}) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);

  const { isLoading, isError, data, error } = useQuery({
    queryKey: ['query-user-external-courses'],
    queryFn: () => DashboardService.getUserExternalCourses(userId),
    onError: (err: any) => console.log('Error', err.response?.data || err),
  });

  if (isLoading) return <Loading />;

  if (isError) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Flex alignItems="center" justifyContent="right">
        <ToggleButton
          leftLabel={t('manage.users.edit_active')}
          rightLabel={t('dashboard.tracks.completed')}
          checked={isChecked}
          setIsChecked={() => setIsChecked(!isChecked)}
        />
      </Flex>

      <Grid gap={4}>
        {isChecked ? (
          <ContentList items={data.completed} isChecked={isChecked} />
        ) : (
          <ContentList items={data.open} isChecked={isChecked} />
        )}
      </Grid>
    </>
  );
}
