import { Box } from '@chakra-ui/react';
import { useTheme } from '@nivo/core';
import { Chip, TableTooltip } from '@nivo/tooltip';

export function TooltipBothAxes(data: { slice: any; axis: string }) {
  const { slice, axis } = data;
  const theme = useTheme();
  const otherAxis = axis === 'x' ? 'y' : 'x';
  const { points } = slice;

  return (
    <TableTooltip
      rows={[
        [
          '',
          <Box>
            <strong style={{ whiteSpace: 'nowrap' }}>{points[0].data.x.toString()}</strong>
          </Box>,
          <Box key="value" style={theme.tooltip.tableCellValue} />,
        ],
        points.map((point: any) => [
          <Chip
            key="chip"
            color={point.serieColor}
            style={{ whiteSpace: 'nowrap', ...theme.tooltip.chip }}
          />,
          <Box style={{ whiteSpace: 'nowrap' }}>{point.serieId}</Box>,
          <Box key="value" style={{ whiteSpace: 'nowrap', ...theme.tooltip.tableCellValue }}>
            {point.data[`${otherAxis}Formatted`]}
          </Box>,
        ]),
      ]}
    />
  );
}
