import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { WarningTriangle } from '@/client/components/icons/ContinuIcons';
import { useTranslation } from 'react-i18next';

interface DeleteGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  handleDelete: () => void;
}

export default function DeleteGroupModal({ isOpen, onClose, handleDelete }: DeleteGroupModalProps) {
  const { t } = useTranslation();

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <WarningTriangle color="brand.warning" marginRight={4} />{' '}
          {t('admin.connect.groups.groupRemoval')}
        </ModalHeader>

        <ModalBody>
          <Text as="p">{t('admin.connect.groups.delete.lineOne')}</Text>

          <br />

          <Text as="p">{t('admin.connect.groups.delete.lineTwo')}</Text>
        </ModalBody>

        <ModalFooter width="full" display="flex" justifyContent="space-between">
          <Button variant="adminCancel" mr={3} onClick={onClose}>
            {t('global.actions.cancel')}
          </Button>

          <Button variant="adminError" onClick={handleDelete}>
            {t('global.actions.delete')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
