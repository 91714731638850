import { colors } from '../colors';
import { hexToRGBA } from '@/client/utils/hexToRGBA';

export const Tag = {
  baseStyle: {
    container: {
      marginRight: 2,
      marginBottom: 2,
    },
  },
  variants: {
    explore: {
      container: {
        backgroundColor: 'blackAlpha.300',
        color: 'white',
      },
    },
    skill: {
      container: {
        backgroundColor: 'blackAlpha.200',
        color: 'blackAlpha.700',
        fontWeight: 'bold',
        paddingX: 2,
      },
    },
    admin: {
      container: {
        backgroundColor: hexToRGBA(colors.brand.primary, 0.1),
        color: '#1A1A1A',
        borderRadius: '2xl',
      },
      label: {
        fontSize: '12px',
      },
    },
    adminSuccess: {
      container: {
        paddingY: 1,
        paddingX: 4,
        backgroundColor: `rgba(98, 157, 60, 0.06)`,
        border: `1px solid rgba(47, 120, 0, 1)`,
      },
    },
    adminWarning: {
      container: {
        paddingY: 1,
        paddingX: 4,
        backgroundColor: `rgba(186, 123, 0, 0.07)`,
        border: `1px solid rgba(186, 123, 0, 1)`,
      },
    },
    adminError: {
      container: {
        paddingY: 1,
        paddingX: 4,
        backgroundColor: `rgba(255, 0, 0, 0.07)`,
        border: `1px solid rgba(153, 0, 0, 1)`,
        productCardPurchased: {
          container: {
            color: hexToRGBA(colors.brand.primary, 1),
            borderWidth: '1px',
            borderRadius: '.25rem',
            borderColor: hexToRGBA(colors.brand.primary, 1),
          },
          label: {
            fontWeight: 'bold',
          },
        },
        productCardUnpurchased: {
          container: {
            color: '#41B305',
            borderWidth: '1px',
            borderRadius: '.25rem',
            borderColor: '#41B305',
          },
          label: {
            fontWeight: 'bold',
          },
        },
      },
    },
    create: {
      container: {
        backgroundColor: 'brand.gold.10',
        padding: 3,
        border: '1px solid',
        borderColor: 'brand.gold.50',
        borderRadius: 'md',
      },
      label: {
        color: 'brand.legibleBlack.100',
        fontWeight: 500,
      },
    },
    landingPage: {
      container: {
        backgroundColor: '#EDEDED',
        padding: 2,
        border: '1px solid #C7C7C7',
        borderColor: 'brand.grey.20',
        borderRadius: 'md',
      },
      label: {
        color: 'brand.legibleBlack.100',
        fontWeight: 500,
      },
    },
  },
};
