import { useEffect, useState } from 'react';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import AssessmentAwaitingGrade from '@/client/components/content/assessments/AssessmentAwaitingGrade';
import AssessmentScore from '@/client/components/content/assessments/AssessmentScore';
import ContentService from '@/client/services/api/ContentService';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import FileUploadAssessment from './FileUploadAssessment';
import Loading from '@/client/components/media/Loading';
import TextAssessment from '@/client/components/content/assessments/textAssessment/TextAssessment';
import VideoAssessment from './VideoAssessment';
import ViewActions from '@/client/components/overlay/ViewActions';
import { useAuthStore } from '@/client/services/state/authStore';
import { useGetAssessment } from '@/client/services/hooks/content/assessments/useGetAssessment';
import { useGetUserAssessment } from '@/client/services/hooks/content/assessments/useGetUserAssessment';
import { useSessionTracking } from '@/client/services/hooks/useSessionTracking';
import { useTextAssessmentStore } from '@/client/services/state/content/textAssessmentStore';

interface AssessmentProps {
  trackId?: string;
  journeyId?: string;
  contentId: string;
  journeyNavigateAction?: () => void;
  needsContextSelection?: boolean;
  contextId?: string;
  sendCompleteContent: Function;
}

export default function Assessment({
  trackId,
  journeyId,
  contentId,
  journeyNavigateAction,
  needsContextSelection,
  contextId,
  sendCompleteContent,
}: AssessmentProps) {
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const [retakingAssessment, setRetakingAssessment] = useState(false);
  const inTrack = !!trackId;
  const inJourney = !!journeyId;
  const { setAssessment, setUserAssessment } = useTextAssessmentStore();

  const { sendSessionTime } = useSessionTracking(contentId, 'assessment');

  const { data: assessment, isLoading, isError } = useGetAssessment(contentId);

  const {
    data: userAssessment,
    isLoading: userAssessmentLoading,
    isError: isUserAssessmentError,
    refetch: refetchUserAssessent,
  } = useGetUserAssessment(contextId);

  useEffect(() => {
    if (assessment && !assessment.video_assessment && !assessment.file_assessment) {
      setAssessment(assessment);
    }

    if (userAssessment) {
      setUserAssessment(userAssessment);
    }
  }, [assessment, userAssessment]);

  useEffect(() => {
    if (!needsContextSelection) {
      AnalyzeService.postViews(contentId, 'assessment');
      ContentService.postViews(contentId, 'assessment');
    }

    return () => {
      sendSessionTime();
    };
  }, [needsContextSelection]);

  const handleCompleteContent = () => {
    if (!needsContextSelection) {
      return sendCompleteContent();
    }

    if (userAssessment) {
      let contexts: [{ key: string; value: string | undefined }] | [] = [];
      if (inJourney) {
        contexts = [{ key: 'journey', value: journeyId }];
      }
      if (inTrack) {
        contexts = [{ key: 'track', value: trackId }];
      }

      return ContentService.completeContent(contentId, 'assessment', contexts, null);
    }
  };

  if (isLoading || userAssessmentLoading) return <Loading />;

  if (isError || isUserAssessmentError) return <ErrorAlert title="Unable to get assessment" />;

  if (!assessment || !userAssessment) return <ErrorAlert title="Unable to get assessment" />;

  // TODO differentiate between userAssessments from different possible contexts
  if (userAssessment.awaiting_manual_grade) return <AssessmentAwaitingGrade />;

  if (userAssessment.passed) {
    return (
      <AssessmentScore
        assessment={assessment}
        userAssessment={userAssessment}
        setRetakingAssessment={setRetakingAssessment}
        hasPassed
        user={user}
        inTrack={inTrack}
        inJourney={inJourney}
        backToJourney={journeyNavigateAction}
      />
    );
  }

  if (!retakingAssessment) {
    if (
      (!userAssessment.awaiting_manual_grade &&
        userAssessment.scores &&
        userAssessment.scores.length > 0 &&
        !userAssessment.passed &&
        !userAssessment.in_progress_responses) ||
      userAssessment.scores.length - 1 === assessment.number_of_retakes
    )
      return (
        <AssessmentScore
          assessment={assessment}
          userAssessment={userAssessment}
          hasPassed={false}
          user={user}
          setRetakingAssessment={setRetakingAssessment}
          inTrack={inTrack}
        />
      );
  }

  return (
    <>
      {assessment.video_assessment && (
        <VideoAssessment
          assessment={assessment}
          userAssessment={userAssessment}
          setRetakingAssessment={setRetakingAssessment}
          refetch={refetchUserAssessent}
          handleCompleteContent={handleCompleteContent}
        />
      )}

      {assessment.file_assessment && (
        <FileUploadAssessment
          assessment={assessment}
          userAssessment={userAssessment}
          setRetakingAssessment={setRetakingAssessment}
          handleCompleteContent={handleCompleteContent}
        />
      )}

      {!assessment.video_assessment && !assessment.file_assessment && (
        <TextAssessment
          refetchUserAssessment={refetchUserAssessent}
          setRetakingAssessment={setRetakingAssessment}
          inTrack={inTrack}
          inJourney={inJourney}
          handleCompleteContent={handleCompleteContent}
        />
      )}

      {!inTrack && !inJourney && <ViewActions bottomOffset={50} buttons={['gototop']} />}
    </>
  );
}

Assessment.defaultProps = {
  trackId: null,
  journeyId: null,
  journeyNavigateAction: null,
  needsContextSelection: false,
  contextId: null,
};
