import { AuthoringEmbedLink, AuthoringFile } from '@/client/components/icons/ContinuIcons';
import { Button, Icon } from '@chakra-ui/react';

import CreateRadioGroup from '@/client/components/admin/create/shared/input/CreateRadioGroup';
import FileDropzone from './FileDropzone';
import FileEmbedForm from './FileEmbedForm';
import type { FileFormData } from '@/client/routes/admin/create/content/EditFile';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// TODO: Refactor this with VideoUpload as reusable component?

export default function FileUpload() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<FileFormData>();
  const { uploadStatus } = useCreateStore();

  const fileUploadType = watch('fileUploadType');
  const fileDetails = watch('fileDetails');
  const link = watch('link');

  const renderReplaceButton = !!id && !fileDetails && link;
  const renderRadioGroup =
    fileUploadType === 'upload' ? uploadStatus !== 'loading' && (!fileDetails || !link) : !link;

  return (
    <>
      {renderReplaceButton && (
        <Button
          variant="createGoldOutline"
          size="sm"
          onClick={() => {
            setValue('link', '');
            setValue('fileUploadType', 'upload');
          }}
        >
          {t('authoring.file.replaceFile')}
        </Button>
      )}

      {!renderReplaceButton && (
        <>
          {renderRadioGroup && (
            <CreateRadioGroup
              name="fileUploadType"
              defaultValue={fileUploadType}
              label=""
              options={[
                {
                  value: 'upload',
                  label: 'Upload',
                  icon: <Icon boxSize={12} as={AuthoringFile} />,
                },
                {
                  value: 'embed',
                  label: 'URL Link',
                  icon: <Icon boxSize={12} as={AuthoringEmbedLink} />,
                },
              ]}
            />
          )}

          {fileUploadType === 'upload' && <FileDropzone />}

          {fileUploadType === 'embed' && <FileEmbedForm />}
        </>
      )}
    </>
  );
}
