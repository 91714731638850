import type { Content, Product } from '@/client/services/api/graphql/gql/graphql';

import AuthoringImageActionsButtonGroup from '@/client/components/admin/create/content/buttons/AuthoringImageActionsButtonGroup';
import AuthoringImageDropzone from '@/client/components/admin/create/content/images/AuthoringImageDropzone';
import AuthoringImageEditor from '@/client/components/admin/create/content/images/AuthoringImageEditor';
import ContentHero from '@/client/components/media/images/ContentHero';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ScormBannerPreview from '../forms/scorm/ScormBannerPreview';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

interface AuthoringBannerUploadProps {
  heroHeight: number;
  contentType: 'article' | 'video' | 'file' | 'scorm' | 'product';
}

export default function AuthoringBannerUpload({
  heroHeight,
  contentType,
}: AuthoringBannerUploadProps) {
  const { tabIndex } = useCreateStore();
  const { setValue, watch } = useFormContext<Content & Product>();
  const [editBanner, setEditBanner] = useState<string>('');

  const bannerImage =
    contentType === 'product' ? watch('images', [''])[0] : watch('bannerImage', '');

  // ! if we end up with more than 4 tabs we'll need to store length in createStore
  const renderBannerImage = bannerImage && bannerImage !== '';

  return (
    <>
      {renderBannerImage &&
        (contentType === 'scorm' ? (
          <ScormBannerPreview
            imageUrl={bannerImage! || `/public/images/cardPlaceholder.png`}
            height={`${heroHeight}px`}
            imageEditActions={
              bannerImage && tabIndex !== 3 ? (
                <AuthoringImageActionsButtonGroup
                  onRemove={() => setValue('bannerImage', '')}
                  onEdit={() => setEditBanner(bannerImage)}
                />
              ) : (
                <></>
              )
            }
          />
        ) : (
          <ContentHero
            imageUrl={bannerImage! || `/public/images/cardPlaceholder.png`}
            height={`${heroHeight}px`}
            imageEditActions={
              bannerImage && tabIndex !== 3 ? (
                <AuthoringImageActionsButtonGroup
                  onRemove={() =>
                    contentType === 'product' ? setValue('images', []) : setValue('bannerImage', '')
                  }
                  onEdit={() => setEditBanner(bannerImage)}
                />
              ) : (
                <></>
              )
            }
          />
        ))}

      {!renderBannerImage && (
        <DndProvider backend={HTML5Backend}>
          <AuthoringImageDropzone type="banner" height={heroHeight} setImage={setEditBanner} />
        </DndProvider>
      )}

      <AuthoringImageEditor
        image={editBanner}
        type="banner"
        setImage={setEditBanner}
        // TODO: Fix this circular type dependency
        // @ts-ignore
        setFormValue={(cloudfrontLink: string) =>
          contentType === 'product'
            ? setValue('images', [cloudfrontLink])
            : setValue('bannerImage', cloudfrontLink)
        }
      />
    </>
  );
}
