import AdminUsersService from '@/client/services/api/admin/users/AdminUsersService';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export const useReinviteUsers = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();

  const reinviteUsersMutation = useMutation({
    mutationFn: (rowSelection: { [key: string]: boolean }) =>
      AdminUsersService.reinviteUsers(Object.keys(rowSelection)),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('inviteManager.reminder_sent_success'),
      });
    },
    onError: (err) => {
      console.error('Error reinviting users: ', err);

      setToast({
        show: true,
        status: 'error',
        title: t('inviteManager.error_resending_invite'),
      });
    },
  });

  return {
    reinviteUsersMutation,
  };
};
