/* eslint-disable react/jsx-props-no-spreading */

import { Flex, IconButton, Input, useToken } from '@chakra-ui/react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import PartnerPermissionModal from '../overlay/PartnerPermissionModal';
import type { PartnerPermissions } from '@/client/types/Partner';
import { Plus } from '@/client/components/icons/ContinuIcons';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useAdminStore } from '@/client/services/state/admin/adminStore';
import { useConfigStore } from '@/client/services/state/configStore';
import { useConnect } from '@/client/services/hooks/admin/connect/useConnect';
import { useConnectStore } from '@/client/services/state/admin/connectStore';
import { useSkillService } from '@/client/services/hooks/admin/connect/useSkillService';
import { useTranslation } from 'react-i18next';

interface CategoryInputProps {
  level: number;
  parentId: string | null | undefined;
}

interface FormInputs {
  name: string;
  partnerPermissions?: PartnerPermissions;
}

export default function ConnectInput({ level, parentId }: CategoryInputProps) {
  const { t } = useTranslation();
  const [brandButton] = useToken('colors', ['brand.button']);
  const { config } = useConfigStore();
  const { setPartnerPermissionModal } = useAdminStore();
  const { columnCopy, type } = useConnectStore();
  const { addItem } = useConnect();
  const { addSkill } = useSkillService();

  const methods = useForm<FormInputs>({
    defaultValues: {
      name: '',
    },
    mode: 'onChange',
  });

  const onModalSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { name, partnerPermissions } = data;
    await addItem.mutateAsync({
      name,
      level,
      parentId,
      itemType: type,
      partnerPermissions,
    });

    methods.reset();
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { name } = data;

    if (type === 'category' && config.features.enable_partners) {
      setPartnerPermissionModal({
        isOpen: true,
        isControlled: true,
        selectedItem: {
          name,
          level,
          parentId,
          itemType: type,
        },
        value: 'tenant',
        handleSubmit: methods.handleSubmit(onModalSubmit),
      });
      return;
    }

    if (type === 'skill') {
      await addSkill.mutateAsync(name);
    } else {
      await addItem.mutateAsync({
        name,
        level,
        parentId,
        itemType: type,
      });
    }

    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Flex paddingY={4} alignItems="center">
          <Input
            placeholder={`${t('global.actions.add')} ${columnCopy.singular}`}
            backgroundColor="brand.mist"
            borderRadius="md"
            variant="unstyled"
            size="md"
            marginRight={6}
            paddingLeft={4}
            paddingY={2}
            fontSize="14px"
            {...methods.register('name', { required: true })}
          />

          <IconButton
            isDisabled={!methods.formState.isValid}
            aria-label={`Add ${columnCopy.singular}`}
            borderRadius="full"
            size="xs"
            backgroundColor={brandButton}
            icon={<Plus />}
            _hover={{ backgroundColor: hexToRGBA(brandButton, 0.8) }}
            type="submit"
          />
        </Flex>

        {!!config.features.enable_partners && <PartnerPermissionModal />}
      </form>
    </FormProvider>
  );
}
