import { Box, HStack, Text, useToken } from '@chakra-ui/react';
import { Star, StarFilled, StarHalf } from '@/client/components/icons/ContinuIcons';

import { calculateStars } from '../../../utils/calculateStars';
import { RatingConfiguration } from '@/client/types/content/RatingConfiguration';

export default function ContentRatingGroup({
  averageRating,
  ratingConfiguration,
}: {
  averageRating: number;
  ratingConfiguration: RatingConfiguration;
}) {
  const stars = calculateStars(averageRating);
  const [yellow, gray] = useToken('colors', ['yellow.400', 'gray.400']);

  const calculateRating = (r: number) => Math.round((r / 20 + Number.EPSILON) * 100) / 100;
  if (ratingConfiguration.show_users_rating && stars) {
    return (
      <HStack spacing={2}>
        {stars.map((star, index) => {
          if (star.filled && !star.half) {
            return (
              <Box key={index}>
                <StarFilled color={yellow} boxSize={3} />
              </Box>
            );
          }
          if (star.filled && star.half) {
            return (
              <Box key={index} overflow="hidden">
                <StarHalf color={yellow} boxSize={3} />
              </Box>
            );
          }
          return (
            <Box key={index}>
              <Star color={gray} boxSize={3} />
            </Box>
          );
        })}

        <Text fontSize="sm">{calculateRating(averageRating)}</Text>
      </HStack>
    );
  }
  return null;
}
