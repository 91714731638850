import { graphql } from '@/client/services/api/graphql/gql/gql';
// IMPORTANT: Build gql after ANY changes to queries (client OR api)

export const getImportedContentType: any = graphql(`
  query GetImportedContentType($id: String!, $type: String!, $userid: String!) {
    content(id: $id, type: $type, userid: $userid) {
      currentVersion {
        ... on ImportedContentExtras {
          vendor
        }
      }
    }
  }
`);

export const getcontentForEditor: any = graphql(`
  query GetContentForEditor($id: String!, $type: String!, $userid: String!) {
    content(id: $id, type: $type, userid: $userid) {
      id
      company
      creator
      currentVersion {
        ... on ScormExtras {
          title
          author
          duration
          description
          sslaConfig {
            openScoInNewWindow
            scoWindowWidth
            scoWindowHeight
            scoWindowTryFullscreen
          }
          properties {
            name
            value
          }
        }
        ... on VideoExtras {
          title
          author
          duration
          link
          description
          useGenerativeAi
          textGenerationStatus
          transcript
          textGenerationJob
          source
          transcoding
          vttFiles {
            kind
            url
            locale
          }
        }
        ... on FileExtras {
          title
          author
          duration
          link
          description
          downloadable
          useGenerativeAi
          textGenerationStatus
          transcript
          textGenerationJob
          draft
          embed
          sourceV2
        }
        ... on ArticleExtras {
          title
          author
          description
          html
          duration
        }
        ... on TrackExtras {
          draft
          title
          resource
          description
          author
          sequential
          lockScorm
          learnItems
          instructors {
            id
            name
            email
            image
            bio
            notes
            reusable
            socials {
              linkedin
              twitter
              facebook
              slack
              github
              instagram
              youtube
              dribbble
            }
          }
          sections {
            id
            name
            description
            position
            courses {
              id
              title
              type
              position
            }
          }
        }
        ... on ImportedContentExtras {
          title
          description
          duration
          instructors {
            id
            name
            email
            image
            bio
            notes
            reusable
            socials {
              linkedin
              twitter
              facebook
              slack
              github
              instagram
              youtube
              dribbble
            }
          }
          vendor
          vendorLink
          vendorFullLink
          vendorContentId
          published
          learnItems
          lilStructure {
            duration
            difficulty
            type
            title
            children {
              duration
              difficulty
              type
              title
              children {
                duration
                difficulty
                type
                title
              }
            }
          }
        }
      }
      contentType
      private
      image
      bannerImage
      coverJustification
      featuredJustification
      exploreHide
      approvalRequired
      tags
      totalLikes
      averageRating
      ratingConfiguration {
        allowRating
        allowFeedback
        allowEdits
        notifyFeedbackOnly
        showUsersRating
        totalValue
        messaging {
          individuals {
            id
            name
            linkedDepartments {
              id
              name
            }
            linkedLocations {
              id
              name
            }
            linkedTeams {
              id
              name
            }
            linkedOrgLevel {
              id
              name
            }
            linkedGrade {
              id
              name
            }
            groups {
              id
              name
            }
          }
          slackChannels {
            id
            name
          }
        }
      }
      allowComments
      surveys {
        id
        title
      }
      archived
      privacyCollaborators {
        id
        name
        linkedDepartments {
          id
          name
        }
        linkedLocations {
          id
          name
        }
        linkedTeams {
          id
          name
        }
        linkedOrgLevel {
          id
          name
        }
        linkedGrade {
          id
          name
        }
        groups {
          id
          name
        }
      }
      privacyLocations {
        id
        name
        parent {
          id
          name
          parent {
            id
            name
          }
        }
      }
      privacyDepartments {
        id
        name
        parent {
          id
          name
          parent {
            id
            name
          }
        }
      }
      privacyTeams {
        id
        name
        parent {
          id
          name
          parent {
            id
            name
          }
        }
      }
      privacyOrgLevels {
        id
        name
        parent {
          id
          name
          parent {
            id
            name
          }
        }
      }
      privacyGrades {
        id
        name
        parent {
          id
          name
          parent {
            id
            name
          }
        }
      }
      privacyGroups {
        id
        name
      }
      linkedCategory {
        id
        name
        parent {
          id
          name
          parent {
            id
            name
          }
        }
      }
      partnerPermissions
      certificate
      certificateData {
        name
        value
      }
    }
  }
`);
