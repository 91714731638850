import {
  CLIENT_CREATION_KEY_API,
  registerClientCreation,
} from './clientRegister';
import axios, { AxiosInstance } from 'axios';

export let learnV4ApiClient: AxiosInstance;

const create = (baseURL: string) => {
  learnV4ApiClient = axios.create({
    baseURL: baseURL + '/learn/v4/',
  });

  learnV4ApiClient.interceptors.request.use(
    async (config) => {
      const value = localStorage.getItem('authStore');

      let keys;

      if (value) {
        keys = JSON.parse(value);

        config.headers = {
          authorization: `AuthToken token=${keys.state.authConfig.authtoken}`,
        };
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
};

registerClientCreation(CLIENT_CREATION_KEY_API, create);
