import { Journey, UserJourney } from '@/client/types/content/Journey';

import AssignmentsService from '../AssignmentsService';
import { SearchAssignment } from '@/client/types/Assignment';
import { learnApiClient } from '../clients/learnApiClient';

const getJourney = async (
  journeyId: string | undefined,
  userId: string,
  userJourneyId: string,
): Promise<{ journey: Journey; userJourney: UserJourney; userAssignments: SearchAssignment[] }> => {
  const journey = await learnApiClient.get(`journeys/${journeyId}`);
  const userJourney = await learnApiClient.get(`user-journeys/${userJourneyId}`);
  const userAssignments = await AssignmentsService.search(userId, journeyId);

  return {
    journey: journey.data,
    userJourney: userJourney.data,
    userAssignments,
  };
};

const JourneyService = {
  getJourney,
};

export default JourneyService;
