import {
  ButtonGroup,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
// eslint-disable-next-line import/extensions
import discardImage from '@/client/assets/images/discard.svg';
import { useTranslation } from 'react-i18next';

// TODO: Consolidate all of these similar content creation modals into a single component

interface DiscardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDiscard: () => void;
}

export default function DiscardModal({ isOpen, onClose, onDiscard }: DiscardModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />

      <ModalContent>
        <ModalBody paddingTop={12}>
          <VStack textAlign="center">
            <Image src={discardImage} width={120} height="auto" />

            <Text fontSize="20px" fontWeight={600} color="baseBlack">
              {t('authoring.discardModal.areYouSure')}
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
          <ButtonGroup width="full" alignItems="center" justifyContent="center">
            <OutlineButton onClick={onDiscard}>
              {t('authoring.discardModal.discardProgess')}
            </OutlineButton>

            <SolidButton onClick={onClose}>{t('authoring.keepEditing')}</SolidButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
