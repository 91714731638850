import AdminUsersService from '@/client/services/api/admin/users/AdminUsersService';
import { useMutation } from '@tanstack/react-query';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export const useUnsuspendUsers = () => {
  const { t } = useTranslation();
  const { setToast } = useToastStore();

  const unsuspendMutation = useMutation({
    mutationFn: (rowSelection: { [key: string]: boolean }) =>
      AdminUsersService.unsuspendUsers(Object.keys(rowSelection)),
    onSuccess: () => {
      setToast({
        show: true,
        status: 'success',
        title: t('suspendManager.all_reinstated_success'),
      });
    },
    onError: (error) => {
      console.error('Error reinstating users: ', error);

      setToast({
        show: true,
        status: 'error',
        title: t('suspendManager.all_reinstated_error'),
      });
    },
  });

  return {
    unsuspendMutation,
  };
};
