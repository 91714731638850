import { Grid, GridItem } from '@chakra-ui/react';

import ContentCard from '@/client/components/data-display/cards/ContentCard';
import { ExploreContent } from '@/client/types/ExploreContent';
import { Fragment } from 'react';
import { InfiniteData } from '@tanstack/react-query';

interface ExploreContentGridProps {
  data: InfiniteData<{ items: ExploreContent[]; nextPage: number | undefined }>;
  cardType: 'card' | 'list';
  recordSearch?: (contentSelectedId: string, contentSelectedType: string) => void | undefined;
}

const cardColumns = {
  base: 'repeat(1, 1fr)',
  md: 'repeat(2, 1fr)',
  lg: 'repeat(3, 1fr)',
  xl: 'repeat(4, 1fr)',
  '2xl': 'repeat(4, 1fr)',
  '3xl': 'repeat(5, 1fr)',
  '4xl': 'repeat(6, 1fr)',
  '5xl': 'repeat(7, 1fr)',
};

const listColumns = {
  base: 'repeat(1, 1fr)',
  sm: 'repeat(1, 1fr)',
  md: 'repeat(2, 1fr)',
  lg: 'repeat(3, 1fr)',
  xl: 'repeat(5, 1fr)',
  '2xl': 'repeat(5, 1fr)',
  '3xl': 'repeat(6, 1fr)',
  '4xl': 'repeat(7, 1fr)',
  '5xl': 'repeat(8, 1fr)',
};

export default function ExploreContentGrid({
  data,
  cardType,
  recordSearch,
}: ExploreContentGridProps) {
  const buildKey = (i: number) => i + 1;

  const setContentImageUrl = (content: ExploreContent) => {
    let imageUrl;
    if (
      (content.type === 'video' || content.type === 'track' || content.type === 'file') &&
      content.images
    ) {
      imageUrl = content.images.explore;
    } else {
      imageUrl = content.image;
    }

    return imageUrl;
  };

  // TODO: Pull this out into a util for use in Workshop/Explore cards
  const setContentImageAlignment = (content: ExploreContent, isFirst: boolean) => {
    let topPos = '';
    if (!isFirst && content.cover_justification === 'left') {
      topPos = 'north';
    }
    if (!isFirst && content.cover_justification === 'right') {
      topPos = 'south';
    }
    if (isFirst && content.featured_justification === 'left') {
      topPos = 'north';
    }
    if (isFirst && content.featured_justification === 'right') {
      topPos = 'south';
    }
    return `${topPos}`;
  };

  return (
    <Grid
      templateColumns={cardType === 'card' ? cardColumns : listColumns}
      gap={cardType === 'card' ? 5 : 3}
    >
      {data.pages.map((page, i) => (
        <Fragment key={buildKey(i)}>
          {page.items.map((content: ExploreContent, innerIndex: number) => {
            if (content.type === 'presentation') return;

            return (
              <GridItem
                key={content._id}
                colSpan={{
                  base: 1,
                  md: i === 0 && innerIndex === 0 ? 2 : 1,
                }}
                onClick={recordSearch ? () => recordSearch(content._id, content.type) : undefined}
              >
                <ContentCard
                  title={content.name || content.title}
                  type={content.type}
                  id={content._id}
                  imageUrl={setContentImageUrl(content)}
                  cardType={cardType}
                  isFirst={innerIndex === 0 && i === 0}
                  isResource={content.type === 'track' && content.resource}
                  vendor={content.vendor}
                  imageAlignment={setContentImageAlignment(content, innerIndex === 0 && i === 0)}
                  coverImageJustification={content.cover_justification}
                  productDetails={content.productDetails}
                />
              </GridItem>
            );
          })}
        </Fragment>
      ))}
    </Grid>
  );
}

ExploreContentGrid.defaultProps = {
  recordSearch: undefined,
};
