import { Recording } from '@/client/types/content/Workshop';

export const parseRecordings = (recordings: Recording[]) => {
  console.log('Recordings: ', recordings);

  const parsedRecordings = recordings.map((recording) => {
    recording.recording_type = recording.recording_type
      ? recording.recording_type
          .replace(/_/g, ' ')
          .replace(/shared/g, '')
          .replace(/screen/g, '')
          .replace(/with/g, '')
      : '';

    return recording;
  });

  return parsedRecordings;
};
