import ExplorerService from "../../api/ExplorerService";
import { useAuthStore } from "@/client/services/state/authStore";

export const useCheckContentAuth = () => {
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const token = authConfig.authtoken;

  const privacyValues = [
    "privacy_departments",
    "privacy_locations",
    "privacy_teams",
    "privacy_org_levels",
    "privacy_grades",
    "privacy_groups",
  ];

  const MSWToPrivacy: { [key: string]: string } = {
    department: "privacy_departments",
    location: "privacy_locations",
    team: "privacy_teams",
    org_level: "privacy_org_levels",
    grade: "privacy_grades",
    group: "privacy_groups",
  };

  const allToPrivacy: { [key: string]: string } = {
    all_departments: "privacy_departments",
    all_locations: "privacy_locations",
    all_teams: "privacy_teams",
    all_org_levels: "privacy_org_levels",
    all_grades: "privacy_grades",
    all_groups: "privacy_groups",
  };

  const allowView = async (content: any, asSegmentation?: boolean) => {
    if (asSegmentation) {
      if (!content || !content.segmentation) {
        return false;
      }

      const translatedContent: any = {
        private: !!content.private || !!content.isPrivate,
      };

      privacyValues.forEach((item) => {
        translatedContent[item] = [];
      });

      content.segmentation.forEach((item: any) => {
        if (
          item.type &&
          item.id &&
          MSWToPrivacy[item.type] &&
          translatedContent[MSWToPrivacy[item.type]]
        ) {
          translatedContent[MSWToPrivacy[item.type]].push(item.id);
        }
      });

      content = translatedContent;
    }

    const privacyBlock: any = {};

    privacyValues.forEach((value) => {
      privacyBlock[value] = { user_values: [], passed: false };
    });

    if (!content) {
      return true;
    }

    if (user.role === "external") {
      // Deny for external users
      const externalDepartment =
        token && authConfig.company.external_department;

      if (
        !externalDepartment ||
        !content.privacy_departments ||
        !content.privacy_departments.length
      ) {
        return false;
      }
      if (content.privacy_departments.indexOf(externalDepartment) === -1) {
        return false;
      }
    }

    if (!content.private) {
      return true;
    }

    const check = (contentToCheck: any) => {
      Object.keys(privacyBlock).forEach((key) => {
        const contentSegmentation = contentToCheck[key];
        const userValues = privacyBlock[key].user_values;

        if (key === "privacy_departments" && user.role === "external") {
          privacyBlock[key].passed = true;
        }

        if (contentSegmentation && contentSegmentation.length) {
          for (let i = 0; i < contentSegmentation.length; i += 1) {
            if (userValues.indexOf(contentSegmentation[i]) !== -1) {
              privacyBlock[key].passed = true;
              break;
            }
          }
        } else {
          privacyBlock[key].passed = true;
        }
      });

      let passed = true;

      Object.keys(privacyBlock).forEach((key) => {
        if (!privacyBlock[key].passed) {
          passed = false;
        }
      });

      if (passed) {
        return true;
      }

      return false;
    };

    try {
      const results = await ExplorerService.getUserAccess();
      Object.keys(allToPrivacy).forEach((key) => {
        const info = privacyBlock[allToPrivacy[key]];

        info.user_values = results[key] || [];
      });

      return check(content);
    } catch (error) {
      console.log("getUserAccess error: ", error);
      return false;
    }

  };

  return { allowView };
};
