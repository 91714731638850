import { Comment } from '@/client/types/Comment';
import { learnApiClient } from './clients/learnApiClient';
import { analyzeApiClient } from './clients/analyzeApiClient';


const getComments = async (
  contentType: string,
  contentId: string | undefined,
  pageParam: number,
  perPage: number
): Promise<Comment[]> => {
  let query;

  if (contentType === 'track') {
    query = `maincomments?${contentType}=${contentId}&fields=comment,media,track,article,evnt,workshop,imported_content,quiz,assessment,scorm,user,edited,createdAt,mentions,likes,removed,is_reply,children&is_reply=$ne,true&page=${pageParam}&per_page=${perPage}&sort=createdAt,-1$exists,false&user-populate=first_name,last_name,email,image,job_title`;
  } else {
    query = `maincomments?${contentType}=${contentId}&fields=comment,media,track,article,evnt,workshop,imported_content,quiz,assessment,scorm,user,edited,createdAt,mentions,likes,removed,is_reply,children&is_reply=$ne,true&page=${pageParam}&per_page=${perPage}&sort=createdAt,-1&track=$exists,false&user-populate=first_name,last_name,email,image,job_title`;
  }

  const response = await learnApiClient.get<Comment[]>(query);

  if (response.data.length === 0) {
    return [];
  }

  const sortedComments = response.data.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));

  let childCommentIds: string[] = [];

  sortedComments.forEach((comment) => {
    if (comment.children?.length) {
      childCommentIds = childCommentIds.concat(comment.children);
    }
  });

  let childComments: Comment[] = [];

  childCommentIds.length ? (childComments = await getChildComments(childCommentIds.join(','))) : null;

  sortedComments.forEach((comment) => {
    comment.replies = [];

    if (comment.children) {
      comment.replies = comment.children.map((childId) => {
        return childComments.find((child) => child._id === childId);
      });
    }
  });

  return sortedComments;
};

const getChildComments = async (commentIds: string): Promise<Comment[]> => {
  const response = await learnApiClient.get<Comment[]>(
    `maincomments?_id=$in,1,${commentIds}&fields=comment,media,track,article,evnt,quiz,workshop,user,edited,createdAt,mentions,likes,removed,is_reply&user-populate=first_name,last_name,email,image,job_title`
  );

  return response.data;
};

const deleteComment = async (commentId: string): Promise<void> => {
  await learnApiClient.delete(`maincomments/${commentId}`);
};

const addComment = async (
  comment: string,
  contentType: string,
  contentId: string | undefined,
  userId: string,
  mentions: string[],
  inTrack?: boolean,
  trackId?: string,
  trackContentType?: string,
  trackContentId?: string
): Promise<Comment> => {
  let payload;

  if (inTrack) {
    payload = {
      children: [],
      comment: comment,
      is_reply: false,
      likes: [],
      mentions: mentions,
      removed: false,
      user: userId,
      track: trackId,
    };

    // @ts-ignore
    payload[trackContentType] = trackContentId;
  } else {
    payload = {
      children: [],
      comment: comment,
      is_reply: false,
      likes: [],
      mentions: mentions,
      removed: false,
      user: userId,
    };
  }

  const response = await learnApiClient.post<Comment>(
    `maincomments?contentId=${contentId}&fields=comment,media,track,article,evnt,workshop,imported_content,scorm,user,quiz,assessment,edited,createdAt&type=${contentType}`,
    payload
  );

  await recordComment(contentId, contentType);

  return response.data;
};

const editComment = async (
  commentId: string,
  editedValue: string,
  userId: string,
  mentions: string[]
): Promise<Comment> => {
  const response = await learnApiClient.post<Comment>(
    `maincomments/${commentId}?fields=comment,media,track,article,quiz,evnt,workshop,user,edited,createdAt`,
    {
      comment: editedValue,
      edited: true,
      hasParent: null,
      mentions: mentions,
      user: userId,
    }
  );

  return response.data;
};

const replyToComment = async (
  contentId: string | undefined,
  comment: string,
  contentType: string,
  parentCommentId: string,
  userId: string,
  mentions: string[]
): Promise<Comment> => {
  const response = await learnApiClient.post(
    `maincomments?contentId=${contentId}&fields=comment,media,track,article,evnt,user,workshop,quiz,createdAt,is_reply&parent=${parentCommentId}&type=${contentType} `,
    {
      children: [],
      comment: comment,
      is_reply: true,
      liked: [],
      mentions: mentions,
      user: userId,
    }
  );

  return response.data;
};

const likeComment = async (commentId: string, userId: string): Promise<Comment> =>
  learnApiClient.post(`maincomments/${commentId}/like/${userId}`);

const unLikeComment = async (commentId: string, userId: string): Promise<Comment> =>
  learnApiClient.post(`maincomments/${commentId}/unlike/${userId}`);


const recordComment = async (contentId: string | undefined, contentType: string) => {
  await analyzeApiClient.post('comments', {
    content: contentId,
    content_type: contentType
  })
}

export const CommentService = {
  getComments,
  deleteComment,
  addComment,
  editComment,
  replyToComment,
  likeComment,
  unLikeComment,
};
