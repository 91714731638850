import {
  AbsoluteCenter,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

interface ImageModalProps {
  isOpen: boolean;
  imageSrc: string;
  setIsOpen: () => void;
}

export default function ImageModal({
  isOpen,
  imageSrc,
  setIsOpen,
}: ImageModalProps) {
  return (
    <Modal size="full" isOpen={isOpen} onClose={setIsOpen}>
      <ModalOverlay />

      <ModalContent backgroundColor="rgba(255,255,255,.92)">
        <ModalCloseButton />

        <ModalBody>
          <AbsoluteCenter>
            <Image src={imageSrc} />
          </AbsoluteCenter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
