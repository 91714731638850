import { Box, Button, Flex, useToken } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import AdminSecondaryHeader from '@/client/components/admin/layout/AdminSecondaryHeader';
import ApiIntegrationsList from './ApiIntegrationsList';
import { PlusCircle } from '@/client/components/icons/ContinuIcons';
import WebhooksManager from './WebhooksManager';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useAuthStore } from '@/client/services/state/authStore';
import { useIntegrationService } from '@/client/services/hooks/admin/connect/useIntegrationService';
import { useTranslation } from 'react-i18next';

export default function DeveloperTools() {
  const { tab } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<'api' | 'webhooks' | string>(tab || 'api');
  const [editing, setEditing] = useState<string>('');
  const [viewId, setViewId] = useState<string>('');
  const [brandButton] = useToken('colors', ['brand.button']);
  const { authConfig } = useAuthStore();
  const { company } = authConfig;
  const [editingId, setEditingId] = useState<string>('');
  const { t } = useTranslation();
  const { isAllowed } = useAccessHelper();

  const {
    isSearching,
    setSearchTerm,
    getApiIntegrations,
    removeApiIntegration,
    createApiIntegration,
    getWebhooks,
    removeWebhook,
    searchTerm,
    removeMutation,
  } = useIntegrationService();

  const allowances = {
    settings: isAllowed(['admin', 'settings'], null, ['admin']),
    api:
      (company.linkedin_learning || company.feature_flags.enable_masterclass || company.open_api) &&
      isAllowed(['admin', 'settings'], null, ['admin']),
    webhooks:
      company.feature_flags.enable_webhooks && isAllowed(['admin', 'settings'], null, ['admin']),
  };

  const nav = [
    {
      key: 'api',
      text: t('overview.settings.api'),
      allowance: allowances.api,
    },
    {
      key: 'webhooks',
      text: t('overview.settings.webhooks'),
      allowance: allowances.webhooks,
    },
  ];

  useEffect(() => {
    if (!allowances.settings || (!allowances.api && !allowances.webhooks)) {
      navigate('/explore');
    }

    if (allowances.api && tab === 'api') {
      setActiveTab('api');
    } else if (allowances.webhooks && tab === 'webhooks') {
      setActiveTab('webhooks');
    }
  }, [allowances, tab, nav, navigate]);

  return (
    <>
      <AdminPrimaryHeader
        isSearching={isSearching}
        tooltipText={`${t('overview.settings.integrations')}`}
        showSortSelector={false}
        title={`${t('global.developerTools')}`}
        setSearchTerm={(e) => {
          setSearchTerm(e as string);
        }}
        sortOrder="asc"
        setSortOrder={() => {}}
        showSearch={activeTab !== 'webhooks'}
      />

      <AdminSecondaryHeader>
        <Flex alignItems="center" width="100%">
          <Box flex="1">
            {nav.map((item) => {
              if (!item.allowance) return;

              return (
                <Button
                  key={item.key}
                  variant="adminTab"
                  size="xs"
                  mr={2}
                  background={activeTab === item.key ? hexToRGBA(brandButton, 0.2) : ''}
                  _hover={{
                    background: hexToRGBA(brandButton, 0.2),
                  }}
                  onClick={() => {
                    setActiveTab(item.key);
                    navigate('/admin/developer-tools/' + item.key);
                    setEditing('');
                    setEditingId('');
                  }}
                >
                  {item.text}
                </Button>
              );
            })}
          </Box>

          <Box>
            {editing === '' && (
              <Button
                variant="adminPrimary"
                size="xs"
                leftIcon={<PlusCircle />}
                onClick={() => {
                  setEditing(activeTab);
                }}
              >
                {activeTab === 'api'
                  ? t('overview.settings.addApiApplication')
                  : t('overview.settings.addWebhook')}
              </Button>
            )}
          </Box>
        </Flex>
      </AdminSecondaryHeader>

      {allowances.api && activeTab === 'api' && (
        <ApiIntegrationsList
          editing={editing}
          setEditing={setEditing}
          getApiIntegrations={getApiIntegrations}
          removeApiIntegration={removeApiIntegration}
          createApiIntegration={createApiIntegration}
          searchTerm={searchTerm}
          removeMutation={removeMutation}
        />
      )}
      {allowances.webhooks && activeTab === 'webhooks' && (
        <WebhooksManager
          editing={editing}
          viewId={viewId}
          setEditing={setEditing}
          setViewId={setViewId}
          getWebhooks={getWebhooks}
          removeWebhook={removeWebhook}
          setEditingId={setEditingId}
          editingId={editingId}
        />
      )}
    </>
  );
}

DeveloperTools.defaultProps = {
  tab: 'api',
};
