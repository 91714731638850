/* eslint react/jsx-props-no-spreading: 0 */

import ProductCard from '@/client/components/data-display/cards/ProductCard';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthStore } from '@/client/services/state/authStore';
import { useQuery, useMutation } from '@tanstack/react-query';
import ProductsService from '@/client/services/api/graphql/ProductsService';
import EcommerceService from '@/client/services/api/graphql/EcommerceService';
import { useToastStore } from '@/client/services/state/toastStore';
import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import UpdatePayment from '../UpdatePayment';

export default function SubscriptionManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { authConfig } = useAuthStore();
  const { id: subscriptionId } = useParams();
  const { user } = authConfig;

  const { data } = useQuery({
    queryKey: ['active-purchase', user._id, subscriptionId],
    queryFn: () =>
      ProductsService.listPurchases({
        user: { in: [user._id] },
        id: { eq: subscriptionId! },
        subscriptionActive: true,
        page: 0,
        pageSize: 10,
        includeTotalCount: true,
      }),
  });

  return (
    <Box marginX={24} marginY={20}>
      <Stack paddingX={20} spacing={8}>
        <Text fontSize="xl" fontWeight="600">
          {t('ecommerce.subscriptionManagement.headline')}
        </Text>
        {!!data?.purchases.length && (
          <>
          <ProductCard
            product={{
              title: data.purchases[0].productDetails.title,
              description: data.purchases[0].productDetails.description,
              price: data.purchases[0].productDetails.price,
              subscription_duration: data.purchases[0].productDetails.subscriptionDuration,
              _id: data.purchases[0].product,
              images: data.purchases[0].productDetails.images,
            }}
            managementButton={
              <SolidButton
                width={200}
                onClick={() => navigate(`/change-subscription/${subscriptionId}`)}
              >
                {t('ecommerce.subscriptionManagement.changeSubscription')}
              </SolidButton>
            }
          />
        <UpdatePayment cardBrand={data.purchases[0].cardDetails?.brand!} lastFour={data.purchases[0].cardDetails?.lastFour!}/>
        </>
        )}
        <Box
          padding={8}
          borderRadius={10}
          border="1px solid"
          backgroundColor="brand.backgroundGrey"
          borderColor="lightgray"
        >
          <Text fontSize="lg" fontWeight="600">
            {t('ecommerce.subscriptionManagement.cancelSubscription')}
          </Text>
          <Flex direction="row" justifyContent="space-between" alignItems="center">
            <Text fontSize="sm">
              {t('ecommerce.subscriptionManagement.cancelSubscriptionDescription')}
            </Text>
            <OutlineButton
              alignSelf="start"
              onClick={() => navigate(`/cancel-subscription/${subscriptionId}`)}
            >
              {t('global.label.cancel')}
            </OutlineButton>
          </Flex>
        </Box>
      </Stack>
    </Box>
  );
}
