import { Bar } from '@nivo/bar';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ChartWrapper } from './ChartWrapper';
import { Range } from 'react-calendar/dist/cjs/shared/types';
import { useToken } from '@chakra-ui/react';
import { StackedBarTotalsLayer } from './StackedBarTotalsLayer';
import { SkipTicksAxis } from '@/client/utils/charts/getAxes';
import { Dispatch, SetStateAction } from 'react';
import { ProductReportDatum } from '@/client/services/api/graphql/gql/graphql';

type StackedBarChartProps = {
  data: { [key: string]:  number }[] | ProductReportDatum[];
  labels: { [key: string]: string };
  colSpan: number;
  rowSpan: number;
  dateRange: string | Date | Range<any>;
  setDateRange: Dispatch<SetStateAction<Range<any>>>;
  title: string;
  tooltip: string;
  leftLegend: string;
  showLegend?: boolean;
};

export function StackedBarChart({
  data,
  labels,
  colSpan,
  rowSpan,
  dateRange,
  setDateRange,
  title,
  tooltip,
  leftLegend,
  showLegend=false
}: Readonly<StackedBarChartProps>) {
  const [brandPrimaryColor, brandSecondaryColor] = useToken('colors', [
    'brand.primary',
    'brand.secondary',
  ]);

  return (
    <ChartWrapper
      showDatePicker
      title={title}
      tooltip={tooltip}
      dateRange={dateRange}
      colSpan={colSpan}
      rowSpan={rowSpan}
      setDateRange={setDateRange}
      dateLocale={{ format: 'en_US' }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <Bar
          // @ts-ignore
            data={data}
            groupMode="stacked"
            width={width}
            height={height}
            layout="vertical"
            layers={[
              'grid',
              'axes',
              'bars',
              'markers',
              'legends',
              'annotations',
              StackedBarTotalsLayer,
            ]}
            enableGridX
            enableGridY={false}
            keys={Object.keys(labels)}
            indexBy="date"
            margin={{ left: 70, bottom: 80, top: 40, right: 70 }}
            padding={0.5}
            innerPadding={1}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={[brandPrimaryColor, brandSecondaryColor]}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickRotation: -70,
              ...SkipTicksAxis(55, width, data, 'date'),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: leftLegend,
              legendPosition: 'middle',
              legendOffset: -60,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="#ffffff"
            legendLabel={(datum) => `${labels[datum.id]}`}
            tooltipLabel={(datum) => `${labels[datum.id]} - ${datum.indexValue}`}
            legends={showLegend ? [
              {
                dataFrom: 'keys',
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: -5,
                translateY: -20,
                itemsSpacing: 2,
                itemWidth: 10,
                itemHeight: 20,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 12,
                toggleSerie: true,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]: undefined}
            theme={{
              axis: {
                legend: {
                  text: { fill: '#999', fontSize: 10, fontWeight: 'bold' },
                },
                ticks: {
                  line: { stroke: '#77777722', strokeWidth: 0.5 },
                  text: { fill: '#999', fontSize: 10 },
                },
              },
              grid: {
                line: {
                  stroke: '#77777722',
                  strokeWidth: 0.5,
                },
              },
              labels: {
                text: { fontSize: 10, fontWeight: 'bold' },
              },
              legends: {
                text: { fontSize: 10, fontWeight: 'bold' },
              },
            }}
          />
        )}
      </AutoSizer>
    </ChartWrapper>
  );
}
