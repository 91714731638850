import { Stack } from '@chakra-ui/react';
import SwitchWithTooltip from '@/client/components/admin/forms/SwitchWithTooltip';
import { useTranslation } from 'react-i18next';

export default function ProfileExploreSettings() {
  const { t } = useTranslation();

  return (
    <Stack spacing={2}>
      <SwitchWithTooltip
        name="permissions.explore.order_content"
        label={t('profiles.edit.orderContent')}
        tooltipText={t('profiles.edit.exploreSettingsHelpText')}
      />

      <SwitchWithTooltip
        name="permissions.explore.update_theme"
        label={t('profiles.edit.theme')}
        tooltipText={t('profiles.edit.themeHelpText')}
      />
    </Stack>
  );
}
