/* eslint react/no-unstable-nested-components: 0 */

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Text,
  useToken,
} from '@chakra-ui/react';
import { Close, EditSimple, PlusCircle } from '@/client/components/icons/ContinuIcons';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AdminPrimaryHeader from '@/client/components/admin/layout/AdminPrimaryHeader';
import AdminSecondaryHeader from '@/client/components/admin/layout/AdminSecondaryHeader';
import AdminElevatedBox from '@/client/components/admin/layout/AdminElevatedBox';
import AssignUsersModal from '@/client/components/admin/overlay/assignUsersModal/AssignUsersModal';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import { Person } from '@/client/types/Person';
import TableActionsMenu from '@/client/components/admin/menus/TableActionsMenu';
import UserCell from '@/client/components/admin/tables/cells/UserCell';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useGroupsService } from '@/client/services/hooks/admin/connect/useGroupsService';
import { useTranslation } from 'react-i18next';
import { useUserSearch } from '@/client/services/hooks/shared/search/useUserSearch';

export default function GroupUsers() {
  const { t } = useTranslation();
  const { groupId } = useParams<{ groupId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const {
    setSearchTerm,
    getGroupNameById,
    deleteGroupUsers,
    addGroupUsers,
    getGroupUsers,
    getGroupManagers,
  } = useGroupsService();
  const navigate = useNavigate();
  const [isUsers, setIsUsers] = useState<boolean>(searchParams.get('type') === 'users');
  const [selectedDeleteUsers, setSelectedDeleteUsers] = useState<string[]>([]);
  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>({});
  const [brandButton] = useToken('colors', ['brand.button']);

  useEffect(() => {
    const selectedItemIds = Object.keys(rowSelection).filter((key) => rowSelection[key]);

    setSelectedDeleteUsers(selectedItemIds);
  }, [rowSelection]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data: groupUsersData, refetch: refetchGroupUsers } = getGroupUsers(
    fetchDataOptions,
    groupId,
    isUsers,
  );

  const { data: groupManagersData, refetch: refetchGroupManagers } = getGroupManagers(
    fetchDataOptions,
    groupId,
    !isUsers,
  );

  const { data: groupInfo } = getGroupNameById(groupId);

  const userSearchHook = useUserSearch('admin', groupId);

  const { setActiveGroupTab, setSelectedItems, setSearchValue, setSearchActive, setQueryString } =
    userSearchHook;

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });

    if (searchParams.get('type') === 'users') {
      setIsUsers(true);
      setActiveGroupTab('users');
    } else {
      setIsUsers(false);
      setActiveGroupTab('managers');
    }
  }, [searchParams]);

  const handleDeleteUsers = () => {
    const mutationPayload = {
      groupId,
      userIds: Object.keys(rowSelection).filter((key) => rowSelection[key]),
      removeManagers: !isUsers,
    };

    deleteGroupUsers.mutateAsync(mutationPayload).then(() => {
      setSelectedDeleteUsers([]);
      setRowSelection({});

      !isUsers ? refetchGroupManagers() : refetchGroupUsers();
    });
  };

  const columnHelper = createColumnHelper<Person>();
  const columns = useMemo<ColumnDefBase<Person, any>[]>(
    () => [
      columnHelper.display({
        id: 'checkbox',
        cell: (info) => (
          <Flex alignItems="center">
            <Checkbox
              marginLeft={9}
              variant="admin"
              onChange={info.row.getToggleSelectedHandler()}
              isChecked={info.row.getIsSelected()}
            />
          </Flex>
        ),
        header: (info) => (
          <Checkbox
            marginLeft={9}
            variant="admin"
            onChange={info.table.getToggleAllRowsSelectedHandler()}
            isChecked={info.table.getIsAllRowsSelected()}
          />
        ),
      }),
      columnHelper.accessor('full_name', {
        cell: (info) => (
          <UserCell
            name={info.getValue()}
            image={info.row.original.image}
            id={info.row.original._id}
          />
        ),
        header: () => <span>{t('global.forms.labels_name')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('global.forms.labels_email')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linked_departments', {
        cell: (info) => <span>{info.getValue()[0] ? info.getValue()[0].name : '-----'}</span>,

        header: () => <span>{t('admin.connect.departments.singular')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linked_locations', {
        cell: (info) => <span>{info.getValue()[0] ? info.getValue()[0].name : '-----'}</span>,
        header: () => <span>{t('admin.connect.locations.singular')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <TableActionsMenu
            onEdit={() => navigate(`/pvt/#/edit/users/${info.row.original._id}`)}
            onDelete={() =>
              deleteGroupUsers
                .mutateAsync({
                  groupId,
                  userIds: [info.row.original._id],
                  removeManagers: !isUsers,
                })
                .then(() => (isUsers ? refetchGroupUsers() : refetchGroupManagers()))
            }
          />
        ),
      }),
    ],
    [isUsers],
  );

  return (
    <>
      <AdminPrimaryHeader
        tooltipText={
          isUsers
            ? t('admin.searchFor', { type: t('global.roles.users') })
            : t('admin.searchFor', { type: t('global.roles.managers') })
        }
        showSearch={false}
        goBackAction={() => navigate('/admin/connect/groups')}
        titleIsElement
        sortOrder="newest"
        setSortOrder={() => null}
        title={
          <HStack>
            <Text fontSize="18px" fontWeight="medium">
              {groupInfo?.name}

              <IconButton
                aria-label={t('admin.connect.groups.edit')}
                variant="ghost"
                icon={<EditSimple color="brand.button" />}
                onClick={() => navigate(`/admin/connect/groups/${groupInfo?._id}/edit`)}
              />
            </Text>
          </HStack>
        }
        setSearchTerm={setSearchTerm}
        isSearching={false}
      />

      <AdminSecondaryHeader>
        <>
          <ButtonGroup>
            <Button
              background={isUsers ? hexToRGBA(brandButton, 0.2) : 'transparent'}
              variant="adminTab"
              size="xs"
              onClick={() => setSearchParams({ type: 'users' })}
            >
              {t('global.roles.users')}
            </Button>

            <Button
              background={!isUsers ? hexToRGBA(brandButton, 0.2) : 'transparent'}
              variant="adminTab"
              size="xs"
              onClick={() => setSearchParams({ type: 'managers' })}
            >
              {t('global.roles.managers')}
            </Button>
          </ButtonGroup>

          {selectedDeleteUsers.length > 1 && (
            <Button
              variant="adminError"
              size="xs"
              leftIcon={<Close />}
              onClick={handleDeleteUsers}
              isLoading={deleteGroupUsers.isLoading}
            >
              {isUsers
                ? t('admin.connect.groups.removeUsers')
                : t('admin.connect.groups.removeManagers')}
            </Button>
          )}

          {selectedDeleteUsers.length <= 1 && (
            <Button
              variant="adminPrimary"
              leftIcon={<PlusCircle />}
              size="xs"
              onClick={() => setModalOpen(true)}
            >
              {isUsers
                ? t('admin.connect.groups.addUsers')
                : t('admin.conneect.groups.addManagers')}
            </Button>
          )}
        </>
      </AdminSecondaryHeader>

      <AdminElevatedBox>
        <ManuallyPaginatedTable
          columns={columns}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          queryData={isUsers ? groupUsersData : groupManagersData}
          pagination={pagination}
          setPagination={setPagination}
          showCheckbox
        />
      </AdminElevatedBox>

      <AssignUsersModal
        groupId={groupId}
        isManagers={!isUsers}
        userSearchHook={userSearchHook}
        isOpen={modalOpen}
        userType={isUsers ? 'users' : 'managers'}
        assignUsers={addGroupUsers}
        onClose={() => {
          isUsers ? refetchGroupUsers() : refetchGroupManagers();
          setQueryString('');
          setSearchTerm('');
          refetchGroupManagers();
          refetchGroupUsers();
          setSelectedItems([]);
          setSearchValue('');
          setSearchActive(false);
          setModalOpen(false);
        }}
      />
    </>
  );
}
