import { froalaStyles } from '@/client/theme/global/froalaStyles';

// ! These styles are for backwards compatability when editing content created in Froala

export const buildFroalaStyles = (theme: any) => ({
  body: {
    'max-width': '700px !important',
    'font-size': '18px',
    'font-family': 'Inter,Helvetica,Arial,sans-serif',
    'line-height': '1.5',
    'padding-top': '1rem',
  },
  'body a': {
    color: `${theme.colors.brand.link} !important`,
    'text-decoration': 'none',
  },
  'body h1, body h2, body h3, body h4': {
    'line-height': '1.2857rem',
    margin: 'calc(1.85715rem) 0 2rem',
  },
  'body h2, body h3, body h4,': {
    'font-weight': '300',
  },
  'body h1': {
    'font-size': '1.57rem',
    'font-weight': '500',
    'margin-bottom': '1.7rem',
  },
  'body h2, body h3': {
    'font-size': '1.714rem',
    'font-weight': '300',
  },
  'body h4': {
    'font-size': '1.286rem',
    'font-weight': '300',
  },
  'body pre': {
    background: '#f7f7f7',
    'font-family': 'monospace !important',
    padding: '2rem',
    'border-radius': '4px',
    border: '1px solid #B2B2B2',
  },
  ...froalaStyles,
});
