import { HStack, Tag, TagCloseButton, TagLeftIcon, Text } from '@chakra-ui/react';

import type { Content } from '@/client/services/api/graphql/gql/graphql';
import type { IconType } from 'react-icons';
import { useFormContext } from 'react-hook-form';

interface AuthoringSegmentationTagListProps {
  formName:
    | 'privacyLocations'
    | 'privacyDepartments'
    | 'privacyTeams'
    | 'privacyOrgLevels'
    | 'privacyGrades'
    | 'privacyGroups';
  label: string;
  icon: IconType;
}

export default function AuthoringSegmentationTagList({
  formName,
  label,
  icon,
}: AuthoringSegmentationTagListProps) {
  const { watch, setValue } = useFormContext<Content>();

  const selectedItems = watch(formName) || [];

  if (selectedItems.length === 0) return null;

  return (
    <>
      <Text variant="createLabel">{label}</Text>

      <HStack flexWrap="wrap">
        {selectedItems.map((item) => (
          <Tag key={item.id} variant="create">
            <TagLeftIcon as={icon} />

            {item.name}

            <TagCloseButton
              onClick={() =>
                setValue(
                  formName,
                  // @ts-ignore
                  selectedItems.filter((i) => i.id !== item.id),
                )
              }
            />
          </Tag>
        ))}
      </HStack>
    </>
  );
}
