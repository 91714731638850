import CreateNavigationBlocker from '../create/content/overlay/CreateNavigationBlocker';
import { Outlet } from 'react-router-dom';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
// import { useEffect } from 'react';
// import { useLocalStorage } from 'usehooks-ts';
// import { useTrackStateStore } from '@/client/services/state/admin/create/trackStateStore';

export default function AdminContentAuthoringLayout() {
  const { canLeave } = useCreateStore();
  // const [angularTrackState] = useLocalStorage('trackState', null);
  // const { setTrackState } = useTrackStateStore();

  // useEffect(() => {
  //   if (angularTrackState) {
  //     setTrackState(angularTrackState);
  //   }
  // }, [angularTrackState]);

  return (
    <>
      <Outlet />

      <CreateNavigationBlocker enabled={!canLeave} />
    </>
  );
}
