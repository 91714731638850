import { Text } from '@chakra-ui/react';
import { useIntegrationService } from '@/client/services/hooks/admin/connect/useIntegrationService';
import { useEffect, useState } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export default function ApiSecret({ visibility, id }: { visibility: boolean; id: string }) {
  const { exposeApiIntegration } = useIntegrationService();
  const [exposedSecret, setExposedSecret] = useState();
  const { data } = exposeApiIntegration({ id });
  const { setToast } = useToastStore();
  const { t } = useTranslation();

  useEffect(() => {
    if (data && data.client_secret) {
      setExposedSecret(data.client_secret);
    }
  }, [id, data]);

  const copyContent = async (toCopy: string) => {
    try {
      await navigator.clipboard.writeText(toCopy);
      setToast({ show: true, status: 'success', title: t('apiIntegrations.copiedSecret') });
    } catch (err) {
      setToast({ show: true, status: 'error', title: t('apiIntegrations.copySecretError') });
    }
  };

  return (
    <Text
      onClick={() => {
        if (exposedSecret) copyContent(exposedSecret);
      }}
    >
      {visibility ? exposedSecret : '**********'}
    </Text>
  );
}
