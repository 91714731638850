import { PointProps } from "@/client/types/Charts";

export function Star ({ size, color, borderWidth, borderColor }: PointProps) {
  const outerSize = size / 2;
  const innerSize = size / 4;
  const svgPoints = [];
  let n = 0;
  const c = { x: 0, y: 0 }; //the center of the svg canvas
  const step = Math.PI / 5; //since the star has 5 points you will need to calculate the position of a point every 36degs i.e Math.PI/5;

  for (let a = -Math.PI / 2; a < (3 * Math.PI) / 2; a += step) {
    //The point will be either on the outer circle (r=outerSize) or on the inner one (r=innerSize)
    const r = n % 2 === 0 ? outerSize : innerSize;
    const x = c.x + r * Math.cos(a);
    const y = c.y + r * Math.sin(a);
    svgPoints.push(`${x},${y}`);
    n += 1;
  }
  return (
    <g>
      <polygon
        points={svgPoints.join(' ')}
        fill={color}
        stroke={borderColor}
        strokeWidth={borderWidth}
        style={{ pointerEvents: 'none' }}
      />
    </g>
  );
};
