import { Box, Link } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

type PurchaseCardProps = {
  linkUrl: string;
  originRoute: string | undefined;
  imageUrl: string;
  children: ReactElement;
};

export default function LinkCardWrapper({
  linkUrl,
  originRoute,
  imageUrl,
  children
}: PurchaseCardProps) {

  return (
    <Link
      as={RouterLink}
      to={linkUrl}
      state={originRoute ? { originRoute } : {}}
      cursor="pointer"
      display="inline-flex"
      width="100%"
      backgroundColor="white"
      color="black"
      borderRadius="4px"
      overflow="hidden"
      wordBreak="break-word"
      height="100%"
      _hover={{ color: 'brand.link' }}
    >
      <Box
        width="100px"
        minW="100px"
        minH="100px"
        overflow="hidden"
        background={imageUrl?.endsWith('undefined') ? 'brand.primary' : 'url(' + imageUrl + ')'}
        backgroundSize="cover"
        backgroundColor="brand.primary"
      />
      {children}
    </Link>
  );
}



