import SwitchList from './SwitchList';
import { useTranslation } from 'react-i18next';

export default function ProfileConnectAccess() {
  const { t } = useTranslation();

  const connectAccessOptions = [
    {
      name: 'permissions.admin.connect.categories',
      label: t('profiles.edit.connectOverview'),
      enabled: true,
    },
    {
      name: 'permissions.admin.connect.locations',
      label: t('profiles.edit.connectLocations'),
      enabled: true,
    },
    {
      name: 'permissions.admin.connect.departments',
      label: t('profiles.edit.connectDepartments'),
      enabled: true,
    },
    {
      name: 'permissions.admin.connect.teams',
      label: t('profiles.edit.connectTeams'),
      enabled: true,
    },
    {
      name: 'permissions.admin.connect.levels',
      label: t('profiles.edit.connectLevels'),
      enabled: true,
    },
    {
      name: 'permissions.admin.connect.grades',
      label: t('profiles.edit.connectGrades'),
      enabled: true,
    },
    {
      name: 'permissions.admin.connect.groups',
      label: t('profiles.edit.connectGroups'),
      enabled: true,
    },
    {
      name: 'permissions.admin.connect.skills',
      label: t('profiles.edit.connectSkills'),
      enabled: true,
    },
  ];

  return <SwitchList enableSelectAll={false} options={connectAccessOptions} />;
}
