/* eslint-disable react/jsx-props-no-spreading */
import { Flex, TabProps, useMultiStyleConfig, useTab, useToken } from '@chakra-ui/react';

import { hexToRGBA } from '@/client/utils/hexToRGBA';

export default function CustomTab(props: TabProps) {
  const [brandButton] = useToken('colors', ['brand.button']);
  const tabProps = useTab(props);
  const isSelected = !!tabProps['aria-selected'];
  const styles = useMultiStyleConfig('Tabs', tabProps);
  const { children } = props;

  return (
    <Flex
      __css={{
        ...styles.tab,
        cursor: 'pointer',
        backgroundColor: isSelected ? hexToRGBA(brandButton, 0.1) : 'transparent',
        borderRadius: 'lg',
        _hover: {
          backgroundColor: hexToRGBA(brandButton, 0.1),
        },
      }}
      {...tabProps}
    >
      {children}
    </Flex>
  );
}
