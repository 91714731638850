/* eslint-disable import/extensions */

import {
  Avatar,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Stack,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Close } from '@/client/components/icons/ContinuIcons';
import ContinuAvatar from '../../assets/continuAvatar.json';
import { Notification } from '../../types/Notification';
import NotificationMessage from '../data-display/notifications/NotificationMessage';
import v3ApiService from '../../services/api/clients/v3ApiClient';

type UserNotificationsDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  notifications: Notification[] | undefined;
};

export default function UserNotificationsDrawer({
  isOpen,
  onClose,
  notifications,
}: UserNotificationsDrawerProps) {
  const queryClient = useQueryClient();

  const archiveNotificationMutation = useMutation({
    mutationFn: (id: string) => v3ApiService.archiveNotification(id),
    onSuccess: () => queryClient.refetchQueries({ queryKey: ['notifications'] }),
  });

  return (
    <Drawer size="lg" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />

      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">
          <Flex alignItems="center" justifyContent="space-between">
            Inbox
            <Close
              boxSize={4}
              onClick={onClose}
              _hover={{ cursor: 'pointer', color: 'brand.primary' }}
            />
          </Flex>
        </DrawerHeader>

        <DrawerBody padding={0}>
          {notifications && notifications.length > 0 && (
            <Stack>
              {notifications.map((notification) => (
                <Flex
                  key={notification._id}
                  alignItems="center"
                  padding={4}
                  borderBottom="1px"
                  borderBottomColor="gray.200"
                  maxWidth="100%"
                  wordBreak="break-word"
                >
                  <Avatar
                    size="sm"
                    name={notification.user_from?.full_name}
                    src={
                      notification.user_from?.full_name === 'Continu Inc.'
                        ? ContinuAvatar.src
                        : notification.user_from?.image
                    }
                  />

                  <NotificationMessage message={notification.message} onClose={onClose} />

                  <Close
                    boxSize={3}
                    onClick={() => archiveNotificationMutation.mutate(notification._id)}
                    _hover={{ cursor: 'pointer', color: 'brand.primary' }}
                  />
                </Flex>
              ))}
            </Stack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
