import AssessmentService from '@/client/services/api/AssessmentService';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useQuery } from '@tanstack/react-query';

export const useGetAssessment = (contentId: string | undefined) =>
  useQuery({
    enabled: !!contentId,
    queryKey: ['assessment', { contentId }],
    queryFn: () => AssessmentService.getAssessment(contentId),
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      document.title = data.title;

      trackEvent('assessment_viewed', { content: data._id, content_type: 'assessment', title: data.title });
    },
  });
