import { Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useListProductsInfinite } from "@/client/services/hooks/content/products/useListProducts";
import ProductCard from "../../data-display/cards/ProductCard";
import SolidButton from "../../shared/buttons/SolidButton";
import { OrderingDirection, Product } from "@/client/services/api/graphql/gql/graphql";
import { PaginationState } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InView } from "react-intersection-observer";
import Loading from "../../media/Loading";
import { ProductListResults } from "@/client/services/api/graphql/gql-utils";
import { useGetBaseProduct } from "@/client/services/hooks/content/products/useGetProduct";

export default function ProductBrowseList() {
  const { t } = useTranslation();
  const navigate = useNavigate();


  const [{ pageIndex, pageSize }] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const {data: baseProduct} = useGetBaseProduct();

  const getAlternativeProducts = () => {
    if (!baseProduct?.allowRelatedProducts) {
      return [];
    }
    if (baseProduct.relatedProducts.length === 0) {
      return null;
    }
    return baseProduct.relatedProducts;
  }

  const { data, isFetchingNextPage, fetchNextPage } = useListProductsInfinite({
    page: pageIndex,
    pageSize,
    includeTotalCount: true,
    archived: {eq: false},
    id: {in: getAlternativeProducts()},
    ordering: [{ field: 'createdAt', direction: OrderingDirection.Desc }],
  });

  return (
    <Stack spacing={4}>
      {data?.pages.map((productList: ProductListResults) => (
        productList.products.map((product: Product) => (
          <ProductCard
            product={{
              title: product.title,
              description: product.description,
              price: product.price,
              subscription_duration: product.subscriptionDuration,
              _id: product.id,
              images: product.images,
            }}
            managementButton={
              <SolidButton
                onClick={() => {
                  navigate(`/product/${product.id}/payment-method`);
                }}
                width={200}
              >
                {t('ecommerce.purchases.select')}
              </SolidButton>
            }
          />
        ))
      ))}
      <>
        <InView
          rootMargin="-30px"
          as="div"
          threshold={0}
          onChange={(inView) => {
            if (inView) {
              fetchNextPage();
            }
          }}
        />
        {isFetchingNextPage && <Loading />}
      </>
      
    </Stack>
  );
}