/* eslint-disable jsx-a11y/anchor-is-valid */

import { Box, Link, Text } from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

export default function SampleLinks({ overrideColor }: { overrideColor: string }) {
  const { t } = useTranslation();

  return (
    <Box width="xs">
      <Text>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
        <Link color={overrideColor || 'brand.primary'}>{t('admin.theme.samples.sampleLink')}</Link>{' '}
        Vivamus tempor bibendum pharetra. Morbi eu aliquam sapien, faucibus vehicula nisi.{' '}
      </Text>
    </Box>
  );
}
