import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { WorkshopInstance } from '@/client/types/content/Workshop';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

interface WorkshopInstanceListProps {
  instances: WorkshopInstance[];
  setSelectedInstance: (instance: WorkshopInstance) => void;
}

export default function WorkshopInstanceList({
  instances,
  setSelectedInstance,
}: WorkshopInstanceListProps) {
  const { t } = useTranslation();

  return (
    <Menu>
      <MenuButton as={Button} variant="menu" rightIcon={<ChevronDownIcon />} fontSize="xs">
        {t('workshopviewer.select_other_date')}
      </MenuButton>

      <MenuList maxHeight="375px" width="full" overflow="auto">
        {instances.map((instance: any) => {
          const date = format(new Date(instance.start_date), 'MMMM do, yyyy ');

          const time = format(new Date(instance.start_date), 'h:mm a');

          const dateTimeString =
            `${date} @ ${time} ` + (instance.online ? '| Online' : `| ${instance.location.venue}`);

          return (
            <MenuItem
              key={instance._id}
              fontSize="xs"
              onClick={() => setSelectedInstance(instance)}
            >
              {dateTimeString}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
