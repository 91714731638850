import type { ContentSubmissionData } from '@/client/types/admin/content-authoring/shared/ContentSubmissionData';
import { learnApiClient } from '@/client/services/api/clients/learnApiClient';

interface ArticleSubmissionData extends ContentSubmissionData {}

const createArticle = async (data: Omit<ArticleSubmissionData, '_id'>, articleId?: string) => {
  let url = '/articles';

  if (articleId) {
    url = `/articles/${articleId}`;
  }

  const response = await learnApiClient.post(url, data);

  return response.data;
};

const AuthoringArticleService = {
  createArticle,
};

export default AuthoringArticleService;
