import { HStack, Stack } from '@chakra-ui/react';

import type { Element } from '@/client/types/admin/content-authoring/tracks/imported-content/LinkedInSearchData';
import ImportedContentSearch from './ImportedContentSearch';
import ImportedContentSearchCard from './ImportedContentSearchCard';
import ImportedContentSearchFilterMenu from './ImportedContentSearchFilterMenu';
import ImportedContentService from '@/client/services/api/ImportedContentService';
import { useAuthorLinkedInLearning } from '@/client/services/hooks/admin/authoring/courses/imported-content/useAuthorLinkedInLearning';
import { useDebounceValue } from 'usehooks-ts';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function LinkedinSearch() {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState<{ name: string; value: string | null } | null>(null);
  const [type, setType] = useState<{ name: string; value: string | null } | null>(null);
  const [difficulty, setDifficulty] = useState<{ name: string; value: string | null } | null>(null);
  const [language, setLanguage] = useState<{ name: string; value: string | null } | null>(null);
  const [searchTerm, setSearchTerm] = useDebounceValue('', 300);
  const { getLinkedinCourseByUrn } = useAuthorLinkedInLearning();

  const pageSize = 20;

  const { data, fetchNextPage, isFetchingNextPage, status } = useInfiniteQuery({
    queryKey: [
      'adminSearchLinkedinContent',
      sortBy?.value,
      type?.value,
      difficulty?.value,
      language?.value,
      pageSize,
      searchTerm,
    ],
    queryFn: ({ pageParam = 0 }) =>
      ImportedContentService.adminSearchLinkedinContent(
        sortBy?.value,
        type?.value,
        difficulty?.value,
        language?.value,
        searchTerm,
        pageSize,
        pageParam,
      ),
    getNextPageParam: (lastPage) => lastPage.paging.start + pageSize,
  });

  const sortOptions = [
    { name: t('edit.importedcontent.relevance'), value: 'RELEVANCE' },
    { name: t('edit.importedcontent.most_recent'), value: 'RECENCY' },
    { name: t('edit.importedcontent.most_popular'), value: 'POPULARITY' },
    { name: t('edit.importedcontent.clear'), value: null },
  ];

  const typeOptions = [
    { name: t('global.contentTypes.video'), value: 'VIDEO' },
    { name: t('edit.importedcontent.course'), value: 'COURSE' },
    { name: t('edit.importedcontent.learning_path'), value: 'LEARNING_PATH' },
    { name: t('edit.importedcontent.clear'), value: null },
  ];

  const difficultyOptions = [
    { name: t('edit.importedcontent.beginner'), value: 'BEGINNER' },
    { name: t('edit.importedcontent.intermediate'), value: 'INTERMEDIATE' },
    { name: t('edit.importedcontent.advanced'), value: 'ADVANCED' },
    { name: t('edit.importedcontent.clear'), value: null },
  ];

  const languageOptions = [
    { name: t('English'), value: 'en' },
    { name: t('German'), value: 'de' },
    { name: t('language.japanese'), value: 'ja' },
    { name: t('language.french'), value: 'fr' },
    { name: t('language.french'), value: 'es' },
    { name: t('edit.importedcontent.clear'), value: null },
  ];

  const getDuration = (item: Element) => {
    if (
      !item ||
      !item.details ||
      !item.details.timeToComplete ||
      !item.details.timeToComplete.duration
    ) {
      return;
    }
    const time = item.details.timeToComplete.duration;

    if (!item.details.timeToComplete.unit || item.details.timeToComplete.unit === 'SECOND') {
      if (time && time < 60) {
        return t('fileviewer.less_than_a_minute');
      }
      let mins = Math.floor(time / 60);

      const hrs = Math.floor(time / 3600);
      mins = Math.floor((time % 3600) / 60);

      let ret = '';

      if (hrs > 0) {
        ret += '' + hrs + (hrs === 1 ? ' hr' : ' hrs') + ' ';
      }

      ret += '' + mins + (mins === 1 ? ' min' : ' mins');

      return ' - ' + ret;
    }

    return '';
  };

  return (
    <ImportedContentSearch
      heading={t('authoring.courses.importedContent.linkedin.importCourse')}
      searchPlaceholder={t('authoring.courses.importedContent.linkedin.searchPlaceholder')}
      setSearchTerm={setSearchTerm}
      filterGroup={
        <HStack marginBottom={12}>
          <ImportedContentSearchFilterMenu
            label={t('authoring.courses.importedContent.searchFilters.sortBy')}
            selected={sortBy}
            setSelected={setSortBy}
            options={sortOptions}
          />

          <ImportedContentSearchFilterMenu
            label={t('authoring.courses.importedContent.searchFilters.type')}
            selected={type}
            setSelected={setType}
            options={typeOptions}
          />

          <ImportedContentSearchFilterMenu
            label={t('authoring.courses.importedContent.searchFilters.difficulty')}
            selected={difficulty}
            setSelected={setDifficulty}
            options={difficultyOptions}
          />

          <ImportedContentSearchFilterMenu
            label={t('authoring.courses.importedContent.searchFilters.language')}
            selected={language}
            setSelected={setLanguage}
            options={languageOptions}
          />
        </HStack>
      }
      status={status}
      isFetchingNextPage={isFetchingNextPage}
      canFetchNextPage={data?.pages[data.pages.length - 1].elements.length === pageSize}
      fetchNextPage={fetchNextPage}
      renderNoResults={data && data.pages[0].elements.length === 0}
      renderResults={data?.pages && data.pages[0].elements.length > 0}
      renderEndOfResults={data?.pages[data.pages.length - 1].elements.length !== pageSize}
      searchResultsElement={
        data?.pages && data.pages[0].elements.length > 0 ? (
          <>
            {data.pages.map((page) => (
              <Stack key={page.elements[0].title.value} spacing={4}>
                {page.elements.map((item) => (
                  <ImportedContentSearchCard
                    key={item.urn}
                    isLoading={getLinkedinCourseByUrn.isLoading}
                    onClick={() => getLinkedinCourseByUrn.mutateAsync(item.urn)}
                    backgroundImage={item.details.images.primary}
                    title={item.title.value}
                    author={
                      (item.details.contributors &&
                        item.details.contributors[0] &&
                        item.details.contributors[0].name &&
                        item.details.contributors[0].name.value) ||
                      ''
                    }
                    duration={getDuration(item)}
                    description={item.details.description.value}
                  />
                ))}
              </Stack>
            ))}
          </>
        ) : undefined
      }
    />
  );
}
