import { HStack, Stack } from '@chakra-ui/react';

import ImportedContentSearch from './ImportedContentSearch';
import ImportedContentSearchCard from './ImportedContentSearchCard';
import ImportedContentSearchFilterMenu from './ImportedContentSearchFilterMenu';
import ImportedContentService from '@/client/services/api/ImportedContentService';
import { useAuthorUdemy } from '@/client/services/hooks/admin/authoring/courses/imported-content/useAuthorUdemy';
import { useDebounceValue } from 'usehooks-ts';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function UdemySearch() {
  const { t } = useTranslation();
  const [order, setOrder] = useState<{ name: string; value: string | null } | null>(null);
  const [rating, setRating] = useState<{ name: string; value: string | null } | null>(null);
  const [duration, setDuration] = useState<{ name: string; value: string | null } | null>(null);
  const [searchTerm, setSearchTerm] = useDebounceValue('', 300);
  const { getUdemyCourseById } = useAuthorUdemy();

  const pageSize = 20;

  const { data, fetchNextPage, isFetchingNextPage, status } = useInfiniteQuery({
    queryKey: [
      'adminSearchUdemyContent',
      order?.value,
      rating?.value,
      duration?.value,
      pageSize,
      searchTerm,
    ],
    queryFn: ({ pageParam = 1 }) =>
      ImportedContentService.adminSearchUdemyContent(
        pageParam,
        pageSize,
        searchTerm,
        order?.value,
        rating?.value,
        duration?.value,
      ),
    getNextPageParam: (lastPage, pages) =>
      lastPage.results.length === pageSize ? pages.length + 1 : undefined,
  });

  const orderOptions = [
    { name: t('edit.importedcontent.relevance'), value: 'relevance' },
    { name: t('edit.importedcontent.most_viewed'), value: 'most-reviewed' },
    { name: t('edit.importedcontent.highest_rated'), value: 'highest-rated' },
    { name: t('edit.importedcontent.newest'), value: 'newest' },
    { name: t('edit.importedcontent.price_low_high'), value: 'price-low-to-high' },
    { name: t('edit.importedcontent.price_high_low'), value: 'price-high-to-low' },
    { name: t('edit.importedcontent.clear'), value: null },
  ];

  const ratingOptions = [
    { name: '1 ' + t('edit.importedcontent.star') + ' +', value: '1' },
    { name: '2 ' + t('edit.importedcontent.star') + ' +', value: '2' },
    { name: '3 ' + t('edit.importedcontent.star') + ' +', value: '3' },
    { name: '4 ' + t('edit.importedcontent.star') + ' +', value: '4' },
    { name: '5 ' + t('edit.importedcontent.star') + ' +', value: '5' },
    { name: t('edit.importedcontent.clear'), value: null },
  ];

  const durationOptions = [
    { name: t('edit.importedcontent.short'), value: 'short' },
    { name: t('edit.importedcontent.medium'), value: 'medium' },
    { name: t('edit.importedcontent.long'), value: 'long' },
    { name: t('edit.importedcontent.extra_long'), value: 'extraLong' },
    { name: t('edit.importedcontent.clear'), value: null },
  ];

  return (
    <ImportedContentSearch
      heading={t('authoring.courses.importedContent.udemy.importCourse')}
      searchPlaceholder={t('authoring.courses.importedContent.udemy.searchPlaceholder')}
      setSearchTerm={setSearchTerm}
      status={status}
      isFetchingNextPage={isFetchingNextPage}
      canFetchNextPage={data?.pages[data.pages.length - 1].results.length === pageSize}
      fetchNextPage={fetchNextPage}
      renderNoResults={data && data.pages[0].results.length === 0}
      renderResults={data?.pages && data.pages[0].results.length > 0}
      renderEndOfResults={data?.pages[data.pages.length - 1].results.length !== pageSize}
      filterGroup={
        <HStack marginBottom={12}>
          <ImportedContentSearchFilterMenu
            label={t('authoring.courses.importedContent.searchFilters.order')}
            selected={order}
            setSelected={setOrder}
            options={orderOptions}
          />

          <ImportedContentSearchFilterMenu
            label={t('authoring.courses.importedContent.searchFilters.starRating')}
            selected={rating}
            setSelected={setRating}
            options={ratingOptions}
          />

          <ImportedContentSearchFilterMenu
            label={t('authoring.courses.importedContent.searchFilters.duration')}
            selected={duration}
            setSelected={setDuration}
            options={durationOptions}
          />
        </HStack>
      }
      searchResultsElement={
        data?.pages && data.pages[0].results.length > 0 ? (
          <>
            {data.pages.map((page) => (
              <Stack key={page.results[0].id} spacing={4}>
                {page.results.map((result) => (
                  <ImportedContentSearchCard
                    key={result.id}
                    isLoading={getUdemyCourseById.isLoading}
                    onClick={() => getUdemyCourseById.mutateAsync(result.id)}
                    backgroundImage={result.image_480x270}
                    title={result.title}
                    author={result.visible_instructors[0].display_name}
                    description={result.headline}
                  />
                ))}
              </Stack>
            ))}
          </>
        ) : undefined
      }
    />
  );
}
