/* eslint-disable react/jsx-props-no-spreading */

import { Controller, useFormContext } from 'react-hook-form';

import { Checkbox } from '@chakra-ui/react';
import type { Content } from '@/client/services/api/graphql/gql/graphql';

interface CreateCheckboxProps {
  name: any;
  label: string;
  isChecked: boolean;
}

export default function CreateCheckbox({ name, label, isChecked }: CreateCheckboxProps) {
  const { control } = useFormContext<Content>();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Checkbox isChecked={isChecked} {...field} variant="create">
          {label}
        </Checkbox>
      )}
    />
  );
}
