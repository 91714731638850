import { AuthoringEmbedLink, AuthoringVideo } from '@/client/components/icons/ContinuIcons';
import {
  Button,
  ButtonGroup,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';

import CreateRadioGroup from '@/client/components/admin/create/shared/input/CreateRadioGroup';
import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import VideoDropzone from './VideoDropzone';
import VideoEmbedForm from './VideoEmbedForm';
import type { VideoFormData } from '@/client/routes/admin/create/content/EditVideo';
// eslint-disable-next-line import/extensions
import discardImage from '@/client/assets/images/discard.svg';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// TODO: Refactor this with FileUpload as reusable component?

export default function VideoUpload() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { uploadStatus } = useCreateStore();
  const { watch, setValue } = useFormContext<VideoFormData>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const videoUploadType = watch('videoUploadType');
  const videoDetails = watch('videoDetails');
  const link = watch('link');
  const isTranscoding = watch('transcoding');

  const renderReplaceButton = !!id && !videoDetails && link;
  const renderRadioGroup = videoUploadType === 'upload' ? !videoDetails || !link : !link;

  return (
    <>
      {renderReplaceButton && (
        <>
          <Button
            isDisabled={isTranscoding}
            variant="createGoldOutline"
            size="sm"
            onClick={() => onOpen()}
          >
            {t('authoring.video.replaceVideo')}
          </Button>

          {isTranscoding && (
            <Stack>
              <Text variant="createHelpText">{t('authoring.video.upload.processing')}</Text>

              <Text variant="createHelpText">
                {t('authoring.video.upload.processing.helpText')}
              </Text>
            </Stack>
          )}

          <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
            <ModalOverlay />

            <ModalContent>
              <ModalBody paddingTop={12}>
                <VStack textAlign="center">
                  <Image src={discardImage} width={120} height="auto" />

                  <Text fontSize="20px" fontWeight={600} color="baseBlack">
                    {t('authoring.video.replaceModal')}
                  </Text>
                </VStack>
              </ModalBody>

              <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
                <ButtonGroup width="full" alignItems="center" justifyContent="center">
                  <OutlineButton onClick={onClose}>
                    {t('authoring.video.replaceModal.cancel')}
                  </OutlineButton>

                  <SolidButton
                    type="submit"
                    onClick={() => {
                      setValue('link', '');
                      setValue('videoUploadType', 'upload');
                      onClose();
                    }}
                  >
                    {t('authoring.video.replaceModal.confirm')}
                  </SolidButton>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}

      {!renderReplaceButton && (
        <>
          {uploadStatus !== 'loading' && renderRadioGroup && (
            <CreateRadioGroup
              name="videoUploadType"
              defaultValue={videoUploadType}
              label=""
              options={[
                {
                  value: 'upload',
                  label: t('authoring.video.uploadVideo'),
                  icon: <Icon boxSize={12} as={AuthoringVideo} />,
                },
                {
                  value: 'embed',
                  label: t('authoring.video.urlLink'),
                  icon: <Icon boxSize={12} as={AuthoringEmbedLink} />,
                },
              ]}
            />
          )}

          {videoUploadType === 'upload' && <VideoDropzone />}

          {videoUploadType === 'embed' && <VideoEmbedForm />}
        </>
      )}
    </>
  );
}
