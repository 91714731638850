import { Box, Container, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import AnalyzeService from '@/client/services/api/AnalyzeService';
import CommentList from '../../components/lists/CommentList';
import ContentContainer from '@/client/components/layout/ContentContainer';
import ContentHeader from '../../components/layout/ContentHeader';
import ContentRatingInput from '@/client/components/data-display/ratings/ContentRatingInput';
import ContentService from '@/client/services/api/ContentService';
import ContentStats from '../../components/data-display/content/ContentStats';
import ErrorAlert from '@/client/components/data-display/ErrorAlert';
import HtmlRenderer from '../../components/html/HtmlRenderer';
import Loading from '@/client/components/media/Loading';
import NotAuthorized from '@/client/components/data-display/content/NotAuthorized';
import PopupSurveyLayout from '@/client/components/overlay/PopupSurvey';
import Recommendations from '@/client/components/layout/Recommendations';
import VideoPlayer from '../../components/media/video/VideoPlayer';
import ViewActions from '@/client/components/overlay/ViewActions';
import { trackEvent } from '@/client/utils/AnalyticsProvider';
import { useCheckContentAuth } from '@/client/services/hooks/content/useCheckContentAuth';
import { useCheckSurveyCompletion } from '@/client/services/hooks/useCheckSurveyCompletion';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { useQuery } from '@tanstack/react-query';
import { useSessionTracking } from '@/client/services/hooks/useSessionTracking';
import { usePartnerStore } from '@/client/services/state/partnerStore';

interface VideoProps {
  contentId: string;
  needsContextSelection: boolean;
  sendCompleteContent: Function;
  trackId?: string;
  journeyId?: string;
}

export default function Video({
  trackId,
  journeyId,
  contentId,
  needsContextSelection,
  sendCompleteContent,
}: VideoProps) {
  const { allowView } = useCheckContentAuth();
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();
  const { partner } = usePartnerStore();
  const [isAllowed, setIsAllowed] = useState<undefined | boolean>();
  const [renderRecommendations, setRenderRecommendations] = useState<boolean>(false);

  const { sendSessionTime } = useSessionTracking(contentId, 'video');

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (!needsContextSelection && isAllowed) {
      sendCompleteContent();
      AnalyzeService.postViews(contentId, 'video');
      ContentService.postViews(contentId, 'video');
    }

    return () => {
      sendSessionTime();
    };
  }, [needsContextSelection, isAllowed]);

  const { isLoading, isError, data } = useQuery({
    enabled: !!contentId,
    queryKey: ['video', contentId],
    queryFn: () => ContentService.getVideo(contentId),
    onSuccess: (videoData) => {
      document.title = videoData.title;
      checkShowPopupSurvey(data, true, !!trackId || !!journeyId);
      trackEvent('video_viewed', {
        content: videoData._id,
        content_type: 'video',
        title: videoData.title,
      });
    },
  });

  useEffect(() => {
    (async () => {
      if (data) {
        const allowed = await allowView(data);

        setIsAllowed(allowed);
      }
    })();
  }, [data]);

  const { data: position } = useQuery({
    enabled: !!contentId,
    queryKey: ['user-videos', contentId],
    queryFn: async () => AnalyzeService.getUserVideoPosition(contentId),
  });

  useDocumentTitle(data ? data.title : 'Video', true);

  const recordPosition = (progressed: number) => {
    AnalyzeService.recordUserVideoPosition(contentId, progressed);
  };

  if (isLoading) return <Loading />;

  if (isError) return <ErrorAlert title="Unable to get video" />;

  if (isAllowed === false) return <NotAuthorized />;

  return (
    <>
      {isAllowed && (
        <>
          {!trackId && !journeyId && (
            <ContentHeader
              contentType="video"
              contentTitle={data.title}
              contentId={data._id}
              withBorder
              allowComments={data.allow_comments}
            />
          )}

          <Box marginTop={10}>
            <Container maxWidth="container.sm" margin="0 auto">
              <ContentContainer inJourney={!!journeyId}>
                <ContentStats
                  author={data.author}
                  duration={data.duration}
                  averageRating={data.average_rating}
                  ratingConfiguration={data.rating_configuration}
                />

                <Text variant="contentheader">{data.title}</Text>
              </ContentContainer>
            </Container>

            <Box background="#e4e4e4" width="100%" paddingY={8} marginBottom={8}>
              <Box maxWidth="container.sm" margin="0 auto">
                <VideoPlayer
                  url={data.link}
                  recordPosition={recordPosition}
                  position={position?.last_position}
                  vttFiles={data.vtt_files}
                  videoSource={data.source}
                  enableRecommendations={!trackId && !journeyId}
                  setRenderRecommendations={setRenderRecommendations}
                />
              </Box>
            </Box>

            <Container maxWidth="container.md" margin="0 auto">
              <Box maxWidth="container.md" width="700px" margin="0 auto">
                <HtmlRenderer html={data.description} width="700px" />

                {renderRecommendations && <Recommendations contentId={contentId} />}

                {data.rating_configuration && data.rating_configuration.allow_rating && (
                  <ContentRatingInput
                    contentId={data._id}
                    contentType="Media"
                    ratingConfiguration={data.rating_configuration}
                  />
                )}
              </Box>

              {data.allow_comments && !journeyId && !partner._id && (
                <CommentList contentId={contentId} contentType="media" contentTitle={data.title} />
              )}
            </Container>

            {!trackId && !journeyId && (
              <ViewActions buttons={['gototop']} bottomOffset={showPopup ? 10 : 0} />
            )}
          </Box>

          {showPopup && (
            <PopupSurveyLayout
              surveyId={data.surveys[0]?.survey_id}
              contentId={data._id}
              contentType="video"
            />
          )}
        </>
      )}
    </>
  );
}

Video.defaultProps = {
  trackId: '',
  journeyId: '',
};
