import { learnApiClient } from './clients/learnApiClient';

const getUserAccess = async () => {
  const response = await learnApiClient.get('/explore/user-access');

  return response.data;
};

const ExplorerService = {
  getUserAccess,
};

export default ExplorerService;
