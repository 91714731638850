import { Avatar, Button, ButtonGroup, Flex, useToken } from '@chakra-ui/react';
import { Mention, MentionsInput } from 'react-mentions';

import { SearchService } from '@/client/services/api/SearchService';
import mentionInputStyles from './mentionInputStyles';
import { useAuthStore } from '@/client/services/state/authStore';
import { useTranslation } from 'react-i18next';
import { usePartnerStore } from '@/client/services/state/partnerStore';

interface CommentInputProps {
  value: string | undefined;
  setValue: (value: string) => void;
  onSubmit: any;
  contentType: string;
  onCancel?: any;
  isReply?: boolean;
  isEdit?: boolean;
  mentions: string[];
  setMentions: (mentions: string[]) => void;
}

export default function CommentInput({
  value,
  setValue,
  onSubmit,
  contentType,
  onCancel,
  isReply,
  isEdit,
  mentions,
  setMentions,
}: CommentInputProps) {
  const { authConfig } = useAuthStore();
  const user = authConfig.user;
  const { partner } = usePartnerStore();
  const [mentionColor] = useToken('colors', ['brand.gray.100']);
  const { t } = useTranslation();
  const placeholder = t('singlecomment.typeComment');

  const fetchTaggableUsers = async (query: string, callback: any) => {
    if (!query) return;

    const partnerId = partner._id || null;

    const users = await SearchService.searchAllUsers(query, partnerId);

    const formattedUsers = users.map((user: any) => ({ display: user.full_name, id: user._id }));

    return callback(formattedUsers);
  };

  return (
    <Flex paddingY={4} width="full" flexDirection={{ base: 'row' }}>
      {!isEdit && (
        <Avatar
          name={user.full_name}
          src={user.image}
          marginRight={6}
          marginBottom={{ base: 0, sm: 4, lg: 0 }}
          size="md"
          backgroundColor="gray.300"
          color="gray.500"
        />
      )}

      <Flex direction="column" width="full">
        <MentionsInput
          style={mentionInputStyles}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
        >
          <Mention
            trigger="@"
            data={fetchTaggableUsers}
            onAdd={(id) => setMentions([...mentions, id.toString()])}
            appendSpaceOnAdd
            style={{ backgroundColor: mentionColor, borderRadius: '4px' }}
            markup="<:__display__:__id__>"
            displayTransform={(id, display) => `@${display}`}
          />
        </MentionsInput>

        <ButtonGroup marginTop={4}>
          <Button isDisabled={value === ''} onClick={onSubmit}>
            {t('modules.comments.addComment')}
          </Button>

          {(isReply || isEdit) && (
            <Button
              backgroundColor="red"
              _hover={{ backgroundColor: 'red' }}
              onClick={() => {
                onCancel(false);
                setMentions([]);
              }}
            >
              {t('singlecomment.cancel')}
            </Button>
          )}
        </ButtonGroup>
      </Flex>
    </Flex>
  );
}
