import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from '@stripe/react-stripe-js';
import { useState, useEffect } from 'react';
import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'usehooks-ts';
import PaymentSummary from './PaymentSummary';
import { Product } from '@/client/services/api/graphql/gql/graphql';
import { useAuthStore } from '@/client/services/state/authStore';
import ProductsService from '@/client/services/api/graphql/ProductsService';
import AlreadyPurchased from './AlreadyPurchased';
import { useGetPurchaseFromProduct } from '@/client/services/hooks/content/products/useGetPurchaseFromProduct';

export type Props = {
  clientSecret: string;
  context: 'create' | 'update-existing' | 'update-new';
  setupIntentId: string;
  productData?: Product | null;
  subscriptionId?: string;
  newProductId?: string;
};

export default function PaymentMethodForm({
  clientSecret,
  context,
  setupIntentId,
  productData = undefined,
  subscriptionId = undefined,
  newProductId = undefined,
}: Props) {
  useDocumentTitle('Manage Payment Methods');
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const [addressIsComplete, setAddressIsComplete] = useState(false);
  const [paymentIsComplete, setPaymentIsComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const CLIENT_URL = window.location.origin;
  const [error, setError] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const { data: purchaseData } = useGetPurchaseFromProduct(user._id, productData?.id!);

  const getReturnURL = (paymentContext: string, clientURL: string, productId: string, setupId: string, userSubscriptionId?: string, newSelectedProductId?: string) => {
    if (paymentContext === 'create') {
      return `${clientURL}/product/${productId}/payment-setup-success?setupIntentId=${setupId}`;
    } 
    if (paymentContext === 'update-existing') {
      return `${clientURL}/subscription/${userSubscriptionId}/payment-update-success?setupIntentId=${setupId}`;
    } 
    if (paymentContext === 'update-new' && newSelectedProductId) {
      return `${clientURL}/change-subscription/${userSubscriptionId}/product/${newSelectedProductId}?setupIntentId=${setupId}`;
    }
    // This will only happen if context isn't set correctly so it's more of a dev precaution. Wondering if there's a better way to handle this.
    return `/dashboard`;
  }

  useEffect(() => {
    if (!elements) return;
    const paymentElement = elements.getElement(PaymentElement);
    const addressElement = elements.getElement(AddressElement);
    if (!paymentElement) return;
    // @ts-ignore
    paymentElement.on('change', (event) => {
      setPaymentIsComplete(event.complete);
    });

    if (!addressElement) return;
    // @ts-ignore
    addressElement.on('change', (event) => {
      setAddressIsComplete(event.complete);
    });

    return () => {
      // @ts-ignore
      paymentElement.off('change');
      // @ts-ignore
      addressElement.off('change');
    };
  }, [elements]);

  const handleAddNewCard = async () => {
    if (!stripe || !elements) return;

    const paymentElementRef = elements.getElement(PaymentElement);
    const addressElementRef = elements.getElement(AddressElement);
    const address = await addressElementRef?.getValue();
    // TODO: Throw error is address not complete
    if (!address?.complete) return;

    if (!paymentElementRef) return;

    try {
      await elements.submit();
      const { error: setupError } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: getReturnURL(context, CLIENT_URL, productData?.id!, setupIntentId, subscriptionId, newProductId),
        },
      });

      if (error) {
        setError(setupError.message as string);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    handleAddNewCard();
  };

  if (purchaseData?.purchases.length) {
    return (
      <AlreadyPurchased purchasedDate={new Date(purchaseData.purchases[0].purchaseConfirmedDate)} />
    );
  }

  return (
    <Grid minH="100vh" templateColumns={productData ? 'repeat(2, 1fr)' : '1fr'}>
      <GridItem>
        {clientSecret && (
          <Flex w="100%" justifyContent="center" paddingTop="100px">
            <form onSubmit={handleSubmit}>
              <Box>
                <Grid gap={2}>
                  <GridItem colSpan={{ base: 12 }}>
                    <Text fontWeight="600" textAlign="left" marginBottom={4}>
                      {t('ecommerce.checkout.billingInformation')}
                    </Text>
                    <Box>
                      <AddressElement
                        options={{
                          mode: 'billing',
                          validation: {
                            phone: {
                              required: 'always',
                            },
                          },
                          fields: {
                            phone: 'always',
                          },
                        }}
                      />
                    </Box>
                  </GridItem>

                  <GridItem colSpan={{ base: 12 }}>
                    <Text variant="label" textAlign="left" marginY={4}>
                      {t('ecommerce.checkout.cardDetails')}
                    </Text>
                    <Box>
                      <PaymentElement />
                    </Box>
                  </GridItem>
                </Grid>
              </Box>
              {error && <p>{error}</p>}
              {!productData && (
                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={!addressIsComplete || !paymentIsComplete || isSubmitting}
                  onClick={(event: any) => handleSubmit(event)}
                >
                  {t('ecommerce.checkout.button.completeCheckout')}
                </Button>
              )}
            </form>
          </Flex>
        )}
      </GridItem>
      {!!productData && (
        <GridItem>
          <PaymentSummary
            productInfo={productData}
            handleSubmit={handleSubmit}
            isComplete={addressIsComplete && paymentIsComplete}
            isSubmitting={isSubmitting}
          />
        </GridItem>
      )}
    </Grid>
  );
}
