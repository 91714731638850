import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useEffect } from 'react';
import { useTrackStateStore } from '@/client/services/state/admin/create/trackStateStore';

export const useCreateNavigationBlocker = () => {
  const { canLeave } = useCreateStore();
  const { trackState, setTrackState } = useTrackStateStore();

  const beforeUnloadHandler = (event: any) => {
    // Recommended
    event.preventDefault();

    setTrackState(null);
    localStorage.removeItem('trackState');

    // Included for legacy support, e.g. Chrome/Edge < 119
    // eslint-disable-next-line no-param-reassign
    event.returnValue = true;
  };

  useEffect(() => {
    if (canLeave) {
      window.removeEventListener('beforeunload', beforeUnloadHandler);

      return;
    }

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [canLeave, trackState]);
};
