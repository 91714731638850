import { Button, ButtonGroup, Center, Flex, Text } from '@chakra-ui/react';
import { Action, AuthType, init } from '@thoughtspot/visual-embed-sdk';
import { getAuthToken } from '@/client/services/api/admin/reports/AdvancedReportsService';
import { useTranslation } from 'react-i18next';
import useDocumentTitle from '@/client/utils/useDocumentTitle';
import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useToastStore } from '@/client/services/state/toastStore';
import { useConfigStore } from '@/client/services/state/configStore';
import { AppEmbed, Page } from '@thoughtspot/visual-embed-sdk/react';
import AdminSecondaryHeader from '@/client/components/admin/layout/AdminSecondaryHeader';
import { useNavigate } from 'react-router-dom';
import AdvancedReportsService from '@/client/services/api/admin/advanced-reports/AdvancedReportsService';
import { useQuery } from '@tanstack/react-query';

export default function AdvancedReports() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { allowance } = useAccessHelper();
  const { setToast } = useToastStore();
  const { config } = useConfigStore();

  if (!allowance.advancedReports) {
    setToast({
      show: true,
      status: 'error',
      title: t('advancedReports.noAccess'),
    });
    navigate('/explore');
  }

  const { data: isAIEnabled } = useQuery({
    enabled: allowance.advancedReports,
    queryKey: ['checkAiEnabled'],
    queryFn: () => AdvancedReportsService.checkAiEnabled(),
  });

  useDocumentTitle(t('advancedReports.header'));

  init({
    thoughtSpotHost: config.advancedReportsHost,
    authType: AuthType.TrustedAuthTokenCookieless,
    getAuthToken: async () => getAuthToken(),
    disableLoginFailurePage: true,
    customizations: {
      content: {
        strings: {
          Sage: 'Continu AI',
        },
      },
    },
  });

  const goLiveboardHome = () => {
    navigate('/admin/reports/advanced');
  };

  const visibleActions = [Action.Download, Action.DownloadAsCsv];

  return (
    <>
      <AdminSecondaryHeader>
        <Flex justify="left">
          <Center>
            <ButtonGroup size="xs">
              <Button variant="adminPrimary" size="xs" onClick={() => goLiveboardHome()}>
                {t('global.actions.home')}
              </Button>
            </ButtonGroup>
          </Center>
          <Text fontSize="xl" lineHeight="1.2" fontWeight="500" margin={2}>
            {t('advancedReports.header')}
          </Text>
        </Flex>
      </AdminSecondaryHeader>
      <AppEmbed
        frameParams={{ height: '100vh' }}
        pageId={Page.Liveboards}
        visibleActions={isAIEnabled ? [...visibleActions, Action.AskAi] : visibleActions}
        showPrimaryNavbar={false}
        isLiveboardHeaderSticky
        onError={(error) => console.error('Error Loading Advanced Reports', error)}
        onLiveboardRendered={() => {}}
        fullHeight
        hideOrgSwitcher
        enableAskSage
      />
    </>
  );
}
