import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type EmailInputProps = {
  setEmail: (email: string) => void;
};

export default function EmailInput({ setEmail }: EmailInputProps) {
  const { t } = useTranslation();
  const [emailError, setEmailError] = useState<boolean>(false);
  const emailPlaceholder = t('global.forms.labels_emailAddress');

  const isValidEmail = (email: string) => /\S+@\S+\.\S+/.test(email);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'email' && isValidEmail(event.target.value)) {
      setEmailError(false);
      setEmail(event.target.value);
    } else {
      setEmailError(true);
    }
  };

  return (
    <FormControl isInvalid={emailError}>
      <Input
        name="email"
        type="email"
        placeholder={emailPlaceholder}
        variant="flushed"
        onChange={(event) => handleEmailChange(event)}
      />

      <FormErrorMessage color="red">Please enter a valid email address</FormErrorMessage>
    </FormControl>
  );
}
