/* eslint-disable react/jsx-props-no-spreading */
import { Box, HStack, Icon, Text, useRadio } from '@chakra-ui/react';
import { FaRegCircle, FaRegCircleDot } from 'react-icons/fa6';

export default function CreateRadio(props: any) {
  const { label, icon } = props;
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const { checked } = input;
  const checkbox = getRadioProps();

  return (
    <Box as="label" width="50%">
      <input {...input} />

      <Box
        {...checkbox}
        cursor="pointer"
        border="1px solid"
        borderRadius="md"
        backgroundColor="white"
        borderColor="brand.gold.30"
        paddingY={3}
        paddingX={4}
        _checked={{
          borderColor: 'brand.gold.100',
        }}
      >
        <HStack>
          <Icon
            as={checked ? FaRegCircleDot : FaRegCircle}
            color={checked ? 'brand.gold.100' : 'brand.gold.30'}
          />

          {icon}

          <Text variant="createLabel">{label}</Text>
        </HStack>
      </Box>
    </Box>
  );
}
