import { Button, Flex, HStack, Input, Stack, Text } from '@chakra-ui/react';

import { AuthoringEmbedLink } from '@/client/components/icons/ContinuIcons';
import { CloseIcon } from '@chakra-ui/icons';
import IconButtonWithTooltip from '@/client/components/buttons/IconButtonWithTooltip';
import { useAuthorVideo } from '@/client/services/hooks/admin/authoring/videos/useAuthorVideo';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function VideoEmbedForm() {
  const { t } = useTranslation();
  const { embedLink, setEmbedLink, currentLink, handleAddLink } = useAuthorVideo(undefined);
  const { setValue } = useFormContext();

  return (
    <Stack>
      {currentLink && (
        <Flex
          backgroundColor="warmNeutral.100"
          border="1px solid"
          borderColor="warmNeutral.200"
          borderRadius="md"
          paddingY={4}
          paddingX={6}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack>
            <AuthoringEmbedLink boxSize={8} />

            <Text color="baseBlack" fontWeight={600} wordBreak="break-word">
              {currentLink}
            </Text>
          </Stack>

          <IconButtonWithTooltip
            tooltipCopy="Replace Link"
            icon={<CloseIcon color="brand.gold.100" />}
            ariaLabel="Replace Link"
            onClick={() => {
              setValue('title', '');
              setValue('description', '');
              setValue('link', '');
              setEmbedLink('');
            }}
          />
        </Flex>
      )}

      {!currentLink && (
        <>
          <Text variant="createLabel">{t('authoring.video.embed.videoUrl')}</Text>

          <HStack>
            <Input
              variant="create"
              value={embedLink}
              isRequired
              placeholder="https://www.example.com/1234"
              onChange={(e) => setEmbedLink(e.target.value)}
            />

            <Button
              isDisabled={embedLink === '' || embedLink === currentLink}
              paddingY={2}
              paddingX={3}
              variant="createGoldOutline"
              onClick={handleAddLink}
            >
              {t('authoring.video.embed.saveLink')}
            </Button>
          </HStack>
        </>
      )}
    </Stack>
  );
}
