/* eslint-disable react/no-unstable-nested-components */
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Progress,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import { Check } from '@/client/components/icons/ContinuIcons';
import ManuallyPaginatedTable from '@/client/components/admin/tables/ManuallyPaginatedTable';
import TableActionsMenuNew from '@/client/components/admin/menus/TableActionsMenuNew';
import { UserManagerLearningTrack } from '@/client/types/admin/user-manager/UserManagerLearningTrack';
import { differenceInCalendarDays } from 'date-fns';
import { learnApiClient } from '@/client/services/api/clients/learnApiClient';
import { useFormatDate } from '@/client/services/hooks/date-and-time/useFormatDate';
import { useParams } from 'react-router-dom';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

const calculateProgress = (track: UserManagerLearningTrack) => {
  let totalCourses = 0;
  let completedCourses = 0;

  completedCourses += track.completed_articles.length;
  completedCourses += track.completed_media.length;
  completedCourses += track.completed_quizzes.length;
  completedCourses += track.completed_scorm.length;
  completedCourses += track.completed_assessments.length;

  track.track.sections.forEach((section) => {
    totalCourses += section.courses.length;
  });

  return Math.round((completedCourses / totalCourses) * 100);
};

interface UserLearningTracksProps {
  totalLearningTracks: number | undefined;
}

export default function UserLearningTracks({ totalLearningTracks }: UserLearningTracksProps) {
  const { formatDate } = useFormatDate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { setToast } = useToastStore();
  const [selectedTrack, setSelectedTrack] = useState<UserManagerLearningTrack | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);

  const getUserManagerLearningTracks = async (
    options: { pageIndex: number; pageSize: number },
    userId: string | undefined,
  ): Promise<{ rows: UserManagerLearningTrack[]; pageCount: number }> => {
    const response = await learnApiClient.get<UserManagerLearningTrack[]>(`user-tracks`, {
      params: {
        fields:
          'user,track,completed_media,completed_articles,completed_quizzes,completed_scorm,completed_assessments,completed_date,updatedAt,createdAt',
        page: options.pageIndex + 1,
        per_page: options.pageSize,
        sort: 'updatedAt,-1',
        'track-populate': 'name,sections.courses',
        user: userId,
      },
    });

    return {
      rows: response.data,
      pageCount: totalLearningTracks ? Math.ceil(totalLearningTracks / options.pageSize) : 1,
    };
  };

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data, refetch } = useQuery({
    queryKey: ['user-manager-learning-tracks', fetchDataOptions, id],
    queryFn: () => getUserManagerLearningTracks(fetchDataOptions, id),
  });

  const markManuallyComplete = async (idToComplete: string | undefined) => {
    if (!selectedDate) return;

    const response = await learnApiClient.post(`user-tracks/${idToComplete}/manual-complete`, {
      completedDate: new Date(selectedDate),
    });

    return response.data;
  };

  const manuallyMarkComplete = useMutation({
    mutationFn: (trackId: string | undefined) => markManuallyComplete(trackId),
    onSuccess: () => {
      setSelectedTrack(null);
      refetch();

      setToast({
        show: true,
        status: 'success',
        title: t('track.manualCompletion.completed'),
      });
    },
    onError: () =>
      setToast({
        show: true,
        status: 'error',
        title: t('track.manualCompletion.error'),
      }),
  });

  const columnHelper = createColumnHelper<UserManagerLearningTrack>();
  const columns = useMemo<ColumnDefBase<UserManagerLearningTrack, any>[]>(
    () => [
      columnHelper.accessor('track.name', {
        cell: (info) => <Box>{info.getValue()}</Box>,
        header: () => <span>{t('manage.users.tracks.name')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => <Box>{formatDate(new Date(info.getValue()))}</Box>,
        header: () => <span>{t('manage.users.tracks.dateSignedUp')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'progress',
        cell: (info) => {
          const progress = info.row.original.completed_date
            ? 100
            : calculateProgress(info.row.original);

          return (
            <HStack>
              <Progress value={progress} size="sm" colorScheme="primary" width="50%" />

              <Text>{progress}%</Text>
            </HStack>
          );
        },
        header: () => <span>{t('manage.users.tracks.percentComplete')}</span>,
      }),
      columnHelper.display({
        id: 'duration',
        cell: (info) => {
          const duration = info.row.original.completed_date
            ? differenceInCalendarDays(
                new Date(info.row.original.completed_date),
                new Date(info.row.original.createdAt),
              )
            : differenceInCalendarDays(new Date(), new Date(info.row.original.createdAt));

          return (
            <Text>
              {duration} {t('managerUser.days')}
            </Text>
          );
        },
        header: () => <span>{t('manage.users.tracks.duration')}</span>,
      }),
      columnHelper.display({
        id: 'dateCompleted',
        cell: (info) => (
          <Text>
            {info.row.original.completed_date
              ? formatDate(new Date(info.row.original.completed_date))
              : '----'}
          </Text>
        ),
        header: () => <span>{t('manage.users.tracks.dateCompleted')}</span>,
      }),
      columnHelper.accessor('updatedAt', {
        cell: (info) => <Box>{formatDate(new Date(info.getValue()))}</Box>,
        header: () => <span>{t('manage.users.tracks.lastActivity')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => (
          <TableActionsMenuNew
            isDisabled={!!info.row.original.completed_date}
            actions={[
              {
                icon: <Check color="brand.green" />,
                label: t('manage.users.tracks.markAsCompleted'),
                enabled: true,
                onClick: () => setSelectedTrack(info.row.original),
              },
            ]}
          />
        ),
      }),
    ],
    [],
  );

  return (
    <Box
      marginX={4}
      marginY={1}
      paddingX={6}
      paddingY={2}
      borderRadius="md"
      backgroundColor="white"
      boxShadow="0px 2px 2px rgba(0, 0, 0, 0.1);"
    >
      <ManuallyPaginatedTable
        columns={columns}
        queryData={data}
        pagination={pagination}
        setPagination={setPagination}
      />

      <Modal size="2xl" isOpen={selectedTrack !== null} onClose={() => setSelectedTrack(null)}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalBody>
            <VStack paddingY={12} spacing={4}>
              <Text fontSize="md" fontWeight="semibold">
                {t('track.manualCompletion.heading')}
              </Text>

              <Text>{t('track.manualCompletion.cannotBeUndone')}</Text>

              <Text>{t('track.manualCompletion.areYouSure')}</Text>

              <Input
                placeholder="Select Date and Time"
                size="md"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </VStack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup size="sm" onClick={() => manuallyMarkComplete.mutate(selectedTrack?._id)}>
              <Button variant="adminPrimary" onClick={() => setSelectedTrack(null)}>
                {t('global.actions.confirm')}
              </Button>

              <Button variant="adminError">{t('global.label.cancel')}</Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
