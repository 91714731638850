import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';

import { AngleDown } from '@/client/components/icons/ContinuIcons';
import WorkshopsCard from '@/client/components/data-display/cards/WorkshopsCard';
import { format } from 'date-fns';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { validateRadioValue } from '@/client/utils/workshops/handleFilterSelection';

type ListProps = {
  searchData: any;
  querySort: (sortBy: string) => void;
  fetchNextPage: () => void;
  isPast: boolean;
  isSearching: boolean;
  isFetchingNextPage: boolean;
};

export default function WorkshopsList({
  searchData,
  querySort,
  fetchNextPage,
  isPast,
  isSearching,
  isFetchingNextPage,
}: ListProps) {
  const { ref, inView } = useInView();
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState<string>('default');

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (searchData) {
      querySort(sortBy);
    }
  }, [sortBy]);

  const nameFromValueKey: any = {
    default: t('workshops.default'),
    popularity: t('workshops.popularity'),
    availability: t('workshops.availability'),
    newest: t('workshops.recentlyAdded'),
  };

  const handleSortChange = (e: string | string[]) => {
    const formattedSort = validateRadioValue(e);
    setSortBy(formattedSort);
  };

  const showDateLine = (startDate: Date, pageIndex: number, index: number) => {
    if (sortBy !== 'default') {
      return false;
    }
    if (index === 0) {
      return true;
    }

    const previousStartDate = searchData.pages[pageIndex].items[index - 1].start_date;
    const selectedWorkshopDay = new Date(startDate).toDateString();
    const previousWorkshopDay = new Date(previousStartDate).toDateString();

    if (previousWorkshopDay !== selectedWorkshopDay) {
      return true;
    }
    return false;
  };

  const showMonth = (startDate: Date, pageIndex: number, index: number) => {
    if (sortBy !== 'default') {
      return false;
    }

    if (index === 0) {
      return true;
    }

    const previousStartDate = searchData.pages[pageIndex].items[index - 1].start_date;
    const selectedWorkshopMonth = new Date(startDate).getMonth();
    const selectedWorkshopYear = new Date(startDate).getFullYear();
    const previousWorkshopMonth = new Date(previousStartDate).getMonth();
    const previousWorkshopYear = new Date(previousStartDate).getFullYear();

    if (previousWorkshopMonth !== selectedWorkshopMonth) {
      return true;
    }
    if (previousWorkshopYear !== selectedWorkshopYear) {
      return true;
    }
    return false;
  };

  const startDateToMonth = (startDate: Date) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const workshopDate = new Date(startDate);
    const workshopMonth = workshopDate.getMonth();
    const yearDifference = workshopDate.getFullYear() - currentDate.getFullYear();

    if (yearDifference === 0 && currentMonth === workshopMonth) {
      return t('workshops.thisMonth');
    }
    if (
      (yearDifference === 0 && workshopMonth === currentMonth + 1) ||
      (yearDifference === 1 && currentMonth === 11 && workshopMonth === 0)
    ) {
      return t('workshops.nextMonth');
    }

    return format(workshopDate, 'MMMM yyyy');
  };

  return (
    <Box
      padding={6}
      paddingLeft={8}
      backgroundColor="white"
      margin={{ base: 0, lg: 6 }}
      marginTop={0}
      borderRadius="5px"
      width={{ base: 'full', lg: '60%' }}
      // height={{ base: '60vh', lg: '630px' }}
      // minHeight="615px"
      height="auto"
      overflowY="auto"
      order={{ base: 2, lg: 1 }}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Text fontSize="xl" lineHeight="1.2" fontWeight="500" marginBottom="5">
          {t('global.contentTypes.events')}
        </Text>
        <Menu variant="workshopfilter" matchWidth>
          <MenuButton
            as={Button}
            variant="workshopfilter"
            height={8}
            width="30%"
            rightIcon={<AngleDown color="#1A1A1A80" />}
          >
            {nameFromValueKey[sortBy]}
          </MenuButton>
          <MenuList minWidth={0}>
            <MenuOptionGroup type="radio" onChange={(e) => handleSortChange(e)}>
              <MenuItemOption value="default">{t('workshops.default')}</MenuItemOption>
              <MenuItemOption value="newest">{t('workshops.recentlyAdded')}</MenuItemOption>
              <MenuItemOption value="popularity">{t('workshops.popularity')}</MenuItemOption>
              {!isPast && (
                <MenuItemOption value="availability">{t('workshops.availability')}</MenuItemOption>
              )}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Flex>

      {searchData &&
        searchData.pages.map((page: any, i: number) => (
          <Fragment key={i}>
            {page.items.map((workshopDate: any, innerIndex: number) => (
              <Fragment key={workshopDate._id}>
                {showMonth(workshopDate.start_date, i, innerIndex) && (
                  <Text fontWeight="600">{startDateToMonth(workshopDate.start_date)}</Text>
                )}
                <Box
                  borderTopColor={
                    showDateLine(workshopDate.start_date, i, innerIndex) ? 'gray.300' : ''
                  }
                  borderTopWidth={showDateLine(workshopDate.start_date, i, innerIndex) ? 1 : 0}
                  padding="8"
                  paddingTop={showDateLine(workshopDate.start_date, i, innerIndex) ? '8' : '0'}
                  paddingLeft="0"
                  paddingRight="0"
                >
                  <WorkshopsCard
                    workshopId={workshopDate.workshop._id}
                    workshopTitle={workshopDate.workshop.title}
                    workshopType={workshopDate.workshop.type}
                    online={workshopDate.online}
                    image={workshopDate.workshop.image}
                    startDate={workshopDate.start_date}
                    registeredStatus={workshopDate.status}
                    seats={workshopDate.seats}
                    registrations={workshopDate.registrations}
                    imageAlignment={workshopDate.workshop.cover_justification}
                  />
                </Box>
              </Fragment>
            ))}
          </Fragment>
        ))}

      {!isSearching && searchData && searchData.pages && searchData.pages.length > 0 && (
        <Center ref={ref} margin={2}>
          {isFetchingNextPage && <Spinner />}
        </Center>
      )}

      {searchData && searchData.pages[0].items.length === 0 && <Center>No workshops found</Center>}
    </Box>
  );
}
