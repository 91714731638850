import { AuthConfig } from '@/client/types/AuthConfig';
import { useState } from 'react';

export const useNPS = () => {
  const [hasBeenAdded, setHasBeenAdded] = useState(false);
  const [beingAdded, setBeingAdded] = useState(false);
  const [attempts, setAttempts] = useState(0);

  const addNPS = (authConfig: AuthConfig) => {
    if (authConfig.company && !authConfig.company.feature_flags.enable_nps_surveys) {
      return;
    }

    setBeingAdded(true);

    if (hasBeenAdded === true) {
      return;
    }

    let script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('class', 'js-nps');

    var user = authConfig.user;

    var delightedOptions = {
      email: user.email,
      name: user.full_name,
      properties: {
        company: user.company,
      },
      initialDelay: 1209600,
    };

    var waitForUser = setInterval(function () {
      setAttempts((attempts) => attempts + 1);

      if (attempts === 10) clearInterval(waitForUser);
      if (hasBeenAdded) clearInterval(waitForUser);

      if (!hasBeenAdded && delightedOptions.email && delightedOptions.email.length > 0) {
        script.innerHTML =
          '!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript", o.async=!0, o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"XWzBDZmSCPCgSPod","delighted");delighted.survey(' +
          JSON.stringify(delightedOptions) +
          ')';
        document.body.appendChild(script);
        setHasBeenAdded(true);
        setBeingAdded(false);

        clearInterval(waitForUser);
      }
    }, 500);
  };

  const removeNPS = () => {
    const npsScript = document.querySelector('.js-nps');

    if (npsScript) {
      npsScript.remove();
    }
  };

  return {
    addNPS,
    removeNPS,
  };
};
