import { useEffect } from 'react';
import { useGetReportData } from '@/client/services/hooks/admin/useGetReportData';
import { useTranslation } from 'react-i18next';
import { StackedBarChart } from '../StackedBarChart';

export default function PurchaseChart() {
  const {
    dateRange,
    setDateRange,
    getDataBasedOnDateRange,
    getEcommercePurchasesReportData,
  } = useGetReportData(1);
    const { data, refetch } = getEcommercePurchasesReportData;

  useEffect(() => {
    getDataBasedOnDateRange(refetch);
  }, [dateRange]);

  const { t } = useTranslation();

  const labels = {
    permanent: t('overviewProducts.licensing_permanent'),
    subscription: t('overviewProducts.licensing_subscription'),
  };

  return (
    <StackedBarChart
      data={data || []}
      labels={labels}
      colSpan={1}
      rowSpan={1}
      dateRange={dateRange}
      setDateRange={setDateRange}
      title={t('dashboard.purchases')}
      tooltip={t('dashboard.purchases.tooltip')}
      leftLegend={t('dashboard.purchases')}
    />
  );
}
