import { HStack, Text } from '@chakra-ui/react';

import { Clock } from '@/client/components/icons/ContinuIcons';
import { useTranslation } from 'react-i18next';

type ContentLengthProps = {
  duration: number;
  color?: string;
  size?: string | null;
  iconBoxSize?: number;
  fontWeight?: number;
  renderIcon?: boolean;
  textVariant?: string | undefined;
};

export default function ContentLength({
  duration,
  color = 'black',
  size = null,
  iconBoxSize = 4,
  fontWeight = 400,
  renderIcon = true,
  textVariant = undefined,
}: ContentLengthProps) {
  const { t } = useTranslation();

  const getDisplayDuration = () => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    if (hours === 0 && minutes <= 1) return t('edit.article.lessThanAMinute');

    let timeFmt = ``;

    if (hours > 0) {
      timeFmt += `${hours} hour${hours > 1 ? 's' : ''}`;
    }

    if (minutes > 1) {
      timeFmt += ` ${minutes} min${minutes > 1 ? 's' : ''}`;
    }

    return timeFmt;
  };

  return (
    <HStack spacing={2}>
      {renderIcon && <Clock color={color} boxSize={iconBoxSize} />}

      <Text variant={textVariant} fontSize={size || 'xs'} color={color} fontWeight={fontWeight}>
        {getDisplayDuration()}
      </Text>
    </HStack>
  );
}
