import { CreateApiIntegrationType, GetApiIntegrationsType } from '@/client/types/Integrations';

import { learnApiClient } from '../../clients/learnApiClient';
import { v3ApiClient } from '../../clients/v3ApiClient';

const getApiIntegrationsCount = async (fields: string): Promise<{ docCount: number }> => {
  const response = await v3ApiClient.get(`api-applications/count?fields=${fields}`);

  return response.data;
};

const getApiIntegrations = async ({
  fetchDataOptions,
  isSearching,
  searchTerm,
}: GetApiIntegrationsType): Promise<{
  rows: {
    _id: string;
    application_name: string;
    client_id: string;
    application_info: [
      {
        name: string;
        value: string;
        _id: string;
      },
    ];
  };
  pageCount: number;
}> => {
  const fields =
    isSearching && searchTerm
      ? `client_id,application_name,application_info&application_name=$regex,2,${searchTerm},i&page=${
          fetchDataOptions.pageIndex + 1
        }&per_page=${fetchDataOptions.pageSize}`
      : `client_id,application_name,application_info&page=${
          fetchDataOptions.pageIndex + 1
        }&per_page=${fetchDataOptions.pageSize}`;
  const integrationsResponse = await v3ApiClient.get(`api-applications?fields=${fields}`);
  const apiIntegrationsCount = await getApiIntegrationsCount(fields);

  return {
    rows: integrationsResponse.data,
    pageCount: Math.ceil(apiIntegrationsCount.docCount / fetchDataOptions.pageSize) || -1,
  };
};

const createApiIntegration = async (details: CreateApiIntegrationType) => {
  const resp = v3ApiClient.post(`api-applications`, details);

  return resp;
};

const removeApiIntegration = (id: string) => {
  const resp = v3ApiClient.delete(`api-applications/${id}`);

  return resp;
};
type ExposeApiIntegrationType = { id: string };
const exposeApiIntegration = async ({ id }: ExposeApiIntegrationType) => {
  const resp = await v3ApiClient.get(`api-applications/${id}/expose`);
  return resp.data;
};

const getWebhooks = async (getArchived: boolean): Promise<{ rows: any; pageCount: number }> => {
  const resp = await learnApiClient.get(
    `webhooks/registrations${getArchived ? '?archived=true' : ''}`,
  );

  return {
    rows: resp.data,
    pageCount: 1 || -1,
  };
};

const createWebhook = (details: {
  name: string;
  url: string;
  customizations: {
    headers: [];
    body: [];
    filters: [];
  };
  events: string[];
}) => {
  const resp = learnApiClient.post(`webhooks/registrations`, details);

  return resp;
};

const enableWebhook = (id: string) => {
  const resp = learnApiClient.post(`webhooks/registrations/${id}/enable`);

  return resp;
};

const disableWebhook = (id: string) => {
  const resp = learnApiClient.post(`webhooks/registrations/${id}/disable`);

  return resp;
};

const invalidateWebhook = (id: string) => {
  const resp = learnApiClient.post(`webhooks/registrations/${id}/invalidate`);

  return resp;
};

const validateWebhook = (id: string) => {
  const resp = learnApiClient.post(`webhooks/registrations/${id}/validate`);

  return resp;
};

const removeWebhook = (data: any) => {
  const resp = learnApiClient.delete(`webhooks/registrations/${data.id}`);

  return resp;
};

type GetWebhookDetailsTypes = { id: any };
const getWebhookDetails = async ({ id }: GetWebhookDetailsTypes) => {
  const resp = await learnApiClient.get(`webhooks/registrations/${id}`);

  return resp;
};

const updateWebhook = (details: any) => {
  const resp = learnApiClient.post(`webhooks/registrations/${details._id}`, details);

  return resp;
};

const archiveWebhook = (id: string) => {
  const resp = learnApiClient.post(`webhooks/registrations/${id}/archive`);

  return resp;
};

const unArchiveWebhook = (id: string) => {
  const resp = learnApiClient.post(`webhooks/registrations/${id}/unarchive`);

  return resp;
};

const getWebhookActivity = (
  fetchDataOptions: { pageIndex: number; pageSize: number },
  id: string,
) => {
  const resp = learnApiClient.get(
    `webhooks/registrations/${id}/activity?page=${fetchDataOptions.pageIndex + 1}&perPage=${
      fetchDataOptions.pageSize
    }`,
  );

  return resp;
};

const checkSlackEnabled = async () => {
  const response = await v3ApiClient.get('companyintegrations/status');

  return response.data;
};

const getExistingContentIntegrations = async (): Promise<{ integrations: string[] }> => {
  const response = await v3ApiClient.get('content-integrations/existing');

  return response.data;
};

const IntegrationService = {
  getApiIntegrations,
  createApiIntegration,
  removeApiIntegration,
  exposeApiIntegration,
  getWebhooks,
  createWebhook,
  getWebhookDetails,
  removeWebhook,
  enableWebhook,
  disableWebhook,
  invalidateWebhook,
  validateWebhook,
  updateWebhook,
  archiveWebhook,
  unArchiveWebhook,
  getWebhookActivity,
  checkSlackEnabled,
  getExistingContentIntegrations,
};

export default IntegrationService;
