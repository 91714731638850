import { useInfiniteQuery, useMutation } from '@tanstack/react-query';

import { Category } from '@/client/types/Category';
import ConnectService from '@/client/services/api/admin/connect/ConnectService';
import { PartnerPermissions } from '@/client/types/Partner';
import { useConnectStore } from '@/client/services/state/admin/connectStore';
import { useEffect } from 'react';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

export const useConnect = () => {
  const {
    activeMainCategory,
    activeSubCategory,
    setActiveMainCategory,
    setActiveSubCategory,
    setActiveSubSubCategory,
    setSelectedEditItem,
    setSearchItems,
    items,
    setItems,
    subItems,
    setSubItems,
    subSubItems,
    setSubSubItems,
    searchTerm,
    isSearching,
    type,
    sortOrder,
    selectedLanguage,
  } = useConnectStore();
  const { t } = useTranslation();
  const { setToast } = useToastStore();

  const setLocalizedName = (item: Category) => {
    if (selectedLanguage.abr === 'en') {
      return item.name;
    }

    if (item.localizedNamesObj && item.localizedNamesObj[selectedLanguage.abr]) {
      return item.localizedNamesObj[selectedLanguage.abr];
    }

    return item.name;
  };

  useEffect(() => {
    setItems(
      items.map((item) => ({
        ...item,
        renderedName: setLocalizedName(item),
      })),
    );

    setSubItems(
      subItems.map((item) => ({
        ...item,
        renderedName: setLocalizedName(item),
      })),
    );

    setSubSubItems(
      subSubItems.map((item) => ({
        ...item,
        renderedName: setLocalizedName(item),
      })),
    );
  }, [selectedLanguage]);

  const checkIfUsingThreeColumnLayout = (): boolean =>
    type === 'location' || type === 'department' || type === 'category';

  // Used by SingleColumnLayout
  const searchQuery = useInfiniteQuery({
    enabled: !!searchTerm && !checkIfUsingThreeColumnLayout(),
    queryKey: ['search', searchTerm, type, sortOrder],
    queryFn: ({ pageParam = 1 }) =>
      ConnectService.searchItems(searchTerm!, pageParam, type, sortOrder),
    getNextPageParam: (lastPage, pages) => (lastPage.next_available ? pages.length + 1 : undefined),
    onSuccess: (data) => {
      setSearchItems(data.pages.map((page) => page.data).flat());
    },
  });

  // Used by ThreeColumnLayout
  const fullTextSearchQuery = useInfiniteQuery({
    enabled: !!searchTerm && checkIfUsingThreeColumnLayout(),
    queryKey: ['full-text-search', searchTerm, type, sortOrder],
    queryFn: ({ pageParam = 1 }) =>
      ConnectService.fullTextSearch(pageParam, 500, searchTerm!, type),
    onSuccess: (data) => {
      const dataArr = data.pages
        .map((page) => page)
        .flat()
        .map((item) => ({
          ...item,
          // @ts-ignore
          renderedName: setLocalizedName(item),
        }));

      // @ts-ignore
      setItems(dataArr);
      // @ts-ignore
      setActiveMainCategory(dataArr[0]);

      setSubItems(
        // @ts-ignore
        dataArr[0].subItems.map((subItem) => ({
          ...subItem,
          renderedName: setLocalizedName(subItem),
        })) || [],
      );
      // @ts-ignore
      setActiveSubCategory(dataArr[0].subItems[0] || null);

      setSubSubItems(
        // @ts-ignore
        dataArr[0].subItems[0]?.subItems.map((subItem: Category) => ({
          ...subItem,
          renderedName: setLocalizedName(subItem),
        })) || [],
      );
      // @ts-ignore
      setActiveSubSubCategory(dataArr[0].subItems[0]?.subItems[0] || null);
    },
  });

  /**
   * There isn't currently a way to send partial sort order index data to the API
   * Since we use infinite scroll, when you send an item to the bottom of the list, if all items haven't been fetched yet, the sort order index will be incorrect since when the item moves to the bottom of the list, we begin fetching next pages
   * Since categories are the only type that allow drag and drop, try to fetch them all
   * Since we're using a virtualized list, this could be quite a large initial request, but shouldn't impact performance once loaded
   */

  const perPage = type === 'category' ? 10000 : 500;

  const levelOneQuery = useInfiniteQuery({
    enabled: !isSearching,
    queryKey: ['level-one', type, sortOrder, perPage],
    queryFn: ({ pageParam = 1 }) => ConnectService.getItems(perPage, pageParam, type, sortOrder),
    getNextPageParam: (lastPage, pages) => (lastPage.next_available ? pages.length + 1 : undefined),
    // TODO: Remove use of onSuccess callback
    onSuccess: (data) => {
      setItems(
        data.pages
          .map((page) => page.data)
          .flat()
          .map((item) => ({
            ...item,
            renderedName:
              selectedLanguage.abr === 'en'
                ? item.name
                : item.localizedNamesObj[selectedLanguage.abr] || item.name,
          })),
      );
    },
  });

  const levelTwoQuery = useInfiniteQuery({
    enabled: !!activeMainCategory && !isSearching,
    queryKey: ['level-two', activeMainCategory?._id, sortOrder, type, perPage],
    queryFn: ({ pageParam = 1 }) =>
      ConnectService.getSubItems(activeMainCategory?._id, perPage, pageParam, type, sortOrder),
    getNextPageParam: (lastPage, pages) => (lastPage.next_available ? pages.length + 1 : undefined),
    // TODO: Remove use of onSuccess callback
    onSuccess: (data) => {
      setSubItems(
        data.pages
          .map((page) => page.data)
          .flat()
          .map((item) => ({
            ...item,
            renderedName:
              selectedLanguage.abr === 'en'
                ? item.name
                : item.localizedNamesObj[selectedLanguage.abr] || item.name,
          })),
      );
    },
  });

  const levelThreeQuery = useInfiniteQuery({
    enabled: !!activeSubCategory && !isSearching,
    queryKey: ['level-three', activeSubCategory?._id, type, perPage],
    queryFn: ({ pageParam = 1 }) =>
      ConnectService.getSubSubItems(activeSubCategory?._id!, perPage, pageParam, type),
    getNextPageParam: (lastPage, pages) => (lastPage.next_available ? pages.length + 1 : undefined),
    // TODO: Remove use of onSuccess callback
    onSuccess: (data) => {
      setSubSubItems(
        data.pages
          .map((page) => page.data)
          .flat()
          .map((item) => ({
            ...item,
            renderedName:
              selectedLanguage.abr === 'en'
                ? item.name
                : item.localizedNamesObj[selectedLanguage.abr] || item.name,
          })),
      );
    },
  });

  const { refetch: levelOneRefetch } = levelOneQuery;
  const { refetch: levelTwoRefetch } = levelTwoQuery;
  const { refetch: levelThreeRefetch } = levelThreeQuery;
  const { refetch: searchRefetch } = searchQuery;
  const { refetch: fullTextSearchRefetch } = fullTextSearchQuery;

  interface AddItemVariables {
    level: number;
    name: string;
    parentId: string | null | undefined;
    itemType: 'category' | 'location' | 'department' | 'team' | 'org_level' | 'grade' | 'skill';
    partnerPermissions?: PartnerPermissions;
  }

  const addItem = useMutation({
    mutationFn: ({ level, name, parentId, itemType, partnerPermissions }: AddItemVariables) =>
      ConnectService.addItem(level, name, parentId, itemType, partnerPermissions),
    onSuccess: (data, variables) => {
      if (variables.level === 1) {
        levelOneRefetch();
      } else if (variables.level === 2) {
        levelTwoRefetch();
      } else if (variables.level === 3) {
        levelThreeRefetch();
      }

      setToast({
        show: true,
        status: 'success',
        title: t('admin.connect.createSuccess', { type: variables.itemType }),
      });
    },
    onError: (data, variables) => {
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.createError', { type: variables.itemType }),
      });
    },
  });

  interface EditItemVariables {
    itemId: string;
    name: string;
    localizedNames?: string;
    showExternal?: boolean;
    partnerPermissions?: PartnerPermissions;
    level: number;
  }

  const editItem = useMutation({
    mutationFn: (variables: EditItemVariables) =>
      ConnectService.editItem(
        variables.itemId,
        variables.name,
        variables.localizedNames,
        variables.showExternal,
        variables.partnerPermissions,
      ),
    onSuccess: (data, variables) => {
      setSelectedEditItem(null);

      if (isSearching) {
        fullTextSearchRefetch();
      } else if (variables.level === 1) {
        levelOneRefetch();
      } else if (variables.level === 2) {
        levelTwoRefetch();
      } else if (variables.level === 3) {
        levelThreeRefetch();
      }

      setToast({
        show: true,
        status: 'success',
        title: t('admin.connect.categoryUpdateSuccess'),
      });
    },
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.categoryUpdateError'),
      });
    },
  });

  const handleRefetch = (level: number | undefined) => {
    if (isSearching) {
      searchRefetch();
      fullTextSearchRefetch();
    }

    if (type === 'category' || type === 'location' || type === 'department') {
      if (level === 3) {
        levelThreeRefetch();
      } else if (level === 2) {
        levelTwoRefetch();
        levelThreeRefetch();
      } else if (level === 1) {
        levelOneRefetch();
        levelTwoRefetch();
        levelThreeRefetch();
      }

      return;
    }

    if (type === 'team' || type === 'org_level' || type === 'grade') {
      levelOneRefetch();
    }
  };

  const deleteItem = useMutation({
    mutationFn: (category: Category | null) => ConnectService.deleteItem(category?._id),
    onSuccess: (data, category) => {
      handleRefetch(category?.level);

      setToast({
        show: true,
        status: 'success',
        title: t('admin.connect.itemDeleteSuccessful'),
      });
    },
    onError: (error) => {
      console.log(error);
      setToast({
        show: true,
        status: 'error',
        title: t('admin.connect.itemDeleteError'),
      });
    },
  });

  interface UpdateSortOrderVariables {
    ids: string[];
    parentId: string | null | undefined;
    level: number;
  }

  const updateSortOrder = useMutation({
    mutationFn: ({ ids, parentId, level }: UpdateSortOrderVariables) =>
      ConnectService.updateSortOrderIndex(ids, parentId, level),
  });

  const handleSelectSearchItem = (item: Category) => {
    if (item.level === 1 || item.level === 10) {
      setActiveMainCategory(item);

      if (item.subItems) {
        if (item.subItems.length === 0) {
          setSubItems([]);
          setActiveSubCategory(null);
          setSubSubItems([]);
          setActiveSubSubCategory(null);
          return;
        }

        const subItemOne = item.subItems[0];

        setSubItems(
          item.subItems.map((subItem) => ({
            ...subItem,
            renderedName: setLocalizedName(subItem),
          })) || [],
        );
        setActiveSubCategory(subItemOne || null);

        setSubSubItems(
          subItemOne.subItems.map((subSubItem: any) => ({
            ...subSubItem,
            renderedName: setLocalizedName(subSubItem),
          })) || null,
        );

        setActiveSubSubCategory(item.subItems[0].subItems[0] || null);
      }
    } else if (item.level === 2) {
      setActiveSubCategory(item);

      setSubSubItems(
        item.subItems.map((subSubItem) => ({
          ...subSubItem,
          renderedName: setLocalizedName(subSubItem),
        })) || [],
      );

      setActiveSubSubCategory(item.subItems[0] || null);
    } else if (item.level === 3) {
      setActiveSubSubCategory(item);
    }
  };

  const handleSelectNonSearchItem = (item: Category) => {
    if (item.level === 1 || item.level === 10) {
      setActiveMainCategory(item);

      setSubItems(item.subItems || []);
      setActiveSubCategory(null);
      setSubSubItems(item.subSubItems || []);
      setSubSubItems([]);

      setActiveSubSubCategory(null);
    } else if (item.level === 2) {
      setActiveSubCategory(item);
      setActiveSubSubCategory(null);
    } else if (item.level === 3) {
      setActiveSubSubCategory(item);
    }
  };

  const handleSelectItem = (item: Category) => {
    isSearching ? handleSelectSearchItem(item) : handleSelectNonSearchItem(item);
  };

  return {
    levelOneQuery,
    levelTwoQuery,
    levelThreeQuery,
    searchQuery,
    fullTextSearchQuery,
    addItem,
    editItem,
    searchRefetch,
    deleteItem,
    fullTextSearchRefetch,
    updateSortOrder,
    handleSelectItem,
  };
};
