/* eslint react/no-unstable-nested-components: 0 */

import { Product, Purchase } from '@/client/services/api/graphql/gql/graphql';
import { ellipsisCellStyles, ellipsisHeaderStyles } from '@/client/utils/charts/tableStyleUtils';
import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';


import { useCallback, useRef } from 'react';

interface EcommerceListTableProps {
  title: string;
  columns: any;
  data: any;
}

// We should rename this to something more generic if we begin to reuse this for most admin lists
export default function EcommerceListTable({ title, columns, data }: Readonly<EcommerceListTableProps>) {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const getRowId = useCallback((row: Product | Purchase
  ) => row.id, []);
  
  const table = useReactTable({
    data: data.rows,
    columns,
    getRowId,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    state: {},
    initialState: {},
    manualPagination: false,
  });

  const isPaymentsTable = title === t('ecommerce.user.paymentHistory.title');

  return (
    <Box padding={0} backgroundColor="white" width="100%" borderRadius="10px">
      <Box
        height="50px"
        alignContent="center"
        alignItems="center"
        textAlign={isPaymentsTable ? 'left' : 'center'}
        marginX={isPaymentsTable ? 0 : 4}
        marginY={isPaymentsTable ? 4 : 1}
        paddingX={isPaymentsTable ? 0 : 6}
        paddingY={2}
        borderRadius="md"
        backgroundColor="white"
      >
        <Text
          fontSize={isPaymentsTable ? '24px' : '18px'}
          fontWeight={isPaymentsTable ? 'bold' : 'medium'}
          color="black"
        >
          {title}
        </Text>
      </Box>
      <TableContainer
        ref={tableContainerRef}
        borderRadius="xl"
        maxHeight="400"
        overflowY="auto"
        width="100%"
        borderWidth="1px"
      >
        <Table variant="admin" overflowY="auto" style={{ tableLayout: 'fixed' }}>
          <Thead position="sticky" top={0} zIndex={10}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      ...ellipsisHeaderStyles(header, true),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>{flexRender(header.column.columnDef.header, header.getContext())}</>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <Tbody>
            {table.getCoreRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    style={{
                      ...ellipsisCellStyles(cell, true),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
