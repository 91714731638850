import { SearchAssignment } from '@/client/types/Assignment';
import { learnApiClient } from './clients/learnApiClient';

const completeContent = async (contentId: string | undefined, contentType: string) => {
  const response = await learnApiClient.post(`assignments/complete-item`, {
    content: contentId,
    type: contentType,
  });

  return response.data;
};

const queryCreate = async(body: any) => {
  const response = await learnApiClient.post(
    `assignments/query`,
    body
  );
  
  return response.data;
}

const search = async (assigneeId: string, contentId: string | undefined): Promise<SearchAssignment[]> => {
  const response = await learnApiClient.get(
    `assignments/search?assignee=${assigneeId}&content=${contentId}&fields=due_date`
  );

  return response.data;
};

const AssignmentsService = {
  completeContent,
  queryCreate,
  search,
};

export default AssignmentsService;
