import CalendarDay from './CalendarDay';
import { Grid } from '@chakra-ui/react';

interface WeekProps {
  weekData: any[];
  modalOpener: (data: any) => void;
}
export default function CalendarWeek({ weekData, modalOpener }: WeekProps) {
  return (
    <Grid templateColumns="repeat(7, 1fr)">
      {weekData.map((week) =>
        week.map((day: any, index: number) => (
          <CalendarDay dayData={day} modalOpener={modalOpener} key={`calendar_day_${index + 1}`} />
        )),
      )}
    </Grid>
  );
}
