import { useQuery } from '@tanstack/react-query';
import ProductsService from '@/client/services/api/graphql/ProductsService';

export const useGetProduct = (id: string | undefined) =>
  useQuery({
    queryKey: ['products', id],
    queryFn: () => ProductsService.getProduct(id),
  });

export const useGetBaseProduct = () =>
  useQuery({
    queryKey: ['base-product'],
    queryFn: () => ProductsService.getBaseProduct(),
  });
