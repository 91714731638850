/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonProps, useToken } from '@chakra-ui/react';

import { hexToRGBA } from '@/client/utils/hexToRGBA';

export default function OutlineButton(props: ButtonProps) {
  const { children } = props;
  const [brandButton] = useToken('colors', ['brand.button']);

  return (
    <Button
      {...props}
      border="2px solid"
      borderColor="brand.button"
      backgroundColor="transparent"
      color="brand.button"
      borderRadius="md"
      paddingY={4}
      paddingX={8}
      _disabled={{ _hover: { color: 'brand.button' } }}
      _hover={{ backgroundColor: hexToRGBA(brandButton, 0.1) }}
    >
      {children}
    </Button>
  );
}
