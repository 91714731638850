import CategoriesService from '../../CategoriesService';
import { Category } from '@/client/types/Category';
import { Profile } from '@/client/types/admin/profiles/Profile';
import { ProfileForm } from '@/client/types/admin/profiles/ProfileForm';
import { learnApiClient } from '../../clients/learnApiClient';

const getProfileUserCount = async (profileIds: string[]) => {
  if (profileIds.length === 0) return;

  const response = await learnApiClient.get(
    `access-profiles/user-count?profiles=${profileIds.join(',')}`,
  );

  return response.data;
};

export const getProfiles = async (
  options: {
    pageIndex: number;
    pageSize: number;
  },
  searchTerm: string,
): Promise<{ rows: Profile[]; pageCount: number }> => {
  let queryString = `created_by-populate=first_name,last_name,full_name,email&fields=name,description,created_by,createdAt&page=${
    options.pageIndex + 1
  }&per_page=${options.pageSize}`;

  if (searchTerm !== '') {
    queryString += `&$or=10,name,$regex,2,${searchTerm},i,tags,$regex,2,${searchTerm},i`;
  }

  const response = await learnApiClient.get(`access-profiles?${queryString}`);

  const profileUserCounts = await getProfileUserCount(
    response.data.map((profile: Profile) => profile._id),
  );

  const count = await learnApiClient.get<{ count: number }>(`access-profiles/count?${queryString}`);

  const profiles = response.data.map((profile: Profile) => ({
    ...profile,
    userCount: profileUserCounts[profile._id],
  }));

  return {
    rows: profiles.filter((profile: Profile) => profile.created_by),
    pageCount: Math.ceil(count.data.count / options.pageSize) || -1,
  };
};

const deleteProfile = async (profileId: string | null) => {
  const response = await learnApiClient.delete(`access-profiles/${profileId}`);

  return response.data;
};

const getProfileUsers = async (
  options: {
    pageIndex: number;
    pageSize: number;
  },
  profileId: string | undefined,
  userCount: number,
) => {
  if (!profileId) return;

  const response = await learnApiClient.get(
    `access-profiles/${profileId}/users?fields=_id,first_name,last_name,email,full_name,role,image,department,office_location,last_login,linked_locations,linked_departments&linked_departments-populate=name&linked_locations-populate=name&page=${
      options.pageIndex + 1
    }&per_page=${options.pageSize}&sort=full_name,-1`,
  );

  return {
    rows: response.data,
    pageCount: Math.ceil(userCount / options.pageSize) || -1,
  };
};

const assignProfileUsers = async (profileId: string | undefined, userIds: string[]) => {
  const response = await learnApiClient.post(`access-profiles/query-assign`, {
    profile: profileId,
    users: {
      queries: [],
      nin: [],
      in: userIds,
    },
  });

  return response.data;
};

const createProfile = async (profile: ProfileForm): Promise<ProfileForm> => {
  const response = await learnApiClient.post(`access-profiles`, profile);

  return response.data;
};

const updateProfile = async (profile: ProfileForm) => {
  const response = await learnApiClient.post(`access-profiles/${profile._id}`, profile);

  return response.data;
};

const getProfile = async (profileId: string | undefined) => {
  const response = await learnApiClient.get<ProfileForm>(`access-profiles/${profileId}`, {
    params: {
      fields: 'company,name,description,permissions',
    },
  });

  const profileSettings = response.data;

  const contentSettings = profileSettings.permissions.admin.content;
  const userSettings = profileSettings.permissions.admin.users;

  let contentSegmentationIds: string[] = [];
  let userSegmentationIds: string[] = [];

  const allowedContentCategoryIds = contentSettings.allowed_categories;

  Object.entries(contentSettings.segmentation_group).forEach(([, value]) => {
    contentSegmentationIds = [...contentSegmentationIds, ...value];
  });

  Object.entries(userSettings.user_group).forEach(([, value]) => {
    userSegmentationIds = [...userSegmentationIds, ...value];
  });

  const allowedFullContentCategories = await CategoriesService.getCategoriesById(
    allowedContentCategoryIds,
  );

  const contentSegmentationCategories = await CategoriesService.getCategoriesById(
    contentSegmentationIds,
  );

  const userSegmentationCategories = await CategoriesService.getCategoriesById(userSegmentationIds);

  const filterByType = (categories: Category[]) => ({
    locations: categories.filter((category) => category.location),
    departments: categories.filter((category) => category.department),
    teams: categories.filter((category) => category.team),
    org_levels: categories.filter((category) => category.org_level),
    grades: categories.filter((category) => category.grade),
  });

  const returnData = {
    ...profileSettings,
    permissions: {
      ...profileSettings.permissions,
      admin: {
        ...profileSettings.permissions.admin,
        connect: {
          ...profileSettings.permissions.admin.connect,
        },
        content: {
          ...profileSettings.permissions.admin.content,
          allowed_categories: allowedFullContentCategories,
          segmentation_group: filterByType(contentSegmentationCategories),
        },
        users: {
          ...profileSettings.permissions.admin.users,
          user_group: filterByType(userSegmentationCategories),
        },
      },
    },
  };

  return returnData;
};

const ProfilesService = {
  getProfiles,
  deleteProfile,
  getProfileUsers,
  assignProfileUsers,
  createProfile,
  updateProfile,
  getProfile,
};

export default ProfilesService;
