import AdminCategoryService from '@/client/services/api/AdminCategoryService';
import SearchableDropdownInput from '@/client/components/input/SearchableDropdownInput';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

// TODO: We need to refactor these components to actually search instead of front loading data

export default function AdminUsersTeamsSearch({fieldName = "linked_teams"}: {fieldName?: string}) {
  const { t } = useTranslation();

  const { data: teams } = useQuery({
    queryKey: [`categories-teams`, 1, 'team'],
    queryFn: () => AdminCategoryService.getCategoriesByManagerSearch('team', 1, 1, 10000, ''),
    select: (data) => {
      const formattedTeams = data.data.map((team) => ({ value: team._id, label: team.name }));

      return formattedTeams;
    },
  });

  return (
    <SearchableDropdownInput
      name={fieldName}
      isMultiple
      label="Teams"
      placeholder="Search Teams"
      options={teams || []}
      tooltipText={t('registrationForms.help.segment', { type: 'team' })}
    />
  );
}
