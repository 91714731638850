import { useEffect } from 'react';

function useDocumentTitle(title: string, hideprefix?: boolean) {
  const preTitle = 'Continu :: ';
  useEffect(() => {
    document.title = (!hideprefix ? preTitle : '') + title;
  }, [title]);
}

export default useDocumentTitle;
