import { Box, Circle, Icon, TagCloseButton, Tag, TagLabel, HStack, As } from '@chakra-ui/react';

import BaseFormCreateTagList from '../../admin/input/BaseFormCreateTagList';

type InputWithTagsListProps = {
  fieldName: string;
  selectedItems: any[];
  defaultItems?: any[];
  ItemsIcon: As;
  selectItem: (selectedItem: any) => void;
  deleteItem: (selectedItem: any) => void;
  checkIfSelected?: (item: any, selectedItems: any[]) => boolean;
  searchTerm: string | undefined;
  inputPlaceholder?: string;
  setSearchTerm: (term: string | undefined) => void;
  searchedItems: any[];
}

export function InputWithTagList({
  fieldName,
  selectedItems,
  defaultItems = [],
  ItemsIcon,
  selectItem,
  deleteItem,
  checkIfSelected = undefined,
  searchTerm,
  inputPlaceholder = '',
  setSearchTerm,
  searchedItems,
}: InputWithTagsListProps) {

  return (
    <Box width="100%">
      <BaseFormCreateTagList
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        checkIfSelected={checkIfSelected}
        data={searchedItems}
        selectedItemChange={(selectedItem: any) => selectItem(selectedItem)}
        placeholder={inputPlaceholder}
        fieldName={fieldName}
        ItemsIcon={ItemsIcon}
      />
        <HStack flexWrap="wrap">
          {!!defaultItems.length && defaultItems.map((tag: any) => (
            <Tag key={tag.id || tag.name} variant="landingPage">
              <Circle backgroundColor="neutral.200" size="30px" marginRight={2}>
                <Icon
                  color="baseBlack"
                  as={ItemsIcon}
                  />
              </Circle>
              <TagLabel>{tag.title}</TagLabel>
            </Tag>
          ))}
          {!!selectedItems.length && selectedItems.map((tag: any) => (
            <Tag key={tag.id || tag.name} variant="landingPage">
              <Circle backgroundColor="neutral.200" size="30px" marginRight={2}>
                <Icon
                  color="baseBlack"
                  as={ItemsIcon}
                />
              </Circle>
              <TagLabel>{tag.title}</TagLabel>

              <TagCloseButton
                onClick={() => deleteItem(tag)}
              />
            </Tag>
          ))}
        </HStack>
    </Box>
  );
}
