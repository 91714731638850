/* eslint react/no-unstable-nested-components: 0 */

import { ArrowLeft, ArrowRight, Assign, Users, View } from '@/client/components/icons/ContinuIcons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
  useToken,
} from '@chakra-ui/react';
import { ColumnDefBase, PaginationState, createColumnHelper } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';

import ManuallyPaginatedTable from '../../tables/ManuallyPaginatedTable';
import { Person } from '@/client/types/Person';
import SingleCategoryModal from '@/client/components/admin/overlay/SingleCategoryModal';
import { UseMutationResult } from '@tanstack/react-query';
import UserCell from '../../tables/cells/UserCell';
import UserSearch from '@/client/components/input/search/UserSearch';
import { UserSearchHook } from '@/client/services/hooks/shared/search/useUserSearch';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useSegmentationService } from '@/client/services/hooks/admin/useSegmentationService';
import { useTranslation } from 'react-i18next';

interface AssignUsersModalProps {
  isOpen: boolean;
  userSearchHook: UserSearchHook;
  assignUsers: UseMutationResult<any, unknown, any, unknown>;
  onClose: () => void;
  userType?: 'users' | 'managers';
  groupId?: string | undefined;
  isManagers?: boolean;
}

export default function AssignUsersModal({
  isOpen,
  userSearchHook,
  assignUsers,
  userType,
  onClose,
  groupId,
  isManagers,
}: AssignUsersModalProps) {
  const { t } = useTranslation();
  const [brandButton] = useToken('colors', ['brand.button']);
  const [isAudience, setIsAudience] = useState<boolean>(true);
  const [selectedUserTeams, setSelectedUserTeams] = useState([]);
  const {
    handleCreateItem,
    filteredCategories,
    selectedItems,
    setSelectedItems,
    selectedUsers,
    setSelectedUsers,
    handleSelectedItemsChange,
    setSearchValue,
    searchValue,
    searchWithParamsPaginated,
  } = userSearchHook;
  const { getSegmentationList } = useSegmentationService();

  const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const selectedItemIds = Object.keys(rowSelection).filter((key) => rowSelection[key]);

    setSelectedUsers(selectedItemIds);
  }, [rowSelection]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const { data: searchData } = searchWithParamsPaginated(fetchDataOptions);

  const { data: previewData } = getSegmentationList(
    selectedUsers.length > 0 && !isAudience,
    fetchDataOptions,
    selectedUsers,
  );

  const handleClose = () => {
    setIsAudience(true);
    userSearchHook.setSelectedUsers([]);
    setRowSelection({});
    onClose();
  };

  const columnHelper = createColumnHelper<Person>();
  const columns = useMemo<ColumnDefBase<Person, any>[]>(
    () => [
      columnHelper.display({
        id: 'checkbox',
        cell: (info) => (
          <Flex alignItems="center">
            <Checkbox
              marginLeft={9}
              variant="admin"
              onChange={info.row.getToggleSelectedHandler()}
              isChecked={info.row.getIsSelected()}
            />
          </Flex>
        ),
        header: (info) => (
          <Checkbox
            marginLeft={9}
            variant="admin"
            onChange={info.table.getToggleAllRowsSelectedHandler()}
            isChecked={info.table.getIsAllRowsSelected()}
          />
        ),
      }),
      columnHelper.accessor('full_name', {
        cell: (info) => (
          <Box marginLeft={!isAudience ? 9 : 0}>
            <UserCell
              name={info.getValue()}
              image={info.row.original.image}
              id={info.row.original._id}
            />
          </Box>
        ),
        header: () => (
          <Box as="span" marginLeft={!isAudience ? 9 : 0}>
            {t('global.forms.labels_name')}
          </Box>
        ),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('email', {
        cell: (info) => info.getValue(),
        header: () => <span>{t('global.forms.labels_email')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linked_locations', {
        cell: (info) => (
          <span>{info.getValue().length > 0 ? info.getValue()[0].name : '-----'}</span>
        ),
        header: () => <span>{t('global.forms.labels_location')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linked_departments', {
        cell: (info) => (
          <span>{info.getValue().length > 0 ? info.getValue()[0].name : '-----'}</span>
        ),
        header: () => <span>{t('global.forms.labels_department')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linked_teams', {
        cell: (info) =>
          info.getValue().length > 0 ? (
            <Text
              as="span"
              color="brand.link"
              _hover={{ cursor: 'pointer' }}
              onClick={() => setSelectedUserTeams(info.getValue())}
            >
              {info.getValue().length} teams
            </Text>
          ) : (
            <Text as="span">{info.getValue().length} teams</Text>
          ),
        header: () => <span>{t('global.label.team')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linked_org_level', {
        cell: (info) => (
          <span>{info.getValue() && info.getValue().name ? info.getValue().name : '-----'}</span>
        ),
        header: () => <span>{t('global.label.level')}</span>,
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('linked_grade', {
        cell: (info) => (
          <span>{info.getValue() && info.getValue().name ? info.getValue().name : '-----'}</span>
        ),
        header: () => <span>{t('global.label.grade')}</span>,
        footer: (info) => info.column.id,
      }),
    ],
    [isAudience],
  );

  return (
    <Modal size="full" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />

      <ModalContent borderRadius="lg" margin={50}>
        <ModalCloseButton />

        <ModalBody marginTop={4}>
          <Flex
            paddingBottom={2}
            marginBottom={4}
            borderBottom="1px solid"
            borderBottomColor="brand.grey.20"
          >
            <Flex alignItems="end" width="20%">
              <Button
                variant="adminPrimary"
                size="xs"
                leftIcon={<ArrowLeft />}
                isDisabled={isAudience}
                onClick={() => setIsAudience(true)}
              >
                {t('global.label.back')}
              </Button>
            </Flex>

            <Flex width="60%">
              <Flex margin="0 auto" height="60px">
                <Box
                  paddingTop={2}
                  width="96px"
                  borderRadius="lg"
                  backgroundColor={isAudience ? hexToRGBA(brandButton, 0.1) : 'transparent'}
                >
                  <VStack color="brand.button">
                    <Users boxSize={5} />

                    <Text fontWeight="medium">{t('global.label.audience')}</Text>
                  </VStack>
                </Box>

                <Box
                  paddingTop={2}
                  width="96px"
                  borderRadius="lg"
                  backgroundColor={!isAudience ? hexToRGBA(brandButton, 0.1) : 'transparent'}
                >
                  <VStack color="brand.button">
                    <View boxSize={5} />

                    <Text fontWeight="medium">{t('global.label.preview')}</Text>
                  </VStack>
                </Box>
              </Flex>
            </Flex>

            <Flex justifyContent="end" alignItems="end" width="20%">
              {isAudience && (
                <Button
                  variant="adminPrimary"
                  size="xs"
                  rightIcon={<ArrowRight />}
                  onClick={() => setIsAudience(false)}
                  isDisabled={userSearchHook.selectedUsers.length === 0}
                >
                  {t('global.form.labels_next')}
                </Button>
              )}

              {!isAudience && (
                <Button
                  variant="adminPrimary"
                  size="xs"
                  leftIcon={<Assign />}
                  isDisabled={userSearchHook.selectedUsers.length === 0}
                  onClick={() =>
                    groupId
                      ? assignUsers
                          .mutateAsync({
                            groupId,
                            userIds: userSearchHook.selectedUsers,
                            managers: isManagers,
                          })
                          .then(() => handleClose())
                      : assignUsers
                          .mutateAsync({ userIds: userSearchHook.selectedUsers })
                          .then(() => handleClose())
                  }
                  isLoading={assignUsers.isLoading}
                >
                  {t('edit.general.assign')}
                </Button>
              )}
            </Flex>
          </Flex>

          <>
            <Box display={isAudience ? 'unset' : 'none'}>
              <UserSearch
                variant="admin"
                onCreateItem={handleCreateItem}
                items={filteredCategories}
                selectedItems={selectedItems}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                handleClearAll={() => setSelectedItems([])}
                onSelectedItemsChange={(changes: any) => {
                  handleSelectedItemsChange(changes.selectedItems, changes.type);
                }}
              />
            </Box>

            <Box display={!isAudience ? 'unset' : 'none'}>
              <Flex width="full" justifyContent="end">
                <Text fontWeight="bold" fontSize="sm">
                  {userType === 'managers' ? 'Managers' : 'Users'} Selected:{' '}
                  {userSearchHook.selectedUsers.length}
                </Text>
              </Flex>
            </Box>

            <Box marginTop={4}>
              <ManuallyPaginatedTable
                columns={columns}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                queryData={isAudience ? searchData : previewData}
                pagination={pagination}
                setPagination={setPagination}
                showCheckbox={isAudience}
              />
            </Box>

            <SingleCategoryModal
              title="Teams"
              isOpen={selectedUserTeams.length > 0}
              onClose={() => setSelectedUserTeams([])}
              items={selectedUserTeams}
            />
          </>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

AssignUsersModal.defaultProps = {
  userType: null,
  groupId: undefined,
  isManagers: false,
  refetch: null,
};
