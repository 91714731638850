import axios, { AxiosInstance } from 'axios';
import { CLIENT_CREATION_KEY_API, registerClientCreation } from './clientRegister';

export let preAuthApiClient: AxiosInstance;

const create = (baseURL: string) => {
  preAuthApiClient = axios.create({
    baseURL: baseURL,
  });
}

registerClientCreation(CLIENT_CREATION_KEY_API, create);
