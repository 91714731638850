import { Box, Flex, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useComponentSize, useWindowSize } from 'react-use-size';

import { CreateTab } from './CreateTab';
import type { ReactElement } from 'react';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';

interface CreateTabsProps {
  tabOptions: { label: string; content: any; isDisabled: boolean; render?: boolean }[];
  controls: ReactElement;
  tabIndex: number;
  setTabIndex: (index: number) => void;
  isTrack?: boolean;
}

export default function CreateTabs({
  tabOptions,
  controls,
  tabIndex,
  setTabIndex,
  isTrack = false,
}: CreateTabsProps) {
  const { ref: containerRef, width } = useComponentSize();
  const { width: windowWidth } = useWindowSize();
  const { tinyMceInFullscreenMode } = useCreateStore();

  const handleTabChange = (index: number) => setTabIndex(index);

  return (
    <Box ref={containerRef} paddingBottom="100px">
      <Tabs isLazy index={tabIndex} onChange={handleTabChange} paddingX={4}>
        <TabList
          width="full"
          display="flex"
          justifyContent="space-evenly"
          marginTop={12}
          marginBottom={12}
        >
          {tabOptions.map((tab, index) => (
            <CreateTab
              key={tab.label}
              index={index}
              label={tab.label}
              isDisabled={tab.isDisabled}
              tabIndex={tabIndex}
            />
          ))}
        </TabList>

        <TabPanels>
          {tabOptions.map((tab) => (
            <TabPanel key={tab.label} paddingX={6}>
              {tab.content}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      {!tinyMceInFullscreenMode && (
        <Flex
          zIndex={888}
          position="fixed"
          background="white"
          borderTop="1px solid"
          borderTopColor="warmNeutral.200"
          left={isTrack ? '50px' : width ? windowWidth - width : '40%'}
          right={0}
          bottom={0}
          paddingY={4}
          paddingX={18}
          justifyContent="space-between"
        >
          {controls}
        </Flex>
      )}
    </Box>
  );
}
