import { learnApiClient } from '../../clients/learnApiClient';
import type { Scorm } from '@/client/types/content/scorm/Scorm';
import type { ScormSubmissionData } from '@/client/types/admin/content-authoring/scorm/ScormSubmissionData';

interface UploadPayload {
  file: File;
  title: string;
  user: string;
  self_hosted: boolean;
}

const uploadScormPackage = async (
  payload: UploadPayload,
  progressCallback: (precentCompleted: number) => void,
  url: string,
): Promise<Scorm> => {
  const formData = new FormData();

  formData.append('file', payload.file);
  formData.append('title', payload.title);
  formData.append('user', payload.user);
  formData.append('self_hosted', payload.self_hosted.toString());

  const response = await learnApiClient.post(url, formData, {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      progressCallback(percentCompleted);
    },
  });

  return response.data;
};

const save = async (id: string, data: ScormSubmissionData): Promise<Scorm> => {
  const response = await learnApiClient.post(`scorm/content/${id}`, data, {
    params: {
      fields:
        'company,user,title,description,duration,image,banner_image,images,properties,tags,linked_category,approval_required,approved,last_approved_by,last_approved_by,privacy_collaborators,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,createdAt,updatedAt,cover_justification,featured_justification,explore_hide,self_hosted,certificate,certificate_data,allow_comments,rating_configuration,ssla_config',
    },
  });

  return response.data;
};

const AuthoringScormService = {
  uploadScormPackage,
  save,
};

export default AuthoringScormService;
