import { ArrowLeft, Save, View } from '@/client/components/icons/ContinuIcons';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import { InfoOutlineIcon } from '@chakra-ui/icons';
import { WebhooksIntegrationsEditTypes } from '@/client/types/Integrations';
import { useIntegrationService } from '@/client/services/hooks/admin/connect/useIntegrationService';
import { useTranslation } from 'react-i18next';
import { useValidationStore } from '@/client/services/state/webhooks/validationStore';

export default function WebhooksIntegrationsEdit({
  setEditing,
  existingId,
  setEditingId,
}: WebhooksIntegrationsEditTypes) {
  const { createWebhook, getWebhookDetails, updateWebhook } = useIntegrationService();
  const [isExisting, setIsExisting] = useState<boolean>(false);
  const [existingData, setExistingData] = useState<any>();
  const [showingSecret, setShowingSecret] = useState<boolean>(false);
  const { validateWebhook } = useIntegrationService();
  const { t } = useTranslation();
  const { validating, setValidating, returnedValidation, setReturnedValidation } =
    useValidationStore();

  const contentCheckboxes = [
    {
      text: t('webhooks.assignmentCompletion'),
      key: 'assignment.user.completed',
    },
    {
      text: t('webhooks.contentCompletion'),
      key: 'content.user.completed',
    },
    {
      text: t('webhooks.badgesAwarded'),
      key: 'rewards.badge.awarded',
    },
  ];
  const workshopsCheckboxes = [
    {
      text: t('webhooks.attendance'),
      key: 'user.workshop.attended',
    },
  ];

  const resp = getWebhookDetails(existingId);

  useEffect(() => {
    if (existingId.length) {
      setIsExisting(true);
      setExistingData(resp.data?.data);
    }
  }, [existingId, resp]);

  type FormInputsTypes = any;
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
  } = useForm<FormInputsTypes>({});

  const goBack = () => {
    setEditing('');
    setEditingId('');
    setExistingData(undefined);
    setIsExisting(false);
  };

  useEffect(() => {
    setValue('events', []);
    setValue('name', '');
    setValue('url', '');

    if (existingData && existingData.name) {
      setValue('name', existingData.name);
    }
    if (existingData && existingData.url) {
      setValue('url', existingData.url);
    }
    if (existingData && existingData.events && existingData.events.length) {
      setValue('events', existingData.events);
    }
  }, [existingData]);

  const onSubmit = (submitData: any) => {
    if (!isExisting) {
      try {
        createWebhook({
          name: submitData.name,
          url: submitData.url,
          customizations: {
            headers: [],
            body: [],
            filters: [],
          },
          events: submitData.events,
        });
        setEditing('');
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        updateWebhook({
          _id: existingId,
          name: submitData.name,
          url: submitData.url,
          customizations: {
            headers: [],
            body: [],
            filters: [],
          },
          events: submitData.events,
        });
        setEditing('');
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    setValidating(false);
    setReturnedValidation('');
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex>
        <Box flex="1">
          <Button variant="adminPrimary" size="sm" onClick={goBack}>
            <ArrowLeft marginRight={2} /> Back
          </Button>
        </Box>
        <Box>
          {!existingData && (
            <Button
              variant="adminPrimary"
              size="sm"
              type="submit"
              aria-label={t('webhooks.createIntegration') as string}
              disabled={!isValid}
              opacity={isValid ? '1' : '0.5'}
            >
              <Save marginRight={2} />
              {t('webhooks.save')}
            </Button>
          )}
          {existingData && isValid && (
            <Button
              variant="adminPrimary"
              size="sm"
              type="submit"
              aria-label={t('webhooks.updateIntegration') as string}
              disabled={!isValid}
              opacity={isValid ? '1' : '0.5'}
            >
              <Save marginRight={2} />
              {t('webhooks.save')}
            </Button>
          )}
        </Box>
      </Flex>
      <Box mt={4}>
        <Flex>
          <Box flex="1" marginRight={20}>
            <Stack>
              <Box>
                <Text variant="label">
                  {t('webhooks.name')} <span style={{ color: 'red' }}>*</span>
                </Text>
                <InputGroup paddingRight={0}>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('webhooks.mustHaveName'),
                      },
                    }}
                    render={({ field }) => <Input {...field} />}
                  />
                  <InputRightElement>
                    <Tooltip label={t('webhooks.nameTooltip')} placement="bottom-end">
                      <InfoOutlineIcon opacity="0.3" />
                    </Tooltip>
                  </InputRightElement>
                </InputGroup>
                <Text color="red" fontSize="xs" mt={1}>
                  {errors.name?.message as string}
                </Text>
              </Box>
              <Box>
                <Text variant="label">
                  {t('webhooks.url')}
                  <span style={{ color: 'red' }}>*</span>
                </Text>
                <InputGroup paddingRight={0}>
                  <Controller
                    name="url"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('webhooks.urlEmptyError'),
                      },
                      pattern: {
                        value: /^https|^http/i,
                        message: t('webhooks.urlError'),
                      },
                    }}
                    render={({ field }) => <Input {...field} />}
                  />
                  <InputRightElement>
                    <Tooltip label={t('webhooks.urlTooltip')} placement="bottom-end">
                      <InfoOutlineIcon opacity="0.3" />
                    </Tooltip>
                  </InputRightElement>
                </InputGroup>
                <Text color="red" fontSize="xs" mt={1}>
                  {errors.url?.message as string}
                </Text>
              </Box>
              {existingData && existingData._id && (
                <Box>
                  <Text variant="label">{t('webhooks.secret')}</Text>
                  <InputGroup paddingRight={0} background="gray.100" borderRadius={4}>
                    <Text paddingY={2} paddingX={4}>
                      {existingData && showingSecret ? existingData.secret : '***************'}
                    </Text>
                    <InputRightElement _hover={{ cursor: 'pointer' }}>
                      <Tooltip
                        label={
                          showingSecret
                            ? t('webhooks.secretTooltipHide')
                            : t('webhooks.secretTooltip')
                        }
                        placement="bottom-end"
                        cursor="pointer"
                      >
                        <View
                          opacity="0.3"
                          onClick={() => {
                            if (!showingSecret) {
                              setShowingSecret(true);
                              setTimeout(() => {
                                setShowingSecret(false);
                              }, 5000);
                            } else {
                              setShowingSecret(false);
                            }
                          }}
                        />
                      </Tooltip>
                    </InputRightElement>
                  </InputGroup>
                </Box>
              )}
              {existingData && (
                <>
                  {validating && returnedValidation !== 'passed' && <>Validating...</>}
                  {!validating && returnedValidation !== 'passed' && (
                    <Box paddingTop={2} pointerEvents={validating ? 'none' : 'auto'}>
                      <Tooltip label={t('webhooks.validateThisWebhook')} placement="bottom">
                        <Button
                          variant="adminPrimary"
                          size="sm"
                          opacity={validating ? '0.5' : '1'}
                          onClick={async () => {
                            setValidating(true);
                            validateWebhook({ id: existingId });
                          }}
                        >
                          {t('webhooks.validate')}
                        </Button>
                      </Tooltip>
                    </Box>
                  )}
                </>
              )}
            </Stack>
          </Box>

          <Box flex="1">
            <Stack spacing={4}>
              <Box>
                <Tooltip label={t('webhooks.selectOneOrMany')}>
                  <Text variant="label" fontSize="lg">
                    {t('webhooks.eventTypes')}
                    <InfoOutlineIcon boxSize="16px" opacity="0.5" marginLeft={2} />
                  </Text>
                </Tooltip>
              </Box>
              <Controller
                name="events"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { ref: _ref, ...field } }) => (
                  <>
                    <Box>
                      <Text variant="label">{t('webhooks.content')}</Text>
                      <Stack spacing={1}>
                        <CheckboxGroup {...field}>
                          {contentCheckboxes.map((option) => (
                            <Checkbox name="events" value={option.key} key={option.key}>
                              {option.text}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </Stack>
                    </Box>
                    <Box>
                      <Text variant="label">{t('webhooks.workshops')}</Text>
                      <Stack spacing={1}>
                        <CheckboxGroup {...field}>
                          {workshopsCheckboxes.map((option) => (
                            <Checkbox name="events" value={option.key} key={option.key}>
                              {option.text}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </Stack>
                    </Box>
                  </>
                )}
              />
            </Stack>
          </Box>
        </Flex>
      </Box>
    </form>
  );
}
