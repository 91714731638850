import { preAuthApiClient } from '../services/api/clients/preAuthApiClient';
import { successfulLoginActions } from '../services/hooks/preauth/useLogin';
import { useAuthStore } from '@/client/services/state/authStore';
import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useNavigate } from 'react-router-dom';
import { useToastStore } from '../services/state/toastStore';
import { useTranslation } from 'react-i18next';

const checkAuth = async (key: string, identifier: string) => {
  try {
    const response = await preAuthApiClient.post(
      `/v3/authtoken/oauth?key=${encodeURIComponent(key)}&identifier=${encodeURIComponent(
        identifier,
      )}`,
      {},
    );
    if (response?.data?.company?.suspended) {
      return { result: false, data: null, navigate: '/suspended' };
    }
    return { result: true, data: response.data };
  } catch (e: any) {
    console.log('catching error', e);
    return { result: false, data: null };
  }
};

export default function OauthTransition() {
  const { authConfig, setAuthConfig, logout } = useAuthStore();
  const [redirectLocation] = useLocalStorage('redirectLocation', null);
  const [angularRedirectLocation] = useLocalStorage('preRedirect', null);
  const navigate = useNavigate();
  const { setToast } = useToastStore();
  const { t } = useTranslation();

  useEffect(() => {
    const url: URL = new URL(window.location.href);
    const params: URLSearchParams = url.searchParams;
    const navigateOauthHelper = navigate;

    if (params.get('key') && params.get('identifier')) {
      const tmp = async () => {
        const resultObj = await checkAuth(params.get('key')!, params.get('identifier')!);
        if (!resultObj.result) {
          setToast({
            show: true,
            status: 'error',
            title: t('saml.errors.unkown-origin'),
          });
          navigateOauthHelper('/login');
          return;
        }

        successfulLoginActions(
          resultObj.data,
          authConfig,
          setAuthConfig,
          angularRedirectLocation,
          redirectLocation,
          navigateOauthHelper,
        );
      };
      tmp();
    } else {
      logout();
      navigateOauthHelper('/login');
    }
  }, []);
  return <div />;
}
