import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import ReactDOM from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes/Router';
// import { worker } from '@/client/utils/mockData/service-worker/service-worker';

declare global {
  interface Window {
    sslaConfig: any;
    sslaConfigSetup: any;
    sdht: any;
    sdad: any;
    courseDirectory: any;
    courseId: any;
    studentName: any;
    studentId: any;
    userContentId: any;
    scormPreview: any;
    contents: any;
    zESettings: any;
    newrelic: any;
    gtag: any;
    ga: any;
    plantrack: any;
    Intercom: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

// if (import.meta.env.MODE === 'development') {
//   worker.start({ onUnhandledRequest: 'bypass' });
// }

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <>
    {/* <React.StrictMode> */}
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    {/* </React.StrictMode> */}
  </>,
);
