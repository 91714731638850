import {
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';

import { Content } from '@/client/services/api/graphql/gql/graphql';
import { FileFormData } from '@/client/routes/admin/create/content/EditFile';
import { IoSparklesSharp } from 'react-icons/io5';
import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface GenerativeAiModalProps {
  data: string;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export default function GenerativeAiModal({
  data,
  isLoading,
  isOpen,
  onClose,
}: GenerativeAiModalProps) {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<Content & FileFormData>();
  const description = watch('description');

  const handleUseDescription = () => {
    setValue('description', data);
    onClose();
  };

  const handleAppendDescription = () => {
    setValue('description', `${description}\n${data}`);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent minWidth="60%" minHeight="50%" padding={4}>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <Text
              alignSelf="flex-start"
              fontSize="25px"
              fontWeight={600}
              mt={4}
              marginTop={5}
              marginBottom={2}
            >
              {t('authoring.media.generateWithAi.modal.title')}
            </Text>
            {isLoading ? (
              <>
                <HStack spacing={2} align="center" mt={6}>
                  <IoSparklesSharp />
                  <Text variant="createLabel">
                    {t('authoring.media.generateWithAi.modal.inProgress')}
                  </Text>
                </HStack>
                <Spinner size="lg" mt={8} />
              </>
            ) : (
              <Textarea isDisabled resize="none" variant="generativeAi">
                {data}
              </Textarea>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter display="flex" justifyContent="space-between">
          <Button variant="generateCancel" onClick={onClose}>
            {t('authoring.media.generateWithAi.modal.cancel')}
          </Button>

          <ButtonGroup>
            {description && (
              <>
                <OutlineButton onClick={handleUseDescription}>
                  {t('authoring.media.generateWithAi.modal.replace')}
                </OutlineButton>

                <SolidButton onClick={handleAppendDescription}>
                  {t('authoring.media.generateWithAi.modal.append')}
                </SolidButton>
              </>
            )}
            {!description && (
              <SolidButton fontWeight={500} onClick={handleUseDescription}>
                {t('authoring.media.generateWithAi.modal.use')}
              </SolidButton>
            )}
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
