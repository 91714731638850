import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Button,
  Text,
} from '@chakra-ui/react';
import { AngleDown } from '@/client/components/icons/ContinuIcons';
import WorkshopsSearch from '@/client/components/input/search/WorkshopsSearch';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { validateRadioValue } from '@/client/utils/workshops/handleFilterSelection';
import { useWorkshopsStore } from '../../services/state/routeState/workshopsStore';
import { useMultipleSelection } from 'downshift';

interface FilterProps {
  updateSearch: (query: any) => void;
  workshopsLoadingStatus: string;
}

interface WorkshopType {
  value: string;
  label: string;
  type: 'textInput' | 'workshopType';
}

const contentTypes: WorkshopType[] = [
  {
    type: 'workshopType',
    value: 'multi-session-workshop',
    label: 'Multi-Session Workshops',
  },
  { type: 'workshopType', value: 'workshop', label: 'Workshops' },
];

export default function WorkshopsFilter({ updateSearch, workshopsLoadingStatus }: FilterProps) {
  const { t } = useTranslation();
  const {
    selectedRegistrationStatus,
    setSelectedRegistrationStatus,
    selectedLocation,
    setSelectedLocation,
    selectedTime,
    setSelectedTime,
    persistedSearchTerm,
    setPersistedSearchTerm,
    persistedTypeRestriction,
    setPersistedTypeRestriction,
    persistedSelectedItems,
    setPersistedSelectedItems,
  } = useWorkshopsStore();
  const [when, setWhen] = useState<string>(selectedTime || 'upcoming');
  const [where, setWhere] = useState<string>(selectedLocation || 'online-and-in-person');
  const [registrationStatus, setRegistrationStatus] = useState<string>(
    selectedRegistrationStatus || 'all',
  );
  const [searchTerm, setSearchTerm] = useState<string | null>(persistedSearchTerm || null);
  const [type, setType] = useState<string | null>(persistedTypeRestriction || null);
  const [pickerItems, setPickerItems] = useState(contentTypes);

  const nameFromValueKey: any = {
    upcoming: t('workshops.upcoming'),
    past: t('workshops.past'),
    available: t('workshops.available'),
    registered: t('workshops.registered'),
    waitlisted: t('workshops.waitlisted'),
    online: t('workshops.online'),
    'in-person': t('workshops.inPerson'),
    all: t('workshops.all'),
    'online-and-in-person': t('workshops.onlineAndInPerson'),
  };

  // Handle Persisting Items in search
  useEffect(() => {
    if (persistedSelectedItems.length === 0) {
      setPersistedSearchTerm('');
      setPersistedTypeRestriction('');
      setType(null);
      setSearchTerm(null);
    }
    if (persistedSelectedItems.length === 1 && persistedSelectedItems[0].type === 'workshopType') {
      setPersistedTypeRestriction(persistedSelectedItems[0].value);
      setType(persistedSelectedItems[0].value);
    } else if (
      persistedSelectedItems.length === 1 &&
      persistedSelectedItems[0].type === 'textInput'
    ) {
      setSearchTerm(persistedSelectedItems[0].value);
      setPersistedSearchTerm(persistedSelectedItems[0].value);
    } else if (persistedSelectedItems.length === 2) {
      const textInput = persistedSelectedItems.find((item) => item.type === 'textInput');
      const workshopType = persistedSelectedItems.find((item) => item.type === 'workshopType');

      if (textInput && workshopType) {
        setSearchTerm(textInput.value);
        setPersistedSearchTerm(textInput.value);
      }
    }
  }, [persistedSelectedItems]);

  const handleCreateItem = (item: WorkshopType) => {
    setPickerItems((current) => [...current, item]);

    setPersistedSelectedItems([...persistedSelectedItems, item]);
  };

  const handleClearAll = () => {
    setPersistedSelectedItems([]);
  };

  const handleSelectedItemsChange = (items: WorkshopType[], changeType: string) => {
    if (items.length > 0) {
      if (changeType === useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace) {
        setPersistedSelectedItems(items);
        return;
      }

      const newItem = items[items.length - 1];

      const match = persistedSelectedItems.find((item) => item.type === newItem.type);

      if (match) {
        const existingIndex = persistedSelectedItems.findIndex(
          (item) => item.type === newItem.type,
        );

        const newArr = [...persistedSelectedItems];

        newArr[existingIndex] = newItem;

        setPersistedSelectedItems(newArr);
      } else {
        setPersistedSelectedItems(items);
      }
    } else {
      setPersistedSelectedItems(items);
    }
  };

  // Handle Persisting Items in Filter

  const handleFilterChange = (
    value: string | string[],
    changeFunc: (value: string) => void,
    persistFunc: (value: string) => void,
  ) => {
    if (workshopsLoadingStatus && workshopsLoadingStatus === 'success') {
      const filterValue = validateRadioValue(value);
      persistFunc(filterValue);
      changeFunc(filterValue);
    }
  };

  // Handle Query

  const buildSearchQuery = () => {
    const query: any = {};
    if (registrationStatus !== 'all') {
      query.status = registrationStatus;
    }
    if (where !== 'online-and-in-person') {
      query.online = where === 'online';
    }
    if (searchTerm) {
      query.searchTerm = searchTerm;
    }
    if (type) {
      query.workshopType = type;
    }

    query.upcoming = when === 'upcoming';
    return query;
  };

  useEffect(() => {
    const query = buildSearchQuery();
    updateSearch(query);
  }, [when, where, registrationStatus, searchTerm, type]);

  return (
    <Box padding={6} backgroundColor="white" margin={6} borderRadius="5px">
      <Flex direction={{ base: 'column', lg: 'row' }} justifyContent="space-between">
        <Text variant="pageheader" margin={2}>
          {t('global.contentTypes.workshops')}
        </Text>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justifyContent="space-evenly"
          order={{ base: 2, lg: 1 }}
          flexGrow="1"
          alignItems="center"
        >
          <Menu variant="workshopfilter" matchWidth>
            <MenuButton
              as={Button}
              variant="workshopfilter"
              rightIcon={<AngleDown color="#1A1A1A80" />}
            >
              {nameFromValueKey[when]}
            </MenuButton>
            <MenuList minWidth={0}>
              <MenuOptionGroup
                type="radio"
                onChange={(e) => handleFilterChange(e, setWhen, setSelectedTime)}
              >
              {registrationStatus === 'available' ? (
                <MenuItemOption value="past" isDisabled>{t('workshops.past')}</MenuItemOption>
              ) : (
                <MenuItemOption value="past">{t('workshops.past')}</MenuItemOption>
              )}
                <MenuItemOption value="upcoming">{t('workshops.upcoming')}</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          <Menu variant="workshopfilter" matchWidth>
            <MenuButton
              as={Button}
              variant="workshopfilter"
              rightIcon={<AngleDown color="#1A1A1A80" />}
            >
              {nameFromValueKey[registrationStatus]}
            </MenuButton>
            <MenuList minWidth={0}>
              <MenuOptionGroup
                type="radio"
                onChange={(e) =>
                  handleFilterChange(e, setRegistrationStatus, setSelectedRegistrationStatus)
                }
              >
                <MenuItemOption value="all">{t('workshops.all')}</MenuItemOption>
                {when === 'past' ? (
                  <MenuItemOption value="available" isDisabled>{t('workshops.available')}</MenuItemOption>
                ) : (
                  <MenuItemOption value="available">{t('workshops.available')}</MenuItemOption>
                )}
                <MenuItemOption value="registered">{t('workshops.registered')}</MenuItemOption>
                <MenuItemOption value="waitlisted">{t('workshops.waitlisted')}</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
          <Menu variant="workshopfilter" matchWidth>
            <MenuButton
              as={Button}
              variant="workshopfilter"
              rightIcon={<AngleDown color="#1A1A1A80" />}
            >
              {nameFromValueKey[where]}
            </MenuButton>
            <MenuList minWidth={0}>
              <MenuOptionGroup
                type="radio"
                onChange={(e) => handleFilterChange(e, setWhere, setSelectedLocation)}
              >
                <MenuItemOption value="online-and-in-person">
                  {t('workshops.onlineAndInPerson')}
                </MenuItemOption>
                <MenuItemOption value="online">{t('workshops.online')}</MenuItemOption>
                <MenuItemOption value="in-person">{t('workshops.inPerson')}</MenuItemOption>
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Flex>
        <WorkshopsSearch
          order={{ base: 1, lg: 2 }}
          onCreateItem={handleCreateItem}
          selectedItems={persistedSelectedItems}
          onSelectedItemsChange={(changes: any) =>
            handleSelectedItemsChange(changes.selectedItems, changes.type)
          }
          handleClearAll={handleClearAll}
          items={pickerItems}
        />
      </Flex>
    </Box>
  );
}
