import { IconButton, Tooltip, useToken } from '@chakra-ui/react';

import { Qooper } from '@/client/components/icons/ContinuIcons';
import { hexToRGBA } from '@/client/utils/hexToRGBA';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function QooperButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [brandPrimary] = useToken('colors', ['brand.primary']);

  return (
    <Tooltip hasArrow zIndex={9999} label={t('explore.viewType.qooper')}>
      <span>
        <IconButton
          backgroundColor={hexToRGBA(brandPrimary, 0.2)}
          zIndex={9999}
          aria-label={t('explore.viewType.qooper')}
          borderRadius="full"
          size="sm"
          fontSize="xl"
          icon={<Qooper color={hexToRGBA(brandPrimary, 0.6)} />}
          onClick={() => navigate('/mentor')}
          _hover={{ backgroundColor: hexToRGBA(brandPrimary, 0.1) }}
        />
      </span>
    </Tooltip>
  );
}
