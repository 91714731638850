import {
  Box,
  Button,
  Flex,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import {
  ColumnDefBase,
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { ReactElement, useMemo, useState } from 'react';
import {
  WebhookEventTypes,
  WebhooksIntegrationsSingleViewTypes,
} from '@/client/types/Integrations';

import { ArrowLeft } from '@/client/components/icons/ContinuIcons';
import FileDownloadButton from '@/client/components/buttons/FileDownloadButton';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import { useIntegrationService } from '@/client/services/hooks/admin/connect/useIntegrationService';
import { useTranslation } from 'react-i18next';

export default function WebhooksIntegrationsSingleView({
  id,
  setViewId,
}: WebhooksIntegrationsSingleViewTypes) {
  const { getWebhookDetails, getWebhookActivity } = useIntegrationService();
  const { t } = useTranslation();

  const renderTag = (color: string, text: string) => (
    <Tag
      backgroundColor={color}
      fontWeight="bold"
      color="white"
      padding={1}
      width="100%"
      textAlign="center"
      display="inline-block"
    >
      {text}
    </Tag>
  );

  const renderStatus = (status: number) => {
    if (status === 400 || status === 403 || status === 404 || status === 500 || status === 503) {
      return renderTag('orange', t('webhooks.unsuccessful'));
    }
    if (
      status === 200 ||
      status === 201 ||
      status === 202 ||
      status === 203 ||
      status === 204 ||
      status === 205 ||
      status === 206 ||
      status === 207 ||
      status === 208
    ) {
      return renderTag('green', t('webhooks.success'));
    }
    return renderTag('red', t('webhooks.error'));
  };

  const columnHelper = createColumnHelper<WebhookEventTypes>();

  const columns = useMemo<ColumnDefBase<WebhookEventTypes, any>[]>(
    () => [
      columnHelper.accessor('event', {
        cell: (info) => (<Text fontStyle="italic">{info.getValue()}</Text>) as ReactElement,
        header: () => t('webhooks.eventType'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('_id', {
        cell: (info) => info.getValue(),
        header: () => t('webhooks.messageId'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('createdAt', {
        cell: (info) => format(new Date(info.getValue()), 'hh:mm:ss a  MM/dd/yyyy'),
        header: () => t('webhooks.created'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('updatedAt', {
        cell: (info) => format(new Date(info.getValue()), 'hh:mm:ss a  MM/dd/yyyy'),
        header: () => t('webhooks.updated'),
        footer: (info) => info.column.id,
      }),
      columnHelper.accessor('status', {
        cell: (info) => renderStatus(info.getValue()),
        header: () => t('webhooks.status'),
        footer: (info) => info.column.id,
      }),
    ],
    [],
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchDataOptions = {
    pageIndex,
    pageSize,
  };

  const detailsQuery = getWebhookDetails(id);
  const dataQuery = getWebhookActivity(fetchDataOptions, id || '');
  console.log(dataQuery);

  const defaultData = useMemo(() => [], []);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    // @ts-ignore
    data: dataQuery.data?.data ?? defaultData,
    // @ts-ignore
    columns,
    pageCount: 1 ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });

  return (
    <>
      <Box flex="1">
        <Button
          variant="adminPrimary"
          size="xs"
          onClick={() => {
            setViewId('');
          }}
        >
          <ArrowLeft marginRight={2} /> {t('webhooks.back')}
        </Button>
      </Box>
      <Flex paddingTop={4} paddingBottom={2}>
        <Box flex={1}>
          <Text variant="pageheader" marginBottom={2}>
            {detailsQuery.data?.data.name}
            <Tooltip label={t('webhooks.associatedEvents')} placement="bottom">
              <InfoOutlineIcon opacity="0.3" boxSize="16px" marginLeft={2} />
            </Tooltip>
          </Text>
        </Box>
        <Box>
          {dataQuery.data?.data && dataQuery.data?.data.length > 0 && (
            <FileDownloadButton
              data={dataQuery.data?.data}
              filename={`Webhook_data-${format(new Date(), 'MM-dd-yyyy')}`}
              extension="csv"
              disabled
            />
          )}
        </Box>
      </Flex>
      {!dataQuery.data?.data.length && (
        <Text
          padding={8}
          background="gray.100"
          borderRadius="md"
          fontWeight="bold"
          textAlign="center"
          opacity="0.25"
        >
          {t('webhooks.noLogs')}
        </Text>
      )}
      {dataQuery.data?.data.length ? (
        <Box borderRadius={8} overflow="hidden">
          <TableContainer>
            <Table variant="admin">
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <Th key={header.id} colSpan={header.colSpan} paddingLeft={4}>
                        {header.isPlaceholder ? null : (
                          <Box>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </Box>
                        )}
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <Tr key={row.id + `${index}`}>
                    {row.getVisibleCells().map((cell, innerdex) => (
                      <Td
                        key={cell.id ? cell.id : innerdex + 1}
                        overflow="visible"
                        fontWeight="medium"
                        fontSize="14px"
                        whiteSpace={innerdex === 0 ? 'nowrap' : undefined}
                        style={{ overflow: 'visible' }}
                      >
                        <Box minHeight="40px" display="flex" alignItems="center">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Box>
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      ) : null}
    </>
  );
}

WebhooksIntegrationsSingleView.defaultProps = {
  id: '',
};
