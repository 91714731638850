/* eslint radix: 0 */

import { format, startOfMonth } from "date-fns";

import AnalyzeService from "./AnalyzeService";
import ContentService from "./ContentService";
import { AxiosError } from "axios";
import { Scorm } from "@/client/types/content/scorm/Scorm";
import { learnApiClient } from "./clients/learnApiClient";

const getUserScormRegistration = async (contextId: string | undefined) => {
  const response = await learnApiClient.get(`scorm/registration/${contextId}`);
  return response.data;
};

const getRegistrationsCount = async () => {
  const startOfCurrentMonth = format(startOfMonth(new Date()), "MM-dd-yy");

  const response = await learnApiClient.get(
    `scorm/registrations/all/count?createdAt=$gte,date-${startOfCurrentMonth}`
  );

  return response.data;
};

const getScorm = async (
  scormId: string | undefined,
  contextId: string | undefined,
  scormLimit: number,
  scormEnforcementType: string
): Promise<{
  scorm: Scorm;
  userRegistration: any;
  scormLimitEnforced: boolean;
}> => {
  let scormLimitEnforced = false;

  const response = await learnApiClient.get(
    `scorm/content/${scormId}?company-populate=scorm_limit+scorm_limit_enforcement,self_hosted,rating_configuration,average_rating&fields=company,user,title,description,duration,archived,banner_image,images,properties,tags,linked_category,privacy_locations,privacy_departments,privacy_teams,privacy_org_levels,privacy_grades,private,createdAt,updatedAt,self_hosted,certificate,certificate_data,allow_comments,rating_configuration,average_rating,groups,ssla_config,surveys,metadata`
  );

  const userRegistrationResponse = await getUserScormRegistration(contextId)
    .then(async (data) => {
      if (!data) {
        await getRegistrationsCount().then((registrationCountData) => {
          if (registrationCountData.count) {
            const count = parseInt(registrationCountData.count);

            if (count >= scormLimit && scormEnforcementType === "block") {
              scormLimitEnforced = true;
            }

            ContentService.postViews(scormId, "scorm");
            AnalyzeService.postViews(scormId, "scorm");
          }
        });

        return;
      }

      return data;
    })
    .catch(async (error: AxiosError) => {
      if (error.response?.status && error.response?.status === 404) {
        const registrationsCount = await getRegistrationsCount();

        if (registrationsCount.count) {
          const count = parseInt(registrationsCount.count);

          if (count >= scormLimit && scormEnforcementType === "block") {
            scormLimitEnforced = true;
          }
        }
        
        ContentService.postViews(scormId, "scorm");
        AnalyzeService.postViews(scormId, "scorm");
      }
    });

  return {
    scorm: response.data,
    userRegistration: userRegistrationResponse,
    scormLimitEnforced,
  };
};

const setRegistrationDataUrl = async (
  scormId: string | undefined,
  userId: string
) => {
  const response = await learnApiClient.post(`scorm/registrations/set-data`, {
    local: true,
    studentId: userId,
    activityId: scormId,
    data: JSON.stringify({ status: "incomplete" }),
  });

  return response.data;
};

const ScormService = {
  getScorm,
  getUserScormRegistration,
  getRegistrationsCount,
  setRegistrationDataUrl,
};

export default ScormService;
