import { useAccessHelper } from '@/client/services/hooks/auth/useAccessHelper';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

export default function PartnersFeatureWrapper() {
  const navigate = useNavigate();
  const { allowance } = useAccessHelper();

  useEffect(() => {
    if (!allowance.partners) {
      navigate('/');
    }
  }, [allowance, navigate]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return allowance.partners ? <Outlet /> : null;
}
