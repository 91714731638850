import {
  ButtonGroup,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

import OutlineButton from '@/client/components/shared/buttons/OutlineButton';
import SolidButton from '@/client/components/shared/buttons/SolidButton';
// eslint-disable-next-line import/extensions
import readyToPublish from '@/client/assets/images/readyToPublish.svg';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useTranslation } from 'react-i18next';

interface CollaboratorSaveModalProps {
  onSave: () => void;
}

export default function CollaboratorSaveModal({ onSave }: CollaboratorSaveModalProps) {
  const { t } = useTranslation();
  const { collaboratorSaveModalIsOpen, setCollaboratorSaveModalIsOpen } = useCreateStore();

  const isOpen = collaboratorSaveModalIsOpen;

  const onClose = () => setCollaboratorSaveModalIsOpen(false);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />

      <ModalContent>
        <ModalBody paddingTop={12}>
          <VStack textAlign="center">
            <Image src={readyToPublish} width={120} height="auto" />

            <Text fontSize="20px" fontWeight={600} color="baseBlack">
              {t('authoring.saveModal.saveHeader')}
            </Text>

            <Text fontSize="14px" fontWeight={500} color="neutral.1000">
              {t('authoring.saveModal.saveHelpText')}
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter paddingX={10} paddingBottom={8} paddingTop={10}>
          <ButtonGroup width="full" alignItems="center" justifyContent="center">
            <OutlineButton onClick={onClose}>{t('authoring.returnToEdit')}</OutlineButton>

            <SolidButton type="submit" onClick={onSave}>
              {t('authoring.saveModal.save')}
            </SolidButton>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
