import {
  AltLogins,
  InviteAcceptanceCredentials,
  PartnerLoginConfig,
  RegionCodeRegisterPayload,
} from '@/client/types/preauth/Preauth';

import { preAuthApiClient } from './clients/preAuthApiClient';
import { Partner } from '@/client/types/Partner';

interface LoginPayload {
  domain: string;
  password: string;
  email?: string;
  userid?: string;
}

const login = async (
  domain: string,
  password: string,
  userIdentifier: string,
  type: 'email' | 'employeeId',
  partnerConfig?: PartnerLoginConfig,
) => {
  let payload: LoginPayload = {
    domain,
    password,
  };

  if (type === 'email') {
    payload = {
      ...payload,
      email: userIdentifier,
    };
  } else if (type === 'employeeId') {
    payload = {
      ...payload,
      userid: userIdentifier,
    };
  }

  const response = await preAuthApiClient.post('v3/authtoken', payload);
  if (partnerConfig?.checkForPartnerMatch) {
    // A partner may login through the parent tenant, but a partner may not log in through any child tenants.
    // Non-partners may not log in through child tenants.
    if (
      partnerConfig?.partner && partnerConfig?.partner !== response.data.user.partner?._id
    ) {
      throw new Error(
        `Tenant<->partner mismatch. Expected ${partnerConfig?.partner} but got ${response.data.user.partner?._id}`,
      );
    }
    if (
      partnerConfig?.partner && !response.data.user.partner?._id
    ) {
      throw new Error(
        `Partner<->tenant mismatch. Expected ${partnerConfig?.partner} but got ${response.data.user.partner?._id}`,
      );
    }
  }

  return response.data;
};

const checkAlternativeLogins = async (hostname: string, partner?: string): Promise<AltLogins> => {
  let route = `learn/v3/companies/check-alternative-logins?authtoken=public&domain=${hostname}`;
  if (partner) {
    route += `&partner=${partner}`;
  }
  const response = await preAuthApiClient.get(route);

  return response.data;
};

const requestPasswordReset = async (email: string, domain: string) => {
  const response = await preAuthApiClient.post(`learn/v3/users/${email}/resetting`, {
    domain,
  });

  return response.data;
};

const resetPassword = async (
  domain: string,
  email: string | null,
  key: string | null,
  password: string,
) => {
  const response = await preAuthApiClient.post(`learn/v3/users/reset`, {
    domain,
    email,
    key,
    password,
  });

  return response.data;
};

const registerWithRegionCodeInitial = async (code: string, domain: string) => {
  const response = await preAuthApiClient.post('v3/registration-data/initial', {
    code,
    domain,
  });

  return response;
};

const registerWithRegionCodeSecond = async (payload: RegionCodeRegisterPayload) => {
  const response = await preAuthApiClient.post('v3/registration-data/second', payload);

  return response.data;
};

const checkRegistrationActive = async (domain: string): Promise<{ status: string }> => {
  const response = await preAuthApiClient.get(`v3/registration-data/active?domain=${domain}`);

  return response.data;
};

const registerWithEmployeeId = async (domain: string, password: string, userid: string) => {
  const response = await preAuthApiClient.post(
    'learn/v3/users/register-with-userid?authtoken=public&domain=staging.continu.co&subresource=',
    {
      domain,
      password,
      userid,
    },
  );

  return response.data;
};

const acceptInviteSetup = async (
  hostname: string,
  key: string,
  email: string,
): Promise<{ allow: boolean; company: string; partner: Partner | null }> => {
  const route = `v3/public/users/accept-invite-setup`;
  const usp = new URLSearchParams();
  usp.append('domain', hostname);
  usp.append('key', key);
  usp.append('email', email);
  const response = await preAuthApiClient.post(route, usp.toString());
  return response.data;
};

const acceptInvite = async ({
  firstName,
  lastName,
  domain,
  key,
  email,
  password,
}: InviteAcceptanceCredentials) => {
  const response = await preAuthApiClient.post(`learn/v3/users/accept`, {
    first_name: firstName,
    last_name: lastName,
    domain,
    password,
    key,
    email,
  });

  return response.data;
};

const PreAuthService = {
  login,
  checkAlternativeLogins,
  requestPasswordReset,
  resetPassword,
  registerWithRegionCodeInitial,
  registerWithRegionCodeSecond,
  checkRegistrationActive,
  registerWithEmployeeId,
  acceptInvite,
  acceptInviteSetup,
};

export default PreAuthService;
