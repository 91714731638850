// Adds total number above stacked bar chart
// Only usable for stacked bar chart - insert as last layer
// taken from https://github.com/plouc/nivo/issues/2020 and lazily tweaked for lint

import { ComputedBarDatum } from "@nivo/bar";

type StackedBarTotalsLayerProps = {
  bars: readonly ComputedBarDatum<{ [key: string]: number; }>[];
  xScale: Function;
  yScale: Function;
}

export function StackedBarTotalsLayer({ bars, xScale, yScale }: StackedBarTotalsLayerProps) {
  const labelOffset = 10;
  const labelFontSize = 12;
  if (bars.length === 0) return null;
  // compute totals for each index/bar
  const totals: any = {};
  const bandwidth = bars[0].width;
  bars.forEach((bar: any) => {
    const { indexValue } = bar.data;
    if (!(indexValue in totals)) {
      totals[indexValue] = 0;
    }
    if (!bar.data.hidden) {
      totals[indexValue] += bar.data.value;
    }
  });
  // place text elements above the bars
  const labels = Object.keys(totals).map((indexValue) => {
    const x = xScale(indexValue) + bandwidth / 2;
    const y = yScale(totals[indexValue]) - labelOffset;
    return (
      <text
        key={'total.' + indexValue}
        x={x}
        y={y}
        textAnchor='middle'
        fontWeight='bold'
        fontSize={labelFontSize}
      >
        {totals[indexValue]}
      </text>
    );
  });
  return labels;
};