import { Question, QuestionOption } from '@/client/types/content/assessments/Assessment';

export const getQuestionOptions = (question: Question) => {
  const opts: any = {};

  if (!question || !question.question_options || !question.question_options.length) {
    return opts;
  }

  question.question_options.forEach((item: QuestionOption) => {
    if (!item.name) {
      return;
    }

    opts[item.name] = item.value;
  });

  return opts;
};
