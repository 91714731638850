import { Box, Center, Divider, Flex, HStack, Link, Stack, Text, VStack } from '@chakra-ui/react';
import { Navigate, Outlet, Link as RouterLink, useLocation } from 'react-router-dom';

import Logo from '@/client/components/media/images/Logo';
import { useAuthStore } from '@/client/services/state/authStore';
import { useConfigStore } from '@/client/services/state/configStore';
import { useTranslation } from 'react-i18next';
import { usePartnerStore } from '@/client/services/state/partnerStore';
import { useEffect, useState } from 'react';

export default function LoggedOutLayout() {
  const location = useLocation();
  const { authConfig } = useAuthStore();
  const { config } = useConfigStore();
  const { t } = useTranslation();
  const { partner } = usePartnerStore();
  const [ bgImage, setBgImage ] = useState("");

  const getBgImageUrl = () => {
    if (partner?.branding?.background_image_urls?.length > 0) {
      const partnerNum = Math.floor(
        Math.random() * (partner.branding.background_image_urls.length)
      );
      return partner.branding.background_image_urls[partnerNum];
    }
    const num = Math.floor(Math.random() * (config.theme.background_image_count - 1 + 1) + 1);

    if (config?.theme?.background_image_count) {
      const num = Math.floor(
        Math.random() * (config.theme.background_image_count) + 1
      );
  
      const bgImageUrl = `${config.publicImageUrl}${config.theme.layout}/login-images/login-${num}.jpg`;
  
      return bgImageUrl;
    }

    return "";
  };

  useEffect(() => {
    setBgImage(getBgImageUrl());
  }, []);

  useEffect(() => {
    setBgImage(getBgImageUrl());
  }, [partner]);

  if (authConfig.authtoken) {
    return <Navigate to="/explore" replace />;
  }

  return (
    <Center
      minHeight="100vh"
      backgroundColor="white"
      backgroundImage={config.theme.background_image_count === 0 ? '' : `url(${getBgImageUrl()})`}
      overflow="auto"
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Flex
        minHeight="100vh"
        align="center"
        justify="center"
        width="500px"
        maxWidth="100%"
        zIndex="2"
      >
        <Stack spacing={8} width="100%" paddingY={12} paddingX={6}>
          <Box rounded="lg" boxShadow="lg" backgroundColor="white" padding={8} width="100%">
            <Box marginTop={4} marginBottom={8} textAlign="center">
              <Logo variant="login" />
            </Box>

            <Outlet />

            <VStack marginTop={4}>
              <HStack height="1rem" spacing={2}>
                {!location.pathname.startsWith('/login') && (
                  <>
                    <Link fontSize="xs" as={RouterLink} to="/login">
                      {t('preauth.signin.button')}
                    </Link>

                    <Divider orientation="vertical" borderColor="black" />
                  </>
                )}

                <Link fontSize="xs" isExternal href={`mailto:${config.supportEmailAddress}`}>
                  {t('preauth.signin.contactSupport')}
                </Link>
              </HStack>

              <Text fontSize="xs" color="gray.500" textAlign="center">
                &copy; Continu, Inc. {t('preauth.all_rights_reserved')}
              </Text>
            </VStack>
          </Box>
        </Stack>
      </Flex>
    </Center>
  );
}
