export const Table = {
  variants: {
    admin: {
      table: {
        thead: {
          backgroundColor: 'brand.tableHeader',
          zIndex: 1,
        },
        th: {
          paddingY: 5,
          paddingX: 2,
          textTransform: 'none',
          color: 'white',
          fontSize: '14px',
          fontWeight: 'bold',
        },
        tr: {
          height: '50px',

          td: {
            fontSize: '14px',
            overflow: 'hidden',
            fontWeight: 'medium',
            paddingY: 1,
            paddingX: 2,
          },
          '&:nth-of-type(even)': {
            td: {
              background: 'blackAlpha.50',
            },
          },
        },
      },
    },
    sample: {
      table: {
        th: {
          paddingY: 5,
          paddingX: 2,
          textTransform: 'none',
          color: 'white',
          fontSize: '14px',
          fontWeight: 'bold',
        },
        tr: {
          height: '50px',

          td: {
            fontSize: '14px',
            overflow: 'hidden',
            fontWeight: 'medium',
            paddingY: 1,
            paddingX: 2,
          },
          '&:nth-of-type(even)': {
            td: {
              background: 'blackAlpha.50',
            },
          },
        },
      },
    },
  },
};
