import { ArrowRight, Check, Close, WarningTriangle } from '@/client/components/icons/ContinuIcons';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { InfoOutlineIcon, ViewIcon } from '@chakra-ui/icons';

import { ApiIntegrationsCreateTypes } from '@/client/types/Integrations';
import ApiSecret from '@/client/components/data-display/integrations/ApiSecret';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ApiIntegrationsCreate({
  setEditing,
  editing,
  createApiIntegration,
  viewData,
}: ApiIntegrationsCreateTypes) {
  const [showSecret, setShowSecret] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const { t } = useTranslation();

  type FormInputsType = {
    appName: string;
    appDesc: string;
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
  } = useForm<FormInputsType>({
    defaultValues: {
      appName: '',
      appDesc: '',
    },
  });

  const resetForm = () => {
    setValue('appName', '');
    setValue('appDesc', '');
  };

  const onSubmit = (data: FormInputsType) => {
    const payload = {
      application_name: data.appName,
      application_info: {
        name: 'description',
        value: data.appDesc,
      },
    };

    createApiIntegration(payload);
    resetForm();
  };

  const cancelIntegrationCreate = () => {
    setEditing('');
  };

  const onClose = () => {
    setIsConfirming(false);
  };

  const handleConfirm = () => {
    handleSubmit(onSubmit)();
    setIsConfirming(false);
    setEditing('');
  };

  return (
    <form>
      {editing !== 'api' && (
        <Stack gap={4}>
          <Box>
            <Text variant="label">{t('apiIntegrations.appName')}</Text>

            <InputGroup>
              <Text p={2} color="gray.500">
                {viewData?.application_name}
              </Text>
              <InputRightElement>
                <Tooltip label={t('apiIntegrations.appNameViewTooltip')} placement="bottom-end">
                  <InfoOutlineIcon opacity="0.3" />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box>
            <Text variant="label">{t('apiIntegrations.appDescription')}</Text>

            <InputGroup>
              <Text p={2} paddingRight={8} color="gray.500">
                {viewData?.application_info[0].value}
              </Text>

              <InputRightElement>
                <Tooltip
                  label={t('apiIntegrations.appDesctiptionViewTooltip')}
                  placement="bottom-end"
                >
                  <InfoOutlineIcon opacity="0.3" />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box>
            <Text variant="label">{t('apiIntegrations.clientId')}</Text>

            <InputGroup>
              <Text p={2} paddingRight={8} color="gray.500" wordBreak="break-all">
                {viewData?.client_id}
              </Text>

              <InputRightElement>
                <Tooltip label={t('apiIntegrations.clientIdViewTooltip')} placement="bottom-end">
                  <InfoOutlineIcon opacity="0.3" />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </Box>

          <Box>
            <Text variant="label">{t('apiIntegrations.clientSecret')}</Text>

            <InputGroup>
              <Tooltip label={showSecret ? 'Click to Copy' : ''}>
                <Box
                  p={2}
                  paddingRight={8}
                  color="gray.500"
                  wordBreak="break-all"
                  _hover={{ cursor: showSecret ? 'pointer' : '' }}
                >
                  {viewData && viewData._id && (
                    <ApiSecret visibility={showSecret} id={viewData._id} />
                  )}
                </Box>
              </Tooltip>

              <Tooltip label={showSecret ? 'Hide Secret' : 'Show Secret'} placement="bottom-end">
                <InputRightElement
                  onClick={() => {
                    if (showSecret) {
                      setShowSecret(false);
                    } else {
                      setShowSecret(true);
                      setTimeout(() => {
                        setShowSecret(false);
                      }, 8000);
                    }
                  }}
                  cursor="pointer"
                >
                  <ViewIcon opacity="0.3" />
                </InputRightElement>
              </Tooltip>
            </InputGroup>
          </Box>
        </Stack>
      )}

      {editing === 'api' && (
        <>
          <Flex justifyContent="end" textAlign="right">
            <Box marginRight={2}>
              <Button
                variant="icon"
                onClick={() => {
                  setIsConfirming(true);
                }}
                aria-label={t('apiIntegrations.create') as string}
                opacity={isValid ? '1' : '0.2'}
                backgroundColor="transparent"
                _hover={{ backgroundColor: 'transparent' }}
              >
                <Check color="green" />
              </Button>
            </Box>

            <Box onClick={cancelIntegrationCreate} _hover={{ cursor: 'pointer' }}>
              <Button
                variant="icon"
                onClick={cancelIntegrationCreate}
                aria-label={t('apiIntegrations.cancel') as string}
                backgroundColor="transparent"
                _hover={{ backgroundColor: 'transparent' }}
              >
                <Close color="red" />
              </Button>
            </Box>
          </Flex>

          <Stack gap={4}>
            <Box>
              <Text variant="label">
                {t('apiIntegrations.appName')} <span style={{ color: 'red' }}>*</span>
              </Text>

              <InputGroup>
                <Controller
                  name="appName"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('apiIntegrations.mustHaveName'),
                    },
                  }}
                  render={({ field }) => <Input {...field} />}
                />

                <InputRightElement>
                  <Tooltip label={t('apiIntegrations.appNameTooltip')} placement="bottom-end">
                    <InfoOutlineIcon opacity="0.3" />
                  </Tooltip>{' '}
                </InputRightElement>
              </InputGroup>

              <Text color="red" fontSize="xs" mt={1}>
                {errors.appName?.message}
              </Text>
            </Box>

            <Box>
              <Text variant="label">
                {t('apiIntegrations.appDescription')} <span style={{ color: 'red' }}>*</span>
              </Text>

              <InputGroup paddingRight={0}>
                <Controller
                  name="appDesc"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('apiIntegrations.descriptionRequired'),
                    },
                  }}
                  render={({ field }) => <Textarea {...field} paddingRight={8} />}
                />

                <InputRightElement>
                  <Tooltip
                    label={t('apiIntegrations.appDescriptionTooltip')}
                    placement="bottom-end"
                  >
                    <InfoOutlineIcon opacity="0.3" />
                  </Tooltip>{' '}
                </InputRightElement>
              </InputGroup>

              <Text color="red" fontSize="xs" mt={1}>
                {errors.appDesc?.message}
              </Text>
            </Box>
          </Stack>
        </>
      )}

      <Modal size="xl" isOpen={isConfirming} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader display="flex" alignItems="center">
            <WarningTriangle color="brand.warning" marginRight={4} />

            {t('webhooks.createModalTitle')}
          </ModalHeader>

          <ModalBody>
            <Text as="p" marginBottom={2}>
              {t('webhooks.createModalTextA')}
            </Text>

            <Text as="p">{t('webhooks.createModalTextB')}</Text>
          </ModalBody>

          <ModalFooter width="full" display="flex" justifyContent="space-between">
            <Button variant="adminCancel" mr={3} onClick={onClose}>
              {t('webhooks.createModalCance')}
            </Button>

            <Button variant="adminPrimary" onClick={handleConfirm}>
              {t('webhooks.createModalContinu')} <ArrowRight marginLeft={2} />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </form>
  );
}
