import { Avatar, Box, ButtonGroup, Flex, IconButton, Link, Stack, Text } from '@chakra-ui/react';
import {
  Comment as CommentIcon,
  Delete,
  Edit,
  Heart,
  HeartFilled,
} from '@/client/components/icons/ContinuIcons';
import { useEffect, useState } from 'react';

import CommentInput from './CommentInput';
import { Comment as CommentType } from '../../../types/Comment';
import HtmlRenderer from '../../html/HtmlRenderer';
import { Link as RouterLink } from 'react-router-dom';
import { formatComment } from '@/client/utils/content/comments/formatComment';
import { formatDistance } from 'date-fns';
import { useAuthStore } from '@/client/services/state/authStore';
import { useTranslation } from 'react-i18next';

type CommentProps = {
  comment: CommentType | undefined;
  onDelete: any;
  onEdit: any;
  onReply: any;
  newMentions: string[];
  setNewMentions: React.Dispatch<React.SetStateAction<string[]>>;
  contentType: string;
  parentId?: string;
  onLikeComment: any;
  onUnLikeComment: any;
};

export default function Comment({
  comment,
  onDelete,
  onEdit,
  onReply,
  newMentions,
  setNewMentions,
  contentType,
  parentId,
  onLikeComment,
  onUnLikeComment,
}: CommentProps) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(comment?.comment);
  const [isReplying, setIsReplying] = useState(false);
  const [replyValue, setReplyValue] = useState('');
  const [viewerHasLiked, setViewerHasLiked] = useState(false);

  const onEditSubmit = () => {
    onEdit.mutate({ commentId: comment?._id, editedValue });
    setIsEditing(false);
  };

  const onReplySubmit = () => {
    onReply.mutate({
      replyValue,
      parentCommentId: parentId ? parentId : comment?._id,
    });
    setIsReplying(false);
    setReplyValue('');
  };

  const onHandleReply = () => {
    if (comment?.is_reply) {
      setReplyValue(
        `<:${comment?.user.first_name} ${comment?.user.last_name}:${comment?.user._id}>`,
      );
    }
    setIsReplying(true);
  };

  const onLikeSubmit = () => {
    if (viewerHasLiked && comment) {
      onUnLikeComment.mutate({ commentId: comment._id, userId: user._id });
    } else if (comment) {
      onLikeComment.mutate({ commentId: comment._id, userId: user._id });
    }
  };

  const returnUserName = (comment: CommentType | undefined) => {
    let displayName;
    if (comment && comment.user) {
      if (comment.user.first_name && comment.user.last_name) {
        displayName = `${comment.user.first_name} ${comment.user.last_name}`;
      } else if (comment?.user._id === user._id) {
        displayName = t('singlecomment.you');
      }

      return (
        <Link
          as={RouterLink}
          to={`/profile/${comment.user && comment.user._id}`}
          marginBottom={0}
          color="black"
          _hover={{
            color: 'brand.primary',
          }}
          display="inline-block"
          fontSize="14px"
          fontWeight="500"
        >
          <Text>{displayName}</Text>
        </Link>
      );
    } else {
      displayName = t('singlecomment.anonymous');
      return (
        <Text marginBottom={0} color="black" display="inline-block" fontWeight="bold">
          {displayName}
        </Text>
      );
    }
  };

  useEffect(() => {
    comment?.likes.includes(user._id) ? setViewerHasLiked(true) : setViewerHasLiked(false);
  }, [comment]);

  if (!comment) return <Text>Could not load this comment</Text>;

  return (
    <>
      <Flex paddingY={4} fontSize="18px">
        <Avatar
          name={comment.user && `${comment.user.first_name} ${comment.user.last_name}`}
          src={comment.user && comment.user.image}
          marginRight={4}
          backgroundColor="gray.300"
          color="gray.500"
          size="md"
        />

        <Stack width="full" direction="column" spacing={1}>
          <Flex justifyContent="space-between">
            <Box flex="1">
              {returnUserName(comment)}
              {comment.replies && (
                <Text display="inline-block" marginLeft={2} fontSize="12px" color="gray.400">
                  {t('singlecomment.andMore', {
                    count: comment.replies.length,
                  })}
                </Text>
              )}
            </Box>

            <Text fontSize="12px" color="gray.400">
              {formatDistance(new Date(comment.createdAt), new Date())} ago
              {comment.edited && <Text as="span"> ({t('contentViews.comments.edited')})</Text>}
            </Text>
          </Flex>

          {!isEditing && (
            <>
              <Box marginTop={'0px !important'}>
                <HtmlRenderer html={formatComment(comment.comment)} comment />
              </Box>

              <ButtonGroup variant="pure" size="xs" color="gray.400" spacing={1}>
                <Flex alignItems="center">
                  <IconButton
                    aria-label="Like"
                    icon={viewerHasLiked ? <HeartFilled /> : <Heart />}
                    color={viewerHasLiked ? 'brand.button' : ''}
                    onClick={onLikeSubmit}
                  />
                  {comment.likes.length > 0 && (
                    <Text color="gray.400" fontSize="xs">{`(${comment.likes.length})`}</Text>
                  )}
                </Flex>

                {comment.user && user._id === comment.user._id && (
                  <IconButton
                    aria-label="Edit comment"
                    icon={<Edit />}
                    onClick={() => setIsEditing(true)}
                  />
                )}

                <IconButton
                  aria-label="Reply to comment"
                  icon={<CommentIcon />}
                  onClick={() => onHandleReply()}
                />

                {comment.user && (user._id === comment.user._id || user.role === 'admin') && (
                  <IconButton
                    aria-label="Delete comment"
                    icon={<Delete />}
                    onClick={() => onDelete.mutate(comment._id)}
                  />
                )}
              </ButtonGroup>
            </>
          )}

          {isEditing && (
            <CommentInput
              value={editedValue}
              setValue={setEditedValue}
              onSubmit={onEditSubmit}
              contentType={contentType}
              onCancel={setIsEditing}
              mentions={newMentions}
              setMentions={setNewMentions}
              isEdit
            />
          )}
        </Stack>
      </Flex>

      {isReplying && (
        <CommentInput
          value={replyValue}
          setValue={setReplyValue}
          onSubmit={onReplySubmit}
          contentType={contentType}
          onCancel={setIsReplying}
          mentions={newMentions}
          setMentions={setNewMentions}
          isReply
        />
      )}
    </>
  );
}

Comment.defaultProps = {
  parentId: '',
};
