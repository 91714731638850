export type TCourseContent = { index: number; title: string; id: string; courses: any[] };

const courseContentDataKey = Symbol('course');

export type TCourseContentData = {
  [courseContentDataKey]: true;
  title: TCourseContent['title'];
  id: TCourseContent['id'];
};

export function getCourseContentData(content: TCourseContent): TCourseContentData {
  return { [courseContentDataKey]: true, title: content.title, id: content.id };
}

export function isCourseContentData(
  data: Record<string | symbol, unknown>,
): data is TCourseContentData {
  return data[courseContentDataKey] === true;
}
