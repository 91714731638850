import SwitchList from './SwitchList';
import { useTranslation } from 'react-i18next';

export default function ProfileAssignments() {
  const { t } = useTranslation();

  const assignmentOptions = [
    {
      name: 'permissions.admin.assignments.view',
      label: t('profiles.edit.viewAssignments'),
      enabled: true,
    },
    {
      name: 'permissions.admin.assignments.create',
      label: t('profiles.edit.createAssignments'),
      enabled: true,
    },
    {
      name: 'permissions.admin.assignments.edit',
      label: t('profiles.edit.editAssignments'),
      enabled: true,
    },
    {
      name: 'permissions.admin.assignments.archive',
      label: t('profiles.edit.archiveAssignments'),
      enabled: true,
    },
    {
      name: 'permissions.admin.assignments.complete',
      label: t('profiles.edit.completeAssignments'),
      enabled: true,
    },
  ];

  return <SwitchList enableSelectAll={false} options={assignmentOptions} />;
}
