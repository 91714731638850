import { ArrowRight, Check } from '@/client/components/icons/ContinuIcons';
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Image,
  LinkBox,
  LinkOverlay,
  List,
  ListItem,
  Progress,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useToken,
} from '@chakra-ui/react';
import {
  ImportedContent,
  Instructor,
  LilStructure,
  Registration,
} from '@/client/types/content/ImportedContent';
import { useEffect, useRef, useState } from 'react';

import { BiCollapse } from 'react-icons/bi';
import CommentList from '@/client/components/lists/CommentList';
import ContentHeader from '@/client/components/layout/ContentHeader';
import ContentLength from '@/client/components/data-display/content/ContentLength';
import HtmlRenderer from '@/client/components/html/HtmlRenderer';
import ImportedContentService from '@/client/services/api/ImportedContentService';
import PopupSurveyLayout from '@/client/components/overlay/PopupSurvey';
import ViewActions from '@/client/components/overlay/ViewActions';
import { capCase } from '@/client/utils/capCase';
import { useAuthStore } from '@/client/services/state/authStore';
import { useCheckSurveyCompletion } from '@/client/services/hooks/useCheckSurveyCompletion';
import { useCreateStore } from '@/client/services/state/admin/create/createStore';
import { useMutation } from '@tanstack/react-query';
import { usePartnerStore } from '@/client/services/state/partnerStore';
import { useToastStore } from '@/client/services/state/toastStore';
import { useTranslation } from 'react-i18next';

interface ImportedContentViewProps {
  data: {
    content: ImportedContent;
    registration: Registration | null | undefined;
    progressPercent: number;
  };
  contentId: string | undefined;
  inJourney?: boolean;
  isPreview?: boolean;
}

export default function ImportedContentView({
  data,
  contentId,
  inJourney = false,
  isPreview = false,
}: ImportedContentViewProps) {
  const { t } = useTranslation();
  const { authConfig } = useAuthStore();
  const { user } = authConfig;
  const { setToast } = useToastStore();
  const { partner } = usePartnerStore();
  const { showPopup, checkShowPopupSurvey } = useCheckSurveyCompletion();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [selectedInstructor, setSelectedInstructor] = useState<number>(0);
  const brandPrimary = useToken('colors', 'brand.primary');
  const heroRef = useRef<HTMLDivElement>(null);
  const { setCoursePreviewModalIsOpen } = useCreateStore();

  useEffect(() => {
    checkShowPopupSurvey(data.content, data.progressPercent === 100);
  }, [data]);

  const register = useMutation({
    mutationFn: () => ImportedContentService.register(contentId, user._id),
    onError: () => {
      setToast({
        show: true,
        status: 'error',
        title: 'Unable to create registration.',
      });
    },
  });

  const formatDifficulty = (difficulty: string) => {
    const lowercase = difficulty.toLowerCase();

    return capCase(lowercase);
  };

  // const setVendorImage = (vendor: string) => {
  //   switch (vendor) {
  //     case 'linkedin_learning':
  //       return 'url(https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/integrations/linkedin-full.png)';
  //     case 'udemy':
  //       return 'url(https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/integrations/udemy_full.svg)';
  //     case 'masterclass':
  //       return 'url(/public/images/masterclass-logo.png)';
  //     default:
  //       return '';
  //   }
  // };

  const setVendorImage = (vendor: string) => {
    switch (vendor) {
      case 'linkedin_learning':
        return 'https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/integrations/linkedin-full.png';
      case 'udemy':
        return 'https://d3bv3vdbo0gr1i.cloudfront.net/brand-images/continu/integrations/udemy_full.svg';
      case 'masterclass':
        return '/public/images/masterclass-logo.png';
      default:
        return '';
    }
  };

  return (
    <Box>
      <>
        <Flex
          width="full"
          background={`url(${data.content.banner_image})`}
          backgroundSize="cover"
          backgroundPosition="center center"
          ref={heroRef}
        >
          <Box width="full" height="full" bgColor="blackAlpha.700">
            {isPreview && (
              <Flex width="full" justifyContent="flex-end" padding={4}>
                <Button
                  variant="createCancel"
                  leftIcon={<Icon as={BiCollapse} />}
                  onClick={() => setCoursePreviewModalIsOpen(false)}
                >
                  {t('authoring.livePreview.returnToEditing')}
                </Button>
              </Flex>
            )}

            {!inJourney && !isPreview && (
              <ContentHeader
                contentType="imported_content"
                contentTitle={data.content.title}
                contentId={contentId}
                allowComments={data.content.allow_comments}
                goToComments={() => setActiveTabIndex(1)}
                heroRef={heroRef}
              />
            )}

            <Center width="full" marginY={40}>
              <VStack spacing={6}>
                <Text variant="heroheader" color="white" marginY="0" marginBottom={-2}>
                  {data.content.title}
                </Text>

                {data.content.duration && (
                  <ContentLength duration={data.content.duration} color="white" size="lg" />
                )}

                <Flex alignItems="center">
                  <Box color="white" marginRight={4}>
                    {data.progressPercent}% {t('contentViews.tracks.complete')}
                  </Box>

                  <Progress value={data.progressPercent} variant="hollow" />
                </Flex>

                <LinkBox>
                  <Button
                    rightIcon={<ArrowRight />}
                    paddingX={8}
                    paddingY={7}
                    onClick={!data.registration && !isPreview ? () => register.mutate() : () => {}}
                  >
                    <LinkOverlay isExternal href={data.content.vendor_full_link}>
                      {t('importedContent.view_course')}
                    </LinkOverlay>
                  </Button>
                </LinkBox>
              </VStack>
            </Center>
          </Box>
        </Flex>

        <Center width={{ base: '100%', md: '70%' }} margin="0 auto" marginTop={8} marginBottom={24}>
          <Tabs
            index={activeTabIndex}
            width="full"
            isFitted
            onChange={(index) => setActiveTabIndex(index)}
          >
            <TabList marginBottom={8} alignContent="center" display="flex" justifyContent="center">
              <Tab flex="0" marginRight={4}>
                {t('manage.info')}
              </Tab>

              {data.content.allow_comments && !partner._id && (
                <Tab flex="0">{t('importedContent.discussion')}</Tab>
              )}
            </TabList>

            <TabPanels>
              <TabPanel>
                <Flex direction={{ base: 'column-reverse', lg: 'row' }}>
                  <Box width={{ base: '100%', lg: '50%' }}>
                    {data.content.learn_items.length > 0 && (
                      <Box marginBottom={8}>
                        <Text as="h4">{t('importedContent.in_this_you_will_learn')}</Text>

                        {data.content.learn_items.map((item: any) => {
                          if (item === '') return;

                          return (
                            <HStack key={item} spacing={4}>
                              <Check color="brand.primary" />

                              <Text>{item}</Text>
                            </HStack>
                          );
                        })}
                      </Box>
                    )}

                    <Text as="h4">{t('importedContent.description')}</Text>

                    <HtmlRenderer html={data.content.description} />

                    {data.content.lil_structure && data.content.lil_structure.length > 0 && (
                      <>
                        <Text as="h4">{t('importedContent.course_outline')}</Text>

                        <List>
                          {data.content.lil_structure.map((item: LilStructure) => (
                            <ListItem
                              key={item.title}
                              borderBottom="1px"
                              borderBottomColor="gray.200"
                              paddingY={2}
                            >
                              {item.title}
                            </ListItem>
                          ))}
                        </List>
                      </>
                    )}
                  </Box>

                  <Flex
                    width={{ base: '100%', lg: '50%' }}
                    justifyContent={{ base: 'center', lg: 'flex-end' }}
                  >
                    {data.content.instructor_ids.length === 1 && (
                      <Box
                        backgroundColor="blackAlpha.50"
                        width="70%"
                        marginBottom={10}
                        paddingX={6}
                      >
                        <Stack>
                          <Box>
                            <Text as="h4" marginBottom={0}>
                              {t('importedContent.author')}
                            </Text>
                            <Text>{data.content.instructor_ids[0].name}</Text>
                          </Box>

                          {data.content.difficulty &&
                            data.content.vendor === 'linkedin_learning' && (
                              <Box>
                                <Text as="h4" marginBottom={0}>
                                  {t('importedContent.difficulty')}
                                </Text>
                                <Text>{formatDifficulty(data.content.difficulty)}</Text>
                              </Box>
                            )}

                          <Box>
                            <Text as="h4" marginBottom={0}>
                              {t('importedContent.provider')}
                            </Text>

                            <Box paddingY={4}>
                              <Image
                                src={setVendorImage(data.content.vendor)}
                                height={50}
                                width="auto"
                              />
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    )}

                    {data.content.instructor_ids.length > 1 && (
                      <Box width="70%" marginBottom={10}>
                        <VStack
                          spacing={0}
                          backgroundColor="blackAlpha.50"
                          paddingY={16}
                          paddingX={6}
                        >
                          {data.content.instructor_ids.map(
                            (instructor: Instructor, index: number) => {
                              if (index !== selectedInstructor) return;

                              return (
                                <VStack spacing={2} key={instructor.name}>
                                  <Avatar
                                    name={instructor.name}
                                    src={instructor.image}
                                    marginBottom={4}
                                  />
                                  <Text fontWeight="bold" display="block" marginTop={0}>
                                    {instructor.name}
                                  </Text>

                                  {instructor.bio && <HtmlRenderer html={instructor.bio} />}
                                </VStack>
                              );
                            },
                          )}

                          <HStack padding={4}>
                            {data.content.instructor_ids.map(
                              (instructor: Instructor, index: number) => (
                                <Box
                                  key={instructor.name}
                                  width={2.5}
                                  height={2.5}
                                  boxShadow={`inset 0 0 0 2px ${brandPrimary}`}
                                  background={
                                    selectedInstructor === index ? 'brand.primary' : 'transparent'
                                  }
                                  display="inline-block"
                                  borderRadius={8}
                                  transition="0.3s ease all"
                                  onClick={() => {
                                    setSelectedInstructor(index);
                                  }}
                                  _hover={{
                                    cursor: 'pointer',
                                    boxShadow: `inset 0 0 0 4px ${brandPrimary}`,
                                  }}
                                />
                              ),
                            )}
                          </HStack>
                        </VStack>
                        <Box>
                          <Text as="h4" fontSize="xs" marginBottom={0}>
                            {t('importedContent.provider')}
                          </Text>

                          <Box
                            height="50px"
                            width="80px"
                            backgroundImage={setVendorImage(data.content.vendor)}
                            backgroundSize="80px auto"
                            backgroundPosition="center"
                            transform="scale(1)"
                            backgroundRepeat="no-repeat"
                          />
                        </Box>
                      </Box>
                    )}
                  </Flex>
                </Flex>
              </TabPanel>

              <TabPanel maxWidth="668px" margin="0 auto">
                {data.content.allow_comments && !partner._id && (
                  <CommentList
                    contentId={contentId}
                    contentType="imported_content"
                    contentTitle={data.content.title}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Center>
        <ViewActions buttons={['gototop']} bottomOffset={showPopup ? 10 : 0} />

        {showPopup && (
          <PopupSurveyLayout
            surveyId={data.content.surveys[0].survey_id}
            contentId={data.content._id}
            contentType="imported_content"
          />
        )}
      </>
    </Box>
  );
}
